import { Spin } from "antd";
import React from "react";

const Loader = () => (
    <div style={{ height: "100vh", display:"flex",alignItems:"center",justifyContent:"center" }}>
        <Spin />
    </div>
);

export default Loader;
