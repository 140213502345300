import React from 'react';

import {
    Steps, FlexLayout, FlexItem, StackingLayout
} from '@nutanix-ui/prism-reactjs';
import TemplateDetailsForm from './sub-component/TemplateDetailsForm';
import UserDetailsForm from './sub-component/UserDetailsForm';
import AddColumnsForm from './sub-component/AddColumnsForm';
import Summary from './sub-component/Summary';

const stepsData = [
    {
        title: 'Template Details'
    },
    {
        title: 'User Details'
    },
    {
        title: 'Add Column'
    },
    {
        title: 'Summary'
    }
];


export default function TemplateForms(props) {
    const { current, setCurrent, isLoading } = props;

    const renderForm = () => {
        if(current === 0) return <TemplateDetailsForm />
        if(current === 1) return <UserDetailsForm />
        if(current === 2) return <AddColumnsForm />
        if(current === 3) return <Summary setCurrent={setCurrent} isLoading={isLoading}/>
    }

    return (
        <StackingLayout style={{ width: '100%'}}>
            <Steps
                data={stepsData}
                current={current}
            />
            <FlexLayout
                id={`${2134}-${current + 1}`}
                flexDirection="column"
                alignItems="center"
                color='red'
                role="tabpanel-2"
                aria-labelledby={current}
                tabIndex={0}
            >
                <FlexItem style={{ width: '72%' }}>
                    {renderForm()}
                </FlexItem>
            </FlexLayout>
        </StackingLayout>
    );
}