import React, { useContext } from "react";
import LeoContext from "../../../contexts/leoContexts/leoContext";
import { uniqueId } from "lodash";
import {
  StackingLayout,
  Tabs,
  FlexLayout,
  TextLabel,
  Badge,
} from "@nutanix-ui/prism-reactjs";

const DetailsStatus = ({ id, aggregatedTotal, expenseTotal, flightTotal   }) => {
  const overflowCount = 100000; // overflow from 100000 data
  const { setActiveSpenders, activeSpenders } = useContext(LeoContext);
  
  if (id === "topSpenders") {
    const panelIdPrefix = uniqueId("tab-panel-");
    const Tabsdata = [
      {
        title: (
          <FlexLayout alignItems="center" itemSpacing="10px">
            <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
              Expenses & Flights
            </TextLabel>
            <Badge  type={ Badge.BadgeTypes.TAG }  count={aggregatedTotal} overflowCount={overflowCount} />
          </FlexLayout>
        ),
        key: "1",
      },
      {
        title: (
          <FlexLayout alignItems="center" itemSpacing="10px">
            <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
              Expenses
            </TextLabel>
            <Badge  type={ Badge.BadgeTypes.TAG }  count={expenseTotal} overflowCount={overflowCount} />
          </FlexLayout>
        ),
        key: "2",
      },
      {
        title: (
          <FlexLayout alignItems="center" itemSpacing="10px">
            <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
              {" "}
              Flights
            </TextLabel>
            <Badge  type={ Badge.BadgeTypes.TAG }  count={flightTotal} overflowCount={overflowCount} />
          </FlexLayout>
        ),
        key: "3",
      },
    ];
    const handleTabClick = (tabKey) => {
      setActiveSpenders(tabKey);
    };
    return (
      <StackingLayout data-test-id="default">
        <Tabs
          data={Tabsdata}
          onTabClick={handleTabClick}
          defaultActiveKey={"1"}
          activeKey={activeSpenders}
          data-test-id="default-active"
          panelIdPrefix={panelIdPrefix}
          style={{ fontSize: "14px" }}
          adaptive={true}
        />
      </StackingLayout>
    );
  }
};

export default DetailsStatus;
