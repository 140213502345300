import React from "react";
import "../../App.css";
import { Card, Layout, Row, Col, Spin, Select, Alert, Popover } from "antd";
import { QuestionCircleOutlined, VideoCameraOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import "../../styles/animate.css";
import Tiles from "./Tiles";
import PageHeader from "../../Containers/PageHeader";
import SideBar from "../SideBar";
import { appDataArray, appDataArray1, periodMenuItems } from "./config";
import { SERVER_URL } from "../../config/config";
import { Content, Header } from "antd/lib/layout/layout";
import TimeAgo from "timeago-react";
import moment from "moment";
import Logger from "../../config/logger";

import { sendUserRecord } from "../../Service/adoptionMetric";

const { Option } = Select;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiProgress: { loading: false, ready: false, error: false },
            p0Tickets: {},
            graphname: "DB",
            no_of_days: "1",
            app_data: appDataArray,
            app_data1: appDataArray1,
            dremioCarouselData: [],
            carouselData: {
                ["DAAS (DREMIO)"]: [],
            },

            dbdata: [
                { key: 0, metrics_details: "", type: "" },
                { key: 1, metrics_details: "", type: "" },
            ],
            dbdata1: [
                { key: 0, metrics_details: "", type: "" },
                { key: 1, metrics_details: "", type: "" },
            ],

            periodSelectedRowValue: 0,
        };
    }

    periodChanged = async (periodIndex) => {
        try {
            this.setState({ periodSelectedRowValue: periodIndex });

            var v_no_ofdays = 1;
            let request_body = {
                query: {
                    openpotickets:
                        'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -1d',
                    missedtickets:
                        'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -1d',
                },
            };
            if (periodIndex === "1") {
                v_no_ofdays = 7;
                request_body = {
                    query: {
                        openpotickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -7d',
                        missedtickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -7d',
                    },
                };
            } else if (periodIndex === "2") {
                v_no_ofdays = 30;
                request_body = {
                    query: {
                        openpotickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -30d',
                        missedtickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -30d',
                    },
                };
            } else if (periodIndex === "3") {
                v_no_ofdays = 90;
                request_body = {
                    query: {
                        openpotickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -90d',
                        missedtickets:
                            'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -90d',
                    },
                };
            }

            this.setState({
                apiProgress: { loading: true, ready: false, error: false },
            });
            //Production
            Logger.info(
                `Fetching platform getallmetrics api response with parameter v_no_ofdays : ${v_no_ofdays}`,
                "platform_health_client",
                window.location.href
            );
            const dbdataPromise = await axios.post(
                `${SERVER_URL}/getallmetrics?no_of_days=` + v_no_ofdays
            );
            Logger.info(`Fetched getallmetrics response successfully`,
                "platform_health_client",
                window.location.href);
            Logger.info(
                `Fetching stage getallmetrics api response with parameter v_no_ofdays : ${v_no_ofdays}`,
                "platform_health_client",
                window.location.href
            );
            const dbdataPromiseStage = await axios.post(
                `${SERVER_URL}/getallmetricsstg?no_of_days=` + v_no_ofdays
            );
            Logger.info(`Fetched stage getallmetrics api response }`,
                "platform_health_client",
                window.location.href
            );
            Logger.info(`Fetching Jira query api response`,
                "platform_health_client",
                window.location.href
            );
            axios
                .post(
                    `${SERVER_URL}/jira_query_dashboard?periodIndex=${periodIndex}`,
                    request_body
                )
                .then((response) => {
                    Logger.info(`Fetched Jiraquery data successfully`,
                        "platform_health_client",
                        window.location.href
                    );
                    this.setState({
                        p0Tickets: response.data,
                    });
                });

            Promise.all([dbdataPromise, dbdataPromiseStage])
                .then(([dbdataresponse, dbdataresponsestg]) => {
                    const dbDataResponseUpTime = dbdataresponse.data.find(
                        (e) => e.platform === "DB" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailability = dbdataresponse.data.find(
                        (e) => e.platform === "DB" && e.name === "Availability"
                    ).metrics_details;
                    const dbDataResponseUpTimeetl = dbdataresponse.data.find(
                        (e) => e.platform === "ETL" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilityetl =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "ETL" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseUpTimedaas = dbdataresponse.data.find(
                        (e) => e.platform === "DREMIO" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilitydaas =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "DREMIO" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseUpTimetablue = dbdataresponse.data.find(
                        (e) => e.platform === "TABLEAU" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilitytablue =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "TABLEAU" &&
                                e.name === "Availability"
                        ).metrics_details;

                    let newDataArray = appDataArray;
                    let newDataArray1 = appDataArray1;

                    newDataArray[0]["availability"] =
                        dbDataResponseAvailability;
                    newDataArray[0]["upTime"] = dbDataResponseUpTime;
                    newDataArray[1]["upTime"] = dbDataResponseUpTimeetl;
                    newDataArray[1]["availability"] =
                        dbDataResponseAvailabilityetl;
                    newDataArray[2]["upTime"] = dbDataResponseUpTimedaas;
                    newDataArray[2]["availability"] =
                        dbDataResponseAvailabilitydaas;
                    newDataArray[3]["upTime"] = dbDataResponseUpTimetablue;
                    newDataArray[3]["availability"] =
                        dbDataResponseAvailabilitytablue;

                    const dbDataResponseStgUpTime = dbdataresponsestg.data.find(
                        (e) => e.platform === "DB" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseStgAvailability =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "DB" && e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseStgUpTimeetl =
                        dbdataresponsestg.data.find(
                            (e) => e.platform === "ETL" && e.name === "Up Time"
                        ).metrics_details;
                    const dbDataResponseStgAvailabilityetl =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "ETL" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseStgUpTimetablue =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "TABLEAU" && e.name === "Up Time"
                        ).metrics_details;
                    const dbDataResponseStgAvailabilitytablue =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "TABLEAU" &&
                                e.name === "Availability"
                        ).metrics_details;

                    newDataArray1[0]["availability"] =
                        dbDataResponseStgAvailability;
                    newDataArray1[0]["upTime"] = dbDataResponseStgUpTime;
                    newDataArray1[1]["upTime"] = dbDataResponseStgUpTimeetl;
                    newDataArray1[1]["availability"] =
                        dbDataResponseStgAvailabilityetl;
                    newDataArray1[2]["upTime"] = dbDataResponseStgUpTimetablue;
                    newDataArray1[2]["availability"] =
                        dbDataResponseStgAvailabilitytablue;

                    this.setState({
                        app_data: newDataArray,
                        no_of_days: v_no_ofdays,
                        app_data1: newDataArray1,
                        dbdata: dbdataresponse.data,
                        dbdata1: dbdataresponsestg.data,
                    });
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: true,
                            error: false,
                        },
                    });
                })
                .catch((e) => {
                    Logger.error(e,
                        "platform_health_client",
                        window.location.href);
                    const error = e.message;
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: false,
                            error: error,
                        },
                    });
                });
        } catch (e) {
            Logger.error(e,
                "platform_health_client",
                window.location.href
            );
        }
    };

    async componentDidMount() {
        try {
            var v_no_ofdays = 1;
            let request_body = {
                query: {
                    openpotickets:
                        'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -1d',
                    missedtickets:
                        'project = DS AND status = Open AND priority in ("Blocker - P0") AND created >= -1d AND "First Response in Hours" <= 0.5',
                },
            };

            this.setState({
                apiProgress: { loading: true, ready: false, error: false },
            });
            //Production
            Logger.info(
                `Fetching platform getallmetrics api response with parameter v_no_ofdays : ${v_no_ofdays}`,
                "platform_health_client",
                window.location.href
            );
            const dbdataPromise = axios.post(
                `${SERVER_URL}/getallmetrics?no_of_days=` + v_no_ofdays
            );
            Logger.info(`Fetched getallmetrics response successfully`,
                "platform_health_client",
                window.location.href
            );
            Logger.info(
                `Fetching stage getallmetrics api response with parameter v_no_ofdays : ${v_no_ofdays}`,
                "platform_health_client",
                window.location.href
            );
            const dbdataPromiseStage = axios.post(
                `${SERVER_URL}/getallmetricsstg?no_of_days=` + v_no_ofdays
            );

            sendUserRecord('platform_health', window.location.href)
                .then(resp => { })
                .catch(err => { })

            Logger.info(
                `Fetched stage getallmetrics api response successfully`,
                "platform_health_client",
                window.location.href
            );
            Logger.info(`Fetching Jira query api response `,
                "platform_health_client",
                window.location.href
            );
            axios
                .post(`${SERVER_URL}/jira_query_dashboard`, request_body)
                .then((response) => {
                    Logger.info(`Fetched Jiraquery data successfully`, "platform_health_client",
                        window.location.href);
                    this.setState({
                        p0Tickets: response.data,
                    });
                });
            Logger.info(`Fetching Dremioextra data api response`,
                "platform_health_client",
                window.location.href
            );
            axios.post(`${SERVER_URL}/dremioextradata`).then((response) => {
                Logger.info(
                    `Fetched Dremioextra data api response successfully `,
                    "platform_health_client",
                    window.location.href
                );
                this.setState({
                    carouselData: {
                        ...this.state.carouselData,
                        ["DAAS (DREMIO)"]: response?.data?.rows || [],
                    },
                });
            });
            Promise.all([dbdataPromise, dbdataPromiseStage])
                .then(([dbdataresponse, dbdataresponsestg]) => {
                    const dbDataResponseUpTime = dbdataresponse.data.find(
                        (e) => e.platform === "DB" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailability = dbdataresponse.data.find(
                        (e) => e.platform === "DB" && e.name === "Availability"
                    ).metrics_details;
                    const dbDataResponseUpTimeetl = dbdataresponse.data.find(
                        (e) => e.platform === "ETL" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilityetl =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "ETL" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseUpTimedaas = dbdataresponse.data.find(
                        (e) => e.platform === "DREMIO" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilitydaas =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "DREMIO" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseUpTimetablue = dbdataresponse.data.find(
                        (e) => e.platform === "TABLEAU" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseAvailabilitytablue =
                        dbdataresponse.data.find(
                            (e) =>
                                e.platform === "TABLEAU" &&
                                e.name === "Availability"
                        ).metrics_details;

                    let newDataArray = appDataArray;
                    let newDataArray1 = appDataArray1;

                    newDataArray[0]["availability"] =
                        dbDataResponseAvailability;
                    newDataArray[0]["upTime"] = dbDataResponseUpTime;
                    newDataArray[1]["upTime"] = dbDataResponseUpTimeetl;
                    newDataArray[1]["availability"] =
                        dbDataResponseAvailabilityetl;
                    newDataArray[2]["upTime"] = dbDataResponseUpTimedaas;
                    newDataArray[2]["availability"] =
                        dbDataResponseAvailabilitydaas;
                    newDataArray[3]["upTime"] = dbDataResponseUpTimetablue;
                    newDataArray[3]["availability"] =
                        dbDataResponseAvailabilitytablue;

                    const dbDataResponseStgUpTime = dbdataresponsestg.data.find(
                        (e) => e.platform === "DB" && e.name === "Up Time"
                    ).metrics_details;
                    const dbDataResponseStgAvailability =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "DB" && e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseStgUpTimeetl =
                        dbdataresponsestg.data.find(
                            (e) => e.platform === "ETL" && e.name === "Up Time"
                        ).metrics_details;
                    const dbDataResponseStgAvailabilityetl =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "ETL" &&
                                e.name === "Availability"
                        ).metrics_details;
                    const dbDataResponseStgUpTimetablue =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "TABLEAU" && e.name === "Up Time"
                        ).metrics_details;
                    const dbDataResponseStgAvailabilitytablue =
                        dbdataresponsestg.data.find(
                            (e) =>
                                e.platform === "TABLEAU" &&
                                e.name === "Availability"
                        ).metrics_details;
                    newDataArray1[0]["availability"] =
                        dbDataResponseStgAvailability;
                    newDataArray1[0]["upTime"] = dbDataResponseStgUpTime;
                    newDataArray1[1]["upTime"] = dbDataResponseStgUpTimeetl;
                    newDataArray1[1]["availability"] =
                        dbDataResponseStgAvailabilityetl;
                    newDataArray1[2]["upTime"] = dbDataResponseStgUpTimetablue;
                    newDataArray1[2]["availability"] =
                        dbDataResponseStgAvailabilitytablue;
                    this.setState({
                        app_data: newDataArray,
                        no_of_days: v_no_ofdays,
                        app_data1: newDataArray1,
                        dbdata: dbdataresponse.data,
                        dbdata1: dbdataresponsestg.data,
                    });
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: true,
                            error: false,
                        },
                    });
                })
                .catch((e) => {
                    Logger.error(e, "platform_health_client",
                        window.location.href);
                    const error = e.message;
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: false,
                            error: error,
                        },
                    });
                });
        } catch (e) {
            Logger.error(e, "platform_health_client",
                window.location.href);
        }
    }

    render() {
        const { graphname, app_data, no_of_days, periodSelectedRowValue } =
            this.state;
        const chart_name = graphname;
        const periodValue = periodMenuItems[periodSelectedRowValue].label;

        return (
            <Row>
                <Col span={24}>
                    <div className={"page-title-wrapper"}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <h1
                                style={{
                                    fontSize: "1.7em",
                                }}
                            >
                                Platforms Health
                            </h1>
                            <Select
                                style={{
                                    width: "140px",
                                    margin: "0 8px",
                                    fontSize: "12px",
                                }}
                                value={periodValue}
                                onChange={this.periodChanged}
                            >
                                {periodMenuItems.map((e) => (
                                    <Option
                                        key={e.value}
                                        value={e.value}
                                        style={{
                                            fontSize: "12px",
                                        }}
                                    >
                                        {e.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <CardPopover>
                            <div>
                                <VideoCameraOutlined style={{ fontSize: 18 }} />
                            </div>
                        </CardPopover>
                    </div>
                    {this?.state?.apiProgress?.error && (
                        <Alert
                            message="Error"
                            description={this.state.apiProgress.error}
                            type="error"
                            showIcon
                        />
                    )}
                    <Spin
                        spinning={this.state.apiProgress.loading}
                        tip={"Loading.please wait..."}
                    >
                        <Card
                            bordered={false}
                            className={"n-card"}
                            style={{
                                height: "calc(100vh - 136px)",
                                padding: 10,
                            }}
                        >
                            <div className="n-content wrapperTiles">
                                <div className="card-container">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <h2>Environment: Production</h2>
                                        <h5>
                                            <TimeAgo
                                                datetime={moment().format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                )}
                                                locale="en"
                                            />
                                        </h5>
                                    </div>
                                    <Tiles
                                        app_data={app_data}
                                        no_of_days={no_of_days}
                                        periodValue={periodValue}
                                        carouselDremio={this.state.carouselData}
                                        env={"Production"}
                                    />
                                    <br></br>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <h2>Environment: Stage</h2>
                                        <h5>
                                            <TimeAgo
                                                datetime={moment().format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                )}
                                                locale="en"
                                            />
                                        </h5>
                                    </div>
                                    <Tiles
                                        addExtraCard={true}
                                        app_data={this.state.app_data1}
                                        p0TicketsData={this.state.p0Tickets}
                                        no_of_days={no_of_days}
                                        periodValue={periodValue}
                                        env={"Stage"}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Spin>
                </Col>
            </Row>
        );
    }
}

export default Home;
function CardPopover({ children }) {
    return (
        <div>
            <Popover
                placement="leftBottom"
                // trigger={"click"}
                content={<PopoverContent />}
            >
                {children}
            </Popover>
        </div>
    );
}
function PopoverContent() {
    return (
        <div>
            <iframe
                width="760"
                height="415"
                // src="https://www.youtube.com/embed/K1vfaXND0GE"
                src="https://www.youtube.com/embed/E-3SZ_4L6hY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
            ></iframe>
        </div>
    );
}
