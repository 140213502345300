// apiUtils.js
import axios from "axios";
import { SERVER_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";
import Logger from "../../config/logger";
// teams
const fetchTeams = async () => {
  Logger.info(
    `Started fetching fetch-teams api`,
    "jira_analysis_client",
    window.location.href
  );
  const response = await axios.get(`${SERVER_URL}/fetch-teams`);
  Logger.info(
    `Successfully fetching fetch-teams api`,
    "jira_analysis_client",
    window.location.href
  );
  return response.data.data;
};

export const useTeams = (user) => {
  return useQuery({
    queryKey: ['teams', user],
    queryFn: () => fetchTeams(user),
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000
  });
};

// dashboard
const fetchDashboardData = async (projectId, sprint) => {
  Logger.info(
    `Started fetching jira-ssot-dashboard api`,
    "jira_analysis_client",
    window.location.href
  );
  const DASHBOARD_URL = `${SERVER_URL}/jira-ssot-dashboard?project_id=${projectId}&sprint=${encodeURIComponent(
    sprint
  )}`;
  const response = await axios.get(DASHBOARD_URL);
  Logger.info(
    `Successfully fetching jira-ssot-dashboard api`,
    "jira_analysis_client",
    window.location.href
  );
  return response.data;
};

export const useDashboardData = (projectId, sprint, dispatch) => {
  return useQuery({
    queryKey: ["dashboard", sprint],
    queryFn: () => fetchDashboardData(projectId, sprint, dispatch),
    enabled: !!projectId && sprint !== "All",
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

// Bucketisation
const fetchBucketisation = async (payload) => {
  Logger.info(
    `Started fetching bucketization api`,
    "jira_analysis_client",
    window.location.href
  );
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  const response = await axios.get(
    `${SERVER_URL}/bucketization?payload=${encodedPayload}`
  );
  Logger.info(
    `Successfully fetched bucketization api`,
    "jira_analysis_client",
    window.location.href
  );
  return response.data.data;
};

export const useBucketisationData = (projectId, sprint) => {
  const payload = {
    sprint: sprint,
    project_id: projectId,
    component: null,
    issue_type: null,
    assignee_name: null,
    labels: null,
  };
  return useQuery({
    queryKey: ["bucketisation", projectId, sprint],
    queryFn: () => fetchBucketisation(payload),
    enabled: !!payload.project_id && payload.sprint !== "All",
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000
  });
};

// storyOverview
const fetchStoryOverview = async (payload) => {
  Logger.info(
    `Started fetching jira-ssot-story-overview api`,
    "jira_analysis_client",
    window.location.href
  );
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  const response = await axios.get(
    `${SERVER_URL}/jira-ssot-story-overview?payload=${encodedPayload}`
  );
  Logger.info(
    `Successfully fetched jira-ssot-story-overview api`,
    "jira_analysis_client",
    window.location.href
  );
  return response.data.data;
};

export const useStoryOverviewData = (projectId, sprint) => {
  const payload = {
    sprint: sprint,
    project_id: projectId,
    component: null,
    issue_type: null,
    assignee_name: null,
    labels: null,
    issue_category: null,
    grouped_jira_status: null,
    story_point_assigned_yn_flag: null,
    eta_assigned_yn_flag: null,
  };
  return useQuery({
    queryKey: ["storyOverview", projectId, sprint],
    queryFn: () => fetchStoryOverview(payload),
    enabled: !!payload.project_id && payload.sprint !== "All",
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000
  });
};

// trends
const fetchTrends = async (payload) => {
  Logger.info(
    `Started fetching trend-metrics api`,
    "jira_analysis_client",
    window.location.href
  );
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  const response = await axios.get(
    `${SERVER_URL}/trend-metrics?payload=${encodedPayload}`
  );
  Logger.info(
    `Successfully fetched trend-metrics api`,
    "jira_analysis_client",
    window.location.href
  );
  return response.data.data;
};

export const useTrendsData = (projectId) => {
  const payload = {
    sprint: null,
    project_id: projectId,
    component: null,
    issue_type: null,
    assignee_name: null,
    labels: null,
  };
  return useQuery({
    queryKey: ["trends", projectId],
    queryFn: () => fetchTrends(payload),
    enabled: !!payload.project_id,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
