import {
  Bar,
  CartesianGrid,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "@nutanix-ui/recharts";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoData from "./NoData";
import {
  setOpenModal,
  setModalData,
  setTotalSuccessVul,
  setFooterDataMap,
} from "../../../../features/JiraAnalysis/autoFixSlice";
import { dataMap,toolTipContent,CartesianGridColor } from "../../AutoFixReportsUtil";

const AutoFixReportsGraph = ({  }) => {
  const dispatch = useDispatch();
  const { ReportsData, selectedValues, loading, FooterDataMap } = useSelector(
    (state) => state.autoFixReports
  );
  const handleBarClick = (data) => {
    if (selectedValues.viewBy !== "Daily") {
      const weekMontylyData = data?.WeekData?.map((item) => {
        const { VulnerabilitySummary, ...rest } = item;

        return {
          ...rest,
          ...VulnerabilitySummary,
        };
      });

      dispatch(setModalData(weekMontylyData));
    } else {
      dispatch(setModalData([data]));
    }
    dispatch(setOpenModal(true));
  };
  const constructTotalSuccessVul = (ReportsData) => {
    const totals = { Success: 0 };

    ReportsData?.forEach((entry) => {
      totals.Success += entry.Success || 0;
    });

    return totals.Success;
  };
  useEffect(() => {
    let count = constructTotalSuccessVul(ReportsData);

    dispatch(setTotalSuccessVul(count));
    dispatch(setFooterDataMap(dataMap()));
  }, [ReportsData]);
  const CustomTooltip = ({ active, payload, label, }) => {
    if (active && payload && payload.length) {
     return toolTipContent(active, payload, label,FooterDataMap)
    }
  };
  return (
    <ResponsiveContainer width="100%" height={200} id="autofixChart">
      {ReportsData?.length > 0 ? (
        <BarChart
          data={ReportsData}
          barSize={25}
          alignment="none"
        >
           <CartesianGrid stroke={CartesianGridColor} interval={0} />
          <XAxis
            interval={0}
            dataKey="PRDate"
            type="category"
            tickFormatter={(value) => {
              if (selectedValues.viewBy == "Daily") {
                return moment(value).format("MMM D, YYYY ");
              }
              if (selectedValues.viewBy == "Monthly") {
                const date = moment(value).add(1, "days").format("MMM, YYYY");
                return date;
              
              }
              if (selectedValues.viewBy == "Weekly") {
                const startDate = moment(value).format("MMM D");
                const endDate = moment(value).add(7, "days").format("MMM D");
                return `${startDate} - ${endDate}`;
              }
            }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {FooterDataMap?.map(
            (item, i) =>
              item.hide && (
                <Bar
                  id={i}
                  dataKey={item.label}
                  stackId="a"
                  fill={item.color}
                  onClick={(data) => handleBarClick(data)}
                />
              )
          )}
        </BarChart>
      ) : (
        <NoData
          message={
               "No Data"
          }
        />
      )}
    </ResponsiveContainer>
  );
};

export default AutoFixReportsGraph;
