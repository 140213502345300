import React, { Component, PropTypes } from "react";
import { Card, Menu, Row, Col, Dropdown, Input } from "antd";
import { Timeline, Modal, Button, Spin, Table, Popover, Progress } from "antd";
import {
    SearchOutlined,
    DownCircleOutlined,
    DesktopOutlined,
} from "@ant-design/icons";
// import { Steps } from "antd";
import moment from "moment-timezone";
import greenLogo from "../../images/heart-green.png";
import yellowLogo from "../../images/heart-yellow.png";
import grayLogo from "../../images/heart-gray.png";
import redLogo from "../../images/heart-red.png";
import $ from "jquery";
import TimeAgo from "timeago-react";
import axios from "axios";
import "../DataSync/datasync.css";
import Config from "../../config/ApplicationTiles";
import DataSyncTableRenderer from "./DataSyncTableRenderer";
import { SERVER_URL } from "../../config/config";
import logger from "../../config/logger";
import { InfoPopover } from "../InfoPopover";
import { information } from "../DataSync/card-additional-info";
const propTypes = {};
const title = () => Config.config.table2DaysTitle;
const showHeader = true;
const defaultProps = {};

var backendChartData;
var hourWiseChart = true;

const columns = [
    {
        title: "End-point",
        dataIndex: "endPoint",
        key: "endPoint",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Time",
        dataIndex: "time",
        key: "time",
    },
];
const text = <span>Title</span>;
const content = (
    <div>
        <p>Content</p>
        <p>Content</p>
    </div>
);
const CardInfoTitle = ({ title, ...otherProps }) => (
    <div className="flex">
        <DesktopOutlined
            className="desktopOL"
            style={{ fontSize: "18px", color: "gray" }}
        />
        <div style={{ marginLeft: "-8px" }}>{title}</div>
        <InfoPopover {...otherProps} />
    </div>
);

class TilesDataSyncProdStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoursToShow: Config.config.nHours,
            modalTitle: "",
            showCharts: false,
            showLoading: false,
            title: title,
            showHeader,
            dropdownText: "Select time interval",
            bordered: true,
            visible: false,
            schemaTableVisible: false,
            chartData: Config.config.chartData,
            showLegend: this.props.showLegend,
            dashBoard: this.props.dashBoard,
            filterDropdownVisible: false,
            data: [],
            filteredData: [],
            searchText: "",
            filtered: false,
            schemaName: "",
        };
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onApplicationsData = this.onApplicationsData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.info = this.info.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.onDaysChartApplicationsData =
            this.onDaysChartApplicationsData.bind(this);
        this.onApplicationsDowntimeData =
            this.onApplicationsDowntimeData.bind(this);
    }

    onApplicationsData(error, data) {
        this.backendChartData = data;
        hourWiseChart = true;
        setTimeout(() => {
            this.showModal();
        }, 1000);
    }

    onDaysChartApplicationsData(error, data) {
        this.backendChartData = data;
        hourWiseChart = false;
        setTimeout(() => {
            this.showModal();
        }, 1000);
    }

    handleMenuClick(ev) {
        if (ev.key === "1") {
            this.fetchData(this.state.currentUID);
            this.fetchDownTimeTableData(this.state.currentUID, 2);
            const updatedTitle = () => Config.config.table2DaysTitle;
            this.setState({
                title: updatedTitle,
                showLoading: true,
                showCharts: false,
                dropdownText: "Last 48 hours",
            });
        } else {
            this.fetchMonthData(this.state.currentUID);
            this.fetchDownTimeTableData(this.state.currentUID, 30);
            const updatedTitle = () => Config.config.table30DaysTitle;
            this.setState({
                title: updatedTitle,
                showLoading: true,
                showCharts: false,
                dropdownText: "Last 30 days",
            });
        }
    }

    onApplicationsDowntimeData(error, data) {
        var object = {},
            dataSource = [];

        if (data.length > 0) {
            data.forEach(function (item, index) {
                object.key = index + 1;
                object.endPoint = item.endPoint;
                object.date = moment(item.time).format("DD-MMM-YYYY");
                object.time = moment(item.time).format("HH:mm");
                dataSource.push(object);
                object = {};
            });
        }

        this.setState({
            dataSource: dataSource,
        });
    }

    onInputChange = (e) => {
        this.setState({ searchText: e.target.value });
    };
    onSearch = () => {
        const { searchText, data } = this.state;
        const reg = new RegExp(searchText, "gi");
        this.setState({
            filterDropdownVisible: false,
            filtered: !!searchText,
            filteredData: data
                .map((record) => {
                    const match = record.table_name.match(reg);
                    if (!match) {
                        return null;
                    }
                    return {
                        ...record,
                        table_name: (
                            <span>
                                {record.table_name
                                    .split(reg)
                                    .map((text, i) =>
                                        i > 0
                                            ? [
                                                  <span className="highlight">
                                                      {match[0]}
                                                  </span>,
                                                  text,
                                              ]
                                            : text
                                    )}
                            </span>
                        ),
                    };
                })
                .filter((record) => !!record),
        });
    };
     formatDateToPDT = (dateString) => {
        const date = new Date(dateString + ' GMT-0700');
        const options = {
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('en-US', options).replace(',', '');
    };
    getTableColumns = () => [
        // { title: "S.No.", dataIndex: "key", key: "key" },
        {
            title: "Table Name",
            dataIndex: "table_name",
            key: "table_name",
            className: "custom-cell",
            width: 100,
            render: (value, rowData) => {
                return (
                    <DataSyncTableRenderer rowData={rowData} value={value}>
                        <div
                            className={rowData.delta === 0 ? "" : ""}
                            style={{ cursor: "pointer" }}
                        >
                            {value}
                        </div>
                    </DataSyncTableRenderer>
                );
            },
            filterDropdown: (
                <div className="custom-filter-dropdown">
                    <Input
                        ref={(node) => {
                            this.searchInput = node;
                        }}
                        placeholder="Search name"
                        value={this.state.searchText}
                        onChange={this.onInputChange}
                        onPressEnter={this.onSearch}
                    />
                    <div>
                        <Button type="primary" onClick={this.onSearch}>
                            Search
                        </Button>
                        <Button
                            onClick={() =>
                                this.setState({
                                    filtered: false,
                                    filterDropdownVisible: false,
                                    searchText: "",
                                    filteredData: this.state.data,
                                })
                            }
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: (
                <SearchOutlined
                    style={{ color: this.state.filtered ? "#108ee9" : "#aaa" }}
                />
                // <Icon
                //   type="search"
                //   style={{ color: this.state.filtered ? "#108ee9" : "#aaa" }}
                // />
            ),
            filterDropdownVisible: this.state.filterDropdownVisible,
            onFilterDropdownVisibleChange: (visible) => {
                this.setState({
                    filterDropdownVisible: visible,
                });
                setTimeout(() => {
                    this.searchInput.focus();
                }, 1000);
            },
            sorter: (a, b) => {
                a = String(a.table_name) || "";
                b = String(b.table_name) || "";

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            },
        },

        {
            title: "Delta",
            dataIndex: "delta",
            key: "delta",
            className: "custom-cell t-a-r",
            width: 5,
            render: (value, rowData) => {
                return (
                    <DataSyncTableRenderer rowData={rowData} value={value}>
                        <div className="deltaStyle">{value}</div>
                    </DataSyncTableRenderer>
                );
            },

            sorter: (a, b) => {
                let val1 = a.delta,
                    val2 = b.delta;
                // setTimeout(() => {
                //   $("span.highlight-delta")
                //     .parents("td")
                //     .css("background-color", "#fef0ef");
                // }, 100);
                return val1 - val2;
            },
        },

        {
            title: "Prod Data As Of (PDT)",
            className: "custom-cell",
            dataIndex: "prod_data_as_of",
            key: "prod_data_as_of",
            width: 50,
            render: (value, rowData) => {
                return (
                    <DataSyncTableRenderer rowData={rowData} value={value}>
                        <div className={rowData.delta === 0 ? "" : ""}>
                                {this.formatDateToPDT(value)}
                        </div>
                    </DataSyncTableRenderer>
                );
            },
            sorter: (a, b) => {
                return moment
                    .utc(a.prod_data_as_of)
                    .diff(moment.utc(b.prod_data_as_of));
            },
        },
        {
            title: "Status",
            className: "custom-cell",
            dataIndex: "status",
            key: "status",
            width: 5,
            render: (value, rowData) => {
                return (
                    <DataSyncTableRenderer rowData={rowData} value={value}>
                        <div className={rowData.delta === 0 ? "" : ""}>
                            {this.handleTableColumnStatus(this.state.schemaName,rowData) ? (
                                <Progress
                                    type="circle"
                                    percent={100}
                                    width={24}
                                />
                            ) : (
                                //   <Progress type="circle" percent={100} width={24} status="exception" />
                                <Progress
                                    type="circle"
                                    percent={100}
                                    width={24}
                                    status="exception"
                                />
                            )}
                        </div>
                    </DataSyncTableRenderer>
                );
            },
            sorter: (a, b) => {
                a = a.delta === 0 || false;
                b = b.delta === 0 || false;
                return a - b;
                // if(a < b) { return -1; }
                // if(a > b) { return 1; }
                // return 0;
            },
        },
    ];
    handleTableColumnStatus=(schema,schemaTableRow)=>{
        if(schema==='PULSE'){
            return schemaTableRow?.status
        }else{
            return schemaTableRow?.delta  === 0
        }
    }
    async info(v_schema_name) {
        try {
            logger.info(
                `Calling Datasync Details with parameter schema_name= ${v_schema_name}`
            );
            const { data } = await axios.post(
                `${SERVER_URL}/datasyncdetails?schema_name=` + v_schema_name
            );
            logger.info(`Datasync details fetched successfully`);
            let coloredData;
            this.setState({
                data: data,
                filteredData: data,
                schemaName: v_schema_name,
                schemaTableVisible: true,
            });
            $("span.highlight-delta")
                .parents("td")
                .css("background-color", "#fef0ef");
        } catch (e) {
            logger.error(e);
        }
    }
    openModal(event) {
        var target = event.target,
            type,
            id,
            name,
            values = [];

        Object.keys(target).map(function (keyName, keyIndex) {
            type = target[keyName]._currentElement.props.value;
            id = target[keyName]._currentElement.props.name;
            name = target[keyName]._currentElement.props.title;
        });

        values.push(type, id, name);

        this.fetchDownTimeTableData(id, 2);
        this.fetchData(id);

        this.setState({
            visible: true,
            modalTitle: name,
            showCharts: false,
            showLoading: true,
            currentUID: id,
            onClickValues: values,
        });
    }

    /*
  This function is called when user clicks on any App to show the status chart.
  Using two maps to store the active and inactive status. After data is
  properly formatted, we update the chartData using setState.
  */
    showModal() {
        var data = this.state.onClickValues;
        if (data && data.length === 3) {
            var type = data[0],
                id = data[1],
                name = data[2],
                backendChartData = this.backendChartData,
                activeData = ["active"],
                inactiveData = ["inactive"],
                noDataPolling = ["noDataPolling"],
                date = Date.now(),
                currentDate = new Date(date),
                timeDiff,
                statusCount = 0,
                statusDate,
                hours,
                xAxisLabel = [],
                stateAxisCopy = this.state.chartData.axis,
                stateChartDataCopy = this.state.chartData;

            if (hourWiseChart) {
                hours = this.state.hoursToShow;
                stateAxisCopy.x.label.text = "Last 48 hours (x-axis)";
                stateAxisCopy.y.label.text = "Polling per hour";
                stateAxisCopy.y.tick = { values: [0, 1, 2, 3, 4, 5, 6] };
                stateChartDataCopy.tooltip.format.title = function (d) {
                    return 48 - d + " hours ago";
                };
            } else {
                hours = Config.config.nDaysForChart;
                stateAxisCopy.x.label.text = "Last 30 days (x-axis)";
                stateAxisCopy.y.label.text = "Polling per day";
                stateAxisCopy.y.tick = null;
                stateChartDataCopy.tooltip.format.title = function (d) {
                    return 30 - d + " days ago";
                };
            }

            var chartActiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                ),
                chartInactiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                );

            backendChartData.forEach((item) => {
                statusDate = new Date(item.time);
                if (hourWiseChart) {
                    timeDiff = Math.floor(
                        Math.abs(currentDate.getTime() - statusDate.getTime()) /
                            3600000
                    );
                } else {
                    timeDiff = Math.floor(
                        Math.abs(currentDate.getTime() - statusDate.getTime()) /
                            86400000
                    );
                }
                if (
                    item.uid === id &&
                    item.type === type &&
                    timeDiff <= hours
                ) {
                    if (
                        item.status === "active" &&
                        chartActiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartActiveDataMap.get(timeDiff);
                        chartActiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    } else if (
                        item.status === "inactive" &&
                        chartInactiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartInactiveDataMap.get(timeDiff);
                        chartInactiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    }
                }
            });

            // TODO: Remove for loop
            for (var i = hours; i >= 0; --i) {
                if (i === 0) {
                    xAxisLabel.push("Latest");
                } else {
                    xAxisLabel.push(i);
                }
                activeData.push(chartActiveDataMap.get(i));
                inactiveData.push(chartInactiveDataMap.get(i));
                noDataPolling.push(0);
            }

            stateAxisCopy.x.categories = xAxisLabel;
            stateAxisCopy.x.tick.culling.max = hours / 3;
            stateChartDataCopy.columns = [
                activeData,
                inactiveData,
                noDataPolling,
            ];

            this.setState({
                chartData: stateChartDataCopy,
                visible: true,
                modalTitle: name,
                showCharts: true,
                showLoading: false,
                axis: stateAxisCopy,
            });
        } else {
            this.setState({
                visible: false,
            });
        }
    }

    showDaysChartModal() {
        var data = this.state.onClickValues;
        if (data && data.length === 3) {
            var type = data[0],
                id = data[1],
                name = data[2],
                backendChartData = this.backendChartData,
                activeData = ["active"],
                inactiveData = ["inactive"],
                noDataPolling = ["noDataPolling"],
                date = Date.now(),
                currentDate = new Date(date),
                timeDiff,
                statusCount = 0,
                statusDate,
                xAxisLabel = [],
                hours = this.state.hoursToShow,
                chartActiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                ),
                chartInactiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                );

            backendChartData.forEach((item) => {
                statusDate = new Date(item.time);
                timeDiff = Math.floor(
                    Math.abs(currentDate.getTime() - statusDate.getTime()) /
                        3600000
                );
                if (
                    item.uid === id &&
                    item.type === type &&
                    timeDiff <= hours
                ) {
                    if (
                        item.status === "active" &&
                        chartActiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartActiveDataMap.get(timeDiff);
                        chartActiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    } else if (
                        item.status === "inactive" &&
                        chartInactiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartInactiveDataMap.get(timeDiff);
                        chartInactiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    }
                }
            });

            // TODO: Remove for loop
            for (var i = hours; i >= 0; --i) {
                if (i === 0) {
                    xAxisLabel.push("Latest");
                } else {
                    xAxisLabel.push(i);
                }
                activeData.push(chartActiveDataMap.get(i));
                inactiveData.push(chartInactiveDataMap.get(i));
                noDataPolling.push(0);
            }

            var stateChartDataCopy = this.state.chartData;
            stateChartDataCopy.columns = [
                activeData,
                inactiveData,
                noDataPolling,
            ];
            var stateAxisCopy = this.state.chartData.axis;
            stateAxisCopy.x.categories = xAxisLabel;
            stateAxisCopy.x.tick.culling.max = hours / 3;
            this.setState({
                chartData: stateChartDataCopy,
                visible: true,
                modalTitle: name,
                showCharts: true,
                showLoading: false,
                axis: stateAxisCopy,
            });
        } else {
            this.setState({
                visible: false,
            });
        }
    }

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
            dropdownText: "Select time interval",
        });
    };
    handleSyncModalCancel = (e) => {
        this.setState({
            schemaTableVisible: false,
            modalTitle: "",
            showCharts: false,
            showLoading: false,
            showHeader,
            bordered: true,
            visible: false,
            schemaTableVisible: false,
            filterDropdownVisible: false,
            data: [],
            filteredData: [],
            searchText: "",
            filtered: false,
            schemaName: "",
        });
    };
 handleTableColumns =()=>{
    if (this.state.schemaName === 'PULSE'){
        const updatedTableColumns = [...this.getTableColumns()];
         updatedTableColumns.splice(1, 1);
    return updatedTableColumns;
    } else{
        return this.getTableColumns();
    }
 }
    render() {
        const {
            visible,
            modalTitle,
            schemaName,
            schemaTableVisible,
            filteredData,
        } = this.state;
        // const data = this.state.chartData;
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="1">Last 48 hours</Menu.Item>
                <Menu.Item key="2">Last 30 days</Menu.Item>
            </Menu>
        );
        const content =
            "Scroll down to see the list of downtime in last 30 days";
        var that = this,
            showOnlyProd = false;

        if (this.state.dashBoard === true) {
            showOnlyProd = true;
        }

        if (Object.keys(this.props.app_data).length > 0) {
            const data_object = this.props.app_data;
            // TODO: Use Object instead of $ add check in case Object is empty
            const data_array = $.map(data_object, function (value, index) {
                return [value];
            });

            const app_data = [];
            var index = 0;

            data_array.forEach((object) => {
                const classnames = { heartClass: "animated pulse infinite" };
                if (object.display) {
                    var TimeLineItem = [],
                        CarouselItem = [],
                        TimeLineItemColor,
                        numberOfData =
                            object.environment && object.environment.length,
                        enableCount = 0,
                        statusCount = 0,
                        inactiveCount = 0,
                        imageUrl,
                        imageClass = "animated pulse infinite",
                        cardClass = "",
                        disableCount = 0,
                        appName = object.app_name,
                        appId = object.id,
                        currentTime = moment().format(),
                        upTime = object.upTime,
                        availability = object.availability;

                    if (!upTime) {
                        upTime = "0.00";
                    }
                    let cardPulse = false;
                    object.environment.forEach((item) => {
                        var now = moment(new Date()),
                            end = item.lastUpdatedTime
                                ? moment(item.lastUpdatedTime)
                                : now,
                            duration = moment.duration(now.diff(end)),
                            minutes = Math.round(duration.asMinutes());

                        if (!minutes && minutes === 0) {
                            minutes = "Just Now";
                        } else {
                            minutes = `${minutes} mins ago`;
                        }

                        if (item.status === "active" && item.enable) {
                            TimeLineItemColor = "green";
                            enableCount++;
                            statusCount++;
                        } else if (item.status === "inactive" && item.enable) {
                            TimeLineItemColor = "red";
                            inactiveCount++;
                        } else {
                            TimeLineItemColor = "gray";
                            disableCount++;
                        }

                        if (statusCount === numberOfData - disableCount) {
                            imageUrl = greenLogo;
                        } else if (statusCount - inactiveCount > 0) {
                            imageUrl = yellowLogo;
                        }

                        if (numberOfData - disableCount === 0) {
                            imageUrl = grayLogo;
                            imageClass = "";
                            cardClass = "";
                        }
                        if (upTime >= 99) {
                            imageUrl = greenLogo;
                            classnames.heartClass = "animated pulse infinite";
                        }
                        if (upTime >= 96 && upTime < 99) {
                            imageUrl = yellowLogo;
                            classnames.heartClass = "heart";
                            cardPulse = true;
                        }
                        if (upTime < 96) {
                            imageUrl = redLogo;
                            cardPulse = true;
                            classnames.heartClass = "heart";
                        }
                        TimeLineItem.push(
                            <Timeline.Item
                                key={"TimeLineItem stageprod" + index}
                                color={TimeLineItemColor}
                                dot={
                                    <img
                                        src={imageUrl}
                                        className={classnames.heartClass}
                                        alt={"TimeLineItem"}
                                    />
                                }
                            >
                                <p className="card-title1">
                                    <b>{item.name}</b>
                                </p>

                                {/* <h4>
                                    <TimeAgo
                                        datetime={moment().format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )}
                                        locale="en"
                                    />
                                </h4> */}
                                <div
                                    key={"uptime stageprod" + index}
                                    className="n-uptime-percent"
                                >
                                    Data Quality Index - <b>{upTime ? (Number(upTime).toFixed(2)?.includes(.00) ? Number(upTime).toFixed(2)?.split('.')[0] :  Number(upTime).toFixed(2) ) : "NA"} %</b>
                                </div>

                                <div
                                    key={"uptimeValidated stageprod" + index}
                                    className="n-uptime-percent"
                                >
                                    Last Validated -{" "}
                                    <b>
                                        <TimeAgo
                                            datetime={moment(
                                                moment(availability).format(
                                                    "YYYY-MM-DDTHH:mm:ss-0700"
                                                )
                                            )
                                                .tz(moment.tz.guess())
                                                .format()}
                                            locale="en"
                                        />
                                    </b>
                                </div>

                                <a
                                    value={item.type}
                                    name={appId}
                                    title={appName}
                                    onClick={() => that.info(appName)}
                                >
                                    Details
                                </a>
                            </Timeline.Item>
                        );
                        index++;
                    });

                    if (object.dataLink && object.dataLink.length > 0) {
                        object.dataLink.forEach((dLink) => {
                            var dLinkIndex = 0;
                            if (
                                dLink.hasMultipleValues &&
                                dLink.value &&
                                dLink.value.length > 1
                            ) {
                                dLink.label.forEach((label) => {
                                    CarouselItem.push(
                                        <div key={"Carousel" + label}>
                                            <h3 className="n-content-ellipsis">
                                                {" "}
                                                {label}{" "}
                                            </h3>
                                            <h2>
                                                <b>
                                                    {dLink.value[dLinkIndex]
                                                        ? dLink.value[
                                                              dLinkIndex
                                                          ]
                                                        : "N/A"}
                                                </b>
                                            </h2>
                                        </div>
                                    );
                                    dLinkIndex++;
                                });
                            } else {
                                CarouselItem.push(
                                    <div>
                                        <h3 className="n-content-ellipsis">
                                            {dLink.label}{" "}
                                        </h3>
                                        <h2>
                                            <b>
                                                {dLink.value
                                                    ? dLink.value
                                                    : "N/A"}
                                            </b>
                                        </h2>
                                    </div>
                                );
                            }
                        });
                    }

                    let name = object.app_name;
                    if (showOnlyProd) {
                        if (object.type === "prod") {
                            app_data.push({
                                name: name.replaceAll("_", " "),
                                TimeLineItem: TimeLineItem,
                                imageUrl: imageUrl,
                                CarouselItem: CarouselItem,
                                cardClass: cardClass,
                                cardPulse: cardPulse,
                            });
                        }
                    } else {
                        app_data.push({
                            name: name.replaceAll("_", " "),
                            TimeLineItem: TimeLineItem,
                            imageUrl: imageUrl,
                            CarouselItem: CarouselItem,
                            cardClass: cardClass,
                            cardPulse: cardPulse,
                        });
                    }
                }
            });

            return (
                <div>
                    {schemaTableVisible && (
                        <Modal
                            mask={false}
                            title={"Schema:- " + schemaName}
                            open={schemaTableVisible}
                            footer={[
                                <Button
                                    type="primary"
                                    key="modalDismissButton"
                                    onClick={this.handleSyncModalCancel}
                                >
                                    {" "}
                                    Dismiss
                                </Button>,
                            ]}
                            width={700}
                            onCancel={that.handleSyncModalCancel}
                        >
                            <Table
                                columns={this.handleTableColumns()}
                                dataSource={filteredData}
                                size="small"
                                pagination={{
                                    pageSize: 10,
                                    showSizeChanger: false,
                                }}
                                //    pagination={true}
                                locale={{ emptyText: "No Data" }}
                            />
                        </Modal>
                    )}
                    <Modal
                        mask={false}
                        title={modalTitle}
                        open={visible}
                        className="n-chart-modal"
                        footer={[
                            <Button
                                type="primary"
                                key="modalDismissButton"
                                onClick={this.handleCancel}
                            >
                                {" "}
                                Dismiss
                            </Button>,
                        ]}
                        onCancel={that.handleCancel}
                    >
                        {this.state.showCharts && (
                            <div className="n-chart-and-downtime-modal">
                                <div className="n-dropdown">
                                    <Dropdown.Button overlay={menu}>
                                        {this.state.dropdownText}
                                    </Dropdown.Button>
                                </div>

                                <div
                                    className="n-popover"
                                    onScroll={this.hideDownButton}
                                >
                                    <Popover
                                        placement="leftBottom"
                                        content={content}
                                        trigger="hover"
                                        className="n-popover"
                                    >
                                        <DownCircleOutlined />
                                    </Popover>
                                </div>
                                <div className="n-downtime-table">
                                    <Table
                                        {...this.state}
                                        columns={columns}
                                        dataSource={this.state.dataSource}
                                        bordered
                                        locale={{ emptyText: "No Data" }}
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.showLoading && (
                            <div className="n-chart-spin-loader">
                                <Spin />
                            </div>
                        )}
                    </Modal>

                    <Row gutter={18}>
                        {app_data.map((appDataObject, index) => (
                            <Col span={6} key={"col stageprod" + index}>
                                <Card
                                    title={
                                        <CardInfoTitle
                                            title={appDataObject.name}
                                            message={
                                                information[appDataObject.name]
                                            }
                                        />
                                    }
                                    //    title={appDataObject.name}
                                    bordered={true}
                                    className={
                                        appDataObject.cardPulse
                                            ? "tileDown animated pulse infinite"
                                            : ""
                                    }
                                >
                                    <Timeline key={String(appDataObject.name)}>
                                        {appDataObject.TimeLineItem}
                                    </Timeline>
                                    <center></center>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        } else {
            return (
                <Row gutter={24}>
                    <Col span={24}>
                        <Card loading bordered={true}></Card>
                    </Col>
                </Row>
            );
        }
    }
}

TilesDataSyncProdStage.defaultProps = defaultProps;
TilesDataSyncProdStage.propTypes = propTypes;

export default TilesDataSyncProdStage;
