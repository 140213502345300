import { Modal } from "antd";
import React, { Suspense, lazy } from "react";
import {
  DownloadIcon
 } from "@nutanix-ui/prism-reactjs";
 import moment from "moment";
const ViewDetailsTable = lazy(() => import("./ViewDetailsTable/ViewDetailsTable"));

const ViewDetailModal = ({modalTitle, open, onCancel, columns, dataSource, handleDownloadClick,downloadEnable=false,label=''}) => {
  return (
    <Modal
      title={
        <div  style={{display:'flex',justifyContent:'space-between',marginRight:"40px",marginTop:'5px' }}>
        <div style={{ fontSize: "14px" }}>{modalTitle}</div>
        {downloadEnable&&dataSource?.length>0 && <DownloadIcon size= "large" onClick={()=>handleDownloadClick(dataSource,label + '-'+moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))} data-testid="downloadIcon" style={{cursor:'pointer'}}/>}
      </div>}
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      width="90%"
      bodyStyle={{ maxHeight: "80vh", overflow: "auto", padding: "20px" }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ViewDetailsTable columns={columns} dataSource={dataSource} />
      </Suspense>
    </Modal>
  );
};

export default ViewDetailModal;
