import { Line, LineChart, ResponsiveContainer, XAxis, Tooltip } from '@nutanix-ui/recharts'
import React from 'react'
import CustomTooltip from "./CustomTooltip";
const ProductivityLineChart = ({data, height, width, margin, color = "#82ccfa" }) => {
  return (
    <ResponsiveContainer width={width} height={height}
    >
      <LineChart
        data={data}
        margin={margin}
      >
        <XAxis dataKey="name" interval="preserveStartEnd" />
        <Line
          key="value"
          activeDot={true}
          // dot={true}
          type="linear"
          dataKey="value"
          stroke={color}
        />
        <Tooltip
          transferPropsToContent={true}
          placement="top-right"
          content={<CustomTooltip />}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
export default ProductivityLineChart