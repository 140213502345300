// trendSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTrend: ["All"],
  trends: [],
  carryOverData:[],
  productivity:[],
};

const trendSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
      setSelectedTrend: (state, action) => {
        state.selectedTrend = action.payload;
      },
      setTrends: (state, action) => {
        state.trends = action.payload
      },
      setCarryOverData: (state, action) => {
        state.carryOverData = action.payload
      },
      setProductivity: (state, action) => {
        state.productivity = action.payload
      }
    },
  });
  
  export const {
    setTrends,
    setSelectedTrend,
    setCarryOverData,
    setProductivity
  } = trendSlice.actions;
  
  export default trendSlice.reducer;