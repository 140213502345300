import React, { useState, useEffect } from "react";
import {
  Button,
  ChevronLeftIcon,
  Notification,
  NotificationItem,
  NotificationTrigger,
  Pagination,
  RefreshIcon,
  Select,
  Sorter,
  Table,
  Title,
  Tooltip,
} from "@nutanix-ui/prism-reactjs";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  createDataSourceAndHighlightOnSearch,
  fetchInfoData,
  fetchTableData,
} from "./utility";
import { DatePicker } from "@nutanix-ui/prism-reactjs";
import moment from "moment";
import useModal from "../CustomModal/useModal";
import CustomModal from "../CustomModal/CustomModal";
import { Progress } from "antd";

// Helper function to validate and sanitize query params
const validateParam = (value, defaultValue, minValue = 1) => {
  const parsedValue = parseInt(value, 10);
  if (!Number.isNaN(parsedValue) && parsedValue >= minValue) {
    return parsedValue;
  }
  return defaultValue;
};

const presetOptions = [
  { key: 1, label: "Last 7 Days", value: { startDate: moment().subtract(7, "days"), endDate: moment() } },
  { key: 2, label: "Last 15 Days", value: { startDate: moment().subtract(15, "days"), endDate: moment() } },
  { key: 3, label: "Last 30 Days", value: { startDate: moment().subtract(30, "days"), endDate: moment() } },
  { key: 4, label: "Last 60 Days", value: { startDate: moment().subtract(60, "days"), endDate: moment() } },
  { key: 5, label: "Last 6 Months", value: { startDate: moment().subtract(6, "months"), endDate: moment() } },
  { key: 6, label: "Custom Date Range", value: null },
];

const statistics = [
  { key: "total", title: "Total days with shipment", value: 0, color: "#1856ad", data: [] }, 
  { key: "accuracy", title: "Matched days with shipment", value: 0, color: "#15792e", data: [] }, 
  { key: "miss", title: "Missed days with shipment", value: 0, color: "#d02550", data: [] }, 
  { key: "no_shipment", title: "Days with no shipment", value: 0, color: "#586678", data: [] }
];

const createSortState = () => ({
  order: Sorter.SORT_ORDER_CONST.DESCEND,
  column: "ship_date",
  sortable: [
    "ship_date",
    "sfdc_count",
    "factory_eod_file",
    "factory_eod_count",
    "pg_db_count",
    "mongo_db_count",
    "match_result",
    "missing_assets",
  ],
});

const DataTable = ({
  title,
  columns,
  fetchUrl,
  fetchInfoUrl,
  pageSizeOptions = [5, 10, 15, 20],
  defaultPageSize = 15,
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isModalVisible, openModal, closeModal } = useModal(); // Use the custom hook for modal state
  
  const [sort, setSort] = useState(createSortState());

  const [sortModalTable, setSortModalTable] = useState(createSortState());

  const [structure, setStructure] = useState({
    columnWidths: {
      ship_date: "140px",
      sfdc_count: "140px",
      factory_eod_file: "240px",
      factory_eod_count: "140px",
      pg_db_count: "140px",
      mongo_db_count: "140px",
      match_result: "240px",
      missing_assets: "140px",
    },
    columnResize: true,
  });
  // Apply validation on query params
  const [currentPage, setCurrentPage] = useState(
    validateParam(queryParams.get("page"), 1)
  );
  const [pageSize, setPageSize] = useState(
    validateParam(queryParams.get("limit"), defaultPageSize)
  );
  const [searchValue, setSearchValue] = useState(
    queryParams.get("search") || ""
  );
  const [dataSource, setDataSource] = useState([]);
  const [selectedStat, setSelectedStat] = useState({});
  const [modalDataSource, setModalDataSource] = useState([]);
  const [total, setTotal] = useState(100);
  const [statisticsData, setStatisticsData] = useState(statistics);
  const dateRangeDefault = presetOptions[4];
  // Date Range State
  const [selectedPreset, setSelectedPreset] = useState(dateRangeDefault); // Default: Last 6 Months
  const [dateRange, setDateRange] = useState({
    startDate: dateRangeDefault.value?.startDate, // 6 months ago
    endDate: dateRangeDefault.value?.endDate, // Today's date
  });
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "fetchData",
      {
        page: currentPage,
        // search: searchValue,
        column: sort.column,
        order: sort.order === "ascend" ? "asc" : "desc",
        pageSize,
        fetchUrl,
        start_date: dateRange.startDate.format("YYYY-MM-DD"),
        end_date: dateRange.endDate.format("YYYY-MM-DD"),
      },
    ],
    queryFn: fetchTableData,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
  });

  const {
    data: infoDetailsData,
    error: infoDetailsError,
    isLoading: isInfoDetailsLoading,
    refetch: refetchInfoDetails,
  } = useQuery({
    queryKey: [
      "fetchInfoData",
      {
        start_date: dateRange.startDate.format("YYYY-MM-DD"),
        end_date: dateRange.endDate.format("YYYY-MM-DD"),
        fetchInfoUrl,
      },
    ],
    queryFn: fetchInfoData, // Define another function to fetch data
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
  });

  if (error) {
    throw error;
  }

  if (infoDetailsError) {
    throw error;
  }

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   params.set("page", currentPage);
  //   params.set("limit", pageSize);
  //   if (searchValue) {
  //     params.set("search", searchValue);
  //   }
  //   history.replace({ search: params.toString() });
  // }, [currentPage, pageSize, searchValue, history]);

  const handleChangeSearch = (ev) => {
    const value = ev.currentTarget.value;
    setSearchValue(value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const searchProps = {
    inputProps: {
      placeholder: "Type to search...",
      onChange: handleChangeSearch,
      value: searchValue,
    },
  };

  const onChangePagination = (newPage, newPageSize) => {
    setCurrentPage(validateParam(newPage, currentPage));
    setPageSize(validateParam(newPageSize, defaultPageSize));
  };

  const handleRefreshData = async () => {
    // Trigger loading notification before refetch
    const loadingNotificationId = `loadingNotification-${Date.now()}`;
    NotificationTrigger.add({
      id: loadingNotificationId,
      type: NotificationItem.NotificationIconType.INFO,
      message: "Refreshing data...",
      autoDismissDelaySecs: 0, // Keep it visible until manually dismissed
    });
  
    try {
      // Trigger the refetch (assuming refetch is an async function)
      await Promise.all([refetch(), refetchInfoDetails()]);
  
      // Dismiss loading notification and show success notification
      NotificationTrigger.dismiss(loadingNotificationId); // Remove loading notification
      NotificationTrigger.add({
        id: `successNotification-${Date.now()}`,
        type: NotificationItem.NotificationIconType.SUCCESS,
        message: "Data has been refreshed successfully!",
        autoDismissDelaySecs: 5, // Notification will automatically dismiss after 5 seconds
      });
      
    } catch (error) {
      // Handle the error accordingly, e.g., show a failure notification
      NotificationTrigger.add({
        id: `errorNotification-${Date.now()}`,
        type: NotificationItem.NotificationIconType.ERROR,
        message: "Failed to refresh data.",
        autoDismissDelaySecs: 5,
      });
    }
    finally{
      NotificationTrigger.dismiss(loadingNotificationId); // Remove loading notification
    }
  };
  
  

  const handleChangeSort = (newSort) => {
    setSort({
      ...sort,
      column: newSort.column,
      order: newSort.order,
    });
  };

  const handleChangeSortModal = (newSort) => {
    // Extract column and order from newSort
    const { column, order } = newSort;
  
    // Create a sorted copy of modalDataSource
    const sortedData = [...modalDataSource].sort((a, b) => {
      let valA = a[column];
      let valB = b[column];
  
      // Determine sorting logic based on data type
      if (typeof valA === "number" && typeof valB === "number") {
        // Numeric sorting
        return order === "ascend" ? valA - valB : valB - valA;
      } 
      
      else if (typeof valA === "string" && typeof valB === "string") {
        // Check if the value is in 'YYYYMMDD' format (e.g. '20241001')
        if (/^\d{8}$/.test(valA)) {
          // Convert 'YYYYMMDD' to 'YYYY-MM-DD'
          valA = valA.substring(0, 4) + '-' + valA.substring(4, 6) + '-' + valA.substring(6, 8);
          valB = valB.substring(0, 4) + '-' + valB.substring(4, 6) + '-' + valB.substring(6, 8);
          
          valA = new Date(valA);
          valB = new Date(valB);
          return order === "ascend" ? valA - valB : valB - valA;
        }
        
        // Otherwise, do case-insensitive string sorting
        return order === "ascend" ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }
  
      return 0; // Default return for unmatched cases
    });
  
    // Update state with sorted data
    setModalDataSource(sortedData);
  
    // Update sort state
    setSortModalTable({
      ...sortModalTable,
      column,
      order,
    });
  };

  const handleDateRange = (selectedDate) => {
    setDateRange({
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
    });
  };

  // Handle Preset Selection
  const handlePresetChange = (selectedRow) => {
    const preset = presetOptions.find(
      (option) => option.key === selectedRow.key
    );
    setSelectedPreset(preset);
    if (preset.label === "Custom Date Range") {
      setDateRange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    } else {
      setDateRange({
        startDate: preset.value.startDate,
        endDate: preset.value.endDate,
      });
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      const processedData = data?.data || [];
      setDataSource(
        createDataSourceAndHighlightOnSearch(processedData, searchValue)
      );
      setTotal(data?.total || 1);
    }
  }, [data, isLoading, searchValue]);

  useEffect(() => {
    if (infoDetailsData && !isInfoDetailsLoading) {
      setStatisticsData((prevState) =>
        prevState.map((stat) => {
          const total = infoDetailsData["total"]?.value || 0; // Avoid division by zero
          const accuracy = infoDetailsData["accuracy"]?.value || 0;
          const miss = infoDetailsData["miss"]?.value || 0;

          // Ensure percentages are numbers
          const accuracyPercentage = total
            ? parseFloat(((accuracy / total) * 100).toFixed(1))
            : 0;
          const missPercentage =
            miss === 0 ? 0 : parseFloat(((miss / total) * 100).toFixed(1));
          if (stat.key === "accuracy") {
            return {
              ...stat,
              value: infoDetailsData[stat.key]?.value,
              data: infoDetailsData[stat.key]?.list,
              accuracyPercentage: accuracyPercentage,
            };
          } else if (stat.key === "miss") {
            return {
              ...stat,
              value: infoDetailsData[stat.key]?.value,
              data: infoDetailsData[stat.key]?.list,
              missPercentage: missPercentage,
            };
          } else {
            return {
              ...stat,
              value: infoDetailsData[stat.key]?.value,
              data: infoDetailsData[stat.key]?.list,
            };
          }
        })
      );
    }
  }, [infoDetailsData, isInfoDetailsLoading]);
  

  useEffect(() => {
    refetch();
    refetchInfoDetails();
  }, [sort, dateRange]);

  const handleCardClick = (stat) => {
    const selectedStat = statisticsData.find((item) => item.key === stat.key);
    setSelectedStat(selectedStat);
    // If the data for the selected stat is empty, show a notification
    if (!selectedStat.data || selectedStat.data.length === 0) {
      NotificationTrigger.add({
        id: `emptyStatNotification-${Date.now()}`,
        type: NotificationItem.NotificationIconType.WARNING,
        message: `No data available for ${selectedStat.title}.`,
        autoDismissDelaySecs: 5, // Notification will automatically dismiss after 5 seconds
        minMessageLineCount: 5,
      });
      return;
    }
    openModal();
    setModalDataSource(
      createDataSourceAndHighlightOnSearch(selectedStat.data, searchValue)
    );
  };
  
  const handleChangeStructure = (newStructure) => {
    setStructure(newStructure);
  };

  return (
    <>
      <div className="widgetHeader">
        <div className="factory_left_container">
          <Tooltip
            content="Go to Data Health page"
            position="bottom" // Tooltip position at the bottom
            ariaLabel="Navigate to Data Health"
            oldTooltip={false}
            appearance={Tooltip.APPEARANCE.SECONDARY}
            placement={"right"}
            size="mini"
          >
            <Button
              type={Button.ButtonTypes.TEXT_NORMAL}
              aria-label="Go back to previous page"
              onClick={() => history.push("/datasync")}
            >
              <ChevronLeftIcon aria-hidden="true" /> Back
            </Button>
          </Tooltip>
          <span className="vertical_line"></span>
          <span>{title}</span>
        </div>
        {/* <h2>{title}</h2> */}
        <div className="factory_right_container">
          <Tooltip
            content="Choose Date Range"
            position="bottom" // Tooltip position at the bottom
            ariaLabel="Refresh Tooltip"
            oldTooltip={false}
            appearance={Tooltip.APPEARANCE.SECONDARY}
            placement={"top"}
            size="mini"
          >
            <Select
              rowsData={presetOptions}
              selectedRow={selectedPreset}
              onSelectedChange={handlePresetChange}
            />
          </Tooltip>
          <span className="vertical_line"></span>
          <DatePicker
            name="factory_data_datepicker"
            oldDatePicker={false}
            defaultValue={{
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            }}
            value={{
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            }}
            onChange={handleDateRange}
            disabled={selectedPreset.label !== "Custom Date Range"}
          />
          <span className="vertical_line"></span>
          <Pagination
            currentPage={currentPage}
            onChange={onChangePagination}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            pageSizeOptionLabel="items"
            total={total}
          />
          <span className="vertical_line"></span>
          <Tooltip
            content="Click to Refresh Table"
            position="bottom" // Tooltip position at the bottom
            ariaLabel="Refresh"
            oldTooltip={false}
            appearance={Tooltip.APPEARANCE.SECONDARY}
            placement={"bottom"}
            size="mini"
          >
            <Button
              type={Button.ButtonTypes.TEXT_NORMAL}
              onClick={handleRefreshData}
            >
              Refresh
              <RefreshIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="factory_statistic">
        {statisticsData.map((stat) => (
          <div
            key={stat.key}
            className={`stat_card`}
            onClick={() => handleCardClick(stat)}
          >
            <Title className="title_stat">
              {stat.title}
            </Title>
            <div className="value_stat_cont">
              <Title
                size="h1"
                className="value_stat"
                style={{ color: stat.color }}
              >
                {stat.value}
              </Title>
              {stat.key === 'accuracy' && <Progress
                type="circle"
                percent={stat.accuracyPercentage}
                width={40} // 👈 Custom size
                format={(percent) => (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    {percent}%
                  </span>
                )}
              />}
              {stat.key === 'miss' && <Progress
                type="circle"
                percent={stat.missPercentage}
                width={40} // 👈 Custom size
                format={(percent) => (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    {percent}%
                  </span>
                )}
              />}
            </div>
          </div>
        ))}
      </div>
      <Table
        showCustomScrollbar={true}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        loadingError={!!error}
        onRefreshData={handleRefreshData}
        sort={sort}
        structure={structure}
        onChangeStructure={handleChangeStructure}
        onChangeSort={handleChangeSort}
        wrapperProps={{
          "data-test-id": "factoryData",
        }}
      />
      {
        <Notification>
          <div
            style={{
              position: "fixed",
              top: "80px",
              left: "40%",
            }}
          ></div>
        </Notification>
      }
      {
        <CustomModal
          isVisible={isModalVisible}
          header={selectedStat.title}
          body={
            <Table
              columns={columns}
              dataSource={modalDataSource}
              structure={structure}
              sort={sortModalTable}
              onChangeSort={handleChangeSortModal}
              onChangeStructure={handleChangeStructure}
            />
          }
          footer={null}
          onClose={closeModal}
          className={"factory_modal"}
        />
      }
    </>
  );
};

export default DataTable;
