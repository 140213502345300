import {
  Table,
  Tabs,
  FlexLayout,
  TextLabel,
  Badge,
} from "@nutanix-ui/prism-reactjs";
import { uniqueId } from "lodash";
import { useMemo, useContext } from "react";
import LeoContext from "../../../contexts/leoContexts/leoContext";

// Currency formatter function
function formatCurrency(amount) {
  if (amount === 0) {
    return "$0";
  } else {
    // Convert amount to a number with two decimal places
    const formattedAmount = parseFloat(amount).toFixed(2);
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedAmount.split(".");
    // Add commas for thousand separators
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Return the formatted currency string
    return `$${formattedIntegerPart}.${decimalPart}`;
  }
}

const ExpensifyFlighsDetailsTable = ({ data, expenseFlightTotal, expenseTotal, flightTotal}) => {
  const overflowCount = 100000; // overflow from 100000 data
  const { loading, activeDetails, sort, setSort, setActiveDetails, setCurrentSelection } = useContext(LeoContext);
  const columns = [
    {
      title: "Date",
      key: "transaction_date",
    },
    {
      title: "Name",
      key: "employee_name",
    },
    {
      title: "Expense ID",
      key: "expense_id",
    },
    {
      title: "Type",
      key: "expense_type",
    },
    {
      title: "Category",
      key: "category",
    },
    {
      title: "Description",
      key: "user_comment",
    },
    {
      title: "Amount USD",
      key: "amount_usd",
      render: (text) => `${formatCurrency(text)}`,
    },
  ];
  const tabsData = [
    {
      title: (
        <FlexLayout alignItems="center" itemSpacing="10px">
          <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
            Expenses & Flights
          </TextLabel>
          <Badge type={ Badge.BadgeTypes.TAG } count={ expenseFlightTotal > 0 ? expenseFlightTotal : 0 } overflowCount={ overflowCount } />
        </FlexLayout>
      ),
      key: "1",
    },
    {
      title: (
        <FlexLayout alignItems="center" itemSpacing="10px">
          <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
            Expenses
          </TextLabel>
          <Badge type={ Badge.BadgeTypes.TAG } count={ expenseTotal > 0 ? expenseTotal : 0 } overflowCount={ overflowCount } />
        </FlexLayout>
      ),
      key: "2",
    },
    {
      title: (
        <FlexLayout alignItems="center" itemSpacing="10px">
          <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
            {" "}
            Flights
          </TextLabel>
          <Badge type={ Badge.BadgeTypes.TAG } count={ flightTotal > 0 ? flightTotal : 0 } overflowCount={ overflowCount } />
        </FlexLayout>
      ),
      key: "3",
    },
  ];

  const panelIdPrefix = useMemo(() => uniqueId("tabs-panel-"), []);
  const customHeader = (
    <Tabs
      adaptive={true}
      data={tabsData}
      activeKey={`${activeDetails}`}
      onTabClick={(item) => setActiveDetails(item)}
      defaultActiveKey="1"
      // padding="0px-15px"
      panelIdPrefix={panelIdPrefix}
    />
  );

  const structure = {
    bodyMinWidth: "100%",
    columnWidths: {
    },
    // },
  };

  const searchHandle = (event) => {
    setCurrentSelection((prevState) => ({
      ...prevState,
      searchExpenseAndFlight: event.target.value,
    }));
  };

  const handleSortChange = (newSort) => {
    let sortKey = "";
    if (activeDetails === '1') {
      sortKey = "expenseFlight";
    } else if (activeDetails === '2') {
      sortKey = "expense";
    } else {
      sortKey = "flight";
    }
  
    setSort((prevState) => ({
      ...prevState,
      expenseFilghtDetails: {
        ...prevState.expenseFilghtDetails,
        [sortKey]: {
          ...prevState.expenseFilghtDetails[sortKey],
          order: newSort.order,
          column: newSort.column
        }
      }
    }));
  };
  
  return (
    <Table
      dataSource={ data }
      columns={ columns }
      sort={ activeDetails === '1' ? sort.expenseFilghtDetails.expenseFlight : activeDetails === '2' ? sort.expenseFilghtDetails.expense : sort.expenseFilghtDetails.flight }
      onChangeSort={ handleSortChange }
      loading={ loading.expenseDetailLoading || loading.flightDetailLoading }
      customHeader={ customHeader }
      structure={ structure }
      search={{
        inputProps: {
          placeholder: "Search by Employee",
          onChange: searchHandle,
          disabled: false,
        },
      }}
    />
  );
};
export default ExpensifyFlighsDetailsTable;
