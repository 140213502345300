import React, { useContext, useEffect } from "react";
import LeoContext from "../../../contexts/leoContexts/leoContext";
import {
  generateDivisionMenu,
  generateTimeRangeMenu,
  generateCostCenterMenu,
} from "../utils";

import ExpenseFlightTab from "./TabContent/ExpenseFlightTab";
import TEDashboardTab from "./TabContent/TEDashboardTab";
import { Notification } from "@nutanix-ui/prism-reactjs";
import SessionExpiredScreen from "../../SessionExpired/SessionExpiredScreen";

const Main = () => {
  const { filters, activeTab, setCurrentSelection,visibleTokenExpired } = useContext(LeoContext);
  const timeRangeMenus = generateTimeRangeMenu(filters.timeRanges);
  const divisionMenus = generateDivisionMenu(filters.divisions);
  const costCenterMenus = generateCostCenterMenu(filters.costCenters);

  useEffect(() => {
    if (timeRangeMenus.length > 0) {
      setCurrentSelection((prevState) => ({
        ...prevState,
        timeRange: timeRangeMenus[0],
        teTimeRange: timeRangeMenus[0],
        division: divisionMenus[0],
        costCenter: costCenterMenus[0],
      }));
    }
  }, [filters.timeRanges]);

  return (
    <>
      <div className="mainContainer">
        {activeTab === 1 && <ExpenseFlightTab />}
        {activeTab === 2 && <TEDashboardTab />}
        {
          <Notification>
            <div
              style={{
                position: "fixed",
                top: "80px",
                right: "340px",
              }}
            />
          </Notification>
        }
      </div>
      {
        visibleTokenExpired && <SessionExpiredScreen />
      }
    </>
  );
};

export default Main;
