import React, { useEffect, useState } from "react";
import { NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import ETLListTable from "./ETLlistTable";
import Header from "./ETLpipelineHeader";
import "./style.css";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { SERVER_URL } from "../../config/config";

import { useNextGenCreateProject } from '../../hooks'
import { sendUserRecord } from "../../Service/adoptionMetric";
export default function ETLPipelineListing() {
  const { setProjectDetails } = useNextGenCreateProject()
  const location = useLocation();
  const [selectedProject, setSelectedProject] = useState({
    key: 0,
    label: "Select Project",
    value: "select",
  });

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [PipelineName, setPipeLineName] = useState("");

  const handleProjectIDChange = (item) => {
    setSelectedProject((prevState) => item);
    setLoading(true);
    fetchETLList(item.value);
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setSelectedProject({ label: location.state?.Project?.label, value: location.state?.Project?.value });
      setLoading(true);
      fetchETLList(location.state?.Project?.value);
      setVisible(location.state?.addPipeLineVisible)
    }
    setProjectDetails({'project_id':location.state?.Project?.value})// eslint-disable-next-line 
  }, [location?.state?.Project]);

  useEffect(() => {
    sendUserRecord('paas', window.location.href)
        .then(resp => { })
        .catch(err => { })
}, [])


  const [listingData, setListingData] = useState([
    {
      key: 1,
      status: true,
      pipeline: "Pipeline Demo",
      created: "12-Dec-2024",
    },
  ]);


  const umail = localStorage.getItem("email");

  const fetchETLList = (data) => {
    axios
      .post(`${SERVER_URL}/fetchETLPipeline`, {
        project_id: data,
        user:umail
      })
      .then((resp) => {
        constructListingData(resp?.data?.data?.res);
        setUserRole(resp?.data?.data?.user_role || "")
        setLoading(false);
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "upload success",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 90,
        });
      });
  };

  const constructListingData = (data) => {
    let obj = [];
    let key = 0;
    if (data && data.length > 0) {
      data.forEach((etl) => {
        obj.push({
          key: ++key,
          status: etl[8],
          pipeline: etl[1],
          pipeID: etl[0],
          created: etl[4],
          ProcessCount: etl[5],
          scheduleType: etl[2],
          alertsEnabled: etl[6],
          etlStatusLink: etl[9],
          deployed: etl[3]
        });
      });
    }
    setListingData((prevState) => obj);
  };
  return (
    <div>
      <Header
        projectData={ {
          key: 0,
          label: "Select Project",
          value: "select",
        }}
        selectedProject={selectedProject}
        handleProjectIDChange={handleProjectIDChange}
        loading={false}
        projectID={selectedProject.value}
        PipelineName={""}
      />
      <ETLListTable
        columnData={listingData}
        loading={loading}
        selectedProject={selectedProject}
        visible={visible}
        setVisible={setVisible}
        PipelineName={PipelineName}
        setPipeLineName={setPipeLineName}
        fetchETLList={fetchETLList}
        userRole={userRole}
      />
    </div>
  );
}
