import { useState, useEffect } from "react";
import React from 'react';
import { AndroidOutlined, AppleOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Space } from "antd";
import "./NextGenEtl.css";

const TeamMembersInput = (props) => {
    const { options, width, defaultValue, placeholder, mode, style, handleChange, value } = { ...props };
    const { Option } = Select;

    return (

        <Select
            mode={mode}
            style={{
                width: width, ...style
            }}
            placeholder={placeholder ? placeholder : "Please Select"}
            showSearch={true}
            defaultValue={defaultValue}
            onChange={handleChange}
            optionLabelProp="label"
            options={options}
            value={value}
        />


    );
};

export default TeamMembersInput;
