import React from "react";
import "./FilterDropdown.css";
import { Select, Tooltip } from "antd";

const FilterDropdown = ({ ...dropdown }) => {
  const { items, label, mode, onChangeItem, selectedItem, handleClick, loading, id } = dropdown;
  const selectProps = {
    mode: mode,
    style: {
      width: "100%",
    },
    showArrow: true,
    value: selectedItem,
    options: items,
    onChange: (newValue) => {
      // loggerService({
      //   module: "jira_ssot",
      //   msg: `${label} got changed to ${newValue}`,
      //   logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
      //   eventName: "onchange",
      //   url: window.location.href,
      // });
      onChangeItem(newValue);
    },
    maxTagCount: "responsive",
    onClick: handleClick,
    loading: loading,
    "aria-label": `${id}-dropdown`
  };
  return (
    <div className="filter-container" data-testid="filter-dropdown">
      {label && <p data-testid={`filter-label-${id}`}>{label}</p>}
      <Select 
        {...selectProps} 
        maxTagPlaceholder={(omittedValues) => (<Tooltip title={omittedValues.map(({ label }) => label).join(', ')}><span>+{omittedValues.length}</span></Tooltip>)} 
        className="filterSelect" 
        data-testid={`${id}-filter-select`}
      />
    </div>
  );
};

export default FilterDropdown;