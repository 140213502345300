import axios from "axios";

const formatDate = (value, key) => {
  // Extract the year, month, and day from the numeric string
  const year = Number(value.slice(0, 4));  // First 4 characters are the year
  const month = Number(value.slice(4, 6)); // Next 2 characters are the month
  const day = Number(value.slice(6, 8));   // Last 2 characters are the day

  // Create a date object
  const date = new Date(year, month - 1, day);

  // Get month name in short format (e.g., "Aug")
  const monthShort = date.toLocaleString("en-US", { month: "short" });
  if(key === 'short'){
    return `${day} ${monthShort}`
  }else{
    return `${day} ${monthShort}, ${year}`;
  }
  // Construct the custom date format: "24 Aug, 2024"
};

// Render HTML safely
const renderHTML = (html) => ({ __html: html });

const highlightText = (text, search) => {
  if (!search) return text;
  if (typeof text !== "string") text = String(text); // Convert non-string to string

  console.log("search", search);

  const regex = new RegExp(`(${search})`, "gi");
  return text.replace(
    regex,
    '<span style="background-color: yellow; font-weight: bold;">$1</span>'
  );
};

const createDataSourceAssetModal = (data) => {
  return data.map((item, index) => {
    const newItem = { ...item };
    return {
      ...newItem,
      key: index + 1,
    };
  });
};

const createDataSourceAndHighlightOnSearch = (data, search) => {
  return data.map((item, index) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      newItem[key] = highlightText(
        newItem[key] === null
          ? "NA"
          : newItem[key],
        search
      );
    });
    return {
      ...newItem,
      key: index + 1,
    };
  });
};

const fetchTableData = async ({ queryKey }) => {
  const { page, pageSize, search, column, order, fetchUrl } = queryKey[1];
  const response = await axios.get(
    `${fetchUrl}?page=${page}&limit=${pageSize}&column=${column}&order=${order}&search=${encodeURIComponent(
      search
    )}`
  );
  const { data } = response;
  return data;
};

const getCompletionPercentage = (sfdc_count, factory_eod_count) => {
  // Check if both counts are zero
  if (sfdc_count === 0 && factory_eod_count === 0) {
    return {
      matched: 100,  // Consider it fully matched
      unmatched: 0,
    };
  }

  // Check if denominator is zero to avoid division by zero
  if (factory_eod_count === 0) {
    return {
      matched: 0,
      unmatched: 100,  // If factory_eod_count is zero and sfdc_count is not zero, we consider everything unmatched
    };
  }

  const matchedPercentage = (factory_eod_count/ sfdc_count  ) * 100;
  const unmatchedPercentage = 100 - matchedPercentage;
  return {
    matched: matchedPercentage.toFixed(0),  // Rounding to two decimal places for better readability
    unmatched: unmatchedPercentage.toFixed(0),
  };
}


export {
  renderHTML,
  createDataSourceAndHighlightOnSearch,
  formatDate,
  createDataSourceAssetModal,
  fetchTableData,
  getCompletionPercentage,
};