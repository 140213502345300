import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "@nutanix-ui/recharts";
import { useDispatch, useSelector } from "react-redux";
import { TextLabel } from "@nutanix-ui/prism-reactjs";
import { Badge } from "antd";
import { setColumnData, setDataSourceData, setOpenModal } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { CartesianGridColor, getStageColumn, getStageDataSource, graphHeight, transformAndCalculatePercentagesJira, useStageDetail, labelStyle } from "../../EngineeringUtils";
import NoData from "./NoData";

const JiraComparison = ({ margin, height }) => {
  const dispatch = useDispatch()
  const { defaultView, jiraComparisonData, filters, jiraDateRange } = useSelector(
    (state) => state.enggDashboard
  );

  const [param, setParam] = useState(null);
  const [enabled, setEnabled] = useState(false);
  
  const handleBarClick = (data) => {
    setParam(data.name);
    setEnabled(true);
  };
  const { data:stageData, isLoading } = useStageDetail(filters, enabled, 'cycle_time_stages_jira_comparison', param, jiraDateRange)

  useEffect(() => {
    if(enabled && !isLoading && stageData.length > 0){
      dispatch(setColumnData(getStageColumn()))
      dispatch(setDataSourceData(getStageDataSource(stageData)))
      dispatch(setOpenModal(true));
      setEnabled(false)
    }
  }, [stageData, enabled, isLoading])

  const filteredData = jiraComparisonData.filter(item => filters.selectedJiraIssues.includes(item.issuenum));
  
  const data = transformAndCalculatePercentagesJira(filteredData, filters);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <TextLabel
            display="block"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            {label}
          </TextLabel>
            <div class="grid-container">
              <div class="grid-item bold">Stages</div>
              <div class="grid-item bold center-aligned">Days</div>
              <div class="grid-item bold center-aligned">% of Total</div>
              <div class="grid-item">
                <Badge color="#F55656" text="Blocked" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Blocked"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["BlockedPERCENTAGE"] + '%'}
              </div>
              <div class="grid-item">
                <Badge color="#F0CE75" text="Development" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Development"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["DevelopmentPERCENTAGE"]+'%'}
              </div>
              <div class="grid-item">
                <Badge color="#3DAEF5" text="Code Review" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Code Review"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["Code ReviewPERCENTAGE"]+'%'}
              </div>
              <div class="grid-item">
                <Badge color="#596CA6" text="QA Testing" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["QA Testing"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["QA TestingPERCENTAGE"]+'%'}
              </div>
              <div class="grid-item">
                <Badge color="#8974F0" text="Business UAT" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Business UAT"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["Business UATPERCENTAGE"]+'%'}
              </div>
              <div class="grid-item">
                <Badge color="#93D1B2" text="QA/UAT Done" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["QA/UAT Done"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["QA/UAT DonePERCENTAGE"]+'%'}
              </div>
              <div class="grid-item">
                <Badge color="#3D8F8F" text="Deployment" />
              </div>
              <div class="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Deployment"] : 0}
              </div>
              <div class="grid-item center-aligned">
                {payload[0].payload["DeploymentPERCENTAGE"]+'%'}
              </div>
            </div>
        </div>
      );
    }

    return null;
  };

  const formatLabelText = (value) => {
    return `${value}${
      defaultView.view_days ? "" : "%"
    }`;
  };


  const getColor = (stage) => {
    const colorMap = {
      "Blocked": "#F55656",
      "Development": "#F0CE75",
      "Code Review": "#3DAEF5",
      "QA Testing": "#596CA6",
      "Business UAT": "#8974F0",
      "QA/UAT Done": "#93D1B2",
      "Deployment": "#3D8F8F",
    };
    return colorMap[stage];
  };

  const renderBar = (stage) => (
    <Bar
      key={stage}
      barGap={30}
      dataKey={defaultView.view_days ? stage : `${stage}PERCENTAGE`}
      stackId="a"
      fill={getColor(stage)}
      onClick={(data) => handleBarClick(data)}
    >
      {!defaultView.default && (
        <LabelList
          dataKey={defaultView.view_days ? stage : `${stage}PERCENTAGE`}
          position="right"
          style={labelStyle}
          formatter={formatLabelText}
        />
      )}
    </Bar>
  );
  
  return (
    <ResponsiveContainer width="100%" height={graphHeight}>
      {data?.length > 0 ? (
        <BarChart
          data={data}
          barSize={30} // Adjust the gap between bars as needed
          barCategoryGap={10}
          alignment="none"
          margin={margin}
        >
          <CartesianGrid stroke={CartesianGridColor} />
          <XAxis tickLine={false} dataKey="name" />
          <YAxis
            tickLine={false}
            axisLine={false}
            allowDataOveflow={true}
            tickFormatter={(value) => {
              if (value === 0) {
                return 0;
              }
              return defaultView.view_days ? value : `${value}%`;
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {["Blocked", "Development", "Code Review", "QA Testing", "Business UAT", "QA/UAT Done", "Deployment"].map(renderBar)}
        </BarChart>
       ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default JiraComparison;
