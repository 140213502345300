import { Title } from "@nutanix-ui/prism-reactjs";
import React from "react";
import CardContainer from "./Status/CardContainer";

const RMEStatus = ({ applyClicked }) => {
  return (
    <>
      <Title className="titleText m-20">
        Statistics
      </Title>
      <CardContainer applyClicked={applyClicked} />
    </>
  );
};

export default RMEStatus;
