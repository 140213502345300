import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "@nutanix-ui/recharts";
import { renderTooltip } from "../../utils";
import NoData from "./NoData";

const ExpensifyByCategory = ({ categories, margin }) => {
  const [tooltip, setTooltip] = useState(null);
  if (categories.length <= 0) {
    return <NoData height="304px" />;
  }

  const CustomAxisTick = ({ x, y, payload }) => {
    const maxTextWidth = 220; // Maximum width for text before wrapping
    return (
      
        <g transform={`translate(${x},${y})`} style={{ width: "100%" }}>
          <foreignObject x={-220} y={-10} width={maxTextWidth} height={20}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "14px",
                textAlign: "right",
                color: "rgb(154, 165, 181)",
              }}
            >
              {payload.value}
            </div>
          </foreignObject>
        </g>
    );
  };
  return (
    <>
      <ResponsiveContainer height={388} width="100%">
        <BarChart
          data={categories}
          alignment="none"
          layout="vertical"
          margin={margin}
          onMouseLeave={() => setTooltip(null)}
        >
          <CartesianGrid vertical={true} horizontal={false} stroke="#eee" />
          <XAxis
            tickline={true}
            axisLine={false}
            type="number"
            tickFormatter={(value) => {
              if (value === 0) {
                return value;
              }
              const formattedValue = (value / 1000).toFixed(2);
              return formattedValue.endsWith(".00")
                ? `$${parseInt(formattedValue)}k`
                : `$${formattedValue}k`;
            }}
          />
          <YAxis
            dataKey="name"
            type="category"
            allowDataOveflow={true}
            width={220}
            onMouseEnter={(event) => {
              setTooltip(event);
            }}
            onMouseLeave={() => setTooltip(null)}
            tick={<CustomAxisTick />}
          />
          <Tooltip
            formatter={(value, name) => {
              const formattedValue = Number(value).toFixed(2);
              const [integerPart, decimalPart] = formattedValue.split(".");
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              const formattedTooltipValue = `$${formattedIntegerPart}.${decimalPart}`;
              return decimalPart === "00"
                ? [formattedIntegerPart, name]
                : [formattedTooltipValue, name];
            }}
          />
          <Bar dataKey="amount" fill="#59BCF9" barSize={20} barGap={5} />
        </BarChart>
      </ResponsiveContainer>
      {tooltip && renderTooltip(tooltip)}
    </>
  );
};

export default ExpensifyByCategory;
