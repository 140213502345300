import React, {  useContext, useEffect } from "react";
import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";
import _ from "lodash";

import AamzonS3Modal from './AmazonS3Modal'
import MongoDBModal from './MongoDBModal'
import SnowFlakeModal from './SnowFlakeModal'
import PostGresModal from './PostGresModal'
import ConnectorProcessForm from './ConnectorProcessForm'
export default function FormModal(props) {

  const { schema, origin } = props;
  const {pipelineData} = useContext(ETLCanvasContext);
  const {setPipelineData} = useContext(ETLCanvasContextDispatcher);
  const queryParameters = new URLSearchParams(window.location.search);
  const projectID = queryParameters.get("projectID");
  const pipeLineID = queryParameters.get("pipelineID");
  useEffect(() => {
    setPipelineData({
      ...pipelineData,
      pipelineID: pipeLineID,
      project_id: projectID,
    });// eslint-disable-next-line 
  }, []);
  const handleOnSave = (
    data,
    schemaRowsData,
    noSQLSchemaRows,
    selectedColumns,
    section
  ) => {
    if (section === "Source") {
      const schemaData = JSON.stringify(schemaRowsData);
      const sourceSchemaList = _.cloneDeep(pipelineData?.SourceSchema || {});
      const newData = _.cloneDeep(data);
      const parseSchema = JSON.parse(schemaData) || [];
      const schemaKey = Math.floor((Math.random() * 100) + 1);
      newData.schema_mapping = parseSchema?.length > 0 ? schemaData : newData.schema_mapping;
      newData.dbType = schema.dbType;
      newData.query = {
        projectID: projectID,
        pipeLine: {
          pipeID: pipeLineID,
          queryData: { value: newData.query ? btoa(newData.query):'' },
        },
      };
      const columnsData = selectedColumns?.map(item => item.label).join(",");
      newData.columns = columnsData;
      newData.columnsData = selectedColumns;

      const newSchema = { ...schema, key: schemaKey, formData: newData };

      if (schema && schema?.value && sourceSchemaList[schema.value]) {
        sourceSchemaList[schema.value].push(newSchema);
      } else {
        sourceSchemaList[schema.value] = [newSchema];
      }

      setPipelineData(prevState => ({
        ...prevState,
        SourceSchema: sourceSchemaList,
        schema_mapping: schemaData,
      }));
    } else if (section === "Target") {
      const targetSchemaList = _.cloneDeep(pipelineData?.TargetSchema || {});

      const newData = _.cloneDeep(data);

      const schemaKey = Math.floor((Math.random() * 100) + 1);

      newData.query = {
        projectID: projectID,
        pipeLine: {
          pipeID: pipeLineID,
          queryData: { value: btoa(newData.query) },
        },
      };
      newData.dbType = schema?.dbType || '';

      const columnsData = selectedColumns?.map(item => item.label).join(",");
      newData.columns = columnsData;
      newData.columnsData = selectedColumns;

      const newSchema = { ...schema, key: schemaKey, formData: newData };

      if (schema && schema?.value && targetSchemaList[schema.value]) {
        targetSchemaList[schema.value].push(newSchema);
      } else {
        targetSchemaList[schema.value] = [newSchema];
      }

      setPipelineData(prevState => ({
        ...prevState,
        TargetSchema: targetSchemaList,
      }));
    }
    props.handleOnClose();
  };
  const handleS3OnSave = (data, schemaMapping) => {
    if (origin === "Source") {
      const sourceSchemaList = _.cloneDeep(pipelineData?.SourceSchema || null);
      const schemaKey = Math.floor((Math.random() * 100) + 1);
      if (schema && schema?.value) {
        const newData = _.cloneDeep(data);
        if (sourceSchemaList[schema?.value]) {
          newData.schema_mapping = schemaMapping && schemaMapping?.file_schema.length>0 ? JSON.stringify(schemaMapping) : '[]';
          newData.dbType = schema?.dbType;
          const newSchema = { ...schema, key: schemaKey, formData: newData };

          sourceSchemaList[schema?.value].push(newSchema);
          setPipelineData({
            ...pipelineData,
            SourceSchema: sourceSchemaList,
          });
        } else {
          newData.dbType = schema?.dbType;
          newData.schema_mapping = schemaMapping && schemaMapping?.file_schema.length>0 ? JSON.stringify(schemaMapping) : '[]';
          const newSchema = { ...schema, key: schemaKey, formData: newData };
          pipelineData.SourceSchema[schema?.value] = [];
          pipelineData.SourceSchema[schema?.value].push(newSchema);
          setPipelineData({ ...pipelineData });
        }
      }
    }
    props.handleOnClose();
  };
  const handleMongoOnSave = (data, schemaMapping) => {
    if (origin === "Source") {
      const sourceSchemaList = _.cloneDeep(pipelineData?.SourceSchema || null);
      const newData = _.cloneDeep(data);
      const schemaKey = Math.floor((Math.random() * 100) + 1);
      if (schema && schema?.value) {
        newData.schema_mapping = schemaMapping && schemaMapping?.file_schema.length>0 ? JSON.stringify(schemaMapping) : '[]';
        newData.dbType = schema?.dbType || "No Sql";
        const newSchema = { ...schema, key: schemaKey, formData: newData };
  
        if(sourceSchemaList[schema?.value]){
          sourceSchemaList[schema?.value]?.push(newSchema);
        }else{
          sourceSchemaList[schema?.value]=[]
          sourceSchemaList[schema?.value]?.push(newSchema);
        }
        setPipelineData({
          ...pipelineData,
          SourceSchema: sourceSchemaList,
        });
    }
    }
    props.handleOnClose();
  };

  const handleconfigProcessorSave = (data, schemaMapping,selectedColumns) => {
    if (origin === "Source") {
      const sourceSchemaList = _.cloneDeep(pipelineData?.SourceSchema || null);
      const newData = _.cloneDeep(data);
      const schemaKey = Math.floor((Math.random() * 100) + 1);
      if(newData?.query?.length>0){
        newData.query = {
          projectID: projectID,
          pipeLine: {
            pipeID: pipeLineID,
            queryData: { value: btoa(newData.query) },
          },
        };
      }
      newData.schema_mapping =  schemaMapping  ? JSON.stringify(schemaMapping) : '[]';
      newData.dbType = schema.dbType;
      const columnsData = selectedColumns?.map(item => item.label).join(",");
      newData.columns = columnsData;
      newData.columnsData = selectedColumns;

      const newSchema = { ...schema, key: schemaKey, formData: newData };

      if (schema && schema?.value && sourceSchemaList[schema.value]) {
        sourceSchemaList[schema.value].push(newSchema);
      } else {
        sourceSchemaList[schema.value] = [newSchema];
      }

      setPipelineData(prevState => ({
        ...prevState,
        SourceSchema: sourceSchemaList
      }));
    }
    props.handleOnClose();
  };
  if (schema?.label === "snowflake") {
    return (
      <SnowFlakeModal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        handleOnSave={handleOnSave}
      />
    );
  }

  if (schema?.label === "postgres" || schema?.label === "mysql" || schema?.label === "dremio") {
    return (
      <PostGresModal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        handleOnSave={handleOnSave}
      />
    );
  }
  if (schema?.label === "s3") {
    return (
      <AamzonS3Modal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        handleS3OnSave={handleS3OnSave}
      />
    );
  }
  if (schema?.label === "mongo") {
    return (
      <MongoDBModal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        handleMongoOnSave={handleMongoOnSave}
      />
    );
  }
  if (schema?.label === "sfdc" || schema?.label === "sftp" || schema?.label === "netsuite") {
    return (
      <ConnectorProcessForm
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        handleconfigProcessorSave={handleconfigProcessorSave}
      />
    );
  }
}


