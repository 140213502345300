import React, { useContext, useMemo } from "react";
import DetailsStatus from "./DetailsStatus";
import ExpensifyComparison from "./Graphs/ExpensifyComparison";
import ExpensifyGrowth from "./Graphs/ExpensifyGrowth";
import ExpensifyHorizontalComparison from "./Graphs/SpendersGraph";
import ExpensifyByCategory from "./Graphs/ExpensifyByCategoryGraph";
import LeoContext from "./../../../contexts/leoContexts/leoContext";
import ExpensifyFlighsDetailsTable from "./ExpensifyFlightsDetailsTable";
import { getPaginatedData, processCategory, processExpenseReports } from "../utils";

const Body = ({
  id,
  expenseDetails,
  flightDetails,
  expenseAndFlightDetails,
  detailsPageSize
}) => {
  const { activeSpenders, expenseReports, flightReports, expenseCategory, currentSelection, activeDetails } = useContext(LeoContext);
  const { topSpendersExpenses, topSpendersFlights, topSpendersAggregates } = processExpenseReports(expenseReports, flightReports);
  const categories = processCategory(expenseCategory);
  const margin = {top: 20, right: 20, bottom: 20, left: 20}

  // console.log("TOP SPENDERS: POINT_API_DATA_EXPENSE_FLIGHT", expenseReports, flightReports);
  // console.log("TOP SPENDERS: POINT_TRANFORMED_EXPENSE_FLIGHT", topSpendersExpenses, topSpendersFlights);
  // console.log("TOP SPENDERS: POINT_AGGREGATED_EXPENSE_FLIGHT", topSpendersAggregates);
  // console.log("TOP SPENDERS: POINT_API_DATA_CATEGORIES", expenseCategory);
  // console.log("TOP SPENDERS: POINT_API_TRANFORMED_CATEGORIES", categories);
  // console.log("TOP SPENDERS: POINT_API_TRANFORMED_DETAILS", expenseDetails,
  // Define the useMemo outside the condition
  const MemoizedExpensifyFlighsDetailsTable = useMemo(() => {
    if (id === 'expensesDetails') {
      return (
        <ExpensifyFlighsDetailsTable 
          data={ activeDetails === '1' ? 
            getPaginatedData(expenseAndFlightDetails, currentSelection.expenseAndFlightPagination.expenseFlightDetail, detailsPageSize) : 
            activeDetails === '2' ? 
            getPaginatedData(expenseDetails, currentSelection.expenseAndFlightPagination.expenseDetail, detailsPageSize) : 
            getPaginatedData(flightDetails, currentSelection.expenseAndFlightPagination.flightDetail, detailsPageSize)
          }  
          expenseFlightTotal = {expenseAndFlightDetails?.length} 
          expenseTotal={expenseDetails?.length} 
          flightTotal={flightDetails?.length}
        />
      );
    }
    // Return null if id is not 'expensesDetails'
    return null;
  }, [id, activeDetails, expenseAndFlightDetails, expenseDetails, flightDetails, currentSelection.expenseAndFlightPagination]);

  
  return (
    <div className="widgetBody">
      {/* TOP SPENDERS GRAPH : TAB SECTION */}
      {/* KEPT OUTSIDE BODY CONTAINER TO AVOID PADDING ISSUE */}
      {id === "topSpenders" && (
        <DetailsStatus
          id="topSpenders"
          aggregatedTotal={topSpendersAggregates.length}
          expenseTotal={topSpendersExpenses.length}
          flightTotal={topSpendersFlights.length}
        />
      )}
      <div className={`bodyContainer ${id}`}>
        {/* BODY CONTAINER OF EXPENSE COMPARISON GRAPH */}
        {id === "comparison" && <ExpensifyComparison margin={margin} />}

        {/* BODY CONTAINER OF EXPENSE GROWTH GRAPH */}
        {id === "growth" && <ExpensifyGrowth margin={margin} />}

        {/* BODY CONTAINER OF LIST OF SPENDERS GRAPH */}
        {id === "topSpenders" && (
          <>
            <ExpensifyHorizontalComparison
              margin={margin}
              data={
                activeSpenders === "1"
                  ? getPaginatedData(topSpendersAggregates, currentSelection.expenseAndFlightPagination.aggregated, 10)
                  : activeSpenders === "2"
                  ? getPaginatedData(topSpendersExpenses, currentSelection.expenseAndFlightPagination.expenses, 10)
                  : getPaginatedData(topSpendersFlights, currentSelection.expenseAndFlightPagination.flights, 10)
              }
            />
          </>
        )}

        {/* BODY CONTAINER OF CATEGORIES GRAPH */}

        {id === "expenseCategory" && (<ExpensifyByCategory margin={margin} categories={getPaginatedData(categories, currentSelection.expenseAndFlightPagination.category, 10)} /> )}
        {id === "expensesDetails" && MemoizedExpensifyFlighsDetailsTable}
      </div>
    </div>
  );
};

export default Body;
