import React from 'react'
import { Checkbox } from '@nutanix-ui/prism-reactjs'
import { useDispatch, useSelector } from 'react-redux';
import { toggleCustomerDefects } from '../../../../features/JiraAnalysis/enggDashboardSlice';
import { formatQuarter, getPeriodSelectionCustomer } from '../../EngineeringUtils';

const CustomerFooter = () => {

  const dispatch = useDispatch();
  const {  filters, cfdData, customerDefects } = useSelector((state) => state.enggDashboard);
  const periodSelection = getPeriodSelectionCustomer(cfdData, filters?.viewByCfd);
  
  const getCheckBoxState = (index) => {
    if(filters.viewByCfd === 'Quarterly'){
      switch (index) {
        case 1:
          return customerDefects.firstQuarter
        case 2:
          return customerDefects.secondQuarter
        case 3:
          return customerDefects.thirdQuarter
        case 4:
          return customerDefects.fourthQuarter
        default:
          return 
      }
      
    }else{
      switch (index) {
        case 1:
          return customerDefects.firstMonth
        case 2:
          return customerDefects.secondMonth
        case 3:
          return customerDefects.thirdMonth
        case 4:
          return customerDefects.fourthMonth
        case 5:
          return customerDefects.fifthMonth
        case 6:
          return customerDefects.sixthMonth
        default:
          return 
      }
    }
  }

  const handleChange = (e, index) => {
    if(filters.viewByCfd === 'Quarterly'){
      switch (index) {
        case 1:
          return dispatch(toggleCustomerDefects('firstQuarter'));
        case 2:
          return dispatch(toggleCustomerDefects('secondQuarter'));
        case 3:
          return dispatch(toggleCustomerDefects('thirdQuarter'));
        case 4:
          return dispatch(toggleCustomerDefects('fourthQuarter'));
        default:
          return
      }
    }else{
      switch (index) {
        case 1:
          return dispatch(toggleCustomerDefects('firstMonth'));
        case 2:
          return dispatch(toggleCustomerDefects('secondMonth'));
        case 3:
          return dispatch(toggleCustomerDefects('thirdMonth'));
        case 4:
          return dispatch(toggleCustomerDefects('fourthMonth'));
        case 5:
          return dispatch(toggleCustomerDefects('fifthMonth'));
        case 6:
          return dispatch(toggleCustomerDefects('sixthMonth'));
        default:
          return
      }
    }
  }

  return (
    <div className="cycleFooterContainer">
      {periodSelection.map((item, index) => (
        <Checkbox
          key={item.name}
          checked={getCheckBoxState(index+1)}
          color={item.color}
          id={`${item.name}`}
          label={filters.viewByCfd === "Monthly" ? item.name : formatQuarter(item.name)}
          onChange={(e) => handleChange(e, index+1)}
          style={{fontSize: '12px'}}
        />
      ))}
    </div>
  )
}

export default CustomerFooter