import React, { useEffect, useState } from "react";
import {
    NavBarLayout,
    FlexLayout,
    Select,
    Modal,
    Dropdown,
    ChevronDownIcon,
    Divider,
    Separator,
} from '@nutanix-ui/prism-reactjs';

import {
    LineChart,
    Line,
    CartesianGrid,
    ColorManager,
    XAxis,
    YAxis,
    Tooltip,
    LineDot,
    ResponsiveContainer,
    Legend,
    LabelList,
} from "@nutanix-ui/recharts";

import './index.css';

import { getAdoptionMetric } from "../../Service/adoptionMetric";

export default function AdoptionMetric(props) {

    const [visible, setVisible] = useState(false);
    const [timeSeriesData, setTimeSeriesData] = useState(props.timeSeriesData || []);
    const [totalUsersData, setTotalUsers] = useState(props.totalUsersData || []);

    useEffect(() => {
        setTimeSeriesData(prevState => props.timeSeriesData);
        setTotalUsers(prevState => props.totalUsersData);
    }, [props.totalUsersData, props.timeSeriesData])

    const renderMetricList = () => {
        if (totalUsersData.length > 0) {
            return totalUsersData.map(el => {
                if (el.name != "Total") {
                    return <div className="moduleItem">
                        <div>
                            <p style={{ color: '#627386' }}>{el.name}</p>
                        </div>
                        <div>
                            <p style={{ color: '#627386' }}>{el.count}</p>
                        </div>
                    </div>
                } else {
                    return <div>
                        <Divider />
                        <div className="moduleItem">
                            <div>
                                <p style={{ fontWeight: 800, fontSize: '12px' }}>{el.name}</p>
                            </div>
                            <div>
                                <p style={{ fontWeight: 800, fontSize: '12px' }}>{el.count}</p>
                            </div>
                        </div>
                    </div>
                }
            })
        }

    }

    const handleClick = () => {
        setVisible(prevState => true);
    }

    const handleOnClose = () => {
        setVisible(prevState => false);
    }

    const height = 400;
    const width = "95%";

    const selectProps = {
        rowsData: [
            {
                key: 1,
                label: 'puppyfood'
            },
            {
                key: 2,
                label: 'Peach'
            },
            {
                key: 3,
                label: 'galesbure2'
            },
            {
                key: 4,
                label: 'Orange',
                // An example of a non selectable item.
                disabled: true
            },
            {
                key: 5,
                label: 'Green'
            },
            {
                key: 6,
                label: 'Red'
            },
            {
                key: 7,
                label: 'Purple'
            },
            {
                key: 8,
                label: 'Blue'
            },
            {
                key: 9,
                label: 'Yellow'
            },
            {
                key: 10,
                label: 'Cyan'
            }
        ],
        label: 'Clusters',
        // onSelectedChange: this.handleOnSelectedChange,
        dropdownProps: { showCustomScrollbar: false },
        // We attached to the right-panel to handle the scrolling placement properly.
        getPopupContainer: () => document.querySelector('.right-panel')
    }

    const dataMap = {
        internal: {
            label: 'Internal Users(SaaS Data)'
        },
        external: {
            label: 'External Users'
        }
    };

    const formatCustomLabel = (value) => {
        return <p style={{ fontSize: '16px' }}>{`${dataMap[value].label}`}</p>;
    }

    const adoptionMetricList = (
        <div className="adoptionMetricContent">
            <FlexLayout alignItems="center" justifyContent="center" style={{ width: '18rem' }} onClick={handleClick}>
                <div className="moduleList">
                    <div className="moduleItem">
                        <div>
                            <p style={{ fontWeight: 800, fontSize: '12px' }}>Component</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: 800, fontSize: '12px' }}>Total Users</p>
                        </div>
                    </div>
                    {renderMetricList()}
                </div>
            </FlexLayout>
        </div>
    )

    return (
        <div>
            <Dropdown
                popup={adoptionMetricList}
                action="hover"
            >
                <FlexLayout itemSpacing="15px" alignItems="center">
                    <div className='adoptionMetricStats'><ChevronDownIcon /></div>
                </FlexLayout>
            </Dropdown>
            <Modal
                visible={visible}
                title={<div style={{ textAlign: 'start' }}>Adoption Metric</div>}
                width={'55rem'}
                height='fit-content'
                style={{
                    zIndex: '10'
                }}
                onClose={handleOnClose}
                footer={null}
                closeIconProps={{ 'data-test': 'my-close-icon-data-test' }}
                primaryButtonOnClick={handleOnClose}
            >
                {/* <div className="adoptionFilters">
                    <div>
                        <label className="adoptionFilterLabel">Select Product</label>
                        <Select
                            inputProps={{
                                name: 'basic-select'
                            }}
                            style={{ width: '17rem' }}
                            {...selectProps}
                            selectedRow={{
                                key: 10,
                                label: 'All'
                            }}
                        />
                    </div>
                    <div>
                        <label className="adoptionFilterLabel">User</label>
                        <Select
                            inputProps={{
                                name: 'basic-select'
                            }}
                            style={{ width: '17rem' }}
                            {...selectProps}
                            selectedRow={{
                                key: 10,
                                label: 'All'
                            }}
                        />
                    </div>
                    <div>
                        <label className="adoptionFilterLabel">Select Period</label>
                        <Select
                            inputProps={{
                                name: 'basic-select'
                            }}
                            style={{ width: '17rem' }}
                            {...selectProps}
                            selectedRow={{
                                key: 10,
                                label: 'All'
                            }}
                        />
                    </div>
                </div> */}
                <div>
                    <ResponsiveContainer width={width} height={height}
                    >
                        <LineChart
                            height={height}
                            data={timeSeriesData}
                            margin={{
                                top: 15,
                                right: 20,
                                left: 10,
                                bottom: 25
                            }}
                        >
                            <XAxis dataKey="name" />
                            <CartesianGrid stroke="#eee" vertical={true} horizontal={false} />
                            <YAxis dataKey="total" />
                            <Line
                                activeDot={true}
                                dot={true}
                                type="monotone"
                                dataKey="internal"
                                stroke={ColorManager.getColor('blue')} />
                            <Line
                                activeDot={true}
                                dot={true}
                                type="monotone"
                                dataKey="external"
                                stroke={ColorManager.getColor('mediumSlateBlue')} />
                            <Tooltip wrapperStyle={{
                                zIndex: 15
                            }} />
                            <Legend
                                wrapperStyle={
                                    {
                                        bottom: 5
                                    }
                                }
                                formatter={formatCustomLabel} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Modal>
        </div>

    )
}