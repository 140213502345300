import React, { Component, PropTypes, useState } from "react";
import { Card, Menu, Row, Col, Dropdown, Input } from "antd";
import { Timeline, Modal, Button, Spin, Table, Popover, Progress } from "antd";
import {
    SearchOutlined,
    DownCircleOutlined,
    DesktopOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
// import { Steps } from "antd";
import moment from "moment";
import greenLogo from "../../images/heart-green.png";
import yellowLogo from "../../images/heart-yellow.png";
import grayLogo from "../../images/heart-gray.png";
import redLogo from "../../images/heart-red.png";
import $ from "jquery";
import TimeAgo from "timeago-react";
import axios from "axios";
import "../DataSync/datasync.css";
import Config from "../../config/ApplicationTiles";
import { SERVER_URL } from "../../config/config";
import logger from "../../config/logger";
import { InfoPopover } from "../InfoPopover";
import { information } from "../DataSync/card-additional-info";
// import { loggerService } from "../../Service/LoggerService";
import FactoryDataTilesDataSync from "./FactoryDataTilesDataSync";
import { Alert } from "@nutanix-ui/prism-reactjs";

const propTypes = {};
const title = () => Config.config.table2DaysTitle;
const showHeader = true;
const defaultProps = {};

var backendChartData;
var hourWiseChart = true;

const columns = [
    {
        title: "End-point",
        dataIndex: "endPoint",
        key: "endPoint",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Time",
        dataIndex: "time",
        key: "time",
    },
];
const text = <span>Title</span>;
const content = (
    <div>
        <p>Content</p>
        <p>Content</p>
    </div>
);
const CardInfoTitle = ({ title, ...otherProps }) => {
    return (
        <div className="flex">
            <DesktopOutlined
                className="desktopOL"
                style={{ fontSize: "18px", color: "gray" }}
            />
            <div style={{ marginLeft: "-8px" }}>{title}</div>
            {otherProps.message && <InfoPopover {...otherProps} />}
        </div>
    );
};

const ExcludedColumns = (props) => {
    const { record } = { ...props }
    const [excludedColumns, setExcludedColumns] = useState([])
    const [seeMoreOpen, setSeeMoreOpen] = useState([])
    let content = record?.excl_cols
    return (
        <>
            {record?.excl_cols ?
                <div style={{ maxWidth: "250px", padding: "16px", overflow: "auto", maxHeight: "150px" }}>
                    <p style={{ fontSize: "12px" }}><b> Excluded Columns :</b></p>
                    <ul style={{ padding: 0, paddingLeft: "12px" }}>{content.split(",").map(e => <li style={{ fontSize: "13px" }}>{e}</li>)}</ul>
                </div>
                : "No data"}
        </>
    )
}
const EtlScheduledTime = (props) => {
    const { record } = { ...props }
    let etl_time;
    let sch_time;
    if (!record?.act_time) {
        etl_time = "nil"
        sch_time = "nil"
    }
    else {
        etl_time = moment(record?.act_time, "YYYY-MM-DD hh:mm:ss").format("YYYY MMM DD hh:mm A")
        sch_time = moment(record?.sch_time, "YYYY-MM-DD hh:mm:ss").format("YYYY MMM DD hh:mm A")
    }

    return (
        <div style={{ border: "1px solid #c6c1c1f0", minWidth: "350px" }}>
            <div style={{ display: "flex", alignItems: "center", background: "#ececec", padding: "8px" }}>
                <p style={{ fontSize: "12px", flex: 1, margin: 0 }}>ETL completed time (PDT)</p>
                <p style={{ fontSize: "12px", flex: 1, margin: 0 }}>Scheduled ETL time (PDT)</p>
            </div>
            <div style={{ display: "flex", alignItems: "center", padding: "8px" }}>
                <p style={{ fontSize: "12px", flex: 1, margin: 0 }}>{etl_time}</p>
                <p style={{ fontSize: "12px", flex: 1, margin: 0 }}>{sch_time}</p>
            </div>
        </div>
    )
}
const DqiInformation = () => {
    const columns = [
        {
            title: 'Metrics Weightage',
            dataIndex: 'metrics_weightage',
            width: "30%"
        },
        {
            title: '% Allocated',
            dataIndex: 'allocated',
            width: "30%"
        }
    ];
    const dataSource = [
        {
            key: '1',
            metrics_weightage: 'Accuracy',
            allocated: '50%',

        },
        {
            key: '2',
            metrics_weightage: 'Completeness',
            allocated: '40%',

        },
        {
            key: '3',
            metrics_weightage: 'Timeliness',
            allocated: '10%',

        }
    ];
    return (
        <div>
            <p style={{ fontSize: "12px", fontWeight: 600, display: "block", border: "1px solid #c6c1c1f0", width: "400px", padding: "8px" }}> DQI = (Accuracy * Weightage_Accuracy) + (Completeness * Weightage_Completeness) + (Timeliness * Weightage_Timeliness)
            </p>
            <Table columns={columns} dataSource={dataSource} pagination={false} size="small" style={{ width: "400px" }} />
        </div>
    )
}
class TilesDataSync extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoursToShow: Config.config.nHours,
            apiProgress: {},
            modalTitle: "",
            showCharts: false,
            showLoading: false,
            title: title,
            showHeader,
            dropdownText: "Select time interval",
            bordered: true,
            visible: false,
            schemaTableVisible: false,
            chartData: Config.config.chartData,
            showLegend: this.props.showLegend,
            dashBoard: this.props.dashBoard,
            filterDropdownVisible: false,
            data: [],
            filteredData: [],
            searchText: "",
            filtered: false,
            schemaName: "",
            factoryDataChildCondition: true
        };
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onApplicationsData = this.onApplicationsData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.info = this.info.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.onDaysChartApplicationsData =
            this.onDaysChartApplicationsData.bind(this);
        this.onApplicationsDowntimeData =
            this.onApplicationsDowntimeData.bind(this);
        this.handleCardStyle = this.handleCardStyle.bind(this);
    }

    onApplicationsData(error, data) {
        this.backendChartData = data;
        hourWiseChart = true;
        setTimeout(() => {
            this.showModal();
        }, 1000);
    }

    onDaysChartApplicationsData(error, data) {
        this.backendChartData = data;
        hourWiseChart = false;
        setTimeout(() => {
            this.showModal();
        }, 1000);
    }

    handleMenuClick(ev) {
        if (ev.key === "1") {
            this.fetchData(this.state.currentUID);
            this.fetchDownTimeTableData(this.state.currentUID, 2);
            const updatedTitle = () => Config.config.table2DaysTitle;
            this.setState({
                title: updatedTitle,
                showLoading: true,
                showCharts: false,
                dropdownText: "Last 48 hours",
            });
        } else {
            this.fetchMonthData(this.state.currentUID);
            this.fetchDownTimeTableData(this.state.currentUID, 30);
            const updatedTitle = () => Config.config.table30DaysTitle;
            this.setState({
                title: updatedTitle,
                showLoading: true,
                showCharts: false,
                dropdownText: "Last 30 days",
            });
        }
    }

    onApplicationsDowntimeData(error, data) {
        var object = {},
            dataSource = [];

        if (data.length > 0) {
            data.forEach(function (item, index) {
                object.key = index + 1;
                object.endPoint = item.endPoint;
                object.date = moment(item.time).format("DD-MMM-YYYY");
                object.time = moment(item.time).format("HH:mm");
                dataSource.push(object);
                object = {};
            });
        }

        this.setState({
            dataSource: dataSource,
        });
    }

    onInputChange = (e) => {
        this.setState({ searchText: e.target.value });
    };
    onSearch = () => {
        const { searchText, data } = this.state;
        const reg = new RegExp(searchText, "gi");
        this.setState({
            filterDropdownVisible: false,
            filtered: !!searchText,
            filteredData: data
                .map((record, index) => {
                    const match = record.table_name.match(reg);
                    if (!match) {
                        return null;
                    }
                    return {
                        ...record,
                        table_name: (
                            <span key={index}>
                                {record.table_name
                                    .split(reg)
                                    .map((text, i) =>
                                        i > 0
                                            ? [
                                                <span className="highlight">
                                                    {match[0]}
                                                </span>,
                                                text,
                                            ]
                                            : text
                                    )}
                            </span>
                        ),
                    };
                })
                .filter((record) => !!record),
        });
    };

    getTableColumns = (schemaName) => {
        const statusColumns = [
            // { title: "S.No.", dataIndex: "key", key: "key" },
            {
                title: "Table Name",
                dataIndex: "table_name",
                key: "table_name",
                className: "custom-cell",
                // width: 100,
                flex: 1,
                render: (value, record) => {
                    if (record?.schema_name) {
                        return (
                            <div style={{ display: "flex", alignItems: "center", gap: "16px", justifyContent: "flex-start" }}>

                                <Popover content={<ExcludedColumns record={record} />}>
                                    <InfoCircleOutlined style={{ color: "ash" }} />
                                </Popover>
                                {value}
                            </div>
                        )
                    }
                    else {
                        return (
                            <>
                                {value}

                            </>
                        )
                    }
                },
                filterDropdown: (
                    <div className="custom-filter-dropdown">
                        <Input
                            ref={(node) => {
                                this.searchInput = node;
                            }}
                            placeholder="Search name"
                            value={this.state.searchText}
                            onChange={this.onInputChange}
                            onPressEnter={this.onSearch}
                        />
                        <div>
                            <Button type="primary" onClick={this.onSearch}>
                                Search
                            </Button>
                            <Button
                                onClick={() =>
                                    this.setState({
                                        filtered: false,
                                        filterDropdownVisible: false,
                                        searchText: "",
                                        filteredData: this.state.data,
                                    })
                                }
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                ),
                filterIcon: (
                    <SearchOutlined
                        style={{ color: this.state.filtered ? "#108ee9" : "#aaa" }}
                    />
                    // <Icon
                    //   type="search"
                    //   style={{ color: this.state.filtered ? "#108ee9" : "#aaa" }}
                    // />
                ),
                filterDropdownVisible: this.state.filterDropdownVisible,
                onFilterDropdownVisibleChange: (visible) => {
                    this.setState({
                        filterDropdownVisible: visible,
                    });
                    setTimeout(() => {
                        this.searchInput.focus();
                    }, 1000);
                },
                sorter: (a, b) => {
                    a = String(a.table_name) || "";
                    b = String(b.table_name) || "";

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                title: "Accuracy (%)",
                dataIndex: "accuracy_metrics",
                key: "accuracy",
                align: "center",
                flexBasis: "150px",
                render: (value) => {
                    return (
                        <div>
                            {value ? (value.toFixed(2)?.includes(.00) ? value.toFixed(2)?.split('.')[0] : value.toFixed(2)) : "NA"}
                        </div>
                    );
                },
                sorter: (a, b) => {
                    return Number(a.accuracy_metrics) - Number(b.accuracy_metrics);
                }

            },
            {
                title: "Completeness (%)",
                dataIndex: "completeness_metrics",
                key: "completeness",
                flexBasis: "150px",
                align: "center",
                render: (value) => {
                    return (
                        <div>
                            {value ? (value.toFixed(2)?.includes(.00) ? value.toFixed(2)?.split('.')[0] : value.toFixed(2)) : "NA"}

                        </div>
                    );
                },
                sorter: (a, b) => {
                    return Number(a.completeness_metrics) - Number(b.completeness_metrics);
                }

            },
            {
                title: "Timeliness (%)",
                dataIndex: "timeliness",
                key: "timeliness",
                align: "center",
                flexBasis: "150px",
                render: (value, record) => {
                    return (
                        <div>
                            {value ?
                                <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>

                                    <Popover content={<EtlScheduledTime record={record} />}>
                                        <InfoCircleOutlined style={{ color: "ash" }} />
                                    </Popover>
                                    <p style={{ margin: 0, width: 40 }}>{value.toFixed(2)?.includes(.00) ? value.toFixed(2)?.split('.')[0] : value.toFixed(2)}</p>
                                </div>


                                :
                                <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                    <InfoCircleOutlined style={{ color: "#c6c1c1f0" }} />
                                    <p style={{ margin: 0, width: 40 }}>NA</p>
                                </div>
                            }

                        </div>
                    );
                },

                sorter: (a, b) => {
                    return Number(a.timeliness) - Number(b.timeliness);
                }

            },
            {

                title: <Popover content={<DqiInformation />} placement="bottom">DQI (%) </Popover>,
                dataIndex: "dqi",
                key: "dqi",
                align: "center",
                flexBasis: "150px",
                render: (value, record) => {


                    let color = "black";
                    if (value > 95) { color = "black" }
                    if (value >= 80 && value <= 95) { color = "orange" }
                    if (value < 80) { color = "red" }
                    return (
                        <div style={{ color: color }}>
                            {value}

                        </div>
                    );
                },
                sorter: (a, b) => {
                    return Number(a.dqi) - Number(b.dqi);
                }


            }

        ];
        if (!["SFDC", "NETSUITE"].includes(this.state.schemaName)) {
            delete statusColumns[1]
            delete statusColumns[2]
        }
        if (["PULSE"].includes(this.state.schemaName)) {
            return [
                statusColumns[0], // table_name column
                {
                    title: "Last Validated On",
                    dataIndex: "load_time",
                    key: "load_time",
                    align: "center",
                    flexBasis: "150px",
                    render: (value) => value ? moment(value).format("YYYY MMM DD hh:mm A") : "NA",
                    sorter: (a, b) => {
                        return moment(a.load_time) - moment(b.load_time);
                    }
                },
                statusColumns[4] // dqi column
            ];
        }
        return statusColumns
    }

    async info(v_schema_name) {
        try {
            // loggerService({
            //     module: "data_health",
            //     msg: `calling Schedule vs Actual Schema details with parameter schema_name= ${v_schema_name}`,
            //     logLevel: "INFO",
            //     eventName: "onchange",
            //     url: window.location.href,
            // });
    
            this.setState({ apiProgress: { [v_schema_name]: { loading: true, ready: false, error: false } } });
            const metricsRequest = {
                SFDC: { schema: "sfdc", envs: ["prd"], metrics: ["accuracy", "completeness"] },
                NETSUITE: { schema: "netsuite", envs: ["prd"], metrics: ["accuracy", "completeness"] }
            };
    
            const { data } = await axios.post(`${SERVER_URL}/gettimeliness?schema_name=${v_schema_name}`);
    
            // A common function to set state with the required filtered data
            const setFilteredData = (newData) => {
                const updatedFilterData = newData.map(e => ({
                    ...e,
                    dqi: v_schema_name === "PULSE" ? e.dqi : this?.findDqi(e),
                }));
                // Only update the state if data or filteredData has actually changed
                const isDataChanged = JSON.stringify(this.state.data) !== JSON.stringify(newData);
                const isFilteredDataChanged = JSON.stringify(this.state.filteredData) !== JSON.stringify(updatedFilterData);
                console.log(isDataChanged, isFilteredDataChanged, 'Changes');
                
                if (isDataChanged || isFilteredDataChanged) {
                    this.setState({
                        data: newData,
                        filteredData: updatedFilterData,
                        schemaTableVisible: true,
                    });
                }
            };
            // Handle specific schema cases
            if (v_schema_name === "PULSE") {
                setFilteredData(data);
            } else if (v_schema_name === "SFDC" || v_schema_name === "NETSUITE") {
                const metricsRequestKey = v_schema_name === "SFDC" ? "SFDC" : "NETSUITE";
                const { data: { result: metrics } } = await axios.post(`${SERVER_URL}/data-audit/data_metrics`, metricsRequest[metricsRequestKey]);
                this.SchemaModalInfo(data, metrics);
                setFilteredData(data);  // Apply the same filtering for other schemas
            } else {
                setFilteredData(data);
            }
            // Update state after processing
            this.setState({
                apiProgress: { [v_schema_name]: { loading: false, ready: true, error: false } },
                schemaName: v_schema_name,
                schemaTableVisible: true,
            });
            // Styling highlight after processing
            $("span.highlight-delta").parents("td").css("background-color", "#fef0ef");
        } catch (e) {
            logger.error(e);
            this.setState({
                apiProgress: { [v_schema_name]: { loading: false, ready: false, error: true } },
            });
        }
    }
    SchemaModalInfo = (data, metrics) => {
        const metrics_sfdc_details = metrics.prd
        const data_health_sfdc_details = data
        const allTables = [...metrics_sfdc_details, ...data_health_sfdc_details]

        const allKeys = allTables.reduce((acc, curr) => {
            const keys = Object.keys(curr)
            acc = [...new Set([...acc, ...keys])]
            return acc
        }, [])

        const distinctTableObject = allTables.reduce((acc, curr) => {
            const keyMap = allKeys.reduce((acclc, currlc) => {

                acclc[currlc] = null
                return acclc
            }, {})

            const tableName = curr.table_name
            if (acc[tableName]) {
                acc[tableName] = { ...acc[tableName], ...curr }
                return acc // return if table already exist in acc
            }

            acc[tableName] = { ...keyMap, ...curr, key: tableName }
            return acc
        }, {})

        const distinctTables = Object.values(distinctTableObject).map(e => {
            return {
                ...e, dqi: this.findDqi(e)
            }
        })
        this.setState({
            data: distinctTables,
            filteredData: distinctTables
        })
    }
    findDqi(e) {

        let Weightage_Accuracy = 50;
        let Weightage_Completeness = 40;
        let Weightage_Timeliness = 10;
        let Weightage_Accuracy_Updated = 55;
        let Weightage_Completeness_Updated = 45;
        let dqi_value;
        if (e?.schema_name) {
            if (e?.accuracy_metrics && e?.completeness_metrics && e?.timeliness) {
                const dqi_value_initial = ((e.accuracy_metrics * Weightage_Accuracy / 100) + (e.completeness_metrics * Weightage_Completeness / 100) + (e.timeliness * Weightage_Timeliness / 100)).toFixed(2)
                dqi_value = dqi_value_initial?.includes(.00) ? dqi_value_initial?.split('.')[0] : dqi_value_initial
            }
            else if (!e?.accuracy_metrics && !e?.completeness_metrics && e?.timeliness) {
                const dqi_value_initial = (e.timeliness).toFixed(2)
                dqi_value = dqi_value_initial?.includes(.00) ? dqi_value_initial?.split('.')[0] : dqi_value_initial
            }
            else {
                const dqi_value_initial = ((e.accuracy_metrics * Weightage_Accuracy_Updated / 100) + (e.completeness_metrics * Weightage_Completeness_Updated / 100)).toFixed(2)
                dqi_value = dqi_value_initial?.includes(.00) ? dqi_value_initial?.split('.')[0] : dqi_value_initial
            }
        }
        else {
            const dqi_value_initial = (e.timeliness).toFixed(2)
            dqi_value = dqi_value_initial?.includes(.00) ? dqi_value_initial?.split('.')[0] : dqi_value_initial
        }
        return dqi_value
    }
    openModal(event) {
        // loggerService({
        //     module: "data_health",
        //     msg: `user opened modal`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        var target = event.target,
            type,
            id,
            name,
            values = [];

        Object.keys(target).map(function (keyName, keyIndex) {
            type = target[keyName]._currentElement.props.value;
            id = target[keyName]._currentElement.props.name;
            name = target[keyName]._currentElement.props.title;
        });

        values.push(type, id, name);

        this.fetchDownTimeTableData(id, 2);
        this.fetchData(id);

        this.setState({
            visible: true,
            modalTitle: name,
            showCharts: false,
            showLoading: true,
            currentUID: id,
            onClickValues: values,
        });
    }

    /*
  This function is called when user clicks on any App to show the status chart.
  Using two maps to store the active and inactive status. After data is
  properly formatted, we update the chartData using setState.
  */
    showModal() {
        // loggerService({
        //     module: "data_health",
        //     msg: `user opened modal`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        var data = this.state.onClickValues;
        if (data && data.length === 3) {
            var type = data[0],
                id = data[1],
                name = data[2],
                backendChartData = this.backendChartData,
                activeData = ["active"],
                inactiveData = ["inactive"],
                noDataPolling = ["noDataPolling"],
                date = Date.now(),
                currentDate = new Date(date),
                timeDiff,
                statusCount = 0,
                statusDate,
                hours,
                xAxisLabel = [],
                stateAxisCopy = this.state.chartData.axis,
                stateChartDataCopy = this.state.chartData;

            if (hourWiseChart) {
                hours = this.state.hoursToShow;
                stateAxisCopy.x.label.text = "Last 48 hours (x-axis)";
                stateAxisCopy.y.label.text = "Polling per hour";
                stateAxisCopy.y.tick = { values: [0, 1, 2, 3, 4, 5, 6] };
                stateChartDataCopy.tooltip.format.title = function (d) {
                    return 48 - d + " hours ago";
                };
            } else {
                hours = Config.config.nDaysForChart;
                stateAxisCopy.x.label.text = "Last 30 days (x-axis)";
                stateAxisCopy.y.label.text = "Polling per day";
                stateAxisCopy.y.tick = null;
                stateChartDataCopy.tooltip.format.title = function (d) {
                    return 30 - d + " days ago";
                };
            }

            var chartActiveDataMap = new Map(
                Array(hours + 1)
                    .fill("0")
                    .entries()
            ),
                chartInactiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                );

            backendChartData.forEach((item) => {
                statusDate = new Date(item.time);
                if (hourWiseChart) {
                    timeDiff = Math.floor(
                        Math.abs(currentDate.getTime() - statusDate.getTime()) /
                        3600000
                    );
                } else {
                    timeDiff = Math.floor(
                        Math.abs(currentDate.getTime() - statusDate.getTime()) /
                        86400000
                    );
                }
                if (
                    item.uid === id &&
                    item.type === type &&
                    timeDiff <= hours
                ) {
                    if (
                        item.status === "active" &&
                        chartActiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartActiveDataMap.get(timeDiff);
                        chartActiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    } else if (
                        item.status === "inactive" &&
                        chartInactiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartInactiveDataMap.get(timeDiff);
                        chartInactiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    }
                }
            });

            // TODO: Remove for loop
            for (var i = hours; i >= 0; --i) {
                if (i === 0) {
                    xAxisLabel.push("Latest");
                } else {
                    xAxisLabel.push(i);
                }
                activeData.push(chartActiveDataMap.get(i));
                inactiveData.push(chartInactiveDataMap.get(i));
                noDataPolling.push(0);
            }

            stateAxisCopy.x.categories = xAxisLabel;
            stateAxisCopy.x.tick.culling.max = hours / 3;
            stateChartDataCopy.columns = [
                activeData,
                inactiveData,
                noDataPolling,
            ];

            this.setState({
                chartData: stateChartDataCopy,
                visible: true,
                modalTitle: name,
                showCharts: true,
                showLoading: false,
                axis: stateAxisCopy,
            });
        } else {
            this.setState({
                visible: false,
            });
        }
    }

    showDaysChartModal() {
        // loggerService({
        //     module: "data_health",
        //     msg: `chart modal opened`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        var data = this.state.onClickValues;
        if (data && data.length === 3) {
            var type = data[0],
                id = data[1],
                name = data[2],
                backendChartData = this.backendChartData,
                activeData = ["active"],
                inactiveData = ["inactive"],
                noDataPolling = ["noDataPolling"],
                date = Date.now(),
                currentDate = new Date(date),
                timeDiff,
                statusCount = 0,
                statusDate,
                xAxisLabel = [],
                hours = this.state.hoursToShow,
                chartActiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                ),
                chartInactiveDataMap = new Map(
                    Array(hours + 1)
                        .fill("0")
                        .entries()
                );

            backendChartData.forEach((item) => {
                statusDate = new Date(item.time);
                timeDiff = Math.floor(
                    Math.abs(currentDate.getTime() - statusDate.getTime()) /
                    3600000
                );
                if (
                    item.uid === id &&
                    item.type === type &&
                    timeDiff <= hours
                ) {
                    if (
                        item.status === "active" &&
                        chartActiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartActiveDataMap.get(timeDiff);
                        chartActiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    } else if (
                        item.status === "inactive" &&
                        chartInactiveDataMap.has(timeDiff)
                    ) {
                        statusCount = chartInactiveDataMap.get(timeDiff);
                        chartInactiveDataMap.set(
                            timeDiff,
                            parseInt(statusCount) + 1
                        );
                    }
                }
            });

            // TODO: Remove for loop
            for (var i = hours; i >= 0; --i) {
                if (i === 0) {
                    xAxisLabel.push("Latest");
                } else {
                    xAxisLabel.push(i);
                }
                activeData.push(chartActiveDataMap.get(i));
                inactiveData.push(chartInactiveDataMap.get(i));
                noDataPolling.push(0);
            }

            var stateChartDataCopy = this.state.chartData;
            stateChartDataCopy.columns = [
                activeData,
                inactiveData,
                noDataPolling,
            ];
            var stateAxisCopy = this.state.chartData.axis;
            stateAxisCopy.x.categories = xAxisLabel;
            stateAxisCopy.x.tick.culling.max = hours / 3;
            this.setState({
                chartData: stateChartDataCopy,
                visible: true,
                modalTitle: name,
                showCharts: true,
                showLoading: false,
                axis: stateAxisCopy,
            });
        } else {
            this.setState({
                visible: false,
            });
        }
    }

    handleOk = (e) => {
        // loggerService({
        //     module: "data_health",
        //     msg: `user clicked on ok`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        // loggerService({
        //     module: "data_health",
        //     msg: `user closed modal`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        this.setState({
            visible: false,
            dropdownText: "Select time interval",
        });
    };
    handleSyncModalCancel = (e) => {
        // loggerService({
        //     module: "data_health",
        //     msg: `user closed modal`,
        //     logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
        //     eventName: "onclick",
        //     url: window.location.href,
        // })
        this.setState({
            schemaTableVisible: false,
        });
    };
    handleCardStyle = (style) => {
        this.setState({ factoryDataChildCondition: style })
    }
    render() {
        const {
            visible,
            modalTitle,
            schemaName,
            schemaTableVisible,
            filteredData,
        } = this.state;
        // const data = this.state.chartData;
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="1">Last 48 hours</Menu.Item>
                <Menu.Item key="2">Last 30 days</Menu.Item>
            </Menu>
        );
        const content =
            "Scroll down to see the list of downtime in last 30 days";
        var that = this,
            showOnlyProd = false;

        if (this.state.dashBoard === true) {
            showOnlyProd = true;
        }
        if (Object.keys(this.props.app_data).length > 0) {
            const data_object = this.props.app_data;
            // TODO: Use Object instead of $ add check in case Object is empty
            const data_array = $.map(data_object, function (value, index) {
                return [value];
            });

            const app_data = [];
            var index = 0;

            data_array.forEach((object) => {
                const classnames = { heartClass: "animated pulse infinite" };
                if (object.display) {
                    var TimeLineItem = [],
                        CarouselItem = [],
                        TimeLineItemColor,
                        numberOfData =
                            object.environment && object.environment.length,
                        enableCount = 0,
                        statusCount = 0,
                        inactiveCount = 0,
                        imageUrl,
                        imageClass = "animated pulse infinite",
                        cardClass = "",
                        disableCount = 0,
                        appName = object.app_name,
                        appId = object.id,
                        currentTime = moment().format(),
                        upTime = object.upTime,
                        availability = object.availability;

                    if (!upTime) {
                        upTime = "0.00";
                    }
                    let cardPulse = false;
                    object?.environment.forEach((item) => {
                        var now = moment(new Date()),
                            end = item.lastUpdatedTime
                                ? moment(item.lastUpdatedTime)
                                : now,
                            duration = moment.duration(now.diff(end)),
                            minutes = Math.round(duration.asMinutes());

                        if (!minutes && minutes === 0) {
                            minutes = "Just Now";
                        } else {
                            minutes = `${minutes} mins ago`;
                        }

                        if (item.status === "active" && item.enable) {
                            TimeLineItemColor = "green";
                            enableCount++;
                            statusCount++;
                        } else if (item.status === "inactive" && item.enable) {
                            TimeLineItemColor = "red";
                            inactiveCount++;
                        } else {
                            TimeLineItemColor = "gray";
                            disableCount++;
                        }

                        if (statusCount === numberOfData - disableCount) {
                            imageUrl = greenLogo;
                        } else if (statusCount - inactiveCount > 0) {
                            imageUrl = yellowLogo;
                        }

                        if (numberOfData - disableCount === 0) {
                            imageUrl = grayLogo;
                            imageClass = "";
                            cardClass = "";
                        }
                        if (upTime >= 99) {
                            imageUrl = greenLogo;
                            classnames.heartClass = "animated pulse infinite";
                        }
                        if (upTime >= 96 && upTime < 99) {
                            imageUrl = yellowLogo;
                            classnames.heartClass = "heart";
                            cardPulse = true;
                        }
                        if (upTime < 96) {
                            imageUrl = redLogo;
                            cardPulse = true;
                            classnames.heartClass = "heart";
                        }

                        TimeLineItem.push(
                            <Timeline.Item
                                key={"TimeLineItem datasync" + index}
                                color={TimeLineItemColor}
                                dot={
                                    <img
                                        src={imageUrl}
                                        className={classnames.heartClass}
                                        alt={"TimeLineItem"}
                                    />
                                }
                            >
                                <p className="card-title1">
                                    <b>{item.name}</b>
                                </p>

                                {/* <h4>
                                    <TimeAgo
                                        datetime={moment().format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )}
                                        locale="en"
                                    />
                                </h4> */}
                                <div
                                    key={"uptime datasync" + index}
                                    className="n-uptime-percent"
                                >
                                    Data Quality Index - <b>{upTime ? (Number(upTime).toFixed(2)?.includes(.00) ? Number(upTime).toFixed(2)?.split('.')[0] : Number(upTime).toFixed(2)) : "NA"} %</b>
                                </div>

                                <div
                                    key={"uptimeValidated datasync" + index}
                                    className="n-uptime-percent"
                                >
                                    Last Validated -{" "}
                                    <b>
                                        <TimeAgo
                                            datetime={moment(
                                                moment(availability).format(
                                                    "YYYY-MM-DDTHH:mm:ss-0700"
                                                )
                                            )
                                                .tz(moment.tz.guess())
                                                .format()}
                                            locale="en"
                                        />
                                    </b>
                                </div>

                                <a
                                    value={item.type}
                                    name={appId}
                                    title={appName}
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        that.info(appName)
                                    }}
                                >
                                    Details
                                </a>
                            </Timeline.Item>
                        );
                        index++;
                    });

                    if (object.dataLink && object.dataLink.length > 0) {
                        object.dataLink.forEach((dLink) => {
                            var dLinkIndex = 0;
                            if (
                                dLink.hasMultipleValues &&
                                dLink.value &&
                                dLink.value.length > 1
                            ) {
                                dLink.label.forEach((label) => {
                                    CarouselItem.push(
                                        <div key={"Carousel" + label}>
                                            <h3 className="n-content-ellipsis">
                                                {" "}
                                                {label}{" "}
                                            </h3>
                                            <h2>
                                                <b>
                                                    {dLink.value[dLinkIndex]
                                                        ? dLink.value[
                                                        dLinkIndex
                                                        ]
                                                        : "N/A"}
                                                </b>
                                            </h2>
                                        </div>
                                    );
                                    dLinkIndex++;
                                });
                            } else {
                                CarouselItem.push(
                                    <div>
                                        <h3 className="n-content-ellipsis">
                                            {dLink.label}{" "}
                                        </h3>
                                        <h2>
                                            <b>
                                                {dLink.value
                                                    ? dLink.value
                                                    : "N/A"}
                                            </b>
                                        </h2>
                                    </div>
                                );
                            }
                        });
                    }

                    let name = object.app_name;
                    if (showOnlyProd) {
                        if (object.type === "prod") {
                            app_data.push({
                                name: name.replaceAll("_", " "),
                                TimeLineItem: TimeLineItem,
                                imageUrl: imageUrl,
                                CarouselItem: CarouselItem,
                                cardClass: cardClass,
                                cardPulse: cardPulse,
                            });
                        }
                    } else {
                        app_data.push({
                            name: name.replaceAll("_", " "),
                            TimeLineItem: TimeLineItem,
                            imageUrl: imageUrl,
                            CarouselItem: CarouselItem,
                            cardClass: cardClass,
                            cardPulse: cardPulse,
                        });
                    }
                } else {
                    // if app_name is factory data
                    if (object.app_name === 'factory_data') {
                        app_data.push({
                            name: "Factory Data",
                            cardPulse: this.state.factoryDataChildCondition,
                        })
                    }
                }
            });
            return (
                <div>
                    {schemaTableVisible && (
                        <Modal
                            mask={false}
                            title={"Schema:- " + schemaName}
                            open={schemaTableVisible}
                            footer={[
                                <Button
                                    type="primary"
                                    key="modalDismissButton"
                                    onClick={this.handleSyncModalCancel}
                                >
                                    {" "}
                                    Dismiss
                                </Button>,
                            ]}
                            width={"70vw"}
                            style={{ maxWidth: "800px" }}
                            onCancel={that.handleSyncModalCancel}
                        >
                            <Table
                                columns={this.getTableColumns(schemaName)}
                                dataSource={filteredData}
                                size="small"
                                pagination={{
                                    pageSize: 10,
                                    showSizeChanger: false,
                                }}
                                locale={{ emptyText: "No Data" }}
                            />
                        </Modal>
                    )}
                    <Modal
                        mask={false}
                        title={modalTitle}
                        open={visible}
                        className="n-chart-modal"
                        footer={[
                            <Button
                                type="primary"
                                key="modalDismissButton"
                                onClick={this.handleCancel}
                            >
                                {" "}
                                Dismiss
                            </Button>,
                        ]}
                        onCancel={that.handleCancel}
                    >
                        {this.state.showCharts && (
                            <div className="n-chart-and-downtime-modal">
                                <div className="n-dropdown">
                                    <Dropdown.Button overlay={menu}>
                                        {this.state.dropdownText}
                                    </Dropdown.Button>
                                </div>

                                <div className="n-popover" onScroll={this.hideDownButton}>
                                    <Popover
                                        placement="leftBottom"
                                        content={content}
                                        trigger="hover"
                                        className="n-popover"
                                    >
                                        <DownCircleOutlined />
                                    </Popover>
                                </div>
                                <div className="n-downtime-table">
                                    <Table
                                        {...this.state}
                                        columns={columns}
                                        dataSource={this.state.dataSource}
                                        bordered
                                        locale={{ emptyText: "No Data" }}
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.showLoading && (
                            <div className="n-chart-spin-loader">
                                <Spin />
                            </div>
                        )}
                    </Modal>

                    <Row gutter={18}>
                        {app_data.map((appDataObject, index) => (
                            <Col span={6} key={"col datasync" + index}>
                                <Spin
                                    spinning={
                                        this.state.apiProgress?.[appDataObject.name]
                                            ?.loading || false
                                    }
                                >
                                    <Card
                                        title={
                                            <CardInfoTitle
                                                title={appDataObject.name}
                                                message={appDataObject.name === 'Factory Data' ? information['FactoryData'] : information[appDataObject.name]}
                                            />
                                        }
                                        //    title={appDataObject.name}
                                        bordered={true}
                                        className={
                                            appDataObject.cardPulse
                                                ? "tileDown animated pulse infinite"
                                                : ""
                                        }
                                    >
                                        {appDataObject.name === "Factory Data" ? (
                                            <FactoryDataTilesDataSync handleCardStyle={this.handleCardStyle} />
                                        ) : (
                                            <>
                                                <Timeline key={`${appDataObject.name}datasync`}>
                                                    {appDataObject.TimeLineItem}
                                                </Timeline>
                                                <center></center>
                                            </>
                                        )}
                                    </Card>
                                </Spin>
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        } else {
            return (
                <Row gutter={24}>
                    <Col span={24}>
                        <Card loading bordered={true}></Card>
                    </Col>
                </Row>
            );
        }
    }
}

TilesDataSync.defaultProps = defaultProps;
TilesDataSync.propTypes = propTypes;

export default TilesDataSync;