import React, { useState } from "react";
import { FlowAnalysisGraph } from "@ant-design/graphs";

const reportNodeID = "-1";
const tableLimiter = 25;
const reportLimiter = 30;

const getTableItems = (table) =>
    table && table.length > tableLimiter
        ? [
              {
                  text: table.slice(0, tableLimiter),
              },
              {
                  text: table.slice(tableLimiter),
              },
          ]
        : [
              {
                  text: table,
              },
          ];

const getReportItems = (reportName) =>
    reportName && reportName.length > reportLimiter
        ? [
              {
                  text: reportName.slice(0, reportLimiter),
              },
              {
                  text: reportName.slice(reportLimiter),
              },
          ]
        : [
              {
                  text: reportName || "",
              },
          ];

const getNodes = (data) => {
    let nodes = [
        {
            id: reportNodeID,
            value: {
                title: "Report",
                items: getReportItems(data[0].dashboard_name),
            },
        },
    ];

    let edges = [];
    let tableKeyMap = {};
    let targetTableFunctionMap = {};
    let key = 1;

    data.forEach((ele) => {
        const { source_table, target_table, function_name } = ele;
        if (!!!tableKeyMap[source_table])
            tableKeyMap[source_table] = (key++).toString();

        if (!!!tableKeyMap[target_table])
            tableKeyMap[target_table] = (key++).toString();

        if (target_table && function_name) {
            const currFunctionName = function_name.split(".")[1];
            const prevFunctionName = targetTableFunctionMap[target_table];

            if (!prevFunctionName)
                targetTableFunctionMap[target_table] = [currFunctionName];
            else if (
                prevFunctionName &&
                !prevFunctionName.includes(currFunctionName)
            ) {
                targetTableFunctionMap[target_table].push(currFunctionName);
            }
        }
    });
    Object.keys(tableKeyMap).forEach((ele) => {
        const [schemaName, tableName] = ele.split(".");
        if (schemaName === "null") return;

        let node = {
            id: tableKeyMap[ele],
            value: {
                title: `${schemaName} Table`,
                items: getTableItems(tableName),
            },
        };
        if (targetTableFunctionMap[ele])
            node.function = targetTableFunctionMap[ele];

        nodes.push(node);
    });
    data.forEach((ele) => {
        if (ele.source_table !== null)
            edges.push({
                target: tableKeyMap[ele.source_table],
                source: tableKeyMap[ele.target_table],
            });
        if (!data.find((link) => link.source_table === ele.target_table))
            edges.push({
                target: tableKeyMap[ele.target_table],
                source: reportNodeID,
            });
    });
    return [nodes, edges];
};

const FlowPlot = ({ flowData }) => {
    const graphData = getNodes(flowData);
    const [nodesData, edgesData] = graphData;

    const data = {
        nodes: nodesData,
        edges: edgesData,
    };
    const ToolTip = ({ cfg }) => {
        const functionName = cfg.function;
        if (cfg.value.title === "Report") return <div>Report</div>;
        if (!functionName) return <div>Base Table</div>;
        return (
            <div>
                {functionName.length === 1 ? (
                    "fn: " + functionName[0]
                ) : (
                    <div>
                        fns:&nbsp;
                        <ol>
                            {functionName.map((func) => (
                                <li>{func}</li>
                            ))}
                        </ol>
                    </div>
                )}
            </div>
        );
    };
    const config = {
        data,
        // autoFit: false,
        width: 1000,
        height: 1200,
        nodeCfg: {
            // type: "rect",
            size: [230, 40],
            items: {
                containerStyle: {
                    fill: "#fff",
                },
                padding: 6,
                style: (cfg, group, type) => {
                    const styles = {
                        text: {
                            fill: "#0000ff",
                        },
                    };
                    return styles[type];
                },
            },
            nodeStateStyles: {
                hover: {
                    stroke: "#1890ff",
                    lineWidth: 2,
                },
            },
            title: {
                containerStyle: {
                    fill: "transparent",
                },
                style: {
                    fill: "#000",
                    fontSize: 12,
                },
            },
            style: {
                fill: "#E6EAF1",
                stroke: "#B2BED5",
                radius: [2, 2, 2, 2],
            },
        },
        edgeCfg: {
            // type: "polyline",
            startArrow: true,
            // endArrow: true,
            style: (edge) => {
                const gray = "#6082B6";
                const stroke = gray;
                return {
                    stroke,
                    lineWidth: Math.random() * 10 + 1,
                    strokeOpacity: 0.5,
                };
            },
            edgeStateStyles: {
                hover: {
                    strokeOpacity: 1,
                },
            },
        },
        markerCfg: (cfg) => {
            const { edges } = data;
            return {
                position: "left",
                show: edges.find((item) => item.source === cfg.id),
                collapsed: !!!edges.find((item) => item.source === cfg.id),
            };
        },
        tooltipCfg: {
            /** whether to show */
            show: true,
            /** custom content */
            customContent: (cfg) => <ToolTip cfg={cfg} />,
        },
        layout: {
            rankdir: "RL",
            nodesepFunc: () => 0, // vertical distance between nodes
            ranksepFunc: () => 100, // horizontal distance
        },
        behaviors: ["drag-canvas", "scroll-canvas", "drag-node"],
        // behaviors: ["drag-node"],
    };

    return <FlowAnalysisGraph {...config} />;
};

export default FlowPlot;
