import React from "react";
import { useSelector } from "react-redux";
import Velocity from "./Components/TabComponents/Velocity";
import CycleTime from "./Components/TabComponents/CycleTime";
import CustomerDefects from "./Components/TabComponents/CustomerDefects";
import CodeCoverage from "./Components/TabComponents/CodeCoverage";

const EnggDashboardBody = () => {
  const { activeHeaderTab } = useSelector((state) => state.enggDashboard);
  const graphMargin = { top: 20, right: 20, bottom: 20, left: 20 };
  return (
    <>
      {activeHeaderTab === 1 && <Velocity margin={graphMargin} />}
      {activeHeaderTab === 2 && <CycleTime margin={graphMargin} />}
      {activeHeaderTab === 3 && <CustomerDefects margin={graphMargin} />}
      {activeHeaderTab === 4 && <CodeCoverage margin={graphMargin} />}
    </>
  );
};

export default EnggDashboardBody;
