import { Modal, StackingLayout } from "@nutanix-ui/prism-reactjs";
import React from "react";

const CustomModal = ({ isVisible, header, body, footer, onClose, className }) => {
  return (
    <Modal
      visible={isVisible}
      onClose={onClose}
      footer={footer ? footer : null}
      className={className}
      title={header}
    >
      <StackingLayout padding="20px">
        {/* <div>{ header }</div> */}
        <div>{ body }</div>
      </StackingLayout>
    </Modal>
  );
};

export default CustomModal;
