import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";

import { Loader, StackingLayout } from "@nutanix-ui/prism-reactjs";

import postgress_logo from "../../images/postgresql-logo-png-transparent.png";
import snowflake_logo from "../../images/Snowflake_logo.png";
import Mysqllogo_logo from "../../images/Mysqllogo.png";
import MongoDB_logo from "../../images/mongoDb.png";
import AmazonS3_logo from "../../images/s3-logo.png";
import Dremio_logo from "../../images/dremio-logo.png";
import SalesForce_logo from "../../images/salesForce.png";
import sftp_logo from "../../images/sftp-logo.png";
import "./styles.css";

export default function InputPane(props) {
  const {data,section}=props
 const [connectCount,setConnectorsCount] =useState(0)
  useEffect(()=>{
    let count=data?.filter(e => section?.includes(e.dropArea));
    setConnectorsCount(count.length)
  },[data,section])
  return (
    <div className="inputPaneContainer">
      <StackingLayout padding="20px">
        <div className="schemaListContainer">
          <p className="schemaTitle">Connectors ({connectCount})</p>
          {data?.map((item) => (
            <RenderSchemaIcon item={item} section={section}/>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          {data?.length === 0 && <Loader></Loader>}
        </div>
      </StackingLayout>
    </div>
  );
}

function RenderSchemaIcon({ item ,section}) {// eslint-disable-next-line 
  const [{ isDragging }, dragRef] = useDrag({
    type: "schemaBox",
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (item?.imgID === "postgres" && section?.includes((item.dropArea))) {
    return (// need to think of alternative for specifying the which is source and target
      <div className="schemaItem" data-testid={section?.includes((item.dropArea)) ? 'paas-etlCanvas-postgres-sourceConnector':'paas-etlCanvas-postgres-targetConnector'}>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={postgress_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "snowflake" && section?.includes((item.dropArea))) {
    return (
      <div className="schemaItem"  data-testid='paas-etlCanvas-snowflake-connector'>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={snowflake_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "mysql" && section?.includes((item.dropArea))) {
    return (
      <div className="schemaItem"  data-testid='paas-etlCanvas-mysql-connector'>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={Mysqllogo_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "s3" && section?.includes((item.dropArea))) {
    return (
      <div className="schemaItem" data-testid='paas-etlCanvas-s3-connector'>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={AmazonS3_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "mongo" && section?.includes((item.dropArea))) {
    return (
      <div className="schemaItem" data-testid='paas-etlCanvas-mongo-connector'>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={MongoDB_logo}
            style={{ height: "45px",width: '45px' }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "dremio" && section?.includes((item.dropArea))) {
    return (// need to think of alternative for specifying the which is source and target
      <div className="schemaItem" data-testid={section?.includes((item.dropArea)) ? 'paas-etlCanvas-postgres-sourceConnector':'paas-etlCanvas-postgres-targetConnector'}>
        <div className="schemaBox" ref={dragRef} >
          <img
            src={Dremio_logo}
            style={{ height: "45px" ,width:'40px'}}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  } else if (item?.imgID === "sfdc" && section?.includes((item.dropArea))) {
    return (// need to think of alternative for specifying the which is source and target
      <div className="schemaItem" data-testid={section?.includes((item.dropArea)) ? 'paas-etlCanvas-postgres-sourceConnector':'paas-etlCanvas-postgres-targetConnector'}>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={SalesForce_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  }  else if (item?.imgID === "sftp" && section?.includes((item.dropArea))) {
    return (
      <div className="schemaItem" data-testid={section?.includes((item.dropArea)) ? 'paas-etlCanvas-postgres-sourceConnector':'paas-etlCanvas-postgres-targetConnector'}>
        <div className="schemaBox" ref={dragRef}>
          <img
            src={sftp_logo}
            style={{ height: "45px" }}
            alt="schemaIcon"
          />
        </div>
        <label>{item?.value}</label>
      </div>
    );
  }
}
