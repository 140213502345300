import React, { useContext } from "react";
import { Badge, Pagination } from "@nutanix-ui/prism-reactjs";
import LeoContext from "../../../contexts/leoContexts/leoContext";

const Footer = ({
  id,
  expenseFlightTotal,
  expenseTotal,
  flightTotal,
  detailsPageSize,
  setDetailsPageSize
}) => {
  const { activeSpenders, activeDetails, currentSelection, setCurrentSelection } = useContext(LeoContext);

  const renderBadges = () => {
    if (id === "topSpenders") {
      return (
        <div className="cardFooter">
          {activeSpenders === "1" && (
            <>
              <Badge color="#59BCF9" text="Expenses" />
              <Badge color="#1B6DC6" text="Flights" />
            </>
          )}
          {activeSpenders === "2" && <Badge color="#59BCF9" text="Expenses" />}
          {activeSpenders === "3" && <Badge color="#1B6DC6" text="Flights" />}
        </div>
      );
    }
    return (
      <div className="cardFooter">
        {id === "expensesDetails" && (
          <div className="detailFooter">
            {activeDetails === "1" && expenseFlightTotal > 0 && (
              <Pagination
                total={expenseFlightTotal}
                currentPage={
                  currentSelection.expenseAndFlightPagination.expenseFlightDetail
                }
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      expenseFlightDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {activeDetails === "2" && expenseTotal > 0 && (
              <Pagination
                total={expenseTotal}
                currentPage={
                  currentSelection.expenseAndFlightPagination.expenseDetail
                }
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      expenseDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {activeDetails === "3" && flightTotal > 0 && (
              <Pagination
                total={flightTotal}
                currentPage={
                  currentSelection.expenseAndFlightPagination.flightDetail
                }
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      flightDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
          </div>
        )}
        {(id === "topSpenders" || id === "comparison" || id === "growth") && (
          <div className="centerAligned">
            <Badge color="#59BCF9" text="Expenses" />
            <Badge color="#1B6DC6" text="Flights" />
          </div>
        )}
      </div>
    );
  };
  return renderBadges();
};

export default Footer;
