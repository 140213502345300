export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const TABLEAU_BASE_URL = process.env.REACT_APP_TABLEAU_BASE_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const BUILD_TIME = process.env.REACT_APP_BUILD_TIME;
export const GRAFANA_URL = process.env.REACT_APP_GRAFANA_POC_URL;
export const PROJECT_TRACKER = process.env.REACT_APP_PROJECT_TRACKER;

export const ZOOM_DB_ENABLED = process.env.REACT_APP_ZOOM_DB_ENABLED === "true";
export const VPN_DB_ENABLED = process.env.REACT_APP_VPN_DB_ENABLED === "true";
export const VDI_DB_ENABLED = process.env.REACT_APP_VDI_DB_ENABLED === "true";
export const VIDEO_CONFERENCING_DB_ENABLED = process.env.REACT_APP_VIDEO_CONFERENCING_DB_ENABLED === "true";
export const MESSAGING_DB_ENABLED = process.env.REACT_APP_MESSAGING_DB_ENABLED === "true";
export const CONT_SECURITY_IMPROVEMENT_DB_ENABLED = process.env.REACT_APP_CONT_SECURITY_IMPROVEMENT_DB_ENABLED === "true";
export const AUTHENTICATION_DB_ENABLED = process.env.REACT_APP_AUTHENTICATION_DB_ENABLED === "true";

const KIBANA_SEARCH = process.env.REACT_APP_KIBANA_SEARCH;

export const ZOOM_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_ZOOM_DASHBOARD}`;
export const VPN_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_VPN_DASHBOARD}`;
export const VDI_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_VDI_DASHBOARD}`;
export const VIDEO_CONFERENCING_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_VIDEO_CONFERENCING_DASHBOARD}`;
export const MESSAGING_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_MESSAGING_DASHBOARD}`;
export const CONT_SECURITY_IMPROVEMENT_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_CONT_SECURITY_IMPROVEMENT_DASHBOARD}`;
export const AUTHENTICATION_DASHBOARD = `${KIBANA_SEARCH}${process.env.REACT_APP_AUTHENTICATION_DASHBOARD}`;

export const QUARTER_END_BACKLOG_REPORT = process.env.REACT_APP_QUARTER_END_BACKLOG_REPORT
export const NCR_DASHBOARD_REPORT = process.env.REACT_APP_NCR_DASHBOARD_REPORT
export const SYSTEM_PROGRESSION_REPORT = process.env.REACT_APP_SYSTEM_PROGRESSION_REPORT
export const FULFILLMENT_METRICS_REPORT = process.env.REACT_APP_FULFILLMENT_METRICS_REPORT
export const PORTFOLIO_PRODUCT_SUMMARY_REPORT = process.env.REACT_APP_PORTFOLIO_PRODUCT_SUMMARY_REPORT
export const TABLEAU_DEV_BASE_URL = process.env.REACT_APP_TABLEAU_DEV_BASE_URL

export const ENV = process.env.REACT_APP_ENV
const BASE_URL = ENV ==="stage" ? TABLEAU_DEV_BASE_URL : TABLEAU_BASE_URL
export const TABLEAU_REPORT = `${BASE_URL}/#/views/${PORTFOLIO_PRODUCT_SUMMARY_REPORT}?:iid=1`
export const PAAS_AIRFLOW_URL=process.env.REACT_APP_AIRFLOW_BASE_URL
