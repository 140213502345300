import { Button, MultiSelect, Modal, FormItemProvider, FormItemInput, UploadIcon, Notification, NotificationTrigger, Link, ShareArrowIcon, CloseIcon, FlexLayout } from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useState } from "react";

import '../../styles.css';
import { convertFileIntoJson, fileUploadToNuO } from "../../../../utils/csvDataValidation";
import { fetchAirflowDag, sendFileMetaData } from "../../../../Service/fileUploadUtility";

const SelectWithLabel = FormItemProvider(MultiSelect);

export default function FileUploadModal({ uploadFileModal, handlecloseUploadModal, selectedTemplate, hiddenFileInput, handleChange, handleClick, file, setIsRowError, setErrorContent }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const handleOnSelectedChange = (selectedRows) => {
        setSelectedRows(prevState => selectedRows);
    }
    const [dagMenu, setDagMenu] = useState([]);

    useEffect(() => {
        try {
            fetchAirflowDag(selectedTemplate.templateCode)
                .then(resp => {
                    setIsLoading(prevState => false);
                    if (resp?.length !== 0) {
                        setDagMenu(prevState => resp);
                    } else {
                        setDagMenu(prevState => [{
                            id: '1',
                            label: 'No Data Found'
                        }])
                    }

                })
        } catch (err) {

        }
    }, [selectedTemplate])

    const submitDags = async () => {
        try {
            handlecloseUploadModal();
            await convertFileIntoJson(file, selectedTemplate)
                .then(async res => {          
                    await fileUploadToNuO(file, selectedTemplate)
                        .then(resp => {
                            NotificationTrigger.add({
                                id: 'upload success',
                                className: 'successAlert',
                                message: `File has been accepted. You can check for status in table below`,
                                type: 'info',
                                autoDismissDelaySecs: 60,
                            });
                            sendFileMetaData(selectedTemplate, file, selectedRows)
                                .then(resp => {
                                    NotificationTrigger.add({
                                        id: 'upload success',
                                        className: 'successAlert',
                                        message: `${resp.message}`,
                                        type: 'success',
                                        autoDismissDelaySecs: 90,
                                    });
                                })
                                .catch(err => {
                                    window.location.replace('/error')
                                })
                        })
                        .catch(err => {
                            window.location.replace('/error')
                        })
                })
                .catch(err => {
                    if (err.fileError) {
                        NotificationTrigger.add({
                            id: 'upload-failed-file-error',
                            message: 'File has failed to upload due to format mismatch. Please align your file with respect to the selected Template',
                            type: 'error',
                            autoDismissDelaySecs: 30,
                        })
                    } else if (err.rowError) {
                        setIsRowError(prevState => !prevState);
                        setErrorContent(prevState => err.corruptRecords);
                        NotificationTrigger.add({
                            id: 'upload-failed-file-error',
                            message: 'File has failed to upload. Please check error modal for more details.',
                            type: 'error',
                            autoDismissDelaySecs: 30,
                        })
                    }
                });
        } catch (err) {

        }
    }

    const props = {
        'data-testid': 'default',
        rowsData: dagMenu,
        selectedRows: selectedRows,
        onSelectedChange: handleOnSelectedChange,
        label: 'Dags',
        groupNestedItemsTag: false,
        selectAllOption: true,
        loading: isLoading,
        treeViewProps: {
            treeItemIdPrefix: 'a11y-example-'
        },
        clearInputValueOnRowSelected: false,
        clearButtonIconProps: {
            'data-test-id': 'my-clear-button',
            className: 'myClearButton'
        },
        dropdownProps: {
            className: 'custom-class-name',
            'data-test-id': 'dropdown-test-1'
        },
    };

    return <Modal
        visible={uploadFileModal}
        onClose={handlecloseUploadModal}
        title={<h3 style={{ textAlign: 'start' }} >Upload a File</h3>}
        headerActions={[ <FlexLayout
            htmlTag="button"
            key="save-btn"
            onClick={handlecloseUploadModal}
            data-testid='modalCloseButton'
            type={Button.ButtonTypes.ICON_DEFAULT}
        >
         <Link style={{color:'#1890ff',marginRight:'-16px',fontSize:'16px'}} onClick={()=>window.open('https://confluence.eng.nutanix.com:8443/display/DataScience/File+Upload+Steps')}>Guidelines</Link><ShareArrowIcon color='#1890ff'/> 
            <CloseIcon key="close" />
        </FlexLayout>
        ]}
        footer={<Button onClick={submitDags}>
            Submit
        </Button>}
    >
        <div className="uploadFileModalContainer">
            <div className="uploadBtnTitleContainer">
                <h3>Template Name</h3>
                <FormItemInput
                    defaultValue={selectedTemplate?.label}
                    disabled
                    label="Template Name"
                />
            </div>
            <input
                data-testid="uploader"
                type="file"
                id="images"
                accept=".csv"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                onClick={(event) => event.target.value = null}
                required
            />
            <div className="uploadBtnTitleContainer">
                <h3>Browse your file system to choose the appropriate file</h3>
                <div className="uploadBtnContainer">
                    <Button className="uploadButton" onClick={handleClick} data-testid="uploadButton" >
                        <UploadIcon />
                        <p>Select File</p>
                    </Button>
                </div>
            </div>
            <div className="uploadBtnTitleContainer">
                <h3>Review the Airflow DAGs (all DAGs will be selected by default)</h3>
                <div>
                    <SelectWithLabel {...props} />
                </div>
            </div>
        </div>
        <Notification className="alert modal " data-testid="notifications">
            <div
                style={{
                    position: 'fixed',
                    top: '80px',
                    right: '340px'
                }}

            />
        </Notification>
    </ Modal>
}
