import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";
import PipelineSetting from "./PipelineSetting";
import InputPane from "./InputPane";

import "./styles.css";
import SchemaSelection from "./SchemaSelection";
import { SourceInputList, TargetInputList } from "./InputList";
import { useLocation } from 'react-router-dom';

import axios from "axios";
import { SERVER_URL } from "../../config/config";
import { sendUserRecord } from "../../Service/adoptionMetric";
export default function PaaS() {
  const location = useLocation();
  const { pipeID, project,process_name, edit } = location.state;
  const [pipelineData, setPipelineData] = useState({
    SourceSchema: {},
    TargetSchema: {},
  });
  const [processorConfigForm, setProcessorConfigForm] = useState([]);
  const [data, setData] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const pipeLineName = queryParameters.get("pipelineName");
  const projectID = queryParameters.get("projectID");
  const pipeLineID = queryParameters.get("pipelineID");
  const umail = localStorage.getItem("email");
  useEffect(()=>{
    if(process_name?.length>0){
      axios
      .post(`${SERVER_URL}/fetchProcessData`, {
      project_id: project.value,
      pipeline_id:pipeID,
      process_name: process_name,
      user:umail
      })
      .then((resp) => {
        if(resp?.status === 200) {
          let inputArray =JSON.parse(resp?.data?.data?.proc_template)['input'];
          let outPutArray =JSON.parse(resp?.data?.data?.proc_template)['output'];
          let transfromQuery = JSON.parse(resp?.data?.data?.proc_template)['query'];
          if(inputArray?.length>0){
            let connectionName = '';
            let connectionObj = {};
            for(let i=0;i<inputArray.length;i++){
              connectionName = (inputArray[i].vault_path).split('/').pop()
              let obj={
                bucket :inputArray[i]?.bucket || '',
                db:inputArray[i].db,
                dbType : "RDBMS",
                id: Date.now().toString(36) + Math.random().toString(12),
                value : (inputArray[i].vault_path).split('/').pop(),
                imgID : inputArray[i]?.format === 'rdbms' ? 
                        (inputArray[i]?.driver.includes('postgres') ? 'postgres' : inputArray[i]?.driver.includes('mysql') ? 'mysql':  inputArray[i]?.driver.includes('netsuite') ? 'netsuite' : ''):inputArray[i]?.format,
                label : inputArray[i]?.format === 'rdbms' ? 
                        (inputArray[i]?.driver.includes('postgres') ? 'postgres' : inputArray[i]?.driver.includes('mysql') ? 'mysql':  inputArray[i]?.driver.includes('netsuite') ? 'netsuite' : ''):inputArray[i]?.format,
                warehouse : inputArray[i].warehouse,
                formData :{
                  bucket :inputArray[i]?.bucket || '',
                  alias : inputArray[i].alias,
                  dbType : "RDBMS",
                  last_date : inputArray[i].last_n_time_data,
                  key_cols : inputArray[i].key_cols,
                  offset_col : { key: inputArray[i].offset_column, label: inputArray[i].offset_column},
                  array_cols :inputArray[i].array_cols || '',
                  operation : {key: inputArray[i].operation || 'upsert', label: inputArray[i].operation || 'upsert'},
                
                  query : {projectID: pipeID, 
                            pipeLine: {
                              pipeID: pipeID,
                              queryData: { value: btoa(inputArray[i].query) },
                            }},
                        
                  schemaForm : {key: inputArray[i].schema, label: inputArray[i].schema},
                  schema : {key: inputArray[i].schema, label: inputArray[i].schema},
                  start_offset : {key: inputArray[i].starting_offset, label: inputArray[i].starting_offset},
                  starting_offset : {key: inputArray[i].starting_offset, label: inputArray[i].starting_offset},
                  tableName : {key: inputArray[i].table_name, label: inputArray[i].table_name},
                  table : {key: inputArray[i].table_name, label: inputArray[i].table_name} || '',
                  buffer_size :inputArray[i].buffer_size|| '',
                  data_load_type : {key: inputArray[i].data_load_type || '', label: inputArray[i].data_load_type || ''}|| '',
                  start_time : inputArray[i].start_time|| '',
                  end_time : inputArray[i].end_time|| '',
                  parallelism : inputArray[i].parallelism|| '',
                  columnsData :inputArray[i]?.columns?.split(',')?.map((value, index) => ({'id': ++index, 'key': value,'label': value })),
                  columns : inputArray[i]?.columns,
                  file_path : inputArray[i].file_path,
                  schema_mapping :  ['s3','mongo','dremio','sfdc','sftp'].includes(inputArray[i].format) ?  JSON.stringify(inputArray[i]?.schema_mapping) : inputArray[i]?.schema_mapping ? JSON.stringify(Object.entries(inputArray[i]?.schema_mapping).map(([key, value]) => ({ [key]: value }))):'[]',
                }
                
              }
              if (connectionName && connectionObj[connectionName]) {
                connectionObj[connectionName].push(obj);
              } else {
                connectionObj[connectionName] = [obj];
              }
            }

            setPipelineData(prevState => ({
              ...prevState,
              SourceSchema: connectionObj,
            }));
          }
          if(outPutArray?.length>0){
            let connectionName = '';
            let connectionObj = {};
            for(let i=0;i<outPutArray.length;i++){
              connectionName = (outPutArray[i].vault_path).split('/').pop()
              let obj={
                db : outPutArray[i].db,
                dbType :"RDBMS",
                formData : {
                array_cols : outPutArray[i].array_cols || "NA",
                columns : "",
                columnsData : [],
                dbType : "RDBMS",
                key_cols : outPutArray[i].key_cols,
                offset_col : { key: outPutArray[i].offset_column, label: outPutArray[i].offset_column},
                operation : {key: outPutArray[i].operation, label: outPutArray[i].operation},
                query : {projectID: pipeID, 
                  pipeLine: {
                    pipeID: pipeID,
                    queryData: { value: btoa(outPutArray[i].query) },
                  }},
                schemaForm : {key: outPutArray[i].schema, label: outPutArray[i].schema},
                start_offset : {key: outPutArray[i].starting_offset, label: outPutArray[i].starting_offset},
                tableName : {key:  outPutArray[i].table_name, label:  outPutArray[i].table_name},
                truncate_and_load : outPutArray[i].truncate_and_load,
                uuid_columns : outPutArray[i].uuid_columns
                },
                id : Date.now().toString(36) + Math.random().toString(12),
                imgID : outPutArray[i].driver.split('.')[1] ==='postgresql' ? 'postgres' :  outPutArray[i].driver.split('.')[1] ==='snowflake' ? 'snowflake' :outPutArray[i].driver.split('.')[1] ==='mysql' ? 'mysql' : 's3',
                label :outPutArray[i].driver.split('.')[1] ==='postgresql' ? 'postgres' :  outPutArray[i].driver.split('.')[1] ==='snowflake' ? 'snowflake' :outPutArray[i].driver.split('.')[1] ==='mysql' ? 'mysql' : 's3',
                value : connectionName,
                warehouse : outPutArray[i].warehouse
              }

              if (connectionName && connectionObj[connectionName]) {
                connectionObj[connectionName].push(obj);
              } else {
                connectionObj[connectionName] = [obj];
              }
            }
            setPipelineData(prevState => ({
              ...prevState,
              TargetSchema: connectionObj,
              transfromQuery: {
                globalQuery: {
                  queryString: {
                    query: btoa(transfromQuery),
                  },
                } 
              },
              processName:process_name
            }));
          }

        }
      })
      .catch((err) => {
      });
    }// eslint-disable-next-line 
  },[edit])
  useEffect(() => {
    pipelineData.project_id = projectID;
    pipelineData.pipelineID = pipeLineID;
    setPipelineData({ ...pipelineData });
    fetchConnection(projectID);// eslint-disable-next-line 
  }, [projectID, pipeLineID]);
  useEffect(() => {
    sendUserRecord('paas', window.location.href)
      .then(resp => { })
      .catch(err => { })
  }, [])

  const fetchConnection = (id) => {
    if (id) {
      axios
        .post(`${SERVER_URL}/fetchConnection`, {
          project_id: id,
        })
        .then((resp) => {
          if(resp?.status === 200) {
            constructConnectionData(resp?.data?.data?.data);
          }
        })
        .catch((err) => {
        });
    }
  };

  const constructConnectionData = (connectionData) => {
    let tempData = [];
    connectionData?.forEach((obj, idx) => {
      tempData.push({
        key: idx,
        label: obj[1] || '',
        value: obj[0] || '',
        imgID: obj[1] || '',
        db: obj[3] ? obj[3]?.database : '',
        warehouse: obj[3] ? obj[3]?.warehouse : "N.A",
        dbType: obj[2],
        dropArea: obj[4] || '',
        formData: obj[5] || [],
      });
    });
    setProcessorConfigForm(tempData)
    setData((prevState) => tempData);
  };
  return (
    <ETLCanvasContext.Provider value={{pipelineData,processorConfigForm}}>
      <ETLCanvasContextDispatcher.Provider value={{setPipelineData,setProcessorConfigForm}}>
        <div style={{ margin: "2rem" }}>
          <PipelineSetting pipeLineName={pipeLineName} />
          <DndProvider backend={HTML5Backend}>
            <div className="processContainer">
              <InputPane data={data} section={['S', 'ST']} />
              <SchemaSelection />
              <InputPane data={data} section={['T', 'ST']} />
            </div>
          </DndProvider>
          <div className="InputsListContainer">
            <SourceInputList
              heading="Source"
              sourceSchema={pipelineData?.SourceSchema}
            />
            <TargetInputList
              heading="Target"
              targetSchema={pipelineData?.TargetSchema}
            />
          </div>
        </div>
      </ETLCanvasContextDispatcher.Provider>
    </ETLCanvasContext.Provider>
  );
}
