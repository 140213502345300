import React from "react";
import { Popover, Button, Progress } from "antd";
import moment from "moment";

export default function DataSyncTableRenderer({ value, rowData, children }) {
    return <LocalPopover data={rowData}>{children}</LocalPopover>;
}

function LocalPopover(props) {
    const { children, data } = { ...props };
    return (
        <Popover
            placement="right"
            style={{ paddingleft: 100 }}
            title={<PopoverTitle title={data?.table_name || ""} />}
            content={<PopoverContent {...data} />}
            trigger="click"
        >
            {children}
        </Popover>
    );
}

function PopoverContent(props) {
    const {
        prod_count,
        stage_count,
        delta,
        prod_etl_run_time,
        prod_data_as_of,
        stage_etl_run_time,
        stage_data_as_of,
    } = { ...props };

    return (
        <article style={{ width: 250 }}>
            <LocalPropertyRenderer
                propertyName={"Production Count"}
                propertyValue={Number(prod_count || 0).toLocaleString()}
            />
            <LocalPropertyRenderer
                propertyName={"Stage Count"}
                propertyValue={Number(stage_count || 0).toLocaleString()}
            />
            <LocalPropertyRenderer
                propertyName={"Delta"}
                propertyValue={Number(delta || 0).toLocaleString()}
            />
            <div style={{ marginTop: 8 }}>
                <p
                    style={{
                        borderBottom: "1px solid #a6a6a6",
                        marginBottom: 4,
                        fontWeight: 600,
                        paddingBottom: 4,
                        fontSize: 12,
                        color: "rgb(99 99 99)",
                    }}
                >
                    ETL Run Time (PDT)
                </p>
                <LocalPropertyRenderer
                    propertyName={"Production"}
                    propertyValue={
                        prod_etl_run_time
                            ? moment(
                                  prod_etl_run_time,
                                  "MMM DD hh:mm A"
                              ).format("MMM DD hh:mm A")
                            : "Nil"
                    }
                />
                <LocalPropertyRenderer
                    propertyName={"Stage"}
                    propertyValue={
                        stage_etl_run_time
                            ? moment(
                                  stage_etl_run_time,
                                  "MMM DD hh:mm A"
                              ).format("MMM DD hh:mm A")
                            : "Nil"
                    }
                />
            </div>
            <div style={{ marginTop: 8 }}>
                <p
                    style={{
                        borderBottom: "1px solid #a6a6a6",
                        marginBottom: 4,
                        fontWeight: 600,
                        paddingBottom: 4,
                        fontSize: 12,
                        color: "rgb(99 99 99)",
                    }}
                >
                    Data As Of (PDT)
                </p>
                <LocalPropertyRenderer
                    propertyName={"Production"}
                    propertyValue={
                        prod_data_as_of
                            ? moment(prod_data_as_of, "MMM DD hh:mm A").format(
                                  "MMM DD hh:mm A"
                              )
                            : "Nil"
                    }
                />
                <LocalPropertyRenderer
                    propertyName={"Stage"}
                    propertyValue={
                        stage_data_as_of
                            ? moment(stage_data_as_of, "MMM DD hh:mm A").format(
                                  "MMM DD hh:mm A"
                              )
                            : "Nil"
                    }
                />
            </div>
        </article>
    );
}

function LocalPropertyRenderer(props) {
    const { propertyName, propertyValue, className } = { ...props };
    let color = "inherit";
    let fontColor = "inherit";

    if (propertyName === "Delta") {
        if (propertyValue !== "0") {
            color = "#fcdede";
            fontColor = "red";
        }
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
                paddingBottom: 2,
                paddingTop: 2,
                background: color,
                color: fontColor,
                fontSize: 12,
                lineHeight: "14px",
            }}
        >
            <p>{propertyName}</p>
            <p>{propertyValue}</p>
        </div>
    );
}

function PopoverTitle({ title }) {
    return (
        <p style={{ fontWeight: 600, fontSize: 12, color: "rgb(99 99 99)" }}>
            {title}
        </p>
    );
}
