import axios from 'axios';
import moment from 'moment'
import { SERVER_URL } from "./config";

let Logger;
(function (Logger) {
    Logger.info = (logMessage, module, url, eventName = "TBD") => loggerFunction(console.info, logMessage, 'info', module, url, eventName)
    Logger.warn = (warnMessage) => loggerFunction(console.warn, warnMessage, 'warn')
    Logger.error = (errorMessage, module, url, eventName = "TBD") => loggerFunction(console.error, errorMessage, 'error', module, url, eventName)
    Logger.debug = (debugMessage) => loggerFunction(console.debug, debugMessage, 'debug')
})(Logger || (Logger = {}));

const loggerFunction = (logger, log, severity, module, url, eventName) => {
    const LOG_URL = `${SERVER_URL}/logging`
    const timeNow = moment().toISOString()

    // timestamp in utc time
    if (severity === "error") {
        let object = { timestamp: timeNow, level: severity, error: log.message, module: module, eventName: eventName, url: url };
        const { data } = axios.post(LOG_URL, object, {
            withCredentials: true,
        }).then().catch()
        // logger(error)
    } else {
        // logger({timestamp: timeNow, message: log, level: severity})
        let object = { timestamp: timeNow, message: log, level: severity, module: module, eventName: eventName, url: url };
        const { data } = axios.post(LOG_URL, object, {
            withCredentials: true,
        }).then().catch()
    }

}

export default Logger



