import React, { useContext } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "@nutanix-ui/recharts";
import LeoContext from "../../../../contexts/leoContexts/leoContext";
import { transformExpenseData } from "../../utils";
import NoData from "./NoData";
const ExpensifyComparison = ({ margin }) => {
  const { expensifyData, loaders } = useContext(LeoContext);
  const data = transformExpenseData(expensifyData);
  if (data.length <= 0 && !loaders.loadingAll) {
    return <NoData />;
  }
  // Custom X-axis tick component
  const CustomAxisTick = ({ x, y, payload }) => {
    let total = 0
    if(data.length>0){
      const filteredItem = data.filter(item => payload.value === item.name)
      total = `$${(filteredItem[0].Expenses + filteredItem[0].Flights).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          y={-5}
          dy={16}
          textAnchor="middle"
          fill="rgb(154, 165, 181)"
          className="axisValue"
        >
          {`${payload.value}`}
        </text>
        <text
          y={25}
          textAnchor="middle"
          fill="rgb(154, 165, 181)"
          className="axisValue"
        >
          {`${total}`}
        </text>
      </g>
    );
  };
  return (
    <ResponsiveContainer height={200} width="100%">
      <BarChart 
        data={data.slice(1)} 
        barSize={30}
        alignment="none"
        margin={margin}
      >
        <CartesianGrid horizontal={true} stroke="#eee" />
        <XAxis tickline={true} dataKey="name" axisLine={true} tick={<CustomAxisTick />}  />
        <YAxis
         tickFormatter={(value) => {
          if (value === 0) {
            return "0";
          } else if (value >= 1000) {
            return `$${(value / 1000).toLocaleString()}k`;
          } else {
            return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }}
        />
        <Tooltip
            formatter={(value, name) => {
              const formattedValue = Number(value).toFixed(2);
              const [integerPart, decimalPart] = formattedValue.split(".");
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              const formattedTooltipValue = `$${formattedIntegerPart}.${decimalPart}`;
              return decimalPart === "00"
                ? [formattedIntegerPart, name]
                : [formattedTooltipValue, name];
            }}
          />
        <Bar dataKey="Expenses" fill="#59BCF9" />
        <Bar dataKey="Flights" fill="#1B6DC6" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ExpensifyComparison;
