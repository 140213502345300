// checkBoxSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isETASelected: true,
  isCurrentSelected: true,
  isUpcomingSelected: true,
  isAssignedSelected: true,
  isCompletedSelected: false,
  isOpenSelected: true,
  selectedCalenderView: {
    label: 'Weekly',
    key: '2',
  },
  selectedCalenderViewBy: {
    label: "Component",
    key: "1",
  },
};

const checkBoxSlice = createSlice({
  name: "checkBox",
  initialState,
  reducers: {
    toggleETASelection: (state, action) => {
      state.isETASelected = action.payload;
    },
    toggleCurrentSelection: (state, action) => {
      state.isCurrentSelected = action.payload;
    },
    toggleUpcomingSelection: (state, action) => {
      state.isUpcomingSelected = action.payload;
    },
    toggleAssignedSelection: (state, action) => {
      state.isAssignedSelected = action.payload;
    },
    toggleCompletedSelection: (state, action) => {
      state.isCompletedSelected = action.payload;
    },
    toggleOpenSelection: (state, action) => {
      state.isOpenSelected = action.payload;
    },

    setSelectedCalenderView: (state, action) => {
      state.selectedCalenderView = action.payload;
    },
    setSelectedCalenderViewBy: (state, action) => {
      state.selectedCalenderViewBy = action.payload;
    },

  },
});

export const {
  toggleETASelection,
  toggleCurrentSelection,
  toggleUpcomingSelection,
  toggleAssignedSelection,
  toggleCompletedSelection,
  toggleOpenSelection,
  setSelectedCalenderView,
  setSelectedCalenderViewBy
} = checkBoxSlice.actions;

export default checkBoxSlice.reducer;
