import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/config';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUser = async () => {
        try {
            const { data } = await axios.post(`${SERVER_URL}/user`);
            setUser(data.email);
            localStorage.setItem("email", data.email);
        } catch (error) {
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
        return () => {
            // Cleanup function to reset user state on unmount
            setUser(null);
            setLoading(true);  // Optionally reset loading state
            localStorage.removeItem("email");
        };
    }, []);

    return (
        <UserContext.Provider value={{ user, loading, fetchUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
