import React, { useState } from 'react'
import UXFilter from './components/UXFilter'
import UXStatus from './components/UXStatus'
import ViewTable from './components/ViewTable'
import { FiltersProvider } from '../../../../contexts/uxContexts/FiltersContext'

const UXContainer = () => {
  const [applyClicked, setApplyClicked] = useState(false);
  return (
    <div className="uxContainer">
      <FiltersProvider>
       <UXFilter applyClicked={applyClicked} setApplyClicked={setApplyClicked}   />
       <UXStatus applyClicked={applyClicked} />
       <ViewTable applyClicked={applyClicked} />
      </FiltersProvider>
    </div>
  )
}

export default UXContainer