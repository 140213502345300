import { Table } from "antd";
import React from "react";
import "./DayView.css";
import { generateColumnsForDayView, generateDataSourceForDayView } from "../../../utils.js";
import { useSelector } from "react-redux";

const DayView = ({ currentWeekDates , title, uniqueComponent, generatedAssigneeData, uniqueAssignee, uniqueBusinessName, generatedBusinessData, generatedComponentData, footer }) => {
  const { currentTeamSelection } = useSelector((state) => state.team);
  const { selectedCalenderViewBy } = useSelector((state) => state.checkbox);
  const columns = generateColumnsForDayView(currentWeekDates, selectedCalenderViewBy, currentTeamSelection?.project_id)
  const dataSource = generateDataSourceForDayView(columns, uniqueComponent, generatedComponentData)
  const dataSourceAssignee = generateDataSourceForDayView(columns, uniqueAssignee, generatedAssigneeData)
  const dataSourceBusinessName = generateDataSourceForDayView(columns, uniqueBusinessName, generatedBusinessData)
  return (
    <>
      <Table
        className="jira-ssot-table"
        columns={columns}
        dataSource={
          currentTeamSelection.project_id === 12530 ? selectedCalenderViewBy?.label === 'Component' ? dataSource : 
          selectedCalenderViewBy?.label === 'Assignee Name' ? dataSourceAssignee : 
          dataSourceBusinessName : dataSourceAssignee
        }
        size="small"
        title={title}
        footer={footer}
        pagination={false}
        scroll={{ y: 320 }}
      />
    </>
  );
};

export default DayView;
