import React, { useContext } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  LineDot,
} from "@nutanix-ui/recharts";
import { Badge, TextLabel } from "@nutanix-ui/prism-reactjs";

import LeoContext from "../../../../contexts/leoContexts/leoContext";
import { transformGrowthData } from "../../utils";
import NoData from "./NoData";
const ExpensifyGrowth = ({ margin }) => {
  const { expensifyData, loaders } = useContext(LeoContext);
  const data = transformGrowthData(expensifyData);
  if (data.length <= 0 && !loaders.loadingAll) {
    return <NoData />;
  }

  const calculatePercentageChange = (prevTotalAmount, nextTotalAmount) => {
    return (
      ((nextTotalAmount - prevTotalAmount) / nextTotalAmount) *
      100
    ).toFixed(2);
  };

  const CustomAxisTick = ({ x, y, payload }) => {
    let percentageChange = "";
    if (data.length > 0) {
      // Filter the data based on the payload value
      const filteredItem = data.find((item) => item.name === payload.value);

      if (filteredItem) {
        // Find the percentage change from the previous quarter
        const currentIndex = data.findIndex(
          (item) => item.name === payload.value
        );
        if (currentIndex > 0) {
          const prevTotalAmount = data[currentIndex - 1].totalAmount;
          const nextTotalAmount = filteredItem.totalAmount;
          percentageChange = calculatePercentageChange(
            prevTotalAmount,
            nextTotalAmount
          );
        }
      }
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          y={-5}
          dy={16}
          textAnchor="middle"
          fill="rgb(154, 165, 181)"
          className="axisValue"
        >
          {`${payload.value}`}
        </text>
        <text
          y={-16}
          dy={45}
          textAnchor="middle"
          fill="rgb(154, 165, 181)"
          className="axisValue"
        >
          {`${percentageChange}%`}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let percentageChange = "";
      if (data.length > 0) {
        // Filter the data based on the payload value
        const filteredItem = data.find(
          (item) => item.name === payload[0]?.payload.name
        );
        if (filteredItem) {
          // Find the percentage change from the previous quarter
          const currentIndex = data.findIndex(
            (item) => item.name === payload[0]?.payload.name
          );
          if (currentIndex > 0) {
            const prevTotalAmount = data[currentIndex - 1].totalAmount;
            const nextTotalAmount = filteredItem.totalAmount;
            percentageChange = calculatePercentageChange(
              prevTotalAmount,
              nextTotalAmount
            );
          }
        }
      }
      return (
        <>
          <div className="custom-tooltip-leo">
            <TextLabel
              display="block"
              size={TextLabel.TEXT_LABEL_SIZE.SMALL}
              type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
              style={{ marginBottom: "10px" }}
            >
              {payload[0]?.payload.name}
            </TextLabel>
            <div className="tooltipContainer">
              <div className="tooltipData">
                <div className="textContainer">Overall Growth</div>
                <div className="data">{`${percentageChange}%`}</div>
              </div>
              <div className="tooltipData">
                <div className="badgeContainer">
                  <Badge color="rgb(89, 188, 249)" />
                  Expenses
                </div>
                <div className="data">{`${
                  payload[0]?.payload.Expenses
                    ? payload[0]?.payload.Expenses.toFixed(2)
                    : 0
                }%`}</div>
              </div>
              <div className="tooltipData">
                <div className="badgeContainer">
                  <Badge color="rgb(27, 109, 198)" />
                  Flights
                </div>
                <div className="data">{`${
                  payload[0]?.payload.Flights
                    ? payload[0]?.payload.Flights.toFixed(2)
                    : 0
                }%`}</div>
              </div>
            </div>
          </div>
          <div className="custom-tooltip-leo-arrow"></div>
        </>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer height={200} width="100%">
      <LineChart data={data.slice(1)} margin={margin}>
        <CartesianGrid stroke="#eee" interval={0} margin={{ left: "20px" }} />
        <XAxis
          dataKey="name"
          axisLine={true}
          interval={0}
          tick={<CustomAxisTick />}
        />
        <YAxis
          hide={true}
          width={100}
          // tickFormatter={ value => `${value}%` }
        />
        <Line
          dot={(props) => {
            const { cx, cy, index, stroke } = props;
            return <LineDot color={stroke} cx={cx} cy={cy} key={index} />;
          }}
          dataKey="Expenses"
          stroke={"#59BCF9"}
          type="linear"
        />
        <Line
          dot={(props) => {
            const { cx, cy, index, stroke } = props;
            return <LineDot color={stroke} cx={cx} cy={cy} key={index} />;
          }}
          dataKey="Flights"
          stroke={"#1B6DC6"}
          type="linear"
        />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ExpensifyGrowth;
