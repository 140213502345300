import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
    TABLEAU_REPORT
} from "../../config/config";
import "./PnP2.0.css";
import { sendUserRecord } from "../../Service/adoptionMetric";

const PnP2 = () => {

    useEffect(() => {
        sendUserRecord('pnp_2.0', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }, []);
    
    return (
        <Row style={{ width: "100%", height: "100%" }}>
            <Col span={24}>
                <div
                    style={{
                        width: "100%",
                        height: "90%",
                        overflow: "auto",
                    }}
                >
                    <tableau-viz
                        id="tableauViz"
                        src={TABLEAU_REPORT}
                        device="desktop"
                        toolbar="bottom"
                        hide-tabs
                        className={"tableauVizContainer"}
                    ></tableau-viz>
                </div>
            </Col>
        </Row>
    );
};

export default PnP2;
