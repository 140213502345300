import React from "react";

import internalError from '../images/internalError.svg';

import { Button } from '@nutanix-ui/prism-reactjs'

import { Link } from 'react-router-dom'

import '../App.css';

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className="errorPageContainer">
            <div className="erroPageContent">
                <div className="errorImage">
                    <img src={internalError} alt="" />
                </div>
                <div className="errorHeading">
                    <h2>Internal Server Error</h2>
                </div>
                <div className="errorParagraph">
                    <p>Try again in a few minutes. If the issue persists, contact your Admin.</p>
                </div>
                <div className="reloadButton">
                    <Button>
                        <Link to="/" style={{color: 'white', margin: '2rem'}}>
                            Go to Home
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    }
}

export default ErrorPage;
