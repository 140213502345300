import React,{useState, useEffect, useRef} from "react";
import { useSelector,useDispatch } from "react-redux";
import { Loader,Badge } from "@nutanix-ui/prism-reactjs";
import moment from "moment";

import AutoFixFilter from "../../Filters/AutoFixFilter";
import AutoFixReportsGraph from "../../Graphs/AutoFixReportsGraph";
import Widget from "../../WidgetContainer/Widget";

import {getAutoFixFilterData,columnData} from '../../../AutoFixReportsUtil'
import {setDropdownData,setProjectLoading,setSelectedValue,setOpenModal,setTotalSuccessVul,setReportsData,setReportsDataLoading} from '../../../../../features/JiraAnalysis/autoFixSlice'
import AutoFixFooter from '../AutoFix/AutoFixFooter'
import ViewAutoFixModal from "./ViewAutoFixModal";
import '../../../../EngineeringDashboard/EnggDashboard.css'
const AutoFixReports = () => {
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const prevStoreValue = useRef(); 
  const dispatch = useDispatch();
  const {loading,openModal,modalData,TotalSuccessVul,dropdownOptions,selectedValues,ReportsData } = useSelector((state) => state.autoFixReports);
    useEffect(() => {
      if (prevStoreValue.current !== dropdownOptions || Object.values(dropdownOptions)[0].length===0) {
        setHasDataChanged(true);
      } else {
        setHasDataChanged(false);
      }
      prevStoreValue.current = dropdownOptions;  
    }, []);
     useEffect(() => {
      let selectedFilterValues ={
            Project: selectedValues.Project || "All",
            Module:  selectedValues.Module || "All",
            Branch:  selectedValues.Branch || "All",
            Level:  selectedValues.Level || "All",
            RepositoryUrl:  selectedValues.RepositoryUrl || "All",
            PRStatus:  selectedValues.PRStatus || "All",
            filter: selectedValues.filter || "month",
          
            viewBy: selectedValues.viewBy || 'Monthly',
            dateRange: { startDate : selectedValues.dateRange.startDate || moment().subtract(1, "month").startOf("day").toDate(),
               endDate :selectedValues.dateRange.endDate || moment().endOf("day").toDate()},
            applyButton:false
          }
          Object.keys(selectedFilterValues).map((key)=>{
      
            dispatch(setSelectedValue({field: key, value: selectedFilterValues[key] }));
          });
          if(hasDataChanged || Object.values(dropdownOptions)[0].length===0){
             dispatch(setReportsData([]));
             dispatch(setTotalSuccessVul(0));
              dispatch(setReportsDataLoading(true));
              getAutoFixFilterData(selectedValues)
              .then(resp => {
                dispatch(
                      setSelectedValue({ field: "applyButton", value: true })
                    );
                dispatch(setProjectLoading(false))
                dispatch(setDropdownData(resp));
              })
              .catch(err => {
                  // window.location.replace('/error')
              })
          }
      }, [dispatch]);


  return (
    <>
    <div className="tabContainer">
      <div className="filterContainer">
        <AutoFixFilter />
      </div>
     
      <div className="widgetContainer" >
        <div className="widgetItems full">
          <Loader
            loading={loading.reportsData}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title={<p>Auto Fix Adoption    <Badge color="green" count={`Total Success Case: ${TotalSuccessVul}`} /></p>}
              // id="avgDeveloper"
              // width="50%"
              body={<AutoFixReportsGraph />}
              footer={<AutoFixFooter />}
            />
          </Loader>
        </div>
      </div>
    </div>
      {
        <ViewAutoFixModal
          modalTitle={'AutoFix Reports'}
          open={openModal}
          onCancel={() => dispatch(setOpenModal(false)) }
          columns={columnData()}
          dataSource={modalData}
        />
      }
      </>
  );
};

export default AutoFixReports;
