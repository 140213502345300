import React, { useState } from "react";
import _ from "lodash";
import "./FactoryData.css";
import { SERVER_URL } from "../../config/config";
import DataTable from "./DataTable";
import AssetModal from "./AssetModal";
import { Link } from "@nutanix-ui/prism-reactjs";
import { formatDate } from "./utility";
import axios from "axios";

const FactoryData = () => {
  // Initialize a single state object to manage multiple state variables
  const [state, setState] = useState({
    modalVisible: false,
    loading: false,
    loadingError: false,
    missingAssets: [],
    lastFetchedShipDate: null,
  });

  const { modalVisible, loading, loadingError, missingAssets } = state;

  const handleModalOpenAndFetchMissingAsset = async (rowData) => {
    const { ship_date } = rowData;

    // Check if the ship_date is the same as the last fetched one
    if (state.lastFetchedShipDate === ship_date) {
      // If it's the same, just open the modal without fetching again
      setState((prevState) => ({
        ...prevState,
        modalVisible: true,
      }));
      return; // Stop further execution since we don't need to fetch again
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
      modalVisible: true,
    }));

    try {
      // Fetch data from API only if the ship_date is different
      const response = await axios.get(
        `${SERVER_URL}/manufacturing-missing-serials?date_shipped=${ship_date}`
      );
      const { data } = response?.data;

      setState((prevState) => ({
        ...prevState,
        missingAssets: data,
        lastFetchedShipDate: ship_date, // Save the fetched ship_date
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loadingError: true,
      }));
      throw error
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const columns = [
    {
      title: <span className="bold-header">Ship Date</span>,
      key: "ship_date",
      render: (value) => {
        return formatDate(value);
      },
    },
    {
      title: <span className="bold-header">SFDC Count</span>,
      key: "sfdc_count",
      textAlign: "center",
    },
    {
      title: <span className="bold-header">Factory Eod File</span>,
      key: "factory_eod_file",
      width: 400,
    },
    {
      title: <span className="bold-header">Factory Eod Count</span>,
      key: "factory_eod_count",
      textAlign: "center",
    },
    {
      title: <span className="bold-header">Pg DB Count</span>,
      key: "pg_db_count",
      textAlign: "center",
    },
    {
      title: <span className="bold-header">Mongo DB Count</span>,
      key: "mongo_db_count",
      textAlign: "center",
    },
    {
      title: <span className="bold-header">Match Result</span>,
      key: "match_result",
      textAlign: "center",
    },
    {
      title: <span className="bold-header">Missing Assets</span>,
      key: "missing_assets",
      textAlign: "center",
      render: (value, object) => {
        return (
          <Link
            onClick={() =>
              value !== "NA" && handleModalOpenAndFetchMissingAsset(object)
            }
            className={`missingAssetLink ${value === "NA" ? "disabled" : ""}`}
          >
            {value !== "NA" ? `${value} Asset` : "NA"}
          </Link>
        );
      },
    },
  ];
  return (
    <div className="factoryDataContainer">
      <DataTable
        title={"Factory Data"}
        columns={columns}
        fetchUrl={`${SERVER_URL}/manufacturing-factory-eod`}
        defaultPageSize={15}
      />
      <AssetModal
        modalVisible={modalVisible}
        setState={setState}
        loading={loading}
        loadingError={loadingError}
        missingAssets={missingAssets}
      />
    </div>
  );
};

export default FactoryData;
