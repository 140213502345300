import axios from "axios";

import { Typography } from "antd";
import moment from "moment";
import {
  Badge,
  TextLabel,
  InformationSquareIcon,
} from "@nutanix-ui/prism-reactjs";
import { SERVER_URL } from "../../config/config";

export const CartesianGridColor = "#f2f4f6"
export const getAutoFixFilterData = async () => {
  try {
    return axios
      .get(`${SERVER_URL}/autofix-filter`)
      .then((resp) => {
        return resp?.data?.data || [];
      })
      .catch((err) => {
        throw new Error({ message: err });
      });
  } catch (err) {
    throw new Error({ message: err });
  }
};
const constructData = (data) => {
  return data?.data?.map((week) => {
    const totals = {
      Success: 0,
      Failed: 0,
      Skipped: 0,
      Excluded: 0,
      TotalVulnerabilities: 0,
    };
    week?.data?.forEach((entry) => {
      const { VulnerabilitySummary, ...rest } = entry;
      totals.Success += VulnerabilitySummary.Success || 0;
      totals.Failed += VulnerabilitySummary.Failed || 0;
      totals.Skipped += VulnerabilitySummary.Skipped || 0;
      totals.Excluded += VulnerabilitySummary.Excluded || 0;
      totals.TotalVulnerabilities +=
        VulnerabilitySummary.TotalVulnerabilities || 0;
    });

    return { PRDate: week._id, ...totals, WeekData: week?.data };
  });
};
export const getAutoFixReportsData = async (payload) => {
  try {
    return axios
      .post(`${SERVER_URL}/fetch-autoFix-reports`, payload)
      .then((resp) => {
        let data = [];
        if (payload.filter !== "day") {
          data = resp?.data;
          let constructedData = constructData(data);
          return constructedData || [];
        } else {
            
          data = resp?.data?.data || [];
          return data?.map((item) => {
            const { VulnerabilitySummary, ...rest } = item;

            return {
              ...rest,
              ...VulnerabilitySummary,
            };
          });
        }
      })
      .catch((err) => {
        throw new Error({ message: err });
      });
  } catch (err) {
    throw new Error({ message: err });
  }
};

export const formatDate = (date, isStart = true) =>
  `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
    isStart ? "00:00:00Z" : "23:59:59Z"
  }`;

export const dataMap = () => {
  let dataMap = [
    {
      label: "Success",
      stackId: "a",
      color: "#36D068",
      hide: true,
    },
    {
      label: "Failed",
      stackId: "a",
      color: "#F55656",
      hide: true,
    },
    {
      label: "Skipped",
      stackId: "a",
      color: "#FFBC0B",
      hide: true,
    },
    {
      label: "Excluded",
      stackId: "a",
      color: "#22A5F7",
      hide: true,
    },
  ];

  return dataMap;
};
export const columnData = () => {
  return [
    {
      title: <span className="text_bold">PR Date</span>,
      dataIndex: "PRDate",
      width: 100,
      render: (text, record) => {
        return moment(text).format("MMM D, YYYY ");
      },
    },
    {
      title: <span className="text_bold">Product</span>,
      width: 100,
      dataIndex: "Project",
    },
    {
      title: <span className="text_bold">PR URL</span>,
      dataIndex: "PRUrl",
      width: 130,
      render: (text, record) => {
        return (
          <Typography.Link href={text} target="_blank">
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: <span className="text_bold">Branch</span>,
      dataIndex: "Branch",
    },
    {
      title: <span className="text_bold">Level</span>,
      dataIndex: "Level",
    },
    {
      title: <span className="text_bold">Module</span>,
      dataIndex: "Module",
    },
    {
      title: <span className="text_bold">Total Vulnerabilities</span>,
      dataIndex: "TotalVulnerabilities",
    },
    {
      title: <span className="text_bold">Success</span>,
      dataIndex: "Success",
    },
    {
      title: <span className="text_bold">Failed</span>,
      dataIndex: "Failed",
    },
    {
      title: <span className="text_bold">Skipped</span>,
      dataIndex: "Skipped",
    },
    {
      title: <span className="text_bold">Excluded</span>,
      dataIndex: "Excluded",
    },
  ];
};

export const toolTipContent = (active, payload, label, FooterDataMap) => {
  return (
    <div className="custom-tooltip-velocity" style={{width:"65%"}}>
      <div style={{ display: "flex", flexDirection: "column", gap: 18 }}>
        <TextLabel
          display="block"
          size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
          type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          style={{ fontWeight: "bold" }}
        >
          {moment(label).format("MMM D, YYYY ")}
        </TextLabel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "200px",
          }}
        >
          <TextLabel
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          >
            TotalVulnerabilities
          </TextLabel>
          <span style={{ justifyContent: "end" }}>{`${
            payload?.length > 0 && payload[0]?.payload?.TotalVulnerabilities
          }`}</span>
        </div>
        {FooterDataMap[0].hide && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "200px",
              zIndex: "auto",
            }}
          >
            <Badge color="#36D068" text="Success" />
            <span style={{ justifyContent: "end" }}>{`${
              payload?.length > 0 && payload[0]?.payload?.Success
            }`}</span>
          </div>
        )}
        {FooterDataMap[1].hide && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "200px",
            }}
          >
            <Badge color="#F55656" text="Failed" />
            <span style={{ justifyContent: "end" }}>{`${
              payload?.length > 0 && payload[0]?.payload?.Failed
            }`}</span>
          </div>
        )}
        {FooterDataMap[2].hide && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "200px",
            }}
          >
            <Badge color="#FFBC0B" text="Skipped" />
            <span style={{ justifyContent: "end" }}>{`${
              payload?.length > 0 && payload[0]?.payload?.Skipped
            }`}</span>
          </div>
        )}
        {FooterDataMap[3].hide && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "200px",
            }}
          >
            <Badge color="#22A5F7" text="Excluded" />
            <span style={{ justifyContent: "end" }}>{`${
              payload?.length > 0 && payload[0]?.payload?.Excluded
            }`}</span>
          </div>
        )}
      </div>
      <div style={{ color: "#0D8BF2", display: "flex", gap: 10 }}>
        <div style={{height:'35px'}}>
        <InformationSquareIcon />
        </div>
        <span style={{ color: "#0D8BF2" }}>
          Bars are clickable for deeper pull request insights.
        </span>
      </div>
    </div>
  );
};
