import React, { useEffect, useState } from "react";
import {
    FlexItem,
    Table,
    Sorter,
    FlexLayout, FormItemInput, Button, RemoveIcon, Checkbox
} from "@nutanix-ui/prism-reactjs";

export default function UserDetailsForm(props) {

    const [user, setUser] = useState('');

    const [data, setData] = useState(props.users || []);

    const [loading, setLoading] = useState(true);

    const [isAdmin, setIsAdmin] = useState(false);

    const [isError, setIsError] = useState(false);

    const columns = [{
        title: 'Email Address',
        key: 'email'
    }, {
        title: 'Admin',
        key: 'admin',
        render: (value, object, index) => {
            return (
                <Checkbox checked={object.isAdmin} />
            )
        }
    },
    {
        title: 'Remove',
        key: 'remove',
        render: (value, object, index) => {
            return (
                <div className="userTableAction" data-testid="removeUserButton" onClick={() => handleRemove(index)}><RemoveIcon /></div>
            );
        }
    }];

    useEffect(() => {
        setData(prevState => props.users);
        if (props.users.length > 0) {
            setLoading(false);
        }
    }, [props.users]);

    const handleUserAdd = (e) => {
        setIsError(false);
        setUser(e.target.value);
    }

    const handleRemove = (index) => {
        setData(data.filter((_, i) => index !== i));
        props.setUserData(data.filter((_, i) => index !== i));
    }

    const addUser = () => {
        console.log(user, '123e2')
        const regex = /@nutanix\.com$/;
        if (regex.test(user)) {
            setData(prevState => [...prevState, {
                key: Math.random().toString(36).slice(2, 7),
                email: user,
                isAdmin: isAdmin
            }]);
            props.setUserData(prevState => [...prevState, {
                key: Math.random().toString(36).slice(2, 7),
                email: user,
                isAdmin: isAdmin
            }]);
            setUser('');
            setIsAdmin(false);
        } else {
            setIsError(true);
        }


    }

    const handleIsAdmin = (e) => {
        setIsAdmin(prevState => !prevState);
    }

    const topSection = {
        title: <div className="tableHeader">
            <h3>Users : {data.length}</h3>
        </div>,
    };

    const sort = {
        order: Sorter.SORT_ORDER_CONST.ASCEND,
        column: 'age',
        sortable: ['userID', 'email']
    }

    return <div className="userDetailsContainer">
        <FlexLayout flexDirection="column" justifyContent="center">
            <FlexItem><h3>Share with</h3></FlexItem>
            {isError && <FlexItem><p style={{ color: 'red' }}>Please enter correct email address</p></FlexItem>}
            <FlexLayout flexDirection="row" >
                <FlexItem style={{ width: '100%' }}><FormItemInput className="textField" placeholder="Start typing to add people" width='100%' value={user} onChange={handleUserAdd}
                    wrapperProps={{ 'data-test-id': 'default' }} data-testid="emailInput" error={isError} /></FlexItem>
                <FlexItem>
                    <Checkbox
                        className="textField"
                        data-testid="isAdmin"
                        checked={isAdmin}
                        label="Admin"
                        onChange={handleIsAdmin}
                    /></FlexItem>
                <FlexItem><Button onClick={addUser} data-testid="addUserButton" >Add</Button></FlexItem>
            </FlexLayout>
            <FlexItem>
                <Table
                    showCustomScrollbar={true}
                    dataSource={data}
                    className="historyTable"
                    columns={columns}
                    topSection={topSection}
                    loading={loading}
                    sort={sort}
                    wrapperProps={{
                        'data-test-id': 'customized-options-table'
                    }}
                />
            </FlexItem>
        </FlexLayout>
    </div>
}