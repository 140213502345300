import React from "react";
import { PROJECT_TRACKER } from "../../config/config";
import FloatingButton from "../Datalineage2/FloatingButton";
import { sendUserRecord } from "../../Service/adoptionMetric";

export default class ProjectTracker extends React.Component {
    
    async componentDidMount() {
        sendUserRecord('project_tracker', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }

    render() {
        const tooltipMessage =
            "Data lineage information is not available for Dremio data source currently";
        return (
            <div style={{ height: "100%" }}>
                <FloatingButton
                    onDataLineageClick={this.showModal}
                    disabled={true}
                    tooltipMessage={tooltipMessage}
                />
                <iframe
                    src={`${PROJECT_TRACKER}`}
                    style={{ overflow: "auto", width: "100%", height: "100%" }}
                ></iframe>
            </div>
        );
    }
}
