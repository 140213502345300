import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import {
  filterDataByDropdown,
  generateCarryOverData,
  generateTrendsData,
  getLine,
  getUniqueValuesForSprint,
  getViewDetailDataSource,
  transformProductivity,
  ViewDetailsColumns,
} from "../../utils";
import { Tooltip } from "@nutanix-ui/prism-reactjs";
import TrendLineChart from "../../GraphContainer/Graphs/TrendLineChart";
import BucketizationCarryOver from "../../GraphContainer/Graphs/BucketizationCarryOver";
import ProductivityLineChart from "../../GraphContainer/Graphs/ProductivityLineChart";
import { useBucketisationData, useTrendsData } from "../../apiUtils";
import { Loader } from "@nutanix-ui/prism-reactjs";
import ViewDetailModal from "../../../ViewDetailModal/ViewDetailModal";
import ETAAccurasyTooltip from "../../GraphContainer/Graphs/ETAAccurasyTooltip";

// Set the default position for all messages
message.config({
  top: 70, // Adjust the top position as needed
  right: 0, // Adjust the right position as needed
});

const DashboardStatusCard = ({
  doubleRow = false,
  redText = false,
  blueText = false,
  greenText = false,
  count,
  label,
  title,
  sumStory,
  storyOverView,
}) => {
  const {
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels,
  } = useSelector((state) => state.filter);

  const { currentTeamSelection } = useSelector((state) => state.team);
  const [storyOverviewModal, setStoryOverviewModal] = useState([]);
  const [productivityCount, setProductivityCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [toolTipVisible, setTooltipVisible] = useState(false);
  const [trends, setTrends] = useState([]);
  const [bucketization, setBucketization] = useState([]);

  const { productivity } = useSelector((state) => state.trend);
  const productivityData = transformProductivity(productivity);
  const {
    data: trendsData,
    isLoading: trendsLoading,
    isError: isTrendsError,
    error: trendsError,
  } = useTrendsData(currentTeamSelection?.project_id);
  if (isTrendsError) {
    throw trendsError;
  }

  const {
    data: bucketisationData,
    isLoading: bucketisationLoading,
    isError: isBucketisationError,
    error: bucketisationError,
  } = useBucketisationData(
    currentTeamSelection?.project_id,
    selectedSprint?.[0]
  );
  if (isBucketisationError) {
    throw bucketisationError;
  }

  useEffect(() => {
    if (
      bucketisationData &&
      bucketisationData?.bucketization &&
      bucketisationData?.bucketization.length > 0
    ) {
      const filteredBucketisation = filterDataByDropdown(
        bucketisationData?.bucketization,
        selectedSprint,
        selectedAssignee,
        selectedComponent,
        selectedIssueType,
        selectedLabels
      );
      const carryOver = generateCarryOverData(filteredBucketisation);
      setBucketization(carryOver);
    } else {
      setBucketization([]);
    }
  }, [
    bucketisationData,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels,
    selectedSprint,
  ]);

  useEffect(() => {
    if (
      trendsData &&
      trendsData?.trend_story_point &&
      trendsData?.trend_story_point.length > 0
    ) {
      const trendStoryPointData = trendsData?.trend_story_point || [];
      const filteredTrends = (
        filterDataByDropdown(
          trendStoryPointData,
          null,
          selectedAssignee,
          selectedComponent,
          selectedIssueType,
          selectedLabels
        ) || []
      ).sort((a, b) => (a.sprint_order_num || 0) - (b.sprint_order_num || 0));
      const trends = generateTrendsData(
        getUniqueValuesForSprint(filteredTrends, {
          name: "sprint",
          nullLabel: "Sprint",
        }),
        filteredTrends
      ).filter((item) => item.sprint !== "No Sprint Assigned");
      setTrends(trends);
    } else {
      setTrends([]);
    }
  }, [
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels,
    selectedSprint,
    trendsData,
  ]);

  useEffect(() => {
    if (productivityData.length > 0) {
      const filteredData = productivityData.filter(
        (item) => item.name === selectedSprint[0]
      );
      if (filteredData?.length > 0) {
        setProductivityCount(filteredData[0].value);
      } else {
        setProductivityCount(0);
      }
    }
  }, [productivityData, selectedSprint]);

  const handleOnClick = useCallback(
    (e) => {
      // Reason:
      // The following if statement is not covered in the test suite because the bar graph component
      // does not render on the screen. As a result, the onClick function associated with it is not
      // accessible during testing. This situation prevents the test from interacting with and verifying
      // the functionality of the bar graph click events.

      if (e.activePayload && storyOverView) {
        const { name } = e.activePayload[0]?.payload;
        let filteredStoryPoint;
        switch (name) {
          case "2 Sprints":
            filteredStoryPoint = storyOverView.filter(
              (item) => item.cnt_sprint === 2
            );
            break;
          case "3 Sprints":
            filteredStoryPoint = storyOverView.filter(
              (item) => item.cnt_sprint === 3
            );
            break;
          case ">4 Sprints":
            filteredStoryPoint = storyOverView.filter(
              (item) => item.cnt_sprint >= 4
            );
            break;
          default:
            filteredStoryPoint = storyOverView;
        }
        setStoryOverviewModal(filteredStoryPoint);
        setOpenModal(true);
      } else if (storyOverView) {
        setStoryOverviewModal(storyOverView);
        setOpenModal(true);
      }
    },
    [storyOverView]
  );

  const dataSource = getViewDetailDataSource(storyOverviewModal);
  const line = getLine(label);
  const height = 80;
  const width = "90%";
  return (
    <>
      <div
        onMouseLeave={() => {
          setTooltipVisible(false);
        }}
        data-testid="dashboardCardContainer"
        className={`dashboardCardContainer ${doubleRow ? "doubleRow" : ""}`}
      >
        <div className={`dashboardInnerContainer`}>
          {label === "Total Jira" && (
            <div className="doubleContainer">
              <div className="totalJiraContainer">
                <div
                  data-testid="totalJiraContainer"
                  className="labelContainer"
                  onClick={() =>
                    count !== "0"
                      ? handleOnClick(true)
                      : message.info(
                          "No relevant data available for the selected criteria. Please adjust your filters and try again.",
                          [3]
                        )
                  }
                >
                  <div
                    className={`labelCount ${
                      redText
                        ? "missed animated pulse infinite"
                        : blueText
                        ? "current"
                        : greenText
                        ? "completed"
                        : ""
                    } 
                  ${doubleRow ? "doubleLabel" : ""}`}
                  >
                    {count}
                  </div>
                  <div
                    data-testid="labeTextTotalJira"
                    className={`labelText ${doubleRow ? "doubleText" : ""}`}
                  >
                    {title}
                  </div>
                  <div className="storypoints">{`${sumStory} SP`}</div>
                </div>
                <div className="graphContainer">
                  {trendsLoading && (
                    <Loader
                      loaderClassName="-gray"
                      tip="Trends..."
                      data-test-id="overlay"
                    />
                  )}
                  {!trendsLoading && (
                    <TrendLineChart
                      label={label}
                      line={line}
                      trendata={trends}
                      height={height}
                      width={width}
                    />
                  )}
                </div>
              </div>
              <div className="totalJiraContainer">
                <div className="labelContainer prodctivityContainer">
                  <div
                    className={`labelCount ${
                      redText
                        ? "missed animated pulse infinite"
                        : blueText
                        ? "current"
                        : greenText
                        ? "completed"
                        : ""
                    } 
                  ${doubleRow ? "doubleLabel" : ""}`}
                  >
                    {productivityCount}
                  </div>
                  <div
                    data-testid="labeTextProductivity"
                    className={`labelText ${doubleRow ? "doubleText" : ""}`}
                  >
                    Productivity
                  </div>
                </div>
                <div className="graphContainer">
                  <ProductivityLineChart
                    data={productivityData}
                    height={height}
                    width={width}
                  />
                </div>
              </div>
            </div>
          )}
          {label !== "Total Jira" && label !== "ETA Accuracy" && (
            <>
              <div
                data-testid={`labelContainer-${label}`}
                className="labelContainer"
                onClick={() =>
                  count !== "0"
                    ? handleOnClick(true)
                    : message.info(
                        "No relevant data available for the selected criteria. Please adjust your filters and try again.",
                        [3]
                      )
                }
              >
                <div
                  className={`labelCount ${
                    redText
                      ? "missed animated pulse infinite"
                      : blueText
                      ? "current"
                      : greenText
                      ? "completed"
                      : ""
                  } 
                  ${doubleRow ? "doubleLabel" : ""}`}
                >
                  {count}
                </div>
                <div className={`labelText ${doubleRow ? "doubleText" : ""}`}>
                  {title}
                </div>
                <div className="storypoints">{`${sumStory} SP`}</div>
              </div>
              {label !== "Carry Over" && (
                <div className="graphContainer">
                  {trendsLoading && (
                    <Loader
                      loaderClassName="-gray"
                      tip="Trends..."
                      data-test-id="overlay"
                    />
                  )}
                  {!trendsLoading && (
                    <TrendLineChart
                      label={label}
                      line={line}
                      trendata={trends}
                      height={height}
                      width={width}
                    />
                  )}
                </div>
              )}
              {label === "Carry Over" && (
                <div className="graphContainer bucketisaton">
                  <div className="graphContainer">
                    {trendsLoading && (
                      <Loader
                        loaderClassName="-gray"
                        tip="Trends..."
                        data-test-id="overlay"
                      />
                    )}
                    {!trendsLoading && (
                      <TrendLineChart
                        label={label}
                        line={line}
                        trendata={trends}
                        height={50}
                        width={width}
                      />
                    )}
                  </div>
                  <div className="graphContainer">
                    {bucketisationLoading && (
                      <Loader
                        loaderClassName="-gray"
                        tip="Trends..."
                        data-test-id="overlay"
                      />
                    )}
                    {!bucketisationLoading && (
                      <BucketizationCarryOver
                        carryOver={bucketization}
                        handleOnClick={handleOnClick}
                        height={40}
                        width={width}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {label === "ETA Accuracy" && (
            <div className="doubleContainer etaAccuracyContainer">
              <div className="labelContainer prodctivityContainer">
                <div
                  data-testid="totalJiraContainer"
                  className="labelContainer"
                >
                  <div className="etaTrends">
                    {trendsLoading && (
                      <Loader
                        loaderClassName="-gray"
                        tip="Trends..."
                        data-test-id="overlay"
                      />
                    )}
                    {!trendsLoading && (
                      <TrendLineChart
                        label={label}
                        line={line}
                        trendata={trends}
                        height={height}
                        width={width}
                      />
                    )}
                  </div>
                </div>
                <Tooltip
                  oldTooltip={false}
                  appearance={Tooltip.APPEARANCE.SECONDARY}
                  content={
                    <ETAAccurasyTooltip
                      handleClickOnToolTip={handleOnClick}
                      toolTipVisible={toolTipVisible}
                      counts={{
                        total: storyOverView.length,
                        percentage: count,
                      }}
                    />
                  }
                >
                  <div
                    onMouseEnter={() => {
                      setTooltipVisible(true);
                    }}
                  >
                    <div
                      className={`labelCount ${
                        count > 50
                          ? "completed"
                          : count < 40
                          ? "missed"
                          : "upcoming"
                      } 
                  ${doubleRow ? "doubleLabel" : ""}`}
                      style={{ fontSize: "35px", textAlign: "center" }}
                    >
                      {count + "%"}
                    </div>
                    <div
                      data-testid="labeTextProductivity"
                      className={`labelText ${doubleRow ? "doubleText" : ""}`}
                      style={{ fontSize: "18px", textAlign: "center" }}
                    >
                      ETA Accuracy
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
      {
        <ViewDetailModal
          modalTitle={label}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          columns={ViewDetailsColumns}
          dataSource={dataSource}
        />
      }
    </>
  );
};

export default DashboardStatusCard;
