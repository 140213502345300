import { FormItemProvider, Modal, Button, MultiSelect, NotificationTrigger, LocalTable } from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useState } from "react";
import { fetchAirflowDag, integrateDagWithTemplate } from "../../../../Service/fileUploadUtility";

const SelectWithLabel = FormItemProvider(MultiSelect);

export default function SelectAirflowDag({ modalVisible, handleModalClose, templateName, templateID, dagMenuData }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dagLoading, setDagLoading] = useState(false);
  const handleOnSelectedChange = (selectedRows) => {
    setSelectedRows(prevState => selectedRows);
  }
  const [prevDag, setPrevDag] = useState([]);
  const [dagData, setDagData] = useState([]);

  const columns = [
    {
      title: "DAG ID",
      key: 'dag_id'
    },
    {
      title: "DAG Name",
      key: 'dag_name'
    }
  ]

  useEffect(() => {
    if (templateID) {
      setDagLoading(prevState => true);
      const tempData = [];
      fetchAirflowDag(templateID)
        .then(resp => {
          resp.forEach(ob => {
            tempData.push({
              key: Math.random().toString(36).slice(2, 7),
              dag_id: ob.id,
              dag_name: ob.label
            });
          });
          setPrevDag(prevState => tempData);
          setDagLoading(prevState => false);
        })
    }
  }, [templateID]);

  useEffect(() => {
    if (dagMenuData?.length > 0) {
      setIsLoading(prevState => false);
      setDagData(prevState => dagMenuData);
    }
  }, [dagMenuData])

  const submitDags = () => {
    try {
      integrateDagWithTemplate(templateID, selectedRows)
        .then(resp => {
          handleModalClose();
          setSelectedRows(prevState => []);
          NotificationTrigger.add({
            id: 'upload success',
            className: 'successAlert',
            message: `Dags has been integrated with Template`,
            type: 'info',
            autoDismissDelaySecs: 60,
          });
        })
    } catch (err) {
      NotificationTrigger.add({
        id: "Pipeline  To Make Run Failed",
        className: "successAlert",
        message: "Dags integration with Template has been failed",
        type: "error",
        autoDismissDelaySecs: 5,
      });
    }
  }

  const props = {
    'data-testid': 'default',
    rowsData: dagMenuData,
    selectedRows: selectedRows,
    onSelectedChange: handleOnSelectedChange,
    label: 'Dags',
    groupNestedItemsTag: false,
    selectAllOption: true,
    loading: isLoading,
    treeViewProps: {
      treeItemIdPrefix: 'a11y-example-'
    },
    clearInputValueOnRowSelected: false,
    clearButtonIconProps: {
      'data-test-id': 'my-clear-button',
      className: 'myClearButton'
    },
    dropdownProps: {
      className: 'custom-class-name',
      'data-test-id': 'dropdown-test-1'
    },
  };

  return <Modal
    width={'60%'}
    visible={modalVisible}
    onClose={() => {
      setSelectedRows(prevState => []);
      handleModalClose();
    }}
    title={
      <div>
        <h3 style={{ textAlign: 'left' }}>
          {templateName}
        </h3>
      </div>
    }
    footer={<Button onClick={submitDags}>
      Submit
    </Button>
    }>
    <div style={{ margin: '2rem', gap: '1rem', display: 'flex', flexDirection: 'column' }}>
      <LocalTable
        dataSource={prevDag}
        className="DagTable"
        columns={columns}
        loading={dagLoading}
        wrapperProps={{
          'data-test-id': 'DagTable'
        }}
      />
      <SelectWithLabel {...props} />
    </div>
  </Modal>
}