import { useState } from "react";
import React from 'react';
import {  Tabs } from "antd";
import "./NextGenEtl.css";
import ProjectDetails from "./ProjectDetails";
import Summary from "./Summary";
import TeamDetails from "./TeamDetails";
import Source from "./Source";

const CreateProjectModal = (props) => {
    const { onCancel,tabNo } = { ...props }
    const [tabKey, setTabKey] = useState(tabNo || 1)
    const prevTabClick = () => {
        setTabKey(curr => curr - 1)
    }
    const nextTabClick = () => {
        setTabKey(curr => curr + 1)
    }
    return (
        <div>
            <Tabs
                centered={true}
                activeKey={tabKey.toString()}
                items={[(
                    <span style={{ display: "flex", alignItems: "center", gap: 8 ,fontFamily: "Nutanix Soft"}}>
                        <TabIcon value={1} />
                        Project Details
                    </span>
                ),
                {
                    label: <TabLabel name={"Project Details"} value={"1"} />,
                    key: "1",
                    children: <ProjectDetails onCancel={onCancel} onNext={nextTabClick} />

                },
                {
                    label: <TabLabel name={"Team Details"} value={"2"} />,
                    key: "2",
                    children: <TeamDetails onCancel={onCancel} onNext={nextTabClick} onPrev={prevTabClick} tabNo={tabNo}/>

                },
                {
                    label: <TabLabel name={"Source"} value={"3"} />,
                    key: "3",
                    children: <Source onCancel={onCancel} onNext={nextTabClick} onPrev={prevTabClick} tabNo={tabNo}/>

                },
                {
                    label: <TabLabel name={"Summary"} value={"4"} />,
                    key: "4",
                    children: <Summary onCancel={onCancel} onPrev={prevTabClick} />

                },
                ]}

            />
        </div>
    );
};

export default CreateProjectModal;
const TabLabel = (props) => {
    const { name, value } = { ...props }
    return (
        <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
            {<TabIcon value={value} />}
            {name}
        </span>
    )
}
const TabIcon = (props) => {
    const { value } = { ...props }
    return (

        <p className="labelCircle">{value}</p>

    )
}