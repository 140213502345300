import React from "react";
import { Gauge } from '@ant-design/plots';

export default function DemoGauge({percentage}) {
  const config = {
    percent: [percentage],
    type: 'meter',
    innerRadius: 0.75,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ['#F4664A', '#FAAD14', '#30BF78'],
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: '30px',
          lineHeight: '36px',
        },
      },
    },
  };
  return <Gauge {...config} width={320}/>;
};