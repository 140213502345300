import React, { useEffect, useState, useContext } from "react";
import { Alert, Button, Checkbox, CloseIcon, FlexItem, FlexLayout, FormItemInput, FormItemSelect, PlusIcon } from "@nutanix-ui/prism-reactjs";

import { TemplateFormContext } from "../../../FileTemplateUploadDashboard";

export default function AddColumnsForm() {

    const [formData, setFormData] = useContext(TemplateFormContext);

    const [columnData, setColumnData] = useState(formData?.columnsData || []);

    const [selectedRow, setSelectedRow] = useState({
        key: 0,
        label: 'Select Data Type',
        value: 'select'
    });

    const [columnNameInput, setColumnNameInput] = useState('');

    const [columnDataTypeInput, setColumnDataTypeInput] = useState('')

    const [columnDataTypeValueInput, setColumnDataTypeValueInput] = useState('')

    const [isValidate, setIsValidate] = useState(false);

    const [timeStampMessage, setTimeStampMessage] = useState(false);

    const [isError, setError] = useState(false);

    useEffect(() => {
        setFormData({ ...formData, columnsData: columnData });// eslint-disable-next-line 
    }, [columnData]);

    const handleAdd = () => {
        setTimeStampMessage(prevState => false);
        setColumnData(prevState => [...prevState, {
            key: columnData.length,
            columnName: columnNameInput,
            dataType: columnDataTypeInput,
            dataTypeValue: columnDataTypeValueInput,
            isValidate: isValidate
        }]);
        setColumnNameInput('');
        setColumnDataTypeInput('');
        setColumnDataTypeValueInput('');
        setIsValidate(false);
        setSelectedRow({
            key: 0,
            label: 'Select Data Type',
            value: 'select'
        });
    }

    const hasNoSpecialCharsOrWhitespace = (text) => /^[a-zA-Z0-9_]*$/.test(text);

    const handleRemove = (row) => {
        setColumnData(columnData.filter((column, i) => column.key !== row.key));
    }

    const handleValidationCheck = () => {
        setIsValidate(prevState => !prevState);
    }


    const renderColumn = (colData, isLast) => {
        let label = "Length"
        const data = [
            {
                key: 0,
                label: 'Select Data Type',
                value: 'select'
            },
            {
                key: 1,
                label: 'Small Integer',
                value: 'smallint'
            },
            {
                key: 2,
                label: 'Integer',
                value: 'integer'
            },
            {
                key: 3,
                label: 'Big Integer',
                value: 'bigint'
            },
            {
                key: 4,
                label: 'Small Serial',
                value: 'smallserial'
            },
            {
                key: 5,
                label: 'Serial',
                value: 'serial'
            },
            {
                key: 6,
                label: 'Big Serial',
                value: 'bigserial'
            },
            {
                key: 7,
                label: 'Text',
                value: 'text'
            },
            {
                key: 8,
                label: 'JSON',
                value: 'json'
            },
            {
                key: 9,
                label: 'Real',
                value: 'real'
            },
            {
                key: 10,
                label: 'Double Precision',
                value: 'double precision'
            },
            {
                key: 11,
                label: 'Decimal',
                value: 'decimal'
            },
            {
                key: 12,
                label: 'Varchar',
                value: 'varchar'
            },
            {
                key: 13,
                label: 'Date',
                value: 'date'
            },
            {
                key: 14,
                label: 'Timestamp',
                value: 'timestamp'
            }
        ];

        if (colData?.dataType?.label === "VARCHAR") {
            label = "Length";
        }

        const handleColumnChange = (e) => {
            setColumnNameInput(e.target.value);

            if(hasNoSpecialCharsOrWhitespace(e.target.value)) {
                setError(prevState => false);
            } else {
                setError(prevState => true);
            }
        }

        if (isLast) {
            return <FlexItem style={{ justifyContent: 'center' }}>
                {timeStampMessage && <FlexItem>
                    <Alert
                        type={Alert.AlertTypes.INFO}
                        data-test-id="type-info"
                        aria-live="off"
                        message="this is the format we follow for timestamp: DD/MM/YYYY " />
                </FlexItem>}
                {isError && <FlexItem>
                    <Alert
                        type={Alert.AlertTypes.ERROR}
                        data-test-id="type-info"
                        aria-live="off"
                        message="Please dont use special characters" />
                </FlexItem>}
                <FlexLayout flexDirection="row" alignItems="center" style={{ padding: '1rem 2rem 1rem', background: '#F8F9FA' }}>
                    <FlexItem style={{ width: '30%' }}>
                        <FormItemInput className="textField" placeholder="Type Column Name" label="Column Name" width='100%'
                            data-testid="columnNameInput" wrapperProps={{ 'data-test-id': 'default' }} onChange={handleColumnChange} value={columnNameInput} error={isError} />
                    </FlexItem>
                    <FlexItem style={{ width: '30%' }} data-testid="default">
                        <div>
                            <FormItemSelect
                                className="textField"
                                rowsData={data}
                                label="Data Type"
                                width='100%'
                                selectedRow={selectedRow}
                                onSelectedChange={(item) => {
                                    if (item.value === 'timestamp') {
                                        setTimeStampMessage(prevState => true);
                                    }
                                    setSelectedRow(prevState => item);
                                    setColumnDataTypeInput(prevState => item);
                                }}
                            />
                        </div>
                    </FlexItem>
                    {renderDataTypeValue()}
                    <FlexItem>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '1rem' }}>
                            <label style={{ color: '#627386', fontSize: '12px', fontWeight: 500 }}>Validate</label>
                            <Checkbox
                                id="validation_required"
                                onChange={handleValidationCheck}
                                checked={isValidate} />
                        </div>
                    </FlexItem>
                    <FlexItem>
                        <Button style={{ marginTop: '20px', padding: '0px' }} onClick={handleAdd} data-testid="addButton" disabled={isError}> <PlusIcon /> </Button>
                    </FlexItem>
                </FlexLayout >
            </FlexItem >
        }
        return <FlexItem style={{ justifyContent: 'center' }}>
            <FlexLayout flexDirection="row" alignItems="center" style={{ padding: '1rem 2rem 1rem', background: '#F8F9FA' }}>
                <FlexItem style={{ width: '40%' }}>
                    <FormItemInput className="textField" label="Column Name" width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.columnName} data-testid='default' />
                </FlexItem>
                <FlexItem style={{ width: '40%' }} data-testid="default">
                    <FormItemInput className="textField" label="Data Type" width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.dataType.label} />
                </FlexItem>
                <FlexItem style={{ width: '40%' }} data-testid="default">
                    <FormItemInput className="textField" label={label} width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.dataTypeValue} />
                </FlexItem>
                <FlexItem>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '1rem' }}>
                        <label style={{ color: '#627386', fontSize: '12px', fontWeight: 500 }}>Validate</label>
                        <Checkbox
                            id="validation_required"
                            disabled
                            checked={colData.isValidate} />
                    </div>
                </FlexItem>
                <FlexItem>
                    <Button style={{ marginTop: '20px', background: '#586678', padding: '3px' }} onClick={(e) => handleRemove(colData)} data-testid="removeButton"> <CloseIcon size="small" /> </Button>
                </FlexItem>
            </FlexLayout>
        </FlexItem>
    }

    const renderDataTypeValue = () => {
        let label = "Length"
        let placeHolder = "Please add length here";
        let disabled = false
        if (selectedRow.value !== "decimal" && selectedRow.value !== "varchar") {
            placeHolder = "";
            disabled = true
        } else if (selectedRow.value === "decimal") {
            placeHolder = "10,2";
            label = "Precision";
        } else if (selectedRow.value === "varchar") {
            placeHolder = "100";
            label = "Length";
        }
        return <FlexItem>
            <FormItemInput className="textField" placeholder={placeHolder} label={label} width='100%'
                disabled={disabled} data-testid="dataTypeInput" wrapperProps={{ 'data-test-id': 'default' }} value={columnDataTypeValueInput} onChange={(e) => setColumnDataTypeValueInput(prevState => e.target.value)} />
        </FlexItem>
    }

    const renderAddedColumns = () => {
        return columnData.map((ob, idx) => {
            return renderColumn(ob, false);
        })
    }

    return <div className="addColumnFormContainer">
        <FlexLayout
            flexDirection="column"
        >
            <FlexItem><h3>Add Template Details</h3></FlexItem>
            <FlexItem>{renderAddedColumns()}
                {renderColumn({}, true)}</FlexItem>
        </FlexLayout>
    </div>
}