import React, { useState } from 'react'
import RMEFILTER from '../RMEContainer/components/RMEFilter'
import RMEStatus from './components/RMEStatus'
import ViewTable from './components/ViewTable'
import { RMEFiltersProvider } from '../../../../contexts/rmeTeamContext/RMEFiltersContext'

const RMEContainer = () => {
  const [applyClicked, setApplyClicked] = useState(false);
  return (
    <div className="uxContainer">
      <RMEFiltersProvider>
       <RMEFILTER applyClicked={applyClicked} setApplyClicked={setApplyClicked}   />
       <RMEStatus applyClicked={applyClicked} />
       <ViewTable applyClicked={applyClicked} />
      </RMEFiltersProvider>
    </div>
  )
}

export default RMEContainer