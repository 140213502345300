import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Legend,
    LabelList,
    Tooltip,
  } from "@nutanix-ui/recharts";
  import React, { useEffect } from "react";
  import { formatDate, getCompletionPercentage } from "../FactoryData/utility";
  import { useState } from "react";
  import axios from "axios";
  import { SERVER_URL } from "../../config/config";
  import AssetModal from "../FactoryData/AssetModal";
  import {
    CustomizedXAxisTick,
    CustomizedYAxisTick,
    labelStyle,
  } from "../EngineeringDashboard/EngineeringUtils";
  import {
    Badge,
    TextLabel,
  } from "@nutanix-ui/prism-reactjs";
  
  function generateData(datas) {
    return datas.map((data) => {
      const { ship_date, sfdc_count, factory_eod_count, missing_assets } = data;
      const { matched, unmatched } = getCompletionPercentage(
        sfdc_count,
        factory_eod_count
      );
      return {
        name: formatDate(ship_date, 'short'),
        Matched: matched,
        Missed: unmatched,
        ship_date: ship_date,
        missing_assets: missing_assets,
      };
    });
  }
  
  const FactoryFataModal = ({ factoryData }) => {
    const [data, setData] = useState([]);
    const [state, setState] = useState({
      lastFetchedShipDate: false,
      modalVisible: false,
      missingAssets: [],
      loading: false,
    });
  
    useEffect(() => {
      if (factoryData.length > 0) {
        setData(generateData(factoryData));
      }
    }, [factoryData]);
  
    const handleBarClick = async (barData) => {
      // Check if the ship_date is the same as the last fetched one
      if (state.lastFetchedShipDate === barData.ship_date) {
        // If it's the same, just open the modal without fetching again
        setState((prevState) => ({
          ...prevState,
          modalVisible: true,
        }));
        return; // Stop further execution since we don't need to fetch again
      }
  
      setState((prevState) => ({
        ...prevState,
        loading: true,
        modalVisible: true,
      }));
  
      try {
        // Fetch data from API only if the ship_date is different
        const response = await axios.get(
          `${SERVER_URL}/manufacturing-missing-serials?date_shipped=${barData.ship_date}`
        );
  
        const { data } = response?.data;
  
        setState((prevState) => ({
          ...prevState,
          missingAssets: data,
          lastFetchedShipDate: barData.ship_date, // Save the fetched ship_date
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          loadingError: true,
        }));
        throw error;
      } finally {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };
  
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const matchedValue = payload[0].payload["Matched"];
        const missedValue = payload[0].payload["Missed"];
    
        // Format the display values
        const formatValue = (value) => {
          if (value == 0) return '0';
          return `${value}%`;
        };
    
        return (
          <div className="custom-tooltip" style={{ width: '200px' }}>
            <TextLabel
              display="block"
              size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
              type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
              style={{ marginBottom: "10px", fontWeight: "bold" }}
            >
              {label}
            </TextLabel>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Badge color="#68dc8e" text="Matched" />
                <div className="grid-item center-aligned">
                  {formatValue(matchedValue)}
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Badge color="#F55656" text="Missed" />
                <div className="grid-item center-aligned">
                  {formatValue(missedValue)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    
      return null;
    };
  
    // Custom legend component
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: "flex", justifyContent: "center", position:'relative', top:'15px' }}>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                marginRight: "5px",
                borderRadius: '50%'
              }}
            />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
  
    return (
      <>
        <ResponsiveContainer height={200}>
          <BarChart
            alignment="none"
            height={200}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            className="factory_data"
          >
            <CartesianGrid stroke="#eee" />
            <XAxis
              dataKey="name"
              horizontal={true}
              tick={<CustomizedXAxisTick />}
              interval={0}
            />
            <YAxis
              tickFormatter={(value) => `${value}%`}
              tick={<CustomizedYAxisTick />}
            />
            <Legend content={<CustomLegend />} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              barSize={30}
              dataKey="Matched"
              stackId="a"
              fill={"#68dc8e"}
              onClick={(data) => {
                if (data.missing_assets) {
                  handleBarClick(data);
                }
              }}
            >
              <LabelList
                dataKey="Matched"
                position="inside"
                labelStyle={labelStyle}
                formatter={(value) => {
                  if (value == 0) {
                    return [""];
                  }
                  return [`${value}%`];
                }}
                style={{
                  fill: "#37414b",
                }}
              />
            </Bar>
            <Bar
              barSize={30}
              dataKey="Missed"
              stackId="a"
              fill={"#f55656"}
              onClick={(data) => {
                if (data.missing_assets) {
                  handleBarClick(data);
                }
              }}
            >
              <LabelList
                dataKey="Missed"
                position="top"
                formatter={(value) => {
                  if (value == 0) {
                    return [""];
                  }
                  return [`${value}%`];
                }}
                style={{
                  fill: "#37414b",
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {state.modalVisible && (
          <AssetModal
            modalVisible={state.modalVisible}
            setState={setState}
            loading={state.loading}
            loadingError={state.loadingError}
            missingAssets={state.missingAssets}
          />
        )}
      </>
    );
  };
  
  export default FactoryFataModal;