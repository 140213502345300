import { useState, useEffect } from "react";
import { Row, Col, Carousel, AutoComplete, Button } from "antd";
import "./FulfillmentSlide.css";
import {
    TABLEAU_BASE_URL,
    QUARTER_END_BACKLOG_REPORT,
    NCR_DASHBOARD_REPORT,
    SYSTEM_PROGRESSION_REPORT,
    FULFILLMENT_METRICS_REPORT,
} from "../../config/config";

import { sendUserRecord } from "../../Service/adoptionMetric";

const tableauReports = [
    `${TABLEAU_BASE_URL}/#/views/${QUARTER_END_BACKLOG_REPORT}?:iid=1`,
    `${TABLEAU_BASE_URL}/#/views/${NCR_DASHBOARD_REPORT}?:iid=1`,
    `${TABLEAU_BASE_URL}/#/views/${SYSTEM_PROGRESSION_REPORT}?:iid=1`,
    `${TABLEAU_BASE_URL}/#/views/${FULFILLMENT_METRICS_REPORT}?:iid=1`,
];

const FulfillmentSlide = () => {
    const [open, setOpen] = useState(false);
    const [currentActiveSlide, setCurrentActiveSlide] = useState(0);

    const contentStyle = {
        height: "160px",
        color: "#fff",
        lineHeight: "160px",
        textAlign: "center",
        background: "#364d79",
    };

    useEffect(() => {
        sendUserRecord('fullfilment_slide', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }, []);

    const handleChange = (oldIndex, newIndex) => {
        // console.log({oldIndex, newIndex})
        setCurrentActiveSlide(newIndex);
    };

    return (
        <Row>
            <Col span={24}>
                <Carousel
                    style={{ width: "100%", height: "90vh" }}
                    className="Carousel"
                    autoplay
                    initialSlide={0}
                    beforeChange={handleChange}
                    autoplaySpeed={15000}
                    dotsClass="button__bar"
                    dots={{ className: "button__bar" }}
                >
                    {tableauReports.map((e, index) => {
                        return (
                            <div
                                key={e}
                                style={{
                                    width: "100%",
                                    height: "90%",
                                    overflow: "auto",
                                }}
                            >
                                {(index === currentActiveSlide ||
                                    index > currentActiveSlide ||
                                    index < currentActiveSlide) && (
                                    <tableau-viz
                                        id={index}
                                        src={e}
                                        key={index}
                                        device="desktop"
                                        toolbar="bottom"
                                        hide-tabs
                                        style={{
                                            width: "100%",
                                            height: "95%",
                                            overflow: "auto",
                                        }}
                                    ></tableau-viz>
                                )}
                            </div>
                        );
                    })}
                </Carousel>
            </Col>
        </Row>
    );
};

export default FulfillmentSlide;
