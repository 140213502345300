import { Link, Pagination, Sorter, Table, Title } from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useMemo, useState } from "react";
import { SERVER_URL } from "../../../../../config/config";
import axios from 'axios';
import { Badge } from "antd";
import { useFilters } from "../../../../../contexts/uxContexts/FiltersContext";

// Helper function to validate and sanitize query params
const validateParam = (value, defaultValue, minValue = 1) => {
  const parsedValue = parseInt(value, 10);
  if (!Number.isNaN(parsedValue) && parsedValue >= minValue) {
    return parsedValue;
  }
  return defaultValue;
};
const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

const ViewTable = ({ applyClicked }) => {
  const { selectedFilters } = useFilters();
  const defaultPageSize = [5, 10, 15, 20];
  const [currentPage, setCurrentPage] = useState(validateParam(1));
  const [pageSize, setPageSize] = useState(validateParam(defaultPageSize[2]));
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(1)

  const [sort, setSort] = useState({
    order: Sorter.SORT_ORDER_CONST.ASCEND,
    column: "ux_owner",
    sortable: [
      "fixversion",
      "ux_owner",
      "summary",
      "jira_status",
      "ux_fnf_review",
    ],
  });

  const filterQueryKey = useMemo(() => {
    const { ux_owners, projects, fixversions, ux_pod } = selectedFilters;

    return {
      ux_owners: ux_owners[0] === 'All' ? null : ux_owners,
      projects: projects[0] === 'All' ? null : projects,
      fixversions: fixversions[0] === 'All' ? null : fixversions,
      ux_pod: ux_pod[0] === 'All' ? null : ux_pod,
    };
  }, [selectedFilters]);

  const onChangePagination = (newPage, newPageSize) => {
    setCurrentPage(validateParam(newPage, currentPage));
    setPageSize(validateParam(newPageSize, defaultPageSize));
  };

  const fetchTableData = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await axios.post(`${SERVER_URL}/statistics_table`, {
        page: currentPage,
        limit: pageSize,
        column: sort.column,
        order: sort.order === "ascend" ? "asc" : "desc",
        ux_owners: filterQueryKey.ux_owners,
        projects: filterQueryKey.projects,
        fixversions: filterQueryKey.fixversions,
        ux_pod: filterQueryKey.ux_pod,
      });
      if(!data.data?.data){
        setDataSource([]);
        setTotal(1)
      }else {
        const dataSource = data.data?.data.map((item, index) => ({
          key: index + 1,
          ...item,
        }));
        const total_count = data?.data?.total_count
        setDataSource(dataSource);
        setTotal(total_count)
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshData = () => {
    fetchTableData();
  };

  useEffect(() => {
    fetchTableData();
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    if (applyClicked) {
      fetchTableData(); // Refetch data if apply button is clicked
    }
  }, [applyClicked]);

  const statusColors = {
    "Code Not Ready": "#FDDDDD",
    "Review Not Yet Done": "#FFF2CE",
    "Review Done": "#D7F6E1",
    "Not Applicable": "#F2F4F6",
  };

  const columns = [
    {
      title: <span>Fix version</span>,
      key: "fixversion",
      width: '100px',
      render: (name) => name || 'NA',
    },
    {
      title: <span>UX Owner</span>,
      key: "ux_owner",
      width: 200,
      render: (name) => name || 'NA',
    },
    {
      title: <span>Summary</span>,
      key: "summary",
      width: 300,
      render: (name) => name || 'NA',
    },
    {
      title: <span>Jira Ticket</span>,
      key: "issuenum",
      width: 150,
      render: (name) => {
        return <Link target="blank" href={`https://jira.nutanix.com/browse/${name}`} type="table-nav-primary">{name || 'NA'}</Link>;
      },
    },
    {
      title: <span>Jira Status</span>,
      key: "jira_status",
      width: 120,
      render: (name) => {
        return toTitleCase(name || 'NA');
      },
    },
    {
      title: <span>UX FNF Review</span>,
      key: "ux_fnf_review",
      width: 150,
      render: (name) => {
        return <Badge color={statusColors[name]} count={name || 'NA'} style={{ color: 'black' }}/>;
      },
    },
  ];
  
  const structure = {
    columnWidths: {
      fixversion: '110px',
      ux_owner: '230px',
      summary: '500px',
      issuenum: '130px',
      jira_status: '130px',
      ux_fnf_review: '140px',
    }
  }

  const handleChangeSort = (newSort) => {
    setSort({
      ...sort,
      column: newSort.column,
      order: newSort.order,
    });
  };

  return (
    <>
      <Title
        className="titleText"
        style={{ marginTop: "20px" }}
      >
        All Jira Details
      </Title>
      <div className="widgetHeader">
        <h3 style={{ color: '#627386' }}>{`Viewing all ${total} tables`}</h3>
        <Pagination
          currentPage={currentPage}
          onChange={onChangePagination}
          pageSize={pageSize}
          pageSizeOptions={defaultPageSize}
          pageSizeOptionLabel="items"
          total={total} // Adjust this according to your data
        />
      </div>
      <Table
        showCustomScrollbar={true}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        loadingError={!!error}
        onRefreshData={handleRefreshData}
        structure={structure}
        sort={sort}
        onChangeSort={handleChangeSort}
        wrapperProps={{
          "data-test-id": "factoryData",
        }}
      />
    </>
  );
};

export default ViewTable;
