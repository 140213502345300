import React from 'react';
import { FlexLayout, TextLabel } from "@nutanix-ui/prism-reactjs";
import imgNoData from '../../../../images/emptyScreen.svg'
const NoData = ({height}) => {
  return (
    <FlexLayout
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: height ? height : '215px' }}
    >
      <img src={imgNoData} alt="" />
      <TextLabel style={{ fontSize: '30px' }}>
        No Data
      </TextLabel>
    </FlexLayout>
  );
};

export default NoData;
