import { Divider, Typography } from "antd";
import React from "react";
import CustomCheckbox from "./CustomCheckbox";
import "./CalendarFooter.css";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCompletedSelection,
  toggleOpenSelection,
} from "../../../../features/JiraAnalysis/checkBoxSlice";
import { indicatorItems } from "../../utils";

/**
 * The `CalendarFooter` component displays footer content for a calendar view, including indicators and checkboxes.
 * It allows users to toggle selection for "Open" and "Completed" checkboxes and view status indicators.
 */

const CalendarFooter = () => {
  const dispatch = useDispatch();
  const { isCompletedSelected, isOpenSelected } = useSelector(
    (state) => state.checkbox
  );

  return (
    <div className="calendar-footer-container" data-testid="calendar-footer">
      <div className="footer-indicators">
        {indicatorItems.map((item) => (
          <React.Fragment key={item.key}>
            <span id="indicator" className={`${item.status}`}></span>
            <Typography.Text id="indicator-text">{item.title}</Typography.Text>
          </React.Fragment>
        ))}
      </div>
      <Divider style={{ margin: "7px 0" }} />
      <div className="footer-checkboxes">
        <CustomCheckbox
          className="open-checkbox"
          checked={isOpenSelected}
          label={"Open"}
          handleChange={(e) => dispatch(toggleOpenSelection(e.target.checked))}
        />
        <CustomCheckbox
          className="completed-checkbox"
          checked={isCompletedSelected}
          label={"Completed"}
          handleChange={(e) =>
            dispatch(toggleCompletedSelection(e.target.checked))
          }
        />
      </div>
    </div>
  );
};

export default CalendarFooter;
