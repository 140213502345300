import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemTextArea,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  FlexLayout,
  TextLabel,
  PlusIcon,
  FlexItem,
  CloseIcon
} from "@nutanix-ui/prism-reactjs";


import axios from "axios";
import { SERVER_URL } from "../../config/config";
import { useLocation } from "react-router-dom";

 export default function PostGresModal(props) {
    const location = useLocation();
    const { schema, origin, handleOnSave } = props;
    const DEFAULT_VALIDATIONS = [
      {
        tableName: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Table Name is required",
        },
      },
      {
        operation: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Operation is required",
        },
      },
      {
        start_offset: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Starting Offset is required",
        },
      },
      {
        query: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Query is required",
        },
      },
    ];
  
    const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
    useEffect(() => {
      if (origin === "Target" ) {
        validations.splice(2,1);
        setValidations(validations);
      }
      if (origin === "Source" && location.state?.scheduleType === "streaming") {
        validations.pop();
        setValidations(validations);
      }// eslint-disable-next-line 
    }, []);
    const [selectedTableName, setSelectedTableName] = useState(null);
    const [selectedTableColsName, setSelectedTableColsName] = useState(null);
    const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [schemaAndTableData, setSchemaAndTableData] = useState([]);
  
    const operationDataArray = [
      {
        key: "append",
        label: "Append",
         rowProps:{
          'data-testid':'Append'
         }
      },
      {
        key: "upsert",
        label: "Upsert",
        rowProps:{
          'data-testid':'Upsert'
         }
      },
    ];
  
    const [schemaData, setSchemaData] = useState([]);
    const [searchSchemaData, setSearchSchemaData] = useState([]);
  
    const [selectedSchema, setSelectedSchema] = useState(null);
  
    const [tableNameData, setTableNameData] = useState([]);
    const [searchTableNameData, setSearchTableNameData] = useState([]);
    const [tableColsNameData, setTableColsNameData] = useState([]);
    const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
    const [tableColsLoading, setTableColsLoading] = useState(true);
    const [schemaText, setSchemaText] = useState("");
    const [schemaRowsData, setSchemaRowsData] = useState([]);
  
    const [schemaLoading, setSchemaLoading] = useState(true);
    const startOffsetDataArray = [
      { key: "earliest", label: "earliest"  ,rowProps:{
        'data-testid':'earliest'
       }},
      { key: "latest", label: "latest" ,rowProps:{
        'data-testid':'latest'
       }},
    ];
    const [searchStartOffsetData, setSearchStartOffsetData] = useState(startOffsetDataArray);
    const [selectedStartOffset, setSelectedStartOffset] = useState(null);
    
    const [searchOperationData, setSearchOperationtData] = useState(operationDataArray);
    
    useEffect(() => {
      fetchSchemaAndTable();// eslint-disable-next-line 
    }, [schema]);
  
    const fetchSchemaAndTable = async(value) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const projectID = queryParameters.get("projectID");
      if (schema) {
        try{
          await axios
          .post(`${SERVER_URL}/fetchSchemaAndTable`, {
            project_id: projectID,
            conn_name: schema?.value ? schema?.value : "pg_stg_r1",
          })
          .then((resp) => {
            if (resp.status===200 &&resp?.data?.data?.res) {
              constructSchemaData(resp.data.data.res);
            }
          });
        } catch(err){
       
        }
      }
    };
  
    const constructSchemaData = (res) => {
      setSchemaAndTableData((prev) => res);
      let tempData = [];
      let schemaKeys = Object.keys(res);
      schemaKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setSchemaData((prevState) => tempData);
      setSearchSchemaData((prevState) => tempData);
      setSchemaLoading((prevState) => false);
    };
    const addSchemaRows = async () => {
      let obj = { key: schemaText };
      obj[selectedTableColsName.label] = obj["key"];
      delete obj["key"];
      schemaRowsData.push(obj);
      setSchemaRowsData(schemaRowsData);
      setSelectedTableColsName(null);
      setSchemaText("");
    };
    const handleEditSchemaRows = (objKVType, value, i) => {
      let key = Object.keys(schemaRowsData[i])[0];
      if (objKVType === "value") {
        schemaRowsData[i][key] = value;
      } else {
        schemaRowsData[i][value.label] = schemaRowsData[i][key];
        delete schemaRowsData[i][key];
      }
    };
    const removeSchemaRows = (index) => {// eslint-disable-next-line 
      const newRows = schemaRowsData.filter((sale, i) => i != index);
      setSchemaRowsData(newRows);
    };
    const handleTableNameChange = (item) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const projectID = queryParameters.get("projectID");
      let payload = {
        project_id: projectID,
        conn_name: schema?.value || '',
        schema_name: selectedSchema?.label || '',
        object_name: item?.label || '',
      };
      setSelectedTableName((prevState) => item); //label
      getTableColumns(payload);
    };
    const getTableColumns = (payload) => {
      axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {
        if (resp?.data?.data?.res) {
          setTableColsLoading(false);
          const tableColKeys = Object.keys(resp?.data?.data?.res);
          let TablleColsData = [];
          tableColKeys.forEach((o, i) => {
            TablleColsData.push({
              id: ++i,
              key: o,
              label: o,
              rowProps:{
                'data-testid':o
               }
            });
          });
          setTableColsNameData((prevState) => TablleColsData);
          setSearchTableColsNameData((prevState) => TablleColsData);
        }
      });
    };
    const handleOperationChange = (item) => {
      setSelectedOperation((prevState) => item);
    };
    const handleOnInputValueChange = (
      event,
      searchArray,
      setSearchArray,
      setSelectedValue
    ) => {
      if (event.target.value.length > 0) {
        if(searchArray.length===0){
          setSchemaLoading(false);
          setTableColsLoading(false)
         }
        const newRows = searchArray?.filter((row) => {
          return row.label
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        });
        newRows.unshift({ key: event.target.value, label: event.target.value })
        setSearchArray(newRows);
        setSelectedValue({ key: event.target.value, label: event.target.value });
      } else if (event.target.value.length === 0) {
        setSearchArray(searchArray);
        setSelectedValue(null);
      }
    };
    const handleSchemaChange = (item) => {
      setSelectedSchema((prevState) => item);
      const tableKeys = schemaAndTableData&&schemaAndTableData[[item.key]] ? Object.keys(schemaAndTableData[item.key]) : [];
      let tempData = [];
      tableKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setTableNameData((prevState) => tempData);
      setSearchTableNameData((prevState) => tempData);
      // setTableLoading((prevState) => false);
    };
    return (
      <Modal
        visible={props.visible}
        title={schema?.value + " ( " + schema?.label + ")" || "Schema"}
        onClose={props.handleOnClose}
        closeIconProps={{ "data-test": "my-close-icon-data-test" }}
        footer={false}
      >
        <StackingLayout padding="20px">
          <Form
            validations={validations}
            onSubmit={(values) =>
              handleOnSave(values, schemaRowsData, [], [], origin)
            }
          >
            {(formHelper) => {
              return (
                <FormSection>
                  <FormItemInput
                    id="id"
                    value={schema?.id || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Input ID"
                  />
                  {origin !== "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgres-alias'
                      id="alias"
                      formHelper={formHelper}
                      label={"Alias"}
                    />
                  )}
                  <FormItemInput
                    id="id"
                    value={schema?.db || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Database"
                  />
                  <FormItemSelect
                    id="schemaForm"
                    data-testid = 'paas-etlCanvas-formModal-postgres-schema'
                    rowsData={searchSchemaData}
                    loading={schemaLoading}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleSchemaChange(item)}
                    label={"Schema"}
                    inputProps={
                      { value:selectedSchema?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,schemaData,setSearchSchemaData,setSelectedSchema)} 
                  />
                  <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgres-tableName'
                    id="tableName"
                    rowsData={searchTableNameData}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleTableNameChange(item)}
                    label={"Table Name"}
                    inputProps={
                      { value:selectedTableName?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,tableNameData,setSearchTableNameData,setSelectedTableName)} 
                  />
                  <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgres-operation'
                    showSearch={true}
                    id="operation"
                    selected={selectedOperation}
                    rowsData={searchOperationData}
                    value={selectedOperation}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleOperationChange(item)}
                    label={"Operations"}
                    searchable={true}
                    inputProps={
                      { value:selectedOperation?.label || ''}
                    }
                    onChange={(event)=>handleOnInputValueChange(event,operationDataArray,setSearchOperationtData,setSelectedOperation)} 
                 />
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-formModal-postgres-key_cols'
                    id="key_cols"
                    formHelper={formHelper}
                    label={"Keys Column"}
                    helpText="Optional"
                  />
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-formModal-postgres-array_cols'
                    id="array_cols"
                    formHelper={formHelper}
                    label={"Arrays Column"}
                    helpText="Optional"
                  />
                  { origin === "Source" && (
                   <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgres-start_offset'
                    id="start_offset"
                    showSearch={true}
                    selected={selectedStartOffset}
                    rowsData={searchStartOffsetData}
                    formHelper={formHelper}
                    onSelectedChange={(item) => setSelectedStartOffset(item)}
                    label={"Starting Offset"}
                    inputProps={
                      { value:selectedStartOffset?.label || ''}
                     }
                     searchable={true}
                     onChange={(event)=>handleOnInputValueChange(event,startOffsetDataArray,setSearchStartOffsetData,setSelectedStartOffset)} 
                  />)}
                  <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgres-offset_col'
                    id="offset_col"
                    rowsData={searchTableColsNameData}
                    loading={tableColsLoading}
                    formHelper={formHelper}
                    label={"Offset Column"}
                    onSelectedChange={(item) => setSelectedOffsetCol(item)}
                    selected={selectedOffsetCol}
                    inputProps={
                      { value:selectedOffsetCol?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,tableColsNameData,setSearchTableColsNameData,setSelectedOffsetCol)} 
                    helpText="Optional"
                  />
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgres-uuid_columns'
                      id="uuid_columns"
                      formHelper={formHelper}
                      label={"UUID Columns"}
                      helpText="Optional"
                    />
                  )}
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgres-truncate_and_load'
                      id="truncate_and_load"
                      formHelper={formHelper}
                      label={"Truncate And Load"}
                      helpText="Optional"
                    />
                  )}
                  {origin === "Source" &&
                  location.state?.scheduleType === "streaming" ? (
                    ""
                  ) : (
                    <FormItemTextArea
                      data-testid = 'paas-etlCanvas-formModal-postgres-query'
                      id="query"
                      label="Query"
                      formHelper={formHelper}
                      placeholder="Please Type your query here"
                      style={{ height: "10rem" }}
                    />
                  )}
                  {origin === "Source" && (
                    <StackingLayout>
                      <TextLabel
                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                        data-test-id="type-primary"
                      >
                        Schema Configuration
                      </TextLabel>
                      {schemaRowsData?.length > 0 &&
                        schemaRowsData.map((row, i) => (
                          <FlexLayout
                            flexDirection="row"
                            alignItems="flex-end"
                            key={i}
                          >
                            <FormItemSelect
                              data-testid = {'paas-etlCanvas-formModal-postgres-addColumn'+i}
                              id={i}
                              selectedRow={{
                                key: Object.keys(row)[0],
                                label: Object.keys(row)[0],
                              }}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                handleEditSchemaRows("key", item, i)
                              }
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid = {'paas-etlCanvas-formModal-postgres-addSchema'+i}
                              key={i}
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={Object.values(row)[0]}
                              onChange={(e) =>
                                handleEditSchemaRows("value", e.target.value, i)
                              }
                            />
                            <FlexItem>
                              <Button
                                data-testid = {'paas-etlCanvas-formModal-postgres-removeRow'+i}
                                type="secondary"
                                style={{ backgroundColor: "gray" }}
                                onClick={() => removeSchemaRows(i)}
                              >
                                <CloseIcon
                                  size="small"
                                  aria-hidden="true"
                                  color="secondary"
                                />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        ))}
                        <StackingLayout>
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FormItemSelect
                              data-testid = 'paas-etlCanvas-formModal-postgres-addColumn'
                              id="columns Data"
                              selectedRow={selectedTableColsName}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                setSelectedTableColsName(item)
                              }
                              selected={selectedTableColsName}
                              inputProps={
                                { value:selectedTableColsName?.label || ''}
                              }
                              searchable={true}
                              onChange={(event)=>handleOnInputValueChange(event,tableColsNameData,setSearchTableColsNameData,setSelectedTableColsName)}
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid = 'paas-etlCanvas-formModal-postgres-addSchema'
                              id="schema"
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={schemaText}
                              onChange={(e) => setSchemaText(e.target.value)}
                            />
                            <FlexItem>
                              <Button onClick={addSchemaRows} 
                              data-testid = 'paas-etlCanvas-formModal-postgres-addRow-btn'>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                    </StackingLayout>
                  )}
                  <Button onClick={formHelper.handleSubmit} style={{float:'right'}}>Save</Button>
                </FormSection>
              );
            }}
          </Form>
        </StackingLayout>
      </Modal>
    );
  }