import React from "react";
import axios from "axios";
import moment from "moment";
import { Spin, Alert, Table } from "antd";
import Logger from "../../config/logger";
import {SERVER_URL} from "../../config/config";
export class JiraTableDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      apiProgress: { loading: false, ready: false, error: false },
    };
  }
  async componentDidMount() {
    this.setState({
      apiProgress: { loading: true, ready: false, error: false },
    });
    try {
      Logger.info(`Getting Jira Details`)
      const getJiraDetails = await axios.post(
        `${SERVER_URL}/getjiradetails`
      );
      Logger.info(`Fetched Jira Details Successfully`)
      this.setState({
        apiProgress: { loading: false, ready: true, error: false },
      });

      this.setState({
        data: getJiraDetails.data,
        filteredData: getJiraDetails.data,
      });
    
    } catch (e) {
      Logger.error(e)
      const error = e.message;
      this.setState({
        apiProgress: { loading: false, ready: false, error: error },
      });
    }
  }
  jiraTableColumns = () => [
    {
      title: "Jira No",
      dataIndex: "issuenum",
      key: "issuenum",
      className: "columndata",
      width: "20%",
    },

    {
      title: "Type",
      dataIndex: "issue_type",
      key: "issue_type",
      className: "columndata",
      width: "20%",
    },

    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      className: "columndata",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "issue_status",
      key: "issue_status",
      className: "columndata",
      width: "20%",
    
    },
    // {
    //   title: "Resolution Name",
    //   dataIndex: "resolution_name",
    //   key: "resolution_name",
    //   className:"columndata",
    // },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "columndata",
      width: "20%",

      render: (value) => {
        return (
          <div>{moment(value.split("+")[0]).format("YYYY MMM DD hh:mm A")}</div>
        );
      },
    },
  ];

  render() {
    const { filteredData } = this.state;
    return (
      <div style={{width:"100%"}}>
        {this.state.apiProgress.error && (
          <Alert
            message="Error"
            description={this.state.apiProgress.error}
            type="error"
            showIcon
          />
        )}
        <Spin
          spinning={this.state.apiProgress.loading}
          tip={"Loading.please wait..."}
        >
          <Table
            columns={this.jiraTableColumns()}
            dataSource={filteredData}
            pagination={{ pageSize: 6 }}
            style={{width:"100%",overflow:"auto"}}
            locale={{ emptyText: "No Data" }}
          />
        </Spin>
      </div>
    );
  }
}

export default JiraTableDetails;
