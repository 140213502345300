import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import "antd/dist/antd.css";
import { HomeOutlined } from "@ant-design/icons";
import TableauDashboard from "./TableauDashboard";
import Datalineage from "../Datalineage2/Datalineage2";
import "./TableauDashboard.css";

import { sendUserRecord } from "../../Service/adoptionMetric";

const TableauDashboardMain = () => {
    const initialItems = [
        {
            label: "All Reports",
            children: <TableauDashboard onReportClick={addNewTab} />,
            key: 0,
            closable: false,
        },
    ];
    const [activeKey, setActiveKey] = useState(initialItems[0].key);
    const [items, setItems] = useState(initialItems);
    const [modalStatus, setModalStatus] = useState({
        0: { key: 0, open: false },
    });

    const onChange = (newActiveKey) => {
        setModalStatus((e = {}) => {
            const modalState = Object.entries(e);
            return modalState.reduce((acc, curr) => {
                const currentKey = curr[0];
                const newValue = {
                    key: currentKey,
                    open: false,
                };
                acc[currentKey] = newValue;
                return acc;
            }, {});
        });
        setActiveKey(newActiveKey);
    };

    function addNewTab({ url, name, id, isFav }) {
        setItems((e) => {
            if (e.find((item) => item.key === id)) {
                return e;
            }
            const newPanes = {
                label: name,
                children: (
                    <Datalineage
                        url={url}
                        name={name}
                        id={id}
                        isFav={isFav}
                        setModalStatus={setModalStatus}
                        modalStatus={modalStatus}
                    />
                ),
                key: id,
            };
            return [...e, newPanes];
        });
        setModalStatus((e) => {
            return { ...e, [id]: { open: false, key: id } };
        });
        setActiveKey(id);
    }

    const remove = (targetKey) => {
        setItems((e) => e.filter((stateItems) => stateItems.key !== targetKey));
        setActiveKey(0);
        setModalStatus((e) => {
            delete e[targetKey];
            return e;
        });
        // let newActiveKey = activeKey;
        // let lastIndex = -1;
        // items.forEach((item, i) => {
        //     if (item.key === targetKey) {
        //         lastIndex = i - 1;
        //     }
        // });
        // const newPanes = items.filter((item) => item.key !== targetKey);
        // if (newPanes.length && newActiveKey === targetKey) {
        //     if (lastIndex >= 0) {
        //         newActiveKey = newPanes[lastIndex].key;
        //     } else {
        //         newActiveKey = newPanes[0].key;
        //     }
        // }
        // setItems(newPanes);
        // setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey, action) => {
        if (action === "add") {
            // addNewTab();
        } else {
            remove(targetKey);
        }
    };

    useEffect(() => {
        setItems((e) => {
            return e.map((tabItem) => {
                if (tabItem.key === 0) return tabItem;
                return {
                    ...tabItem,
                    children: (
                        <Datalineage
                            url={""}
                            name={tabItem.label}
                            id={tabItem.key}
                            isFav={false}
                            setModalStatus={setModalStatus}
                            modalStatus={modalStatus}
                        />
                    ),
                };
            });
        });
    }, [modalStatus]);

    useEffect(() => {
        sendUserRecord('tableau_dashboard', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }, [])
    return (
        <Row >
            <Col span={24}>
                <div className="tableau-main-dashboard" data-testid="tableau-main-dashboard">
                    <Tabs
                        type="editable-card"
                        onChange={onChange}
                        activeKey={activeKey}
                        onEdit={onEdit}
                        items={items}
                        hideAdd
                        data-testid="tableau-edit-tabs"
                    />
                </div>
            </Col>
        </Row>
    );
};
export default TableauDashboardMain;
