import React, { useContext, useEffect, useState } from "react";
import { Table, FlexLayout } from "@nutanix-ui/prism-reactjs";
import LeoContext from "../../../contexts/leoContexts/leoContext";
import {
  formatCurrencyWithCommas,
  generateDataSourceDivision,
  sortByColumn,
} from "../utils";

const columns = [
  {
    title: "Division",
    key: "division",
    width: "260px",
    textAlign: "left",
  },
  {
    title: "No. of Emp.",
    key: "count_of_emp",
    textAlign: "center",
    width: 60,
  },
  {
    title: "Avg. Cost per Emp.",
    key: "average_amount_per_employee",
    textAlign: "center",
  },
  {
    title: "Expense",
    key: "expense_amount_usd",
    textAlign: "right",
  },
  {
    title: "Flight",
    key: "flight_amount_usd",
    textAlign: "right",
  },
  {
    title: "PO Bill Amount",
    key: "po_invoice_amount_usd",
    textAlign: "right",
  },
  {
    title: "Amount USD",
    key: "total_amount_usd",
    className: "bold-column",
    textAlign: "right",
  },
];
const DivisionTable = ({ transformedDivisionData }) => {
  const { data, loading, sort, setSort } = useContext(LeoContext);
  const sortedDivisionData = sortByColumn(
    data.divisionData,
    sort.divisionTable.column,
    sort.divisionTable.order
  );
  const grandTotalData = sortedDivisionData.filter(
    (data) => data.derived_column === "GRAND_TOTAL"
  );
  const originalData = sortedDivisionData.filter(
    (data) => data.derived_column === "ORIGINAL_LINE"
  );
  const [rowExpand, setRowExpand] = useState({
    isNestedTable: true,
    render: (row) => {
      const originalData = sortedDivisionData.filter(
        (data) =>
          data.derived_column === "ORIGINAL_LINE" &&
          data.division === row.division
      );
      if (row.key !== "total" && originalData.length > 0) {
        const dataSource = generateDataSourceDivision(originalData, "original");
        return (
          <FlexLayout className="subtable" flexDirection="column">
            <div className="costCenterText">Cost Center</div>
            <Table
              showCustomScrollbar={true}
              border={false}
              columns={columns}
              dataSource={dataSource}
              structure={{
                hideHeader: true,
              }}
              className="subTable"
            />
          </FlexLayout>
        );
      }
    },
  });
  useEffect(() => {
    if (originalData.length > 0) {
      setRowExpand({
        isNestedTable: true,
        render: (row) => {
          const originalRowData = originalData.filter(
            (data) => data.division === row.division
          );
          if (row.key !== "total" && originalRowData.length > 0) {
            const dataSource = generateDataSourceDivision(
              originalRowData,
              "original"
            );
            return (
              <FlexLayout className="subtable" flexDirection="column">
                <div className="costCenterText">Cost Center</div>
                <Table
                  showCustomScrollbar={true}
                  border={false}
                  columns={columns}
                  dataSource={dataSource}
                  structure={{
                    hideHeader: true,
                  }}
                  className="subTable"
                />
              </FlexLayout>
            );
          }
        },
      });
    }
  }, [originalData.length]);

  const handleChangeRowExpand = (newExpand) => {
    setRowExpand(newExpand);
  };

  const updatedData = [];
  if (transformedDivisionData.length > 0 && grandTotalData.length > 0) {
    const empNumber =
      grandTotalData[0].count_of_emp === null
        ? "-"
        : grandTotalData[0].count_of_emp;
    const avgCost =
      grandTotalData[0].average_amount_per_employee === null
        ? "-"
        : `${formatCurrencyWithCommas(
            grandTotalData[0].average_amount_per_employee.toFixed(2)
          )}`;
    const expense =
      grandTotalData[0].expense_amount_usd === null
        ? "-"
        : `${formatCurrencyWithCommas(
            grandTotalData[0].expense_amount_usd.toFixed(2)
          )}`;
    const flight =
      grandTotalData[0].flight_amount_usd === null
        ? "-"
        : `${formatCurrencyWithCommas(
            grandTotalData[0].flight_amount_usd.toFixed(2)
          )}`;
    const amountUSD =
      grandTotalData[0].total_amount_usd === null
        ? "-"
        : `${formatCurrencyWithCommas(
            grandTotalData[0].total_amount_usd.toFixed(2)
          )}`;
    const po_invoice_amount_usd =
      grandTotalData[0].po_invoice_amount_usd === null
        ? "-"
        : `${formatCurrencyWithCommas(
            grandTotalData[0].po_invoice_amount_usd.toFixed(2)
          )}`;

    updatedData.push({
      key: "total",
      division: <p className="bold-column">Grand Total</p>,
      count_of_emp: <p className="bold-column">{empNumber}</p>,
      average_amount_per_employee: <p className="bold-column">{avgCost}</p>,
      expense_amount_usd: <p className="bold-column">{expense}</p>,
      flight_amount_usd: <p className="bold-column">{flight}</p>,
      po_invoice_amount_usd: <p className="bold-column">{po_invoice_amount_usd}</p>,
      total_amount_usd: <p className="bold-column">{amountUSD}</p>, // Format total amount as currency
    });
  }
  if (transformedDivisionData.length > 0) {
    const divisionDataSource = generateDataSourceDivision(
      transformedDivisionData,
      "division"
    );
    updatedData.push(...divisionDataSource);
  }

  const handleSortChange = (newSort) => {
    setSort((prevState) => ({
      ...prevState,
      divisionTable: {
        ...prevState.divisionTable,
        order: newSort.order,
        column: newSort.column,
      },
    }));
  };
  return (
    <Table
      loading={loading.divisionLoading}
      showCustomScrollbar={true}
      columns={columns}
      dataSource={updatedData}
      rowExpand={rowExpand}
      sort={sort.divisionTable}
      onChangeSort={handleSortChange}
      onChangeRowExpand={handleChangeRowExpand}
      wrapperProps={{
        "data-test-id": "expandable",
      }}
      style={{ backgroundColor: "white" }}
      className="divisionTable"
    />
  );
};

export default DivisionTable;
