import React from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardOptions from "./DashboardOptions";
import UploadHistory from "./FileUploadHistory";

export default function fileUploadUtilityDashboard() {
    return <div className="fileUploadDashboardContainer" data-testid="fileUploadDashboardContainer">
        <DashboardHeader />
        <DashboardOptions />
        <UploadHistory />
    </div>
}