import { TextLabel, Tooltip } from "@nutanix-ui/prism-reactjs";

export const processCategory = (data) => {
  return data.map((item, index) => ({
    index: index + 1,
    name: item.category,
    amount: item.amount_usd,
  }));
};

export const transformExpenseData = (data) => {
  const result = {};
  // Iterate over the data array
  data.forEach((item, index) => {
    // Extract fiscal quarter and year
    const [year, quarter] = item.calc_fiscal_qtr.split("-Q");
    // Format the fiscal quarter name
    const fiscalQuarter = `FY${year.slice(2)} Q${quarter}`;
    // Initialize the object for the fiscal quarter if it doesn't exist
    if (!result[fiscalQuarter]) {
      result[fiscalQuarter] = {
        key: index + 1,
        name: fiscalQuarter,
        Expenses: 0,
        Flights: 0,
      };
    }
    // Increment expenses or flights based on the 'flight_or_expense' value
    if (item.flight_or_expense === "EXPENSE") {
      result[fiscalQuarter].Expenses += item.amount_usd;
    } else if (item.flight_or_expense === "FLIGHT") {
      result[fiscalQuarter].Flights += item.amount_usd;
    }
  });
  // Convert the result object into an array of values
  return Object.values(result);
};

export const transformGrowthData = (data) => {
  const result = {};
  // Iterate over the data array
  data.forEach((item, index) => {
    const [year, quarter] = item.calc_fiscal_qtr.split("-Q");
    // Format the fiscal quarter name
    const fiscalQuarter = `FY${year.slice(2)} Q${quarter}`;
    // Initialize the object for the fiscal quarter if it doesn't exist
    if (!result[fiscalQuarter]) {
      result[fiscalQuarter] = {
        key: index + 1,
        name: fiscalQuarter,
        Expenses: 0,
        Flights: 0,
        totalAmount: 0
      };
    }
    // Increment expenses or flights based on the 'flight_or_expense' value
    if (item.flight_or_expense === "EXPENSE") {
      result[fiscalQuarter].Expenses += item.amount_growth_pct;
      result[fiscalQuarter].totalAmount += item.amount_usd;
    } else if (item.flight_or_expense === "FLIGHT") {
      result[fiscalQuarter].Flights += item.amount_growth_pct;
      result[fiscalQuarter].totalAmount += item.amount_usd;
    }
  });

  // Convert the result object into an array of values
  return Object.values(result);
};

export const aggregateExpenseData = (data) => {
  const aggregatedData = {};

  data.forEach((entry) => {
    const { name, Expenses, Flights } = entry;

    if (!aggregatedData[name]) {
      aggregatedData[name] = { name, Expenses: 0, Flights: 0 };
    }

    if (Expenses) {
      aggregatedData[name].Expenses += Expenses;
    }

    if (Flights) {
      aggregatedData[name].Flights += Flights;
    }
  });

  return Object.values(aggregatedData);
};

const formatDateByCategory = (inputDateString) => {
  const date = new Date(inputDateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day} ${year}`;
}

export const transformDetails = (data) => {
  const transformedData = [];

  // Iterate over each entry in the data array
  data.forEach((entry, index) => {
    // Extract the name and amount from each entry
    const {
      amount_usd,
      category,
      employee_name,
      expense_id,
      expense_type,
      transaction_date,
      user_comment,
    } = entry;
    // Push the transformed entry into the transformedData array
    transformedData.push({
      key: `${index + 1}`,
      transaction_date: formatDateByCategory(transaction_date),
      employee_name: employee_name,
      expense_id: expense_id,
      expense_type: expense_type,
      category: category,
      user_comment: user_comment,
      amount_usd: amount_usd,
    });
  });
  return transformedData;
};


export const viewByMenu = [
  {
    key: "1",
    label: "Division",
  },
  {
    key: "2",
    label: "Employee",
  },
];

const formatLabel = (label) => {
  // Split the label by underscores
  const words = label.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the capitalized words with spaces
  const formattedLabel = capitalizedWords.join(" ");

  return formattedLabel;
};

export const generateTimeRangeMenu = (datas) => {
  const customDateRange = {
    key: "custom",
    label: "Custom Date Range",
    start_date: null, // Set the start date for custom range if needed
    end_date: null, // Set the end date for custom range if needed
  };

  const allTimeRanges = datas.map((data, index) => ({
    key: (index + 1).toString(),
    label: formatLabel(data.time_key),
    start_date: data.start_date,
    end_date: data.end_date,
  }));

  return [...allTimeRanges, customDateRange];
};

export const generateDivisionMenu = (datas) => {
  const menuItems = datas.map((data, index) => ({
    key: (index + 1).toString(),
    label: data.divisions,
  }));
  // Add "ALL" label as the first item in the menu
  menuItems.unshift({ key: "0", label: "All" });
  return menuItems;
};

export const generateCostCenterMenu = (datas, division) => {
  const menuItems = datas.map((data, index) => ({
    key: (index + 1).toString(),
    label: data.cost_centers,
    divisions: data.divisions,
  }));

  // Add "ALL" label as the first item in the menu
  menuItems.unshift({ key: "0", label: "All" });

  // Apply filter only when division is defined and division.label is not "All"
  if (division && division.label !== "All") {
    return menuItems.filter(
      (menu) => menu.divisions === division.label || menu.label === "All"
    );
  } else {
    return menuItems; // Return unfiltered menuItems if division is undefined or division.label is "All"
  }
};

export function formatCurrencyWithCommas(currencyString) {
  // Remove the dollar sign and commas
  const value = parseFloat(currencyString.replace(/\$|,/g, ""));
  // If value is 0.00, return $0
  if (value === 0) {
    return "$0";
  }
  // Format the number with commas
  const formattedValue = value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
  // Split the formatted value into parts before and after the decimal point
  const [integerPart, decimalPart] = formattedValue.split(".");
  // Insert commas every three digits starting from the right
  const integerPartWithCommas = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  // Combine integer and decimal parts, and add the dollar sign
  return "$" + integerPartWithCommas + (decimalPart ? "." + decimalPart : "");
}

const formatCurrencyWithUSStandard = (currencyString) => {
  // Parse the input string to a float
  const value = parseFloat(currencyString);
  if (isNaN(value)) {
    return "$0";
  }
  // Check if the value is exactly 0 or close to 0
  if (value === 0 || Math.abs(value) < 0.01) {
    return "$0";
  }

  // Format the number with commas for thousands and fixed to two digits after the decimal point
  const formattedValue = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // Add the dollar sign at the prefix
  return `$${formattedValue}`;
}

export const generateDataSourceDivision = (data, type) => {
  if (data.length === 0) {
    return []; // Return an empty array if data is empty
  }
  return data.map((item, index) => {
    const count_of_emp = item.count_of_emp;
    const division = type === "division" ? item.division : item.cost_center;
    return {
      key: (index + 1).toString(),
      division,
      count_of_emp,
      average_amount_per_employee: formatCurrencyWithUSStandard(
        item.average_amount_per_employee
      ),
      expense_amount_usd: formatCurrencyWithUSStandard(item.expense_amount_usd),
      flight_amount_usd: formatCurrencyWithUSStandard(item.flight_amount_usd),
      po_invoice_amount_usd: formatCurrencyWithUSStandard(
        item.po_invoice_amount_usd
      ),
      total_amount_usd: formatCurrencyWithUSStandard(item.total_amount_usd),
    };
  });
};

export const generateDataSourceEmployee = (data, type) => {
  if (data.length === 0) {
    return []; // Return an empty array if data is empty
  }
  return data.map((item, index) => {
    const division = item.division === null ? "-" : item.division;
    const costCenter = item.cost_center === null ? "-" : item.cost_center;
    const empName = item.employee_name === null ? "-" : item.employee_name;
    return {
      key: (index + 1).toString(),
      employee_name: <p className="bold-column">{empName}</p>,
      division: division,
      cost_center: costCenter,
      expense_amount_usd: formatCurrencyWithUSStandard(item.expense_amount_usd),
      flight_amount_usd: formatCurrencyWithUSStandard(item.flight_amount_usd),
      po_invoice_amount_usd: formatCurrencyWithUSStandard(
        item.po_invoice_amount_usd
      ),
      total_amount_usd: formatCurrencyWithUSStandard(item.total_amount_usd),
    };
  });
};

export const getDataByPage = (data, page, pageSize) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data.slice(startIndex, endIndex);
};

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Extract the year, month, and day components from the date object
  const year = dateObject.getFullYear();
  // Months are zero-indexed, so add 1 to get the correct month
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  // Format the date in the desired format
  return `${year}-${month}-${day}`;
};

export const filterBySearch = (data, searchQuery, category, type) => {
  let filteredData = data;
  if (!type) {
    filteredData = transformDetails(data);
  }

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item[category].toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  return filteredData;
};

export const sortByColumn = (array, column, sortOrder) => {
  return array.sort((a, b) => {
    if (sortOrder === "ascend") {
      if (column === "transaction_date") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        return dateA - dateB;
      }
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    } else if (sortOrder === "descend") {
      if (column === "transaction_date") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        return dateB - dateA;
      }
      if (a[column] > b[column]) return -1;
      if (a[column] < b[column]) return 1;
      return 0;
    }
  });
};

export const renderTooltip = (tooltip) => {
  if (tooltip?.index === undefined) return null;
  <Tooltip
    oldTooltip={false}
    content={tooltip.value}
    contentProps={{
      style: {
        maxWidth: 300,
      },
      "data-test-id": "basic-tooltip",
      className: "custom-content-classname",
    }}
  >
    <TextLabel
      type={TextLabel.TEXT_LABEL_TYPE.TRIGGER}
      data-test-id="open-basic-tooltip"
    >
      {tooltip.value}
    </TextLabel>
  </Tooltip>;
  return <div className="tooltip">{tooltip.value}</div>;
};

export const processExpenseReports = (expenseReports, flightReports) => {
  // TRANSFORMING SPENDERS DATA [Expenses || Flights] AS PER GRAPH REQUIRES AND SORTED : [DESC]
  const transformSpendersData = (data, key) => {
    const transformedData = {};

    data.forEach((entry) => {
      const { name, amount_usd } = entry;

      if (!transformedData[name]) {
        transformedData[name] = { name, Expenses: 0, Flights: 0 };
      }

      if (entry.expense_type === "EXPENSE" && key === "Expenses") {
        transformedData[name].Expenses += amount_usd;
      } else if (entry.expense_type === "FLIGHT" && key === "Flights") {
        transformedData[name].Flights += amount_usd;
      }
    });

    const sortedTransformData = Object.values(transformedData).sort(
      (a, b) => b[key] - a[key]
    );

    return Object.values(sortedTransformData);
  };

  // AGGREGATED SPENDERS DATA [...Expenses, ...Flights] AS PER GRAPH REQUIRES AND SORTED : [DESC]
  const aggregateExpenseData = (data) => {
    const aggregatedData = {};

    data.forEach((entry) => {
      const { name, Expenses, Flights } = entry;

      if (!aggregatedData[name]) {
        aggregatedData[name] = { name, Expenses: 0, Flights: 0 };
      }

      if (Expenses) {
        aggregatedData[name].Expenses += Expenses;
      }

      if (Flights) {
        aggregatedData[name].Flights += Flights;
      }
    });

    const sortedData = Object.values(aggregatedData)
      // .filter((entry) => entry.Expenses !== 0 && entry.Flights !== 0) // Filter entries with non-zero Expenses and Flights
      .sort((a, b) => {
        const totalA = a.Expenses + a.Flights;
        const totalB = b.Expenses + b.Flights;
        return totalB - totalA; // Sort in descending order
      });

    return sortedData;
  };

  // GET TOP SPENDERS : EXPENSES
  const topSpendersExpenses = transformSpendersData(expenseReports, "Expenses");

  // GET TOP SPENDERS : FLIGHTS
  const topSpendersFlights = transformSpendersData(flightReports, "Flights");

  // GET TOP SPENDERS : EXPENSES + FLIGHTS
  const topSpendersAggregates = aggregateExpenseData([
    ...topSpendersExpenses,
    ...topSpendersFlights,
  ]);

  // return TOP SPENDERS : EXPENSES, FLIGHTS, EXPENSES + FLIGHTS
  return { topSpendersExpenses, topSpendersFlights, topSpendersAggregates };
};

export const getPaginatedData = (data, currentPage, pageSize) => {
  // Calculate start and end indices for current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);

  // Get data for current page
  const currentPageData = data.slice(startIndex, endIndex);

  return currentPageData;
}