import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemInput,
  Form,
  FormSection,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";

import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";


export default function AamzonS3Modal(props) {

  const { data, schema, origin,handleS3OnSave } = props;
  const {pipelineData} = useContext(ETLCanvasContext);
  const {setPipelineData} = useContext(ETLCanvasContextDispatcher);
  const queryParameters = new URLSearchParams(window.location.search);
  const projectID = queryParameters.get("projectID");
  const pipeLineID = queryParameters.get("pipelineID");

  useEffect(() => {
    setPipelineData({
      ...pipelineData,
      pipelineID: pipeLineID,
      project_id: projectID,
    }); // eslint-disable-next-line 
  }, []);
 const DEFAULT_VALIDATIONS = [
    {
      alias: {
        [Form.VALIDATION_RULE.REQUIRED]: true,
        message: "Alias is required",
      },
    },
    {
        bucket: {
        [Form.VALIDATION_RULE.REQUIRED]: true,
        message: "bucket value is required",
      },
    },
  ];
  // const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
  const [schemaMapping, setSchemaMapping] = useState(data ? JSON.parse(data.schemaMapping) : '');
  
  return (
    <Modal
      visible={props.visible}
      title={schema?.label + " ( " + schema?.value + ")" || "Schema"}
      onClose={props.handleOnClose}
      closeIconProps={{ "data-test": "my-close-icon-data-test" }}
      footer={false}
    >
      <StackingLayout padding="20px">
        <Form
          validations={DEFAULT_VALIDATIONS}
          onSubmit={(values) =>
            handleS3OnSave(values,schemaMapping)
          }
        >
          {(formHelper) => {
            return (
              <FormSection>
                <FormItemInput
                  id="id"
                  value={schema?.id || "N.A"}
                  disabled
                  formHelper={formHelper}
                  label="Input ID"
                />
                {origin !== "Target" && (
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-s3Modal-alias'
                    id="alias"
                    formHelper={formHelper}
                    label={"Alias"}
                  />
                )}
                <FormItemInput
                  data-testid = 'paas-etlCanvas-s3Modal-bucket'
                  id="bucket"
                  formHelper={formHelper}
                  label={"Folder Path"}
                />
                {origin === "Source" && (
                  <StackingLayout>
                    <TextLabel
                      type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                      data-test-id="type-primary"
                    >
                      Schema Configuration
                    </TextLabel>
                    <FormItemInput
                        data-testid = 'paas-etlCanvas-s3Modal-schema'
                        id="schema"
                        label="Object Schema"
                        placeholder="Object Schema"
                        value={schemaMapping?.file_schema || ''}
                        onChange={(e) => setSchemaMapping({'file_schema':e.target.value})}
                        />
                    </StackingLayout>
                )}
              
                <Button onClick={formHelper.handleSubmit} data-testid = 'paas-etlCanvas-s3Modal-save-btn'>Save</Button>
              </FormSection>
            );
          }}
        </Form>
      </StackingLayout>
    </Modal>
  );
}