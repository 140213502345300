import { Title, Pagination } from "@nutanix-ui/prism-reactjs";

import LeoContext from "./../../../contexts/leoContexts/leoContext";
import React, { useContext } from "react";
import { processCategory, processExpenseReports } from "../utils";

const CardHeader = ({ title, id, detailsPageSize, setDetailsPageSize, expenseFlightTotal, expenseTotal, flightTotal}) => {
  const {
    activeSpenders,
    currentSelection,
    setCurrentSelection,
    expenseReports,
    flightReports,
    expenseCategory,
    activeDetails,
  } = useContext(LeoContext);

  const { topSpendersExpenses, topSpendersFlights, topSpendersAggregates } =
    processExpenseReports(expenseReports, flightReports);
  const categories = processCategory(expenseCategory);


  const setSpendersPagination = (page) => {
    const paginationType =
      activeSpenders === "1"
        ? "aggregated"
        : activeSpenders === "2"
        ? "expenses"
        : "flights";

    setCurrentSelection((prevState) => ({
      ...prevState,
      expenseAndFlightPagination: {
        ...prevState.expenseAndFlightPagination,
        [paginationType]: page,
      },
    }));
  };

  const setCategoriesPagination = (page) => {
    setCurrentSelection((prevState) => ({
      ...prevState,
      expenseAndFlightPagination: {
        ...prevState.expenseAndFlightPagination,
        category: page,
      },
    }));
  };

  return (
    <div className="cardHeader">
      <div style={{ display: "flex", gap: 10 }}>
        <Title data-test-id="size-h3" size="h3" className="graphHeaderTitle">
          {title}
        </Title>
      </div>
      <div className="rightCardContainer">
        {id === "topSpenders" && (
          <>
            {activeSpenders === "1" && topSpendersAggregates.length > 0 ?  (
              <Pagination
                total={topSpendersAggregates.length}
                currentPage={
                  currentSelection.expenseAndFlightPagination.aggregated
                }
                pageSize={10}
                onChange={(page) => setSpendersPagination(page)}
              />
            ) : activeSpenders === "2" && topSpendersExpenses.length ? (
              <Pagination
                total={topSpendersExpenses.length}
                currentPage={
                  currentSelection.expenseAndFlightPagination.expenses
                }
                pageSize={10}
                onChange={(page) => setSpendersPagination(page)}
              />
            ) : activeSpenders === "3" && topSpendersFlights.length ? (
              <Pagination
                total={topSpendersFlights.length}
                currentPage={
                  currentSelection.expenseAndFlightPagination.flights
                }
                pageSize={10}
                onChange={(page) => setSpendersPagination(page)}
              />
            ): null}
          </>
        )}
        {/* category */}
        {id === "expenseCategory" && categories.length > 0 && (
          <Pagination
            total={categories.length}
            currentPage={currentSelection.expenseAndFlightPagination.category}
            pageSize={10}
            onChange={(page) => setCategoriesPagination(page)}
          />
        )}
        {id === "expensesDetails" && (
          <>
            {activeDetails === "1" && expenseFlightTotal > 0 && (
              <Pagination
                total={expenseFlightTotal}
                currentPage={currentSelection.expenseAndFlightPagination.expenseFlightDetail}
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      expenseFlightDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {activeDetails === "2" && expenseTotal > 0 && (
              <Pagination
                total={expenseTotal}
                currentPage={currentSelection.expenseAndFlightPagination.expenseDetail}
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      expenseDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {activeDetails === "3" && flightTotal > 0 && (
              <Pagination
                total={flightTotal}
                currentPage={currentSelection.expenseAndFlightPagination.flightDetail}
                pageSize={detailsPageSize}
                onChange={(page, pageSize) => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    expenseAndFlightPagination: {
                      ...prevState.expenseAndFlightPagination,
                      flightDetail: page,
                    },
                  }));
                  setDetailsPageSize(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardHeader;
