import { Parser } from "@json2csv/plainjs";

export const downloadRMETemplate = (data,label) => {
    try {
        const parser = new Parser();
        const csv = parser.parse(data);
        const element = document.createElement("a");
        const file = new Blob([csv], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = `${label}.csv`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    } catch (err) {
        console.error(err);
    }
};