import React from "react";
import {
  XAxis,
  BarChart,
  ResponsiveContainer,
  Bar,
} from "@nutanix-ui/recharts";
import { Tooltip } from "@nutanix-ui/recharts";
const BucketizationCarryOver = ({ carryOver, handleOnClick, height, width, margin }) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={carryOver}
        onClick={(e) => {
          if (e) {
            handleOnClick(e);
          }
        }}
      >
        <XAxis dataKey="name"></XAxis>
        <Bar dataKey="value" fill="#8884d8" />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BucketizationCarryOver;
