import React from "react";
import Datalineage from "../../images/datalineage.png";
import { Tooltip } from "antd";

export default function FloatingButton(props) {
    const { onDataLineageClick, disabled, tooltipMessage } = { ...props };
    const tootltipMsg = tooltipMessage || "No Data Lineage Information";

    return (
        <div className={"FloatingButtonWrapper"}>
            <div
                className={"parent-button flex-common"}
                style={{ cursor: disabled ? "inherit" : "pointer" }}
            >
                {disabled ? (
                    <Tooltip placement="left" title={tootltipMsg}>
                        <img
                            className={"floating-button-disabled"}
                            src={Datalineage}
                            alt={"data-lineage"}
                        />
                    </Tooltip>
                ) : (
                    <img
                        src={Datalineage}
                        alt={"data-lineage"}
                        onClick={onDataLineageClick}
                    />
                )}

                {/* <div 
                    // onClick={onDataLineageClick}
                    className={'child-button flex-common'}>
                    Data Lineage
                </div> */}
            </div>
        </div>
    );
}
