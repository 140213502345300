import React from "react";
import { useSelector } from "react-redux";
import VelocityRadioButton from "./RadioButton/VelocityRadioButton";
import CycleTimeRadioButton from "./RadioButton/CycleTimeRadioButton";
import CustomerDefectRadioButton from "./RadioButton/CustomerDefectRadioButton";
import CodeCoverageRadioButton from "./RadioButton/CodeCoverageRadioButton";

const FilterRadioButton = () => {
  const { activeHeaderTab } = useSelector((state) => state.enggDashboard);
  return (
    <>
      {activeHeaderTab === 1 && <VelocityRadioButton />}
      {activeHeaderTab === 2 && <CycleTimeRadioButton />}
      {activeHeaderTab === 3 && <CustomerDefectRadioButton />}
      {activeHeaderTab === 4 && <CodeCoverageRadioButton />}
    </>
  );
};

export default FilterRadioButton;
