import React, { useEffect, useMemo, useState } from "react";
import { Title ,DatePicker,Button} from "@nutanix-ui/prism-reactjs";
import axios from "axios";
import { SERVER_URL } from "../../../../../config/config";
import { useRMEFilters } from "../../../../../contexts/rmeTeamContext/RMEFiltersContext";
import FilterDropdown from "../../../../EngineeringDashboard/Components/FilterDropdown/FilterDropdown";
import { generateMenuForDropdown } from "../../../../EngineeringDashboard/EngineeringUtils";
import moment from "moment";
const RMEFILTER = ({ setApplyClicked }) => {
  
  const { selectedFilters, updateFilters,loading,setLoading, setTeamData,tableData, setTableData,tableColumns, setTableColumns } = useRMEFilters(); // Ensure correct destructuring
 
  const [openTicketType, setOpenTicketType] = useState(false);
  const [openIssueNum, setIssueNum] = useState(false);
  const [openEngType, setOpenEngType] = useState(false);
  const [openAging, setOpenAging] = useState(false);

  const [openAssignee, setOpenAssignee] = useState(false);
  const [openAcStatus, setOpenAcStatus] = useState(false);
  const [openRMERegion, setRMERegion] = useState(false);
  const [openRMEStatus, setOpenRMEStatus] = useState(false);

  let date = moment().format('YYYY-MM-DD');
  const [endDate, setEndDate] = useState(date);

  date = moment().subtract(3, 'months').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(date);

  const [filterData, setFilterData] = useState([]);

  const [error, setError] = useState(null);
  const [initialFilterData, setInitialFilterData] = useState(null); // Holds the first fetched data
  const filterQueryKey = useMemo(() => {
    const { ticket_type, issuenum, aging, engage_type, assignee,  current_acc_sts, rme_region, rme_status, } = selectedFilters;

    return {
      ticket_type: ticket_type[0] === 'All' ? 'all' : ticket_type.length>1 ? ticket_type :ticket_type.join(),
      issuenum: issuenum[0] === 'All' ? 'all' : issuenum.length>1 ? issuenum :issuenum.join(),
      start_date:startDate || '',
      end_date:endDate || '',

      engage_type: engage_type[0] === 'All' ? 'all' : engage_type.length>1 ? engage_type :engage_type.join(),

      assignee: assignee[0] === 'All' ? 'all' : assignee.length>1 ? assignee :assignee.join(),
      current_acc_sts: current_acc_sts[0] === 'All' ? 'all' : current_acc_sts.length>1 ? current_acc_sts :current_acc_sts.join(),
      rme_region: rme_region[0] === 'All' ? 'all' : rme_region.length>1 ? rme_region :rme_region.join(),
      rme_status: rme_status[0] === 'All' ? 'all' : rme_status.length>1 ? rme_status :rme_status.join(),
    };
  }, [selectedFilters,startDate,endDate]);

  const fetchRMEFilterData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${SERVER_URL}/rme_filter`, 
      );
      if (!initialFilterData) {
        setInitialFilterData(data[0]?.result);
      }
      setFilterData(data[0]?.result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRMEFilterData(); 
    fetchRMEData()
  }, []);
  const fetchRMEData = async() =>{
   try {
      setLoading(true);
      const filteredObj = Object.fromEntries(
        Object.entries(filterQueryKey).filter(([key, value]) => value !== 'all' && value !=='')
      );
      setLoading(true);
      const { data } = await axios.post(
        `${SERVER_URL}/rme-team-details`, filteredObj);
  setTeamData(data)
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }
  const handleApplyClick = async () => {
    setApplyClicked(true);
    await fetchRMEData();
    setApplyClicked(false);
  };

  if (error) {
    throw error;
  }

  const createDropdownConfig = ({
    id,
    label,
    openState,
    selectedFilters,
    dataKey,
    isLoading,
    data,
    setOpenState,
    updateFilters,
  }) => {
   
    return {
      id,
      label,
      mode: "multiple",
      open: openState,
      loading: isLoading,
      handleClose: () => setOpenState(false),
      handleApplyClick,
      component: "ux_team",
      selectedItem: selectedFilters[id],
      items: !isLoading
        ? [
            { label: "All", value: "All" },
            ...generateMenuForDropdown(
              !['ticket_type','rme_status','aging' ].includes(dataKey) ? 
              (Object.keys(initialFilterData || {}).length > 0 ? 
              initialFilterData[dataKey] : []) : data
            ),
          ]
        : [],
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          updateFilters(id, ["All"]);
        }
        setOpenState(true);
      },
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          updateFilters(id, filteredItem);
        } else if (item.length === 0) {
          updateFilters(id, ["All"]);
        } else {
          updateFilters(id, item);
        }
      },
    };
  };

  const ticketTypeDropdownConfig = createDropdownConfig({
    id: "ticket_type",
    label: "Ticket Type",
    openState: openTicketType,
    selectedFilters,
    dataKey: "ticket_type",
    isLoading: loading,
    data: ['RM RME','SM RME'],
    setOpenState: setOpenTicketType,
    updateFilters,
  });

  const issueNumDropdownConfig = createDropdownConfig({
    id: "issuenum",
    label: "Issue Number",
    openState: openIssueNum,
    selectedFilters,
    dataKey: "issuenum",
    isLoading: loading,
    data: filterData,
    setOpenState: setIssueNum,
    updateFilters,
  });

  const AgingDropdownConfig = createDropdownConfig({
    id: "aging",
    label: "Aging",
    openState: openAging,
    selectedFilters,
    dataKey: "aging",
    isLoading: loading,
    data: [],
    setOpenState: setOpenAging,
    updateFilters,
  });

  const EnggagementTypeDropdownConfig = createDropdownConfig({
    id: "engage_type",
    label: "Engagement Type",
    openState: openEngType,
    selectedFilters,
    dataKey: "engage_type",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenEngType,
    updateFilters,
  });

  const assigneeDropdownConfig = createDropdownConfig({
    id: "assignee",
    label: "assignee",
    openState: openAssignee,
    selectedFilters,
    dataKey: "assignee",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenAssignee,
    updateFilters,
  });

  const AccStatusDropdownConfig = createDropdownConfig({
    id: "current_acc_sts",
    label: "Account Status",
    openState: openAcStatus,
    selectedFilters,
    dataKey: "current_acc_sts",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenAcStatus,
    updateFilters,
  });

  const RMERegionsDropdownConfig = createDropdownConfig({
    id: "rme_region",
    label: "RME Region",
    openState: openRMERegion,
    selectedFilters,
    dataKey: "rme_region",
    isLoading: loading,
    data: filterData,
    setOpenState: setRMERegion,
    updateFilters,
  });

  const RMEStatusDropdownConfig = createDropdownConfig({
    id: "rme_status",
    label: "RME STATUS",
    openState: openRMEStatus,
    selectedFilters,
    dataKey: "rme_status",
    isLoading: loading,
    data: ['Open','Closed'],
    setOpenState: setOpenRMEStatus,
    updateFilters,
  });

  const dropdownConfigs = [
    ticketTypeDropdownConfig,
    issueNumDropdownConfig,
    AgingDropdownConfig,
    EnggagementTypeDropdownConfig,
    assigneeDropdownConfig,
    AccStatusDropdownConfig,
    RMERegionsDropdownConfig,
    RMEStatusDropdownConfig
  ];
  
  return (
    <>
      <Title className="titleText">Filters</Title>
      <div className="uxDropdownContainer" data-testid="ux-filter-container">
        {dropdownConfigs.map((dropdown) => 
        {
          if(dropdown.id ==='aging'){
          return (
            <div className="rme-datePicket">
              <span style={{ color: "gray" }}>Age</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <DatePicker
                  name="datepicker-with-range"
                  data-test="range-select"
                  oldDatePicker={false}
                  defaultValue={{
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                  }}
                  onChange={(selectedDate) => {
                    const button = document.querySelector(
                      ".wiget_header_container .ntnx-date-picker-open.ntnx.ntnx-button"
                    );
                    if (button) {
                      button.click();
                    }
                    if (selectedDate) {
                      if (selectedDate?.endDate) {
                        setEndDate((prevState) =>
                          selectedDate.endDate.format("YYYY-MM-DD")
                        );
                      }
                      if (selectedDate?.startDate) {
                        setStartDate((prevState) =>
                          selectedDate.startDate.format("YYYY-MM-DD")
                        );
                      }
                    }
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    handleApplyClick();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          );
          }else{

           return <FilterDropdown key={dropdown.id} {...dropdown} />
          }
        })}
      </div>
    </>
  );
};

export default RMEFILTER;
