import { Table } from "antd";
import greenLogo from "../../images/heart-green.png";
import redLogo from "../../images/heart-red.png";

const LineageTable = ({ lineageData, fromCard }) => {
    const getHealthSorter = (a, b) => {
        const healthA = a ? 1 : 0;
        const healthB = b ? 1 : 0;
        return healthA - healthB;
    };

    const scroll = !fromCard
        ? {}
        : {
              y: 440,
          };

    const columns = [
        {
            title: "Schema",
            dataIndex: "schema_name",
            key: "schema_name",
        },
        {
            title: "Table",
            dataIndex: "table_name",
            key: "table_name",
        },
        {
            title: "Last refresh",
            dataIndex: "last_refresh_ts",
            key: "last_refresh_ts",
        },
        {
            title: "Next schedule",
            dataIndex: "next_schedule",
            key: "next_schedule",
        },
        {
            title: "Health",
            dataIndex: "data_health",
            key: "data_health",
            sorter: (a, b) => getHealthSorter(a.data_health, b.data_health),
            render: (text) => (
                <img
                    src={text ? greenLogo : redLogo}
                    alt="data health"
                    style={{ width: "25px" }}
                />
            ),
        },
    ];
    return (
        <div style={fromCard ? {} : { width: 1000 }}>
            <Table
                columns={columns}
                dataSource={lineageData}
                pagination={false}
                scroll={scroll}
            />
        </div>
    );
};

export default LineageTable;
