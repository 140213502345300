export const teamContactDataSource = [
    {
      sno: "1",
      name: "Neeta Kolaki",
      team: "India",
      phone: "9844980073",
      email: "neeta.kolaki@nutanix.com",
    },
 
    {
      sno: "2",
      name: "Shashank Kulkarni",
      team: "India",
      phone: "9844993475",
      email: "shashank.kulkarni@nutanix.com",
    },
   
    {
      sno: "3",
      name: "Varsha Mohan",
      team: "India",
      phone: "8792746564",
      email: "varsha.mohan@nutanix.com",
    },
    {
      sno: "4",
      name: "Risha Rai",
      team: "India",
      phone: "9167319261",
      email: "risha.rai@nutanix.com",
    },
    {
      sno: "5",
      name: "Prabhakaran Sankaran",
      team: "India",
      phone: "9986274919",
      email: "prabhakaran.s@nutanix.com",
    },
    {
      sno: "6",
      name: "Chenna Kesava Reddy Gajjala",
      team: "India",
      phone: "",
      email: "kesava.reddy@nutanix.com",
    },
    {
      sno: "7",
      name: "Raghunatha Reddy Siddareddygari",
      team: "India",
      phone: "9766735992",
      email: "raghunathareddy.s@nutanix.com",
    },
    {
      sno: "8",
      name: "Sukumar Bairi",
      team: "India",
      phone: "9573391065",
      email: "sukumar.bairi@nutanix.com",
    },
    {
      sno: "9",
      name: "Aman Taneja",
      team: "US",
      phone: "201-233-8466",
      email: "aman.taneja@nutanix.com",
    },
    {
      sno: "10",
      name: "Anshu Bhatnagar",
      team: "US",
      phone: "510-449-8322",
      email: "anshu.bhatnagar@nutanix.com",
    },
    {
      sno: "11",
      name: "Tauseef Ahmad",
      team: "US",
      phone: "925-967-8384",
      email: "tauseef.ahmad@nutanix.com",
    },
    {
      sno: "12",
      name: "Devraj Beniwal",
      team: "US",
      phone: "608-622-5094",
      email: "devraj.beniwal@nutanix.com",
    },
    {
      sno: "13",
      name: "Subramanian Venkateswaran",
      team: "US",
      phone: "408-489-2122",
      email: "Subramanian.V@Nutanix.com",
    },
    {
      sno: "14",
      name: "Hank Chen",
      team: "US",
      phone: "510-371-3410",
      email: "hank.chen@nutanix.com",
    },
    {
      sno: "15",
      name: "Neetika Kaura",
      team: "US",
      phone: "408-507-4900",
      email: "neetika.kaura@nutanix.com",
    },
    {
      sno: "16",
      name: "Meena Sangareswaran",
      team: "US",
      phone: "",
      email: "meena.sangareswaran@nutanix.com",
    },
    {
      sno: "17",
      name: "Karthik Krishnasamy",
      team: "US",
      phone: "408-912-6357",
      email: "karthik.krishnasamy@nutanix.com",
    },
    {
      sno: "18",
      name: "Lalitya Sawant",
      team: "US",
      phone: "669-255-9386",
      email: "lalitya.sawant@nutanix.com",
    },
    {
      sno: "19",
      name: "Prathyusha Gidugu",
      team: "US",
      phone: "978-905-5247",
      email: "prathyusha.gidugu@nutanix.com",
    },
    {
      sno: "20",
      name: "Dipu Panday",
      team: "US",
      phone: "669-588-7805",
      email: "dipu.panday@nutanix.com",
    },
    {
      sno: "21",
      name: "Arun Porwal",
      team: "US",
      phone: "510-333-2699",
      email: "arun.porwal@nutanix.com",
    },
  ];