import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemTextArea,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  FlexLayout,
  TextLabel,
  PlusIcon,
  FlexItem,
  CloseIcon,
  FormItemProvider,
  MultiSelect
} from "@nutanix-ui/prism-reactjs";


import axios from "axios";
import { SERVER_URL } from "../../config/config";
import { useLocation } from "react-router-dom";

export default function SnowFlakeModal(props) {
    const SelectWithLabel = FormItemProvider(MultiSelect);
  
    const location = useLocation();
    const { schema, origin, handleOnSave } = props;
  
    const DEFAULT_VALIDATIONS = [
      {
        alias: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Alias is required",
        },
      },
      {
        tableName: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Table Name is required",
        },
      },
      {
        schemaForm: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Schema is required",
        },
      },
      {
        start_offset: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Starting Offset is required",
        },
      },
      {
        last_date: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Last Date is required",
        },
      },
      {
        query: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Query is required",
        },
      },
    ];
  
    const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
    useEffect(() => {
      if (origin === "Source" && location.state?.scheduleType === "streaming") {
        validations.pop();
        setValidations(validations);
      }
      if (origin === "Target") {
        validations.shift();
        setValidations(validations);
      }// eslint-disable-next-line 
    }, []);
    const [selectedTableName, setSelectedTableName] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const startOffsetDataArray = [
      { key: "earliest", label: "earliest", rowProps:{
        'data-testid':'earliest'
       } },
      { key: "latest", label: "latest", rowProps:{
        'data-testid':'latest'
       } },
    ];
    const [selectedSchema, setSelectedSchema] = useState(null);
    const [selectedTableColsName, setSelectedTableColsName] = useState(null);
    const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
    const [schemaText, setSchemaText] = useState("");
    const [noSQLSchemaText, setNOSQLSchemaText] = useState("");
    const [noSQLSchemaRows, setNOSQLSchemaRows] = useState([]);
    const [schemaRowsData, setSchemaRowsData] = useState([]);
  
    const [schemaAndTableData, setSchemaAndTableData] = useState([]);
  
    const [schemaData, setSchemaData] = useState([]);
    const [searchSchemaData, setSearchSchemaData] = useState([]);
  
    const [schemaLoading, setSchemaLoading] = useState(true);
    const [tableColsLoading, setTableColsLoading] = useState(true);
  
    const [tableNameData, setTableNameData] = useState([]);
    const [searchTableNameData, setSearchTableNameData] = useState([]);
  
    const [tableColsNameData, setTableColsNameData] = useState([]);
    const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
    const [searchStartOffsetData, setSearchStartOffsetData] = useState(startOffsetDataArray);
    const [selectedStartOffset, setSelectedStartOffset] = useState(null);
    // const [tableLoading, setTableLoading] = useState(true);
    const handleOnchangeNOSQLSchmeaText = (text, index) => {
      if (index > -1) {
        noSQLSchemaRows[index] = text;
        setNOSQLSchemaRows(noSQLSchemaRows);
      } else {
        setNOSQLSchemaText(text);
      }
    };
    const addSchemaRows = async () => {
      let obj = { key: schemaText };
      obj[selectedTableColsName?.label] = obj["key"];
      delete obj["key"];
      schemaRowsData.push(obj);
      setSchemaRowsData(schemaRowsData);
      setSelectedTableColsName(null);
      setSchemaText("");
    };
    const addNOSQLSchemaRows = () => {
      noSQLSchemaRows.push(noSQLSchemaText);
      setNOSQLSchemaRows(noSQLSchemaRows);
      setNOSQLSchemaText("");
    };
    const removeNOSQLSchemaRows = (index) => {// eslint-disable-next-line 
      const newRows = noSQLSchemaRows.filter((sale, i) => i != index);
      setSchemaRowsData(newRows);
    };
    const removeSchemaRows = (index) => {// eslint-disable-next-line 
      const newRows = schemaRowsData.filter((sale, i) => i != index);
      setSchemaRowsData(newRows);
    };
    const handleEditSchemaRows = (objKVType, value, i) => {
      let key = Object.keys(schemaRowsData[i])[0];
      if (objKVType === "value") {
        schemaRowsData[i][key] = value;
      } else {
        schemaRowsData[i][value.label] = schemaRowsData[i][key];
        delete schemaRowsData[i][key];
      }
    };
    const handleTableNameChange = (item) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const projectID = queryParameters.get("projectID");
      let payload = {
        project_id: projectID,
        conn_name: schema?.value,
        schema_name: selectedSchema?.label,
        object_name: item?.label,
      };
      setSelectedTableName((prevState) => item);
      getTableColumns(payload);
    };
    const getTableColumns = (payload) => {
      axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {
        if (resp.data?.data?.res) {
          setTableColsLoading(false);
          const tableColKeys = Object.keys(resp?.data?.data?.res);
          let TablleColsData = [];
          tableColKeys.forEach((o, i) => {
            TablleColsData.push({
              id: ++i,
              key: o,
              label: o,
              rowProps:{
               'data-testid':o
              }
            });
          });
          setTableColsNameData((prevState) => TablleColsData);
          setSearchTableColsNameData((prevState) => TablleColsData);
        }
      });
    };
    const handleOnInputValueChange = (
      event,
      searchArray,
      setSearchArray,
      setSelectedValue
    ) => {
      if (event.target.value.length > 0) {
        if(searchArray.length===0){
          setSchemaLoading(false);
          setTableColsLoading(false)
         }
        const newRows = searchArray?.filter((row) => {
          return row.label.toLowerCase().includes(event.target.value.toLowerCase());
        });
        newRows.unshift({ key: event.target.value, label: event.target.value })
        setSearchArray(newRows);
        setSelectedValue({ key: event.target.value, label: event.target.value });
      } else if (event.target.value.length === 0) {
        setSearchArray(searchArray);
        setSelectedValue(null);
      }
    };
    const handleSchemaChange = (item) => {
      item !== null && setSelectedSchema((prevState) => item);
      const tableKeys = schemaAndTableData&&schemaAndTableData[[item.key]] ? Object.keys(schemaAndTableData[item.key]) : [];
      let tempData = [];
      tableKeys.forEach((o, i) => {
        tempData.push({
          key: i++,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setTableNameData((prevState) => tempData);
      setSearchTableNameData((prevState) => tempData);
      // setTableLoading((prevState) => false);
    };
  
    useEffect(() => {
      fetchSchemaAndTable();// eslint-disable-next-line 
    }, [schema]);
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    const fetchSchemaAndTable = (value) => {
      if (schema) {
        setSchemaLoading((prevState) => true);
        // setTableLoading((prevState) => true);
        axios
          .post(`${SERVER_URL}/fetchSchemaAndTable`, {
            project_id: projectID, //"75f260",
            conn_name: schema?.value ? schema?.value : "pg_stg_r1",
          })
          .then((resp) => {
            if (resp?.data?.data?.res) {
              constructSchemaData(resp.data.data.res);
            }
          });
      }
    };
  
    const constructSchemaData = (res) => {
      setSchemaAndTableData((prev) => res);
      let tempData = [];
      let schemaKeys = Object.keys(res);
      schemaKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setSchemaData((prevState) => tempData);
      setSearchSchemaData((prevState) => tempData)
      setSchemaLoading((prevState) => false);
    };
    const propsData = {
      rowsData: tableColsNameData || [],
      groupNestedItemsTag: false,
      selectAllOption: true,
    };
    return (
      <Modal
        visible={props.visible}
        title={schema?.value + " ( " + schema?.label + ")" || "Schema"}
        onClose={props.handleOnClose}
        closeIconProps={{ "data-test": "process-pipeline-data-test" }}
        footer={false}
      >
        <StackingLayout padding="20px">
          <Form
            validations={validations}
            onSubmit={(values) => {
              handleOnSave(
                values,
                schemaRowsData,
                noSQLSchemaRows,
                selectedColumns,
                origin
              );
            }}
          >
            {(formHelper) => {
              return (
                <FormSection>
                  <FormItemInput
                    id="id"
                    value={schema?.id || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Input ID"
                  />
                  {origin !== "Target" && (
                    <FormItemInput
                      data-testid='paas-etlCanvas-snowflake-formModal-alias'
                      id="alias"
                      formHelper={formHelper}
                      label={"Alias"}
                    />
                  )}
                  <FormItemInput
                    id="warehouse"
                    value={schema?.warehouse || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Warehouse"
                  />
                  <FormItemInput
                    id="db"
                    value={schema?.db || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Database"
                  />
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflake-formModal-schema'
                    id="schemaForm"
                    selected={selectedSchema}
                    rowsData={searchSchemaData}
                    loading={schemaLoading}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleSchemaChange(item)}
                    label={"Schema"}
                    inputProps={
                      { value:selectedSchema?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,schemaData,setSearchSchemaData,setSelectedSchema)} 
                  />
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflake-formModal-tableName'
                    id="tableName"
                    showSearch={true}
                    selected={selectedTableName}
                    rowsData={searchTableNameData}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleTableNameChange(item)}
                    label={"Table Name"}
                    inputProps={
                      { value:selectedTableName?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,tableNameData,setSearchTableNameData,setSelectedTableName)} 
                  />
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflake-formModal-start_offset'
                    id="start_offset"
                    showSearch={true}
                    selected={selectedStartOffset}
                    rowsData={searchStartOffsetData}
                    formHelper={formHelper}
                    onSelectedChange={(item) => setSelectedStartOffset(item)}
                    label={"Starting Offset"}
                    inputProps={
                      { value:selectedStartOffset?.label || ''}
                     }
                     searchable={true}
                     onChange={(event)=>handleOnInputValueChange(event,startOffsetDataArray,setSearchStartOffsetData,setSelectedStartOffset)} 
                  />
                  <FormItemSelect
                    id="offset_col"
                    data-testid='paas-etlCanvas-snowflake-formModal-offset_col'
                    rowsData={searchTableColsNameData}
                    loading={tableColsLoading}
                    formHelper={formHelper}
                    label={"Offset Column"}
                    helpText="Optional"
                    onSelectedChange={(item) => setSelectedOffsetCol(item)}
  
                    selected={selectedOffsetCol}
                    inputProps={
                      { value:selectedOffsetCol?.label || ''}
                     }
                    searchable={true}
                    onChange={(event)=>handleOnInputValueChange(event,tableColsNameData,setSearchTableColsNameData,setSelectedOffsetCol)} 
                  
                  />
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid='paas-etlCanvas-snowflake-formModal-uuid_columns'
                      id="uuid_columns"
                      formHelper={formHelper}
                      label={"UUID Columns"}
                      helpText="Optional"
                    />
                  )}
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid='paas-etlCanvas-snowflake-formModal-truncate_and_load'
                      id="truncate_and_load"
                      formHelper={formHelper}
                      label={"Truncate And Load"}
                      helpText="Optional"
                    />
                  )}
                  <FormItemInput
                    data-testid='paas-etlCanvas-snowflake-formModal-last_date'
                    id="last_date"
                    formHelper={formHelper}
                    label={"Last Date"}
                  />
                  <SelectWithLabel
                    data-testid='paas-etlCanvas-snowflake-formModal-columns'
                    selectedRows={selectedColumns || []}
                    onSelectedChange={(rows) => {
                      setSelectedColumns(rows);
                    }}
                    formItemProviderProps={{ id: "columns" }}
                    overflowLimit={2}
                    {...propsData}
                    label={"Columns"}
                  />
                  {origin === "Source" &&
                  location.state?.scheduleType === "streaming" ? (
                    ""
                  ) : (
                    <FormItemTextArea
                      data-testid='paas-etlCanvas-snowflake-formModal-query'
                      id="query"
                      label="Query"
                      formHelper={formHelper}
                      placeholder="Please Type your query here"
                      style={{ height: "10rem" }}
                    />
                  )}
                  {origin === "Source" && (
                    <StackingLayout>
                      <TextLabel
                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                        data-test-id="type-primary"
                      >
                        Schema Configuration
                      </TextLabel>
  
                      {schema.dbType === "RDBMS" &&
                        schemaRowsData.length > 0 &&
                        schemaRowsData.map((row, i) => (
                          <FlexLayout
                            flexDirection="row"
                            alignItems="flex-end"
                            key={i}
                          >
                            <FormItemSelect
                              data-testid='paas-etlCanvas-snowflake-formModal-addColumns'
                              id={i}
                              selectedRow={{
                                key: Object.keys(row)[0],
                                label: Object.keys(row)[0],
                              }}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                handleEditSchemaRows("key", item, i)
                              }
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid='paas-etlCanvas-snowflake-formModal-addSchema'
                              key={i}
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={Object.values(row)[0]}
                              onChange={(e) =>
                                handleEditSchemaRows("value", e.target.value, i)
                              }
                            />
                            <FlexItem>
                              <Button
                                data-testid='paas-etlCanvas-snowflake-formModal-cancel-btn'
                                type="secondary"
                                style={{ backgroundColor: "gray" }}
                                onClick={() => removeSchemaRows(i)}
                              >
                                <CloseIcon
                                  size="small"
                                  aria-hidden="true"
                                  color="secondary"
                                />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        ))}
  
                      {schema?.dbType === "RDBMS" ? (
                        <StackingLayout>
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FormItemSelect
                              data-testid='paas-etlCanvas-snowflake-formModal-columnsData'
                              id="columns Data"
                              selectedRow={searchTableColsNameData}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                setSelectedTableColsName(item)
                              }
                              selected={selectedTableColsName}
                              inputProps={
                                { value:selectedTableColsName?.label || ''}
                              }
                              searchable={true}
                              onChange={(event)=>handleOnInputValueChange(event,tableColsNameData,setSearchTableColsNameData,setSelectedTableColsName)}
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid='paas-etlCanvas-snowflake-formModal-addSchema-btn'
                              id="schema"
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={schemaText}
                              onChange={(e) => setSchemaText(e.target.value)}
                            />
                            <FlexItem>
                              <Button onClick={addSchemaRows}>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                      ) : (
                        <StackingLayout>
                          {noSQLSchemaRows?.length > 0 &&
                            noSQLSchemaRows.map((row, i) => (
                              <FlexLayout
                                flexDirection="row"
                                alignItems="flex-end"
                                key={i}
                              >
                                <FlexItem flexGrow="1">
                                  <FormItemInput
                                    id="schema"
                                    label="Add Schema"
                                    placeholder="Add Schema"
                                    value={row}
                                    onChange={(e) =>
                                      handleOnchangeNOSQLSchmeaText(
                                        e.target.value,
                                        i
                                      )
                                    }
                                  />
                                </FlexItem>
                                <FlexItem>
                                  <Button
                                    onClick={() => removeNOSQLSchemaRows(i)}
                                  >
                                    <CloseIcon size="small" aria-hidden="true" />
                                  </Button>
                                </FlexItem>
                              </FlexLayout>
                            ))}
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FlexItem flexGrow="1">
                              <FormItemInput
                                id="schema"
                                label="Add Schema"
                                placeholder="Add Schema"
                                value={noSQLSchemaText}
                                onChange={(e) =>
                                  handleOnchangeNOSQLSchmeaText(
                                    e.target.value,
                                    -1
                                  )
                                }
                              />
                            </FlexItem>
                            <FlexItem>
                              <Button onClick={addNOSQLSchemaRows}>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                      )}
                    </StackingLayout>
                  )}
                  <Button data-testid='paas-etlCanvas-snowflake-formModal-save-btn'
                    onClick={formHelper.handleSubmit}
                  >
                    Save
                  </Button>
                </FormSection>
              );
            }}
          </Form>
        </StackingLayout>
      </Modal>
    );
  }