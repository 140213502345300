import React, { useEffect, useState } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "@nutanix-ui/recharts";
import { useDispatch, useSelector } from "react-redux";
import { getVelocityColumn, getVelocityDataSource, useVelocityDetail, formatQuarter, getDataByPage, CustomizedAxisTick, graphHeight, generateBarDataVelocity, getPeriodSelectionVelocity, CartesianGridColor, RefrenceLineColor, renderMixStackedBarsVelocity, issueTypeColors } from "../../EngineeringUtils";
import { setColumnData, setDataSourceData, setOpenModal } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import NoData from "./NoData";
import { TextLabel } from "@nutanix-ui/prism-reactjs";
import { Badge } from "antd";
import { InformationStatusIcon } from "@nutanix-ui/prism-reactjs";
// InformationSquareIcon: This is deprecated starting with v2.32.0. The component name is kept for backward compatability. Please use InformationStatusIcon instead.

const AverageTeamVelocity = () => {
  const dispatch = useDispatch()
  const { avgDeveloperVelocityData, avgDeveloperPage, filters, defaultViewVelocity } = useSelector((state) => state.enggDashboard);
  const [param, setParam] = useState({});
  const [enabled, setEnabled] = useState(false)
  const [data, setData] = useState([]);
  const { data:avgTeamvelocity, isLoading } = useVelocityDetail(filters, enabled, param.interval, 'avg_team_velocity', param.name)
  

  useEffect(() => {
    if(enabled && !isLoading && avgTeamvelocity?.length > 0){
      dispatch(setColumnData(getVelocityColumn()))
      dispatch(setDataSourceData(getVelocityDataSource(avgTeamvelocity)))
      dispatch(setOpenModal(true));
      setEnabled(false)
    }
  }, [avgTeamvelocity, enabled, isLoading])

  useEffect(() => {
    if(avgDeveloperVelocityData?.length > 0) {
      setData(generateBarDataVelocity(avgDeveloperVelocityData, getPeriodSelectionVelocity(avgDeveloperVelocityData, filters?.viewByVelocity) , 'storyPoints'))
    }
  }, [avgDeveloperVelocityData, filters]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      // Extract periods and issue types
      const periods = Object.keys(data).filter(key => !/-(defects|feature|enhancement)|name/.test(key));
      const issueTypeKeys = ["defects", "enhancement", "feature"]; 
      // Helper function to round values only if they are non-zero
      const roundValue = (value) => {
        return value !== 0 ? value.toFixed(1) : value;
      };
  
      // Calculate total SP for each period (only period data, not issue type)
      const totals = periods.map(period => {
        const totalValue = issueTypeKeys.reduce((sum, issueType) => {
          return sum + (data[`${period}-${issueType}`] || 0);
        }, 0);
        return totalValue;
      });
  
      // Create data rows for each issue type with percentage
      const rows = issueTypeKeys.map(issueType => (
        <React.Fragment key={issueType}>
          <div className="grid-item">
            <Badge
              color={issueTypeColors[issueType]}
              text={issueType.charAt(0).toUpperCase() + issueType.slice(1)}
            />
          </div>
          {periods.map((period, index) => {
            const value = data[`${period}-${issueType}`] || 0;
            const total = totals[index];
            const percentage = total > 0 ? ((value / total) * 100).toFixed(0) : 0;  // Calculate percentage
  
            return (
              <div key={`${issueType}-${period}`} className="grid-item center-aligned">
                {`${roundValue(value)} (${percentage}%)`}  {/* Display SP and percentage */}
              </div>
            );
          })}
        </React.Fragment>
      ));
  
      return (
        <div className="custom-tooltip-velocity">
          <TextLabel
            display="block"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            {label}
          </TextLabel>
          <div>
            <TextLabel><InformationStatusIcon color={issueTypeColors.defects} /><strong> Defects:</strong> Bug, Support </TextLabel>
          </div>
          <div>
            <TextLabel><InformationStatusIcon color={issueTypeColors.enhancement} /><strong> Enhancement: </strong>Improvement, Best Practices, Feedback</TextLabel>
          </div>
          <div>
            <TextLabel><InformationStatusIcon color={issueTypeColors.feature} /><strong> Feature: </strong>Mini-Task, New Feature, Story, Task, Test, Training, Unit Test, User Story</TextLabel>
          </div>
          <div className="grid-container-velocity" style={{gridTemplateColumns: `repeat(${filters?.viewByVelocity === 'Quarterly' ? 5 : 7}, 1fr)`}}>
            <div className="grid-item bold">Issue Type</div>
            {periods.map((item) => (
              <div key={item} className="grid-item bold center-aligned">
                {filters?.viewByVelocity === 'Quarterly'? formatQuarter(item): item}
              </div>
            ))}
            {rows}
            <div className="grid-item bold">Total</div>
            {totals.map((total, index) => (
              <div key={`total-${periods[index]}`} className="grid-item bold center-aligned">
                {roundValue(total)}
              </div>
            ))}
          </div>
        </div>
      );
    }
  
    return null; // Return null if not active or no payload
  };
  return (
    <ResponsiveContainer height={graphHeight} width="100%">
      {data?.length > 0 ? (
        <BarChart
          data={getDataByPage(
            data,
            avgDeveloperPage.currentPage,
            avgDeveloperPage.pageSize
          )}
          margin={{ top: 20, right: 30, bottom: 30, left: 0 }}
          alignment="none"
        >
          <CartesianGrid stroke={CartesianGridColor} interval={0} />
          <XAxis tick={<CustomizedAxisTick />} dataKey="name" />
          <YAxis />
          <ReferenceLine
            y={ filters.viewByVelocity === "Quarterly" ? 60 : 20 }
            stroke={RefrenceLineColor}
            strokeDasharray="6 6"
            // label={{
            //   value: `Threshold (${filters.viewByVelocity === "Quarterly" ? 60 : 20} SP)`,
            //   position: "insideBottomLeft",
            //   fill: "#f2f4f6",
            // }}
          />
          <Tooltip content={<CustomTooltip />} />
          {renderMixStackedBarsVelocity(data, filters?.viewByVelocity, defaultViewVelocity?.default_story, setParam, setEnabled)}
        </BarChart>
      ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default AverageTeamVelocity;