import {
    AlertTriangleIcon,
    Link,
    Loader,
    Modal,
    RestartIcon,
    Select,
    TextLabel,
    Title,
  } from "@nutanix-ui/prism-reactjs";
  import React, { useEffect, useState } from "react";
  import "./styles.css";
  import FactoryDataModal from "./FactoryDataModal";
  import { SERVER_URL } from "../../config/config";
  import { useQuery } from "@tanstack/react-query";
  import { fetchTableData, formatDate } from "../FactoryData/utility";
  import { useHistory } from "react-router-dom";
  import greenLogo from "../../images/heart-green.png";
  import yellowLogo from "../../images/heart-yellow.png";
  import redLogo from "../../images/heart-red.png";
  const viewDropdownData = [
    {
      key: 1,
      label: "7 Days",
      value: 7,
    },
    {
      key: 2,
      label: "14 Days",
      value: 14,
    },
    {
      key: 3,
      label: "21 Days",
      value: 21,
    },
    {
      key: 4,
      label: "30 Days",
      value: 30,
    },
  ];

  const FactoryDataTilesDataSync = ({ handleCardStyle }) => {
    const history = useHistory(); // Get the history object
    const [state, setState] = useState({
      modalVisible: false,
      loading: false,
      loadingError: false,
      selectedRow: viewDropdownData[0],
      fetchURL: `${SERVER_URL}/manufacturing-factory-eod`,
      factoryData: [],
    });
  
    const { data, error, isFetching, refetch } = useQuery({
      queryKey: [
        "fetchData",
        {
          page: 1,
          pageSize: state.selectedRow.value,
          search: "",
          fetchUrl: state.fetchURL,
          column: 'ship_date',
          order: 'desc',
        },
      ],
      queryFn: fetchTableData,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
    });
  const factoryDataValue = calculateQualityIndex(
    state?.factoryData[0]?.sfdc_count,
    state?.factoryData[0]?.factory_eod_count
  );
  useEffect(() => {
    if (typeof handleCardStyle === "function") {
      if (factoryDataValue >= 99) {
        handleCardStyle(false);
      } else {
        handleCardStyle(true);
      }
    }
  }, [
    state?.factoryData[0]?.sfdc_count !== undefined &&
      factoryDataValue &&
      handleCardStyle,
  ]);
    useEffect(() => {
      if (data?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          factoryData: data?.data,
        }));
      }
    }, [data]);
  
    const handleCloseModal = () => {
      setState((prevState) => ({
        ...prevState,
        modalVisible: false,
      }));
    };
  
    const handleOnSelectedChange = (selectedRow) => {
      setState((prevState) => ({
        ...prevState,
        selectedRow,
        loading: true,
      }));
      setTimeout(() => {
        refetch();
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }, 500);
    };
  
    function calculateQualityIndex(sfdcCount, factoryEodCount) {
      if (sfdcCount === 0 && factoryEodCount === 0) {
        return 100; // Return 100% if both count is zero
      }
    
      const difference = Math.abs(sfdcCount - factoryEodCount);
      const maxCount = Math.max(sfdcCount, factoryEodCount);
      const qualityIndex = Math.floor((1 - (difference / maxCount)) * 100);
      return Math.min(qualityIndex, 100); // Ensure the result doesn't exceed 100%
    }
  
    if (error) {
      if (error.response.status === 403) {
        throw error;
      }
  
      return (
        <div className="fetchErrorContainer">
          <TextLabel
            data-test-id="type-error-small"
            type={TextLabel.TEXT_LABEL_TYPE.ERROR}
          >
            <AlertTriangleIcon /> Error
          </TextLabel>
          <Link onClick={refetch}>
            <RestartIcon /> Retry
          </Link>
        </div>
      );
    }
  
    return (
      <Loader
        loading={(isFetching && !state.modalVisible) || state.loading}
        tip="Fetching data..."
      >
      <div style={{ display: "flex" }}>
        <img
          width={"20px"}
          height={"19px"}
          src={
            factoryDataValue >= 99
              ? greenLogo
              : factoryDataValue >= 96 && factoryDataValue < 99
              ? yellowLogo
              : redLogo
          }
          className={
            factoryDataValue >= 99 ? "animated pulse infinite" : "heart"
          }
          alt={"TimeLineItem"}
          style={{ marginRight: "13px" }}
        />
        {state.factoryData.length > 0 && (
          <div className="factory-data-tiles" style={{ marginTop: "-6px" }}>
            <div className="tile" style={{ fontSize: "13px" }}>
              <strong>
                Ship Date:-{" "}
                {formatDate(state.factoryData[0].ship_date, "short") || "NA"}
              </strong>
            </div>
            <div className="tile">
              Data Quality Index - <strong>{factoryDataValue}%</strong>
            </div>
            <div className="tile">
              Last Validated -{" "}
              <strong>
                {formatDate(state.factoryData[0].ship_date, "short") || "NA"}
              </strong>
            </div>
            {/* <div className="tile">
              EOD Count -{" "}
              <strong>{state.factoryData[0].factory_eod_count}</strong>
            </div>
            <div className="tile">
              Mongo DB Count -{" "}
              <strong>{state.factoryData[0].mongo_db_count}</strong>
            </div>
            <div className="tile">
              Pg DB Count - <strong>{state.factoryData[0].pg_db_count}</strong>
            </div>
            <div className="tile">
              SFDC Count - <strong>{state.factoryData[0].sfdc_count}</strong>
            </div> */}
            <Link
              size="small"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  modalVisible: true,
                }));
              }}
            >
              Details
            </Link>
          </div>
        )}
        {state.modalVisible && (
          <Modal
            visible={state.modalVisible}
            width="1400px"
            title={
              <div className="factory_title">
                <Title size="h2">View Details</Title>
                <Select
                  autoSize={true}
                  rowsData={viewDropdownData}
                  onSelectedChange={handleOnSelectedChange}
                  selectedRow={state.selectedRow}
                  type="mini"
                />
              </div>
            }
            onClose={handleCloseModal}
            footer={
              <div className="factoryDataFooter">
                <Link
                  onClick={() => {
                    history.push("/factory_data");
                  }}
                >
                  View Complete Details
                </Link>
              </div>
            }
            className="missingAssetModal"
          >
            <Loader loading={isFetching} tip="Fetching data...">
              <FactoryDataModal factoryData={state.factoryData} />
            </Loader>
          </Modal>
        )}
        </div>
      </Loader>
    );
  };
  
  export default FactoryDataTilesDataSync;