import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from "@nutanix-ui/recharts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CartesianGridColor, CustomizedXAxisTick, getDataByPage, graphHeight, labelStyle, RefrenceLineColor, transformAutomationTest } from "../../EngineeringUtils";
import NoData from "./NoData";

const AutomationTest = () => {
  const {
    automationTestData,
    automationCoverageTestPage,
    defaultCodeCoverage,
  } = useSelector((state) => state.enggDashboard);
  const [data, setData] = useState([])

  useEffect(() => {
    if(automationTestData?.length > 0){
      setData(transformAutomationTest(automationTestData))
    }
  }, [automationTestData])

  return (
    <ResponsiveContainer height={graphHeight + 40} width="100%">
      {data?.length > 0 ? (
        <BarChart
          alignment="none"
          data={getDataByPage(
            data,
            automationCoverageTestPage.currentPage,
            automationCoverageTestPage.pageSize
          ).reverse()}
          margin={{
            top: 20,
            left: 10,
            right: 20,
            bottom: 20,
          }}
        >
          <XAxis dataKey="name" tick={<CustomizedXAxisTick />} interval={0} />
          <YAxis
            dataKey="value"
            domain={[1, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />
          <Tooltip
            formatter={(value) => {
              if (typeof value === "number") {
                const valueChanged = value.toFixed(1);
                return [`${valueChanged}%`];
              } else {
                // Handle non-numeric values (e.g., return empty array or default message)
                return [`${value}%`]; // Or return ['Could not format value'] for informative message
              }
            }}
          />
          <ReferenceLine
            y={90}
            stroke={RefrenceLineColor}
            strokeDasharray="6 6"
            // label={{
            //   value: "Threshold (90%)",
            //   position: "insideBottomLeft",
            //   fill: "#627386",
            // }}
          />

          <CartesianGrid stroke={CartesianGridColor} />
          <Bar dataKey="value" barSize={15}>
            {defaultCodeCoverage?.default_cc && (
              <LabelList
                dataKey="value"
                position="top"
                style={labelStyle}
                formatter={(value) => {
                  if (typeof value === "number") {
                    const valueChanged = value.toFixed(1);
                    return [`${valueChanged}%`];
                  } else {
                    // Handle non-numeric values (e.g., return empty array or default message)
                    return [`${value}%`]; // Or return ['Could not format value'] for informative message
                  }
                }}
              />
            )}
          </Bar>

          
        </BarChart>
      ) : (
        <NoData height={240} />
      )}
    </ResponsiveContainer>
  );
};

export default AutomationTest;
