import React, { useRef, useState } from "react";
import { Button, LocalTable, Modal, UploadIcon } from "@nutanix-ui/prism-reactjs";

import TemplateSelect from "./Components/TemplateSelect/TemplateSelect";

import './styles.css';
import FileUploadModal from "./Components/FileUploadModal";


export default function DashboardOptions() {

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [isRowError, setIsRowError] = useState(false);
    const [errorContent, setErrorContent] = useState('');
    const [fileData, setFileData] = useState(null);

    function handleTemplateSelect(template) {
        setSelectedTemplate(template);
    }

    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handlecloseUploadModal = () => {
        setUploadFileModal(prevState => false);
    }

    const handleUploadModalOpen = () => {
        setUploadFileModal(prevState => true);
    }

    const handleChange = event => {
        setFileData(prevState => event.target.files[0])
    };

    const handleClose = () => {
        setIsRowError(false);
    }

    const renderErrors = () => {
        const columns = [
            {
                title: 'Row Number',
                key: 'key'
            },
            {
                title: 'Column Name',
                key: 'column'
            },
            {
                title: 'Error',
                key: 'errorMessage'
            }
        ];

        return <div style={{ padding: '1rem' }}>
            <LocalTable
                columns={columns}
                dataSource={errorContent}
            />
        </div>
    };

    return <div className="dashboardOptionsContainer" data-testid="dashboardOptionsContainer" >
        <TemplateSelect handleTemplateSelect={handleTemplateSelect} />
        <Button className="uploadButton" onClick={handleUploadModalOpen} data-testid="uploadButton" >
            <UploadIcon />
            <p>Upload File</p>
        </Button>
        <FileUploadModal
            uploadFileModal={uploadFileModal}
            file={fileData}
            handlecloseUploadModal={handlecloseUploadModal}
            selectedTemplate={selectedTemplate}
            hiddenFileInput={hiddenFileInput}
            handleClick={handleClick}
            handleChange={handleChange}
            setIsRowError={setIsRowError}
            setErrorContent={setErrorContent}
        />
        <Modal
            visible={isRowError}
            onClose={handleClose}
            primaryButtonOnClick={handleClose}
            title={<h3 style={{ textAlign: 'start' }} >Errors</h3>}>
            {renderErrors()}
        </Modal>
    </div>
}
