import { Popover} from "antd";
const CardInfoTitle = ({ ...otherProps }) => (
    <div className="info-icon">
        <Popover
            placement="right"
            // trigger={"click"}
            content={<PopoverContent {...otherProps} />}
        >
            <div style={{float: 'right', height: 21, width: 21, marginTop: 5, opacity: 1}}>
            <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                fill="rgb(135 141 137)"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="InfoOutlinedIcon"
                
            >
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
            </div>
        </Popover>
    </div>
);

const PopoverContent = ({ message }) => (
    <div className={"popover-cont"}>
        <p style={{fontSize:13}}>
            {message}
        </p>
        {/* <p style={{fontSize:13}}>
            <b>Uptime</b>:
            <br />
           The time during which a computer or IT system is operational.
        </p>
        <p style={{fontSize:13}}>
            <b>Availability</b>:
            <br />
            represents the system performance is as expected compared to the bench mark processes.
        </p> */}
    </div>
);

export default CardInfoTitle