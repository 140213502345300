import { Button, Radio } from "@nutanix-ui/prism-reactjs";
import React from "react";

const RadioButton = ({ name, title, checked, onChange }) => {
  return (
    <Button type={Button.ButtonTypes.BORDERLESS}>
      <Radio
        id={`radio_${name}`}
        name={name}
        value={name}
        checked={checked}
        onChange={onChange}
        data-testid={`radio_${name}`}
      >
        {title}
      </Radio>
      {``}
    </Button>
  );
};

export default RadioButton;
