import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const autoFixFilterSlice = createSlice({
  name: "autoFixFilter",
  initialState: {
    openModal:false,
    modalData: [],
    data: [],
    ReportsData: [],
    FooterDataMap: [],
    filteredData: [],
    dropdownOptions: {
      Project: [],
      Module: [],
      Branch: [],
      Level: [],
      RepositoryUrl: [],
      PRStatus: [],
      
    },
    TotalSuccessVul :0,
    selectedValues: {
      Project: "All",
      Module: "All",
      Branch: "All",
      Level: "All",
      RepositoryUrl: "All",
      PRStatus: "All",
      filter:"month",
    
      viewBy:'Monthly',
      dateRange: { startDate : moment().subtract(1, "month").startOf("day").toDate(),
               endDate : moment().endOf("day").toDate()},
      applyButton:false
    },
    loading: {
      Project: true,
      Module: true,
      Branch: true,
      Level: true,
      RepositoryUrl: true,
      PRStatus: true,
      reportsData:true
    
    },
  },
  reducers: {
    setDropdownData: (state, action) => {
      state.dropdownOptions = {
        ...action.payload,  
      };
      
    },
    setSelectedValue: (state, action) => {
      const { field, value } = action.payload;
     return {  ...state,selectedValues: {
          ...state.selectedValues,
          [field] : value
        }
      }
    
    },
    
    
    setReportsData: (state, action) => {
      return { ...state, ReportsData : action.payload}
    },
    setReportsDataLoading: (state, action) => {
      return {...state,loading:{
        ...state.loading,'reportsData':action.payload
      }}
    },
     
    setOpenModal: (state, action) => {
      return { ...state, openModal: action.payload };
    },
    setModalData: (state, action) => {
      return { ...state, modalData: action.payload };
    },
    
    
    setProjectLoading: (state, action) => {
      state.loading.Project = action.payload;
    },
    setTotalSuccessVul: (state, action) => {
      
      state.TotalSuccessVul = action.payload;
    },
    setFooterDataMap: (state, action) => {
      
      state.FooterDataMap = action.payload;
    },
  },
});

export const { setDropdownData, setSelectedValue, setProjectLoading ,setReportsData,setOpenModal,setModalData,setReportsDataLoading, setTotalSuccessVul,setFooterDataMap} =
  autoFixFilterSlice.actions;

export default autoFixFilterSlice.reducer;
