import { configureStore } from '@reduxjs/toolkit';
import teamReducer from '../features/JiraAnalysis/teamSlice'
import filterReducer from '../features/JiraAnalysis/filterSlice'
import checkboxReducer from '../features/JiraAnalysis/checkBoxSlice'
import dataReducer from '../features/JiraAnalysis/dataSlice'
import trendSlice from '../features/JiraAnalysis/trendSlice';
import enggDashboardSlice from '../features/JiraAnalysis/enggDashboardSlice';
import autoFixFilterSlice from '../features/JiraAnalysis/autoFixSlice'
const store = configureStore({
  reducer: {
    team: teamReducer,
    filter:filterReducer,
    checkbox:checkboxReducer,
    data:dataReducer,
    trend:trendSlice,
    enggDashboard:enggDashboardSlice,
    autoFixReports:autoFixFilterSlice,
    // Add more reducers if needed
  },
  // Add middleware and other configurations here
});

export default store;