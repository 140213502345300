import React from "react";
import "./Maintenance.css";
import { Button, RestartIcon, Title } from "@nutanix-ui/prism-reactjs";
import { useHistory } from "react-router-dom";
import maintenanceIcon from "./Maintenance.svg";
const Maintance = ({ component, date }) => {
    const history = useHistory();

  const handleHomeNavigation = () => {
    history.push("/"); // Adjust the path if your home route is different
  };
  return (
    <div className="maintanceContainer">
      <img
        src={maintenanceIcon}
        alt="Maintenance Icon"
        class="maintenance-icon"
      />
      <h1>We’re Under Maintenance</h1>
      <p>
        {`${component} is currently undergoing maintenance to improve your
        experience. We’ll be back shortly.`}
      </p>
      <p class="resume-date">Expected Resume Date: <strong className="resume-date">{date}</strong></p>
      <Button onClick={handleHomeNavigation} type={ Button.ButtonTypes.NAVIGATION_PRIMARY }
        aria-label="Go back to previous page"
      >
        <RestartIcon /> Home Page
      </Button>
    </div>
  );
};

export default Maintance;
