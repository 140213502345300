import React from "react";
import RadioButton from "./RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { toggleCustomerDefault } from "../../../../features/JiraAnalysis/enggDashboardSlice";

const CustomerDefectRadioButton = () => {
  const { defaultCustomerDefect } = useSelector((state) => state.enggDashboard);
  const dispatch = useDispatch();
  return (
    <>
      <RadioButton
        name="default_defect"
        title="Default"
        checked={defaultCustomerDefect.default_defect}
        onChange={(e) => dispatch(toggleCustomerDefault(e))}
      />
      <RadioButton
        name="hide_default_counts"
        title="Hide Defects Counts"
        checked={defaultCustomerDefect.hide_default_counts}
        onChange={(e) => dispatch(toggleCustomerDefault(e))}
      />
    </>
  );
};

export default CustomerDefectRadioButton;
