import React, { useState } from 'react';
import { Button, Popconfirm, Table, Modal, Input } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'

const TeamTable = (props) => {
  const [deleteModalEnable, setDeleteModalEnable] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")
  const { users, setUsers, setSaveButton } = { ...props }
  const handleModalCancel = () => {
    setDeleteModalEnable(false)
  }
  const PopoverContent = (props) => {
    return (
      <div style={{ marginLeft: "38px" }}>
        <p style={{ margin: 0, color: "grey" }}>Are you sure you want to delete this user?</p>
        <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: "8px", marginLeft: '58px' }}>
          <Button data-testid = 'paas-createProject-addMember-removeUserPopUp-cancel-btn' size='small' onClick={handleModalCancel}>Cancel</Button>
          <Button type='danger' data-testid = 'paas-createProject-addMember-removeUserPopUp-delete-btn'  size='small' onClick={handleModalCancel}>Delete</Button>
        </div>
      </div>
    )
  }

  const handleDelete = (key) => {
    setSaveButton(true)
    const newData = users.filter((item) => item.key !== key);
    setUsers(newData)

  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => {
        a = String(a.name) || "";
        b = String(b.name) || "";

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
      render: (value) => {
        const name = value.split('@')[0].split('.').map(e => {
          return e.charAt(0).toUpperCase() + e.slice(1);
        }).join(' ')
        return name
      },
      filteredValue: [selectedValue],
      onFilter: (value, record) => {
        return record.name.includes(value)
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => {
        a = String(a.name) || "";
        b = String(b.name) || "";

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
      filteredValue: [selectedValue],
      onFilter: (value, record) => {
        return record.name.includes(value)
      }

    },
    {
      title: 'Access',
      dataIndex: 'permission',
      sorter: (a, b) => {
        a = String(a.permission) || "";
        b = String(b.permission) || "";

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Remove',
      dataIndex: 'remove',
      render: (_, record) =>
        users.length >= 1 ? (
          <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => handleDelete(record.key)}>
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        ) : null,


    },
  ];

  return (
    <>
      <Input width={"750px"} data-testid = 'paas-createProject-addMember-search-input' placeholder={"Type email address to filter"} onChange={(e) => setSelectedValue(e.target.value)} prefix={<SearchOutlined style={{ color: "grey" }} />} /><br />
      <Table columns={columns} dataSource={users} />
      {deleteModalEnable && (
        <Modal
          mask={false}
          centered={true}
          open={deleteModalEnable}
          footer={false}
          width={"25vw"}
          style={{ maxWidth: "800px" }}
          onCancel={handleModalCancel}
        >
          <PopoverContent />
        </Modal>
      )}
    </>
  )
}
export default TeamTable;