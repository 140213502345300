import React from "react";
import Reset from "./Components/Reset";
import FilterRadioButton from "./Components/FilterRadioButton";
const HeaderContainer = () => {
  return (
    <>
      <div className="headerContainer">
        <span className="left">Filters</span>
        <span className="right">
          <Reset />
          <FilterRadioButton />
        </span>
      </div>
    </>
  );
};

export default HeaderContainer;
