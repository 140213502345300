import React, { useEffect, useState } from "react";
import "./JiraAnalysis.css";
import "./UXComponent/styles.css";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { filterDataByDropdown } from "./utils";
import axios from "axios";
import { SERVER_URL } from "../../config/config.js";
import DashboardFilter from "./DashboardFilter/DashboardFilter.jsx";
import { Loader } from "@nutanix-ui/prism-reactjs";
import DashboardStatus from "./DashboardStatus/DashboardStatus.jsx";
import DashboardCalendar from "./DashboardCalendar/DashboardCalendar.jsx";
import { setProductivity } from "../../features/JiraAnalysis/trendSlice.js";
import { useDashboardData } from "./apiUtils.js";
import { useHistory } from "react-router-dom";
import Logger from "../../config/logger.js";
import { Alert } from "@nutanix-ui/prism-reactjs";
import UXContainer from "./UXComponent/UXContainer/UXContainer.jsx";
import RMEContainer from "./RMEComponent/RMEContainer/RMEContainer.jsx";

const JiraAnalysis = () => {
  Logger.info(
    `Jira analysis page started loading`,
    "jira_analysis_client",
    window.location.href
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [summary, setSummary] = useState([]);
  const [eta, setEta] = useState([]);

  // global states
  const {
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels,
  } = useSelector((state) => state.filter);

  const { currentTeamSelection, currentTeamViewBySelection } = useSelector(
    (state) => state.team
  );

  const {
    data: jira_data,
    isLoading: dashboardLoading,
    isError,
    error,
  } = useDashboardData(currentTeamSelection?.project_id, selectedSprint?.[0]);

  if (isError) {
    throw error; // This will be caught by the ErrorBoundary
  }
  // console.log(jira_data, 'jira_data');

  useEffect(() => {
    if (jira_data && !dashboardLoading) {
      setSummary(
        jira_data?.data.summary === null ? [] : jira_data?.data.summary
      );
      setEta(jira_data?.data.eta === null ? [] : jira_data?.data.eta);
    }
  }, [dashboardLoading, dispatch, jira_data]);

  const fetchProductivity = async () => {
    if (selectedSprint[0] !== "All" && currentTeamSelection?.project_id) {
      try {
        const payload = {
          sprint: null,
          project_id: currentTeamSelection.project_id,
          component: selectedComponent[0] === "All" ? null : selectedComponent,
          issue_type: selectedIssueType[0] === "All" ? null : selectedIssueType,
          assignee_name:
            selectedAssignee[0] === "All" ? null : selectedAssignee,
          labels: selectedLabels[0] === "All" ? null : selectedLabels,
        };
        const trendsPayload = encodeURIComponent(JSON.stringify(payload));
        const PRODUCTIVITYURL = `${SERVER_URL}/productivity-metrics?payload=${trendsPayload}`;
        const [productivity] = await Promise.all([axios.get(PRODUCTIVITYURL)]);
        dispatch(
          setProductivity(
            productivity.data.data.productivity_metrics !== null
              ? productivity.data.data.productivity_metrics
              : []
          )
        );
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (
      currentTeamSelection?.project_id &&
      ![26330, 100000].includes(currentTeamSelection.project_id)
    ) {
      fetchProductivity();
    }
  }, [
    currentTeamSelection,
    selectedComponent,
    selectedIssueType,
    selectedAssignee,
    selectedLabels,
  ])

  const filteredSummaryByDropdown = filterDataByDropdown(
    summary,
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels
  );

  const filteredEtaByDropdown = filterDataByDropdown(
    eta,
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels
  );

  const handleRefresh = () => {
    history.go(0);
  };


  return (
    <>
      <DashboardHeader onRefresh={handleRefresh} />
      {currentTeamSelection?.project_key_desc === "No Team Assigned" && (
        <Alert
          type={Alert.AlertTypes.INFO}
          data-test-id="type-info"
          aria-live="off"
          message="You haven't been assigned to any teams. Please contact Data Science Team."
        />
      )}
      <div className="main-container">
        {currentTeamViewBySelection && currentTeamViewBySelection.link ? (
          <tableau-viz
            id="tableauViz"
            className="jira-ssot-table"
            src={currentTeamViewBySelection.link}
            device="desktop"
            toolbar="bottom"
            hide-tabs
            style={{ width: "100%", height: "100%", overflow: "auto" }}
          ></tableau-viz>
        ) : (
          <>
            <div
              className="section-container"
              data-testid="dashboard-container"
            >
              {currentTeamSelection.project_id === 100000 && <UXContainer />}
              {currentTeamSelection.project_id === 26330 && <RMEContainer />}
              {currentTeamSelection.project_id !== 26330 &&
              currentTeamSelection.project_id !== 100000 && (
                <>
                  <div
                    className="section-container"
                    data-testid="dashboard-filter"
                  >
                    <DashboardFilter filterSummary={summary} />
                  </div>
                  <div
                    className="section-container"
                    data-testid="dashboard-status"
                  >
                    <Loader
                      loading={dashboardLoading}
                      tip="Loading..."
                      data-test-id="wrap-around-container"
                    >
                      <DashboardStatus
                        filterSummary={filteredSummaryByDropdown}
                      />
                    </Loader>
                  </div>
                  <div
                    className="section-container"
                    data-testid="dashboard-status"
                  >
                    <Loader
                      loading={dashboardLoading}
                      tip="Loading..."
                      data-test-id="wrap-around-container"
                    >
                      <DashboardCalendar filteredEta={filteredEtaByDropdown} />
                    </Loader>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default JiraAnalysis;
