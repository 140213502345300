import React, { useEffect, useState, useContext } from "react";
import {
    FlexItem,
    Table,
    Sorter,
    FlexLayout, FormItemInput, Button, RemoveIcon, Checkbox
} from "@nutanix-ui/prism-reactjs";

import { TemplateFormContext } from "../../../FileTemplateUploadDashboard";

export default function UserDetailsForm() {

    const [formData, setFormData] = useContext(TemplateFormContext);

    const [user, setUser] = useState('');

    const [data, setData] = useState(formData?.users || []);

    const [loading, setLoading] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    const [isError, setError] = useState(false);
    const columns = [{
        title: 'Email Address',
        key: 'email'
    },{
        title: 'Admin',
        key: 'admin',
        render: (value, object, index ) => {
            return (
                <Checkbox checked={object.isAdmin} />
            )
        }
    },
    {
        title: 'Remove',
        key: 'remove',
        render: (value, object, index) => {
            return (
                <div className="userTableAction" data-testid="removeUserButton" onClick={() => handleRemove(index)}><RemoveIcon /></div>
            );
        }
    }];


    useEffect(() => {
        setFormData({ ...formData, users: data });
    }, [data]);

    const containsNutanixEmail = (text) => /@nutanix\.com\b/.test(text);

    const handleUserAdd = (e) => {
        const text = e.target.value.trim();
        setUser(e.target.value.trim());
        if(containsNutanixEmail(text)) {
            setError(prevState => false);
        } else {
            setError(prevState => true);
        }
        
    }

    const handleRemove = (index) => {
        setData(data.filter((_, i) => index !== i))
    }

    const addUser = () => {
        setData(prevState => [...prevState, {
            key: Math.random().toString(36).slice(2, 7),
            email: user,
            isAdmin: isAdmin
        }]);
        setUser('');
        setIsAdmin(false);
    }

    const handleIsAdmin = (e) => {
        setIsAdmin(prevState => !prevState);
    }

    const topSection = {
        title: <div className="tableHeader">
            <h3>Users : {data.length}</h3>
        </div>,
    };

    const sort = {
        order: Sorter.SORT_ORDER_CONST.ASCEND,
        column: 'age',
        sortable: ['userID', 'email']
    }



    return <div className="userDetailsContainer">
        <FlexLayout flexDirection="column" justifyContent="center">
            <FlexItem><h3>Share with</h3></FlexItem>
            <FlexLayout flexDirection="row" >
                <FlexItem style={{ width: '100%' }}><FormItemInput className="textField" placeholder="Start typing to add people" width='100%' value={user} onChange={handleUserAdd}
                    wrapperProps={{ 'data-test-id': 'default' }} data-testid="emailInput" error={isError} /></FlexItem>
                <FlexItem>
                    <Checkbox
                    className="textField"
                    checked={isAdmin}
                    label="Admin"
                    onChange={handleIsAdmin}
                /></FlexItem>
                <FlexItem><Button onClick={addUser} data-testid="addUserButton" disabled={isError} >Add</Button></FlexItem>
            </FlexLayout>
            <FlexItem>
                <Table
                    showCustomScrollbar={true}
                    dataSource={data}
                    className="historyTable"
                    columns={columns}
                    topSection={topSection}
                    loading={loading}
                    sort={sort}
                    wrapperProps={{
                        'data-test-id': 'customized-options-table'
                    }}
                />
            </FlexItem>
        </FlexLayout>
    </div>
}