import React, { useState } from "react";

import './styles.css';

import DashboardHeader from "./Component/DashboardHeader/DashboardHeader";
import TemplateTable from "./Component/TemplateTable/TemplateTable";
import { RemoveIcon } from "@nutanix-ui/prism-reactjs";

export const TemplateDataContext = React.createContext();
export const TemplateFormContext = React.createContext();

export default function FileTemplateUploadDashboard() {
    const [templateData, setTemplateData] = useState([{
        key: '1',
        templateID: 'John Brown',
        templateName: 32,
        desc: 'This is Dummy Description',
        time: 'Oct 02 2023, 8:03 AM',
        user: 'Nitesh.Tiwari',
        action: <RemoveIcon />
    }]);

    const [visible, setVisible] = useState(false);

    const [formData, setFormData] = useState({
        aggregator: '',
        columnsData: [],
        users: [],
        desc: '',
        templateName: '',
        validation: ''
    });

    return <TemplateDataContext.Provider value={[templateData, setTemplateData]}>
        <TemplateFormContext.Provider value={[formData, setFormData]}>
            <div className='fileTemplateUploadDashboardContainer' data-testid="templateUploadDashboardContainer">
                <DashboardHeader visible={visible} setVisible={setVisible} />
                <TemplateTable data={templateData} visible={visible} />
            </div>
        </TemplateFormContext.Provider>
    </TemplateDataContext.Provider>
}