import Home from "../Components/Home";
import DataSync from "../Components/DataSync";
import Datalineage from "../Components/Datalineage2";
import ErrorPage from "../Components/ErrorPage";
import DataAudit from "../Components/DataAudit";
import Dashboard from "../Components/Dashboard";
import About from "../Components/About";
import TableauDashboardMain from "../Components/TableauDashboard/TableauDashboardMain";
import ProjectTracker from "../Components/ProjectTracker";
import PnP2 from "../Components/PnP2.0";
import FulfillmentSlide from "../Components/FulfillmentSlide";
import JiraAnalysis from "../Components/JiraAnalysis/JiraAnalysis";
import FileTemplateUploadDashboard from "../Components/FileTemplateUploadUtility/FileTemplateUploadDashboard";
import FileUploadUtilityDashboard from "../Components/FileUploadUtility/FileUploadUtilityDashboard";
import PaaS from "../Components/PaaS/PaaS";
import NextGenETL from "../Components/NextGenETL";
import ETLPipelineProcessList from "../Components/ETLCanvas/ETLPipelineProcessList";
// import EtlCanvasDashboard from "../Components/ETLCanvas";
import ETLPipelineListing from '../Components/ETLCanvas/ETLPipelineListing';
import LEO from "../Components/LEO/LEO";
import UnAuthorisedPage from "../Components/UnAuthorisedPage";
import EngineeringDashboard from "../Components/EngineeringDashboard/EngineeringDashboard"
import FactoryData from "../Components/FactoryData/FactoryData"
import Maintenance from "../Components/Maintenance/Maintenance";

const fixedRoutes = [
    {
        id: "tableau",
        path: "/",
        component: () => <TableauDashboardMain />,
    },

    {
        id: "home",
        path: "/platform_health",
        component: () => <Home />,
    },
    {
        id: "datasync",
        path: "/datasync",
        component: () => <DataSync />,
    },
    {
        id: "datalineage",
        path: "/datalineage",
        component: (props) => <Datalineage {...props} />,
    },
    {
        id: "dashboard",
        path: "/dashboard",
        component: () => <Dashboard />,
    },
    {
        id: "aboutus",
        path: "/feedback",
        component: () => <About />,
    },
    // {
    //     id: "dataaudit",
    //     path: "/dataaudit",
    //     component: () => <Maintenance component="Data Audit" date="October 30, 2024, by 5:00 PM (IST)" />,
    // },
    {
        id: "dataaudit",
        path: "/dataaudit",
        component: () => <DataAudit />,
    },
    {
        id: "tracker",
        path: "/tracker",
        component: () => <ProjectTracker />,
    },
    {
        id: "PnP 2.0",
        path: "/PnP 2.0",
        component: () => <PnP2 />,
    },
    {
        id: "fulfillment_slide",
        path: "/fulfillment_slide",
        component: () => <FulfillmentSlide />,
    },
    {
        id: "jira-analysis",
        path: "/jira-analysis",
        component: () => <JiraAnalysis />
    },
    {
        id: "kpi_engineering",
        path: "/kpi_engineering",
        component: () => <EngineeringDashboard />
    },
    {
        id: "leo",
        path: "/leo",
        component: () => <LEO />
    },
    {
        id: "factory_data",
        path: "/factory_data",
        component: () => <FactoryData />
    },
    {
        id: "error",
        path: "/error",
        component: () => <ErrorPage />,
    },
    {
        id: "file_template",
        path: "/file_template",
        component: () => <FileTemplateUploadDashboard />
    },
    {
        id: "file_upload",
        path: "/file_upload",
        component: () => <FileUploadUtilityDashboard />
    },
    {
        id: "NextGen ETL",
        path: "/NextGenETL",
        component: () => <NextGenETL />,
    },
    // {
    //     id: "ETL Canvas",
    //     path: "/EtlCanvas",
    //     component: () => <EtlCanvasDashboard />,
    // },
    {
        id: "Column Mapping",
        path: "/ETL_listing",
        component: () => <ETLPipelineListing />,
    },
    {
        id: "Pipeline Process List",
        path: "/PipeLine_Processes_List/:ProjectID/:PipeLineName",
        component: () => <ETLPipelineProcessList />,
    },
    {
        id: "PaaS",
        path: "/paas",
        component: () => <PaaS />,
    },
    {
        id: "unauthorised",
        path: "/unauthorised",
        component: () => <UnAuthorisedPage />,
    },
];

export const routes = fixedRoutes;
