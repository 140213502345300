// filterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSprint: ["All"],
  selectedComponent: ["All"],
  selectedIssueType: ["All"],
  selectedAssignee: ["All"],
  selectedLabels: ["All"],
  sprintStartDate:null,
  sprintEndDate:null
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSprintStartDate: (state, action) => {
      state.sprintStartDate = action.payload;
    },
    setSprintEndDate: (state, action) => {
      state.sprintEndDate = action.payload;
    },
    setSelectedSprint: (state, action) => {
      state.selectedSprint = action.payload;
    },
    setSelectedComponent: (state, action) => {
      state.selectedComponent = action.payload;
    },
    setSelectedIssueType: (state, action) => {
      state.selectedIssueType = action.payload;
    },
    setSelectedAssignee: (state, action) => {
      state.selectedAssignee = action.payload;
    },
    setSelectedLabels: (state, action) => {
      state.selectedLabels = action.payload;
    },
  },
});

export const {
  setSelectedSprint,
  setSelectedComponent,
  setSelectedIssueType,
  setSelectedAssignee,
  setSelectedLabels,
  setSprintStartDate,
  setSprintEndDate
} = filterSlice.actions;

export default filterSlice.reducer;
