import React, { useEffect, useState } from "react";

import { ChevronRightIcon, FlexItem, FlexLayout } from "@nutanix-ui/prism-reactjs";

import './styles.css';
import { getFileUploadUtilityUserRole } from "../../Service/fileUploadUtility";

export default function DashboardHeader() {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        getFileUploadUtilityUserRole()
            .then(resp => {
                if (resp.role === 'UNAUTH') {
                    window.location.replace('/unauthorised')
                }
                if (resp.role === 'ADMIN') {
                    setIsAdmin(true);
                }
            })
            .catch(err => {
                window.location.replace('/error')
            })
    }, [])

    return <div className="dashboardHeader" data-testid="dashboardHeader">
        <div className="dashboardHeaderContent" data-testid="dashboardHeaderContent">
            <h2 >File Upload Dashboard</h2>
            <div className="fileUploadHeaderAdoption">
                {isAdmin && <a className="templateDashboardLink" href="/file_template" data-testid="templateDashboardLink">Template <ChevronRightIcon /></a>}
            </div>

        </div>
    </div>
}