import React, { Component } from "react";
import axios from "axios";
import "./datalineage2.css";
import "ant-design-draggable-modal/dist/index.css";
import moment from "moment";
import FloatingButton from "./FloatingButton";
import Logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";
import LineageModal from "./LineageModal";

let viz;
class Datalineage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineageData: [],
            lineageGraphData: [],
            flowData: [],
            modalVisible: false,
        };
        this.reportID = this.props?.id;
    }
    isFav = this.props?.isFav;
    tableauUrl = this.props?.url;
    reportName = this.props?.name || "Systems Progression Report";

    async componentDidMount() {
        try {
            // this.initViz(this.tableauUrl);
            let reportname = "Systems Progression Report";
            Logger.info(
                `Fetching Datalineage Reports with parameter reportID : ${this.reportID}`,
                "data_lineage_client", window.location.href
            );
            const {
                data: { lineageData, lineageGraphData, flowData },
            } = await axios.post(
                `${SERVER_URL}/datalineage?reportID=${this.reportID}`
            );
            Logger.info(`Fetched Datalineage Reports successfully`, "data_lineage_client", window.location.href);

            this.setState({ lineageData, lineageGraphData, flowData });
        } catch (e) {
            Logger.error(e, "data_lineage_client", window.location.href);
        }
    }

    // initViz(tableauUrl) {
    //     const url = "";
    //     if (viz) {
    //         // If a viz object exists, delete it.
    //         viz.dispose();
    //     }
    //     const vizUrl = tableauUrl;

    //     const vizContainer = this.vizContainer;
    //     viz = new window.tableau.Viz(vizContainer, vizUrl);
    // }
    getTableColumns = () => [
        { title: "S.No.", dataIndex: "key", key: "key" },
        { title: "Schema name", dataIndex: "schema_name", key: "schema_name" },
        { title: "Table name", dataIndex: "table_name", key: "table_name" },
        {
            title: "Last Refresh",
            dataIndex: "last_refresh_ts",
            key: "last_refresh_ts",
            render: (value) => {
                if (!value) {
                    return null;
                }
                return (
                    <div>
                        {moment(value?.split("+")[0]).format(
                            "YYYY MMM DD hh:mm A"
                        )}
                    </div>
                );
            },
        },
    ];
    handleModalVisible = (e) => {
        this.setState({
            modalVisible: false,
        });

        this.props.setModalStatus((e) => {
            const newState = { ...e };
            newState[this.props.id].open = false;
            return newState;
        });
    };
    showModal = () => {
        this.props.setModalStatus((e) => {
            const newState = { ...e };
            newState[this.props.id].open = true;
            return newState;
        });

        this.setState({
            modalVisible: true,
        });
    };

    render() {
        const { lineageData, lineageGraphData, flowData } = this.state;
        const { id, modalStatus } = this.props;
        return (
            <div className="datalineage2">
                <tableau-viz
                    id="tableauViz"
                    src={this.tableauUrl}
                    device="desktop"
                    toolbar="bottom"
                    // hide-tabs
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                ></tableau-viz>
                {/* <div id={this.reportID}
                    ref={(div) => {
                        this.vizContainer = div;
                    }}
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                ></div> */}
                <div>
                    <FloatingButton
                        onDataLineageClick={this.showModal}
                        disabled={lineageData.length === 0}
                        isFav={this.isFav}
                    />
                    <LineageModal
                        title={
                            "Data Lineage - " + lineageData[0]?.workbook_name
                        }
                        visible={modalStatus?.[id]?.["open"]}
                        handleModalVisible={this.handleModalVisible}
                        lineageData={lineageData}
                        lineageGraphData={lineageGraphData}
                        flowData={flowData}
                    />
                </div>
            </div>
        );
    }
}

export default Datalineage;
