import { Modal, Table } from "@nutanix-ui/prism-reactjs";
import React from "react";
import { createDataSourceAssetModal, formatDate } from "./utility";

const AssetModal = ({
  id = 'missedModal',
  modalVisible,
  setState,
  loading,
  loadingError,
  assets,
  className,
}) => {
  const handleCloseModal = (id) => {
    if(id === 'missedModal'){
      setState((prevState) => ({ 
        ...prevState, 
        missedModalVisible: false,
        modalVisible: false 
      }));
    }else{
      setState((prevState) => ({ ...prevState, matchedModalVisible: false }));
    }
  };

  const missingAssetColumns = [
    {
      title: <span className="bold-header">Date Shipped</span>,
      key: "date_shipped",
      render: (value) => {
        return formatDate(value);
      },
    },
    { title: <span className="bold-header">Asset</span>, key: "asset" },
    {
      title: <span className="bold-header">Factory EOD</span>,
      key: "factory_eod",
    },
    {
      title: <span className="bold-header">Mongo DB</span>,
      key: "mongo_db",
    },
    {
      title: <span className="bold-header">Postgres DB</span>,
      key: "postgres_db",
    },
  ];

  const matchedAssetColumn = [
    {
      title: <span className="bold-header">Date Shipped</span>,
      key: "sfdc_ship_date",
      render: (value) => {
        return formatDate(value);
      },
    },
    {
      title: <span className="bold-header">Factory Serial Number</span>, 
      key: 'factory_serialnumber'
    }
  ]

  return (
    <Modal
      visible={modalVisible}
      width="900px"
      title={id === 'missedModal' ? "Missing Assets": "Matched Assets"}
      onClose={() => handleCloseModal(id)}
      footer={null}
      className={`missingAssetModal ${className ? className : ""}`}
    >
      <div className="modalContainer">
        <Table
          columns={id === 'missedModal' ? missingAssetColumns: matchedAssetColumn}
          dataSource={createDataSourceAssetModal(assets)}
          loading={loading}
          loadingError={loadingError}
        />
      </div>
    </Modal>
  );
};

export default AssetModal;
