import React, { useEffect, useState } from "react";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getUniqueValuesByArray,
  updateArrayWithAllOption,
  getUniqueValuesByKey,
  extractAndDispatchDates,
  generateSelectMenu,
} from "../utils";

import {
  setSelectedAssignee,
  setSelectedComponent,
  setSelectedIssueType,
  setSelectedLabels,
  setSelectedSprint,
  setSprintEndDate,
  setSprintStartDate,
} from "../../../features/JiraAnalysis/filterSlice";

const DashboardFilter = ({ filterSummary }) => {
  const [sprintMenus, setSprintMenus] = useState([])
  const dispatch = useDispatch();
  const { currentTeamSelection } = useSelector((state) => state.team);
  const { loaders } = useSelector((state) => state.data);
  const {
    selectedSprint,
    selectedComponent,
    selectedIssueType,
    selectedAssignee,
    selectedLabels,
  } = useSelector((state) => state.filter);

  // First useEffect: Handles finding the current sprint and setting sprint dates
  useEffect(() => {
    if (filterSummary?.length > 0) {
      // Extract sprint start and end dates and dispatch them
      extractAndDispatchDates(
        filterSummary,
        'dt_sprint_start',
        'dt_sprint_end',
        setSprintStartDate,
        setSprintEndDate,
        dispatch
      );
    }
  }, [filterSummary, currentTeamSelection]);

  // Second useEffect: Handles resetting filter options when the selected sprint changes
  useEffect(()=> {
    // Reset filter options to "All" when the selected sprint changes
    dispatch(setSelectedAssignee(["All"]));
    dispatch(setSelectedComponent(["All"]));
    dispatch(setSelectedIssueType(["All"]));
    dispatch(setSelectedLabels(["All"]));

    // Extract sprint start and end dates again when the selected sprint changes
    extractAndDispatchDates(
      filterSummary,
      'dt_sprint_start',
      'dt_sprint_end',
      setSprintStartDate,
      setSprintEndDate,
      dispatch
    );
  }, [selectedSprint]);

  useEffect(() => {
    (async () => {
      if (!currentTeamSelection || !currentTeamSelection.sprint) return;

      const sprintMenu = currentTeamSelection.sprint
        .filter(item => item?.sprint_name)
        .map(sprint => ({
          label: sprint.sprint_name,
          value: sprint.sprint_name,
          currentSprint: sprint.current_sprint || false,
        }));

      const currentSprint = sprintMenu.find(item => item.currentSprint) || sprintMenu[0];

      dispatch(setSelectedSprint([currentSprint?.label || "No Sprint Assigned"]));

      setSprintMenus(
        sprintMenu.length > 0
          ? sprintMenu
          : [{ label: "No Sprint Assigned", value: null, currentSprint: true }]
      );
    })();
  }, [currentTeamSelection]);
  
  // Define the dropdown items for different filter categories
  const dropdownItems = [
    // Sprint dropdown
    {
      id: "sprint",
      mode: "single",
      label: "Sprint",
      items: sprintMenus,
      loading: false,
      selectedItem: selectedSprint,
      onChangeItem: (item) => dispatch(setSelectedSprint([item])),
    },
    // Component dropdown
    {
      id: "component",
      mode: "multiple",
      label: "Component",
      loading: loaders?.dashboardLoading,
      items: generateSelectMenu(
        getUniqueValuesByArray(filterSummary, {
          name: "component",
          nullLabel: "Components",
        }),
        'component'
      ),
      selectedItem: selectedComponent,
      onChangeItem: (item) =>
        dispatch(setSelectedComponent(updateArrayWithAllOption(item))),
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setSelectedComponent(["All"]));
        }
      },
    },
    // Issue Type dropdown
    {
      id: "issue_type",
      mode: "multiple",
      label: "Issue Type",
      loading: loaders?.dashboardLoading,
      items: generateSelectMenu(
        getUniqueValuesByKey(filterSummary, {
          name: "issue_type",
          nullLabel: "Issue Type",
        }),
        'issue_type'
      ),
      selectedItem: selectedIssueType,
      onChangeItem: (item) =>
        dispatch(setSelectedIssueType(updateArrayWithAllOption(item))),
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setSelectedIssueType(["All"]));
        }
      },
    },
    // Assignee dropdown
    {
      id: "assignee_name",
      mode: "multiple",
      label: "Assignee",
      loading: loaders?.dashboardLoading,
      items: generateSelectMenu(
        getUniqueValuesByKey(filterSummary, {
          name: "assignee_name",
          nullLabel: "Assignee",
        }),
        'assignee_name'
      ),
      selectedItem: selectedAssignee,
      onChangeItem: (item) =>
        dispatch(setSelectedAssignee(updateArrayWithAllOption(item))),
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setSelectedAssignee(["All"]));
        }
      },
    },
    // Label dropdown
    {
      id: "label",
      mode: "multiple",
      label: "Label",
      loading: loaders?.dashboardLoading,
      items: generateSelectMenu(
        getUniqueValuesByArray(
          filterSummary,
          { name: "labels", nullLabel: "Labels" }
        ),
        'label'
      ),
      selectedItem: selectedLabels,
      onChangeItem: (item) =>
        dispatch(setSelectedLabels(updateArrayWithAllOption(item))),
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setSelectedLabels(["All"]));
        }
      },
    },
  ];

  return (
    <>
      <div className="filterDropdownContainer" data-testid="jira-ssot-filter-container">
        {dropdownItems.map((dropdown) => (
          <FilterDropdown key={dropdown.id} {...dropdown} />
        ))}
      </div>
    </>
  );
};

export default DashboardFilter;
