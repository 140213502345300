import React from "react";
import { Typography, Pagination } from "antd";
import {
  setSelectedCalenderView,
  setSelectedCalenderViewBy,
} from "../../../../features/JiraAnalysis/checkBoxSlice";
import { useDispatch, useSelector } from "react-redux";
import { headerItems, headerItemsViewBy } from "../../utils";
import SelectDropdown from "../../SelectDropdown/SelectDropdown";

const CalendarHeader = ({ total, currentPage, setCurrentPage }) => {
  const dispatch = useDispatch();
  const { currentTeamSelection } = useSelector((state) => state.team);
  const { selectedCalenderView, selectedCalenderViewBy } = useSelector((state) => state.checkbox);
  const calenderViewByProps = {
    inputProps: {
      name:'calender-view-by',
      'data-testid':'calender-view-by'
    },
    selectProps: {
      rowsData: headerItemsViewBy,
      onSelectedChange: (selectedRow) =>
        dispatch(setSelectedCalenderViewBy(selectedRow)),
      autoSize: true,
    },
    selectedRow: selectedCalenderViewBy,
  };
  const calenderViewProps = {
    inputProps: {
      name:'calender-view',
      'data-testid':'calender-view'
    },
    selectProps: {
      rowsData: headerItems,
      onSelectedChange: (selectedRow) =>
        dispatch(setSelectedCalenderView(selectedRow)),
      autoSize: true,
    },
    selectedRow: selectedCalenderView,
  };
  return (
    <div className="table-title-container flex" data-testid="calendar-header">
      <div className="left-title-content">
        <Typography.Text className="headerTitle">
          Overview of ETA
        </Typography.Text>
      </div>
      <div
        className="right-title-content flex align-content-center"
        style={{ gap: "0px" }}
      >
          {currentTeamSelection && currentTeamSelection.project_id === 12530 && (
            <SelectDropdown {...calenderViewByProps}  />
          )}
          <SelectDropdown {...calenderViewProps}  />
        {selectedCalenderView?.label === "Days" && (
          <Pagination
            simple
            current={currentPage}
            total={total}
            pageSize={7}
            onChange={(page) => setCurrentPage(page)}
            className="calendar-pagination"
          />
        )}
      </div>
    </div>
  );
};

export default CalendarHeader;
