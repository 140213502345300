import React from "react";
import { Row, Col, Tabs, Spin, notification, Popover } from "antd";
import "antd/dist/antd.css";
import ReportSearch from "./ReportSearch";
import "./TableauDashboard.css";
import TableauAllReports from "./TableauAllReports";
import TableauFavouriteReports from "./TableauFavouriteReports";
import Logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";
import axios from "axios";

export class TableauDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favourites: [],
            favouritesCopy: [],
            allReports: [],
            allReportsCopy: [],
            dataLineage: [],
            searchValue: "",
            activeKey: "1",
            dataFetched: false,
            loading: false,
        };
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            Logger.info(`Fetching Tableau Dashboard data`, "tableau_dashboard_client", window.location.href);
            const { data } = await axios.post(`${SERVER_URL}/tableaudashboard`);

            Logger.info(`Fetched Tableau Dashboard data successfully`,  "tableau_dashboard_client", window.location.href);
            const tableauData = data
                .map((ele) => ({
                    ...ele,
                    reportName: ele.dashboard_name,
                    projectName: ele.project_name,
                    lastSuccessfulRun: ele.last_successful_run,
                    reportUrl: `${ele.url}?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n&:device=desktop`,
                }))
                .sort((a, b) => a.id - b.id);

            let favourites = [];
            favourites = tableauData.filter((ele) => ele.fav === 1);

            this.setState({
                favourites,
                favouritesCopy: favourites,
                allReports: tableauData,
                allReportsCopy: tableauData,
                dataFetched: true,
                loading: false,
            });
        } catch (e) {
            this.setState({ loading: false });
            Logger.error(e,  "tableau_dashboard_client", window.location.href);
        }
    }

    handleFavChange = (id, isFav) => {
        const {
            favouritesCopy,
            allReportsCopy,
            searchValue,
            favourites,
            allReports,
        } = this.state;
        let newFavList = [];
        let newAllReports = allReportsCopy.sort((a, b) => a.id - b.id);
        if (isFav) {
            newFavList = favouritesCopy.filter((e) => e.id !== id);
            newAllReports = newAllReports.map((ele) =>
                ele.id === id ? { ...ele, fav: 0 } : ele
            );
        } else {
            newAllReports = newAllReports.map((ele) =>
                ele.id === id ? { ...ele, fav: 1 } : ele
            );
            const favReports = newAllReports.find((e) => e.id === id);
            newFavList = [...favouritesCopy, favReports];
            newFavList = newFavList.sort((a, b) => a.id - b.id);
        }
        const newAllReportsCopy = newAllReports;
        if (searchValue) {
            const currentValue = searchValue;
            const favouriteSearchResult = newFavList.filter((e) =>
                e.reportName.toLowerCase().includes(currentValue.toLowerCase())
            );
            newAllReports = newAllReports
                .filter((e) =>
                    e.reportName
                        .toLowerCase()
                        .includes(currentValue.toLowerCase())
                )
                .map((ele) =>
                    ele.id === id ? { ...ele, fav: isFav ? 0 : 1 } : ele
                );
            this.setState({
                favouritesCopy: newFavList,
                favourites: favouriteSearchResult,
                allReports: newAllReports,
                allReportsCopy: newAllReportsCopy,
            });
        } else {
            this.setState({
                favourites: newFavList,
                favouritesCopy: newFavList,
                allReports: newAllReports,
                allReportsCopy: newAllReportsCopy,
            });
        }
        this.updateUserFavReports(id, isFav).catch((error) => {
            Logger.info("error occured while updating user's favourites",  "tableau_dashboard_client", window.location.href);
            this.setState({
                favourites,
                favouritesCopy,
                allReports,
                allReportsCopy,
            });
            notification.error({
                message:
                    "Sorry, Error occured, please refresh your page and try again",
                placement: "top",
            });
            Logger.error(error,  "tableau_dashboard_client", window.location.href);
        });
    };

    handleSearch = (value) => {
        const { allReportsCopy, favouritesCopy } = this.state;
        const currentValue = value;
        const searchResult = allReportsCopy.filter((e) =>
            e.reportName.toLowerCase().includes(currentValue.toLowerCase())
        );
        const favouriteSearchResult = favouritesCopy.filter((e) =>
            e.reportName.toLowerCase().includes(currentValue.toLowerCase())
        );
        this.setState({
            allReports: searchResult,
            favourites: favouriteSearchResult,
            searchValue: value,
        });
    };

    handleTabChange = (activeKey) => {
        this.setState({ activeKey });
    };

    getAvgHealth = () => {
        if (this.state.loading) {
            return <Spin />;
        }
        const { favouritesCopy } = this.state;
        const avgHealth = parseFloat(
            (
                favouritesCopy.reduce((acc, curr) => {
                    return acc + parseFloat(curr.data_quality);
                }, 0) / favouritesCopy.length
            ).toFixed(2)
        );
        return !isNaN(avgHealth) ? avgHealth + " %" : 0;
    };

    updateUserFavReports = async (id, fav) => {
        await axios.post(`${SERVER_URL}/updatefavtableau`, {
            id,
            fav: !!!fav,
        });
        this.notifyAddOrRemoveFavourties(fav);
    };

    notifyAddOrRemoveFavourties = (fav) => {
        notification.success({
            message: !fav ? "Added to favourites" : "Removed from favourites",
            placement: "top",
        });
    };

    render() {
        const { favourites, activeKey, allReports, searchValue, dataFetched } =
            this.state;
        const { onReportClick } = this.props;
        return (
            <Row>
                <Col span={24}>
                    <div
                        className={"dashboard-header"}
                        data-testid="dashboard-header"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "16px",
                        }}
                    >
                        <h1 data-testid="dashboard-title">Tableau Reports</h1>
                        <InfoPopover>
                            <div
                                style={{
                                    float: "right",
                                    height: 21,
                                    width: 21,
                                    marginTop: "-10px",
                                }}
                            >
                                <svg
                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                    fill="rgb(135 141 137)"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="InfoOutlinedIcon"
                                >
                                    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                </svg>
                            </div>
                        </InfoPopover>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "32px",
                            justifyContent: "flex-end",
                            position: "absolute",
                            top: "36px",
                            right: "63px",
                        }}
                    >
                        <div className={"metrics-container"}>
                            <h5>My Reports Health</h5>
                            <h3>{this.getAvgHealth()}</h3>
                        </div>
                        <div className={"metrics-container"}>
                            <h5>Total Reports</h5>
                            <h3>
                                {activeKey === "1"
                                    ? favourites.length
                                    : allReports.length}
                            </h3>
                        </div>
                    </div>
                    <div className="ant-layout-content height-adjustment">
                        <div
                            style={{
                                height: "calc(100vh - 225px)",
                                overflow: "auto",
                                background: "#fff",
                            }}
                        >
                            <div
                                className="site-content TableauDashboardWrapper"
                                style={{ paddingLeft: "60px" }}
                            >
                                {
                                    <div
                                        style={{
                                            paddingTop: "16px",
                                            display: "flex",
                                            gap: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        <Tabs
                                            style={{ flex: 1 }}
                                            defaultActiveKey="1"
                                            onChange={this.handleTabChange}
                                            items={[
                                                {
                                                    label: "My Reports",
                                                    key: "1",
                                                    children: dataFetched ? (
                                                        <TableauFavouriteReports
                                                            allReports={
                                                                favourites
                                                            }
                                                            handleFavChange={
                                                                this
                                                                    .handleFavChange
                                                            }
                                                            onReportClick={
                                                                onReportClick
                                                            }
                                                        />
                                                    ) : (
                                                        <Spin />
                                                    ),
                                                },
                                                {
                                                    label: "All Reports",
                                                    key: "2",
                                                    children: dataFetched ? (
                                                        <TableauAllReports
                                                            allReports={
                                                                allReports
                                                            }
                                                            handleFavChange={
                                                                this
                                                                    .handleFavChange
                                                            }
                                                            onReportClick={
                                                                onReportClick
                                                            }
                                                        />
                                                    ) : (
                                                        <Spin />
                                                    ),
                                                },
                                            ]}
                                        />
                                        <ReportSearch
                                            onSearchChange={this.handleSearch}
                                            searchValue={searchValue}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}
const InfoPopover = ({ children }) => (
    <Popover
        placement="bottom"
        // trigger={"click"}
        content={<InfoPopoverContent />}
    >
        {children}
    </Popover>
);
const InfoPopoverContent = () => (
    <div className={"popover-content-tableau"}>
        <p style={{ fontSize: 13 }}>
            <b>All Reports</b>:
            <br />
            List of those reports you have access.
        </p>
        <p style={{ fontSize: 13 }}>
            <b>My Reports</b>:
            <br />
            Those reports you have added as favourite and data health will
            evaluate only for these favourite reports.
        </p>
        <p style={{ fontSize: 13 }}>
            <b>Data Health</b>:
            <br />
            Ratio of Total number of tables having successfull ETL load to Total
            number of tables used in the dashboard.
        </p>
    </div>
);
export default TableauDashboard;
