const RELEASE_API_KEY = process.env.RELEASE_API_KEY;

const RELEASE_API_URL = "https://release-api.nutanix.com/";
const RELEASE_API_ENDPOINT = `${RELEASE_API_URL}api/v1/releases?api_key=${RELEASE_API_KEY}`;

export const periodMenuItems = [
    {
        label: "Last 24 hours",
        value: "0",
    },
    {
        label: "This Week",
        value: "1",
    },
    {
        label: "This Month",
        value: "2",
    },
    {
        label: "This Quater",
        value: "3",
    },
];

export const appDataArray = [
    {
        app_name: "DATABASE (POSTGRES)",
        polling_frequency: 20,
        display: true,
        lastUpdatedTime: "",
        type: "prod",
        availability: "100",

        environment: [
            {
                name: "Production",
                enable: true,
                status: "active",
                sendMail: true,
                sendSlackNotification: true,
                type: "prod",
                endPoint: "https://portal.nutanix.com/#/page/docs",
                link: "http://portal.nutanix.com",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        dataLink: [
            {
                googleAnalytics: true,
                metrics: "ga:totalevents",
                dimensions: "ga:eventCategory",
                dataKey: [2, 6, 16],
                label: [
                    "AOS Downloads (Last 30 Days)",
                    "Foundation Downloads (Last 30 Days)",
                    "PC Downloads (Last 30 Days)",
                ],
                method: "GET",
                header: {},
                hasMultipleValues: true,
                value: ["56", "21", "110"],
            },
        ],
        updatedAt: "2022-03-28T06:50:03.429Z",
        upTime: "99",
        id: "597ef61faecf424d39349ca8",
    },
    {
        app_name: "ETL (INFORMATICA)",
        polling_frequency: 20,
        display: true,
        type: "prod",
        lastUpdatedTime: "",
        availability: "100",
        environment: [
            {
                name: "Production",
                enable: true,
                sendMail: true,
                sendSlackNotification: true,
                status: "active",
                endPoint: "http://alerts.corp.nutanix.com/status",
                link: "http://alerts.corp.nutanix.com",
                type: "prod",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        dataLink: [
            {
                url: "http://alerts.corp.nutanix.com/stats",
                enable: true,
                dataKey: ["alerts,types", "cases,count"],
                label: ["Alert Types (30 Days)", "Alert Cases (30 Days)"],
                method: "GET",
                header: {},
                hasMultipleValues: true,
                value: [24, 1790],
            },
        ],
        updatedAt: "2022-03-28T06:50:03.797Z",
        upTime: "100.00",
        id: "597f0e7daecf424d39349ca9",
    },
    {
        app_name: "DAAS (DREMIO)",
        polling_frequency: 10,
        display: true,
        type: "prod",
        availability: "95.833",
        environment: [
            {
                name: "production",
                enable: true,
                sendSMS: false,
                sendMail: true,
                sendSlackNotification: false,
                endPoint: "http://ubertower.corp.nutanix.com",
                link: "http://ubertower.corp.nutanix.com",
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        dataLink: [
            {
                url: "http://ubertower.corp.nutanix.com:5000",
                enable: true,
                dataKey: ["Number of instances"],
                label: ["Number of instances"],
                method: "GET",
                header: {},
                hasMultipleValues: true,
                value: [null],
            },
        ],
        createdAt: "2018-04-26T05:49:58.626Z",
        updatedAt: "2022-03-28T06:50:33.279Z",
        upTime: "100.00",
        id: "5ae168869279a2061ac65c1d",
    },

    {
        app_name: "TABLEAU",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Production",
                sendSMS: false,
                sendMail: true,
                sendSlackNotification: true,
                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7c4016b015b345e88f81c",
    },
];

export const appDataArray1 = [
    {
        app_name: "DATABASE (POSTGRES)",
        polling_frequency: 20,
        display: true,
        lastUpdatedTime: "",
        type: "STAGE",
        availability: "100",

        environment: [
            {
                name: "Stage",
                enable: true,
                status: "active",
                sendMail: true,
                sendSlackNotification: true,
                type: "S",
                endPoint: "https://portal.nutanix.com/#/page/docs",
                link: "http://portal.nutanix.com",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        dataLink: [
            {
                googleAnalytics: true,
                metrics: "ga:totalevents",
                dimensions: "ga:eventCategory",
                dataKey: [2, 6, 16],
                label: [
                    "AOS Downloads (Last 30 Days)",
                    "Foundation Downloads (Last 30 Days)",
                    "PC Downloads (Last 30 Days)",
                ],
                method: "GET",
                header: {},
                hasMultipleValues: true,
                value: ["56", "21", "110"],
            },
        ],
        updatedAt: "2022-03-28T06:50:03.429Z",
        upTime: "99",
        id: "597ef61faecf424d39349ca8",
    },
    {
        app_name: "ETL (INFORMATICA)",
        polling_frequency: 20,
        display: true,
        type: "prod",
        lastUpdatedTime: "",
        availability: "100",
        environment: [
            {
                name: "Stage",
                enable: true,
                sendMail: true,
                sendSlackNotification: true,
                status: "active",
                endPoint: "http://alerts.corp.nutanix.com/status",
                link: "http://alerts.corp.nutanix.com",
                type: "prod",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        dataLink: [
            {
                url: "http://alerts.corp.nutanix.com/stats",
                enable: true,
                dataKey: ["alerts,types", "cases,count"],
                label: ["Alert Types (30 Days)", "Alert Cases (30 Days)"],
                method: "GET",
                header: {},
                hasMultipleValues: true,
                value: [24, 1790],
            },
        ],
        updatedAt: "2022-03-28T06:50:03.797Z",
        upTime: "100.00",
        id: "597f0e7daecf424d39349ca9",
    },
    

    {
        app_name: "TABLEAU",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Stage",
                sendSMS: false,
                sendMail: true,
                sendSlackNotification: true,
                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7c4016b015b345e88f81c",
    },
];
