import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  ColorManager,
  XAxis,
  YAxis,
  Tooltip,
  LineDot,
  ResponsiveContainer,
  LabelList,
} from "@nutanix-ui/recharts";
import { useDispatch, useSelector } from "react-redux";
import { setColumnData, setDataSourceData, setOpenModal } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { CartesianGridColor, formatQuarter, getVelocityColumn, getVelocityDataSource, graphHeight, labelStyle, useVelocityDetail } from "../../EngineeringUtils";
import NoData from "./NoData";

const TeamVelocity = () => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);
  const [interval, setInterval] = useState(null);
  const [data, setData] = useState([]);
  const { teamVelocityData, filters, defaultViewVelocity } = useSelector((state) => state.enggDashboard);
  const { data: velocityDetail, isLoading } = useVelocityDetail(filters, enabled, interval, 'team_velocity');
  // Calculate the minimum and maximum values of the 'story' data
  const minStory = data.length > 0 ? Math.min(...data.map((entry) => entry.story)) : 0;
  const maxStory = data.length > 0 ? Math.max(...data.map((entry) => entry.story)) : 0;
  
  const renderLabelList = () => {
    return defaultViewVelocity.default_story ? <LabelList dataKey="story" position="top" style={labelStyle} /> : null;
  };

  const fiscalToCalendarQuarter = (fiscalQuarter) => {
    const [fy, fq] = fiscalQuarter.split(' ');
    const fiscalYear = parseInt(fy.slice(2), 10) + 2000;
    const quarter = parseInt(fq.slice(1), 10);
    return `${fiscalYear}-Q${quarter}`;
  }

  const renderLineComponent = () => (
    <Line
      type="linear"
      dataKey="story"
      dot={(props) => (
        <LineDot
          color={props.stroke}
          cx={props.cx}
          cy={props.cy}
          key={props.index}
          r={3}
        />
      )}
      stroke={ColorManager.getColor("blue")}
      activeDot={{
        onClick: (props) => {
          const { name } = props?.payload;
          const calendarQuarter = fiscalToCalendarQuarter(name);
          setInterval(calendarQuarter);
          setEnabled(true); // Enable the query
        },
      }}
    >
      {renderLabelList()}
    </Line>
  );

  useEffect(() => {
    if (enabled && !isLoading && velocityDetail) {
      dispatch(setColumnData(getVelocityColumn()));
      dispatch(setDataSourceData(getVelocityDataSource(velocityDetail)));
      dispatch(setOpenModal(true));
      setEnabled(false); // Reset the trigger
    }
  }, [enabled, isLoading, velocityDetail]);

  useEffect(() => {
    // Generate velocity data
    const generateBarData = (data) => {
      return data
        .map((item) => ({
          name:
            filters.viewByVelocity === "Monthly"
              ? item.period_selection
              : formatQuarter(item.period_selection),
          story: parseInt(item.story_points, 10)?.toFixed(1),
        }))
        .reverse();
    };
    if (teamVelocityData?.length > 0) {
      setData(generateBarData(teamVelocityData));
    }
  }, [teamVelocityData, filters.viewByVelocity]);

  return (
    <ResponsiveContainer height={graphHeight} width="100%">
      {data.length > 0 ? (
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, bottom: 20, left: 0 }}
          data-testid="team-velocity"
        >
          <CartesianGrid stroke={CartesianGridColor} interval={0} vertical={true} />
          <XAxis
            axisLine={true}
            interval={0}
            dataKey="name"
            tickFormatter={(value) => {
              return filters.viewByVelocity === "Monthly"
                ? value
                : formatQuarter(value);
            }}
          />
          <YAxis domain={[minStory - 100, maxStory + 100]} dataKey="story" />
          {renderLineComponent()}
          <Tooltip />
        </LineChart>
      ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default TeamVelocity;
