import { SERVER_URL } from "../../config/config";
import Logger from "../../config/logger";
import axios from "axios";

export const sendUserRecord = async (businessUnit, url) => {
    try {
        return axios.post(`${SERVER_URL}/adoptionMetric`, {
            module: businessUnit,
            url: url,
        })
            .then(resp => {
                Logger.info(
                    `adoption metric record is sent sucessful`,
                    "adoption_metric_client",
                    window.location.href,
                    'getAdoptionMetric'
                );
            })
            .catch(err => {
                Logger.error(
                    err,
                    "adoption_metric_client",
                    window.location.href,
                    'postAdoptionMetric'
                );
            })
    } catch (err) {
        Logger.error(
            err,
            "adoption_metric_client",
            window.location.href,
            'postAdoptionMetric'
        );
    }
}

export const getAdoptionMetric = async () => {
    try {
        return axios.get(`${SERVER_URL}/getAdoptionMetric`)
            .then(resp => {
                const response = constructAdoptionData(resp.data.data);
                Logger.info(
                    `fetching adoption metric is successful`,
                    "adoption_metric_client",
                    window.location.href,
                    'getAdoptionMetric'
                );
                return response;
            })
            .catch(err => {
                Logger.error(
                    err,
                    "adoption_metric_client",
                    window.location.href,
                    'getAdoptionMetric'
                );
            })
    } catch (err) {
        Logger.error(
            err,
            "adoption_metric_client",
            window.location.href,
            'getAdoptionMetric'
        );
    }
}

const constructAdoptionData = (data) => {
    const { timeSeriesData, totalUsers } = data;
    const tempTotalUser = [];
    const tempTimeSeriesData = [];
    totalUsers.forEach(el => {
        if (el.module === "jira_analysis") {
            tempTotalUser.push({
                name: 'Jira Analysis',
                count: el.users
            })
        } else if (el.module === "paas") {
            tempTotalUser.push({
                name: "PaaS",
                count: el.users
            })
        } else if (el.module === "platform_health") {
            tempTotalUser.push({
                name: 'Platform Health',
                count: el.users
            })
        } else if (el.module === "data_health") {
            tempTotalUser.push({
                name: 'Data Health',
                count: el.users
            })
        } else if (el.module === "data_audit") {
            tempTotalUser.push({
                name: 'Data Audit',
                count: el.users
            })
        } else if (el.module === "file_upload") {
            tempTotalUser.push({
                name: 'File Upload',
                count: el.users
            })
        } else if (el.module === "tableau_dashboard") {
            tempTotalUser.push({
                name: 'Tableau Dashboard',
                count: el.users
            })
        } else if (el.module === "leo_2.0") {
            tempTotalUser.push({
                name: 'Leo 2.0',
                count: el.users
            })
        } else if (el.module === "pnp_2.0") {
            tempTotalUser.push({
                name: 'PnP 2.0',
                count: el.users
            })
        } else if (el.module === "project_tracker") {
            tempTotalUser.push({
                name: 'Pro Tracker',
                count: el.users
            })
        } else if (el.module === "fullfilment_slide") {
            tempTotalUser.push({
                name: 'Fulfillment Slide',
                count: el.users
            })
        } else if (el.module === "edp") {
            tempTotalUser.push({
                name: 'Total',
                count: el.users
            })
        }
    });
    timeSeriesData.forEach(el => {
        tempTimeSeriesData.push({
            name: el.month_year,
            internal: el.internal_users,
            external: el.external_users,
            total: el.internal_users + el.external_users
        });
    })
    return {
        timeSeriesDataResp: tempTimeSeriesData,
        totalUsersResp: tempTotalUser
    };
}