import React, { useContext, useEffect, useState } from "react";
import { Accordion, Alert, FlexLayout, LocalTable, Checkbox, Link } from '@nutanix-ui/prism-reactjs';

import { TemplateFormContext } from "../../../FileTemplateUploadDashboard";

export default function Summary(props) {
    const { setCurrent } = props;

    const [formData, setFormData] = useContext(TemplateFormContext);

    const [isError, setIsError] = useState(false);

    const { templateName, desc, validation, aggregator, users, columnsData } = formData;

    useEffect(() => {
        if (templateName === '' || desc === '') {
            setIsError(prevState => true);
        }

        if (validation === '' || aggregator === '') {
            setIsError(prevState => true);
        }

        if (users.length === 0) {
            setIsError(prevState => true);
        }

        if (columnsData.length === 0) {
            setIsError(prevState => true);
        }

        columnsData.map(col => {
            if (col.dataType === '' || col.columnName === '') {
                setIsError(prevState => true);
            }
            return null
        });

        setFormData({ ...formData, isError: isError })
// eslint-disable-next-line 
    }, [isError])

    const renderTemplateDetails = () => {

        const columns = [{
            title: 'Name',
            key: 'name'
        }, {
            title: "Description",
            key: 'desc'
        }];

        const data = [
            {
                key: 1,
                name: templateName,
                desc: desc
            }
        ]

        return <LocalTable
            columns={columns}
            dataSource={data}
            data-testid="templateDetailsTable"
        />

    }

    const renderDataProcessingFunctions = () => {
        const columns = [{
            title: 'Validation Function',
            key: 'validation'
        }, {
            title: "Aggregator Function",
            key: 'aggregator'
        }];

        const data = [
            {
                key: 1,
                validation: validation,
                aggregator: aggregator
            }
        ]

        return <LocalTable
            columns={columns}
            dataSource={data}
            data-testid="functionTable"
        />
    }

    const renderUserDetails = () => {
        const columns = [{
            title: 'Email',
            key: 'email'
        }, {
            title: 'Admin',
            key: 'admin',
            render: (value, object, index) => {
                return (
                    <Checkbox checked={object.admin} />
                )
            }
        }]
        const data = [];

        users.map(user => {
            data.push({
                key: user.key,
                email: user.email,
                admin: user.isAdmin
            });
            return null
        });

        return <LocalTable
            columns={columns}
            dataSource={data}
        />
    }

    const renderColumnsData = () => {
        const columns = [{
            title: 'Column Name',
            key: 'columnName'
        }, {
            title: 'Data Type',
            key: 'dataType'
        }];

        const data = [];
        columnsData.map(col => {
            data.push({
                key: Math.random(),
                columnName: col.columnName,
                dataType: col.dataType.label
            });
            return null
        });

        return <LocalTable
            columns={columns}
            dataSource={data}
        />

    }
    return <div className="templateSummaryContainer" expandedByDefault={true} data-testid="templateSummaryContainer" >
        <FlexLayout
            flexDirection="column"
        >
            {isError && <Alert aria-live="off" inline={true} type={Alert.AlertTypes.ERROR}
                data-test-id="type-error" message="Please fill all the information " />}
            <Accordion headerProps={{
                justifyContent: 'space-between'
            }} title="Template Details" expandedByDefault={true} extraHeaderContent={
                <FlexLayout alignItems="center">
                    <Link onClick={() => {
                        setCurrent(0)
                    }} data-testid="templateDetailsEdit" >Edit</Link>
                </FlexLayout>
            }>
                {renderTemplateDetails()}
            </Accordion>
            <Accordion headerProps={{
                justifyContent: 'space-between'
            }} title="Data Validation Function" expandedByDefault={true} extraHeaderContent={
                <FlexLayout alignItems="center">
                    <Link onClick={() => {
                        setCurrent(0)
                    }} data-testid="dataProcessingFunctionEdit" >Edit</Link>
                </FlexLayout>
            }>
                {renderDataProcessingFunctions()}
            </Accordion>
            <Accordion headerProps={{
                justifyContent: 'space-between'
            }} title="User Details" expandedByDefault={true} extraHeaderContent={
                <FlexLayout alignItems="center">
                    <Link onClick={() => {
                        setCurrent(1)
                    }} data-testid="userDetailsEdit" >Edit</Link>
                </FlexLayout>
            }>
                {renderUserDetails()}
            </Accordion>
            <Accordion headerProps={{
                justifyContent: 'space-between'
            }} title="Columns" expandedByDefault={true} extraHeaderContent={
                <FlexLayout alignItems="flex-end" justifyContent="space-between">
                    <Link onClick={() => {
                        setCurrent(2)
                    }} data-testid="columnDetailsEdit" >Edit</Link>
                </FlexLayout>
            }>
                {renderColumnsData()}
            </Accordion>
        </FlexLayout>
    </div>
}