import React, { useContext } from "react";
import { StackingLayout, Tabs } from "@nutanix-ui/prism-reactjs";
import LeoContext from "./../../../contexts/leoContexts/leoContext";

const TabsList = () => {
  const { setActiveTab, activeTab } = useContext(LeoContext);
  const tabsData = [
    {
      title: "Expense & Flight",
      key: 1,
      // className: activeTab === 1 ? "activeTab" : "",
    },
    {
      title: "T&E Dashboard",
      key: 2,
      // className: activeTab === 2 ? "activeTab" : "",
    },
  ];

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };
  return (
    <StackingLayout data-test-id="default" style={{ background: "#F2F4F6" }}>
      <Tabs
        data={tabsData}
        onTabClick={handleTabClick}
        defaultActiveKey={1}
        activeKey={activeTab}
        data-test-id="default-active"
        style={{
          borderBottom: "0.5px solid lightGrey",
          borderRadius: "5px",
          fontSize: "14px",
          color: "#22272E",
        }}
      />
    </StackingLayout>
  );
};
export default TabsList;
