import React, { useEffect, useState } from "react";
import ETLListTable from "./ETLlistTable";
import Header from './ETLpipelineHeader'
import './style.css';
import { NotificationTrigger} from '@nutanix-ui/prism-reactjs';
import { useParams,useLocation } from "react-router-dom";

  import axios from "axios";
import { sendUserRecord } from "../../Service/adoptionMetric";

  import { SERVER_URL } from "../../config/config";
export default function ETLPipelineProcessList() {

  const { PipeLineName } = useParams();
  const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [processList, setProcessList] = useState([]);

    useEffect(() => {
        sendUserRecord('paas', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }, [])
    
    const constructProcessesData = (data) => {
      let obj = [];
      let key = 0;
      if (data && data.length > 0) {
          data.forEach(etl => {
              obj.push({
                  key: ++key,
                  proc_name: etl.proc_name,
                  schedule_type: etl.schedule_type,
                  ip_cnt: etl.ip_cnt,
                  op_cnt: etl.op_cnt,
              })
          })
      }
      setProcessList(prevState => obj);
  }
   useEffect(()=>{
    getProcessListModel(location.state?.pipeID)// eslint-disable-next-line 
   },[])
   const getProcessListModel =(pipeID)=>{
    axios.post(`${SERVER_URL}/getProcessListModel`, {
        pipeline_id: pipeID
    })
        .then(resp => {
            constructProcessesData(resp.data.data.res)
            setLoading(false);
        })
        .catch(err => {
            NotificationTrigger.add({
                id: 'procress Fetchted successful',
                className: 'successAlert',
                message: 'Something went wrong. Please Refresh the page',
                type: 'error',
                autoDismissDelaySecs: 90,
            });
        })
   }


    return <div>
        <Header
            projectData={[]}
            selectedProject={location.state?.Project}
            loading={loading}
            projectID={location.state?.Project.value}
            PipelineName={PipeLineName}
        />
       <ETLListTable columnData={processList} loading={loading} selectedProject={location.state?.Project} visible={visible} setVisible={setVisible} PipelineName={PipeLineName}  listType={'processes'} pipeID={location.state?.pipeID} getProcessListModel={getProcessListModel}scheduleType={location.state?.scheduleType}/>
    </div>

}


