import {
  ContainerLayout,
  DatePicker,
  FormItemSelect,
  HeaderFooterLayout,
  Loader,
  NotificationTrigger,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";
import React, { useContext, useMemo, useState } from "react";
import CardHeader from "../CardHeader";
import Footer from "../Footer";
import Body from "../Body";
import { filterBySearch, generateTimeRangeMenu, sortByColumn, transformDetails } from "../../utils";
import LeoContext from "../../../../contexts/leoContexts/leoContext";
const ExpenseFlightTab = () => {
  const {
    filters,
    setCurrentSelection,
    currentSelection,
    setLoaders,
    expenseReportsDetails,
    flightReportsDetails,
    sort,
    loading
  } = useContext(LeoContext);
  const timeRangeMenus = generateTimeRangeMenu(filters.timeRanges);
  const [detailsPageSize, setDetailsPageSize] = useState(5);

  const expenseDetailsSortedAndTranformed = useMemo(
    () => {
      const sortedDetails = sortByColumn(
        expenseReportsDetails,
        sort.expenseFilghtDetails.expense.column,
        sort.expenseFilghtDetails.expense.order
      );
      return transformDetails(sortedDetails);
    },
    [expenseReportsDetails, sort.expenseFilghtDetails.expense.column, sort.expenseFilghtDetails.expense.order]
  );

  const flightDetailsSortedAndTransformed = useMemo(
    () => {
      const sortedDetails = sortByColumn(
        flightReportsDetails,
        sort.expenseFilghtDetails.flight.column,
        sort.expenseFilghtDetails.flight.order
      );
      return transformDetails(sortedDetails);
    },
    [flightReportsDetails, sort.expenseFilghtDetails.flight.column, sort.expenseFilghtDetails.flight.order]
  );

  const expenseAndFlightDetailsSortedAndTransormed = useMemo(
    () => {
      const sortedDetails = sortByColumn(
        [...expenseDetailsSortedAndTranformed, ...flightDetailsSortedAndTransformed],
        sort.expenseFilghtDetails.expenseFlight.column,
        sort.expenseFilghtDetails.expenseFlight.order
      );
      return transformDetails(sortedDetails);
    },
    [expenseDetailsSortedAndTranformed, flightDetailsSortedAndTransformed, sort.expenseFilghtDetails.expenseFlight.column, sort.expenseFilghtDetails.expenseFlight.order]
  );
  const expenseDetails = filterBySearch(expenseDetailsSortedAndTranformed, currentSelection.searchExpenseAndFlight, "employee_name")
  const flightDetails = filterBySearch(flightDetailsSortedAndTransformed, currentSelection.searchExpenseAndFlight, "employee_name")
  const expenseAndFlightDetails = filterBySearch(expenseAndFlightDetailsSortedAndTransormed, currentSelection.searchExpenseAndFlight, "employee_name")
  
  return (
    <>
      <div className="leoContainer">
        <div className="leoItems">
          <Loader
            loading={loading.expenseTrendsLoading}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <ContainerLayout backgroundColor="white" border={true}>
              <HeaderFooterLayout
                header={
                  <CardHeader title="Expense Comparison" id="comparison" />
                }
                footer={<Footer id="comparison" />}
                bodyContent={<Body id="comparison" />}
              />
            </ContainerLayout>
          </Loader>
        </div>
        <div className="leoItems">
          <Loader
            loading={loading.expenseTrendsLoading}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <ContainerLayout backgroundColor="white" border={true}>
              <HeaderFooterLayout
                //   itemSpacing="20px"
                header={<CardHeader title="Expense Growth" id="growth" />}
                footer={<Footer id="growth" />}
                bodyContent={<Body id="growth" />}
              />
            </ContainerLayout>
          </Loader>
        </div>
      </div>
      <div className="leoContainer">
        <div className="leoItems">
          <ContainerLayout>
            <TextLabel className="textTitle">Time Range</TextLabel>
            <FormItemSelect
              id="timeRange"
              inputProps={{
                name: "basic-select",
                "data-test-id": "basic-select",
              }}
              onSelectedChange={(select) => {
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  timeRange: select,
                }));
                if (select.label !== "Custom Date Range") {
                  setLoaders((prevState) => ({
                    ...prevState,
                    loadingOnTimeRange: true,
                  }));
                } else {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    dateRange: {},
                  }));
                  return NotificationTrigger.add({
                    key: 'timeRange',
                    message: 'Please select date range to fetch data.',
                    autoDismissDelaySecs: 3,
                    type: 'info',
                    actions: null,
                  });
                }
              }}
              selectedRow={currentSelection.timeRange}
              rowsData={timeRangeMenus}
            />
          </ContainerLayout>
        </div>
        <div className="leoItems">
          <ContainerLayout style={{ width: "auto" }}>
            <TextLabel className="textTitle">Date Range Selection</TextLabel>
            <DatePicker
              disabled={
                currentSelection.timeRange.label === "Custom Date Range"
                  ? false
                  : true
              }
              data-test="range-select"
              oldDatePicker={false}
              value={currentSelection.dateRange}
              onChange={(selectedDate) => {
                const button = document.querySelector(
                  ".leoItems .ntnx-date-picker-open.ntnx.ntnx-button"
                );
                if (button) {
                  button.click();
                }
                if(selectedDate.startDate && selectedDate.endDate){
                  setLoaders((prevState) => ({
                    ...prevState,
                    loadingOnTimeRange: true,
                  }));
                }
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  dateRange: selectedDate,
                }));
              }}
            />
          </ContainerLayout>
        </div>
      </div>
      {/* TOP SPENDERS AND CATEGORIES SECTION */}
      <div className="leoContainer">
        <div className="leoItems">
          <Loader
            loading={ loading.spendersExpenseLoading || loading.spendersFlightLoading }
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <ContainerLayout backgroundColor="white" border={true}>
              <HeaderFooterLayout
                header={
                  <CardHeader
                    title="List of Spenders"
                    id="topSpenders"
                  />
                }
                footer={<Footer id="topSpenders" />}
                bodyContent={
                  <Body
                    id="topSpenders"
                  />
                }
              />
            </ContainerLayout>
          </Loader>
        </div>
        <div className="leoItems">
          <Loader
            loading={loading.categoriesLoading}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <ContainerLayout backgroundColor="white" border={true}>
              <HeaderFooterLayout
                header={
                  <CardHeader
                    title="Expenses By Category "
                    id="expenseCategory"
                  />
                }
                bodyContent={
                  <Body
                    id="expenseCategory"
                  />
                }
              />
            </ContainerLayout>
          </Loader>
        </div>
      </div>
      {/* DETAILS SECTION */}
      <div className="leoContainer">
        <ContainerLayout
          backgroundColor="white"
          border={true}
          style={{ padding: "10px", width: "100%" }}
        >
          <HeaderFooterLayout
            header={
              <CardHeader
                title="Expenses and Flight Details"
                id="expensesDetails"
                expenseFlightTotal={expenseAndFlightDetails?.length} 
                expenseTotal={expenseDetails?.length} 
                flightTotal={flightDetails?.length}
                detailsPageSize={detailsPageSize} 
                setDetailsPageSize={setDetailsPageSize}
              />
            }
            footer={
              <Footer
                id="expensesDetails"
                expenseFlightTotal={expenseAndFlightDetails?.length} 
                expenseTotal={expenseDetails?.length} 
                flightTotal={flightDetails?.length}
                detailsPageSize={detailsPageSize} 
                setDetailsPageSize={setDetailsPageSize}
              />
            }
            bodyContent={ <Body id="expensesDetails" detailsPageSize={detailsPageSize} expenseDetails={expenseDetails} flightDetails={flightDetails} expenseAndFlightDetails={expenseAndFlightDetails} /> }
          />
        </ContainerLayout>
      </div>
    </>
  );
};

export default ExpenseFlightTab;
