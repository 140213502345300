import React, { useState, useRef } from "react";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const ReportSearch = (props) => {
    const { onSearchChange, searchValue } = { ...props };
    const [open, setOpen] = useState(false);
    const inputRef = useRef(null);

    const handleBackButtonClick = () => {
        onSearchChange("");
        setOpen((current) => !current);
    };

    return (
        <div
            style={{
                marginTop: "13px",
                fontSize: "18px",
                right: "66px",
                position: "absolute",
            }}
            className={"ReportSearch_Wrapper"}
        >
            <SearchOutlined onClick={() => setOpen((current) => !current)} />
            {open && (
                <div
                    style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-6px",
                        zIndex: 1000,
                        height: "54px",
                        background: "white",
                        width: "60vw",
                        paddingLeft: "25px",
                    }}
                >
                    <div
                        style={{ position: "relative", display: "flex" }}
                        className={"ReportSearch_Wrapper-search-wrapper"}
                    >
                        <button onClick={handleBackButtonClick}>
                            <ArrowLeftOutlined
                                style={{
                                    color: "ash",
                                    fontSize: "14px",
                                    paddingLeft: "10px",
                                    cursor: "pointer",
                                }}
                            />
                        </button>
                        <input
                            ref={inputRef}
                            placeholder="Search"
                            onChange={(e) => onSearchChange(e.target.value)}
                            value={searchValue}
                            autoFocus
                        />
                        <button>
                            <SearchOutlined />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportSearch;
