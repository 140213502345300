import React from "react";
import { useRef } from "react";
import axios from "axios";
import {
    Popover,
    Card,
    Layout,
    Breadcrumb,
    Row,
    Col,
    Button,
    Spin,
    Table,
    Select,
    Input,
    Form,
    Alert,
    message,
    Steps,
    notification,
} from "antd";
import {
    QuestionCircleOutlined,
    SearchOutlined,
    CloseOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import { apiResponseError } from "../../config/helper";
import "antd/dist/antd.css";
import "../../App.css";
import "../../styles/animate.css";
import logger from "../../config/logger";
import {
    newReformat,
    environmentMap,
    environmentReqMap,
    fixdataEnvReq,
    menuItems,
    fetchDataStages,
    fixDataStages,
    netsuite_columns,
    titleCase,
} from "./config";
import PageHeader from "../../Containers/PageHeader";
import SideBar from "../SideBar/SideBar";
import { SERVER_URL } from "../../config/config";
import { sendUserRecord } from "../../Service/adoptionMetric";
message.config({
    top: 350,
    duration: 10,
});
const { Option, OptGroup } = Select;
const { Content } = Layout;
const Step = Steps.Step;
const DATA_AUDIT = `${SERVER_URL}/data-audit`;

const Spinner = () => (
    <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

class DataAudit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiProgress: { loading: false, ready: false, error: false },
            fetchApiResponse: null,
            fetchApiRequest: null,
            no_of_days: "1",
            selectedFieldValue: 0,
            id: "",
            environment: [],
            environments: [],
            schema: "",
            objectName: "",
            primaryKeys: [],
            auditColumnName: [],
            schemaNameData: [],
            objectNameData: [],
            auditColumn: [],
            objectNameValue: null,
            auditColumnValue: [],
            primaryKeyValue: null,
            tableData: {},
            fixData: false,
            fetchMessage: "",
            etl_message: "",
            fixDataRunning: false,
            message: "",
            currentStage: 0,
            stepperVisible: false,
            activeArray: [],
            jiraKey: "",
            jiraDetails: [],
            jiraId: "",
            dateColumns: [],
            numericColumns: [],
            jiraClosedMsg: "",
            jiraTicketAlertClose: false,
            fetchEnable: true,
            errorType: null,
            primary_values: {},
            dropdownSpinner: {
                environment: false,
                schema: false,
                objectName: false,
                auditColumnName: false,
            },
        };
    }

    async componentDidMount() {
        sendUserRecord('data_audit', window.location.href)
            .then(resp => { })
            .catch(err => { })
    }

    apiEndpoints = {
        schema: `${DATA_AUDIT}/get_all_schemas`,
        object: `${DATA_AUDIT}/get_all_schema_tables`,
        auditColumn: `${DATA_AUDIT}/get_all_table_cols`,
        columnDetails: `${DATA_AUDIT}/get_all_table_cols/detail`,
        jiraCreate: `${DATA_AUDIT}/jira_create/`,
        jiraClose: `${DATA_AUDIT}/jira_close/`,
        primaryKeys: `${DATA_AUDIT}/get_table_pk`,
    };

    handleChange = async (name, value, label) => {
        try {
            if (name === "environment") {
                // this.setState({ [name]: ['Sfdc', ...value] });
                this.setState({ [name]: [...value] });
                if (
                    this.state.fetchApiRequest &&
                    this.state.fetchApiRequest.env !== [...value]
                ) {
                    this.setState({ fetchEnable: true, fixData: false });
                }
            } else {
                this.setState({ [name]: value });
            }

            if (name === "auditColumnName") {
                if (
                    this.state.fetchApiRequest &&
                    this.state.fetchApiRequest.cols !== value
                ) {
                    this.setState({ fetchEnable: true, fixData: false });
                }
            }

            if (name === "id") {
                if (
                    this.state.fetchApiRequest &&
                    this.state.fetchApiRequest.filter.id !== [value]
                ) {
                    this.setState({ fetchEnable: true, fixData: false });
                }
            }

            if (name === "environment") {
                logger.info(`Fetching Sourcename`, "data_audit_client", window.location.href);
                const { data } = await axios.post(this.apiEndpoints.schema);
                logger.info(`Fetched Sourcename successfully`, "data_audit_client", window.location.href);
                // this.setState({ schemaNameData: data.result });
                this.setState({ schemaNameData: ["sfdc", "netsuite"] });
            }
            if (name === "schema") {
                logger.info(
                    `Fetching Objectname with parameter schema = ${value}`,
                    "data_audit_client", window.location.href
                );
                if (
                    this.state.fetchApiRequest &&
                    this.state.fetchApiRequest.schema !== value
                ) {
                    this.setState({ fetchEnable: true, fixData: false });
                }
                this.setState({
                    primaryKeys: [],
                    primary_values: {},
                    objectNameValue: null,
                    auditColumnValue: [],
                    objectNameData: [],
                    auditColumnName: [],
                    objectName: "",
                    dropdownSpinner: {
                        ...this.state.dropdownSpinner,
                        objectName: true,
                    },
                });
                // if (value === "sfdc") {
                //     this.setState({
                //         primary_values: { id: "" },
                //     });
                // }
                const { data } = await axios.post(this.apiEndpoints.object, {
                    params: {
                        schema: value,
                    },
                });

                if (data.result.length === 0) {
                    notification.error({
                        message: "Schema is not supported",
                        placement: "top",
                        duration: 10,
                    });
                }
                if (data.message === "Invalid token!") {
                    notification.error({
                        message: "Invalid token",
                        placement: "top",
                        duration: 10,
                    });
                }
                logger.info(`Fetched Objectname successfully`, "data_audit_client", window.location.href);
                this.setState({
                    objectNameData: data.result,
                    dropdownSpinner: {
                        ...this.state.dropdownSpinner,
                        objectName: false,
                    },
                });
            }
            if (name === "auditColumnName") {
                this.setState({
                    auditColumnValue: value,
                });
            }
            if (name === "objectName") {
                this.setState({
                    primaryKeys: [],
                    primary_values:
                        this.state.schema === "sfdc"
                            ? {
                                id: "",
                            }
                            : {},
                    auditColumn: [],
                    objectNameValue: value,
                    auditColumnValue: [],
                    dropdownSpinner: {
                        ...this.state.dropdownSpinner,
                        auditColumnName: true,
                    },
                });
                if (
                    this.state.fetchApiRequest &&
                    this.state.fetchApiRequest.collection !== value
                ) {
                    this.setState({ fetchEnable: true, fixData: false });
                }
                const columnDetailsParams = {
                    schema: this.state.schema,
                    table: value,
                };
                logger.info(`Fetching AuditColumn Details `, "data_audit_client", window.location.href);
                const {
                    data: { result: columnDetails },
                } = await axios.post(this.apiEndpoints.columnDetails, {
                    params: columnDetailsParams,
                });

                logger.info(`Fetched AuditColumn Details Successfully`, "data_audit_client", window.location.href);
                const dateColumn = columnDetails
                    .filter(
                        ({ data_type }) =>
                            data_type === "timestamp without time zone" ||
                            data_type === "date"
                    )
                    .map(({ column_name }) => column_name);
                this.setState({ dateColumns: dateColumn });
                const numericColumn = columnDetails
                    .filter(({ data_type }) => data_type === "numeric")
                    .map(({ column_name }) => column_name);
                this.setState({ numericColumns: numericColumn });
                logger.info(`Fetching AuditColumn Name `, "data_audit_client", window.location.href);
                const { data } = await axios.post(
                    this.apiEndpoints.auditColumn,
                    { params: { schema: this.state.schema, table: value } }
                );
                if (data.result.length === 0) {
                    notification.error({
                        message: "Schema is not supported",
                        placement: "top",
                        duration: 10,
                    });
                }
                if (data.result.message === "Invalid token!") {
                    notification.error({
                        message: "Invalid token",
                        placement: "top",
                        duration: 10,
                    });
                }
                logger.info(`Fetched AuditColumn Name Successfully`, "data_audit_client", window.location.href);
                const {
                    data: { result: primaryKeys },
                } = await axios.post(this.apiEndpoints.primaryKeys, {
                    params: { schema: this.state.schema, table: value },
                });
                if (primaryKeys.length === 0) {
                    notification.error({
                        message: "Primary Key constraints are not defined",
                        placement: "top",
                        duration: 10,
                    });
                }

                this.setState({
                    primary_values: primaryKeys.reduce((acc, curr) => {
                        acc[curr] = "";
                        return acc;
                    }, {}),
                    auditColumn: data.result,
                    primaryKeys: primaryKeys,
                    dropdownSpinner: {
                        ...this.state.dropdownSpinner,
                        auditColumnName: false,
                    },
                });
            }
        } catch (e) {
            logger.error(e, "data_audit_client", window.location.href);
            this.setState({
                dropdownSpinner: {
                    environment: false,
                    schema: false,
                    objectName: false,
                    auditColumnName: false,
                },
            });
        }
    };

    handlePrimaryKeyChange = (source, value) => {
        try {
            this.setState((e) => {
                return {
                    primary_values: { ...e.primary_values, [source]: value },
                    fetchEnable: true,
                };
            });
        } catch (e) {
            console.error(e);
        }
    };

    identifyMismatch(data = [], id = "") {
        const mismatches = {};

        data.forEach((singleData) => {
            const sourceData = singleData["src"];
            const otherData = Object.entries(singleData).filter((keyValue) => {
                if (keyValue[0] !== "src") {
                    if (keyValue[0] !== "columnName") return keyValue;
                }
            });
            const mismatch = otherData.reduce((acc, curr) => {
                const currentEnvironment = environmentMap(curr[0]);
                if (curr[1] !== sourceData) {
                    acc[currentEnvironment] = true;
                    return acc;
                }
                return acc;
            }, mismatches);
        });
        return mismatches;
    }

    btnFixDataClick = (e) => {
        this.setState({ fixDataRunning: true, jiraDetails: [] });
        let result_json = {
            collection: this.state.fetchApiRequest.object,
            cols: this.state.fetchApiRequest.viewable_columns,
            env: this.state.fetchApiRequest.target_envs,
            filter: this.state.fetchApiRequest.filter.primary_key,
            timestamp_filter_col: "date_last_modified_gmt",
            schema: this.state.fetchApiRequest.schema,
            audit_datetime: this.state.fetchApiResponse.audit_datetime,
            auth: true,
            data_fix_enable: false,
            db_auth: {
                message: "",
                status: "OK",
            },
            stage: [this.state.fetchApiRequest.filter.primary_key],
            etl_message: "ETL IS YET TO RUN",
            mismatched: this.state.fetchApiResponse.mismatched,
            src: [{}],
        };
        const mismatchedIdentifier = Object.keys(result_json.mismatched)[0];
        result_json.mismatched[mismatchedIdentifier].jira_ticket = {
            key: this.state.fetchApiResponse["Jira ticket"],
        };
        result_json.mismatched[mismatchedIdentifier].env =
            result_json.mismatched[mismatchedIdentifier].env.map((e) => {
                const env = e.split("-");
                return `${fixdataEnvReq(env[0])}-mismatch`;
            });
        result_json.env = result_json.env.map((e) => fixdataEnvReq(e));
        this.setState({
            activeArray: fixDataStages,
            stepperVisible: true,
            currentStage: 0,
            fixData: false,
        });
        logger.info(`Fixing Data Post Call`, "data_audit_client", window.location.href);
        axios
            .post(`${DATA_AUDIT}/audit_data_fix`, result_json)
            .then((response) => {
                logger.info(`Fixed Data Post call Successfully`, "data_audit_client", window.location.href);
                const newFixDataAlertMessage = [];
                if (response?.data?.message)
                    newFixDataAlertMessage.push(response.data.message);
                if (
                    response?.data?.rule_engine_msg?.message &&
                    response?.data?.rule_engine_msg?.message !=
                    response?.data?.message
                )
                    newFixDataAlertMessage.push(
                        `Rule Engine Message: ${response.data.rule_engine_msg.message}`
                    );
                if (
                    response?.data?.db_auth?.message &&
                    response?.data?.db_auth?.message !=
                    response?.data?.rule_engine_msg?.message &&
                    response?.data?.db_auth?.message != response?.data?.message
                )
                    newFixDataAlertMessage.push(
                        `DB Message: ${response.data.db_auth.message}`
                    );
                this.setState({
                    currentStage: 1,
                });
                setTimeout(async () => {
                    const { structuredData } = await this.fetchRowData(
                        this.state.fetchApiRequest
                    );
                    this.setState({
                        currentStage: 2,
                        tableData: structuredData,
                        //fixData:response.data.data_fix_enable
                    });
                    // setTimeout(async () => {
                    //     this.setState({
                    //         currentStage: 3,
                    //     });
                    let jiraResponseMsg = [];
                    for (const param of this.state.jiraDetails) {
                        jiraResponseMsg.push({
                            jiraId: param.jiraKey,
                            alertMessage: ``,
                        });
                    }

                    if (newFixDataAlertMessage.length > 0) {
                        if (jiraResponseMsg.length > 0) {
                            jiraResponseMsg = jiraResponseMsg.map(
                                ({ alertMessage, ...otherItems }) => {
                                    return {
                                        alertMessage:
                                            newFixDataAlertMessage.join(", "),
                                        ...otherItems,
                                    };
                                }
                            );
                        } else {
                            jiraResponseMsg.length = 0;
                            jiraResponseMsg.push({
                                alertMessage: newFixDataAlertMessage.join(", "),
                                jiraId: 1,
                            });
                        }
                    }

                    setTimeout(() => {
                        this.setState({
                            fixDataRunning: false,
                            stepperVisible: false,
                            jiraDetails: jiraResponseMsg,
                            jiraTicketAlertClose: false,
                        });
                    }, 500);
                    // }, 2000);
                }, 0);
            })
            .catch((e) => {
                logger.error(e, "data_audit_client", window.location.href);
                this.setState({ stepperVisible: false });
            });
    };

    fetchData = async (e) => {
        this.setState({
            jiraTicketAlertClose: true,
            jiraDetails: [],
            apiProgress: { loading: true, ready: false, error: false },
            fetchEnable: false,
            errorType: null,
        });
        // const username = localStorage.getItem("email").split('@nutanix.com')[0]

        let objauditpostmessage = {
            source: this.state.schema.replace("netsuite", "ntst"),
            source_env: "prod",
            target: "db",
            target_envs: this.state.environment,
            schema: this.state.schema,
            object: this.state.objectName,
            filter: {
                primary_key: {},
            },
            calling_system: "UI",
            metrics: ["accuracy"],
            viewable_columns: this.state.auditColumnName,
            ent_log_insert_flag: false,
            email_alert_flag: false,
            exclude_fields_flag: false,
            exclude_fields: [],
            etl_reproc_flag: false,
            jira_creation_flag: true,
        };

        objauditpostmessage.filter.primary_key = Object.entries(
            this.state.primary_values
        ).reduce((acc, curr) => {
            const key = curr[0];
            const value = curr[1];
            acc[key] = value.split(", ");
            return acc;
        }, {});

        objauditpostmessage.target_envs = objauditpostmessage.target_envs.map(
            (e) => environmentReqMap(e)
        );

        this.setState({
            fetchApiRequest: objauditpostmessage,
            activeArray: fetchDataStages,
            stepperVisible: true,
            currentStage: 0,
        });

        this.fetchRowData(objauditpostmessage)
            .then(({ fetchResponse, structuredData }) => {
                const alertMessage = [];
                if (fetchResponse?.db_auth?.status === "NOT OK") {
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: false,
                            error: fetchResponse?.db_auth?.message,
                        },
                        stepperVisible: false,
                        currentStage: 3,
                    });
                    return;
                }
                if (fetchResponse?.response_error) {
                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: false,
                            error: fetchResponse?.response_error,
                        },
                        stepperVisible: false,
                        currentStage: 3,
                    });
                    return;
                }
                if (fetchResponse?.etl_message)
                    alertMessage.push(
                        `ETL Message: ${fetchResponse.etl_message}`
                    );
                if (
                    fetchResponse?.db_auth?.message &&
                    fetchResponse?.db_auth?.message !=
                    fetchResponse?.etl_message
                )
                    alertMessage.push(
                        `DB Auth Message: ${fetchResponse.db_auth.message}`
                    );
                if (
                    fetchResponse?.message &&
                    fetchResponse?.message != fetchResponse?.db_auth?.message &&
                    fetchResponse?.message != fetchResponse?.etl_message
                )
                    alertMessage.push(`Message: ${fetchResponse.message}`);

                this.setState({
                    fetchApiResponse: fetchResponse,
                    fixData: fetchResponse.data_fix_enable,
                    etl_message: fetchResponse.etl_message,
                });
                this.setState({
                    currentStage: 1,
                    tableData: structuredData,
                });
                this.setState({
                    fetchMessage: fetchResponse?.db_auth?.message,
                    message: fetchResponse.message,
                });

                setTimeout(() => {
                    this.setState({
                        currentStage: 2,
                        apiProgress: {
                            loading: false,
                            ready: true,
                            error: false,
                        },
                    });

                    let jiraResponseData = [];
                    Object.entries(fetchResponse.mismatched || {}).forEach(
                        ([id, ticketDetails = {}]) => {
                            // const { key, id: jiraTicketId } =
                            //     ticketDetails.jira_ticket;
                            const key = fetchResponse["Jira ticket"];
                            const jiraTicketId = fetchResponse["Jira ticket"];
                            const isMismatchFound = this.identifyMismatch(
                                this.state.tableData.dataSource
                            );
                            jiraResponseData.push({
                                alertMessage: (
                                    <span style={{ margin: 0 }}>
                                        {Object.keys(isMismatchFound).length >
                                            0 ? (
                                            <span>
                                                Mismatch found in{" "}
                                                {ticketDetails.env
                                                    .map((e) => {
                                                        const mismatch_env =
                                                            e.replaceAll(
                                                                "-mismatch",
                                                                ""
                                                            );
                                                        if (
                                                            mismatch_env ===
                                                            "stg"
                                                        )
                                                            return "Stage";
                                                        if (
                                                            mismatch_env ===
                                                            "prd"
                                                        )
                                                            return "Prod";
                                                        return mismatch_env;
                                                    })
                                                    .join(", ")}
                                                . Created new jira ticket with
                                                ticket number{" "}
                                            </span>
                                        ) : (
                                            <span>
                                                No discrepancy found in viewable
                                                column, but there is anomaly in
                                                record. Created Jira with ticket
                                                number{" "}
                                            </span>
                                        )}
                                        <a
                                            href={`https://jira.nutanix.com/browse/${fetchResponse["Jira ticket"]}`}
                                            target="_blank"
                                        >
                                            {/* {key} */}
                                            {fetchResponse["Jira ticket"]}
                                        </a>
                                    </span>
                                ),
                                jiraId: jiraTicketId,
                                jiraKey: key,
                            });
                        }
                    );

                    if (alertMessage.length > 0) {
                        if (jiraResponseData.length > 0) {
                            jiraResponseData = jiraResponseData.map(
                                ({
                                    alertMessage: localAlertMessage,
                                    ...otherItems
                                }) => {
                                    return {
                                        alertMessage: (
                                            <span>
                                                {localAlertMessage},{" "}
                                                {alertMessage.join(", ")}
                                            </span>
                                        ),
                                        ...otherItems,
                                    };
                                }
                            );
                        } else {
                            jiraResponseData.length = 0;
                            jiraResponseData.push({
                                alertMessage: (
                                    <span>{alertMessage.join(", ")}</span>
                                ),
                                jiraId: 1,
                                jiraKey: 1,
                            });
                        }
                    }
                    setTimeout(() => {
                        if (this.state.fixData) {
                            this.setState({
                                currentStage: 3,
                            });
                        }

                        setTimeout(() => {
                            this.setState({
                                jiraDetails: jiraResponseData,
                                stepperVisible: false,
                                jiraTicketAlertClose: false,
                            });
                        }, 1000);
                    }, 1000);
                    // setTimeout(() => {
                    //     this.setState({ jiraTicketAlertClose: true });
                    // }, 6000); // hide jira alert after 3 seconds
                }, 1000);
            })

            .catch((e) => {
                logger.error(e, "data_audit_client", window.location.href);
                this.setState({ stepperVisible: false });
                const error = apiResponseError(e)
                this.setState({
                    apiProgress: { loading: false, ready: false, error: error },
                });
            });
        // Creating Jira
    };

    fetchRowData = async (objauditpostmessage) => {
        logger.info(`Fetching Audit Data`, "data_audit_client", window.location.href);
        const { data: fetchResponse } = await axios.post(
            `${DATA_AUDIT}/audit`,
            objauditpostmessage
        );
        if (fetchResponse?.Error?.includes('is not authorized to view ntst data') && fetchResponse.user_db_authentication === false) {
            this.setState({ errorType: "alert" });
            throw new Error(
                `Access denied. ${fetchResponse.Error}`
            );
        }
        else if (fetchResponse.Info || fetchResponse.ERROR || fetchResponse.Error) {
            this.setState({ errorType: "alert" });
            throw new Error(
                fetchResponse.Info || fetchResponse.ERROR || fetchResponse.Error
            );
        }

        logger.info(`Fetched Audit Data successfully`, "data_audit_client", window.location.href);
        const structuredData = newReformat(
            this.state.environment,
            this.state.auditColumnName,
            fetchResponse,
            this.state.dateColumns,
            this.state.numericColumns
        );
        return { fetchResponse, structuredData };
    };

    render() {
        const {
            id,
            environment,
            schemaNameData,
            dropdownSpinner,
            schema,
            objectNameData,
            objectName,
            auditColumn,
            auditColumnName,
            tableData,
            stepperVisible,
            fetchMessage,
            message,
            etl_message,
            jiraTicketAlertClose,
            jiraDetails,
            currentStage,
            activeArray,
            apiProgress,
            fixData,
            fetchEnable,
        } = this.state;

        return (
            <Row>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: 15,
                            marginLeft: "35px",
                            justifyContent: "space-between",
                            paddingBottom: 15,
                            alignItems: "center",
                            paddingRight: 50,
                            fontSize: "12px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 16,
                                // fontSize:"12px"
                            }}
                        >
                            <h2 style={{ marginBottom: 0 }}>Data Audit Tool</h2>
                            <InfoPopover>
                                <div
                                    style={{
                                        float: "right",
                                        height: 21,
                                        width: 21,
                                    }}
                                >
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                        fill="rgb(135 141 137)"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="InfoOutlinedIcon"
                                    >
                                        <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                    </svg>
                                </div>
                            </InfoPopover>
                        </div>
                        <CardPopover>
                            <div>
                                <VideoCameraOutlined style={{ fontSize: 18 }} />
                            </div>
                        </CardPopover>
                    </div>

                    <div className="ant-layout-content height-adjustment">
                        <Card
                            style={{
                                height: "calc(100vh - 100px)",
                                overflow: "auto",
                            }}
                            // title={'Databases / ' + 'prod' }
                            bordered={false}
                            className="n-card"
                        >
                            <div className="site-layout-content">
                                <div className="audit-card-container">
                                    <DropDown
                                        disabled={this.state.fixDataRunning}
                                        menuItems={menuItems.result}
                                        onChange={this.handleChange}
                                        label={"Environment"}
                                        name={"environment"}
                                        mode="multiple"
                                    />

                                    <DropDown
                                        disabled={
                                            !Boolean(environment) ||
                                            this.state.fixDataRunning
                                        }
                                        onChange={this.handleChange}
                                        menuItems={schemaNameData}
                                        name={"schema"}
                                        label={"Source Name"}
                                    />
                                    <DropDown
                                        loading={dropdownSpinner.objectName}
                                        disabled={
                                            !Boolean(schema) ||
                                            this.state.fixDataRunning
                                        }
                                        onChange={this.handleChange}
                                        menuItems={objectNameData}
                                        name={"objectName"}
                                        label={"Object Name"}
                                        value={this.state.objectNameValue}
                                        showSearch
                                    />

                                    <DropDown
                                        loading={
                                            dropdownSpinner.auditColumnName
                                        }
                                        disabled={
                                            !Boolean(objectName) ||
                                            this.state.fixDataRunning
                                        }
                                        onChange={this.handleChange}
                                        menuItems={auditColumn}
                                        name={"auditColumnName"}
                                        value={this.state.auditColumnValue}
                                        label={"Audit Column Name"}
                                        mode="multiple"
                                    />
                                    {!["sfdc", "netsuite"].includes(
                                        this.state.schema
                                    ) && <div></div>}
                                    {this.state.schema === "sfdc" && (
                                        <InputWrapper
                                            disabled={this.state.fixDataRunning}
                                            onChange={
                                                this.handlePrimaryKeyChange
                                            }
                                            value={
                                                this.state.primary_values["id"]
                                            }
                                            label={"Enter the ID"}
                                            name={"id"}
                                            placeholder={"ID"}
                                        />
                                    )}
                                    {this.state.schema === "netsuite" &&
                                        this.state.objectName && (
                                            <>
                                                {this.state.primaryKeys.map(
                                                    (e) => (
                                                        <InputWrapper
                                                            disabled={
                                                                this.state
                                                                    .fixDataRunning
                                                            }
                                                            onChange={
                                                                this
                                                                    .handlePrimaryKeyChange
                                                            }
                                                            label={titleCase(e)}
                                                            name={e}
                                                            value={
                                                                this.state
                                                                    .primary_values[
                                                                e
                                                                ]
                                                            }
                                                            placeholder={titleCase(
                                                                e
                                                            )}
                                                        />
                                                    )
                                                )}
                                            </>
                                        )}
                                    <div className="audit-buttons">
                                        <div
                                            style={{ display: "flex", gap: 64 }}
                                        >
                                            <div style={{ flex: 1 }}>
                                                <Button
                                                    className="antButton"
                                                    type="primary"
                                                    disabled={
                                                        !(
                                                            environment.length >
                                                            0 &&
                                                            schema &&
                                                            Object.values(
                                                                this.state
                                                                    .primary_values
                                                            ).every((e) =>
                                                                Boolean(e)
                                                            ) &&
                                                            objectName &&
                                                            auditColumnName.length >
                                                            0 &&
                                                            fetchEnable
                                                        )
                                                    }
                                                    onClick={this.fetchData}
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    Fetch
                                                    <SearchOutlined />
                                                </Button>
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <Button
                                                    type="primary"
                                                    disabled={!fixData}
                                                    // disabled={
                                                    // !Object.keys(
                                                    //     this.identifyMismatch(
                                                    //         tableData.dataSource,
                                                    //         id
                                                    //     )
                                                    // ).length > 0
                                                    // }
                                                    onClick={
                                                        this.btnFixDataClick
                                                    }
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    Fix Data
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                {/* {fetchMessage && (
                                <Alert
                                    message={fetchMessage}
                                    type="success"
                                    showIcon

                                    />

                            )} */}

                                {!jiraTicketAlertClose &&
                                    jiraDetails.length > 0 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 8,
                                            }}
                                        >
                                            {jiraDetails.map((e) => {
                                                return (
                                                    <Alert
                                                        key={e.jiraId}
                                                        message={e.alertMessage}
                                                        type="info"
                                                        showIcon
                                                        closable
                                                        closeIcon={
                                                            <CloseOutlined
                                                                style={{
                                                                    color: "ash",
                                                                }}
                                                            />
                                                        }
                                                        style={{
                                                            marginLeft: 52,
                                                            marginRight: 52,
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}

                                <br />
                                <div>
                                    {stepperVisible && (
                                        <div
                                            style={{
                                                marginLeft: 52,
                                                marginRight: 52,
                                            }}
                                        >
                                            <Steps current={currentStage}>
                                                {activeArray.map((e, index) => (
                                                    <Step
                                                        title={e}
                                                        icon={
                                                            currentStage ===
                                                                index ? (
                                                                <Spin />
                                                            ) : null
                                                        }
                                                    />
                                                ))}
                                            </Steps>
                                        </div>
                                    )}
                                </div>

                                <h2
                                    style={{
                                        fontSize: "1.5em",
                                        paddingTop: 6,
                                        marginTop: 32,
                                        marginLeft: 52,
                                    }}
                                >
                                    Results
                                </h2>
                                <br />
                                <div>
                                    {apiProgress?.ready && (
                                        <Table
                                            columns={tableData?.columnDef}
                                            dataSource={tableData?.dataSource}
                                            locale={{
                                                emptyText: "No Data",
                                            }}
                                            bordered
                                            pagination={false}
                                            style={{
                                                marginLeft: 52,
                                                marginRight: 52,
                                            }}
                                        />
                                    )}
                                </div>
                                {apiProgress?.error &&
                                    this.state.errorType === "alert" && (
                                        <Alert
                                            message={apiProgress?.error?.includes('Access denied') ? "Access denied" : apiProgress.error}
                                            description={apiProgress?.error?.includes('Access denied') ? apiProgress?.error?.split("Access denied. ")[1] : undefined}
                                            type="error"
                                            showIcon
                                            closable
                                            closeIcon={
                                                <CloseOutlined
                                                    style={{
                                                        color: "ash",
                                                    }}
                                                />
                                            }
                                            style={{
                                                marginLeft: 52,
                                                marginRight: 52,
                                            }}
                                        />
                                    )}
                                {apiProgress?.error &&
                                    this.state.errorType !== "alert" && (
                                        <Alert
                                            message="Error"
                                            description={apiProgress.error}
                                            type="error"
                                            showIcon
                                            closable
                                        />
                                    )}

                                <br />
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }
}

function InputWrapper(props) {
    const { name, placeholder, label, onChange, disabled, ...otherProps } = {
        ...props,
    };
    return (
        <div className="flex">
            <div style={{ fontSize: 14, flexBasis: "125px" }}>{label}</div>
            <Input
                disabled={disabled}
                onChange={(e) => onChange(name, e.target.value)}
                placeholder={placeholder}
                {...otherProps}
                style={{ flex: 1, marginLeft: 18 }}
            />
        </div>
    );
}

// function DropDown(props) {
//     const {
//         menuItems = [],
//         onChange,
//         defaultValue,
//         name,
//         label,
//         mode,
//         loading,
//         disabled = false,
//     } = { ...props };
//     const selectRef = useRef(null);

//     return (
//         <div className="flex">
//             <div
//                 style={{ fontSize: "18", marginRight: "20px", flexBasis: "125px" }}
//             >
//                 {label}
//             </div>
//             <div className={"dropdown-wrapper"} ref={selectRef}>
//                 <Select
//                     placeholder={"Please select"}
//                     mode={mode}
//                     disabled={disabled}
//                     defaultValue={defaultValue}
//                     loading={loading ? true : false}
//                     notFoundContent={"No Data"}
//                     onChange={(value, label) => onChange(name, value, label)}
//                     style={{ fontSize: "12px" }}
//                 >
//                     {menuItems.map((e) => (
//                         <Option
//                             key={e}
//                             value={e}
//                             style={{
//                                 width: selectRef?.current?.offsetWidth,
//                                 overflow: "hidden",
//                                 fontSize: "12px"
//                             }}
//                         >
//                             {e}
//                         </Option>
//                     ))}
//                 </Select>

//             </div>
//         </div>
//     );
// }
export function DropDown(props) {
    const {
        menuItems = [],
        onChange,
        defaultValue,
        name,
        label,
        mode,
        loading,
        disabled = false,
        showSearch,
        placeholder,
        grouped = false,
        ...otherProps
    } = { ...props };
    const selectRef = useRef(null);

    return (
        <div className="flex">
            <div
                style={{
                    fontSize: "18",
                    marginRight: "20px",
                    flexBasis: "125px",
                }}
            >
                {label}
            </div>
            <div className={"dropdown-wrapper"} ref={selectRef}>
                <Select
                    placeholder={placeholder || "Please select"}
                    mode={mode}
                    showSearch={showSearch}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    loading={loading ? true : false}
                    notFoundContent={"No Data"}
                    onChange={(value, label) => onChange(name, value, label)}
                    {...otherProps}
                    style={{ fontSize: "12px" }}
                >
                    {grouped
                        ? menuItems.map((e, index) => (
                            <OptGroup label={e.schemaName} key={index}>
                                {e.tables.map((tablename, index1) => (
                                    <Option
                                        key={index1}
                                        value={tablename}
                                        style={{
                                            width: selectRef?.current
                                                ?.offsetWidth,
                                            overflow: "hidden",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {tablename}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))
                        : menuItems.map((e) => (
                            <Option
                                key={e}
                                value={e}
                                style={{
                                    width: selectRef?.current?.offsetWidth,
                                    overflow: "hidden",
                                    fontSize: "12px",
                                }}
                            >
                                {e}
                            </Option>
                        ))}
                </Select>
            </div>
        </div>
    );
}
const InfoPopover = ({ children }) => (
    <Popover
        placement="right"
        // trigger={"click"}
        content={<InfoPopoverContent />}
    >
        {children}
    </Popover>
);
const InfoPopoverContent = () => (
    <div className={"popover-cont"}>
        <p style={{ fontSize: 13 }}>
            Audit tool can verify a single record for correctness among chosen
            environments. If discrepency is identifed, that record can be fixed
            with respect to Source Data such as SFDC. Source data is the
            reference (golden) data.
        </p>
    </div>
);

const CardPopover = ({ children }) => (
    <div>
        <Popover
            placement="leftBottom"
            // trigger={"click"}
            content={<PopoverContent />}
        >
            {children}
        </Popover>
    </div>
);

const PopoverContent = () => (
    <div>
        <iframe
            width="760"
            height="415"
            src="https://www.youtube.com/embed/Fax9LulAhks"
            // src="https://www.youtube.com/embed/0VldvOAP9ck"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
        ></iframe>
    </div>
);

export default DataAudit;
