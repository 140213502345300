import React, { useState } from 'react';
import {  Popconfirm, Table, Input, notification } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons'
import { useNextGenCreateProject } from '../../hooks'
import axios from 'axios';

import { SERVER_URL } from "../../config/config";
const SourceTable = (props) => {
    const [selectedValue, setSelectedValue] = useState("")
    const { projectDetails,setProjectDetails } = useNextGenCreateProject()
    const handleDelete = (record) => {
      let uname = localStorage.getItem("email");
      axios
        .post(`${SERVER_URL}/deleteDataSourceModel`, {
          project_id: projectDetails?.project_id,
          conn_name: record?.sourceName,
          user: uname,
        })
        .then((resp) => {
          notification.success({
            message: "Source Deletion successful.",
            duration: 10,
          });

          setProjectDetails((current) => ({
            ...current,
            sourceDetails: current?.sourceDetails?.filter(
              (item) => item.id !== record.id
            ),
          }));
        })
        .catch((err) => {
          notification.error({
            message: "something went wrong please try again.",
            duration: 10,
          });
        });
    };

    
    const columns = [
        {
            title: 'Name',
            dataIndex: 'sourceName',
            sorter: (a, b) => {
                a = String(a.sourceName) || "";
                b = String(b.sourceName) || "";

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            },
            render: (value) => {
                const name = value.split('@')[0].split('.').map(e => {
                    return e.charAt(0).toUpperCase() + e.slice(1);
                }).join(' ')
                return name
            },
            filteredValue: [selectedValue],
            onFilter: (value, record) => {
                return (
                    record?.sourceName?.toLowerCase().includes(value.toLowerCase())
            )}
        },
        {
            title: 'Database',
            dataIndex: 'databaseName',
            sorter: (a, b) => {
                a = String(a.databaseName) || "";
                b = String(b.databaseName) || "";

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            },
            render: (value) => {
                return value || 'N/A'
            },

        },
        {
            title: 'Database Type',
            dataIndex: 'databaseProperty',
            sorter: (a, b) => {
                a = String(a.databaseProperty) || "";
                b = String(b.databaseProperty) || "";

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Host Name',
            dataIndex: 'hostName',
            sorter: (a, b) => {
                a = String(a.hostName) || "";
                b = String(b.hostName) || "";

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            },
            render: (value) => {
                return value || 'N/A'
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) =>
                    projectDetails?.sourceDetails?.length >= 1 ? (
                    <div style={{ display: 'flex', alignItems: "center", gap: 16 }}>
                        <EditOutlined  disabled/>
                        <Popconfirm title="Are you sure you want to delete this source?" onConfirm={() => handleDelete(record)}>
                            <DeleteOutlined style={{ color: "red" }} />
                        </Popconfirm>
                    </div>
                ) : null,


        },
    ];
    
    return (
        <div style={{ marginTop: "18x" }}>
            {projectDetails?.sourceDetails?.length > 0 &&(<>
            <Input width={"750px"} placeholder={"Type name to filter"} onChange={(e) => setSelectedValue(e.target.value)} prefix={<SearchOutlined style={{ color: "grey" }} />} /><br />
            <Table columns={columns} dataSource={projectDetails?.sourceDetails} />
            </>)
            }
        </div>
    )
}

export default SourceTable;


