export const information = {
    EDW: "This schema has Support Readiness, Pulse and Accounting Business Data.\n Quality metric reported here represents a measure of data equivalence between Production and Stage envs.",
    JIRA: "This schema has Jira data. Products and Services life cycle is managed via Jira.\n Quality metric reported here represents a measure of data equivalence between Production and Stage envs.",
    PULSE: "This schema has Pulse data from Nutanix Customer owned clusters. Pulse data enables us to provide context aware, best in class customer-support. Quality metric reported here represents a measure of data equivalence between Production and Stage envs.",
    GSO: "This schema has curated Netsuite data. This data is used for Flex Credit allocation and Automation services to customers.",
    MANUFACTURING: "This schema has data related to manufacturing operations. The data is used for monitoring all aspects of operation, especially during the Qtr end period.",
    "google analytics": "This schema has visitors online behavioral data for Support Portal.",
    XACTLY: "This schema has data from Xactly Cloud service. The data is about Sales Compensation.",
    ZUORA: "This schema has billing Data from Zuora Cloud Service. This data is about Nutanix subscription products billing.",
    SFDC: "Schema contains data of Sales Force application.",
    NETSUITE:"Schema contains data of Netusite application.",
    CSM:"This schema data is used by Customer Success, Sales team, Product teams, in understanding the adoption and utilization of Nutanix products.",
    FactoryData:"This schema data is used by manufacturing team, to understand the factory EOD report shipments.",
}

