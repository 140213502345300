import React from "react";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import { routes } from "./config/routes";
import Authorization from "./Authorization/Authorization";
import ErrorBoundary from "./Components/ErrorBoundary";
import PageFooter from "./Containers/PageFooter";
import { Layout } from "antd";
import PageHeader from "./Containers/PageHeader";
import SideBar from "./Components/SideBar";
import "@nutanix-ui/prism-reactjs/dist/index.css";
const { Content } = Layout;
import { UserProvider } from "./contexts/UserContext/UserContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <Layout>
              <PageHeader />
              <Layout>
                <SideBar />
                <Content>
                  <Switch>
                    {routes.map((routeConfig) => {
                      const {
                        id,
                        path,
                        component: childComponent,
                      } = routeConfig;
                      const component = Authorization(childComponent);
                      const route = {
                        path,
                        component,
                      };
                      // console.log("component");
                      return (
                        // <HashRouter>
                        <Route key={id} {...route} exact />
                        // </HashRouter>
                      );
                    })}
                  </Switch>
                </Content>
              </Layout>
              <PageFooter />
            </Layout>
          </ErrorBoundary>
        </BrowserRouter>
      </UserProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export default App;
