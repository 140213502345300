import React, { useEffect, useState, useContext } from "react";

import UserContext from "../../../../contexts/UserContext/UserContext"  // Context for user state
import {
    Modal, Button, CloseIcon,
    StackingLayout, FlexLayout,
    Notification, NotificationTrigger
} from '@nutanix-ui/prism-reactjs';

import TemplateForm from "../TemplateForm/TemplateForms";
import './styles.css';
import { createTemplate, getTemplateTableData } from "../../../../Service/fileUploadUtility";
import { TemplateDataContext, TemplateFormContext } from "../../FileTemplateUploadDashboard";

export default function CreateTemplateModal(props) {

    return <>
        <TemplateModal visible={props.visible} modalClose={props.modalClose} />
        <Notification className="alert modal " data-testid="notifications">
            <div
                style={{
                    position: 'fixed',
                    top: '80px',
                    right: '340px'
                }}

            />
        </Notification>
    </>

}

function TemplateModal({ visible, modalClose }) {

    const [visibleC, setVisible] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const maxStepIndex = 3;
// eslint-disable-next-line 
    const [templateData, setTemplateData] = useContext(TemplateDataContext);
    const [formData, setFormData] = useContext(TemplateFormContext);

    useEffect(() => {
        setVisible(visible);
        getTemplateTableData()
            .then(resp => {
                setTemplateData(resp);
            })
            .catch(err => {
                window.location.replace('/error')
            })
// eslint-disable-next-line 
    }, [visible]);

    const onNextClick = () => {
        setCurrentTab(prevState => prevState + 1);
    }

    const onPrevClick = () => {
        setCurrentTab(prevState => prevState - 1);
    }

    const { user } = useContext(UserContext);
    const handleSubmit = () => {
        try {
            setVisible(false);
            NotificationTrigger.add({
                id: 'upload success',
                className: 'successAlert',
                message: `Template Creation Request has been sent.`,
                type: 'info',
                autoDismissDelaySecs: 60,
            });
            createTemplate(formData,user)
                .then(resp => {
                    if (resp.error) {
                        NotificationTrigger.add({
                            id: 'template-upload-failed-file-error',
                            message: `Template has failed to upload due to ${resp.error}`,
                            type: 'error',
                            autoDismissDelaySecs: 30,
                        });
                    } else {
                        NotificationTrigger.add({
                            id: 'template-upload-success',
                            className: 'successAlert',
                            message: 'Template has been added successfully',
                            type: 'success',
                            autoDismissDelaySecs: 30,
                        });
                    }
                    setFormData({
                        aggregator: '',
                        columnsData: [],
                        users: [],
                        desc: '',
                        templateName: '',
                        validation: '',
                        isError: false
                    });
                    setCurrentTab(0);
                    modalClose();
                });
        } catch (err) {
            setLoading(false);
        }
    }

    const renderNavButtons = () => {
        let prevButton = (
            <Button onClick={onPrevClick} data-testid='backButton'
                type="secondary" background="#FFFFFF">
                Back
            </Button>
        );
        let nextButton = <Button onClick={onNextClick} background="#22A5F7" data-testid='nextButton'>Next</Button>;
        if (currentTab === 0) {
            prevButton = <Button onClick={handleClose} type="secondary" background="#FFFFFF" data-testid='cancelButton'>Cancel</Button>;
        } else if (currentTab === maxStepIndex) {
            nextButton = <Button data-testid="addButton" disabled={formData.isError} onClick={handleSubmit}>Submit</Button>;
        }

        return (
            <FlexLayout justifyContent="flex-end">
                {prevButton}
                {nextButton}
            </FlexLayout>
        );
    }

    const handleClose = () => {
        setVisible(false);
        modalClose();
    }

    return <Modal
        visible={visibleC}
        width={'60%'}
        title={<h3 style={{ textAlign: 'start' }}>Create a Template</h3>}
        onClose={handleClose}
        primaryButtonOnClick={handleClose}
        headerActions={[
            <FlexLayout
                htmlTag="button"
                key="cancel-btn"
                onClick={handleClose}
                type={Button.ButtonTypes.ICON_DEFAULT}
            >
            </FlexLayout>,
            <FlexLayout
                htmlTag="button"
                key="save-btn"
                onClick={handleClose}
                data-testid='modalCloseButton'
                type={Button.ButtonTypes.ICON_DEFAULT}
            >
                <CloseIcon key="close" />
            </FlexLayout>
        ]}
        footer={renderNavButtons()}
    >
        <>
            <StackingLayout padding="20px" className="tabsStack">
                <TemplateForm current={currentTab} setCurrent={setCurrentTab} isLoading={isLoading} />
            </StackingLayout>
        </>
    </Modal>
}