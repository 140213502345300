import React, { useEffect, useMemo, useState } from "react";
import CodeCoverageFilter from "../Filters/CodeCoverageFilter";
import Widget from "../WidgetContainer/Widget";
import { Loader } from "@nutanix-ui/prism-reactjs";
import { useSelector } from "react-redux";
import CurrentCoverage from "../Graphs/CurrentCoverage";
import CurrentCoverageFooter from "../WidgetContainer/CurrentCoverageFooter";
import AutomationCoverage from "../Graphs/AutomationCoverage";
import AutomationTest from "../Graphs/AutomationTest";
import {
  transformAutomationCoverage,
  transformDonutCoverageData,
} from "../../EngineeringUtils";

const CodeCoverage = () => {
  const { loading, automationCoverage, filters } = useSelector(
    (state) => state.enggDashboard
  );
  // Memoized data transformation to avoid unnecessary recalculations
  const data = useMemo(() => {
    return automationCoverage?.length > 0
      ? transformAutomationCoverage(automationCoverage, filters)
      : [];
  }, [automationCoverage, filters]);

  // Ensure donutData is valid and not null
  const donutData = data.length > 0 ? data[0] : [];

  // Memoized transformation of donut data, with a null check
  const currentCoverageData = useMemo(() => {
    return donutData ? transformDonutCoverageData(donutData) : [];
  }, [donutData]);

  return (
    <div className="tabContainer">
      <div className="filterContainer">
        <CodeCoverageFilter />
      </div>
      <div className="widgetContainer">
        <div className="widgetItems" style={{ width: "20%" }}>
          <Loader
            loading={loading.currentCoverage}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Current Coverage"
              id="currentCoverage"
              body={<CurrentCoverage donutData={currentCoverageData} />}
              footer={<CurrentCoverageFooter donutData={currentCoverageData} />}
            />
          </Loader>
        </div>
        <div className="widgetItems" style={{ width: "80%" }}>
          <Loader
            loading={loading.automationCoverage}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title={<p>Automation Coverage Trend  <span className="titleSubText">{`Threshold: 80%`}</span></p>}
              id="automationCoverage"
              body={<AutomationCoverage />}
            />
          </Loader>
        </div>
      </div>
      <div className="widgetContainer">
        <div className="widgetItems full">
          <Loader
            loading={loading.automationTestCoverage}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title={<p>Automation Tests Pass Rate  <span className="titleSubText">{`Threshold: 90%`}</span></p>}
              id="automationTest"
              // width="100%"
              body={<AutomationTest />}
              // footer={<AutomationTestFooter />}
            />
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default CodeCoverage;
