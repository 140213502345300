import React, { useState, useEffect } from 'react';
import {
    FlexLayout,
    DownloadIcon, Select
} from '@nutanix-ui/prism-reactjs';

import { downloadTemplate } from '../../../../utils/csvDownloadUtlity';

import { getTemplateData } from '../../../../Service/fileUploadUtility';

export default function TemplateSelect(props) {
    const [selectedRow, setSelectedRow] = useState(null);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTemplateData()
        .then(resp => {
            setData(resp);
            setLoading(false);
        })
        .catch(err => {
            window.location.replace('/error')
        })
    }, []);

    const handleOnSelectedChange = (row) => {
        setSelectedRow(row);
        props.handleTemplateSelect(row);
    }

    const handleDownloadClick = (row) => {
        downloadTemplate(selectedRow);
    }

    return <Select style={{ maxWidth: '440px', width: '300px' }}
        rowsData={data}
        placeholder='Select Template Type'
        data-testid="templateSelect"
        onSelectedChange={handleOnSelectedChange}
        selectedRow={selectedRow}
        loading={loading}
        addonAfter={
            <DownloadIcon onClick={handleDownloadClick} data-testid="downloadIcon"/>
        }
        rowRenderer={(row) => <div data-testid="templateRows">{row.label}</div>}
    />
}