import React, { useMemo, useState, useEffect } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "@nutanix-ui/recharts";
import { Tooltip as TooltipAnt } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { TextLabel, ThemeManager } from "@nutanix-ui/prism-reactjs";
import {
  getStageColumn,
  getStageDataSource,
  useStageDetail,
  findObjectByKey,
  labelStyle,
  graphHeight,
  createStageData,
  CartesianGridColor,
} from "../../EngineeringUtils";
import {
  setColumnData,
  setDataSourceData,
  setOpenModal,
} from "../../../../features/JiraAnalysis/enggDashboardSlice";
import NoData from "./NoData";

const StagesGraph = ({ margin }) => {
  const dispatch = useDispatch();
  const { defaultView, stagesData, filters, cycleDateRange } = useSelector(
    (state) => state.enggDashboard
  );
  const [param, setParam] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [data, setData] = useState([]);

  const handleBarClick = (data) => {
    setParam(data.stage);
    setEnabled(true);
  };

  const { data: stageData, isLoading } = useStageDetail(
    filters,
    enabled,
    "cycle_time_stages",
    param,
    cycleDateRange
  );

  useEffect(() => {
    if (enabled && !isLoading && stageData.length > 0) {
      dispatch(setColumnData(getStageColumn()));
      dispatch(setDataSourceData(getStageDataSource(stageData)));
      dispatch(setOpenModal(true));
      setEnabled(false);
    }
  }, [stageData, enabled, isLoading]);

  const findStageData = useMemo(() => {
    const key = {
      "Total Day Spent": "total_days_spent",
      Average: "avg_days_spent",
      Median: "median_days_spent",
      "Percentile 90th Day Spent": "percentile_90th_days_spent",
    }[filters.selectedCycleTime];
    return (stage) => {
      if (key && stagesData && stagesData.length > 0) {
        const stageObject = findObjectByKey(
          stagesData,
          "jira_status_derived",
          stage
        );
        return stageObject ? stageObject[key] : null;
      }
      return null;
    };
  }, [stagesData, filters.selectedCycleTime]);

  const formatLabelText = (value) => {
    return `${value && value.toFixed(1)}${defaultView.view_days ? "" : "%"}`;
  };

  useEffect(() => {
    // Usage
    const stages = [
      "Blocked",
      "Development",
      "Code Review",
      "QA Testing",
      "Business UAT",
      "QA/UAT Done",
      "Deployment",
    ];
    if (findStageData) {
      setData(createStageData(stages, findStageData));
    }
  }, [findStageData, stageData]);

  // custom-tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip stages">
          <TextLabel
            display="block"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
            style={{ fontWeight: "bold" }}
          >
            {label}
          </TextLabel>
          <div className="stageTooltip">
            <span className="stageValue">{`${
              payload?.length > 0 && payload[0]?.payload?.dayCount?.toFixed(1)
            }`}</span>
            <span className="stageValue">
              {`${payload?.length > 0 && payload[0]?.payload?.day}`}%
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  // custom-axis
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    const cycleStages = {
      Blocked: "Blocked",
      "Business UAT":
        "In UAT', 'UAT', 'Pending Confirmation', 'Ready for Acceptance",
      "Code Review":
        "Code Review', 'In Code Review', 'Pending Review', 'Code Review and Unit Testing",
      "QA Testing":
        "QA Testing', 'QA Testing in UAT', 'On Stage', 'Pending Testing' AND ('Resolved' and Project == 'LICEX')",
      "QA/UAT Done": "Ready for Stage', 'Ready for UAT",
      Deployment:
        "Ready for Production', 'Pending Deployment', 'Pending deployment (dev-complete)",
      Development:
        "In Progress', 'Unit Testing', 'Dependency on Insights Team','Dependency on DS Team','Git Hub Branch Creation', 'Need Info",
    };
    const getTooltipContent = (value) => {
      for (const stage in cycleStages) {
        if (stage === value) {
          return cycleStages[value];
        }
      }
    };

    return (
      <TooltipAnt title={getTooltipContent(payload.value)}>
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={4}
            dy={0} // Adjusted for vertical centering
            dx={0}
            textAnchor="end"
            fill={ThemeManager.getVar("gray-1")}
            fontSize={14}
            style={{ whiteSpace: "nowrap" }}
          >
            {payload.value}
          </text>
        </g>
      </TooltipAnt>
    );
  };

  return (
    <ResponsiveContainer height={graphHeight} width="100%">
      {stagesData?.length > 0 ? (
        <BarChart
          data={data}
          alignment="none"
          layout="vertical"
          margin={margin}
        >
          <CartesianGrid vertical={true} horizontal={false} stroke={CartesianGridColor} />
          <XAxis
            tickline={true}
            axisLine={false}
            type="number"
            // domain={[0, 100]}
            tickFormatter={(value) => {
              if (value === 0) {
                return 0;
              }
              return defaultView.view_days ? value : `${value}%`;
            }}
          />
          <YAxis
            dataKey="stage"
            type="category"
            interval={0}
            allowDataOveflow={false}
            width={100}
            tick={<CustomizedAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey={defaultView.view_days ? "dayCount" : "day"}
            fill="#8A2BE2"
            barSize={10}
            onClick={(data) => handleBarClick(data)}
          >
            {data.map((entry, index) => (
              <Cell cursor="pointer" fill={entry.color} key={`cell-${index}`} />
            ))}
            {(defaultView?.view_days || defaultView?.view_percentage) && (
              <LabelList
                dataKey={defaultView.view_days ? "dayCount" : "day"}
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
        </BarChart>
      ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default StagesGraph;
