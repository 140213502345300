import {
  Line,
  LineChart,
  LineDot,
  ResponsiveContainer,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
} from "@nutanix-ui/recharts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CartesianGridColor, CustomizedXAxisTick, getDataByPage, graphHeight, labelStyle, RefrenceLineColor, transformAutomationCoverage } from "../../EngineeringUtils";
import NoData from "./NoData";

const AutomationCoverage = () => {
  const { automationCoveragePage, defaultCodeCoverage, automationCoverage } = useSelector(
    (state) => state.enggDashboard
  );
  const [data, setData] = useState([])

  useEffect(() => {
    if(automationCoverage?.length > 0){
      setData([...transformAutomationCoverage(automationCoverage)])
    }
  }, [automationCoverage])


  return (
    <ResponsiveContainer height={graphHeight + 40} width="100%">
      {data.length > 0 ? (
        <LineChart
          margin={{
            top: 20,
            left: 10,
            right: 20,
            bottom: 20,
          }}
          data={getDataByPage(
            data,
            automationCoveragePage.currentPage,
            automationCoveragePage.pageSize
          ).reverse()}
          width={300}
          height={100}
        >
          <CartesianGrid stroke={CartesianGridColor}/>
          <XAxis dataKey="name" tick={<CustomizedXAxisTick />} interval={0} />
          <YAxis
            dataKey="value"
            domain={[1, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />
          <ReferenceLine
            y={80}
            stroke={RefrenceLineColor}
            strokeDasharray="6 6"
            // label={{
            //   value: "Threshold (80%)",
            //   position: "insideBottomLeft",
            //   fill: "#627386",
            // }}
          />
          <Tooltip
            formatter={(value) => {
              if (typeof value === "number") {
                const valueChanged = value.toFixed(1);
                return [`${valueChanged}%`];
              } else {
                // Handle non-numeric values (e.g., return empty array or default message)
                return [`${value}%`]; // Or return ['Could not format value'] for informative message
              }
            }}
          />
          <Line
            type="linear"
            dot={(props) => {
              const { cx, cy, index, stroke } = props;
              return (
                <LineDot color={stroke} cx={cx} cy={cy} key={index} r={3} />
              );
            }}
            dataKey="value"
          >
            {defaultCodeCoverage.default_cc && (
              <LabelList
              dataKey="value"
              position="top"
              style={labelStyle}
              formatter={(value) => {
                if (typeof value === "number") {
                  const valueChanged = value.toFixed(1);
                  return [`${valueChanged}%`];
                } else {
                  // Handle non-numeric values (e.g., return empty array or default message)
                  return [`${value}%`]; // Or return ['Could not format value'] for informative message
                }
              }}
            />
            )}
          </Line>
          
        </LineChart>
      ) : (
        <NoData height={240} />
      )}
    </ResponsiveContainer>
  );
};

export default AutomationCoverage;
