import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilters,
  toggleLoadingCodeCoverage,
} from "../../../../features/JiraAnalysis/enggDashboardSlice";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { ApplicationComponent, generateMenuForDropdown } from "../../EngineeringUtils";

const CodeCoverageFilter = () => {
  const dispatch = useDispatch();
  const { filters, codeCoverageFilter, loadingCodeCoverageFilter } =
    useSelector((state) => state.enggDashboard);

  const codeCoverageDropdownItem = [
    {
      id: "codeCoverageProducts",
      component: `${ApplicationComponent.CODECOVERAGE}`,
      label: "Product",
      mode: "single",
      loading: loadingCodeCoverageFilter,
      items: [
        ...generateMenuForDropdown(
          Object.keys(codeCoverageFilter).length > 0
            ? codeCoverageFilter.products
            : [] // replace product from code coverage filter
        ),
      ],
      selectedItem: filters.selectedCodeCoverageProduct,
      onChangeItem: (item) => {
        dispatch(
          setFilters({ section: "selectedCodeCoverageProduct", value: item })
        );
        dispatch(dispatch(toggleLoadingCodeCoverage(true)));
      },
    },
    {
      id: "codeCoverageViewBy",
      label: "View By",
      mode: "single",
      component: `${ApplicationComponent.CODECOVERAGE}`,
      items: generateMenuForDropdown(["Day", "Week"]),
      selectedItem: filters.viewByCC,
      onChangeItem: (item) => {
        dispatch(setFilters({ section: "viewByCC", value: item }));
        dispatch(dispatch(toggleLoadingCodeCoverage(true)));
      },
    },
  ];
  return (
    <div
      className="filterDropdownContainer filterCustomerContainer"
      data-testid="jira-ssot-filter-container"
    >
      {codeCoverageDropdownItem.map((dropdown) => (
        <FilterDropdown key={dropdown.id} {...dropdown} />
      ))}
    </div>
  );
};

export default CodeCoverageFilter;
