import { Button, RefreshIcon } from "@nutanix-ui/prism-reactjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCodeCoverage, resetCustomerDefect, resetDefaultView, resetDefaultViewVelocity, setCycleDateRange, setFilters, setJiraDateRange, toggleLoadingCfd, toggleLoadingCodeCoverage, toggleLoadingCycle, toggleLoadingVelocity } from "../../../features/JiraAnalysis/enggDashboardSlice";
import moment from "moment";

const Reset = () => {
  const { activeHeaderTab, codeCoverageFilter } = useSelector((state) => state.enggDashboard);
  const dispatch = useDispatch();
  
  const applyFilters = (filtersToSet) => {
    filtersToSet.forEach((filter) => {
      dispatch(setFilters(filter));
    });
  };
  
  // RESET - VELOCITY STATES
  const resetVelocityFilters = () => {
    const filtersToSet = [
      { section: "selectedVelocityProduct", value: ["All"] },
      { section: "selectedVelocityComponent", value: ["All"] },
      { section: "selectedVelocityIssueType", value: ["All"] },
      { section: "selectedVelocityPriority", value: ["All"] },
      { section: "selectedVelocityLabel", value: ["All"] },
      { section: "selectedVelocityAssignee", value: ["All"] },
      { section: "selectedVelocity", value: "Production" },
      { section: "viewByVelocity", value: "Quarterly" },
    ];
    applyFilters(filtersToSet);
    dispatch(toggleLoadingVelocity(true));
    dispatch(resetDefaultViewVelocity());
  };
  // RESET - CYCLE STATES
  const resetCycleFilters = () => {
    const startDate = moment().subtract(3, "month").startOf("day").toDate();
    const endDate = moment().endOf("day").toDate();
    const filtersToSet = [
      { section: "selectedProduct", value: ["All"] },
      { section: "selectedComponent", value: ["All"] },
      { section: "selectedStage", value: ["All"] },
      { section: "selectedIssueType", value: ["All"] },
      { section: "selectedPriority", value: ["All"] },
      { section: "selectedLabel", value: ["All"] },
      { section: "selectedStoryPoint", value: ["All"] },
      { section: "selectedAssignee", value: ["All"] },
      { section: "selectedQAContact", value: ["All"] },
      { section: "selectedJiraIssues", value: ["All"] },
      { section: "selectedTicketProgress", value: "Only Closed" },
      { section: "selectedCycleTime", value: "Average" },
      { section: "viewBy", value: "Quarterly" },
    ];
    applyFilters(filtersToSet);
    dispatch(setCycleDateRange({ startDate, endDate }));
    dispatch(setJiraDateRange({ startDate, endDate }));
    dispatch(toggleLoadingCycle(true));
    dispatch(resetDefaultView());
  };
  // RESET - CUSTOMERS STATES
  const resetCustomerFilters = () => {
    const filtersToSet = [
      { section: "selectedCustomerProduct", value: ["All"] },
      { section: "selectedCustomerComponent", value: ["All"] },
      { section: "selectedCustomerIssueType", value: ["All"] },
      { section: "selectedCustomerLabel", value: ["All"] },
      { section: "viewByCfd", value: "Quarterly" },
    ];
    applyFilters(filtersToSet);
    dispatch(resetCustomerDefect());
    dispatch(toggleLoadingCfd(true));
  };
  // RESET - CODE COVERAGE STATES
  const resetCodeCoverageFilters = () => {
    if (codeCoverageFilter?.products && codeCoverageFilter?.products.length > 0) {
      dispatch(
        setFilters({
          section: "selectedCodeCoverageProduct",
          value: codeCoverageFilter.products[0],
        })
      );
    }
    const filtersToSet = [
      { section: "viewByCC", value: "Day" },
    ];
    applyFilters(filtersToSet);
    dispatch(resetCodeCoverage());
    dispatch(toggleLoadingCodeCoverage(true));
  };

  const handleResetFilters = () => {
    switch (activeHeaderTab) {
      case 1:
        resetVelocityFilters();
        break;
      case 2:
        resetCycleFilters();
        break;
      case 3:
        resetCustomerFilters();
        break;
      case 4:
        resetCodeCoverageFilters()
        break;
      default:
      // console.warn("Unhandled tab:", activeHeaderTab);
    }
  };
  return (
    <Button
      className="show_numbers"
      disabled={false}
      type={Button.ButtonTypes.BORDERLESS}
      onClick={handleResetFilters}
    >
      <RefreshIcon /> Reset
    </Button>
  );
};

export default Reset;
