import React from 'react'
import RadioButton from './RadioButton'
import { useDispatch, useSelector } from 'react-redux';
import { toggleCodeCoverageCount } from '../../../../features/JiraAnalysis/enggDashboardSlice';

const CodeCoverageRadioButton = () => {
  const { defaultCodeCoverage } = useSelector((state) => state.enggDashboard);
  const dispatch = useDispatch();
  return (
    <>
      <RadioButton
        name="default_cc"
        title="Default"
        checked={defaultCodeCoverage.default_cc}
        onChange={(e) => dispatch(toggleCodeCoverageCount(e))}
      />
      <RadioButton
        name="hide_cc"
        title="Hide Numbers"
        checked={defaultCodeCoverage.hide_cc}
        onChange={(e) => dispatch(toggleCodeCoverageCount(e))}
      />
    </>
  )
}

export default CodeCoverageRadioButton