export const information = {
    "Stage": {
        "DATABASE (POSTGRES)": {
            Uptime: "Percentage of the duration database was alive.",
            Availability: "Percentage of the time database had nominal response time."
        },
        "ETL (INFORMATICA)": {
            Uptime: "Percentage of the duration ETL server was alive.",
            Availability: "Percentage of the duration ETL server had nominal response time."
        },
        "TABLEAU": {
            Uptime: "Percentage of the duration Tableau server was alive.",
            Availability: "Percentage of the duration Tableau server had nominal response time."
        },
        "P0 Tickets": {
            P0: "Number of tickets in 'Open' state, number of tickets with SLA missed."
        },

    },
    "Production": {
        "DATABASE (POSTGRES)": {
        Uptime: "Percentage of the duration database was alive.",
        Availability: "Percentage of the duration database had nominal response time."
        },
        "ETL (INFORMATICA)": {
            Uptime: "Percentage of the duration ETL server was alive.",
            Availability: "Percentage of the duration ETL server had nominal response time."
        },
        "DAAS (DREMIO)": {
            Uptime: "Percentage of the duration DaaS Cluster was alive.",
            Availability: "Percentage of the duration DaaS server had nominal response time."
        },
        "TABLEAU": {
            Uptime: "Percentage of the duration Tableau server was alive.",
            Availability: "Percentage of the duration Tableau server had nominal response time."
        },
    },
}