import React from "react";
import {
    Popover,
    Button,
    Modal,
    Input,
    Select,
    notification,
    Spin,
} from "antd";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import greenLogo from "../../images/heart-green.png";
import redLogo from "../../images/heart-red.png";
import yellowLogo from "../../images/heart-yellow.png";
import { SERVER_URL } from "../../config/config";
import axios from "axios";
import LineageModal from "../Datalineage2/LineageModal";
import Logger from "../../config/logger";
const { TextArea } = Input;
const { Option } = Select;
class FavouriteReportCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            summary: "",
            description: "",
            priority: "",
            loading: false,
            lineageVisible: false,
            lineageData: [],
        };
    }

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
    };
    handleValueChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleCancel = () => {
        this.setState({ isModalVisible: false });
        this.setState({ summary: "", description: "", priority: "" });
    };

    handleLineageVisible = () => this.setState({ lineageVisible: false });

    createJira = async () => {
        const DATA_AUDIT = `${SERVER_URL}/data-audit`;

        try {
            this.setState({ loading: true });
            let uname = localStorage.getItem("email")?.split("@")[0];
            let jiraRequest = {
                ticket_heading: this.state.summary,
                ticket_description: this.state.description,
                ticket_assignee: "prabhakaran.s@nutanix.com",
                ticket_priority: this.state.priority,
                labels_list: ["Tableau_Dashboard"],
                watchers_list: [uname],
            };
            const response = await axios.post(
                `${DATA_AUDIT}/jira_create/DS`,
                jiraRequest
            );
            this.setState({
                summary: "",
                description: "",
                priority: "",
                isModalVisible: false,
                loading: false,
            });
            notification.success({
                message: (
                    <p>
                        <a
                            target="_blank"
                            href={`https://jira.nutanix.com/browse/${response.data.key}`}
                        >
                            {response.data.key}
                        </a>{" "}
                        Jira created successfully
                    </p>
                ),
                placement: "top",
                duration: 10,
            });
        } catch (e) {
            this.setState({ loading: false });
            notification.error({
                message: "Unable to connect to Jira",
                placement: "top",
                duration: 10,
            });
        }
    };

    openLineageDataModal = async () => {
        const { reportName, id } = this.props;
        try {
            Logger.info(`Fetching Datalineage for report : ${reportName}`,  "tableau_dashboard_client", window.location.href);
            const {
                data: { lineageData },
            } = await axios.post(`${SERVER_URL}/datalineage?reportID=${id}`);
            Logger.info(`Fetched Datalineage for Report successfully`,  "tableau_dashboard_client", window.location.href);

            if (lineageData.length !== 0)
                this.setState({ lineageData, lineageVisible: true });
            else
                notification.info({
                    message: `No Lineage Data for report ${reportName}`,
                    placement: "top",
                    duration: 10,
                });
        } catch (e) {
            Logger.error(e,  "tableau_dashboard_client", window.location.href);
        }
    };

    render() {
        const {
            reportName,
            dataQualityPercentage,
            reportUrl,
            onFavChange,
            isFav = false,
            id,
            projectName,
            onReportClick,
            lastSuccessfulRun,
        } = this.props;
        const { lineageVisible, lineageData } = this.state;
        const LineageModalTitle = lineageData[0]?.workbook_name || "";

        let imgUrl = greenLogo;
        let className = "";
        if (dataQualityPercentage < 96) {
            imgUrl = redLogo;
            className = "heart";
        } else if (dataQualityPercentage >= 96 && dataQualityPercentage <= 99) {
            imgUrl = yellowLogo;
            className = "heart";
        } else if (dataQualityPercentage > 99) {
            imgUrl = greenLogo;
            className = "animated pulse infinite";
        }

        return (
            <div
                data-testid="favourite-report-card-container"
                style={{
                    overflow: "auto",
                    width: "100%",
                    backgroundColor: "rgb(239 241 241)",
                    padding: "8px",
                }}
            >
                <LineageModal
                    title={"Data - " + LineageModalTitle}
                    visible={lineageVisible}
                    handleModalVisible={this.handleLineageVisible}
                    lineageData={lineageData}
                    fromCard={true}
                />
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    data-testid="Lineage-container"
                >
                    <p style={{ flexBasis: "25%", margin: 0 }}>{projectName}</p>
                    <p
                        style={{
                            margin: 0,
                            flexBasis: "45%",
                            fontWeight: 600,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            onReportClick({
                                url: reportUrl,
                                name: reportName,
                                id,
                                isFav: true,
                            })
                        }
                    >
                        {reportName}
                    </p>

                    <div style={{ flexBasis: "17%", margin: 0 }}>
                        {lastSuccessfulRun ? (
                            lastSuccessfulRun
                        ) : (
                            <Popover
                                trigger={"hover"}
                                content={
                                    <PopoverContent
                                    text={"Extract not Scheduled"}
                                    />
                                }
                                placement="right"
                            >
                                NA
                            </Popover>
                        )}
                    </div>
                    {imgUrl === redLogo && (
                        <div>
                            <Button
                                type="primary"
                                size="small"
                                className="jiraButton"
                                onClick={this.showModal}
                                data-testid='jiraButton'
                            >
                                Create Jira
                            </Button>
                            <Modal
                                title={<b>Create Jira</b>}
                                open={this.state.isModalVisible}
                                footer={null}
                                onCancel={this.handleCancel}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        marginTop: "8px",
                                    }}
                                >
                                    <p style={{ flexBasis: "30%" }}>
                                        Summary
                                        <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <Input
                                        type="text"
                                        placeholder="Summary"
                                        name="summary"
                                        value={this.state.summary}
                                        onChange={this.handleValueChange}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        marginTop: "8px",
                                    }}
                                >
                                    <p style={{ flexBasis: "30%" }}>
                                        Priority
                                        <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <Select
                                        defaultValue="p1"
                                        style={{
                                            flex: 1,
                                            marginLeft: "-36px",
                                        }}
                                        name="priority"
                                        value={this.state.priority}
                                        onChange={(value) =>
                                            this.handleValueChange({
                                                target: {
                                                    name: "priority",
                                                    value: value,
                                                },
                                            })
                                        }
                                    >
                                        <Option value="1">P1</Option>
                                        <Option value="2">P2</Option>
                                        <Option value="3">P3</Option>
                                        <Option value="4">P4</Option>
                                        <Option value="5">P5</Option>
                                    </Select>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        marginTop: "8px",
                                    }}
                                >
                                    <p style={{ flexBasis: "30%" }}>
                                        Description
                                        <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <TextArea
                                        placeholder="Description"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.handleValueChange}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: "16px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        className="jiraButton"
                                        data-testid='submitButtoon'
                                        onClick={this.createJira}
                                        disabled={
                                            !(
                                                this.state.summary &&
                                                this.state.description
                                            ) || this.state.loading
                                        }
                                        style={{ width: "100px" }}
                                    >
                                        {this.state.loading ? (
                                            <Spin />
                                        ) : (
                                            "SUBMIT"
                                        )}
                                    </Button>
                                </div>
                            </Modal>
                        </div>
                    )}
                    <div
                        style={{
                            flexBasis: "13%",
                            display: "flex",
                            alignItems: "center",
                            gap: "32px",
                        }}
                    >
                        <Popover
                            trigger={"hover"}
                            title={<PopoverTitle title={"Data Health"} />}
                            data-testid='dataLineageButton'
                            content={
                                <PopoverContent
                                    text={`Data Quality: ${dataQualityPercentage}%`}
                                />
                            }
                            placement="right"
                        >
                            <img
                                style={{ width: 20, cursor: "pointer" }}
                                className={className}
                                src={imgUrl}
                                alt={"heart-icon"}
                                data-testid='dataLineageButtonClickable'
                                onClick={this.openLineageDataModal}
                            />
                        </Popover>
                        <div
                            onClick={() => onFavChange(id, isFav)}
                            date-testid="favbutton"
                            style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "#ac8450",
                            }}
                        >
                            {isFav ? <StarFilled /> : <StarOutlined />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const PopoverTitle = ({ title }) => (
    <h4>
        <b>{title}</b>
    </h4>
);

const PopoverContent = ({ text }) => <div>{text}</div>;

export default FavouriteReportCard;
