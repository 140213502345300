import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemTextArea,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  FlexLayout,
  TextLabel,
  PlusIcon,
  FlexItem,
  CloseIcon
} from "@nutanix-ui/prism-reactjs";

import axios from "axios";
import { SERVER_URL } from "../../config/config";

import { useLocation } from "react-router-dom";

export default function PostGresModalEdit(props) {
    const location = useLocation();
    const { schema, data, handleOnSave, origin } = props;
    const DEFAULT_VALIDATIONS = [
      {
        tableName: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Table Name is required",
        },
      },
      {
        operation: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Operation is required",
        },
      },
      {
        start_offset: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Starting Offset is required",
        },
      },
      {
        query: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Query is required",
        },
      },
    ];
  
    const {
      alias,
      array_cols,
      id,
      key_cols,
      offset_col,
      query,
      db,
      schemaValue,
      schema_mapping,
      dbType,
      schemaForm,
      tableName,
      operation,
      start_offset,
      uuid_columns,
      source_cluster_uuid,
      truncate_and_load
    } = data;
    const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    useEffect(() => {
      if (origin === "Source" && location.state?.scheduleType === "streaming") {
        validations.pop();
        setValidations(validations);
      }
      if (origin === "Target" ) {
        validations.splice(2,1);
        setValidations(validations);
      }
      let payload = {
        project_id: projectID,
        conn_name: data?.schemaValue,
        schema_name: data?.schemaForm?.label,
        object_name: data?.tableName?.label,
      };
      getTableColumns(payload);// eslint-disable-next-line 
    }, [data]);
    
    const [selectedTableName, setSelectedTableName] = useState(null);
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
    const [schemaAndTableData, setSchemaAndTableData] = useState([]);
  
    const [schemaData, setSchemaData] = useState([]);
    const [searchSchemaData, setSearchSchemaData] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState(null);
  
    const [tableNameData, setTableNameData] = useState([]);
    const [searchTableNameData, setSearchTableNameData] = useState([]);// eslint-disable-next-line 
    const [tableLoading, setTableLoading] = useState(true);
  
    const [schemaLoading, setSchemaLoading] = useState(true);
    const [selectedTableColsName, setSelectedTableColsName] = useState(null);
    const [tableColsNameData, setTableColsNameData] = useState([]);
    const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
  
    const [tableColsLoading, setTableColsLoading] = useState(true);
    const [schemaText, setSchemaText] = useState("");
    let schemaMappingData = schema_mapping
        ? typeof schema_mapping ==='string'?JSON.parse(schema_mapping) :schema_mapping
        : [];
    const [schemaRowsData, setSchemaRowsData] = useState(schemaMappingData);
   const startOffsetDataArray = [
      { key: "earliest", label: "earliest"  ,rowProps:{
        'data-testid':'earliest'
       }},
      { key: "latest", label: "latest" ,rowProps:{
        'data-testid':'latest'
       }},
    ];
    const [searchStartOffsetData, setSearchStartOffsetData] = useState(startOffsetDataArray);
    const [selectedStartOffset, setSelectedStartOffset] = useState(null);
    const operationDataArray = [
      {
        key: "append",
        label: "Append",
        rowProps:{
          'data-testid':'Append'
         }
      },
      {
        key: "upsert",
        label: "Upsert",
        rowProps:{
          'data-testid':'Upsert'
         }
      },
    ];
    const [searchOperationData, setSearchOperationtData] = useState(operationDataArray);
    useEffect(() => {
      setSelectedTableName((prevState) => {
        return {
          key: data?.tableName?.key || '',
          label: data?.tableName?.label || '',
        };
      });
      setSelectedOperation((prevState) => {
        return {
          key: data?.operation?.key,
          label: data?.operation?.label,
        };
      });
      setSelectedSchema((prevState) => {
        return {
          key: data?.schemaForm?.key,
          label: data?.schemaForm?.label,
        };
      });
      setSelectedOffsetCol((prevState) => {
        return {
          key: data?.offset_col ? data?.offset_col?.key : "",
          label: data?.offset_col ? data?.offset_col?.label : "",
        };
      });
      setSelectedStartOffset((prevState) => {
        return {
          key: data?.start_offset ? data?.start_offset?.key : "",
          label: data?.start_offset ? data?.start_offset?.label : "",
        };
      });
      fetchSchemaAndTable();
      setSchemaRowsData(schemaMappingData);// eslint-disable-next-line 
    }, [data]);
   
    const fetchSchemaAndTable = async(value) => {
      if (schemaValue) {
          try{
            await axios
                .post(`${SERVER_URL}/fetchSchemaAndTable`, {
                  project_id: projectID,
                  conn_name: schemaValue ? schemaValue : "pg_stg_r1",
                })
                .then((resp) => {
                  if(resp.status===200){
                    constructSchemaData(resp?.data?.data?.res);
                  }
                })
      } catch(err){
       
      }
      }
    };
  
    const constructSchemaData = (res) => {
      setSchemaAndTableData((prev) => res);
      let tempData = [];
      let schemaKeys =( res &&  Object.keys(res)) || [];
      schemaKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setSchemaData((prevState) => tempData);
      setSearchSchemaData((prevState) => tempData);
      setSchemaLoading((prevState) => false);
      if (data?.schemaForm?.key) {
        const tableKeys = res &&res[data?.schemaForm?.key] ? Object.keys(res[data?.schemaForm?.key]) : [];
        let tempData1 = [];
        tableKeys.forEach((o) => {
          tempData1.push({
            key: o,
            label: o,
            rowProps:{
              'data-testid':o
             }
          });
        });
        setTableNameData((prevState) => tempData1);
        setSearchTableNameData((prevState) => tempData1);
      }
    };
    const addSchemaRows = async () => {
      let obj = { key: schemaText };
      obj[selectedTableColsName?.label] = obj["key"];
      delete obj["key"];
      schemaRowsData.push(obj);
      setSchemaRowsData(schemaRowsData);
      setSelectedTableColsName(null);
      setSchemaText("");
    };
    const removeSchemaRows = (index) => {
      const newRows = schemaRowsData.filter((sale, i) => i !== index);
      setSchemaRowsData(newRows);
    };
    const handleEditSchemaRows = (objKVType, value, i) => {
      let key = Object.keys(schemaRowsData[i])[0];
      if (objKVType === "value") {
        schemaRowsData[i][key] = value;
      } else {
        schemaRowsData[i][value.label] = schemaRowsData[i][key];
        delete schemaRowsData[i][key];
      }
    };
    const handleTableNameChange = (item) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const projectID = queryParameters.get("projectID");
      let payload = {
        project_id: projectID,
        conn_name: schemaValue,
        schema_name: selectedSchema?.label,
        object_name: item?.label,
      };
      setSelectedTableName((prevState) => item);
      getTableColumns(payload);
    };
    const getTableColumns = (payload) => {
      axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {
        if (resp?.data?.data?.res) {
          setTableColsLoading(false);
          const tableColKeys = Object.keys(resp?.data?.data?.res);
          let TablleColsData = [];
          tableColKeys.forEach((o) => {
            TablleColsData.push({
              key: o,
              label: o,
              rowProps:{
                'data-testid':o
               }
            });
          });
          setTableColsNameData((prevState) => TablleColsData);
          setSearchTableColsNameData((prevState) => TablleColsData);
        }
      });
    };
    const handleOperationChange = (item) => {
      setSelectedOperation((prevState) => item);
    };
  
    const handleSchemaChange = (item) => {
      setSelectedSchema((prevState) => item);
      const tableKeys = schemaAndTableData&&schemaAndTableData[[item.key]] ? Object.keys(schemaAndTableData[item.key]) : [];
      let tempData = [];
      tableKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setTableNameData((prevState) => tempData);
      setSearchTableNameData((prevState) => tempData);
      setTableLoading((prevState) => false);
    };
    const handleOnInputValueChange = (
      event,
      searchArray,
      setSearchArray,
      setSelectedValue
    ) => {
      if (event.target.value.length > 0) {
        if(searchArray.length===0){
          setSchemaLoading(false);
          setTableColsLoading(false)
        }
        const newRows = searchArray?.filter((row) => {
          return row.label
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        });
        newRows.unshift({ key: event.target.value, label: event.target.value })
        setSearchArray(newRows);
        setSelectedValue({ key: event.target.value, label: event.target.value });
      } else if (event.target.value.length === 0) {
        setSearchArray(searchArray);
        setSelectedValue(null);
      }
    };
    return (
      <Modal
        visible={props.visible}
        title={schema || "Schema"}
        onClose={props.handleOnClose}
        closeIconProps={{ "data-test": "my-close-icon-data-test" }}
        footer={false}
      >
        <StackingLayout padding="20px">
          <Form
            key={data?.index}
            validations={validations}
            onSubmit={(values) =>
              handleOnSave(values, schemaRowsData, [])
            }
            defaultValues={{
              id: id,
              alias: alias,
              key_cols: key_cols || "",
              array_cols: array_cols || "",
              offset_col: offset_col || "",
              query:query?.pipeLine?.queryData?.value ? atob(query?.pipeLine?.queryData?.value) : (query || ''), // nested object format,
              db: db,
              schemaForm: schemaForm,
              tableName: tableName,
              operation: operation,
              start_offset: start_offset,
              uuid_columns: uuid_columns,
              source_cluster_uuid: source_cluster_uuid,
              truncate_and_load: truncate_and_load,
              index: data?.index,
            }}
          >
            {(formHelper) => {
              return (
                <FormSection>
                  <FormItemInput
                    id="id"
                    value={id || "N.A"}
                    disabled
                    formHelper={formHelper}
                    label="Input ID"
                  />
                  {origin !== "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgresEdit-alias'
                      id="alias"
                      formHelper={formHelper}
                      label={"Alias"}
                    />
                  )}
                  <FormItemInput
                    id="db"
                    disabled
                    formHelper={formHelper}
                    label="Database"
                  />
                  <FormItemSelect
                    id="schemaForm"
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-schema'
                    selectedRow={selectedSchema}
                    rowsData={searchSchemaData}
                    loading={schemaLoading}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleSchemaChange(item)}
                    label={"Schema"}
                    inputProps={{ value: selectedSchema?.label || "" }}
                    searchable={true}
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        schemaData,
                        setSearchSchemaData,
                        setSelectedSchema
                      )
                    }
                  />
                  <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-tableName'
                    id="tableName"
                    rowsData={searchTableNameData}
                    selectedRow={selectedTableName}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleTableNameChange(item)}
                    label={"Table Name"}
                    inputProps={{ value: selectedTableName?.label || "" }}
                    searchable={true}
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        tableNameData,
                        setSearchTableNameData,
                        setSelectedTableName
                      )
                    }
                  />
                   <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-operation'
                    showSearch={true}
                    id="operation"
                    selected={selectedOperation}
                    rowsData={searchOperationData}
                    value={selectedOperation}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleOperationChange(item)}
                    label={"Operations"}
                    searchable={true}
                    inputProps={
                      { value:selectedOperation?.label || ''}
                    }
                    onChange={(event)=>handleOnInputValueChange(event,operationDataArray,setSearchOperationtData,setSelectedOperation)} 
                  />
                  <FormItemSelect
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-offset_col'
                    id="offset_col"
                    rowsData={searchTableColsNameData}
                    loading={tableColsLoading}
                    formHelper={formHelper}
                    label={"Offset Column"}
                    onSelectedChange={(item) => setSelectedOffsetCol(item)}
                    selected={selectedOffsetCol}
                    inputProps={{ value: selectedOffsetCol?.label || "" }}
                    searchable={true}
                    helpText="Optional"
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        tableColsNameData,
                        setSearchTableColsNameData,
                        setSelectedOffsetCol
                      )
                    }
                  />
                 { origin === "Source" && (
                  <FormItemSelect
                      data-testid = 'paas-etlCanvas-formModal-postgres-start_offset'
                      id="start_offset"
                      showSearch={true}
                      selected={selectedStartOffset}
                      rowsData={searchStartOffsetData}
                      formHelper={formHelper}
                      onSelectedChange={(item) => setSelectedStartOffset(item)}
                      label={"Starting Offset"}
                      inputProps={
                        { value:selectedStartOffset?.label || ''}
                      }
                      searchable={true}
                      onChange={(event)=>handleOnInputValueChange(event,startOffsetDataArray,setSearchStartOffsetData,setSelectedStartOffset)} 
                    />)}
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgresEdit-uuid_columns'
                      id="uuid_columns"
                      formHelper={formHelper}
                      label={"UUID Columns"}
                      helpText="Optional"
                    />
                  )}
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-formModal-postgresEdit-truncate_and_load'
                      id="truncate_and_load"
                      formHelper={formHelper}
                      label={"Truncate And Load"}
                      helpText="Optional"
                    />
                  )}
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-key_cols'
                    id="key_cols"
                    formHelper={formHelper}
                    label={"Keys Column"}
                    helpText="Optional"
                  />
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-formModal-postgresEdit-array_cols'
                    id="array_cols"
                    formHelper={formHelper}
                    label={"Arrays Column"}
                    helpText="Optional"
                  />
                  {origin === "Source" &&
                  location.state?.scheduleType === "streaming" ? (
                    ""
                  ) : (
                    <FormItemTextArea
                      data-testid = 'paas-etlCanvas-formModal-postgresEdit-query'
                      id="query"
                      label="Query"
                      formHelper={formHelper}
                      placeholder="Please Type your query here"
                      style={{ height: "10rem" }}
                    />
                  )}
  
                  {origin === "Source" && (
                    <StackingLayout>
                      <TextLabel
                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                        data-test-id="type-primary"
                      >
                        Schema Configuration
                      </TextLabel>
                        <StackingLayout>
                          {schemaRowsData?.length > 0 &&
                            schemaRowsData?.map((row, i) => (
                              <FlexLayout
                                flexDirection="row"
                                alignItems="flex-end"
                                key={i}
                              >
                                <FormItemSelect
                                  data-testid = {'paas-etlCanvas-formModal-postgres-addColumn'+i}
                                  id={i}
                                  selectedRow={{
                                    key: Object.keys(row)[0],
                                    label: Object.keys(row)[0],
                                  }}
                                  rowsData={searchTableColsNameData}
                                  loading={tableColsLoading}
                                  formHelper={formHelper}
                                  onSelectedChange={(item) =>
                                    handleEditSchemaRows("key", item, i)
                                  }
                                  label={"Add Columns"}
                                />
                                <FormItemInput
                                  data-testid = {'paas-etlCanvas-formModal-postgres-addSchema'+i}
                                  key={i}
                                  label="Add Schema"
                                  placeholder="Add Schema"
                                  value={Object.values(row)[0]}
                                  onChange={(e) =>
                                    handleEditSchemaRows(
                                      "value",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                                <FlexItem>
                                  <Button
                                    data-testid = {'paas-etlCanvas-formModal-postgres-removeRow'+i}
                                    type="secondary"
                                    style={{ backgroundColor: "gray" }}
                                    onClick={() => removeSchemaRows(i)}
                                  >
                                    <CloseIcon
                                      size="small"
                                      aria-hidden="true"
                                      color="secondary"
                                    />
                                  </Button>
                                </FlexItem>
                              </FlexLayout>
                            ))}
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FormItemSelect
                              data-testid = 'paas-etlCanvas-formModal-postgres-addColumn'
                              id="columns Data"
                              selectedRow={selectedTableColsName}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                setSelectedTableColsName(item)
                              }
                              selected={selectedTableColsName}
                              inputProps={{
                                value: selectedTableColsName?.label || "",
                              }}
                              searchable={true}
                              onChange={(event) =>
                                handleOnInputValueChange(
                                  event,
                                  tableColsNameData,
                                  setSearchTableColsNameData,
                                  setSelectedTableColsName
                                )
                              }
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid = 'paas-etlCanvas-formModal-postgres-addSchema'
                              id="schema"
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={schemaText}
                              onChange={(e) => setSchemaText(e.target.value)}
                            />
                            <FlexItem>
                              <Button onClick={addSchemaRows}
                              data-testid = 'paas-etlCanvas-formModal-postgres-addRow-btn'>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                    </StackingLayout>
                  )}
                  <Button onClick={formHelper.handleSubmit} style={{float:'right'}} >Save</Button>
                </FormSection>
              );
            }}
          </Form>
        </StackingLayout>
      </Modal>
    );
  }