import {
    DraggableModal,
    DraggableModalProvider,
} from "ant-design-draggable-modal";
import { Modal, Tabs, Button } from "antd";
import LineageTable from "./LineageTable";
import LineagePlot from "./LineagePlot";
import FlowPlot from "./FlowPlot";

const LineageModal = ({
    title,
    visible,
    handleModalVisible,
    lineageData,
    lineageGraphData,
    flowData,
    fromCard,
}) => {
    return fromCard ? (
        <Modal
            title={title}
            open={visible}
            onCancel={handleModalVisible}
            width={1000}
            footer={
                <Button onClick={handleModalVisible} type={"primary"}>
                    OK
                </Button>
            }
        >
            <LineageTable lineageData={lineageData} fromCard={true} />
        </Modal>
    ) : (
        <DraggableModalProvider>
            <DraggableModal
                initialHeight={650}
                initialWidth={1000}
                title={title}
                open={visible}
                footer={
                    <Button onClick={handleModalVisible} type={"primary"}>
                        OK
                    </Button>
                }
                onCancel={handleModalVisible}
            >
                <Tabs
                    defaultActiveKey="1"
                    tabPosition={"left"}
                    items={[
                        {
                            label: `Lineage`,
                            key: "1",
                            children: <LineagePlot lineageGraphData={lineageGraphData} />,
                        },
                        {
                            label: `Flow`,
                            key: "2",
                            children: <FlowPlot flowData={flowData} />,
                        },
                        {
                            label: `Data`,
                            key: "3",
                            children: (
                                <LineageTable lineageData={lineageData} />
                            ),
                        },
                    ]}
                />
            </DraggableModal>
        </DraggableModalProvider>
    );
};

export default LineageModal;
