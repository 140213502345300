import React, { useEffect, useState } from "react";
import LeoContext from "./leoContext";
import moment from "moment";
import { formatDate } from "../../Components/LEO/utils";
import { SERVER_URL } from "../../config/config";
import axios from "axios";

const LeoContextProvider = ({ children }) => {
  const [expensifyData, setExpensifyData] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({
    timeRange: {},
    dateRange: {},
    teTimeRange: {},
    teDateRange: {},
    division: {},
    costCenter: {},
    viewBy: {
      key: "1",
      label: "Division",
    },
    searchDivision: "",
    searchExpenseAndFlight: "",
    expenseAndFlightPagination: {
      aggregated: 1,
      expenses: 1,
      flights: 1,
      category: 1,
      expenseFlightDetail: 1,
      expenseDetail: 1,
      flightDetail: 1,
    },
  });

  const [sort, setSort] = useState({
    expenseFilghtDetails: {
      expenseFlight: {
        order: "ascend",
        column: "employee_name",
        sortable: [
          "category",
          "amount_usd",
          "expense_id",
          "user_comment",
          "employee_name",
          "transaction_date",
          "expense_type",
        ],
      },
      expense: {
        order: "ascend",
        column: "employee_name",
        sortable: [
          "category",
          "amount_usd",
          "expense_id",
          "user_comment",
          "employee_name",
          "transaction_date",
          "expense_type",
        ],
      },
      flight: {
        order: "ascend",
        column: "employee_name",
        sortable: [
          "category",
          "amount_usd",
          "expense_id",
          "user_comment",
          "employee_name",
          "transaction_date",
          "expense_type",
        ],
      },
    },
    divisionTable: {
      order: "ascend",
      column: "division",
      sortable: [
        "division",
        "count_of_emp",
        "average_amount_per_employee",
        "expense_amount_usd",
        "flight_amount_usd",
        "transaction_date",
        "total_amount_usd",
        "po_invoice_amount_usd",
      ],
    },
    employeeTable: {
      order: "ascend",
      column: "employee_name",
      sortable: [
        "employee_name",
        "division",
        "cost_center",
        "expense_amount_usd",
        "flight_amount_usd",
        "total_amount_usd",
        "po_invoice_amount_usd",
      ],
    },
  });
  const [loaders, setLoaders] = useState({
    loadingAll: false,
    loadingOnTimeRange: false,
    loadingOnTETimeRange: false,
    loadingSpenders: false,
    loadingCategory: false,
    loadingDetails: false,
    loadingTrends: false,
    loadingDivision: false,
    loadingEmployee: false,
  });

  const [loading, setLoading] = useState({
    expenseTrendsLoading: false,
    spendersExpenseLoading: false,
    spendersFlightLoading: false,
    categoriesLoading: false,
    expenseDetailLoading: false,
    flightDetailLoading: false,
    divisionLoading: false,
    employeeLoading: false,
  });

  const [data, setData] = useState({
    divisionData: [],
    employeeData: [],
  });
  const [expenseReports, setExpenseReports] = useState([]);
  const [flightReports, setFlightReports] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState([]);
  const [expenseReportsDetails, setExpenseReportsDetails] = useState([]);
  const [flightReportsDetails, setFlightReportsDetails] = useState([]);
  const [activeDetails, setActiveDetails] = useState("1");
  const [activeTab, setActiveTab] = useState(1);
  const [ExpensifyIconSelected, setExpIconSelection] = useState("bar");
  const [GrowthIconSelected, setGrowthIconSelection] = useState("bar");
  const [activeSpenders, setActiveSpenders] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visibleTokenExpired, setVisibleTokenExpired] = useState(false)

  const [filters, setFilters] = useState({
    timeRanges: [],
    divisions: [],
    costCenters: [],
  });

  // LEO API URLS
  const FILTERURL = `${SERVER_URL}/filters`;
  const EXPENSETRENDSURL = `${SERVER_URL}/expense_reports_trend`;
  const SPENDERSURL = `${SERVER_URL}/expense_reports_spenders`;
  const CATEGORIESURL = `${SERVER_URL}/expense_by_category`;
  const DETAILSURL = `${SERVER_URL}/expense_details`;
  const DIVISIONURL = `${SERVER_URL}/expense_by_division`;
  const EMPLOYEEURL = `${SERVER_URL}/expenses_by_employee`;

  const handleSpendersData = (expenseData, flightData, setExpenseReports, setFlightReports, setLoading) => {
    if (expenseData && flightData) {
      setExpenseReports(expenseData.top_spenders);
      setFlightReports(flightData.top_spenders);
      setLoading((prevState) => ({
        ...prevState,
        spendersExpenseLoading: false,
        spendersFlightLoading: false,
      }));
    }
  };

  const handleCategoriesData = (categoriesData, setExpenseCategory, setLoading) => {
    if (categoriesData) {
      setExpenseCategory(categoriesData.expense_by_category);
      setLoading((prevState) => ({
        ...prevState,
        categoriesLoading: false,
      }));
    }
  };

  const handleExpenseAndFlightDetails = (expenseDetailsData, flightDetailsData, setExpenseReportsDetails, setFlightReportsDetails, setLoading) => {
    if (expenseDetailsData && flightDetailsData) {
      setExpenseReportsDetails(expenseDetailsData.expense_details);
      setFlightReportsDetails(flightDetailsData.expense_details);
      setLoading((prevState) => ({
        ...prevState,
        expenseDetailLoading: false,
        flightDetailLoading: false,
      }));
    }
  };

  const handleDivisionsData = (divisionsData, setData, setLoading) => {
    if (divisionsData) {
      setData((prevState) => ({
        ...prevState,
        divisionData: divisionsData.expenses_by_division,
      }));
      setLoading((prevState) => ({
        ...prevState,
        divisionLoading: false,
      }));
    }
  };

  const handleEmployeesData = (employeesData, setData, setLoading) => {
    if (employeesData) {
      setData((prevState) => ({
        ...prevState,
        employeeData: employeesData.expenses_by_employee,
      }));
      setLoading((prevState) => ({
        ...prevState,
        employeeLoading: false,
      }));
    }
  };
  const handleError = (e) => {
    if (e?.response.data.error === 'Token Expired') {
      setVisibleTokenExpired(true)
      setLoading((prevState) => ({
        ...prevState,
        expenseTrendsLoading: false,
        spendersExpenseLoading: false,
        spendersFlightLoading: false,
        categoriesLoading: false,
        expenseDetailLoading: false,
        flightDetailLoading: false,
        divisionLoading: false,
        employeeLoading: false,
      }));
    } else {
      window.location.replace("/error");
    }
  }

  const fetchAllAPI = async () => {
    try {
      const queryFlight = new URLSearchParams({
        time_range: "Current Fiscal Quater",
        expense_type: "FLIGHT",
      });
      const queryExpense = new URLSearchParams({
        time_range: "Current Fiscal Quater",
        expense_type: "EXPENSE",
      });
      const timeRangeParam = new URLSearchParams({
        time_range: "Current Fiscal Quater",
      });
      const SPENDERSEXPENSES = `${SPENDERSURL}?${queryExpense}`;
      const SPENDERSFLIGHTS = `${SPENDERSURL}?${queryFlight}`;
      const EXPENSEDEATILS = `${DETAILSURL}?${queryExpense}`;
      const FLIGHTDETAILS = `${DETAILSURL}?${queryFlight}`;
      const CATEGORIES = `${CATEGORIESURL}?${timeRangeParam}`;
      const DIVISIONS = `${DIVISIONURL}?${timeRangeParam}`;
      const EMPLOYEES = `${EMPLOYEEURL}?${timeRangeParam}`;
      setLoading((prevState) => ({
        ...prevState,
        expenseTrendsLoading: true,
        spendersExpenseLoading: true,
        spendersFlightLoading: true,
        categoriesLoading: true,
        expenseDetailLoading: true,
        flightDetailLoading: true,
        divisionLoading: true,
        employeeLoading: true,
      }));

      
      const filterDataResponse = axios.get(FILTERURL);
      const expenseTrendsResponse = axios.get(EXPENSETRENDSURL);
      const spendersExpenseResponse = axios.get(SPENDERSEXPENSES);
      const spendersFlightResponse = axios.get(SPENDERSFLIGHTS);
      const categoriesResponse = axios.get(CATEGORIES);
      const expenseDetailsResponse = axios.get(EXPENSEDEATILS);
      const flightDetailsResponse = axios.get(FLIGHTDETAILS);
      const divisionsResponse = axios.get(DIVISIONS);
      const employeesResponse = axios.get(EMPLOYEES);

      const filterData = (await filterDataResponse).data;
      if (filterData) {
        const { time_ranges, divisions, cost_centers } = filterData.filters;
        setFilters((prevState) => ({
          ...prevState,
          timeRanges: time_ranges || [],
          divisions: divisions || [],
          costCenters: cost_centers || [],
        }));
      }

      const expenseTrendsData = (await expenseTrendsResponse).data;
      if (expenseTrendsData) {
        setExpensifyData(expenseTrendsData.leo_expenses_qtr_trend);
        setLoading((prevState) => ({
          ...prevState,
          expenseTrendsLoading: false,
        }));
      }

      const spendersExpenseData = (await spendersExpenseResponse).data;
      const spendersFlightData = (await spendersFlightResponse).data;
      handleSpendersData(
        spendersExpenseData,
        spendersFlightData,
        setExpenseReports,
        setFlightReports,
        setLoading
      );
      const categories = (await categoriesResponse).data;
      handleCategoriesData(categories, setExpenseCategory, setLoading);

      const [expenseDetailsData, flightDetailsData] = await Promise.all([expenseDetailsResponse, flightDetailsResponse]);
      handleExpenseAndFlightDetails(expenseDetailsData.data, flightDetailsData.data, setExpenseReportsDetails, setFlightReportsDetails, setLoading);
      
      const divisionsData = (await divisionsResponse).data;
      handleDivisionsData(divisionsData, setData, setLoading);

      const employeesData = (await employeesResponse).data;
      handleEmployeesData(employeesData, setData, setLoading);

      setCurrentSelection((prevState) => ({
        ...prevState,
        dateRange: {
          startDate: moment(currentSelection?.timeRange.start_date),
          endDate: moment(currentSelection?.timeRange.end_date),
        },
        teDateRange: {
          startDate: moment(currentSelection?.teTimeRange.start_date),
          endDate: moment(currentSelection?.teTimeRange.end_date),
        },
      }));
    } catch (error) {
      handleError(error)
    }
  };
  useEffect(() => {
    fetchAllAPI();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (
          Object.keys(currentSelection.timeRange).length !== 0 &&
          currentSelection.timeRange.label !== "Custom Date Range" &&
          loaders.loadingOnTimeRange
        ) {
          setCurrentSelection((prevState) => ({
            ...prevState,
            dateRange: {
              startDate: moment(currentSelection?.timeRange.start_date),
              endDate: moment(currentSelection?.timeRange.end_date),
            },
          }));
          setLoading((prevState) => ({
            ...prevState,
            spendersExpenseLoading: true,
            spendersFlightLoading: true,
            categoriesLoading: true,
            expenseDetailLoading: true,
            flightDetailLoading: true,
          }));
          const queryFlight = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
            expense_type: "FLIGHT",
          });
          const queryExpense = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
            expense_type: "EXPENSE",
          });
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
          });
          const SPENDERSEXPENSES = `${SPENDERSURL}?${queryExpense}`;
          const SPENDERSFLIGHTS = `${SPENDERSURL}?${queryFlight}`;
          const EXPENSEDEATILS = `${DETAILSURL}?${queryExpense}`;
          const FLIGHTDETAILS = `${DETAILSURL}?${queryFlight}`;
          const CATEGORIES = `${CATEGORIESURL}?${timeRangeParam}`;
          const spendersExpenseResponse = axios.get(SPENDERSEXPENSES);
          const spendersFlightResponse = axios.get(SPENDERSFLIGHTS);
          const categoriesResponse = axios.get(CATEGORIES);
          const expenseDetailsResponse = axios.get(EXPENSEDEATILS);
          const flightDetailsResponse = axios.get(FLIGHTDETAILS);
          const spendersExpenseData = (await spendersExpenseResponse).data;
          const spendersFlightData = (await spendersFlightResponse).data;
          handleSpendersData(
            spendersExpenseData,
            spendersFlightData,
            setExpenseReports,
            setFlightReports,
            setLoading
          );
          const categories = (await categoriesResponse).data;
          handleCategoriesData(categories, setExpenseCategory, setLoading);
    
          const [expenseDetailsData, flightDetailsData] = await Promise.all([expenseDetailsResponse, flightDetailsResponse]);
          handleExpenseAndFlightDetails(expenseDetailsData.data, flightDetailsData.data, setExpenseReportsDetails, setFlightReportsDetails, setLoading);
        }
      } catch (error) {
        handleError(error)
      }
    })();
  }, [currentSelection.timeRange]);

  useEffect(() => {
    (async () => {
      try {
        if (
          Object.keys(currentSelection.teTimeRange).length !== 0 &&
          currentSelection.teTimeRange.label !== "Custom Date Range" &&
          loaders.loadingOnTETimeRange
        ) {
          setLoading((prevState) => ({
            ...prevState,
            divisionLoading: true,
            employeeLoading: true,
          }));
          setCurrentSelection((prevState) => ({
            ...prevState,
            teDateRange: {
              startDate: moment(currentSelection?.teTimeRange.start_date),
              endDate: moment(currentSelection?.teTimeRange.end_date),
            },
          }));
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.teTimeRange.label,
          });
          const DIVISIONS = `${DIVISIONURL}?${timeRangeParam}`;
          const EMPLOYEES = `${EMPLOYEEURL}?${timeRangeParam}`;
          const divisionsResponse = axios.get(DIVISIONS);
          const employeesResponse = axios.get(EMPLOYEES);
          const divisionsData = (await divisionsResponse).data;
          handleDivisionsData(divisionsData, setData, setLoading);
    
          const employeesData = (await employeesResponse).data;
          handleEmployeesData(employeesData, setData, setLoading);
        }
      } catch (error) {
        handleError(error)
      }
    })();
  }, [currentSelection.teTimeRange]);

  useEffect(() => {
    (async () => {
      try {
        if (
          Object.keys(currentSelection.dateRange).length !== 0 &&
          currentSelection.timeRange.label === "Custom Date Range" &&
          currentSelection.dateRange.startDate &&
          currentSelection.dateRange.endDate &&
          loaders.loadingOnTimeRange
        ) {
          setLoading((prevState) => ({
            ...prevState,
            spendersExpenseLoading: true,
            spendersFlightLoading: true,
            categoriesLoading: true,
            expenseDetailLoading: true,
            flightDetailLoading: true,
          }));
          const startDate = new Date(currentSelection.dateRange.startDate);
          const endDate = new Date(currentSelection.dateRange.endDate);
          const queryFlight = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
            expense_type: "FLIGHT",
            date_range_min: formatDate(startDate),
            date_range_max: formatDate(endDate),
          });
          const queryExpense = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
            expense_type: "EXPENSE",
            date_range_min: formatDate(startDate),
            date_range_max: formatDate(endDate),
          });
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.timeRange.label,
            date_range_min: formatDate(startDate),
            date_range_max: formatDate(endDate),
          });
          const SPENDERSEXPENSES = `${SPENDERSURL}?${queryExpense}`;
          const SPENDERSFLIGHTS = `${SPENDERSURL}?${queryFlight}`;
          const EXPENSEDEATILS = `${DETAILSURL}?${queryExpense}`;
          const FLIGHTDETAILS = `${DETAILSURL}?${queryFlight}`;
          const CATEGORIES = `${CATEGORIESURL}?${timeRangeParam}`;
          const spendersExpenseResponse = axios.get(SPENDERSEXPENSES);
          const spendersFlightResponse = axios.get(SPENDERSFLIGHTS);
          const categoriesResponse = axios.get(CATEGORIES);
          const expenseDetailsResponse = axios.get(EXPENSEDEATILS);
          const flightDetailsResponse = axios.get(FLIGHTDETAILS);
          const spendersExpenseData = (await spendersExpenseResponse).data;
          const spendersFlightData = (await spendersFlightResponse).data;
          handleSpendersData(
            spendersExpenseData,
            spendersFlightData,
            setExpenseReports,
            setFlightReports,
            setLoading
          );
          const categories = (await categoriesResponse).data;
          handleCategoriesData(categories, setExpenseCategory, setLoading);
    
          const [expenseDetailsData, flightDetailsData] = await Promise.all([expenseDetailsResponse, flightDetailsResponse]);
          handleExpenseAndFlightDetails(expenseDetailsData.data, flightDetailsData.data, setExpenseReportsDetails, setFlightReportsDetails, setLoading);
        }
      } catch (error) {
        handleError(error)
      }
    })();
  }, [currentSelection.dateRange]);

  useEffect(() => {
    (async () => {
      try {
        if (
          Object.keys(currentSelection.teDateRange).length !== 0 &&
          currentSelection.teTimeRange.label === "Custom Date Range" &&
          currentSelection.teDateRange.startDate &&
          currentSelection.teDateRange.endDate &&
          loaders.loadingOnTETimeRange
        ) {
          setLoading((prevState) => ({
            ...prevState,
            divisionLoading: true,
            employeeLoading: true,
          }));
          const startDate = new Date(currentSelection.teDateRange.startDate);
          const endDate = new Date(currentSelection.teDateRange.endDate);
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.teTimeRange.label,
            date_range_min: formatDate(startDate),
            date_range_max: formatDate(endDate),
          });
          const DIVISIONS = `${DIVISIONURL}?${timeRangeParam}`;
          const EMPLOYEES = `${EMPLOYEEURL}?${timeRangeParam}`;
          const divisionsResponse = axios.get(DIVISIONS);
          const employeesResponse = axios.get(EMPLOYEES);
          const divisionsData = (await divisionsResponse).data;
          handleDivisionsData(divisionsData, setData, setLoading);
    
          const employeesData = (await employeesResponse).data;
          handleEmployeesData(employeesData, setData, setLoading);
        }
      } catch (error) {
        handleError(error)
      }
    })();
  }, [currentSelection.teDateRange]);

  useEffect(() => {
    (async () => {
      try {
        if (
          Object.keys(currentSelection.teTimeRange).length !== 0 &&
          currentSelection.teTimeRange.label !== "Custom Date Range" &&
          loaders.loadingOnTETimeRange
        ) {
          setLoading((prevState) => ({
            ...prevState,
            divisionLoading: true,
            employeeLoading: true,
          }));
          let division = null;
          let costCenter = null;
          if (
            currentSelection.division &&
            currentSelection.division.label !== "All"
          ) {
            division = currentSelection.division.label;
          }
          if (
            currentSelection.costCenter &&
            currentSelection.costCenter.label !== "All"
          ) {
            costCenter = currentSelection.costCenter.label;
          }
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.teTimeRange.label,
          });
  
          if (division !== null) {
            timeRangeParam.append("division", encodeURIComponent(division));
          }
          if (costCenter !== null) {
            timeRangeParam.append("cost_center", encodeURIComponent(costCenter));
          }
          const DIVISIONS = `${DIVISIONURL}?${timeRangeParam}`;
          const EMPLOYEES = `${EMPLOYEEURL}?${timeRangeParam}`;
          const divisionsResponse = axios.get(DIVISIONS);
          const employeesResponse = axios.get(EMPLOYEES);
          const divisionsData = (await divisionsResponse).data;
          handleDivisionsData(divisionsData, setData, setLoading);
    
          const employeesData = (await employeesResponse).data;
          handleEmployeesData(employeesData, setData, setLoading);
        } else if (
          Object.keys(currentSelection.teDateRange).length !== 0 &&
          currentSelection.teDateRange.startDate &&
          currentSelection.teDateRange.endDate &&
          loaders.loadingOnTETimeRange
        ) {
          let division = null;
          let costCenter = null;
          if (
            currentSelection.division &&
            currentSelection.division.label !== "All"
          ) {
            division = currentSelection.division.label;
          }
          if (
            currentSelection.costCenter &&
            currentSelection.costCenter.label !== "All"
          ) {
            costCenter = currentSelection.costCenter.label;
          }
          let startDate = new Date(currentSelection.teDateRange.startDate);
          let endDate = new Date(currentSelection.teDateRange.endDate);
          const timeRangeParam = new URLSearchParams({
            time_range: currentSelection.teTimeRange.label,
            date_range_min: formatDate(startDate),
            date_range_max: formatDate(endDate),
          });
          if (division !== null) {
            timeRangeParam.append("division", encodeURIComponent(division));
          }
          if (costCenter !== null) {
            timeRangeParam.append("cost_center", encodeURIComponent(costCenter));
          }
          const DIVISIONS = `${DIVISIONURL}?${timeRangeParam}`;
          const EMPLOYEES = `${EMPLOYEEURL}?${timeRangeParam}`;
          const divisionsResponse = axios.get(DIVISIONS);
          const employeesResponse = axios.get(EMPLOYEES);
          const divisionsData = (await divisionsResponse).data;
          handleDivisionsData(divisionsData, setData, setLoading);
    
          const employeesData = (await employeesResponse).data;
          handleEmployeesData(employeesData, setData, setLoading);
        }
      } catch (error) {
        handleError(error)
      }
    })();
  }, [currentSelection.division]);

  const valueProps = {
    data,
    sort,
    filters,
    endDate,
    startDate,
    activeTab,
    activeDetails,
    expensifyData,
    flightReports,
    expenseReports,
    activeSpenders,
    expenseCategory,
    GrowthIconSelected,
    flightReportsDetails,
    expenseReportsDetails,
    ExpensifyIconSelected,
    currentSelection,
    loaders,
    loading,
    visibleTokenExpired,
    setEndDate,
    setStartDate,
    setSort,
    setActiveTab,
    setActiveDetails,
    setActiveSpenders,
    setCurrentSelection,
    setExpIconSelection,
    setGrowthIconSelection,
    setLoaders,
    setVisibleTokenExpired
  };
  return (
    <LeoContext.Provider value={valueProps}>{children}</LeoContext.Provider>
  );
};

export default LeoContextProvider;
