// enggDashboard.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  codeCoverageLastUpdate: null,
  activeHeaderTab: 1,
  showNumbers: true,
  loadingCycle: false,
  loadingVelocity: false,
  loadingCfd: false,
  loadingCodeCoverage: false,
  loadingVelocityFilter: false,
  loadingCycleFilter: false,
  loadingCfdFilter: false,
  loadingCodeCoverageFilter: false,
  loadingOvertime: false,
  velocityAssigneeChanged:false,
  openModal: false,
  columnData: [],
  dataSourceData: [],
  codeCoverageFilter: [],
  CFDFilter:[],
  cycleFilter:[],
  velocityFilter:[],
  stagesData:[],
  overTimeData:[],
  teamVelocityData:[],
  developerVelocityData:[],
  avgDeveloperVelocityData:[],
  jiraComparisonData:[],
  cfdData:[],
  automationCoverage: [],
  automationTestData: [],
  cycleDateRange: {},
  jiraDateRange: {},
  developerPage : { currentPage: 1, pageSize: 10 },
  customerPage : { currentPage: 1, pageSize: 10 },
  avgDeveloperPage : { currentPage: 1, pageSize: 10 },
  customerDefectsPage : { currentPage: 1, pageSize: 10 },
  automationCoveragePage : { currentPage: 1, pageSize: 16 },
  automationCoverageTestPage : { currentPage: 1, pageSize: 30 },
  overTime: {
    "Blocked": true,
    "Development": true,
    "Code Review": true,
    "QA Testing": true,
    "Business UAT": true,
    "QA/UAT Done": true,
    "Deployment": true
  },
  jiraComparison: {
    "Blocked": true,
    "Development": true,
    "Code Review": true,
    "QA Testing": true,
    "Business UAT": true,
    "QA/UAT Done": true,
    "Deployment": true
  },
  loading: {
    teamVelocity: false,
    developerVelocity: false,
    avgDeveloperVelocity: false,
    stages: false,
    overtime: false,
    jiraComparison: false,
    cfd: false,
    currentCoverage: false,
    automationCoverage: false,
    automationTestCoverage: false,
  },
  developer:{
    firstQuarter: true,
    secondQuarter: true,
    thirdQuarter: true,
    fourthQuarter: true,
    firstMonth: true,
    secondMonth: true,
    thirdMonth: true,
    fourthMonth: true,
    fifthMonth: true,
    sixthMonth: true,
  },
  avgDeveloper:{
    firstQuarter: true,
    secondQuarter: true,
    thirdQuarter: true,
    fourthQuarter: true,
    firstMonth: true,
    secondMonth: true,
    thirdMonth: true,
    fourthMonth: true,
    fifthMonth: true,
    sixthMonth: true,
  },
  customerDefects:{
    firstQuarter: true,
    secondQuarter: true,
    thirdQuarter: true,
    fourthQuarter: true,
    firstMonth: true,
    secondMonth: true,
    thirdMonth: true,
    fourthMonth: true,
    fifthMonth: true,
    sixthMonth: true,
  },
  filters: {
    selectedProduct: ["All"],
    selectedComponent: ["All"],
    selectedVelocityProduct: ["All"],
    selectedVelocityComponent: ["All"],
    selectedCustomerProduct: ["All"],
    selectedCustomerComponent: ["All"],
    selectedCodeCoverageProduct: [],
    selectedStage: ["All"],
    selectedIssueType: ["All"],
    selectedVelocityIssueType: ["All"],
    selectedCustomerIssueType: ["All"],
    selectedPriority: ["All"],
    selectedVelocityPriority: ["All"],
    selectedCustomerPriority: ["All"],
    selectedLabel: ["All"],
    selectedVelocityLabel: ["All"],
    selectedCustomerLabel: ["All"],
    selectedStoryPoint: ["All"],
    selectedAssignee: ["All"],
    selectedVelocityAssignee: ["All"],
    selectedQAContact: ["All"],
    selectedJiraIssues: ["All"],
    selectedTicketProgress: "Only Closed",
    selectedCycleTime: "Average",
    viewBy: "Quarterly",
    viewByVelocity: "Quarterly",
    viewByCfd: "Quarterly",
    selectedVelocity: "Production",
    viewByCC: 'Day'
  },
  defaultView: {
    default: true,
    view_days: false,
    view_percentage: false,
  },
  defaultViewVelocity: {
    default_story: true,
    view_story_points: false,
  },
  defaultCustomerDefect: {
    default_defect: true,
    hide_default_counts: false,
  },
  defaultCodeCoverage: {
    default_cc: true,
    hide_cc: false,
  },
};

const enggDashboardSlice = createSlice({
  name: "enggDashboard",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const { section, value } = action.payload;
      return { ...state, filters: { ...state.filters, [section]: value } };
    },
    setCodeCoverageFilter: (state, action) => {
      return { ...state, codeCoverageFilter: action.payload };
    },
    setCodeCoverageLastUpdate: (state, action) => {
      return { ...state, codeCoverageLastUpdate: action.payload };
    },
    setColumnData: (state, action) => {
      return { ...state, columnData: action.payload };
    },
    setDataSourceData: (state, action) => {
      return { ...state, dataSourceData: action.payload };
    },
    setCFDFilter: (state, action) => {
      return { ...state, CFDFilter: action.payload };
    },
    setCycleFilter: (state, action) => {
      return { ...state, cycleFilter: action.payload };
    },
    setVelocityFilter: (state, action) => {
      return { ...state, velocityFilter: action.payload };
    },
    setStagesData: (state, action) => {
      return { ...state, stagesData: action.payload };
    },
    setJiraComparisonData: (state, action) => {
      return { ...state, jiraComparisonData: action.payload };
    },
    setCfdData: (state, action) => {
      return { ...state, cfdData: action.payload };
    },
    setAutomationCodeCoverage: (state, action) => {
      return { ...state, automationCoverage: action.payload };
    },
    setAutomationTestCoverage: (state, action) => {
      return { ...state, automationTestData: action.payload };
    },
    setOvertimeData: (state, action) => {
      return { ...state, overTimeData: action.payload };
    },
    setTeamVelocityData: (state, action) => {
      return { ...state, teamVelocityData: action.payload };
    },
    setDeveloperVelocityData: (state, action) => {
      return { ...state, developerVelocityData: action.payload };
    },
    setAvgDeveloperVelocityData: (state, action) => {
      return { ...state, avgDeveloperVelocityData: action.payload };
    },
    setDeveloperPagination: (state, action) => {
      return { ...state, developerPage: action.payload };
    },
    setCustomerPagination: (state, action) => {
      return { ...state, customerPage: action.payload };
    },
    setAvgDeveloperPagination: (state, action) => {
      return { ...state, avgDeveloperPage: action.payload };
    },
    setCustomerDefectsPagination: (state, action) => {
      return { ...state, customerDefectsPage: action.payload };
    },
    setAutomationCoveragePagination: (state, action) => {
      return { ...state, automationCoveragePage: action.payload };
    },
    setAutomationCoverageTestPagination: (state, action) => {
      return { ...state, automationCoverageTestPage: action.payload };
    },
    setActiveHeaderTab: (state, action) => {
      return { ...state, activeHeaderTab: action.payload };
    },
    setShowNumbers: (state, action) => {
      return { ...state, showNumbers: action.payload };
    },
    toggleLoadingCycle: (state, action) => {
      return { ...state, loadingCycle: action.payload };
    },
    toggleLoadingFilterCycle: (state, action) => {
      return { ...state, loadingCycleFilter: action.payload };
    },
    toggleLoadingVelocity: (state, action) => {
      return { ...state, loadingVelocity: action.payload };
    },
    toggleLoadingVelocityFilter: (state, action) => {
      return { ...state, loadingVelocityFilter: action.payload };
    },
    toggleLoadingCfd: (state, action) => {
      return { ...state, loadingCfd: action.payload };
    },
    toggleLoadingCodeCoverage: (state, action) => {
      return { ...state, loadingCodeCoverage: action.payload };
    },
    toggleLoadingCfdFilter: (state, action) => {
      return { ...state, loadingCfdFilter: action.payload };
    },
    toggleLoadingCodeCoverageFilter: (state, action) => {
      return { ...state, loadingCodeCoverageFilter: action.payload };
    },
    toggleVelocityAssignee: (state, action) => {
      return { ...state, velocityAssigneeChanged: action.payload };
    },
    setOpenModal: (state, action) => {
      return { ...state, openModal: action.payload };
    },
    toggleLoadingOvertime: (state, action) => {
      return { ...state, loadingOvertime: action.payload };
    },
    toggleOverTimeSection: (state, action) => {
      const section = action.payload;
      return { ...state, overTime: { ...state.overTime, [section]: !state.overTime[section] } };
    },
    toggleJiraComparisonSection: (state, action) => {
      const section = action.payload;
      return { ...state, jiraComparison: { ...state.jiraComparison, [section]: !state.jiraComparison[section] } };
    },
    toggleDeveloper: (state, action) => {
      const section = action.payload;
      return { ...state, developer: { ...state.developer, [section]: !state.developer[section] } };
    },
    toggleAvgDeveloper: (state, action) => {
      const section = action.payload;
      return { ...state, avgDeveloper: { ...state.avgDeveloper, [section]: !state.avgDeveloper[section] } };
    },
    toggleCustomerDefects: (state, action) => {
      const section = action.payload;
      return { ...state, customerDefects: { ...state.customerDefects, [section]: !state.customerDefects[section] } };
    },
    setLoading: (state, action) => {
      return { ...state, loading: {...state.loading, ...action.payload} };
    },
    setCycleDateRange: (state, action) => {
      return { ...state, cycleDateRange: action.payload };
    },
    setJiraDateRange: (state, action) => {
      return { ...state, jiraDateRange: action.payload };
    },
    toggleDefaultView: (state, action) => {
      const section = action.payload;
      // If the selected section is true, set it to false
      if (state.defaultView[section] === true) {
        return {
          ...state,
          defaultView: { ...state.defaultView, [section]: false }
        };
      }
      // If the selected section is false, set it to true and set other sections to false
      const updatedDefaultView = { ...state.defaultView };
      for (const key in updatedDefaultView) {
        updatedDefaultView[key] = key === section;
      }
      return {
        ...state,
        defaultView: updatedDefaultView
      };
    },
    toggleDefaultViewVelocity: (state, action) => {
      const section = action.payload;
      // If the selected section is true, set it to false
      if (state.defaultViewVelocity[section] === true) {
        return {
          ...state,
          defaultViewVelocity: { ...state.defaultViewVelocity, [section]: false }
        };
      }
      // If the selected section is false, set it to true and set other sections to false
      const updatedDefaultView = { ...state.defaultViewVelocity };
      for (const key in updatedDefaultView) {
        updatedDefaultView[key] = key === section;
      }
      return {
        ...state,
        defaultViewVelocity: updatedDefaultView
      };
    },
    toggleCustomerDefault: (state, action) => {
      const section = action.payload;
      // If the selected section is true, set it to false
      if (state.defaultCustomerDefect[section] === true) {
        return {
          ...state,
          defaultCustomerDefect: { ...state.defaultCustomerDefect, [section]: false }
        };
      }
      // If the selected section is false, set it to true and set other sections to false
      const updatedDefaultView = { ...state.defaultCustomerDefect };
      for (const key in updatedDefaultView) {
        updatedDefaultView[key] = key === section;
      }
      return {
        ...state,
        defaultCustomerDefect: updatedDefaultView
      };
    },
    toggleCodeCoverageCount: (state, action) => {
      const section = action.payload;
      // If the selected section is true, set it to false
      if (state.defaultCodeCoverage[section] === true) {
        return {
          ...state,
          defaultCodeCoverage: { ...state.defaultCodeCoverage, [section]: false }
        };
      }
      // If the selected section is false, set it to true and set other sections to false
      const updatedDefaultView = { ...state.defaultCodeCoverage };
      for (const key in updatedDefaultView) {
        updatedDefaultView[key] = key === section;
      }
      return {
        ...state,
        defaultCodeCoverage: updatedDefaultView
      };
    },
    resetDefaultView: (state) => {
      return {
        ...state,
        defaultView: initialState.defaultView
      };
    },
    resetDefaultViewVelocity: (state) => {
      return {
        ...state,
        defaultViewVelocity: initialState.defaultViewVelocity
      };
    },
    resetCustomerDefect: (state) => {
      return {
        ...state,
        defaultCustomerDefect: initialState.defaultCustomerDefect
      };
    },
    resetCodeCoverage: (state) => {
      return {
        ...state,
        defaultCodeCoverage: initialState.defaultCodeCoverage
      };
    }
  },
});

export const { 
  setFilters,
  setActiveHeaderTab, 
  setStagesData,
  setOvertimeData,
  setShowNumbers,
  setCycleDateRange,
  setJiraComparisonData, 
  toggleLoadingCycle, 
  toggleLoadingFilterCycle,
  toggleLoadingVelocity,
  toggleOverTimeSection,
  toggleJiraComparisonSection,
  toggleDeveloper,
  toggleLoadingOvertime,
  setTeamVelocityData,
  setDeveloperVelocityData,
  setDeveloperPagination,
  toggleDefaultView,
  setLoading,
  toggleLoadingCfd,
  setCfdData,
  setJiraDateRange,
  setAvgDeveloperPagination,
  toggleAvgDeveloper,
  setAvgDeveloperVelocityData,
  setCustomerPagination,
  setAutomationCoveragePagination,
  toggleCustomerDefects,
  toggleDefaultViewVelocity,
  resetDefaultView,
  resetDefaultViewVelocity,
  resetCustomerDefect,
  toggleCustomerDefault,
  toggleCodeCoverageCount,
  setCustomerDefectsPagination,
  setCFDFilter,
  setCycleFilter,
  setVelocityFilter,
  toggleVelocityAssignee,
  toggleLoadingVelocityFilter,
  toggleLoadingCfdFilter,
  toggleLoadingCodeCoverageFilter,
  setAutomationCoverageTestPagination,
  setCodeCoverageFilter,
  setColumnData,
  toggleLoadingCodeCoverage,
  setAutomationCodeCoverage,
  setAutomationTestCoverage,
  resetCodeCoverage,
  setOpenModal,
  setDataSourceData,
  setCodeCoverageLastUpdate
} = enggDashboardSlice.actions;
export default enggDashboardSlice.reducer;
