import React, {useState, useEffect} from "react";
import {
  Table,
  Popover,
} from "antd";
import axios from "axios";
export function ProductionPlatformChartPopover ({ prodData,children }) {
console.log(prodData)
  const [state, setState] = useState([])
  const popoverProdColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Up Time",
      dataIndex: "uptime",
      key: "uptime",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
    }
   ];

  

   const convertDataStructure=()=>{
     const test = prodData.reduce((acc, curr)=>{
       if(acc[curr.platform]){
         acc[curr.platform][curr.name.toLowerCase()?.replaceAll(' ', '')] = curr.metrics_details
         return acc
        }
        acc[curr.platform] = {
          name: curr.platform, 
          [curr.name.toLowerCase()?.replaceAll(' ', '')]: curr.metrics_details
        }
        return acc
      }, {})

      const data =  Object.values(test)
      setState(data)
    }

    useEffect(()=>{
      convertDataStructure()
    }, [prodData])

   return (
    <div>
      <Popover
        placement="leftBottom"
        trigger={"click"}
        content={<PopoverContent gridData={state} dataColumns={popoverProdColumns}/>}
      >
        {children}
      </Popover>
    </div>
  );


  
}
function PopoverContent({gridData, dataColumns}) {
  console.log({gridData})
  return (
    <div>
     <Table
        columns={dataColumns}
        dataSource={gridData}
        pagination={false}
     />
    </div>
  );
}

export default ProductionPlatformChartPopover;
