import React from "react";
import RadioButton from "./RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { toggleDefaultView } from "../../../../features/JiraAnalysis/enggDashboardSlice";

const CycleTimeRadioButton = () => {
  const { defaultView } = useSelector((state) => state.enggDashboard);
  const dispatch = useDispatch();
  return (
    <>
      <RadioButton
        name="default"
        title="Default"
        checked={defaultView.default}
        onChange={(e) => dispatch(toggleDefaultView(e))}
      />
      <RadioButton
        name="view_days"
        title="View Days"
        checked={defaultView.view_days}
        onChange={(e) => dispatch(toggleDefaultView(e))}
      />
      <RadioButton
        name="view_percentage"
        title="View Percentage"
        checked={defaultView.view_percentage}
        onChange={(e) => dispatch(toggleDefaultView(e))}
      />
    </>
  );
};

export default CycleTimeRadioButton;
