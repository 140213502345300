import React, { useContext, useEffect, useState } from "react";
import {
    LocalTable, Sorter, Tabs, FlexLayout, TextLabel, Modal, Button,
    FlexItem, StackingLayout, Notification, NotificationTrigger, PlusIcon
} from '@nutanix-ui/prism-reactjs';

import './styles.css';

import { getTemplateDetails, getTemplateTableData, updateUsers, updateColumn, fetchAirflowDag } from "../../../../Service/fileUploadUtility";
import { TemplateDataContext } from "../../FileTemplateUploadDashboard";
import UserDetailsForm from "../EditTemplate/UserDetailsEditForm";
import AddColumnsForm from "../EditTemplate/AddColumnsEditForm";
import SelectAirflowDag from "../CreateAirflowDagModal/selectAirflowDag";

export default function TemplateTable(props) {

    const [dagMenuData, setDagMenuData] = useState([]);

    useEffect(() => {
        try {
            fetchAirflowDag()
                .then(resp => {
                    setDagMenuData(prevState => resp);
                })
        } catch (err) {

        }
    }, []);

    const columns = [{
        title: 'Template ID',
        key: 'templateID'
    }, {
        title: 'Template Name',
        key: 'templateName'
    }, {
        title: 'Description',
        key: 'desc'
    }, {
        title: 'Uploaded On',
        key: 'time',
    }, {
        title: 'Uploaded By',
        key: 'user'
    }, {
        title: 'Integration',
        key: 'integration',
        render: (value, object, index) => <div>
            <a onClick={(e) => handleAddAirflow(object)} ><PlusIcon /> Airflow</a>
        </div>
    }, {
        title: 'Action',
        key: 'action',
        render: (value, object, index) => <div className="templateAction">
            <Button type={Button.ButtonTypes.SECONDARY} onClick={(e) => handleUserEdit(object)}>
                <p className="templateActionText">Edit User</p>
            </Button>
            <Button type={Button.ButtonTypes.SECONDARY} onClick={(e) => handleColumnEdit(object)}>
                <p className="templateActionText">Edit Columns</p>
            </Button>
        </div>
    }];

    const renderForm = () => {
        if (currentModal !== 0) return <AddColumnsForm columns={columnData} setColumnData={setColumnData} templateName={templateName} templateID={templateID} loading={loadingColumn} updateAllowed={updateAllowed} />

        return <UserDetailsForm users={userData} setUserData={setUserData} templateName={templateName} templateID={templateID} />
    }

    const [data, setData] = useState(props.data);

    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line 
    const [templateData, setTemplateData] = useContext(TemplateDataContext);
    const [templateName, setTemplateName] = useState('');
    const [templateID, setTemplateID] = useState(0);
    const [loadingColumn, setLoadingColumn] = useState(false);
    const [updateAllowed, setUpdateAllowed] = useState(true);
    const [columnData, setColumnData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentModal, setCurrentModal] = useState(0);
    const [airflowModal, setAirflowModal] = useState(false);
    useEffect(() => {
        fetchData();// eslint-disable-next-line 
    }, [props.visible]);

    const fetchData = () => {
        getTemplateTableData()
            .then(resp => {
                setData(resp);
                setLoading(false);
                setTemplateData(prevState => resp);
            })
            .catch(err => {
                window.location.replace('/error')
            });
    }

    const handleUserEdit = (object) => {
        setTemplateName(prevState => object.templateName)
        setTemplateID(prevState => object.templateID);
        getTemplateDetails('user', object.templateID)
            .then(resp => {
                setUserData(resp);
            })
            .catch(err => {
                window.location.replace('/error')
            })
        setCurrentModal(prevState => 0);
        setModalVisible(true);
    }

    const handleAddAirflow = (object) => {
        setTemplateName(prevState => object.templateName)
        setTemplateID(prevState => object.templateID);
        setAirflowModal(prevState => true)
    }

    const handleColumnEdit = (object) => {
        setLoadingColumn(prevState => true);
        setTemplateName(prevState => object.templateName)
        setTemplateID(prevState => object.templateID);
        getTemplateDetails('column', object.templateID)
            .then(resp => {
                setColumnData(prevState => resp.columnData);
                setLoadingColumn(prevState => false);
                setUpdateAllowed(prevState => resp.updateAllowed)
            })
            .catch(err => {
                window.location.replace('/error')
            })
        setModalVisible(true);
        setCurrentModal(prevState => 1);
    }

    const closeAirflowModal = () => {
        setAirflowModal(prevState => false);
    }

    const handleModalClose = () => {
        setCurrentModal(prevState => 0);
        setUserData(prevState => []);
        setColumnData(prevState => []);
        setModalVisible(false);
    }

    const handleSubmit = () => {
        if (currentModal === 0) {
            updateUsers(templateID, userData)
                .then(res => {
                    NotificationTrigger.add({
                        id: 'upload success',
                        className: 'successAlert',
                        message: `Template Update Request has been sent successfully`,
                        type: 'success',
                        autoDismissDelaySecs: 60,
                    });
                })
        } else {
            updateColumn(templateID, columnData)
                .then(res => {
                    NotificationTrigger.add({
                        id: 'upload success',
                        className: 'successAlert',
                        message: `Template Update Request has been sent successfully`,
                        type: 'success',
                        autoDismissDelaySecs: 60,
                    });
                })
        }

        setModalVisible(false);
    }

    const pagination = {
        total: data.length,
        currentPage: 1,
        pageSize: 10
    };

    const sort = {
        order: Sorter.SORT_ORDER_CONST.ASCEND,
        sortable: ['templateID', 'templateName', 'time']
    }

    const sorters = {
        templateID: (a, b) => {
            return a.templateID - b.templateID;
        },
        templateName: (a, b) => {
            return a.templateName.localeCompare(b.templateName);
        },
        time: (a, b) => {
            return new Date(a.time).getTime() -
                new Date(b.time).getTime()
        }
    };

    const tabsData = [
        {
            title: (
                <FlexLayout alignItems="center" itemSpacing="10px">
                    <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>All Templates</TextLabel>
                </FlexLayout>
            ),
            key: '1'
        }
    ]

    const customHeader = (
        <Tabs
            data={tabsData}
            disabled="true"
            style={{ 'pointer-events': 'none' }}
            defaultActiveKey={2}
        />
    );

    return <div className="templateTableContainer">
        <LocalTable
            dataSource={data}
            className="historyTable"
            columns={columns}
            loading={loading}
            customHeader={customHeader}
            pagination={pagination}
            sort={sort}
            sorters={sorters}
            wrapperProps={{
                'data-test-id': 'template-list-table'
            }}
        />
        <Modal
            width={'60%'}
            visible={modalVisible}
            onClose={handleModalClose}
            title={
                <div>
                    <h3 style={{ textAlign: 'left' }}>
                        {templateName}
                    </h3>
                </div>
            }
            footer={updateAllowed ? <Button onClick={handleSubmit}>
                Submit
            </Button> : null
            }
        >
            <StackingLayout style={{ width: '100%' }}>
                <FlexLayout
                    flexDirection="column"
                    alignItems="center"
                    color='red'
                    role="tabpanel-2"
                    tabIndex={0}
                >
                    <FlexItem style={{ width: '95%', padding: '2rem' }}>
                        {renderForm()}
                    </FlexItem>
                </FlexLayout>
            </StackingLayout>
        </Modal>
        <SelectAirflowDag modalVisible={airflowModal} handleModalClose={closeAirflowModal} templateName={templateName} templateID={templateID} dagMenuData={dagMenuData} />
        <Notification className="alert modal " data-testid="notifications">
            <div
                style={{
                    position: 'fixed',
                    top: '80px',
                    right: '340px'
                }}

            />
        </Notification>
    </div>
}
