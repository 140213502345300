import React, { useContext } from 'react';
import { ConfirmModal, Button, Title, StackingLayout } from '@nutanix-ui/prism-reactjs';
import { SERVER_URL } from '../../config/config';
import LeoContext from '../../contexts/leoContexts/leoContext';

const SessionExpiredScreen = () => {
  const { visibleTokenExpired, setVisibleTokenExpired } = useContext(LeoContext);
  // const buttonModalTriggerRef = useRef(null);

  // const handleOnClose = () => {
  //   setVisibleTokenExpired(false);
  //   if (buttonModalTriggerRef.current) {
  //     buttonModalTriggerRef.current.focus();
  //   }
  // };
  
  const handleConfirm = () => {
    setVisibleTokenExpired(false);
    const navigateTo = (url) => (url ? (window.location.href = url) : null);
    const loginUrl = `${SERVER_URL}/login`;
    navigateTo(loginUrl)
  }

  return (
    <div>
      <ConfirmModal
        visible={visibleTokenExpired}
        footer={ null }
      >
        <StackingLayout style={ { textAlign: 'center' } }>
            <StackingLayout itemSpacing="15px">
              <Title size='h3'>Your session or token has expired. Please reauthenticate to continue.</Title>
            </StackingLayout>
            <Button onClick={ handleConfirm }>
              Log In
            </Button>
          </StackingLayout>
      </ConfirmModal>
    </div>
  );
};

export default SessionExpiredScreen;
