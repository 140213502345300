import React from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "@nutanix-ui/recharts";

// const defaultData = [
//   {
//     name: "1:00 AM",
//     value: 4000,
//   },
//   {
//     name: "1:15 AM",
//     value: 4500,
//   },
//   {
//     name: "1:30 AM",
//     value: 3500,
//   },
//   {
//     name: "1:45 AM",
//     value: 3200,
//   },
//   {
//     name: "2:00 AM",
//     value: 3300,
//   },
//   {
//     name: "3:00 AM",
//     value: 3000,
//   },
//   {
//     name: "5:00 AM",
//     value: 2000,
//   },
//   {
//     name: "7:00 AM",
//     value: 2780,
//   },
//   {
//     name: "9:00 AM",
//     value: 1890,
//   },
//   {
//     name: "11:00 AM",
//     value: 2390,
//   },
//   {
//     name: "1:00 PM",
//     value: 3490,
//   },
//   {
//     name: "3:00 PM",
//     value: 3490,
//   },
//   {
//     name: "3:15 PM",
//     value: 7800,
//   },
//   {
//     name: "3:30 PM",
//     value: 4500,
//   },
//   {
//     name: "4:00 PM",
//     value: 5500,
//   },
//   {
//     name: "4:15 PM",
//     value: 5000,
//   },
//   {
//     name: "4:30 PM",
//     value: 4000,
//   },
//   {
//     name: "5:00 PM",
//     value: 4100,
//   },
//   {
//     name: "7:00 PM",
//     value: 3490,
//   },
// ];

const LineTrends = ({ stroke = "#8884d8", trends }) => {
  return (
    <ResponsiveContainer width={'100%'} height={40}>
      <LineChart
        data={trends}
        // margin={{
        //   right: 5,
        // }}
      >
        <XAxis dataKey="name" />
        <Line
          type="linear"
          dataKey="value"
          stroke={stroke} // Optional: Set color for the line
          strokeWidth={3} // Set the thickness of the line
        />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineTrends;
