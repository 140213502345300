import React from "react";
import "../../App.css";
import { Card, Layout, Row, Col, Spin, Alert, Popover, Select } from "antd";
import { QuestionCircleOutlined, VideoCameraOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import "../../styles/animate.css";
import TilesDataSync from "../TilesDataSync";
import TilesDataSyncProdStage from "../TilesDataSyncProdStage";
import { appDataArray } from "./config";
import { SERVER_URL } from "../../config/config";
import TableSearch from "./TableSearch";
import logger from "../../config/logger";
import { sendUserRecord } from "../../Service/adoptionMetric";

class DataSync extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiProgress: { loading: false, ready: false, error: false },
            graphname: "DB",
            no_of_days: "1",
            appDataStage: appDataArray,
            appDataProd: appDataArray,
            prodData: [],
            stageData: [],
            dbloader: true,
            daasloader: true,
            etlloader: true,
            chartloader: true,
            tableauloader: true,
            chartdata: [],
            periodselectedRowvalue: [],
            dremiototalactiveusers: "",
            dremiototalqueryexecuted: "",
        };
    }
    async componentDidMount() {
        this.loaddatasyncsummary();
        sendUserRecord('data_health', window.location.href)
            .then(resp => { })
            .catch(err => { })
        try {
            logger.info("Fetching All Datasync details", "data_health_client",
                window.location.href);
            const { data } = await axios.post(`${SERVER_URL}/alldatasyncdetails`);
            const stageData = data.filter((e) => e.env === "stage");
            const prodData = data.filter((e) => e.env === "prod");
            this.setState({ stageData: stageData, prodData: prodData });

        } catch (err) {
            logger.error(err, "data_health_client",
                window.location.href);
        }
    }
    loaddatasyncsummary = (e) => {
        logger.info(e,
            "data_health_client",
            window.location.href
        );
        try {
            logger.info("Fetching Datasync Summary", "data_health_client",
                window.location.href);
            this.setState({ periodselectedRowvalue: e });

            var v_no_ofdays = 1;
            if (e == "1") {
                v_no_ofdays = 7;
            } else if (e == "2") {
                v_no_ofdays = 30;
            } else if (e == "3") {
                v_no_ofdays = 90;
            }
            logger.info(`Selected no of days:${v_no_ofdays}`, "data_health_client",
                window.location.href);
            this.setState({
                apiProgress: { loading: true, ready: false, error: false },
            });
            const dbDataPromoise = axios.post(`${SERVER_URL}/datasyncsummary`);

            Promise.all([dbDataPromoise])
                .then(([dbDataResponse]) => {
                    logger.info(`Datasync Summary loaded successfully`, "data_health_client",
                        window.location.href);
                    const datasyncSummary = dbDataResponse.data;
                    const stageDatasyncSummary = datasyncSummary.filter(
                        (e) => e.env === "STAGE"
                    );
                    const prodDatasyncSummary = datasyncSummary.filter(
                        (e) => e.env === "PROD"
                    );

                    let dataQualityStage = {},
                        dataQualityProd = {},
                        lastValidatedStage = {},
                        lastValidatedProd = {};
                    stageDatasyncSummary.forEach((summary) => {
                        const schema = summary.schema_name;
                        dataQualityStage[schema] = summary?.data_quality;
                        lastValidatedStage[schema] = summary?.last_validated;
                    });

                    prodDatasyncSummary.forEach((summary) => {
                        const schema = summary.schema_name;
                        dataQualityProd[schema] = summary?.data_quality;
                        lastValidatedProd[schema] = summary?.last_validated;
                    });

                    let newDataArrayStage = [],
                        newDataArrayProd = [];
                    appDataArray.forEach((dataArray) => {
                        const appName = dataArray.app_name.toLowerCase();
                        if (dataQualityStage[appName])
                            newDataArrayStage.push({
                                ...JSON.parse(JSON.stringify(dataArray)),
                                upTime: dataQualityStage[appName],
                                availability: lastValidatedStage[appName],
                            });
                        if (dataQualityProd[appName])
                            newDataArrayProd.push({
                                ...JSON.parse(JSON.stringify(dataArray)),
                                upTime: dataQualityProd[appName],
                                availability: lastValidatedProd[appName],
                            });
                    });

                    const availableCards = [
                        "edw",
                        "jira",
                        "pulse",
                        "sfdc",
                        "gso",
                        "manufacturing",
                        // "ADOBE ANALYTICS - WIP",
                        "google_analytics",
                        "netsuite",
                        // "PEOPLE AI - WIP",
                        "xactly",
                        // "XRAY - WIP",
                        "zuora",
                        "csm",
                    ];
                    let cardArrayStage = [];
                    let cardArrayProd = [];

                    for (const cardName of availableCards) {
                        const cardSingleDataStage = newDataArrayStage.find(
                            (e) =>
                                e.app_name.toUpperCase() ===
                                cardName?.toUpperCase()
                        );
                        const cardSingleDataProd = newDataArrayProd.find(
                            (e) =>
                                e.app_name.toUpperCase() ===
                                cardName?.toUpperCase()
                        );
                        if (
                            cardName === "ADOBE ANALYTICS - WIP" ||
                            cardName === "GOOGLE ANALYTICS - WIP" ||
                            cardName === "NETSUITE - WIP" ||
                            cardName === "PEOPLE AI - WIP" ||
                            cardName === "XACTLY - WIP" ||
                            cardName === "XRAY - WIP" ||
                            cardName === "ZUORA - WIP"
                        ) {
                            if (cardSingleDataStage)
                                cardSingleDataStage.environment[0].name = `Tables Monitored:- TBD`;
                            if (cardSingleDataProd)
                                cardSingleDataProd.environment[0].name = `Tables Monitored:- TBD`;
                        } else {
                            let monitorCountStage, monitorCountProd;
                            if (cardName === "clari") {
                                monitorCountStage = stageDatasyncSummary.find(
                                    (e) => e.schema_name === "sfdc"
                                )?.table_count;
                                monitorCountProd = prodDatasyncSummary.find(
                                    (e) => e.schema_name === "sfdc"
                                )?.table_count;
                            } else {
                                monitorCountStage = stageDatasyncSummary.find(
                                    (e) => e.schema_name === cardName
                                )?.table_count; // If jira, its full data
                                monitorCountProd = prodDatasyncSummary.find(
                                    (e) => e.schema_name === cardName
                                )?.table_count; // If jira, its full data
                            }
                            if (cardSingleDataStage)
                                cardSingleDataStage.environment[0].name = `Tables Monitored:- ${monitorCountStage || "Nil"
                                    }`;
                            if (cardSingleDataProd)
                                cardSingleDataProd.environment[0].name = `Tables Monitored:- ${monitorCountProd || "Nil"
                                    }`;
                        }
                        //cardSingleData['environment'][0].name = 'Tables Monitored:-' +monitorCount?.table_count
                        if (cardSingleDataStage)
                            cardArrayStage.push(cardSingleDataStage);
                        if (cardSingleDataProd)
                            cardArrayProd.push(cardSingleDataProd);
                    }
                    cardArrayProd = [
                        ...cardArrayProd,
                        {
                          app_name: "factory_data",
                        },
                    ];
                    this.setState({
                        appDataStage: cardArrayStage,
                        appDataProd: cardArrayProd,
                        no_of_days: v_no_ofdays,
                        apiProgress: {
                            loading: false,
                            ready: true,
                            error: false,
                        },
                    });
                })
                .catch((e) => {
                    logger.error(e,
                        "data_health_client",
                        window.location.href
                    );

                    this.setState({
                        apiProgress: {
                            loading: false,
                            ready: false,
                            error: e.message,
                        },
                    });
                });
        } catch (e) {
            logger.error(e, "data_health_client",
                window.location.href);
        }
    };

    render() {
        // const chart_name = graphname;
        const { appDataStage, appDataProd, no_of_days, apiProgress } =
            this.state;

        return (
            <Row>
                <Col span={24}>
                    {/* <div className={"page-title-wrapper"}> */}
                    <div
                        style={{
                            display: "flex",
                            paddingTop: 15,
                            justifyContent: "space-between",
                            paddingLeft: "35px",
                            paddingBottom: 15,
                            alignItems: "center",
                            paddingRight: 50,
                            background: "#ececec",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px",
                            }}
                        >
                            <h1 style={{ fontSize: "1.7em" }}>Data Health</h1>
                            {/* <h4>
                        <TimeAgo
                            datetime={moment().format(
                                "YYYY-MM-DD HH:mm:ss"
                            )}
                            locale="en"
                        />
                </h4> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: 16,
                                alignItems: "center",
                            }}
                        >
                            {/* <TableSearch /> */}
                            <CardPopover>
                                <div>
                                    <VideoCameraOutlined
                                        style={{ fontSize: 18 }}
                                    />
                                </div>
                            </CardPopover>
                        </div>
                    </div>
                    {/* </div> */}
                    {apiProgress?.error && (
                        <Alert
                            message="Error"
                            description={apiProgress.error}
                            type="error"
                            showIcon
                        />
                    )}
                    <Spin
                        spinning={apiProgress.loading}
                        tip={"Loading.please wait..."}
                    >
                        <Card
                            bordered={false}
                            className="n-card"
                            style={{
                                height: "calc(100vh - 122px)",
                                padding: 10,
                                overflow: "auto",
                            }}
                        >
                            <div className="n-content wrapperTiles">
                                <div className="card-container">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                            paddingTop: 15,
                                            justifyContent: "space-between",
                                            paddingBottom: 15,
                                        }}
                                    >
                                        <h2>
                                            ETL: Data Quality Metrics
                                        </h2>
                                        <TableSearch
                                            envData={this.state.prodData}
                                        />
                                        {/* <h5>
                                            <TimeAgo
                                                datetime={moment().format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                )}
                                                locale="en"
                                            />
                                        </h5> */}
                                    </div>
                                    <TilesDataSync
                                        app_data={appDataProd}
                                        no_of_days={no_of_days}
                                    // visibleReports={this.state.dbdata.find((e)=>e.schema_name==="csm")?[
                                    //     "SFDC",
                                    //     "NETSUITE",
                                    //     "CSM"
                                    // ]:[
                                    //     "SFDC",
                                    //     "NETSUITE"
                                    // ]}
                                    />
                                    <br />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                            paddingTop: 15,
                                            justifyContent: "space-between",
                                            paddingBottom: 15,
                                        }}
                                    >
                                        <h2>
                                            DATABASE: Production and Stage Sync
                                        </h2>
                                        <TableSearch
                                            envData={this.state.stageData}
                                        />
                                        {/* <h5>
                                            <TimeAgo
                                                datetime={moment().format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                )}
                                                locale="en"
                                            />
                                        </h5> */}
                                    </div>
                                    <TilesDataSyncProdStage
                                        app_data={appDataStage}
                                        no_of_days={no_of_days}
                                    // visibleReports={[
                                    //     "EDW",
                                    //     "JIRA",
                                    //     "PULSE",
                                    //     "GSO",
                                    //     "MANUFACTURING",
                                    //     "google analytics",
                                    //     "XACTLY",
                                    //     "ZUORA"
                                    // ]}
                                    />

                                </div>
                            </div>
                        </Card>
                    </Spin>
                </Col>
            </Row>
        );
    }
}

const CardPopover = ({ children }) => (
    <div>
        <Popover
            placement="leftBottom"
            // trigger={"click"}
            content={<PopoverContent />}
        >
            {children}
        </Popover>
    </div>
);

const PopoverContent = () => (
    <div>
        <iframe
            width="760"
            height="415"
            // src="https://www.youtube.com/embed/HKcWJjGNtqY"
            src="https://www.youtube.com/embed/u2cBiASbwgE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
        ></iframe>
    </div>
);

export default DataSync;
