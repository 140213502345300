import React from 'react'
import { Checkbox } from '@nutanix-ui/prism-reactjs'
import { useDispatch, useSelector } from 'react-redux';
import { setFooterDataMap } from '../../../../../features/JiraAnalysis/autoFixSlice';

const AutoFixFooter = () => {

  const dispatch = useDispatch();
  const {  FooterDataMap } = useSelector((state) => state.autoFixReports);
  
 

  const handleChange = (e, i) => {
    const updatedFooterDataMap = FooterDataMap.map((item, index) => {
        if (index === i) {
          return { ...item, hide: e.target.checked };
        }
        return item; 
      });
      
    dispatch(setFooterDataMap(updatedFooterDataMap))
  }

  return (
    <div className="cycleFooterContainer">
      {FooterDataMap?.map((item, index) => (
        <Checkbox
          key={item.label}
          checked={item.hide}
          color={item.color}
          id={`${item.label}`}
          label={item.label}
          onChange={(e) => handleChange(e, index)}
          style={{fontSize: '12px'}}
        />
      ))}
    </div>
  )
}

export default AutoFixFooter