import { Modal } from "antd";
import React, { Suspense, lazy } from "react";
const ViewDetailsTable = lazy(() => import("./ViewDetailsTable/ViewDetailsTable"));

const ViewDetailModal = ({modalTitle, open, onCancel, columns, dataSource}) => {
  return (
    <Modal
      title={<div style={{ fontSize: "14px" }}>{modalTitle}</div>}
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      width="90%"
      bodyStyle={{ maxHeight: "80vh", overflow: "auto", padding: "20px" }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ViewDetailsTable columns={columns} dataSource={dataSource} />
      </Suspense>
    </Modal>
  );
};

export default ViewDetailModal;
