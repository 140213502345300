import React , {useState, useEffect, memo} from "react";
import {
  Table,
  Popover,
} from "antd";
import axios from "axios";

export const StagePlatformChartPopover = memo(function StagePlatformChartPopover ({ stgData = [] ,children }) {
const [state, setState] = useState([])

   const popoverStageColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
 
    },
    {
      title: "Up Time",
      dataIndex: "uptime",
      key: "uptime",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
    }
   ];
   const convertDataStructure=()=>{

    const test = stgData.reduce((acc, curr)=>{
      if(acc[curr.platform]){
        acc[curr.platform][curr.name.toLowerCase()?.replaceAll(' ', '')] = curr.metrics_details
        return acc
       }
       acc[curr.platform] = {
         name: curr.platform, 
         [curr.name.toLowerCase()?.replaceAll(' ', '')]: curr.metrics_details
       }
       return acc
     }, {})

     const data =  Object.values(test)
     setState(data)
   }

   useEffect(()=>{
     convertDataStructure()
   }, [stgData])

   return (
    <div>
      <Popover
        placement="rightTop"
        trigger={"click"}
        content={<PopoverContent gridData={state} dataColumns={popoverStageColumns}/>}
      >
        {children}
      </Popover>
    </div>
  );
})

function PopoverContent({gridData, dataColumns}) {
  return (
    <div>
     <Table
        columns={dataColumns}
        dataSource={gridData}
        pagination={false}
     />
    </div>
  );
}

export default StagePlatformChartPopover;
