import { Modal, Table } from "@nutanix-ui/prism-reactjs";
import React from "react";
import { createDataSourceAssetModal, formatDate } from "./utility";

const AssetModal = ({
  modalVisible,
  setState,
  loading,
  loadingError,
  missingAssets,
}) => {

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const columns = [
    {
      title: <span className="bold-header">Date Shipped</span>,
      key: "date_shipped",
      render: (value) => {
        return formatDate(value);
      },
    },
    { title: <span className="bold-header">Asset</span>, key: "asset" },
    {
      title: <span className="bold-header">Factory EOD</span>,
      key: "factory_eod",
    },
    {
      title: <span className="bold-header">Mongo DB</span>,
      key: "mongo_db",
    },
    {
      title: <span className="bold-header">Postgres DB</span>,
      key: "postgres_db",
    },
  ];

  return (
    <Modal
      visible={modalVisible}
      width="900px"
      title="Missing Asset"
      onClose={handleCloseModal}
      footer={null}
      className="missingAssetModal"
    >
      <div className="modalContainer">
        <Table
          columns={columns}
          dataSource={createDataSourceAssetModal(missingAssets)}
          loading={loading}
          loadingError={loadingError}
        />
      </div>
    </Modal>
  );
};

export default AssetModal;
