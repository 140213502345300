import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextLabel, Link, Badge, FlexLayout } from "@nutanix-ui/prism-reactjs";

 const ETAAccurasyTooltip = ({ counts = {},handleClickOnToolTip }) => {
  const {
    selectedSprint} = useSelector((state) => state.filter);
  return (
    <div className="ETATooltip">
      <TextLabel
        type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
        style={{ color: "#22272E", padding: "5px" }}
        data-test-id="type-primary"
      >
        {selectedSprint[0]}
      </TextLabel>

      <FlexLayout justifyContent="space-between" padding="5px">
        <TextLabel
          type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          color="#627386"
          data-test-id="type-primary"
        >
          1st-Time ETA
        </TextLabel>
        <Link
          onClick={() => {
            handleClickOnToolTip(true);
          }}
          style={{ background: "#D7F6E1" }}
        >
          <Badge
            width={"75px"}
            color="#20794E"
            count={`${counts.total} T | ${counts.percentage + "%"}`}
            data-test-id="tag-default"
          />
        </Link>
      </FlexLayout>

      {/* <FlexLayout justifyContent="space-between" padding="5px">
        <TextLabel
          type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          color="#627386"
          data-test-id="type-primary"
        >
          2x ETA Change
        </TextLabel>
        <Link
          onClick={() => {
            handleClickOnToolTip(true);
          }}
          style={{ background: "#FFF2CE" }}
        >
          <Badge
            id="ETA-2x"
            width={"72px"}
            color="yellow"
            count="14T | 5.6%"
            data-test-id="2x-eta"
          />
        </Link>
      </FlexLayout>

      <FlexLayout justifyContent="space-between" padding="5px">
        <TextLabel
          type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          color="#627386"
          data-test-id="type-primary"
        >
          3x ETA Change
        </TextLabel>
        <Link
          onClick={() => {
            handleClickOnToolTip(true);
          }}
        >
          <Badge
            id="ETA-2x"
            width={"75px"}
            color="red"
            count="25T | 10%"
            data-test-id="tag-default"
          />
        </Link>
      </FlexLayout> */}
    </div>
  );
};

export default ETAAccurasyTooltip
