import React, { useState, useRef } from "react";
import {
  Donut,
  DonutChart,
  ResponsiveContainer,
  Cell,
} from "@nutanix-ui/recharts";
import { graphHeight } from "../../EngineeringUtils";

const donutSize = 160;
const donutThickness = 10;
const startAngle = 90;
const endAngle = -360 + startAngle;



const CurrentCoverage = ({donutData}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const donutChartRef = useRef(null);

  const onMouseEnter = (_, index) => setActiveIndex(index);
  const onMouseLeave = () => setActiveIndex(-1);

  const renderLabel = () => {
    const { value, name } =
      activeIndex > -1 ? donutData[activeIndex] : donutData[0];
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "43%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>{`${value}%`}</div>
        <div style={{ fontSize: "14px", color: "#666" }}>{name}</div>
      </div>
    );
  };

  return (
    <>
      <ResponsiveContainer height={graphHeight} width="100%">
        <DonutChart
          width={donutSize}
          height={donutSize}
          innerRef={donutChartRef}
        >
          <Donut
            data={donutData}
            innerRadius={donutSize / 2 - donutThickness}
            outerRadius={donutSize / 2}
            dataKey="value"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            startAngle={startAngle}
            endAngle={endAngle}
          >
            {donutData.map((entry) => (
              <Cell key={entry.key} fill={entry.color} />
            ))}
          </Donut>
        </DonutChart>
      </ResponsiveContainer>
      {renderLabel()}
    </>
  );
};

export default CurrentCoverage;
