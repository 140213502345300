import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  LabelList,
  Tooltip,
} from "@nutanix-ui/recharts";
import React, { useEffect } from "react";
import { formatDate, getCompletionPercentage } from "../FactoryData/utility";
import { useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../../config/config";
import AssetModal from "../FactoryData/AssetModal";
import {
  CustomizedXAxisTick,
  CustomizedYAxisTick,
  labelStyle,
} from "../EngineeringDashboard/EngineeringUtils";
import { Badge, TextLabel } from "@nutanix-ui/prism-reactjs";

function generateData(datas) {
  return datas.map((data) => {
    const { ship_date, sfdc_count, factory_eod_count, missing_assets } = data;
    const { matched, unmatched, no_shipment } = getCompletionPercentage(
      sfdc_count,
      factory_eod_count
    );
    return {
      ...data,
      name: formatDate(ship_date, "short"),
      Matched: matched,
      Missed: unmatched,
      "No Shipment": no_shipment,
      ship_date: ship_date,
      missing_assets: missing_assets,
      sfdc_count,
      factory_eod_count,
    };
  });
}

// Constants
const CHART_HEIGHT = 500;
const BAR_SIZE = 30;
const COLORS = {
  matched: "#68dc8e",
  missed: "#f55656",
  noShipment: "#edf0f2",
};
const LABEL_STYLE = { fill: "#37414b" };

const FactoryDataModal = ({ factoryData }) => {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    lastFetchedShipDate: false,
    missedModalVisible: false,
    matchedModalVisible: false,
    missingAssets: [],
    matchedAssets: [],
    loading: false,
  });

  useEffect(() => {
    if (factoryData.length > 0) {
      setData(generateData(factoryData));
    }
  }, [factoryData]);

  const handleMacthedBarClick = async (barData) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        matchedModalVisible: true,
      }));
      // Fetch data from API only if the ship_date is different
      const response = await axios.get(
        `${SERVER_URL}/manufacturing-success-serials?date_shipped=${barData.ship_date}`
      );
      const { data } = response?.data;
      setState((prevState) => ({
        ...prevState,
        matchedAssets: data,
        // lastFetchedShipDate: barData.ship_date, // Save the fetched ship_date
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loadingError: true,
      }));
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleMissedBarClick = async (barData) => {
    // Check if the ship_date is the same as the last fetched one
    if (state.lastFetchedShipDate === barData.ship_date) {
      // If it's the same, just open the modal without fetching again
      setState((prevState) => ({
        ...prevState,
        missedModalVisible: true,
      }));
      return; // Stop further execution since we don't need to fetch again
    }
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        missedModalVisible: true,
      }));
      // Fetch data from API only if the ship_date is different
      const response = await axios.get(
        `${SERVER_URL}/manufacturing-missing-serials?date_shipped=${barData.ship_date}`
      );

      const { data } = response?.data;

      setState((prevState) => ({
        ...prevState,
        missingAssets: data,
        lastFetchedShipDate: barData.ship_date, // Save the fetched ship_date
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loadingError: true,
      }));
      throw error;
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const matchedValue = payload[0].payload["Matched"];
      const missedValue = payload[0].payload["Missed"];
      const noShipmentValue = payload[0].payload["No Shipment"];
      const factory_eod_count = payload[0].payload["factory_eod_count"];
      const sfdc_count = payload[0].payload["sfdc_count"];
      // Format the display values
      const formatValue = (value) => {
        if (value == 0) return "0";
        return `${value}%`;
      };
      return (
        <div className="custom-tooltip" style={{ width: "200px" }}>
          <TextLabel
            display="block"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            {label}
          </TextLabel>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#68dc8e" text="Matched" />
              <div className="grid-item center-aligned">
                {formatValue(matchedValue)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#F55656" text="Missed" />
              <div className="grid-item center-aligned">
                {formatValue(missedValue)}
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#8a97a8" text="No Shipment" />
              <div className="grid-item center-aligned">
                {formatValue(noShipmentValue)}
              </div>
            </div> */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#22a5f7" text="Factory Eod Count" />
              <div className="grid-item center-aligned">
                {factory_eod_count}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#22a5f7" text="SFDC Count" />
              <div className="grid-item center-aligned">{sfdc_count}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  // Custom legend component
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          top: "15px",
        }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                marginRight: "12px",
                borderRadius: "50%",
              }}
            />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  // Reusable Bar Configuration
  const renderBar = (dataKey, fill, labelPosition) => {
    return (
      <Bar
        barSize={BAR_SIZE} // Increase width on hover
        dataKey={dataKey}
        stackId="a"
        fill={fill}
        onClick={async (data) => {
          if (dataKey === "Missed") {
            await handleMissedBarClick(data);
          }
          if (dataKey === "Matched") {
            await handleMacthedBarClick(data);
          }
        }}
      >
        {dataKey !== 'No Shipment' && <LabelList
          dataKey={dataKey}
          position={labelPosition}
          formatter={(value) => (value === 0 ? "" : `${value}%`)}
          style={LABEL_STYLE}
        />}
      </Bar>
    )
  }

  return (
    <>
      <ResponsiveContainer height={CHART_HEIGHT}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          className="factory_data"
          alignment="none"
          height={CHART_HEIGHT}
        >
          <CartesianGrid stroke="#eee" />
          <XAxis
            dataKey="name"
            horizontal={true}
            tick={<CustomizedXAxisTick />}
            interval={0}
          />
          <YAxis
            tickFormatter={(value) => `${value}%`}
            tick={<CustomizedYAxisTick />}
          />
          <Legend content={<CustomLegend />} />
          <Tooltip content={<CustomTooltip />} />
          {renderBar("Matched", COLORS.matched, "insideTop")}
          {renderBar("Missed", COLORS.missed, "insideTop")}
          {renderBar("No Shipment", COLORS.noShipment, "top")}
        </BarChart>
      </ResponsiveContainer>
      {state.missedModalVisible && (
        <AssetModal
          id='missedModal'
          modalVisible={state.missedModalVisible}
          setState={setState}
          loading={state.loading}
          loadingError={state.loadingError}
          assets={state.missingAssets}
        />
      )}
      {state.matchedModalVisible && (
        <AssetModal
          id='matchedModal'
          modalVisible={state.matchedModalVisible}
          setState={setState}
          loading={state.loading}
          loadingError={state.loadingError}
          assets={state.matchedAssets}
        />
      )}
    </>
  );
};

export default FactoryDataModal;
