import React, { useState } from "react";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilters,
  toggleLoadingVelocity,
  toggleVelocityAssignee,
} from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { ApplicationComponent, generateMenuForDropdown } from "../../EngineeringUtils";

const VelocityFilter = () => {
  const dispatch = useDispatch();
  const { filters, velocityFilter, loadingVelocityFilter } = useSelector(
    (state) => state.enggDashboard
  );
  const [openProduct, setOpenProduct] = useState(false)
  const [openComponent, setOpenComponent] = useState(false)
  const [openPriorities, setOpenPriorities] = useState(false)
  const [openJiraIssueType, setJiraIssueType] = useState(false)
  const [openLabels, setOpenLabels] = useState(false)
  const [openAssignee, setOpenAssignee] = useState(false)
  
  const handleClose = (id) => {
    switch (id) {
      case "velocityProducts":
        return setOpenProduct(false);
      case "velocityComponents":
        return setOpenComponent(false);
      case "velocityJiraIssueType":
        return setJiraIssueType(false);
      case "velocityIssuePriority":
        return setOpenPriorities(false);
      case "velocityLabels":
        return setOpenLabels(false);
      case "velocityAssignee":
        return setOpenAssignee(false);
      default:
        return;
    }
  };

  // Custom comparison function to sort by role with developers first
  function sortByRoleWithDevelopersFirst(a, b) {
    if (a.role === "Developer" && b.role !== "Developer") {
      return -1; // Developer comes first
    } else if (a.role !== "Developer" && b.role === "Developer") {
      return 1; // Developer comes first
    } else {
      return 0; // Maintain the order
    }
  }

  const getAssignee = (teamMembers) => {
    const sortedTeamMembers = [...teamMembers].sort(
      sortByRoleWithDevelopersFirst
    );
    const sortedNames = sortedTeamMembers.map((member) => member.name);
    return sortedNames;
  };

  const assignees = getAssignee(
    Object.keys(velocityFilter).length > 0 ? velocityFilter.assignees : []
  );

  const velocityDropdownItem = [
    {
      id: "velocityProducts",
      label: "Product",
      mode: "multiple",
      open:openProduct,
      loading:loadingVelocityFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.VELOCITY}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0 ? velocityFilter.products : []
        ),
      ],
      selectedItem: filters.selectedVelocityProduct,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityProduct",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityProduct", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityProduct", value: item })
          );
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityProduct", value: ["All"] })
          );
        }
        setOpenProduct(true)
      },
    },
    {
      id: "velocityComponents",
      label: "Component",
      mode: "multiple",
      open:openComponent,
      loading:loadingVelocityFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.VELOCITY}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0 ? velocityFilter.component : []
        ),
      ],
      selectedItem: filters.selectedVelocityComponent,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityComponent",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityComponent", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityComponent", value: item })
          );
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityComponent", value: ["All"] })
          );
        }
        setOpenComponent(true)
      },
    },
    {
      id: "velocityJiraIssueType",
      label: "Jira Issue Type",
      mode: "multiple",
      loading:loadingVelocityFilter,
      open:openJiraIssueType,
      component:`${ApplicationComponent.VELOCITY}`,
      handleClose:handleClose,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0
            ? velocityFilter.issue_types
            : []
        ),
      ],
      selectedItem: filters.selectedVelocityIssueType,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityIssueType",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityIssueType", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityIssueType", value: item })
          );
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityIssueType", value: ["All"] })
          );
        }
        setJiraIssueType(true)
      },
    },
    {
      id: "velocityIssuePriority",
      label: "Issue Priority",
      mode: "multiple",
      loading:loadingVelocityFilter,
      open:openPriorities,
      component:`${ApplicationComponent.VELOCITY}`,
      handleClose:handleClose,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0
           ? velocityFilter.priorities
           : []
        ),
      ],
      selectedItem: filters.selectedVelocityPriority,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityPriority",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityPriority", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityPriority", value: item })
          );
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityPriority", value: ["All"] })
          );
        }
      setOpenPriorities(true)
      },
    },
    {
      id: "velocityLabels",
      label: "Labels",
      mode: "multiple",
      handleClose:handleClose,
      loading:loadingVelocityFilter,
      component:`${ApplicationComponent.VELOCITY}`,
      open:openLabels,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0 ? velocityFilter.labels : []
        ),
      ],
      selectedItem: filters.selectedVelocityLabel,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityLabel",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityLabel", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityLabel", value: item })
          );
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityLabel", value: ["All"] })
          );
        }
        setOpenLabels(true)
      },
    },
    {
      id: "velocityAssignee",
      label: "Assignee",
      mode: "multiple",
      open:openAssignee,
      handleClose:handleClose,
      loading:loadingVelocityFilter,
      component:`${ApplicationComponent.VELOCITY}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(velocityFilter).length > 0 ? assignees : ["All"]
        ),
      ],
      selectedItem: filters.selectedVelocityAssignee,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({
              section: "selectedVelocityAssignee",
              value: filteredItem,
            })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedVelocityAssignee", value: ["All"] })
          );
        } else {
          dispatch(
            setFilters({ section: "selectedVelocityAssignee", value: item })
          );
        }
        dispatch(toggleVelocityAssignee(true))
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedVelocityAssignee", value: ["All"] })
          );
        }
        setOpenAssignee(true)
      },
    },
    {
      id: "chooseVelocity",
      label: "Velocity For",
      mode: "single",
      items: generateMenuForDropdown([
        "Production",
        "UAT"
      ], "chooseVelocity"),
      selectedItem: filters.selectedVelocity,
      onChangeItem: (item) => {
        dispatch(setFilters({ section: "selectedVelocity", value: item }));
        dispatch(toggleLoadingVelocity(true));
      },
    },
    {
      id: "chooseViewByVelocity",
      label: "View By",
      mode: "single",
      items: generateMenuForDropdown(["Quarterly", "Monthly"]),
      selectedItem: filters.viewByVelocity,
      onChangeItem: (item) => {
        dispatch(setFilters({ section: "viewByVelocity", value: item }));
        dispatch(toggleLoadingVelocity(true));
      },
    },
  ];
  return (
    <div
      className="filterDropdownContainer filterVelocityContainer"
      data-testid="jira-ssot-filter-container"
    >
      {velocityDropdownItem.map((dropdown) => (
        <FilterDropdown key={dropdown.id} {...dropdown} />
      ))}
    </div>
  );
};

export default VelocityFilter;
