import React, { useContext, useState } from "react";

import {
    Alert, FlexItem,
    FlexLayout, FormItemInput
} from "@nutanix-ui/prism-reactjs";

import '../styles.css';

import { TemplateDataContext, TemplateFormContext } from "../../../FileTemplateUploadDashboard";



export default function TemplateDetailsForm() {

    const [templateData, setTemplateData] = useContext(TemplateDataContext);
    const [formData, setFormData] = useContext(TemplateFormContext);

    const [templateName, setTemplateName] = useState(formData?.templateName || '');
    const [desc, setDesc] = useState(formData?.desc || '');
    const [validation, setValidation] = useState(formData?.validation || '');
    const [aggregator, setAggregator] = useState(formData?.aggregator || '');
    const [isError, setIsError] = useState(false);

    const handleNameChange = (e) => {
        setTemplateName(e.target.value);
        setFormData({ ...formData, 
            templateName: e.target.value,
            desc: e.target.value+ ' Description',
            validation: e.target.value +'_val',
            aggregator: e.target.value+ '_agg'
         });
        
        let found = templateData.find(function (element) {
            if (element.templateName === e.target.value) {
                return true;
            }
        });
        if (found) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }

    const handleDescriptionChange = (e) => {
        setDesc(e.target.value);
        setFormData({ ...formData, desc: e.target.value });
    }

    const handleValidationChange = (e) => {
        setValidation(e.target.value);
        setFormData({ ...formData, validation: e.target.value });
    }

    const handleAggregatorChange = (e) => {
        setAggregator(e.target.value);
        setFormData({ ...formData, aggregator: e.target.value });
    }
    
    return <div className="templateDetailsContainer">
        <FlexLayout
            flexDirection="column"
        >
            <FlexItem><h3>Add Template Details</h3></FlexItem>
            {isError && <Alert aria-live="off" inline={true} type={Alert.AlertTypes.ERROR}
                data-test-id="type-error" message="Please use unique Template Name " />}
            <FlexLayout flexDirection="row">
                <FlexItem style={{ width: '50%' }}>
                    <FormItemInput className="textField" placeholder="Type Template Name" label="Name" width='100%' value={templateName} onChange={handleNameChange}
                        error={isError} data-testid="fileName" wrapperProps={{ 'data-test-id': 'default' }} />
                </FlexItem>
                <FlexItem style={{ width: '50%' }}>
                    <FormItemInput className="textField" placeholder="Please type description here" label="Description" width='100%' value={desc} onChange={handleDescriptionChange}
                        data-testid="fileDesc" wrapperProps={{ 'data-test-id': 'default' }} />
                </FlexItem>
            </FlexLayout>
            <FlexLayout flexDirection="row">
                <FlexItem style={{ width: '50%' }}>
                    <FormItemInput className="textField" label="Validation Function" placeholder="Type Validation Function" width='100%' value={validation} onChange={handleValidationChange}
                        data-testid="fileName" wrapperProps={{ 'data-test-id': 'default' }} />
                </FlexItem>
                <FlexItem style={{ width: '50%' }}>
                    <FormItemInput className="textField" label="Aggregator Function" width='100%' placeholder="Type Aggregator Function" value={aggregator} onChange={handleAggregatorChange}
                        data-testid="fileDesc" wrapperProps={{ 'data-test-id': 'default' }} />
                </FlexItem>
            </FlexLayout>
        </FlexLayout>
    </div>
}
