import React, { useState } from "react";
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import { useDispatch, useSelector } from "react-redux";
import { setFilters, toggleLoadingCycle } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { ApplicationComponent, generateMenuForDropdown } from "../../EngineeringUtils";

const CycleFilter = ({ dropdowns }) => {
  const dispatch = useDispatch();
  const { filters, cycleFilter, loadingCycleFilter } = useSelector((state) => state.enggDashboard);
  const [openProduct, setOpenProduct] = useState(false)
  const [openComponent, setOpenComponent] = useState(false)
  const [openStages, setOpenStages] = useState(false)
  const [openJiraIssueType, setJiraIssueType] = useState(false)
  const [openPriorities, setOpenPriorites] = useState(false)
  const [openLabels, setOpenLabels] = useState(false)
  const [openStoryPoints, setOpenStoryPoints] = useState(false)
  const [openAssignee, setOpenAssignee] = useState(false)
  const [openQAContact, setOpenQAContact] = useState(false)

  const handleClose = (id) => {
    switch (id) {
      case 'cycleProducts':
        return setOpenProduct(false)
      case 'cycleComponents':
        return setOpenComponent(false)
      case 'cycleStages':
        return setOpenStages(false)
      case 'cycleJiraIssueType':
        return setJiraIssueType(false)
      case 'cycleIssuePriority':
        return setOpenPriorites(false)
      case 'cycleLabels':
        return setOpenLabels(false)
      case 'cycleStoryPoints':
        return setOpenStoryPoints(false)
      case 'cycleAssignee':
        return setOpenAssignee(false)
      case 'cycleQaContact':
        return setOpenQAContact(false)
      default:
        return
    }
  }

  const dropdownItemsTop = [
    {
      id: "cycleProducts",
      label: "Product",
      mode: "multiple",
      open:openProduct,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.products : []
        ),
      ],
      selectedItem: filters.selectedProduct,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedProduct", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(setFilters({ section: "selectedProduct", value: ["All"] }));
        } else {
          dispatch(setFilters({ section: "selectedProduct", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedProduct", value: ["All"] }));
        }
        setOpenProduct(true)
      },
    },
    {
      id: "cycleComponents",
      label: "Component",
      mode: "multiple",
      open:openComponent,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.components : []
        ),
      ],
      selectedItem: filters.selectedComponent,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedComponent", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(setFilters({ section: "selectedComponent", value: ["All"] }));
        } else {
          dispatch(setFilters({ section: "selectedComponent", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedComponent", value: ["All"] }));
        }
        setOpenComponent(true)
      },
    },
    {
      id: "cycleStages",
      label: "Stages",
      mode: "multiple",
      open:openStages,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.stages : []
        ,"cycleStages"),
      ],
      selectedItem: filters.selectedStage,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedStage", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(setFilters({ section: "selectedStage", value: ["All"] }));
        } else {
          dispatch(setFilters({ section: "selectedStage", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedStage", value: ["All"] }));
        }
        setOpenStages(true)
      },
    },
    {
      id: "cycleJiraIssueType",
      label: "Jira Issue Type",
      mode: "multiple",
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      open:openJiraIssueType,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.issue_types : []
        ),
      ],
      selectedItem: filters.selectedIssueType,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedIssueType", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(
            setFilters({ section: "selectedIssueType", value: ["All"] })
          );
        } else {
          dispatch(setFilters({ section: "selectedIssueType", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(
            setFilters({ section: "selectedIssueType", value: ["All"] })
          );
        }
        setJiraIssueType(true)
      },
    },
    {
      id: "cycleIssuePriority",
      label: "Issue Priority",
      mode: "multiple",
      open:openPriorities,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.priorities : []
        ),
      ],
      selectedItem: filters.selectedPriority,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedPriority", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(setFilters({ section: "selectedPriority", value: ["All"] }));
        } else {
          dispatch(setFilters({ section: "selectedPriority", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedPriority", value: ["All"] }));
        }
        setOpenPriorites(true)
      },
    },
    {
      id: "cycleLabels",
      label: "Labels",
      mode: "multiple",
      open:openLabels,
      loading:loadingCycleFilter,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      handleClose:handleClose,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.labels : []
        ),
      ],
      selectedItem: filters.selectedLabel,
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          dispatch(
            setFilters({ section: "selectedLabel", value: filteredItem })
          );
        } else if (item.length === 0) {
          dispatch(setFilters({ section: "selectedLabel", value: ["All"] }));
        } else {
          dispatch(setFilters({ section: "selectedLabel", value: item }));
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedLabel", value: ["All"] }));
        }
        setOpenLabels(true)
      },
    },
  ];
  const dropdownItemsBottom = [
    {
      id: "cycleStoryPoints",
      label: "Story Points",
      mode: "multiple",
      open:openStoryPoints,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.story_points : []
        ),
      ],
      selectedItem: filters.selectedStoryPoint,
      onChangeItem: (item) => {
        if(item.includes("All") && item.length > 1){
          const filteredItem = item.filter(state => state !== 'All')
          dispatch(setFilters({ section: "selectedStoryPoint", value: filteredItem }))
        }else if(item.length === 0){
          dispatch(setFilters({ section: "selectedStoryPoint", value: ['All'] }))
        }else{
          dispatch(setFilters({ section: "selectedStoryPoint", value: item }))
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedStoryPoint", value: ['All'] }))
        }
        setOpenStoryPoints(true)
      },
    },
    {
      id: "cycleAssignee",
      label: "Assignee",
      mode: "multiple",
      open:openAssignee,
      loading:loadingCycleFilter,
      handleClose:handleClose,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.assignees : []
        ),
      ],
      selectedItem: filters.selectedAssignee,
      onChangeItem: (item) => {
        if(item.includes("All") && item.length > 1){
          const filteredItem = item.filter(state => state !== 'All')
          dispatch(setFilters({ section: "selectedAssignee", value: filteredItem }))
        }else if(item.length === 0){
          dispatch(setFilters({ section: "selectedAssignee", value: ['All'] }))
        }else{
          dispatch(setFilters({ section: "selectedAssignee", value: item }))
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedAssignee", value: ['All'] }))
        }
        setOpenAssignee(true)
      },
    },
    {
      id: "cycleQaContact",
      label: "QA Contact",
      mode: "multiple",
      loading:loadingCycleFilter,
      component:`${ApplicationComponent.CYCLE_TIME}`,
      open:openQAContact,
      handleClose:handleClose,
      items: [
        { label: "All", value: "All" },
        ...generateMenuForDropdown(
          Object.keys(cycleFilter).length > 0 ? cycleFilter.qa_contacts : []
        ),
      ],
      selectedItem: filters.selectedQAContact,
      onChangeItem: (item) => {
        if(item.includes("All") && item.length > 1){
          const filteredItem = item.filter(state => state !== 'All')
          dispatch(setFilters({ section: "selectedQAContact", value: filteredItem }))
        }else if(item.length === 0){
          dispatch(setFilters({ section: "selectedQAContact", value: ['All'] }))
        }else{
          dispatch(setFilters({ section: "selectedQAContact", value: item }))
        }
      },
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          dispatch(setFilters({ section: "selectedQAContact", value: ['All'] }))
        }
        setOpenQAContact(true)
      },
    },
    {
      id: "cycleTicketProgress",
      label: "Include Tickets",
      mode: "single",
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: generateMenuForDropdown([ "All", "Only Closed"], "cycleTicketProgress"),
      selectedItem: filters.selectedTicketProgress,
      onChangeItem: (item) => {
        dispatch(setFilters({ section: "selectedTicketProgress", value: item }))
        dispatch(toggleLoadingCycle(true));
      }
    },
    {
      id: "cycleTime",
      label: "Cycle Time",
      mode: "single",
      component:`${ApplicationComponent.CYCLE_TIME}`,
      items: generateMenuForDropdown(["Total Day Spent", "Average", "Median", "Percentile 90th Day Spent"]),
      selectedItem: filters.selectedCycleTime,
      onChangeItem: (item) => {
        dispatch(setFilters({ section: "selectedCycleTime", value: item }))
      },
    
    },
  ];
  return (
    <div
      className="filterDropdownContainer filterCycle"
      data-testid="jira-ssot-filter-container"
    >
      {dropdownItemsTop.map((dropdown) => (
        <FilterDropdown key={dropdown.id} {...dropdown} />
      ))}
      {dropdownItemsBottom.map((dropdown) => (
        <FilterDropdown key={dropdown.id} {...dropdown} />
      ))}
    </div>
  );
};

export default CycleFilter;
