import React, { useState } from "react";
import { FlowAnalysisGraph } from "@ant-design/graphs";

const tableLimiter = 25;
const reportLimiter = 30;

const getReportItems = (reportName, lastSuccessfulRun, statusPair) => [
    ...(reportName && reportName.length > reportLimiter
        ? [
              {
                  text: reportName.slice(0, reportLimiter),
              },
              {
                  text: reportName.slice(reportLimiter),
              },
          ]
        : [
              {
                  text: reportName || "<Not Defined>",
              },
          ]),
    {
        text: "Extract Status ->",
        value: statusPair.value,
    },
    {
        text: "Last Extract ->",
        value: lastSuccessfulRun || "NA",
    },
];

const getTableItems = (table, lastRefresh) => [
    ...(table.length > tableLimiter
        ? [
              {
                  text: table.slice(0, tableLimiter),
              },
              {
                  text: table.slice(tableLimiter),
              },
          ]
        : [
              {
                  text: table,
              },
          ]),
    {
        text: `Last refresh (PDT): ${lastRefresh}`,
    },
];

const LineagePlot = ({ lineageGraphData }) => {
    const {
        uniqueSchemas,
        uniqueTables,
        edgesData,
        reportNodeID,
        reportName,
        reportStatus,
        lastSuccessfulRun,
    } = lineageGraphData;

    const statusPair =
        reportStatus === 1
            ? { value: "SUCCESS", fillColor: "#008000" }
            : { value: "FAILED", fillColor: "#ff0000" };
    const reportItems = getReportItems(
        reportName,
        lastSuccessfulRun,
        statusPair
    );

    const data = {
        nodes: [
            ...uniqueSchemas.map((ele) => ({
                id: ele.id,
                value: {
                    title: "Schema",
                    items: [
                        {
                            text: `${ele.schema} (${ele.tables})`,
                        },
                    ],
                },
            })),
            ...uniqueTables.map((ele) => ({
                id: ele.id,
                value: {
                    title: "Table",
                    items: getTableItems(ele.table, ele.lastRefresh),
                },
            })),
            {
                id: reportNodeID,
                value: {
                    title: "Report",
                    items: reportItems,
                },
            },
        ],
        edges: edgesData,
    };
    const config = {
        data,
        // autoFit: false,
        width: 1000,
        height: 800,
        nodeCfg: {
            // type: "rect",
            size: [230, 40],
            items: {
                containerStyle: {
                    fill: "#fff",
                },
                padding: 6,
                style: (cfg, group, type) => {
                    const styles = {
                        text: {
                            fill: "#0000ff",
                        },
                        value: {
                            fill: statusPair.fillColor,
                        },
                    };
                    return styles[type];
                },
            },
            nodeStateStyles: {
                hover: {
                    stroke: "#1890ff",
                    lineWidth: 2,
                },
            },
            title: {
                containerStyle: {
                    fill: "transparent",
                },
                style: {
                    fill: "#000",
                    fontSize: 12,
                },
            },
            style: {
                fill: "#E6EAF1",
                stroke: "#B2BED5",
                radius: [2, 2, 2, 2],
            },
        },
        edgeCfg: {
            // type: "polyline",
            startArrow: true,
            endArrow: false,
            style: (edge) => {
                const red = "#ff0000",
                    green = "#5ae859";
                const stroke = edge.health ? green : red;
                return {
                    stroke,
                    lineWidth: Math.random() * 10 + 1,
                    strokeOpacity: 0.5,
                };
            },
            edgeStateStyles: {
                hover: {
                    strokeOpacity: 1,
                },
            },
        },
        markerCfg: (cfg) => {
            const { edges } = data;
            // console.log(edges.find((item) => item.source === cfg.id));
            const isSchema = uniqueSchemas.find((ele) => ele.id === cfg.id);
            return {
                position: "left",
                show: edges.find((item) => item.source === cfg.id),
                collapsed:
                    isSchema || !!!edges.find((item) => item.source === cfg.id),
            };
        },
        onReady: (graph) => {
            uniqueTables.forEach((ele) => {
                graph.hideItem(ele.id);
            });
            graph.updateLayout({
                rankdir: "RL",
                nodesepFunc: () => 0, // vertical distance between nodes
                ranksepFunc: () => 100, // horizontal distance
            });
            graph.refresh();
            graph.on("node:click", (evt) => {
                const item = evt.item;
                const node = item?._cfg;
                // console.log(node);
                const title = node?.model?.value?.title;
                // const collapsed = node?.model?.collapsed;
                if (title !== "Schema" && title !== "Report") return;
                // if (collapsed) {
                // console.log("inside if collapsed")
                graph.updateLayout({
                    rankdir: "RL",
                    nodesepFunc: () => 0, // vertical distance between nodes
                    ranksepFunc: () => 100, // horizontal distance
                });
                graph.refresh();
                // }
            });
        },
        layout: {
            rankdir: "RL",
            nodesepFunc: () => 0, // vertical distance between nodes
            ranksepFunc: () => 100, // horizontal distance
        },
        behaviors: ["drag-canvas", "scroll-canvas", "drag-node"],
        // behaviors: ["drag-node"],
    };

    return <FlowAnalysisGraph {...config} />;
};

export default LineagePlot;
