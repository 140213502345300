import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemTextArea,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  FlexLayout,
  TextLabel,
  PlusIcon,
  FlexItem,
  CloseIcon,
  FormItemProvider,
  MultiSelect
} from "@nutanix-ui/prism-reactjs";


import axios from "axios";
import { SERVER_URL } from "../../config/config";
import { useLocation } from "react-router-dom";

export default function SnowFlakeModalEdit(props) {
    const SelectWithLabel = FormItemProvider(MultiSelect);
    const location = useLocation();
    const { schemaTitle, data, handleOnSave, origin } = props;
    const DEFAULT_VALIDATIONS = [
      {
        alias: {
          [Form.VALIDATION_RULE.REQUIRED]: false,
          message: "Alias is required",
        },
      },
      {
        warehouse: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Warehouse is required",
        },
      },
      {
        db: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Database is required",
        },
      },
      {
        start_offset: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Starting Offset is required",
        },
      },
      {
        last_date: {
          [Form.VALIDATION_RULE.REQUIRED]: true,
          message: "Last Date is required",
        },
      },
      {
        query: {
          [Form.VALIDATION_RULE.REQUIRED]: origin === "Source" ? false : true,
          message: "Query is required",
        },
      },
    ];
    const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
  
    useEffect(() => {
      if (origin === "Source" && location.state?.scheduleType === "streaming") {
        validations.pop();
        setValidations(validations);
      }
      if (origin === "Target") {
        validations.shift();
        setValidations(validations);
      }
      let payload = {
        project_id: projectID,
        conn_name: data?.schemaValue,
        schema_name: data?.schemaForm?.label,
        object_name: data?.tableName?.label,
      };
      getTableColumns(payload);// eslint-disable-next-line 
    }, [data]);
  
    const [selectedTableName, setSelectedTableName] = useState(null);
  
    const [selectedSchema, setSelectedSchema] = useState(null);
    const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
    const [schemaAndTableData, setSchemaAndTableData] = useState([]);
  
    const [schemaData, setSchemaData] = useState([]);
    const [searchSchemaData, setSearchSchemaData] = useState([]);
    const [schemaLoading, setSchemaLoading] = useState(true);
  
    const [tableNameData, setTableNameData] = useState([]);
    const [searchTableNameData, setSearchTableNameData] = useState([]);// eslint-disable-next-line 
    const [tableLoading, setTableLoading] = useState(true);
  
    const {
      alias,
      id,
      query,
      last_date,
      columns,
      offset_col,
      start_offset,
      warehouse,
      db,
      schemaValue,
      schema_mapping,
      schemaForm,
      tableName,
      uuid_columns,
      source_cluster_uuid,
      truncate_and_load
    } = data;
    const [selectedTableColsName, setSelectedTableColsName] = useState(null);
    const [tableColsNameData, setTableColsNameData] = useState([]);
    const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
    const [tableColsLoading, setTableColsLoading] = useState(true);
    const [schemaText, setSchemaText] = useState("");
    let schemaMappingData =
      data.dbType === "RDBMS" && origin === "Source" && schema_mapping
        ? typeof schema_mapping ==='string'?JSON.parse(schema_mapping) :schema_mapping
        : [];
    const [schemaRowsData, setSchemaRowsData] = useState(schemaMappingData);
    const [noSQLSchemaText, setNOSQLSchemaText] = useState("");
    const [noSQLSchemaRows, setNOSQLSchemaRows] = useState(schemaMappingData);
    const [selectedColumns, setSelectedColumns] = useState(
      data?.columnsData || []
    );
    const startOffsetData = [
      { key: "earliest", label: "earliest", rowProps:{
        'data-testid':'earliest'
       } },
      { key: "latest", label: "latest", rowProps:{
        'data-testid':'latest'
       } },
    ];
    useEffect(() => {
      fetchSchemaAndTable();
  
      setSelectedTableName((prevState) => {
        return {
          key: data?.tableName?.key,
          label: data?.tableName?.label,
        };
      });
      setSelectedSchema((prevState) => {
        return {
          key: data?.schemaForm?.key,
          label: data?.schemaForm?.label,
        };
      });
      setSelectedOffsetCol((prevState) => {
        return {
          key: data?.offset_col?.key || '',
          label: data?.offset_col?.label || '',
        };
      });
      setSchemaRowsData(schemaMappingData);
      setSelectedColumns(data?.columnsData || []);// eslint-disable-next-line 
    }, [data]);
    const addSchemaRows = async () => {
      let obj = { key: schemaText };
      obj[selectedTableColsName?.label] = obj["key"];
      delete obj["key"];
      schemaRowsData.push(obj);
      setSchemaRowsData(schemaRowsData);
      setSelectedTableColsName(null);
      setSchemaText("");
    };
    const removeSchemaRows = (index) => {
      const newRows = schemaRowsData.filter((sale, i) => i !== index);
      setSchemaRowsData(newRows);
    };
    const addNOSQLSchemaRows = () => {
      noSQLSchemaRows.push(noSQLSchemaText);
      setNOSQLSchemaRows(noSQLSchemaRows);
      setNOSQLSchemaText("");
    };
    const removeNOSQLSchemaRows = (index) => {
      const newRows = noSQLSchemaRows.filter((sale, i) => i !== index);
      setSchemaRowsData(newRows);
    };
    const handleEditSchemaRows = (objKVType, value, i) => {
      let key = Object.keys(schemaRowsData[i])[0];
      if (objKVType === "value") {
        schemaRowsData[i][key] = value;
      } else {
        schemaRowsData[i][value.label] = schemaRowsData[i][key];
        delete schemaRowsData[i][key];
      }
    };
    const handleOnchangeNOSQLSchmeaText = (text, index) => {
      if (index > -1) {
        noSQLSchemaRows[index] = text;
        setNOSQLSchemaRows(noSQLSchemaRows);
      } else {
        setNOSQLSchemaText(text);
      }
    };
    const handleTableNameChange = (item) => {
      setSelectedTableName((prevState) => item);
      let payload = {
        project_id: projectID,
        conn_name: schemaValue,
        schema_name: selectedSchema?.label,
        object_name: item?.label,
      };
      getTableColumns(payload);
    };
    const getTableColumns = (payload) => {
      axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {
        if (resp?.data?.data?.res) {
          setTableColsLoading(false);
          const tableColKeys = Object.keys(resp.data.data.res);
          let TablleColsData = [];
          tableColKeys.forEach((o, i) => {
            TablleColsData.push({
              id: ++i,
              key: o,
              label: o,
            });
          });
          setTableColsNameData((prevState) => TablleColsData);
          setSearchTableColsNameData((prevState) => TablleColsData);
        }
      });
    };
    const handleSchemaChange = (item) => {
      setSelectedSchema((prevState) => item);
      const tableKeys = schemaAndTableData&&schemaAndTableData[[item.key]] ? Object.keys(schemaAndTableData[item?.key]) : [];
      let tempData = [];
      tableKeys?.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setTableNameData((prevState) => tempData);
      setSearchTableNameData((prevState) => tempData);
      setTableLoading((prevState) => false);
    };
      const handleOnInputValueChange = (
        event,
        searchArray,
        setSearchArray,
        setSelectedValue
      ) => {
        if (event.target.value.length > 0) {
          if(searchArray.length===0){
            setSchemaLoading(false);
            setTableColsLoading(false)
          }
          const newRows = searchArray?.filter((row) => {
            return row.label.toLowerCase().includes(event.target.value.toLowerCase());
          });
          newRows.unshift({ key: event.target.value, label: event.target.value })
          setSearchArray(newRows);
          setSelectedValue({ key: event.target.value, label: event.target.value });
        } else if (event.target.value.length === 0) {
          setSearchArray(searchArray);
          setSelectedValue(null);
        }
      };
    const fetchSchemaAndTable = (value) => {
      if (schemaValue) {
        setSchemaLoading((prevState) => true);
        setTableLoading((prevState) => true);
        axios
          .post(`${SERVER_URL}/fetchSchemaAndTable`, {
            project_id: projectID,
            conn_name: schemaValue ? schemaValue : null,
          })
          .then((resp) => {
            constructSchemaData(resp.data.data.res);
          });
      }
    };
  
    const constructSchemaData = (res) => {
      setSchemaAndTableData((prev) => res);
      let tempData = [];
      let schemaKeys = Object.keys(res);
      schemaKeys.forEach((o) => {
        tempData.push({
          key: o,
          label: o,
          rowProps:{
            'data-testid':o
           }
        });
      });
      setSchemaData((prevState) => tempData);
      setSearchSchemaData((prevState) => tempData);
      setSchemaLoading((prevState) => false);
      if (data?.schemaForm?.key) {
        const tableKeys = Object.keys(res[data?.schemaForm?.key]);
        let tempData1 = [];
        tableKeys.forEach((o) => {
          tempData1.push({
            key: o,
            label: o,
            rowProps:{
              'data-testid':o
             }
          });
        });
        setTableNameData((prevState) => tempData1);
        setSearchTableNameData((prevState) => tempData1);
        setTableLoading((prevState) => false);
      }
    };
    const propsData = {
      rowsData: tableColsNameData || [],
      groupNestedItemsTag: false,
      selectAllOption: true,
    };
  
    return (
      <Modal
        visible={props.visible}
        title={schemaTitle || "Schema"}
        onClose={props.handleOnClose}
        closeIconProps={{ "data-test": "process-pipeline-data-test" }}
        footer={false}
      >
        <StackingLayout padding="20px">
          <Form
            key={data?.index}
            validations={validations}
            onSubmit={(values) => {
              handleOnSave(
                values,
                schemaRowsData,
                noSQLSchemaRows,
                selectedColumns
              );
            }}
            defaultValues={{
              id: id,
              alias: alias,
              start_offset: start_offset,
              offset_col: offset_col || "",
              query: atob(query?.pipeLine?.queryData?.value), // nexted object format,
              columns: columns,
              last_date: last_date,
              warehouse: warehouse,
              db: db,
              schemaForm: schemaForm,
              tableName: tableName,
              uuid_columns: uuid_columns,
              source_cluster_uuid: source_cluster_uuid,
              truncate_and_load: truncate_and_load,
              index: data?.index,
            }}
          >
            {(formHelper) => {
              return (
                <FormSection>
                  <FormItemInput
                    id="id"
                    disabled
                    formHelper={formHelper}
                    label="Input ID"
                  />
                  {origin !== "Target" && (
                    <FormItemInput
                      id="alias"
                      data-testid='paas-etlCanvas-snowflakeEdit-formModal-alias'
                      formHelper={formHelper}
                      label={"Alias"}
                    />
                  )}
                  <FormItemInput
                    id="warehouse"
                    disabled
                    formHelper={formHelper}
                    label="Warehouse"
                  />
                  <FormItemInput
                    id="db"
                    disabled
                    formHelper={formHelper}
                    label="Database"
                  />
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-schema'
                    id="schemaForm"
                    selectedRow={selectedSchema}
                    rowsData={searchSchemaData}
                    loading={schemaLoading}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleSchemaChange(item)}
                    label={"Schema"}
                    inputProps={{ value: selectedSchema?.label || "" }}
                    searchable={true}
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        schemaData,
                        setSearchSchemaData,
                        setSelectedSchema
                      )
                    }
                  />
                  <FormItemSelect
                  data-testid='paas-etlCanvas-snowflakeEdit-formModal-tableName'
                    id="tableName"
                    selectedRow={selectedTableName}
                    rowsData={searchTableNameData}
                    formHelper={formHelper}
                    onSelectedChange={(item) => handleTableNameChange(item)}
                    label={"Table Name"}
                    inputProps={{ value: selectedTableName?.label || "" }}
                    searchable={true}
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        tableNameData,
                        setSearchTableNameData,
                        setSelectedTableName
                      )
                    }
                  />
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-offset_col'
                    id="offset_col"
                    rowsData={searchTableColsNameData}
                    loading={tableColsLoading}
                    formHelper={formHelper}
                    label={"Offset Column"}
                    onSelectedChange={(item) => setSelectedOffsetCol(item)}
                    helpText="Optional"
                    selected={selectedOffsetCol}
                    inputProps={{ value: selectedOffsetCol?.label || "" }}
                    searchable={true}
                    onChange={(event) =>
                      handleOnInputValueChange(
                        event,
                        tableColsNameData,
                        setSearchTableColsNameData,
                        setSelectedOffsetCol
                      )
                    }
                  />
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid='paas-etlCanvas-snowflakeEdit-formModal-uuid_columns'
                      id="uuid_columns"
                      formHelper={formHelper}
                      label={"UUID Columns"}
                      helpText="Optional"
                    />
                  )}
                  {origin === "Target" && (
                    <FormItemInput
                      data-testid='paas-etlCanvas-snowflakeEdit-formModal-truncate_and_load'
                      id="truncate_and_load"
                      formHelper={formHelper}
                      label={"Truncate And Load"}
                      helpText="Optional"
                    />
                  )}
                  <FormItemSelect
                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-start_offset'
                    id="start_offset"
                    rowsData={startOffsetData}
                    formHelper={formHelper}
                    label={"Starting Offset"}
                  />
                  <FormItemInput
                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-last_date'
                    id="last_date"
                    formHelper={formHelper}
                    label={"Last Date"}
                  />
                  <SelectWithLabel
                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-columns'
                    selectedRows={selectedColumns || []}
                    onSelectedChange={(rows) => {
                      setSelectedColumns(rows);
                    }}
                    formItemProviderProps={{ id: "columns" }}
                    overflowLimit={2}
                    {...propsData}
                    label={"Columns"}
                  />
                  {origin === "Source" &&
                  location.state?.scheduleType === "streaming" ? (
                    ""
                  ) : (
                    <FormItemTextArea
                      data-testid='paas-etlCanvas-snowflakeEdit-formModal-query'
                      id="query"
                      label="Query"
                      formHelper={formHelper}
                      placeholder="Please Type your query here"
                      style={{ height: "10rem" }}
                    />
                  )}
                  {origin === "Source" && (
                    <StackingLayout>
                      <TextLabel
                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                        data-test-id="type-primary"
                      >
                        Schema Configuration
                      </TextLabel>
                      {data.dbType === "RDBMS" ? (
                        <StackingLayout>
                          {schemaRowsData?.length > 0 &&
                            schemaRowsData?.map((row, i) => (
                              <FlexLayout
                                flexDirection="row"
                                alignItems="flex-end"
                                key={i}
                              >
                                <FormItemSelect
                                  data-testid='paas-etlCanvas-snowflakeEdit-formModal-addColumns'
                                  id={i}
                                  selectedRow={{
                                    key: Object.keys(row)[0],
                                    label: Object.keys(row)[0],
                                  }}
                                  rowsData={searchTableColsNameData}
                                  loading={tableColsLoading}
                                  formHelper={formHelper}
                                  onSelectedChange={(item) =>
                                    handleEditSchemaRows("key", item, i)
                                  }
                                  label={"Add Columns"}
                                />
                                <FormItemInput
                                  data-testid='paas-etlCanvas-snowflakeEdit-formModal-addSchema'
                                  key={i}
                                  label="Add Schema"
                                  placeholder="Add Schema"
                                  value={Object.values(row)[0]}
                                  onChange={(e) =>
                                    handleEditSchemaRows(
                                      "value",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                                <FlexItem>
                                  <Button
                                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-cancel-btn'
                                    type="secondary"
                                    style={{ backgroundColor: "gray" }}
                                    onClick={() => removeSchemaRows(i)}
                                  >
                                    <CloseIcon
                                      size="small"
                                      aria-hidden="true"
                                      color="secondary"
                                    />
                                  </Button>
                                </FlexItem>
                              </FlexLayout>
                            ))}
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FormItemSelect
                              data-testid='paas-etlCanvas-snowflakeEdit-formModal-columnsData'
                              id="columns Data"
                              selectedRow={selectedTableColsName}
                              rowsData={searchTableColsNameData}
                              loading={tableColsLoading}
                              formHelper={formHelper}
                              onSelectedChange={(item) =>
                                setSelectedTableColsName(item)
                              }
                              selected={selectedTableColsName}
                              inputProps={{
                                value: selectedTableColsName?.label || "",
                              }}
                              searchable={true}
                              onChange={(event) =>
                                handleOnInputValueChange(
                                  event,
                                  tableColsNameData,
                                  setSearchTableColsNameData,
                                  setSelectedTableColsName
                                )
                              }
                              label={"Add Columns"}
                            />
                            <FormItemInput
                              data-testid='paas-etlCanvas-snowflakeEdit-formModal-schema'
                              id="schema"
                              label="Add Schema"
                              placeholder="Add Schema"
                              value={schemaText}
                              onChange={(e) => setSchemaText(e.target.value)}
                            />
                            <FlexItem>
                              <Button onClick={addSchemaRows} data-testid='paas-etlCanvas-snowflakeEdit-formModal-addSchema-btn'>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                      ) : (
                        <StackingLayout>
                          {noSQLSchemaRows?.length > 0 &&
                            noSQLSchemaRows.map((row, i) => (
                              <FlexLayout
                                flexDirection="row"
                                alignItems="flex-end"
                                key={i}
                              >
                                <FlexItem flexGrow="1">
                                  <FormItemInput
                                    data-testid='paas-etlCanvas-snowflakeEdit-formModal-addSchema-btn'
                                    id="schema"
                                    label="Add Schema"
                                    placeholder="Add Schema"
                                    value={row}
                                    onChange={(e) =>
                                      handleOnchangeNOSQLSchmeaText(
                                        e.target.value,
                                        i
                                      )
                                    }
                                  />
                                </FlexItem>
                                <FlexItem>
                                  <Button
                                    type="secondary"
                                    style={{ backgroundColor: "gray" }}
                                    onClick={() => removeNOSQLSchemaRows(i)}
                                  >
                                    <CloseIcon
                                      size="small"
                                      aria-hidden="true"
                                      color="secondary"
                                    />
                                  </Button>
                                </FlexItem>
                              </FlexLayout>
                            ))}
                          <FlexLayout flexDirection="row" alignItems="flex-end">
                            <FlexItem flexGrow="1">
                              <FormItemInput
                                id="schema"
                                label="Add Schema"
                                placeholder="Add Schema"
                                value={noSQLSchemaText}
                                onChange={(e) =>
                                  handleOnchangeNOSQLSchmeaText(
                                    e.target.value,
                                    -1
                                  )
                                }
                              />
                            </FlexItem>
                            <FlexItem>
                              <Button onClick={addNOSQLSchemaRows}>
                                <PlusIcon size="small" aria-hidden="true" />
                              </Button>
                            </FlexItem>
                          </FlexLayout>
                        </StackingLayout>
                      )}
                    </StackingLayout>
                  )}
                  <Button data-testid='paas-etlCanvas-snowflakeEdit-formModal-save-btn'
                    onClick={formHelper.handleSubmit}
                  >
                    Save
                  </Button>
                </FormSection>
              );
            }}
          </Form>
        </StackingLayout>
      </Modal>
    );
  }