import LeoContextProvider from "../../contexts/leoContexts/LeoContextProvider";
import Main from "./Main/Main";
import "./styles.css";
import Tabs from "./Header/Tabs";
import { sendUserRecord } from "../../Service/adoptionMetric";
import { useEffect } from "react";

const LEO = () => {
  useEffect(() => {
    sendUserRecord('leo_2.0', window.location.href)
        .then(resp => { })
        .catch(err => { })
}, [])
  return (
    <LeoContextProvider>
      <Tabs />
      <Main />
    </LeoContextProvider>
  );
};

export default LEO;
