import React from 'react'
import { filterDataByDate } from '../../../utils';
import { statusClassMap } from '../Helper/Helper';
import { Tooltip } from 'antd';
import JiraTooltipView from '../MonthlyView/JiraTooltipView';

const DayDataComponent = ({ date, data}) => {
  const jiraData = filterDataByDate(data, date);
  return (
    <div className="weekly-card" data-testid="weekly-card">
      <div className="weekly-card-body">
        {jiraData.length === 0
          ? null
          : jiraData.map((item, index) => {
            const statusClass = item ? statusClassMap[item.eta_status] : "no-eta";
            
            return(
              <Tooltip
                placement="right"
                trigger="click"
                title={<JiraTooltipView item={item} />}
                color={"white"}
                overlayClassName="tooltip-container"
                // autoAdjustOverflow={true}
                // arrow={{ pointAtCenter: true }}
              >
                <span key={index} className={`jira-container ${statusClass}`} ></span>
              </Tooltip>
            )})}
      </div>
      {jiraData.length === 0 && (
        <span className="no-tickets" data-testid="no-tickets"></span>
      )}
    </div>
  )
}

export default DayDataComponent