// teamSlice.js
import { createSlice } from '@reduxjs/toolkit';

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    currentTeamSelection: {},
    currentTeamViewBySelection: {label: 'View By', key: '10'},
    isRefreshed:false
  },
  reducers: {
    setCurrentTeamSelection: (state, action) => {
      state.currentTeamSelection = action.payload;
    },
    setCurrentTeamViewBySelection: (state, action) => {
      state.currentTeamViewBySelection = action.payload;
    },
    setRefreshed :(state, action) => {
      state.isRefreshed = action.payload;
    }
  },
});

export const {  setCurrentTeamSelection, setCurrentTeamViewBySelection, setRefreshed } = teamSlice.actions;
export default teamSlice.reducer;
