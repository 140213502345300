import {
  Button,
  DatePicker,
  FormItemInput,
  FormItemSelect,
  NotificationTrigger,
  Pagination,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";
import React, { useContext, useState } from "react";
import LeoContext from "../../../../contexts/leoContexts/leoContext";
import {
  generateCostCenterMenu,
  generateTimeRangeMenu,
  generateDivisionMenu,
  viewByMenu,
  getDataByPage,
  sortByColumn,
  filterBySearch,
} from "../../utils";
import DivisionTable from "../DivisionTable";
import EmployeeTable from "../EmployeeTable";

const TEDashboardTab = () => {
  const {
    currentSelection,
    setCurrentSelection,
    setLoaders,
    filters,
    data,
    sort,
  } = useContext(LeoContext);
  const [currentPageEmployee, setCurrentPageEmployee] = useState(1);
  const [currentPageDivision, setCurrentPageDivision] = useState(1);
  const [pageEmployee, setPageEmployee] = useState(10);
  const [pageDivision, setPageDivision] = useState(10);
  const timeRangeMenus = generateTimeRangeMenu(filters.timeRanges);
  const divisionMenus = generateDivisionMenu(filters.divisions);
  const costCenterMenus = generateCostCenterMenu(
    filters.costCenters,
    currentSelection.division
  );
  const divisionTotalData = data.divisionData.filter(
    (data) => data.derived_column === "DIVISION_TOTAL"
  );
  let empTotalData = data.employeeData.filter(
    (data) => data.derived_column === "ORIGINAL_LINE"
  );
  const empGrandTotal = data.employeeData.filter(
    (data) => data.derived_column === "GRAND_TOTAL"
  );
  const sortedEmployeTable = sortByColumn(
    empTotalData,
    sort.employeeTable.column,
    sort.employeeTable.order
  );

  const filteredEmployee = filterBySearch(
    sortedEmployeTable,
    currentSelection.searchDivision,
    "employee_name",
    "employeeTable"
  );

  const transformedEmployeeData = getDataByPage(
    filteredEmployee,
    currentPageEmployee,
    pageEmployee
  );
  const transformedDivisionData = getDataByPage(
    divisionTotalData,
    currentPageDivision,
    pageDivision
  );
  let total = 0;
  if (currentSelection.viewBy.label === "Division") {
    total = divisionTotalData.length > 0 ? divisionTotalData.length : 0;
  } else {
    total =
      transformedDivisionData.length > 0 ? transformedDivisionData.length : 0;
  }
  return (
    <>
      <div className="leoContainer">
        <div className="leoItems">
          <TextLabel className="textTitle">Time Range</TextLabel>
          <FormItemSelect
            id="teTimeRange"
            inputProps={{
              name: "basic-select",
              "data-test-id": "basic-select",
            }}
            onSelectedChange={(select) => {
              setCurrentSelection((prevState) => ({
                ...prevState,
                teTimeRange: select,
              }));

              if (select.label !== "Custom Date Range") {
                setLoaders((prevState) => ({
                  ...prevState,
                  loadingOnTETimeRange: true,
                }));
              } else {
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  teDateRange: {},
                }));
                return NotificationTrigger.add({
                  key: "timeRange",
                  message: "Please select date range to fetch data.",
                  autoDismissDelaySecs: 3,
                  type: "info",
                  actions: null,
                });
              }
            }}
            selectedRow={currentSelection.teTimeRange}
            rowsData={timeRangeMenus}
          />
        </div>
        <div className="leoItems">
          <TextLabel className="textTitle">Date Range Selection</TextLabel>
          <DatePicker
            disabled={
              currentSelection.teTimeRange.label === "Custom Date Range"
                ? false
                : true
            }
            data-test="range-select"
            oldDatePicker={false}
            value={currentSelection.teDateRange}
            onChange={(selectedDate) => {
              const button = document.querySelector(
                ".leoItems .ntnx-date-picker-open.ntnx.ntnx-button"
              );
              if (button) {
                button.click();
              }
              if (selectedDate.startDate && selectedDate.endDate) {
                setLoaders((prevState) => ({
                  ...prevState,
                  loadingOnTETimeRange: true,
                }));
              }
              setCurrentSelection((prevState) => ({
                ...prevState,
                teDateRange: selectedDate,
              }));
            }}
          />
        </div>
        <div className="leoItems">
          <TextLabel className="textTitle">Division</TextLabel>
          <FormItemSelect
            id="division"
            inputProps={{
              name: "basic-select",
              "data-test-id": "basic-select",
            }}
            onSelectedChange={(select) => {
              if (
                currentSelection.teTimeRange.label === "Custom Date Range" &&
                (!currentSelection.teDateRange.startDate ||
                  !currentSelection.teDateRange.endDate)
              ) {
                return NotificationTrigger.add({
                  key: "division",
                  message: "Please select date range to fetch data.",
                  autoDismissDelaySecs: 3,
                  type: "error",
                  actions: null,
                });
              } else {
                setLoaders((prevState) => ({
                  ...prevState,
                  loadingOnTETimeRange: true,
                }));
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  division: select,
                  costCenter: costCenterMenus[0],
                }));
              }
            }}
            selectedRow={currentSelection.division}
            rowsData={divisionMenus}
          />
        </div>
        <div className="leoItems">
          <TextLabel className="textTitle">Cost Center</TextLabel>
          <FormItemSelect
            id="costcenter"
            inputProps={{
              name: "basic-select",
              "data-test-id": "basic-select",
            }}
            onSelectedChange={(select) => {
              if (
                currentSelection.teTimeRange.label === "Custom Date Range" &&
                (!currentSelection.teDateRange.startDate ||
                  !currentSelection.teDateRange.endDate)
              ) {
                return NotificationTrigger.add({
                  key: "division",
                  message: "Please select date range to fetch data.",
                  autoDismissDelaySecs: 3,
                  type: "error",
                  actions: null,
                });
              } else {
                const selectedDivision = divisionMenus.find(
                  (element) => element.label === select.divisions
                );
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  division: selectedDivision
                    ? selectedDivision
                    : { key: "0", label: "All" },
                }));
                setCurrentSelection((prevState) => ({
                  ...prevState,
                  costCenter: select,
                }));
                setLoaders((prevState) => ({
                  ...prevState,
                  loadingOnTETimeRange: true,
                }));
              }
            }}
            selectedRow={currentSelection.costCenter}
            rowsData={costCenterMenus}
          />
        </div>
        <div className="leoItems">
          <TextLabel className="textTitle">View By</TextLabel>
          <FormItemSelect
          id="viewBy"
          inputProps={{
            name: "basic-select",
            "data-test-id": "basic-select",
          }}
          onSelectedChange={(select) =>
            setCurrentSelection((prevState) => ({
              ...prevState,
              viewBy: select,
            }))
          }
          selectedRow={currentSelection.viewBy}
          rowsData={viewByMenu}
          style={{ width: "223px" }}
          // addonBefore={<TextLabel>View By:</TextLabel>}
        />
        </div>
      </div>
      <div className="leoContainer">
        <span className="headerText">
          {/* T&E Lists */}
        </span>
        
      </div>
      <div className="leoContainer">
        <div className="searchContainer">
          <FormItemInput
            id="employee"
            disabled={
              currentSelection.viewBy.label === "Division" ? true : false
            }
            data-test-id="default"
            placeholder="Search by Employee"
            value={currentSelection.searchDivision}
            onChange={(event) => {
              setCurrentSelection((prevState) => ({
                ...prevState,
                searchDivision: event.target.value,
              }));
            }}
            addonAfter={
              <Button
                type={Button.ButtonTypes.TEXT_NORMAL}
                onClick={() => {
                  setCurrentSelection((prevState) => ({
                    ...prevState,
                    searchDivision: "",
                  }));
                }}
              >
                Cancel
              </Button>
            }
          />
        </div>
      </div>
      <div className="leoContainer">
        <div className="tableHeader">
          <TextLabel className="headertext" data-test-id="type-info">
            {/* Viewing all {`${total}`} tables */}
          </TextLabel>
          <div className="rightHeader">
            {/* <TextLabel className="headertext" data-test-id="type-info">
              Export
            </TextLabel>
            <div className="verticalLine"></div> */}
            {currentSelection.viewBy.label === "Division" && (
              <Pagination
                total={
                  divisionTotalData.length > 0 ? divisionTotalData.length : 1
                }
                currentPage={currentPageDivision}
                pageSize={pageDivision}
                onChange={(page, pageSize) => {
                  setCurrentPageDivision(page);
                  setPageDivision(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {currentSelection.viewBy.label === "Employee" && (
              <Pagination
                total={empTotalData.length > 0 ? empTotalData.length : 1}
                currentPage={currentPageEmployee}
                pageSize={pageEmployee}
                onChange={(page, pageSize) => {
                  setCurrentPageEmployee(page);
                  setPageEmployee(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="leoContainer">
        {currentSelection.viewBy.label === "Division" && (
          <DivisionTable transformedDivisionData={transformedDivisionData} />
        )}
        {currentSelection.viewBy.label === "Employee" && (
          <EmployeeTable transformedEmployeeData={transformedEmployeeData} empGrandTotal={empGrandTotal} />
        )}
      </div>
      <div className="leoContainer">
        <div className="divisionTableFooter">
          <div className="rightAlignContainer">
            {/* <TextLabel className="headertext" data-test-id="type-info">
              Export
            </TextLabel>
            <div className="verticalLine"></div> */}
            {currentSelection.viewBy.label === "Division" && (
              <Pagination
                total={
                  divisionTotalData.length > 0 ? divisionTotalData.length : 1
                }
                currentPage={currentPageDivision}
                pageSize={pageDivision}
                onChange={(page, pageSize) => {
                  setCurrentPageDivision(page);
                  setPageDivision(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
            {currentSelection.viewBy.label === "Employee" && (
              <Pagination
                total={empTotalData.length > 0 ? empTotalData.length : 1}
                currentPage={currentPageEmployee}
                pageSize={pageEmployee}
                onChange={(page, pageSize) => {
                  setCurrentPageEmployee(page);
                  setPageEmployee(pageSize);
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TEDashboardTab;
