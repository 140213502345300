import { Checkbox } from 'antd';
import React from 'react';

const CustomCheckbox = ({ className, label, checked, handleChange }) => {
  return (
    <Checkbox
      className={`${className}`} // Apply the custom CSS class
      checked={checked}
      onChange={handleChange}
    >
      {label}
    </Checkbox>
  );
};

export default CustomCheckbox;
