import React, { useContext, useEffect, useState } from "react";
import "./dashboardHeader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTeamSelection,
  setCurrentTeamViewBySelection,
} from "../../../features/JiraAnalysis/teamSlice";

import {
  setSelectedAssignee,
  setSelectedComponent,
  setSelectedIssueType,
  setSelectedLabels,
} from "../../../features/JiraAnalysis/filterSlice";
import { Button, Skeleton, Tooltip } from "antd";
// import EnggDashboardTab from "../EngineeringDashboard/Components/EnggDashboardTab";
import { transformTeams } from "../utils";
import { useTeams } from "../apiUtils";
import UserContext from "../../../contexts/UserContext/UserContext";
import moment from "moment";
import { useLastRefreshed } from "../../EngineeringDashboard/EngineeringUtils";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import { Loader } from "@nutanix-ui/prism-reactjs";
// import AdoptionHeaderContainer from "../../AdoptionHeader";
// DashboardHeader Component:
// This component represents the header of a dashboard that allows the user to select a team and a view option.
// It displays the current team and view options in dropdown buttons, and provides functionality to change them.
const DashboardHeader = ({ onRefresh }) => {
  const dispatch = useDispatch();
  const [lastUpdate, setLastUpdate] = useState(null);
  const { user } = useContext(UserContext);
  const [teams, setTeams] = useState([]);
  const { currentTeamSelection, currentTeamViewBySelection } = useSelector((state) => state.team);
  const { data: lastRefreshed, isLoading: lastRefreshedLoading, isError: isLastRefreshedError, error: lastRefreshedError } = useLastRefreshed();
  const { data, isLoading: teamLoading, isError: isTeamError, error: teamError } = useTeams(user);
  if (isLastRefreshedError) {
    throw lastRefreshedError; // This will be caught by the ErrorBoundary
  }

  if (isTeamError) {
    throw teamError; // This will be caught by the ErrorBoundary
  }

  useEffect(() => {
    if (!teamLoading) {
      const teams = transformTeams(data?.teams || []); // Make sure to define transformTeams
      dispatch(setCurrentTeamSelection(teams?.[0]))
      setTeams(teams);
    }
  }, [data, dispatch, teamLoading, setTeams]);

  useEffect(() => {
    if (!lastRefreshedLoading && lastRefreshed) {
      const lastUpdate = moment(lastRefreshed?.last_refreshed).format("DD MMM YYYY, hh:mm A");
      setLastUpdate(lastUpdate);
    }
  }, [lastRefreshed, lastRefreshedLoading]);

  const handleTeamChange = (selectRow) => {
    // loggerService({
    //   module: "jira_ssot",
    //   msg: `user changed team to ${selectRow?.label}`,
    //   logLevel: "INFO", // Differentiate logs by severity levels (e.g., debug, info, warn, error, fatal) t
    //   eventName: "onclick",
    //   url: window.location.href,
    // });
    dispatch(setCurrentTeamSelection(selectRow))
    dispatch(setSelectedAssignee(["All"]));
    dispatch(setSelectedComponent(["All"]));
    dispatch(setSelectedIssueType(["All"]));
    dispatch(setSelectedLabels(["All"]));
    dispatch(setCurrentTeamViewBySelection({ label: 'View By', key: '10' }));
  }

  const teamProps = {
    inputProps: {
      name: 'team-selection',
      'data-testid': 'team-selection'
    },
    selectProps: {
      autoSize: true,
      rowsData: teams,
      onSelectedChange: handleTeamChange,
    },
    selectedRow: currentTeamSelection,
    type:"mini",
    className:"team"
  };

  const teamViewByProps = {
    inputProps: {
      name: 'team-view-by-selection',
      'data-testid': 'team-view-by-selection'
    },
    selectProps: {
      autoSize: true,
      rowsData: currentTeamSelection?.subitems,
      onSelectedChange: (selectRow) => dispatch(setCurrentTeamViewBySelection(selectRow)),
    },
    selectedRow: currentTeamViewBySelection,
    type:"mini",
    className: 'teamViewBy'
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-left-container">
        {teamLoading ? (
          <Skeleton.Input active={false} size={"small"} />
        ) : (
          <h3 className="dashboard-label">{currentTeamSelection?.project_key_desc}</h3>
        )}
      </div>

      <div className="dashboard-right-container">
        {/* <AdoptionHeaderContainer width={"20%"} businessUnit={'jira_analysis'} /> */}
        <span className="dashboard-recent-update">
          Last Update: {lastRefreshedLoading ? "Fetching..." : lastUpdate}
        </span>
        <Button type="link" onClick={onRefresh}>
          Refresh
        </Button>
        <div className="vertical-border"></div>
        {teamLoading ? <Loader tip="Loading Teams..." data-test-id="team-loading" /> : <Tooltip title={teamProps?.selectedRow?.label}><SelectDropdown {...teamProps} /></Tooltip>}
        {Object.keys(currentTeamSelection).length !== 0 && currentTeamSelection.subitems && (
          <SelectDropdown {...teamViewByProps} />
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
