import React, { useState } from "react";
import { Menu,  Popover, Select, Button } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";
import {
    BarChartOutlined ,
    InboxOutlined,
    PlusOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;
const { Option } = Select;

class BusinessUnitReport extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      reports: []
    };
  }
  componentDidMount() {
      this.fetchData().then()
  }


  fetchData=async()=>{
    try{
        logger.info(`Getting Sliderdata`)
        const {data} = await axios.post(`${SERVER_URL}/getsliderdata`)
        
        logger.info(`Slider details fetched successfully`)
       
        const reports = data.reduce((acc, curr)=>{
          if(acc[curr.bu_name]) {
            if(curr.visible){
              acc[curr.bu_name].visibleReports.push(curr)
            } else{
              acc[curr.bu_name].hiddenReports.push(curr)
            }
            return acc 
          }
          const newItem = { buName: curr.bu_name, visibleReports: [], hiddenReports: [], popupVisible: false }
          if(curr.visible){
            newItem.visibleReports.push(curr)
          } else{
            newItem.hiddenReports.push(curr)
          }
          acc[curr.bu_name] = newItem
          return acc
        }, {})
        this.setState({ reports: Object.values(reports) })
    } catch(e){
      logger.error(e)
    }
  }

  
  handleReportSelectionChange = (newVisibleReportId, buName) => {
    if(!newVisibleReportId) return
    const oldState = this.state.reports
    const newState = oldState.map(e=>{
      if(e.buName === buName) {
        const selectedReport =  e.hiddenReports.find(({id})=> id === newVisibleReportId)
        const newHiddenReportArray = e.hiddenReports.filter(({id})=> id !== newVisibleReportId)
        e.visibleReports.push(selectedReport)
        e.hiddenReports = newHiddenReportArray
      }
      return e
    })
    this.setState({ reports: newState })
    this.handlePopupVisibility(buName, false)
  };

  handlePopupVisibility = (buName, visibleState)=>{
    const currentState =  this.state.reports
    const newState = currentState.map(e=>{
      if(e.buName === buName){
          e.popupVisible = visibleState
          return e
      }
      e.popupVisible = false
      return e
    })
    this.setState({reports: newState})
  }

  

  render() {
    return ( 
      <Menu.ItemGroup  {...this.props}>
        {  
          this.state.reports.map(e=> (
            <SubMenu
                key={e.buName}
                title={
                      <span>
                        <InboxOutlined />
                        <span style={{marginLeft:"5px",fontSize:"12px"}}>{e.buName}</span>
                      </span>
                    }
            >
              {
                e.visibleReports.map(subReport=>(
                      <Menu.Item key={subReport.id}>
                        
                        <Link
                          to={{
                            pathname: `/datalineage`,
                            state: { 
                              url: subReport.url, 
                              name: subReport.report_name
                            },
                          }}
                        >
                          <span>
                          <BarChartOutlined />
                            <span style={{fontSize:"12px"}}>
                              {subReport.report_name}
                            </span>
                          </span>
                        </Link>
                    </Menu.Item>
                ))
              }
              <Menu.Item >
                <span>
                <PlusOutlined />
                  <Popover
                    trigger={"click"}
                    placement={"right"}
                    onOpenChange={() => this.handlePopupVisibility(e.buName, true)}
                    open={e.popupVisible}
                    content={
                      <PopoverContent
                        onCloseButtonClick={() => this.handlePopupVisibility(e.buName, false)}
                        hiddenReports={e.hiddenReports}
                        handleClick={(reportId) => this.handleReportSelectionChange(reportId, e.buName)}
                      />
                    }
                  >
                    <span style={{fontSize:"12px"}}>Add</span>
                  </Popover>
              </span>
            </Menu.Item>
                
            </SubMenu>
          ))
        }
      </Menu.ItemGroup>
    )
  }
}
export default BusinessUnitReport;

function PopoverContent({
  handleClick,
  onCloseButtonClick,
  hiddenReports
}) {
  const [selectedReport, setSelectedReport] = useState("");

  const handleClickLocal = (e) => {
    handleClick(selectedReport);
    setSelectedReport("");
  };

  const handleMenuClick = (value, option) => {
    setSelectedReport(value);
  };

  return (
    <div style={{ width: 200, position: "relative" }}>
      <button
        style={{
          position: "absolute",
          right: 1,
          top: -5,
          width: 22,
          height: 23,
          display:"grid",
          placeItems:"center",
         border:"none",
          background:"transparent"

        }}
        onClick={onCloseButtonClick}
      >
        X
      </button>
      <p style={{ marginBottom: 4 }}> Select report</p>
      <MenuList
        menuList={hiddenReports}
        handleMenuClick={handleMenuClick}
        selectedValue={selectedReport}
      />
      <Button
        style={{ marginTop: 16, width: 200 ,fontSize:12}}
        onClick={handleClickLocal}
        type="primary"
      >
        ADD REPORT
      </Button>
    </div>
  );
}

function MenuList({
  menuList,
  handleMenuClick,
  handleButtonClick,
  selectedValue,
}) {
  return (
    <div style={{ width: "100%" }}>
      <Select
        style={{ width: 200,fontSize:12 }}
        onChange={handleMenuClick}
        value={selectedValue}
        notFoundContent={"No Data"}
       
      >
        {menuList.map((e) => (
          <Option value={e.id} key={e.id} style={{ width: 200,fontSize:12 }} >
            {e.report_name}
          </Option>
        ))}
      </Select>
    </div>
  );
}

