/*
This component is used to render SideBar on the screen. This provides user with
interface to go to the other sections and It provides user with Notify Me
option which one can use to get subscribed to any app. So, you don't have to
keep checking the status app website. If any app or db is down and If you
have subscribed you will automatically get notified by e-mail and sms.

TODO: Make height generic.
Maybe add priority as well and if for any app if priority is set to high
give them an automated call.
*/

import React, { Component } from "react";
import { Layout, Select, Menu, Modal, notification } from "antd";
import {
    AppstoreOutlined,
    BarChartOutlined,
    DesktopOutlined,
} from "@ant-design/icons";
import BusinessUnitReport from "../BusinessUnitReport";
import { Link } from "react-router-dom";
import "./sidebar.css";

import {
    AUTHENTICATION_DASHBOARD,
    AUTHENTICATION_DB_ENABLED,
    CONT_SECURITY_IMPROVEMENT_DASHBOARD,
    CONT_SECURITY_IMPROVEMENT_DB_ENABLED,
    MESSAGING_DASHBOARD,
    MESSAGING_DB_ENABLED,
    VDI_DASHBOARD,
    VDI_DB_ENABLED,
    VIDEO_CONFERENCING_DASHBOARD,
    VIDEO_CONFERENCING_DB_ENABLED,
    VPN_DASHBOARD,
    VPN_DB_ENABLED,
    ZOOM_DASHBOARD,
    ZOOM_DB_ENABLED,
    GRAFANA_URL,
} from "../../config/config";

const { Sider } = Layout;

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ModalText: "",
            visible: false,
            collapsed: false,
            applications: [],
            databases: [],
            dataValidation: [],
            servers: [],
            userEmail: "",
            userMobile: "",
            userAppName: "",
            userAppEnv: "",
            userAppType: "",
            mobilePrefix: "+1",
            autoCompleteResult: [],
            countryCodes: [],
            currentMenuItem: "1",
        };

        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDataSave = this.handleDataSave.bind(this);
        this.onAppDropdownChange = this.onAppDropdownChange.bind(this);
        this.onConfigData = this.onConfigData.bind(this);
        this.onApplicationsData = this.onApplicationsData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.saveNotifyMeData = this.saveNotifyMeData.bind(this);
        this.handlePrefixChange = this.handlePrefixChange.bind(this);
        this.onApplicationDataSaveSuccess =
            this.onApplicationDataSaveSuccess.bind(this);
    }

    /*
  This function sets the visiblity of modal to visible to show the modal
  when user clicks on Notify me button.
  */
    showModal() {
        this.setState({
            visible: true,
        });
    }

    // Call the function to save the form data to backend and clear the form.
    handleDataSave = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.saveApplicationData(values);
            }
        });
    };

    saveApplicationData() {
        this.setState({
            ModalText: "Saving Data",
            confirmLoading: true,
        });

        this.props.form.resetFields();

        setTimeout(() => {
            this.setState({
                visible: false,
                confirmLoading: false,
            });
        }, 1000);

        this.saveNotifyMeData(this.state);
    }

    onApplicationDataSaveSuccess() {
        Modal.success({
            title: "Data saved successfully",
            content: "You will be notified via Email/SMS",
            okText: "Dismiss",
        });
    }

    onApplicationsData(error, data) {
        this.onApplicationDataSaveSuccess();
    }

    handleCancel() {
        this.setState({
            visible: false,
            userEmail: "",
            userMobile: "",
            userAppName: "",
            userAppEnv: "",
            userAppType: "",
            mobilePrefix: "+1",
        });
        this.props.form.resetFields();
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    // On receiving the data, set the states of respective section
    onConfigData(error, data) {
        if (!error && data) {
            this.setState({
                applications: data.applications,
                databases: data.databases,
                databasesLink: data.databasesLink,
                dataValidation: data.dataValidation,
                servers: data.servers,
                countryCodes: data.countryCodes,
            });
        }
    }

    onAppDropdownChange(app) {
        if (app[0] !== "DataValidation") {
            this.setState({
                userAppName: app[1],
                userAppEnv: app[2],
                userAppType: app[0],
                userDataValidationKey: app[1],
            });
        } else {
            this.setState({
                userAppType: app[0],
                userDataValidationKey: app[1],
            });
        }
    }

    handleEmailChange(value) {
        let autoCompleteResult;

        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ["@nutanix.com"].map(
                (domain) => `${value}${domain}`
            );
        }
        this.setState({ autoCompleteResult: autoCompleteResult });
    }

    handleInputChange(event) {
        // In case of select, event only returns it's value
        // TODO: Fix this and make it generic

        const target = event.target;

        if (target) {
            var value = target.value;
            const name = target.name;
            if (name === "userMobile") {
                value = this.state.mobilePrefix + value;
            }

            this.setState({
                [name]: value,
            });
        }
    }

    // TODO: This is kinda hard-coded. So, remove this and use
    // this.props.form.validateFields instead of
    // handleInputChange function and this function.
    handlePrefixChange(event) {
        var str = String(this.state.userMobile);
        if (str && str.length > 0 && str[1] === "1") {
            str = str.substr(2, str.length);
            str = event + str;
            this.setState({ mobilePrefix: event, userMobile: str });
        } else if (str && str.length > 0 && str[1] === "9") {
            str = str.substr(3, str.length);
            str = event + str;
            this.setState({ mobilePrefix: event, userMobile: str });
        } else {
            this.setState({ mobilePrefix: event });
        }
    }

    handleMenuClick = (e) => {
        this.setState({ currentMenuItem: e.key });
    };

    handleGrafanaClick = () => {
        notification.info({
            message: "Grafana dashboard opened in new tab",
            placement: "top",
            duration: 20,
        });
    };

    render() {
        const {
            visible,
            confirmLoading,
            ModalText,
            applications,
            databases,
            dataValidation,
            servers,
            databasesLink,
            userAppName,
            userEmail,
            userMobile,
            autoCompleteResult,
            countryCodes,
            currentMenuItem,
            collapsed,
        } = this.state;

        var emailOptions = [],
            countryCodeList = [],
            cascaderData = [],
            appObj = {
                value: "Applications",
                label: "Applications",
                children: applications,
            },
            dbObj = {
                value: "Databases",
                label: "Databases",
                children: databases,
            },
            dataValidationObj = {
                value: "DataValidation",
                label: "DataValidation",
                children: dataValidation,
            };

        cascaderData.push(appObj);
        cascaderData.push(dbObj);
        cascaderData.push(dataValidationObj);

        if (countryCodes) {
            countryCodeList = countryCodes.map((code) => (
                <Select.Option key={code} value={code}>
                    {" "}
                    {code}{" "}
                </Select.Option>
            ));
        }

        return (
            <div className="sidebar navBackground">
                <Sider
                    width={"100%"}
                    height={"100%"}
                    collapsible
                    trigger={null}
                    className="n-sider ant-layout-sider navBackground"
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                >
                    <Menu
                        theme="dark"
                        className="n-sider-menu navBackground"
                        mode="inline"
                    >
                        <Menu.Item
                            key={"tableau"}
                            icon={<DesktopOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/"}>
                                <span className={"menu-text"}>Home</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"home"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <span>
                                <Link to={"/platform_health"}>
                                    <span className={"menu-text"}>
                                        Platform Health
                                    </span>
                                </Link>
                            </span>
                        </Menu.Item>
                        {/* <Menu.Item>
                            <span>
                            <AppstoreOutlined style={{fontSize:"12px"}} />{"    "}
                                <Link to={"/dashboard"}>
                                    <span
                                     style={{
                                        color: "rgba(255, 255, 255, 0.67)",fontSize:"12px"
                                    }}
                                    >Dashboard</span>
                                </Link>
                            </span>
                        </Menu.Item> */}
                        <Menu.Item
                            key={"datasync"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/datasync"}>
                                <span className={"menu-text"}>Data Health</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"dataaudit"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/dataaudit"}>
                                <span className={"menu-text"}>Data Audit</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"tracker"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/tracker"}>
                                <span className={"menu-text"}>Pro Tracker</span>
                            </Link>
                        </Menu.Item>
						<Menu.Item
                            key={"PnP2.0"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/PnP 2.0"}>
                                <span className={"menu-text"}>PnP 2.0</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"fulfillment"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/fulfillment_slide"}>
                                <span className={"menu-text"}>Fulfillment Slide</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"jira-ssot"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/jira-analysis"}>
                                <span className={"menu-text"}>Jira Analysis</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"file-upload"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/file_upload"}>
                                <span className={"menu-text"}>File Upload</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"paas-onboarding"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/NextGenETL"}>
                                <span className={"menu-text"}>PaaS Onboarding</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key={"etl-canvas"}
                            icon={<AppstoreOutlined className={"menu-icon"} />}
                        >
                            <Link to={"/ETL_listing"}>
                                <span className={"menu-text"}>ETL Canvas</span>
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>
                            <DesktopOutlined style={{fontSize:"12px"}}/>{"    "}
                            <Link to={"/aboutus"}>
                                <span
                                    style={{
                                        color: "rgba(255, 255, 255, 0.67)",fontSize:"12px"
                                    }}
                                >
                                    About Us
                                </span>
                            </Link>
                        </Menu.Item> */}

                        {/* <BusinessUnitReport /> */}
                    </Menu>
                </Sider>
            </div>
        );
    }
}

export default SideBar;
