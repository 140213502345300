import React, { useContext, useEffect, useState } from "react";
import {
  InformationSquareIcon,
  FormItemSelect,
  Notification,
  Breadcrumb,
  BreadcrumbItem,
  TextLabel,
  Link,
} from "@nutanix-ui/prism-reactjs";
import "./style.css";
import { useHistory } from "react-router-dom";

function EtlPipeLineHeader({
  projectData,
  selectedProject,
  handleProjectIDChange,
  loading,
  projectID,
  PipelineName,
}) {
  const history = useHistory();
  const itemsList =
    PipelineName.length > 0
      ? ["Home", selectedProject.label, PipelineName]
      : ["Home", selectedProject.label];
  return (
    <div className="ETLHeaderContainer" data-testid="ETLHeaderContainer">
      <div className="ETLHeaderTitle">
        <Breadcrumb
          aria-label="breadcrumbs"
          style={{
            paddingBottom: "10px",
            background: "#ffff",
            marginLeft: "2%",
          }}
        >
          {itemsList.map((id, index) => (
            <BreadcrumbItem aria-label="breadcrumbs item" key={id}>
              <TextLabel title={id} type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
                 { id === "Home" ? (
                    <Link
                      onClick={() =>
                        history.push({
                          pathname: "/NextGenETL",
                          state: { Project: selectedProject },
                        })
                      }
                    >
                      {id}
                    </Link>
                  ) : (
                  itemsList.length>2 && index === 1 ?
                    <Link
                      onClick={() =>
                        history.push({
                          pathname: "/ETL_listing",
                          state: { Project: selectedProject },
                        })
                      }
                    >
                      {id}
                    </Link>
                 : (
                  id
                ))}
              </TextLabel>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </div>

      <Notification className="alert modal " data-testid="notifications">
        <div
          style={{
            position: "fixed",
            top: "80px",
            right: "340px",
          }}
        />
      </Notification>
    </div>
  );
}
export default EtlPipeLineHeader;
