import { Badge } from "@nutanix-ui/prism-reactjs";
import React from "react";

const CurrentCoverageFooter = ({ donutData }) => {
  return (
    <div className="footerFlex">
      {
          donutData.map(donut => (<Badge key={donut.name} color={donut.color} text={donut.name} />))
      }
    </div>
  );
};

export default CurrentCoverageFooter;
