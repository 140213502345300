import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "@nutanix-ui/recharts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCustomerDefectColumn, getCustomerDefectDataSource, useCFDDetail, formatQuarter, getPeriodSelectionCustomer, labelStyle, getDataByPage, renderBar, CustomizedAxisTick, generateBarData, graphHeight, CartesianGridColor } from "../../EngineeringUtils";
import { setColumnData, setDataSourceData, setOpenModal } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import NoData from "./NoData";

const Customer = () => {
  const dispatch = useDispatch()
  const { customerPage, cfdData, filters, customerDefects, defaultCustomerDefect } = useSelector((state) => state.enggDashboard);
  const [param, setParam] = useState({});
  const [enabled, setEnabled] = useState(false)
  const [data, setData] = useState([]);
  const { data:cfDetail, isLoading } = useCFDDetail(filters, enabled, param?.name, param?.interval)
  
  useEffect(() => {
    if(enabled && !isLoading && cfDetail?.length > 0){
      dispatch(setColumnData(getCustomerDefectColumn()))
      dispatch(setDataSourceData(getCustomerDefectDataSource(cfDetail)))
      dispatch(setOpenModal(true));
      setEnabled(false)
    }
  }, [cfDetail, enabled, isLoading])
  
  useEffect(() => {
    if (cfdData?.length > 0) {
      const periodSelection = getPeriodSelectionCustomer(
        cfdData,
        filters?.viewByCfd
      );

      const generateDeveloperData = (data) => {
        return data.map((item) => {
          const newObj = { name: item.name };
          periodSelection.forEach((periodItem) => {
            const numbers =
              item.data.find(
                (dataItem) => dataItem.period_selection === periodItem.name
              )?.defect_count || null;
            // Check if numbers is a valid number
            newObj[periodItem.name] = Number(numbers) || null;
          });
          return newObj;
        });
      };
      setData(generateDeveloperData(cfdData));
    }
  }, [cfdData, filters]);

  return (
    <ResponsiveContainer height={graphHeight} width="100%">
      {data.length > 0 ? (
        <BarChart
          data={getDataByPage(
            data,
            customerPage.currentPage,
            customerPage.pageSize
          )}
          margin={{ top: 20, right: 30, bottom: 20, left: 0 }}
          alignment="none"
        >
          <CartesianGrid stroke={CartesianGridColor} interval={0} />
          <XAxis tick={<CustomizedAxisTick />} dataKey="name" />
          <YAxis />
          <Tooltip
            formatter={(value, dataKey) => {
              return [
                value?.toFixed(1),
                filters.viewByCfd === "Quarterly" && dataKey !== undefined
                  ? formatQuarter(dataKey)
                  : dataKey,
              ];
            }}
          />
          {renderBar(customerDefects, cfdData, filters.viewByCfd, setParam, setEnabled, defaultCustomerDefect?.default_defect)}
        </BarChart>
      ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default Customer;
