import React, { useEffect } from "react";
import "./EnggDashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "@nutanix-ui/prism-reactjs";
import {
  setCycleDateRange,
  setJiraDateRange,
  setFilters,
  setJiraComparisonData,
  setLoading,
  setOvertimeData,
  setStagesData,
  toggleLoadingCfd,
  toggleLoadingCycle,
  toggleLoadingOvertime,
  toggleLoadingVelocity,
  toggleLoadingCodeCoverage,
  setOpenModal,
} from "../../features/JiraAnalysis/enggDashboardSlice";
import moment from "moment";
import { SERVER_URL } from "../../config/config";
import axios from "axios";
import EnggDashboardBody from "./EnggDashboardBody";
import HeaderContainer from "./HeaderContainer";
import { fetchCCTrendsAPI, fetchCFDAPI, fetchCFDFilter, fetchCodeCoverageFilter, fetchCycleAPI, fetchCycleFilter, fetchVelocityFilter } from "./EngineeringUtils";
import EnggDashboardTab from "./Components/EnggDashboardTab";
import ViewDetailModal from "../ViewDetailModal/ViewDetailModal";

const navigateTo = (url) => (url ? (window.location.href = url) : null);
const loginUrl = `${SERVER_URL}/login`;

const EngineeringDashboard = () => {
  const dispatch = useDispatch();
  const {
    filters,
    loadingCycle,
    cycleDateRange,
    loadingOvertime,
    loadingVelocity,
    loadingCfd,
    jiraDateRange,
    loading,
    velocityAssigneeChanged,
    loadingCodeCoverage,
    openModal,
    columnData,
    dataSourceData
  } = useSelector((state) => state.enggDashboard);

  // on first load of engineering dashboard
  useEffect(() => {
    (async()=>{
      dispatch(toggleLoadingCfd(true));
      dispatch(toggleLoadingCycle(true));
      dispatch(toggleLoadingVelocity(true));
      const startDate = moment().subtract(3, "month").startOf("day").toDate();
      const endDate = moment().endOf("day").toDate();
      dispatch(setCycleDateRange({ startDate: startDate, endDate: endDate }));
      dispatch(setJiraDateRange({ startDate: startDate, endDate: endDate }));
      await fetchCodeCoverageFilter(dispatch)
      dispatch(toggleLoadingCodeCoverage(true))
    })()// eslint-disable-next-line 
  }, []);

  useEffect(() => {
    (async () => {
      if (loadingVelocity) {
        await fetchVelocityFilter(dispatch, filters, velocityAssigneeChanged, loadingVelocity);
      }
    })();// eslint-disable-next-line 
  }, [loadingVelocity]);

  useEffect(() => {
    if (loadingCycle) {
      (async () => {
        fetchCycleFilter(dispatch, filters);
        fetchCycleAPI(dispatch, filters, cycleDateRange, jiraDateRange);
      })();
    }// eslint-disable-next-line 
  }, [loadingCycle]);

  useEffect(() => {
    if (loadingCfd) {
      (async () => {
        fetchCFDFilter(dispatch, filters);
        fetchCFDAPI(dispatch, filters);
      })();
    }// eslint-disable-next-line 
  }, [loadingCfd]);

  useEffect(() => {
    if(loadingCodeCoverage){
      (async()=>{
        await fetchCCTrendsAPI(dispatch, filters)
      })()
    }// eslint-disable-next-line 
  }, [loadingCodeCoverage])
  

  useEffect(() => {
    if (loadingCfd) {
      fetchCFDAPI();
      dispatch(toggleLoadingCfd(false));
    }// eslint-disable-next-line 
  }, [filters.viewByCfd]);

  useEffect(() => {
    (async () => {
      if (loading.stages) {
        try {
          const payload = {
            product:
              filters.selectedProduct[0] === "All"
                ? null
                : filters.selectedProduct,
            component:
              filters.selectedComponent[0] === "All"
                ? null
                : filters.selectedComponent,
            jira_status_derived:
              filters.selectedStage[0] === "All" ? null : filters.selectedStage,
            issue_type:
              filters.selectedIssueType[0] === "All"
                ? null
                : filters.selectedIssueType,
            assignee_name:
              filters.selectedAssignee[0] === "All"
                ? null
                : filters.selectedAssignee,
            qa_contact:
              filters.selectedQAContact[0] === "All"
                ? null
                : filters.selectedQAContact,
            ticket_status: filters.selectedTicketProgress,
            priority:
              filters.selectedPriority[0] === "All"
                ? null
                : filters.selectedPriority,
            story_point_latest:
              filters.selectedStoryPoint[0] === "All"
                ? null
                : filters.selectedStoryPoint,
            labels:
              filters.selectedLabel[0] === "All" ? null : filters.selectedLabel,
          };
          const satgesPayload = {
            ...payload,
            open_start_date: `${moment(cycleDateRange?.startDate).format(
              "YYYY-MM-DD HH:mm:ss.SSS"
            )}`,
            open_end_date: `${moment(cycleDateRange?.endDate).format(
              "YYYY-MM-DD HH:mm:ss.SSS"
            )}`,
          };
          const CYCLE_TIME_SATGES_URL = `${SERVER_URL}/cycle-time-specific-duration?payload=${encodeURIComponent(
            JSON.stringify(satgesPayload)
          )}`;
          const stagesResponse = axios.get(CYCLE_TIME_SATGES_URL);
          const stagesData = (await stagesResponse).data;
          if (stagesData) {
            dispatch(
              setStagesData(
                stagesData.data.cycle_time_specific_duration !== null
                  ? stagesData.data.cycle_time_specific_duration
                  : []
              )
            );
          }
        } catch (error) {
          handleAuthError(error);
        } finally {
          dispatch(
            setLoading({
              stages: false,
            })
          );
          dispatch(toggleLoadingCycle(false));
        }
      }
    })();// eslint-disable-next-line 
  }, [cycleDateRange]);

  useEffect(() => {
    (async () => {
      if (loading.jiraComparison) {
        try {
          const payload = {
            product:
              filters.selectedProduct[0] === "All"
                ? null
                : filters.selectedProduct,
            component:
              filters.selectedComponent[0] === "All"
                ? null
                : filters.selectedComponent,
            jira_status_derived:
              filters.selectedStage[0] === "All" ? null : filters.selectedStage,
            issue_type:
              filters.selectedIssueType[0] === "All"
                ? null
                : filters.selectedIssueType,
            assignee_name:
              filters.selectedAssignee[0] === "All"
                ? null
                : filters.selectedAssignee,
            qa_contact:
              filters.selectedQAContact[0] === "All"
                ? null
                : filters.selectedQAContact,
            ticket_status: filters.selectedTicketProgress,
            priority:
              filters.selectedPriority[0] === "All"
                ? null
                : filters.selectedPriority,
            story_point_latest:
              filters.selectedStoryPoint[0] === "All"
                ? null
                : filters.selectedStoryPoint,
            labels:
              filters.selectedLabel[0] === "All" ? null : filters.selectedLabel,
          };
          const jiraComparisonPayload = {
            ...payload,
            issuenum_list: [],
            open_start_date: `${moment(jiraDateRange?.startDate).format(
              "YYYY-MM-DD HH:mm:ss.SSS"
            )}`,
            open_end_date: `${moment(jiraDateRange?.endDate).format(
              "YYYY-MM-DD HH:mm:ss.SSS"
            )}`,
          };
          const CYCLE_TIME_JIRA_COMPARISON_URL = `${SERVER_URL}/cycle-time-projects-comp?payload=${encodeURIComponent(
            JSON.stringify(jiraComparisonPayload)
          )}`;
          const jiraComparisonResponse = axios.get(
            CYCLE_TIME_JIRA_COMPARISON_URL
          );
          const jiraComparison = (await jiraComparisonResponse).data;
          if (jiraComparison) {
            dispatch(
              setJiraComparisonData(
                jiraComparison.data !== null ? jiraComparison.data : []
              )
            );
            dispatch(
              setLoading({
                jiraComparison: false,
              })
            );
            const issuenumValues = jiraComparison.data.map(
              (item) => item.issuenum
            );
            const uniqueIssuenumValues = [...new Set(issuenumValues)];
            const firstTenUniqueIssuenumValues = uniqueIssuenumValues.slice(
              0,
              10
            );
            dispatch(
              setFilters({
                section: "selectedJiraIssues",
                value: firstTenUniqueIssuenumValues,
              })
            );
          }
        } catch (error) {
          handleAuthError(error);
        } finally {
          dispatch(
            setLoading({
              jiraComparison: false,
            })
          );
        }
      }
    })();// eslint-disable-next-line 
  }, [jiraDateRange, filters.selectedJiraIssues]);

  // FETCH WHEN VIEW BY CHNAGES
  useEffect(() => {
    if (filters.selectedProduct.length > 0 && loadingOvertime) {
      (async () => {
        try {
          dispatch(
            setLoading({
              overtime: true,
            })
          );
          const payload = {
            product:
              filters.selectedProduct[0] === "All"
                ? null
                : filters.selectedProduct,
            component:
              filters.selectedComponent[0] === "All"
                ? null
                : filters.selectedComponent,
            jira_status_derived:
              filters.selectedStage[0] === "All" ? null : filters.selectedStage,
            issue_type:
              filters.selectedIssueType[0] === "All"
                ? null
                : filters.selectedIssueType,
            assignee_name:
              filters.selectedAssignee[0] === "All"
                ? null
                : filters.selectedAssignee,
            qa_contact:
              filters.selectedQAContact[0] === "All"
                ? null
                : filters.selectedQAContact,
            ticket_status: filters.selectedTicketProgress,
            priority:
              filters.selectedPriority[0] === "All"
                ? null
                : filters.selectedPriority,
            story_point_latest:
              filters.selectedStoryPoint[0] === "All"
                ? null
                : filters.selectedStoryPoint,
            labels:
              filters.selectedLabel[0] === "All" ? null : filters.selectedLabel,
          };
          const overtimePayload = {
            ...payload,
            view_by: filters.viewBy.toLowerCase(),
          };
          const CYCLE_TIME_OVERTIME_URL = `${SERVER_URL}/cycle-time-over-period-time-comp?payload=${encodeURIComponent(
            JSON.stringify(overtimePayload)
          )}`;
          const overtimeResponse = axios.get(CYCLE_TIME_OVERTIME_URL);
          const overTime = (await overtimeResponse).data;
          if (overTime) {
            dispatch(
              setOvertimeData(overTime.data !== null ? overTime.data : [])
            );
            dispatch(
              setLoading({
                overtime: false,
              })
            );
          }
        } catch (error) {
          handleAuthError(error);
        } finally {
          dispatch(toggleLoadingOvertime(false));
        }
      })();
    }// eslint-disable-next-line 
  }, [filters.viewBy]);

  const handleAuthError = (error) => {
    if (error?.message === "User not authenticated") {
      navigateTo(loginUrl);
    }
  };

  return (
    <>
      <main className="enggDashboardContainer">
        <section className="sidepanelContainer">
        <EnggDashboardTab />
          <section className="enggInnerContainer">
            <HeaderContainer />
            <EnggDashboardBody />
          </section>
        </section>
      </main>
      {
        <ViewDetailModal
          modalTitle={'Title'}
          open={openModal}
          onCancel={() => dispatch(setOpenModal(false)) }
          columns={columnData}
          dataSource={dataSourceData}
        />
      }
      {
        <Notification>
          <div
            style={{
              position: "fixed",
              top: "80px",
              right: "340px",
            }}
          />
        </Notification>
      }
    </>
  );
};

export default EngineeringDashboard;
