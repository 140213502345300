import React from "react";
import moment from "moment";
import greenHeart from "../../images/heart-green.png";
export function newReformat(
    environments,
    auditColumnName,
    response,
    dateFormatColumns,
    numericColumnFormat
) {
    const columnDefStatic = [
        {
            title: "Column Names",
            dataIndex: "columnName",
            key: "names",
            render(text, record) {
                return {
                    props: {
                        style: { background: "#fafafa", fontWeight: "600" },
                    },
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Source",
            dataIndex: "src",
            key: "src",
        },
    ];

    const columnDef = environments.reduce((acc, environment) => {
        if (environment === "Sfdc") return acc;
        acc.push({
            // title: textInfo.ToTitleCase(environment),
            title: toTitles(environment),
            dataIndex: environment,
            key: environment,
            render(text, record) {
                if (text !== record?.src) {
                    return {
                        props: {
                            style: { background: "#ff000021" },
                        },
                        children: <div>{text}</div>,
                    };
                } else {
                    return {
                        children: <div>{text}</div>,
                    };
                }
            },
        });
        return acc;
    }, columnDefStatic);

    const dataSource = auditColumnName.reduce((acc, columnName) => {
        const tempData = { columnName: toTitles(columnName) }; //{columnName:'Id'}
        ["src", ...environments].forEach((environment) => {
            if (response.data[environmentMap(environment)]) {
                let auditData = String(
                    response.data[environmentMap(environment)]?.[0]?.[columnName]
                    // response[environmentMap(environment)][columnName]
                );
                if (dateFormatColumns.includes(columnName)) {
                    auditData = moment(auditData.split("+")[0]).format(
                        "YYYY MMM DD hh:mm A"
                    );
                }
                if (numericColumnFormat.includes(columnName)) {
                    // auditData = Math.round(auditData * 1000) / 1000
                    auditData = Number(auditData).toFixed(3);
                }

                tempData[environment] = auditData;
            }
        });
        acc.push(tempData);
        return acc;
    }, []);

    return { columnDef, dataSource };
}

export function titleCase(str) {
    str =  str.replaceAll("_", " ")
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

export function toTitles(s) {
    return s.replace(/\w\S*/g, function (t) {
        return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
}
//Env output of fetchdata response - to display table data
export function environmentMap(environment) {
    if (environment === "Database-Production") return "prd";
    if (environment === "Database-Stage") return "stg";
    if (environment === "Database-Dev") return "dev";
    if (environment === "Sfdc") return "sfdc";
    return environment;
}
//Env Request for fetch data
export function environmentReqMap(environment) {
    if (environment === "Database-Production") return "prod";
    if (environment === "Database-Stage") return "stage";
    if (environment === "Database-Dev") return "dev";
    if (environment === "Sfdc") return "sfdc";
    return environment;
}
//Env request for Fix data
export function fixdataEnvReq(environment) {
    if (environment === "prod") return "prd";
    if (environment === "stage") return "stg";
    if (environment === "dev") return "dev";
    if (environment === "sfdc") return "sfdc";
    return environment;
}

export const menuItems = {
    result: ["Database-Stage", "Database-Production"],
};

export const fetchDataStages = [
    "Getting Data from Sources",
    "Comparing Data",
    "Done",
    "Creating Jira",
];

export const fixDataStages = [
    "Updating Values to Target",
    "Updating Jira ",
    "Finished",
    // "Reloading Page",
];

export const data = [
    {
        title: (
            <>
                <div
                    style={{ fontsize: "12px", color: "rgba(0,0,0,0.65)" }}
                ></div>
            </>
        ),
        content: (
            <div className="ant-card-body">
                <div>
                    <img
                        src={greenHeart}
                        style={{ width: "20px" }}
                    ></img>
                    <div className="ant-timeline-item-content">
                        <p
                            className="card-title"
                            style={{
                                fontWeight: 200,
                                color: "rgba(0,0,0,0.65)",
                            }}
                        >
                            Production
                        </p>
                    </div>
                </div>
                <div style={{ marginLeft: "30px" }}>5 minutes ago</div>
                <div style={{ marginLeft: "30px" }}>
                    Uptime (30 Days) - 99.95 %
                </div>
                <p style={{ marginLeft: "30px" }}>
                    {" "}
                    <a>Status</a>
                </p>
            </div>
        ),
    },
];
// export const netsuite_columns = [{label: "First Name"},{label: "Last Name"},{label: "Department"},{label: "Address"}]