import React, { useState } from "react";
import { Badge, ChevronLeftIcon, FlexLayout, PlusIcon, Separator, VerticalSeparator } from "@nutanix-ui/prism-reactjs";

import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";

import './styles.css';
import CreateAirflowDagModal from "../CreateAirflowDagModal";

export default function DashboardHeader({ visible, setVisible }) {

    const [creaeteDagVisible, setCreaeteDagVisible] = useState(false);

    const handleModalOpen = () => {
        setVisible(true);
    }

    const handleModalClose = () => {
        setVisible(false);
    }

    const openCreateDagModal = () => {
        setCreaeteDagVisible(prevState => true);
    }

    const closeCreateDagModal = () => {
        setCreaeteDagVisible(prevState => false);
    }

    return <div className="templateDashboardHeader" data-testid='templateDashboardHeader'>
        <div className="templateDashboardHeaderContent" data-testid='templateDashboardHeaderContent'>
            <FlexLayout alignItems="center">
                <Separator separator={<VerticalSeparator data-testid='separator' />}>
                    <a className="templateDashboardLink" href="/file_upload" data-testid='templateDashboardLink'><ChevronLeftIcon size='large' /></a>
                    <Badge
                        color="gray"
                        count="Dashboard"
                    />
                </Separator>
                <h3 data-testid='header'>Templates Dashboard</h3>
            </FlexLayout>
            <div>
                <Separator separator={<VerticalSeparator data-testid='separator' />}>
                    <a className="createTemplateButton" data-testid='modalButton' onClick={openCreateDagModal}><PlusIcon /> Create Airflow Dag</a>
                    <CreateAirflowDagModal visible={creaeteDagVisible} closeModal={closeCreateDagModal} />
                </Separator>
                <a className="createTemplateButton" data-testid='modalButton' onClick={handleModalOpen}><PlusIcon /> Create Template</a>
                <CreateTemplateModal visible={visible} modalClose={handleModalClose} />
            </div>
        </div>
    </div>
}