import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, toggleLoadingCfd } from '../../../../features/JiraAnalysis/enggDashboardSlice';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import { ApplicationComponent, generateMenuForDropdown } from '../../EngineeringUtils';

const CustomerFilter = () => {
    const dispatch = useDispatch();
    const { filters, CFDFilter, loadingCfdFilter } = useSelector((state) => state.enggDashboard);
    const [openProduct, setOpenProduct] = useState(false)
    const [openComponent, setOpenComponent] = useState(false)
    const [openPriority, setOpenPriority] = useState(false)
    const [openLabel, setOpenLabel] = useState(false)// eslint-disable-next-line 
    const [openViewBy, setOpenViewBy] = useState(false)

    const handleClose = (id) => {
      switch (id) {
        case 'customerProducts':
          return setOpenProduct(false)
        case 'customerComponents':
          return setOpenComponent(false)
        case 'customerPriority':
          return setOpenPriority(false)
        case 'customerLabels':
          return setOpenLabel(false)
        case 'customerViewBy':
          return setOpenViewBy(false)
        default:
          return
      }
    }

    const customerDropdownItem = [
      {
        id: "customerProducts",
        component:`${ApplicationComponent.CFD}`,
        label: "Product",
        mode: "multiple",
        open:openProduct,
        loading: loadingCfdFilter,
        handleClose:handleClose,
        items: [
          { label: "All", value: "All" },
          ...generateMenuForDropdown(
            Object.keys(CFDFilter).length > 0 ? CFDFilter.products : []
          ),
        ],
        selectedItem: filters.selectedCustomerProduct,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setFilters({
                section: "selectedCustomerProduct",
                value: filteredItem,
              })
            );
          } else if (item.length === 0) {
            dispatch(
              setFilters({ section: "selectedCustomerProduct", value: ["All"] })
            );
          } else {
            dispatch(
              setFilters({ section: "selectedCustomerProduct", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(
              setFilters({ section: "selectedCustomerProduct", value: ["All"] })
            );
          }
          setOpenProduct(true)
        },
      },
      {
        id: "customerComponents",
        component:`${ApplicationComponent.CFD}`,
        label: "Component",
        mode: "multiple",
        open:openComponent,
        loading: loadingCfdFilter,
        handleClose:handleClose,
        items: [
          { label: "All", value: "All" },
          ...generateMenuForDropdown(
            Object.keys(CFDFilter).length > 0 ? CFDFilter.components : []
          ),
        ],
        selectedItem: filters.selectedCustomerComponent,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setFilters({
                section: "selectedCustomerComponent",
                value: filteredItem,
              })
            );
          } else if (item.length === 0) {
            dispatch(
              setFilters({ section: "selectedCustomerComponent", value: ["All"] })
            );
          } else {
            dispatch(
              setFilters({ section: "selectedCustomerComponent", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(
              setFilters({ section: "selectedCustomerComponent", value: ["All"] })
            );
          }
          setOpenComponent(true)
        },
      },
      {
        id: "customerPriority",
        component:`${ApplicationComponent.CFD}`,
        label: "Issue Priority",
        mode: "multiple",
        open:openPriority,
        loading: loadingCfdFilter,
        handleClose:handleClose,
        items: [
          { label: "All", value: "All" },
          ...generateMenuForDropdown(
            Object.keys(CFDFilter).length > 0 ? CFDFilter.priorities : []
          ),
        ],
        selectedItem: filters.selectedCustomerIssueType,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setFilters({
                section: "selectedCustomerIssueType",
                value: filteredItem,
              })
            );
          } else if (item.length === 0) {
            dispatch(
              setFilters({ section: "selectedCustomerIssueType", value: ["All"] })
            );
          } else {
            dispatch(
              setFilters({ section: "selectedCustomerIssueType", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(
              setFilters({ section: "selectedCustomerIssueType", value: ["All"] })
            );
          }
          setOpenPriority(true)
        },
      },
      {
        id: "customerLabels",
        component:`${ApplicationComponent.CFD}`,
        label: "Labels",
        mode: "multiple",
        open:openLabel,
        loading: loadingCfdFilter,
        handleClose:handleClose,
        items: [
          { label: "All", value: "All" },
          ...generateMenuForDropdown(
            Object.keys(CFDFilter).length > 0 ? CFDFilter.labels : []
          ),
        ],
        selectedItem: filters.selectedCustomerLabel,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setFilters({
                section: "selectedCustomerLabel",
                value: filteredItem,
              })
            );
          } else if (item.length === 0) {
            dispatch(
              setFilters({ section: "selectedCustomerLabel", value: ["All"] })
            );
          } else {
            dispatch(
              setFilters({ section: "selectedCustomerLabel", value: item })
            );
          }
          // dispatch(toggleLoadingCfd(true));
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(
              setFilters({ section: "selectedCustomerLabel", value: ["All"] })
            );
          }
          setOpenLabel(true)
        },
      },
      {
        id: "customerViewBy",
        component:`${ApplicationComponent.CFD}`,
        label: "View By",
        mode: "single",
        items: generateMenuForDropdown(["Quarterly", "Monthly"]),
        selectedItem: filters.viewByCfd,
        onChangeItem: (item) => {
          dispatch(setFilters({ section: "viewByCfd", value: item }))
          dispatch(toggleLoadingCfd(true));
        }
      },
    ];
    return (
      <div
        className="filterDropdownContainer filterCustomerContainer"
        data-testid="jira-ssot-filter-container"
      >
        {customerDropdownItem.map((dropdown) => (
          <FilterDropdown key={dropdown.id} {...dropdown} />
        ))}
      </div>
    );
}

export default CustomerFilter