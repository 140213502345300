import { SERVER_URL } from "../../config/config";
import axios from "axios";

export const getFileUploadUtilityUserRole = async () => {
    try {
        return axios.get(`${SERVER_URL}/getUserData`)
            .then(resp => {
                const { data } = resp;

                if (data?.data.length === 0) {
                    return {
                        email: null,
                        role: 'UNAUTH'
                    }
                }

                const userRole = data.data[0];
                if (userRole.isadmin) {
                    return {
                        email: userRole.email,
                        role: 'ADMIN'
                    }
                }
                return {
                    email: userRole.email,
                    role: 'NORMAL'
                }
            })
            .catch(err => {
                // // console.log("Error occured while fetching user access role: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // // console.log("Error occured while fetching user access role: ", err);
        throw new Error({ message: err });
    }
}

export const getFileUploadHistory = async () => {
    try {
        return axios.get(`${SERVER_URL}/getFileUploadHistoryData`)
            .then(resp => {
                const { data } = resp;
                return constructFileUploadHistoryData(data.data);
            })
            .catch(err => {
                // // console.log("Error occured while fetching file upload history err: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // // console.log("Error occured while fetching file upload history err: ", err);
        throw new Error({ message: err });
    }
}

const constructFileUploadHistoryData = (data) => {
    const uploadHistoryTableData = [];
    data.forEach(file => {
        let status = null;
        if (file.status === "In Progress") {
            status = <div className="statusContainer"><p className="inProgress status"></p>In Progress</div>
        } else if (file.status === "Completed") {
            status = <div className="statusContainer"><p className="success status"></p><p>Success</p></div>
        } else if (file.status === "Failed") {
            status = <div className="statusContainer"><p className="failed status"></p>Failed</div>
        }
        uploadHistoryTableData.push({
            key: file.file_id,
            fileID: file.file_id,
            fileName: file.file_name,
            time: file.last_modified_ts,
            user: file.email,
            status: status,
            template_name: file.template_name
        });
    });
    return uploadHistoryTableData;
}

export const getTemplateTableData = async () => {
    try {
        return axios.get(`${SERVER_URL}/getTemplateTableData`)
            .then(resp => {
                const { data } = resp;
                return contructTemplateTableData(data.data);
            })
            .catch(err => {
                // console.log("Error occured while fetching file upload history err: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // console.log("Error occured while fetching uploaded template data err: ", err);
        throw new Error({ message: err });
    }
}

export const getTemplateDetails = async (type, templateID) => {
    try {
        return axios.get(`${SERVER_URL}/getTemplateDetails`, {
            params: {
                templateID: templateID
            }
        })
            .then(resp => {
                const { data } = resp;
                if (type === 'user') {
                    return constructUserData(data.data[0].users);
                }
                return constructColumnData(data.data[0].columns_data, data.data[0]);;
            })
            .catch(err => {
                // console.log("Error occured while fetching file upload history err: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // console.log("Error occured while fetching uploaded template data err: ", err);
        throw new Error({ message: err });
    }
}

function constructColumnData(data, response) {
    const obj = {
        updateAllowed: response?.template_update,
        columnData: []
    };
    data.forEach(el => {
        obj.columnData.push({
            key: Math.random().toString(36).slice(2, 7),
            columnName: el.name,
            dataType: el.data_type,
            dataTypeValue: el.length,
            isValidate: el.validation_required
        });
    });
    return obj;
}

function constructUserData(data) {
    const obj = [];
    data.forEach(el => {
        obj.push({
            key: el.user_id,
            email: el.email,
            isAdmin: el.template_admin
        });
    });
    return obj;
}

function formatDate(inputDate) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const dateObj = new Date(inputDate);
    const day = dateObj.getUTCDate();
    const monthIndex = dateObj.getUTCMonth();
    const year = dateObj.getUTCFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${months[monthIndex]}-${year}`;

    return formattedDate;
}

const contructTemplateTableData = (data) => {
    const tableData = [];
    data.forEach(template => {
        tableData.push({
            key: template.template_id,
            templateID: template.template_id,
            templateName: template.template_name,
            desc: template.description,
            time: template.last_modified_ts,
            user: template.created_by,
        })
    })
    return tableData.reverse();
}

export const getTemplateData = () => {
    try {
        return axios.get(`${SERVER_URL}/getTemplateData`)
            .then(resp => {
                const { data } = resp;
                return constructTemplateData(data.data);
            })
            .catch(err => {
                // console.log("Error occured while fetching templates: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
        throw new Error({ message: err });
    }
}

const constructTemplateData = (data) => {
    const templateData = [];
    data.forEach(template => {
        const Columns = [];
        if (template.columns_data) {
            template.columns_data.forEach(column => {
                Columns.push({
                    name: column.name,
                    dataType: column.data_type,
                    validation_required: column.validation_required
                });
            });
        }
        templateData.push({
            key: template.template_id,
            label: template.template_name,
            templateCode: template.template_id,
            Columns: Columns
        });
    });
    return templateData.reverse();
}

export const sendFileMetaData = (template, file, dags) => {
    try {
        const dagList = [];
        dags.forEach(ob => {
            dagList.push(Number(ob.id));
        })
        return axios.post(`${SERVER_URL}/sendFileMetaData`, {
            template_id: template.templateCode,
            templateName: template.label,
            fileName: file.name,
            dag_id_lst: dagList
        })
            .then(resp => {
                const { data } = resp;
                return data.data
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
    }
}

export const createTemplate = (templateData, user) => {
    try {
        let formData = constructTemplateFormData(templateData, user);
        return axios.post(`${SERVER_URL}/createTemplate`, formData)
            .then(resp => {
                const { data } = resp;
                return data;
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
    }
}

export const createAirflowDag = (dagFormData, dagUserData) => {
    try {
        let formData = constructAirflowDagBody(dagFormData, dagUserData);
        return axios.post(`${SERVER_URL}/createDag`, formData)
            .then(resp => {
                const { data } = resp;
                return data;
            });
    } catch (err) {

    }
}

export const fetchAirflowDag = (templateID) => {
    try {
        if (templateID) {
            return axios.get(`${SERVER_URL}/fetchAirflowDag`, {
                params: {
                    templateID: templateID
                }
            })
                .then(resp => {
                    return constructDagMenuData(resp?.data?.data);
                })
        }
        return axios.get(`${SERVER_URL}/fetchAllAirflowDag`)
            .then(resp => {
                return constructDagMenuData(resp?.data?.data);
            })

    } catch (err) {

    }
}

export const integrateDagWithTemplate = (templateID, dags) => {
    try {
        return axios.post(`${SERVER_URL}/integrateDag`, {
            template_id: templateID,
            dag_id_lst: constructIntegrateDag(dags)
        })
            .then(resp => {
                const { data } = resp;
                return data.data
            });
    } catch (err) {

    }
}

export const updateUsers = (templateID, userList) => {
    try {
        return axios.post(`${SERVER_URL}/updateTemplateUsers`, constructUserList(templateID, userList))
            .then(resp => {
                const { data } = resp;
                return data;
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
    }
}

export const updateColumn = (templateID, columnData) => {
    try {
        return axios.post(`${SERVER_URL}/updateTemplateColumnData`, constructColumnList(templateID, columnData))
            .then(resp => {
                const { data } = resp;
                return data;
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
    }
}

export const constructUserList = (templateID, userList) => {
    let obj = {
        template_id: templateID,
        users: []
    }
    userList.forEach(el => {
        obj.users.push({
            email: el.email,
            template_admin: el.isAdmin
        })
    });
    return obj;
}

export const constructColumnList = (templateID, columnList) => {
    let obj = {
        templateID: templateID,
        updated_by: '',
        columnsData: []
    }
    columnList.forEach(el => {
        obj.columnsData.push({
            "name": el.columnName,
            "data_type": el.dataType,
            "length": el.dataTypeValue,
            "validation_required": el.isValidate
        })
    });
    return obj;
}

const constructTemplateFormData = (formData, user) => {
    let users = [];
    formData.users.forEach(user => {
        users.push({
            "is_admin": user.isAdmin,
            "email": user.email
        });
    });
    let columnsData = [];
    formData.columnsData.forEach(column => {
        columnsData.push({
            "name": column.columnName,
            "data_type": column.dataType.value,
            "length": column.dataTypeValue,
            "validation_required": column.isValidate
        });
    });

    return {
        "templateName": formData.templateName,
        "desc": formData.desc,
        "aggregatorFunctions": formData.aggregator,
        "validationFunctions": formData.validation,
        "users": users,
        "columnsData": columnsData,
        "uploaded_by": user
    };
}

export const fetchVaultData = () => {
    try {
        return axios.get(`${SERVER_URL}/getVaultData`)
            .then(resp => {
                const { data } = resp;
                return {
                    status: 200,
                    data: data
                };
            })
            .catch(err => {
                // console.log("Error occured while fetching templates: ", err);
                throw new Error({ message: err });
            });
    } catch (err) {
        // console.log("Error occured while fetching templates: ", err);
        throw new Error({ message: err });
    }
}

const constructDagMenuData = (data) => {
    const obj = [];
    data.forEach(ob => {
        obj.push({
            label: ob.dag_name,
            id: ob.dag_id
        })
    });
    return obj;
}

const constructIntegrateDag = (dags) => {
    const obj = [];
    console.log(dags);
    dags.forEach(ob => {
        obj.push(ob.id);
    });
    return obj;
}

const joinNames = (names) => {
    return names.join(', ');
}


const constructAirflowDagBody = (dagFormData, dagUserData) => {
    const response_body = {
        airflow_dags: []
    };
    const successMails = [];
    const failMails = [];
    dagUserData.forEach(ob => {
        if (ob.accessTypeValue === "success") {
            successMails.push(ob.userEmailAddress);
        } else if (ob.accessTypeValue === "failure") {
            failMails.push(ob.userEmailAddress);
        }
    });
    const successMailsString = joinNames(successMails);
    const failureMailsString = joinNames(failMails);
    dagFormData.forEach(ob => {
        if (ob.functionList.length > 0) {
            const tempData = [];
            tempData.push({
                functionName: ob.functionName,
                executionMode: ob.functionList[0].executionMode

            });
            ob.functionList.forEach(ob => {
                tempData.push(ob);
            })
            ob.functionList = tempData;
        }
        const obj = {
            dag: ob.dagName,
            description: 'file upload utility Dag',
            success_email: successMailsString,
            failure_email: failureMailsString,
            schedule_interval: 'No',
            start_date: new Date(),
            tags: "db_func,fileupload,tableuextract",
            function_config: ob.functionList.length>0 ?constructFunctionObj(ob.functionList) : constructFunctionObj([{'functionName':ob?.functionName}]),
            tableau_report_extract_json: constructReportObj(ob.tableauReports),
            business: "devops"
        }
        response_body.airflow_dags.push(obj);
    });
    return response_body;
}

const constructFunctionObj = (data) => {
    let response = [];
    let currObj = [];
    let seqArray=[];
    const len = data.length;
    for (let i = 0; i <len; i++) {
        if (data[i]?.executionMode?.value === 'parallel') {
            if(seqArray.length>0){
                seqArray.push({
                    name: data[i].functionName,
                    conn_id: "connection1",
                    fn_name: data[i].functionName,
                    rule: "all_success"
                })
                response.push({ parallel: seqArray });
                seqArray=[];
            } else{
                currObj.push({
                    name: data[i].functionName,
                    conn_id: "connection1",
                    fn_name: data[i].functionName,
                    rule: "all_success"
                });
            }
            
        } else {
        if (data[i]?.executionMode?.value === 'sequential') {
            if(currObj.length>0){
                response.push({ parallel: currObj });
                currObj=[]
            }
            if(i !== 0 && i !== 1){
            seqArray.push({
                    name: data[i].functionName,
                    conn_id: "connection1",
                    fn_name: data[i].functionName,
                    rule: "all_success"
                });
            } else{
                response.push({
                    name: data[i].functionName,
                    conn_id: "connection1",
                    fn_name: data[i].functionName,
                    rule: "all_success"
                });
            }
                
        } else {
            response.push({
                name: data[i].functionName,
                conn_id: "connection1",
                fn_name: data[i].functionName,
                rule: "all_success"
            });
        }
    }
    }
    if(currObj.length>0){
        response.push({ parallel: currObj });
        currObj=[]
    }
    if(seqArray.length>0){
        response.push(...seqArray)
        seqArray=[]
    }
    return response;
}

const constructReportObj = (data) => {
    const response_body = [];
    data.forEach(ob => {
        response_body.push({
            name: buildReportName(ob.reportName),
            resource_type: "workbooks",
            tableau_conn_id: "token1",
            match_with: "id",
            resource_name: "7fa11556-75ae-4790-aae7-bdd2daab3f63",
            rule: "all_success"
        })
    });
    return response_body;
}

const buildReportName = (str) => {
    const trimmedStr = str.trim();

    const formattedStr = trimmedStr.replace(/\s+/g, '_');

    return formattedStr;
}