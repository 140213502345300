import React, { useState,useEffect } from 'react'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import {setSelectedValue,setReportsData,setReportsDataLoading} from '../../../../features/JiraAnalysis/autoFixSlice'
import {getAutoFixReportsData} from '../../AutoFixReportsUtil'

const AutoFixFilter = () => {
    const dispatch = useDispatch();
  const {dropdownOptions, data,selectedValues,loading,ReportsData } = useSelector((state) => state.autoFixReports);
    const [openProduct, setOpenProduct] = useState(false)
    const [openComponent, setOpenComponent] = useState(false)
    const [openPriority, setOpenPriority] = useState(false)
    const [openBranch, setOpenBranch] = useState(false)
    const [openPrStatus, setOpenPRStatus] = useState(false) 
    const [openRepoUrl, setOpenRepoUrl] = useState(false) 
    const [openViewBy, setOpenViewBy] = useState(false)
    
  const cleanSelectedValues = (selectedValues) => {
    const viewBy={'Weekly':'week','Monthly':'month','Daily':'day'}
    return Object.keys(selectedValues).reduce((cleanedValues, key) => {
      if (selectedValues[key] !== "All") {
        if(Array.isArray(selectedValues[key])){
            let value=   selectedValues[key].filter((item) => item !== 'All') // Exclude "All"
               .map((item) => item);
               if(value.length>0){
               cleanedValues[key] =value}
        }else{
          cleanedValues[key] =selectedValues[key]
        }
      }
      if(key === 'viewBy'){
        cleanedValues['filter'] =  viewBy[selectedValues['viewBy']]|| 'month'
      }
      if(key === 'dateRange'){
        cleanedValues['start_date'] =`${moment(selectedValues[key]?.startDate ).format("YYYY-MM-DDTHH:mm:ss[Z]")}`
        cleanedValues['end_date'] = `${moment(selectedValues[key]?.endDate ).format("YYYY-MM-DDTHH:mm:ss[Z]")}`
       
      }
      delete cleanedValues.dateRange
      delete cleanedValues.viewBy
      delete cleanedValues.applyButton
      
      return cleanedValues;
    }, {});
  };
  
    useEffect(() => {
      const cleanedValues = cleanSelectedValues(selectedValues);
      if (Object.keys(cleanedValues).length > 0 && (selectedValues.applyButton)) {
        dispatch(setReportsDataLoading(true));
        dispatch(setReportsData([]));
      getAutoFixReportsData(cleanedValues)
        .then(resp => {
          dispatch(setReportsData(resp));
         
          dispatch(setReportsDataLoading(false));
          dispatch(
            setSelectedValue({ field: "applyButton", value: false })
          );
          
        })
        .catch(err => {
            // window.location.replace('/error')
        })
      }
      }, [selectedValues.applyButton, selectedValues.dateRange ,selectedValues.viewBy]);
    const handleClose = (id) => {
      switch (id) {
        case 'customerProducts':
          return setOpenProduct(false)
        case 'customerComponents':
          return setOpenComponent(false)
        case 'customerPriority':
          return setOpenPriority(false)
          case 'Branch':
          return setOpenBranch(false)
        case 'PRStatus':
          return setOpenPRStatus(false)
        case 'RepositoryUrl':
          return setOpenRepoUrl(false)
        case 'customerViewBy':
          return setOpenViewBy(false)
        default:
          return
      }
    }
    const handleApplyClick=(loading)=>{
    dispatch(
            setSelectedValue({ field: "applyButton", value: false })
          );
    }
    const customerDropdownItem = [
      {
        id: "customerProducts",
        component:"AUTOFIX",
        label: "Product",
        placeholder:"Select a person",
        mode: "multiple",
        open:openProduct,
        loading: loading.Project,
        handleClose:handleClose,
        handleApplyClick,
        items: [ { label: "All", value: "All" },...dropdownOptions?.Project.map(item=>({'label':item,'value':item,'data-testid':item}))],
        
        selectedItem: selectedValues.Project,
        onChangeItem: (item) => {
           if (item.includes("All") && item.length > 1) {
              const filteredItem = item.filter((state) => state !== "All");
              dispatch(
                setSelectedValue({ field: "Project", value: filteredItem })
              );
            }else{
              dispatch(
                setSelectedValue({ field: "Project", value: item })
              );
            }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "Project", value: ['All'] }));
          }
          setOpenProduct(true)
        },
      },
      {
        id: "customerComponents",
        component:"AUTOFIX",
        label: "Module",
        placeholder:"Select a person",
        mode: "multiple",
        open:openComponent,
        loading: loading.Project,
        handleClose:handleClose,

        items: [ { label: "All", value: "All" },...dropdownOptions?.Module.map(item=>({'label':item,'value':item,'data-testid':item}))],
        
        selectedItem: selectedValues.Module,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setSelectedValue({ field: "Module", value: filteredItem })
            );
          }else{
            dispatch(
              setSelectedValue({ field: "Module", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "Module", value: ['All'] }));
          }
          setOpenComponent(true)
        },
      },
      {
        id: "customerPriority",
        component:"AUTOFIX",
        label: "Level",
        mode: "multiple",
        open:openPriority,
        loading: loading.Project,
        handleClose:handleClose,
        items: [{ label: "All", value: "All" }, ...dropdownOptions?.Level.map(item=>({'label':item,'value':item,'data-testid':item}))],
        selectedItem: selectedValues.Level,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setSelectedValue({ field: "Level", value: filteredItem })
            );
          }else{
            dispatch(
              setSelectedValue({ field: "Level", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "Level", value: ['All'] }));
          }
          setOpenPriority(true)
        },
      },
      {
        id: "Branch",
        component:"AUTOFIX",
        label: "Branch",
        mode: "multiple",
        open:openBranch,
        loading: loading.Project,
        handleClose:handleClose,

        items: [{ label: "All", value: "All" },...dropdownOptions?.Branch.map(item=>({'label':item,'value':item,'data-testid':item}))],
        
        selectedItem: selectedValues.Branch,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setSelectedValue({ field: "Branch", value: filteredItem })
            );
          }else{
            dispatch(
              setSelectedValue({ field: "Branch", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "Branch", value: ['All'] }));
          }
          setOpenBranch(true)
        },
      },
      {
        id: "PRStatus",
        component:"AUTOFIX",
        label: "PR Status",
        mode: "multiple",
        open:openPrStatus,
        loading: loading.Project,
        handleClose:handleClose,

        items: [{ label: "All", value: "All" },...dropdownOptions?.PRStatus.map(item=>({'label':item,'value':item,'data-testid':item}))],
        
        selectedItem: selectedValues.PRStatus,
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setSelectedValue({ field: "PRStatus", value: filteredItem })
            );
          }else{
            dispatch(
              setSelectedValue({ field: "PRStatus", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "PRStatus", value: ['All'] }));
          }
          setOpenPRStatus(true)
        },
      },
      {
        id: "RepositoryUrl",
        component:"AUTOFIX",
        label: "Repository URL",
        mode: "multiple",
        open:openRepoUrl,
        loading: loading.Project,
        handleClose:handleClose,

        items: [{ label: "All", value: "All" }, ...dropdownOptions?.RepositoryUrl.map(item=>({'label':item,'value':item,'data-testid':item}))],
        
        selectedItem: selectedValues.RepositoryUrl.slice(0,20),
        onChangeItem: (item) => {
          if (item.includes("All") && item.length > 1) {
            const filteredItem = item.filter((state) => state !== "All");
            dispatch(
              setSelectedValue({ field: "RepositoryUrl", value: filteredItem })
            );
          }else{
            dispatch(
              setSelectedValue({ field: "RepositoryUrl", value: item })
            );
          }
        },
        handleClick: (e) => {
          if (e.target.textContent === "All") {
            dispatch(setSelectedValue({ field: "RepositoryUrl", value: ['All'] }));
          }
          setOpenRepoUrl(true)
        },
      },
    ];
    return (
      <div
        className="filterDropdownContainer filterCycle"
        data-testid="jira-ssot-filter-container"
      >
        {customerDropdownItem.map((dropdown) => (
          <FilterDropdown key={dropdown.id} {...dropdown} />
        ))}
      </div>
    );
}

export default AutoFixFilter