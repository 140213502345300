import { Parser } from "@json2csv/plainjs";

export const downloadTemplate = (selectedTemplate) => {
    try {
        const tempData = selectedTemplate
        ["Columns"].reduce(
            (acc, curr) => {
                const columName = `${curr["name"]}`;
                acc[0][columName] = "";
                return acc;
            },
            [{}]
        );
        const csvInput = tempData;
        const parser = new Parser();
        const csv = parser.parse(csvInput);
        const element = document.createElement("a");
        const file = new Blob([csv], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = `${selectedTemplate.label}.csv`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    } catch (err) {
        console.error(err);
    }
};