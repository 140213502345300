import {
  DatePicker,
  NotificationTrigger,
  Title,
  Pagination,
} from "@nutanix-ui/prism-reactjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAutomationCoveragePagination,
  setAutomationCoverageTestPagination,
  setAvgDeveloperPagination,
  setCustomerDefectsPagination,
  setCycleDateRange,
  setDeveloperPagination,
  setFilters,
  setJiraDateRange,
  setLoading,
  toggleLoadingOvertime,
} from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { Select, Tooltip } from "antd";
import { generateMenuForDropdown } from "../../EngineeringUtils";

const Header = ({ title, id }) => {
  const dispatch = useDispatch();
  const {
    developerPage,
    cycleDateRange,
    filters,
    jiraComparisonData,
    activeHeaderTab,
    developerVelocityData,
    avgDeveloperVelocityData,
    jiraDateRange,
    avgDeveloperPage,
    stagesData,
    overTimeData,
    cfdData,
    customerDefectsPage,
    automationCoveragePage,
    automationCoverage,
    automationTestData,
    automationCoverageTestPage
  } = useSelector((state) => state.enggDashboard);
  const issuenumValues = jiraComparisonData.map((item) => item.issuenum);
  const uniqueIssuenumValues = [...new Set(issuenumValues)];
  const selectPropsOvertime = {
    mode: "single",
    style: {
      width: "100px",
    },
    showArrow: true,
    value: filters.viewBy,
    options: generateMenuForDropdown(["Monthly", "Quarterly"]),
    onChange: (newValue) => {
      dispatch(toggleLoadingOvertime(true));
      dispatch(setFilters({ section: "viewBy", value: newValue }));
    },
    maxTagCount: "responsive",
  };
  const selectPropsJiraComparison = {
    mode: "multiple",
    style: {
      width: "150px",
    },
    showArrow: true,
    value: filters.selectedJiraIssues,
    options: [...generateMenuForDropdown([...uniqueIssuenumValues])],
    onChange: (item) => {
      if (item.length > 10) {
        item = item.slice(0, 10);
        return NotificationTrigger.add({
          key: "jiraSelection",
          message: "You can only select up to 10 Jira items.",
          autoDismissDelaySecs: 3,
          type: "info",
          actions: null,
        });
      }
      if (item.includes("All") && item.length > 1) {
        const filteredItem = item.filter((state) => state !== "All");
        dispatch(
          setFilters({ section: "selectedJiraIssues", value: filteredItem })
        );
      } else if (item.length === 0) {
        dispatch(setFilters({ section: "selectedJiraIssues", value: ["All"] }));
      } else {
        dispatch(setFilters({ section: "selectedJiraIssues", value: item }));
      }
    },
    onClick: (e) => {
      if (e.target.textContent === "All") {
        dispatch(setFilters({ section: "selectedJiraIssues", value: ["All"] }));
      }
    },
    maxTagCount: "responsive",
  };

  const onChangeDeveloperPagination = (page, pageSize) => {
    dispatch(
      setDeveloperPagination({
        currentPage: page,
        pageSize,
      })
    );
  };
  const onChangeAvgDeveloperPagination = (page, pageSize) => {
    dispatch(
      setAvgDeveloperPagination({
        currentPage: page,
        pageSize,
      })
    );
  };

  const onCustomerDefectsPagination = (page, pageSize) => {
    dispatch(
      setCustomerDefectsPagination({
        currentPage: page,
        pageSize,
      })
    );
  };

  const onAutomationPagination = (page, pageSize) => {
    dispatch(
      setAutomationCoveragePagination({
        currentPage: page,
        pageSize,
      })
    );
  };
  
  const onAutomationTestPagination = (page, pageSize) => {
    dispatch(
      setAutomationCoverageTestPagination({
        currentPage: page,
        pageSize,
      })
    );
  };

  return (
    <div className="wiget_header_container">
      <Title size="h3" className="widget_title">
        {title}
      </Title>
      {activeHeaderTab === 2 && (
        <>
          {id === "stages" && (
            <Tooltip title="Includes Jira's for which development started during this date range.">
              <div>
                <DatePicker
                  name="datepicker-with-controlled-range-1"
                  oldDatePicker={false}
                  value={cycleDateRange}
                  disabled={stagesData.length === 0 ? true : false}
                  // style={{ width: '50px' }}
                  onChange={(selectedDate) => {
                    const button = document.querySelector(
                      ".wiget_header_container .ntnx-date-picker-open.ntnx.ntnx-button"
                    );
                    if (button) {
                      button.click();
                    }
                    if (selectedDate) {
                      dispatch(setCycleDateRange(selectedDate));
                      dispatch(
                        setLoading({
                          stages: true,
                        })
                      )
                    }
                  }}
                />
              </div>
            </Tooltip>
          )}
          {id === "overtime" && (
            <Select
              {...selectPropsOvertime}
              className="filterSelect"
              data-testid="filter-select"
              disabled={overTimeData.length === 0 ? true : false}
            />
          )}
          {id === "jira_comparison" && (
            <div style={{ display: "flex", gap: "20px" }}>
              <Select
                {...selectPropsJiraComparison}
                className="filterSelect"
                data-testid="filter-select"
                disabled={jiraComparisonData.length === 0 ? true : false}
              />

              <Tooltip title="Includes Jira's for which development started during this date range.">
                <div>
                  <DatePicker
                    name="datepicker-with-controlled-range-2"
                    oldDatePicker={false}
                    value={jiraDateRange}
                    disabled={jiraComparisonData.length === 0 ? true : false}
                    // style={{ width: '50px' }}
                    onChange={(selectedDate) => {
                      const button = document.querySelector(
                        "#jira_comparison .ntnx-date-picker-open.ntnx.ntnx-button"
                      );
                      if (button) {
                        button.click();
                      }
                      if (selectedDate) {
                        dispatch(setJiraDateRange(selectedDate));
                        dispatch(
                          setLoading({
                            jiraComparison: true,
                          })
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </>
      )}
      {activeHeaderTab === 1 && (
        <>
          {id === "developerVelocity" && (
            <Pagination
              total={
                developerVelocityData.length > 0
                  ? developerVelocityData.length
                  : 1
              }
              currentPage={developerPage.currentPage}
              pageSize={developerPage.pageSize}
              onChange={onChangeDeveloperPagination}
            />
          )}
          {id === "avgDeveloper" && (
            <Pagination
              total={
                avgDeveloperVelocityData.length > 0
                  ? avgDeveloperVelocityData.length
                  : 1
              }
              currentPage={avgDeveloperPage.currentPage}
              pageSize={avgDeveloperPage.pageSize}
              onChange={onChangeAvgDeveloperPagination}
            />
          )}
        </>
      )}
      {activeHeaderTab === 3 && (
        <>
          {id === "customerDefects" && (
            <Pagination
              total={cfdData.length > 0 ? cfdData.length : 1}
              currentPage={customerDefectsPage.currentPage}
              pageSize={customerDefectsPage.pageSize}
              onChange={onCustomerDefectsPagination}
            />
          )}
        </>
      )}
      {activeHeaderTab === 4 && (
        <>
          {id === "automationCoverage" && (
            <Pagination
              total={automationCoverage.length > 0 ? automationCoverage.length : 1}
              currentPage={automationCoveragePage.currentPage}
              pageSize={automationCoveragePage.pageSize}
              onChange={onAutomationPagination}
            />
          )}
          {id === "automationTest" && (
            <Pagination
              total={automationTestData.length > 0 ? automationTestData.length : 1}
              currentPage={automationCoverageTestPage.currentPage}
              pageSize={automationCoverageTestPage.pageSize}
              onChange={onAutomationTestPagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Header;
