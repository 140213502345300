import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import LineTrends from "./LineTrends";
import { SERVER_URL } from "../../../../../../config/config";
import { useFilters } from "../../../../../../contexts/uxContexts/FiltersContext";

// Updated initialStatisticsData with id like 'ux_required', 'pending_content_review'
const initialStatisticsData = [
  { id: 'total_ux_required', label: 'UX Required', count: 0, stroke: '#E69B0A', trends: [] },
  { id: 'pending_ux_owner_assignment', label: 'Pending Content Review', count: 0, stroke: '#E69B0A', trends: [] },
  { id: 'pending_design_handoff', label: 'Pending Design at EC', count: 0, stroke: '#E69B0A', trends: [] },
  { id: 'pending_vd_review', label: 'Pending VD Review', count: 0, stroke: '#E69B0A', trends: [] },
  { id: 'ux_a11y_health_check', label: 'UX A11y Health Check', count: 0, stroke: '#20794E', trends: [] },
  { id: 'total_ux_commits', label: 'UX Commits', count: 0, stroke: '#22A5F7', trends: [] },
  { id: 'pending_ux_fnf_review', label: 'Pending FNF Review', count: 0, stroke: '#E69B0A', trends: [] },
  { id: 'total_cg_reviw_passed', label: 'CG Review Passed', count: 0, stroke: '#20794E', trends: [] },
  { id: 'total_pg_reviw_passed', label: 'PG Review Passed', count: 0, stroke: '#20794E', trends: [] },
  { id: 'released_to_end_user', label: 'Released to End User', count: 0, stroke: '#20794E', trends: [] },
];

const CardContainer = ({ applyClicked }) => {
  const { selectedFilters } = useFilters();
  const [statusData, setStatusData] = useState(initialStatisticsData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const filterQueryKey = useMemo(() => {
    const { ux_owners, projects, fixversions, ux_pod } = selectedFilters;

    return {
      ux_owners: ux_owners[0] === 'All' ? null : ux_owners,
      projects: projects[0] === 'All' ? null : projects,
      fixversions: fixversions[0] === 'All' ? null : fixversions,
      ux_pod: ux_pod[0] === 'All' ? null : ux_pod,
    };
  }, [selectedFilters]);

  const fetchData = async () => {
    setIsLoading(true); // Set loading state at the beginning
    try {
      const response = await axios.post(`${SERVER_URL}/statistics`, {
        ux_owners: filterQueryKey.ux_owners,
        projects: filterQueryKey.projects,
        fixversions: filterQueryKey.fixversions,
        ux_pod: filterQueryKey.ux_pod,
      });
      if(!response?.data?.data){
        setStatusData(initialStatisticsData)
        return
      }
      const updatedStatusData = mapApiResponseToStatusData(response?.data?.data);
      setStatusData(updatedStatusData);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false); // Ensure loading state is reset in finally block
    }
  };
  
  const mapApiResponseToStatusData = (apiData) => {
    return statusData.map((item) => {
      const apiItem = apiData[item.id] || {}; // Default to empty object to avoid errors
      return {
        ...item,
        count: apiItem.count || item.count, // Use existing count if not available
        trends: apiItem.trends || item.trends, // Use existing trends if not available
      };
    });
  };
  
  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);
  
  useEffect(() => {
    if (applyClicked) {
      fetchData(); // Refetch data if apply button is clicked
    }
  }, [applyClicked]);
  

  // if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="grid-container">
      {statusData.map((item) => (
        <div className="grid-item" key={item.id}>
          <div className="left-section">
            <div className="top-part" style={{ color: item.textColor || '' }}>
              {item.count}
            </div>
            <div className="bottom-part">{item.label}</div>
          </div>
          <div className="right-section">
          {isLoading ? <div>Loading Trends...</div> : <LineTrends trends={item.trends} stroke={item.stroke} />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardContainer;
