import React from "react";
import { FlexLayout, TextLabel } from "@nutanix-ui/prism-reactjs";

const NoData = ({ height = 200, fontSize = "40px", message = "No Data" }) => {
  return (
    <FlexLayout
      style={{ height }}
      justifyContent="center"
      alignItems="center"
    >
      <TextLabel style={{ fontSize }}>{message}</TextLabel>
    </FlexLayout>
  );
};

export default NoData;
