import moment from "moment";
import WeeklyDataComponent from "./DashboardCalendar/CalendarView/WeeklyView/WeeklyDataComponent";
import DayDataComponent from "./DashboardCalendar/CalendarView/DayView/DayDataComponent";
import { Badge, Typography } from "antd";
import { ColorManager } from "@nutanix-ui/recharts";


export const getUniqueValuesForSprint = (data, key) => {
  let uniqueValues = [];
  data.map((item) => {
    uniqueValues.push(
      item[key.name] === null ? `No ${key.nullLabel} Assigned` : item[key.name]
    );
  });
  return [...new Set(uniqueValues)];
};

export const getUniqueValuesByKey = (currentDataBySelectedSprint, key) => {
  let uniqueValues = [];
  currentDataBySelectedSprint.map((item) => {
    uniqueValues.push(
      item[key.name] === null || ""
        ? `No ${key.nullLabel} Assigned`
        : item[key.name]
    );
    return null
  });
  return [...new Set(uniqueValues)];
};

export const getUniqueValuesByArray = (currentDataBySelectedSprint, key) => {
  // filterData by currentSprint
  let uniqueValues = [];
  // get currentDataBySelectedSprint and generate unique out of it
  currentDataBySelectedSprint?.map((sprintItems) => {
    if (sprintItems[key.name] && sprintItems[key.name].length === 0) {
      uniqueValues.push(`No ${key.nullLabel} Assigned`);
    } else {
      sprintItems[key.name]?.map((item) => {
        uniqueValues.push(item);
        return null
      });
    }
    return null
  });
  return [...new Set(uniqueValues)];
};

export const filterByDropdownSelection = (
  data,
  selectedSprints,
  selectedAssignees,
  selectedComponents,
  selectedIssueTypes,
  selectedLabels
) => {
  const filterData = data.filter((item) => {
    const isSprintMatch =
      selectedSprints === null ||
      (selectedSprints.includes("null") && item.sprint === null) ||
      (Array.isArray(selectedSprints) &&
        (selectedSprints.includes("All") ||
          selectedSprints.includes(item.sprint)));
    
    const isComponentMatch =
      !selectedComponents ||
      selectedComponents.includes("All") ||
      !item.component ||
      item.component.length === 0 ||
      selectedComponents.some((component) =>
        item.component.includes(component)
      );

    const isLabelsMatch =
      !selectedLabels ||
      selectedLabels.includes("All") ||
      !item.labels ||
      item.labels.length === 0 ||
      selectedLabels.some((labels) => item.labels.includes(labels));

    const isIssueTypeMatch =
      !selectedIssueTypes ||
      selectedIssueTypes.includes("All") ||
      !item.issue_type ||
      item.issue_type.length === 0 ||
      selectedIssueTypes.some((issue_type) => item.issue_type.includes(issue_type));

    const isAssigneeMatch =
      (selectedAssignees &&
        selectedAssignees.includes("null") &&
        item.assignee_name === null) ||
      (Array.isArray(selectedAssignees) &&
        (selectedAssignees.includes("All") ||
          selectedAssignees.includes(item.assignee_name)));

    return (
      isSprintMatch &&
      isIssueTypeMatch &&
      isAssigneeMatch &&
      isComponentMatch &&
      isLabelsMatch
    );
  });
  return filterData;
};

export const updateArrayWithAllOption = (arr) => {
  const hasOtherItems = arr.some((item) => item !== "All");
  if (hasOtherItems) {
    // If other items exist, remove 'All'
    arr = arr.filter((item) => item !== "All");
  } else if (arr.length === 0) {
    // If the array is empty, add 'All'
    arr.push("All");
  }
  return arr;
};

export const createArrayOfObjectsFromUniqueKeys = (
  dataArray,
  uniqueKeys,
  criteria
) => {
  const result = {};
  for (const key of uniqueKeys) {
    const dataForKey = dataArray.filter((obj) => {
      if(obj[criteria] !== null){
        return obj[criteria].includes(key)
      }else{
        // 'No Assignee Assigned' || 'No Components Assigned'
        return obj
      }
    });
    result[key] = dataForKey;
  }
  return result;
};

export const filterDataByDate = (data, date) => {
  return data.filter((item) => {
    const etaDueLatest = item.dt_eta_due_latest;
    if (etaDueLatest && moment(etaDueLatest, moment.ISO_8601, true).isValid()) {
      const etaDueDate = moment(etaDueLatest);
      const targetDate = moment(date);
      return etaDueDate.isSame(targetDate, "day");
    }
    return false;
  });
};

export const indicatorItems = [
  {
    key: "etaMissed",
    status: "missed",
    title: "ETA Missed - Open",
  },
  {
    key: "etaMissedCompleted",
    status: "missed-completed",
    title: "ETA Missed - Completed",
  },
  {
    key: "current",
    status: "current",
    title: "Current",
  },
  {
    key: "completed",
    status: "completed",
    title: "Completed",
  },
  {
    key: "assigned",
    status: "assigned",
    title: "Assigned",
  },
];

const textMappings = {
  1: "XS=1",
  2: "S=2",
  3: "M=3",
  5: "L=5",
  8: "XL=8",
  13: "XXL=13",
  21: "XXXL=21",
  // Add more mappings as needed
};

export const ViewDetailsColumns = [
  {
    title: "Jira Number",
    dataIndex: "jiraNumber",
    ellipsis: true,
    width: 110,
    render: (text, record) => {
      const jiraLink = `https://jira.nutanix.com/browse/${text}`;
      return (
        <Typography.Link href={jiraLink} target="_blank">
          {text}
        </Typography.Link>
      );
    },
  },
  {
    title: "Summary",
    dataIndex: "summary",
    ellipsis: true,
    width: 250,
  },
  {
    title: "Age Days",
    dataIndex: "ageDay",
    ellipsis: true,
    width: 82,
    align: "center",
  },
  {
    title: "Business Name",
    dataIndex: "businessName",
    ellipsis: true,
    width: 183,
  },
  {
    title: "Component",
    dataIndex: "component",
    ellipsis: true,
    width: 190,
  },
  {
    title: "Issue Type",
    dataIndex: "issueType",
    ellipsis: true,
    width: 143,
  },
  {
    title: "Labels",
    dataIndex: "label",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    width: 170,
    render: (text) => {
      const formattedDate = moment(text)?.format("MMM DD YYYY, h:mm A");
      return formattedDate;
    },
    ellipsis: true,
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
    width: 170,
    render: (text) => {
      const formattedDate = moment(text)?.format("MMM DD YYYY, h:mm A");
      return formattedDate;
    },
    ellipsis: true,
  },
  {
    title: "Initial ETA",
    dataIndex: "initialEta",
    width: 110,
    render: (text) => {
      if (text !== null) {
        const inputDate = moment(text);
        const formattedDate = inputDate.format("MMM DD YYYY");
        return formattedDate;
      } else {
        return "No ETA Assigned";
      }
    },
    ellipsis: true,
  },
  {
    title: "Latest ETA",
    dataIndex: "lastestEta",
    width: 110,
    render: (text) => {
      if (text !== null) {
        const inputDate = moment(text);
        const formattedDate = inputDate.format("MMM DD YYYY");
        return formattedDate;
      } else {
        return "No ETA Assigned";
      }
    },
    ellipsis: true,
  },
  {
    title: "Days In Status",
    dataIndex: "daysInStatus",
    ellipsis: true,
    width: 69,
    align: "center",
  },
  {
    title: "Initial Story Point",
    dataIndex: "initialStoryPoint",
    width: 88,
    render: (text) => {
      const mappedText = textMappings[text];
      return mappedText !== undefined ? mappedText : text;
    },
    ellipsis: true,
    align: "center",
  },
  {
    title: "Latest Story Point",
    dataIndex: "lastestStoryPoint",
    width: 88,
    render: (text) => {
      const mappedText = textMappings[text];
      return mappedText !== undefined ? mappedText : text;
    },
    ellipsis: true,
    align: "center",
  },
  {
    title: "Count Sprint",
    dataIndex: "countSprint",
    width: 63,
    ellipsis: true,
    align: "center",
  },
  {
    title: "Count Story Point Changes",
    dataIndex: "countStoryPoints",
    ellipsis: true,
    width: 125,
    align: "center",
  },
  {
    title: "Jira Status",
    dataIndex: "jiraStatus",
    ellipsis: true,
    width: 175,
    render: (text) => {
      return (
        <div id="jiraStatusCell">
          {/* <span id="indicators" className={`${text}`}></span> */}
          <span>{text}</span>
        </div>
      );
    },
  },
];

export const getViewDetailDataSource = (data) => {
  return data.map((item, index) => {
    const dataSourceItem = {
      key: index + 1,
      jiraNumber: item.issuenum || "-",
      summary: item.summary || "-",
      ageDay: item.issue_age_days || "-",
      businessName: item.businessname || "-",
      component: item.component || "-",
      issueType: item.issue_type || "-",
      label: Array.isArray(item.labels) ? item.labels.join(", ") : "-",
      assignee: item.assignee_name || "-",
      createdDate: item.dt_created,
      updatedDate: item.dt_updated,
      initialEta: item.dt_eta_initial,
      lastestEta: item.dt_eta_latest,
      daysInStatus: item.days_jira_status || "-",
      initialStoryPoint: item.story_point_initial || "-",
      lastestStoryPoint: item.story_point_latest || "-",
      countSprint: item.cnt_sprint || "-",
      countStoryPoints: item.cnt_story_point || "-",
      jiraStatus: item.jira_status || "-",
    };
    return dataSourceItem;
  });
};

export const generateDateArray = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateArray = [];
  let currentDate = start.clone();

  while (currentDate.isSameOrBefore(end, "day")) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return dateArray;
};

export const generateWeeksArray = (datesArray) => {
  const weeksArray = [];
  const weeksCount = 7; // Number of days in a week
  const targetWeeks = 5; // Total weeks to generate

  for (let i = 0; i < targetWeeks; i++) {
    const startIdx = i * weeksCount;
    let endIdx = startIdx + weeksCount;
    const weekDates = datesArray.slice(startIdx, endIdx);

    // If there are not enough dates for a full week, fill with the next available dates
    while (weekDates.length < weeksCount && endIdx < datesArray.length) {
      weekDates.push(datesArray[endIdx]);
      endIdx++;
    }

    weeksArray.push({
      week: `Week ${i + 1}`,
      dates: weekDates,
    });
  }

  return weeksArray;
};

const getTitle = (projectId, viewBy) => {
  if (projectId === 12530) {
    switch (viewBy?.label) {
      case 'Component':
        return 'Teams';
      case 'Assignee Name':
        return 'Assignee Name';
      default:
        return 'Business Name';
    }
  }
  return 'Team';
};

export const generateColumnsForWeeklyView = (weeksInArray, viewBy, currentTeam) => {
  const columns = [
    {
      title: getTitle(currentTeam, viewBy),
      dataIndex: "component",
      key: "component",
      width: "16.67%",
      sorter: (a, b) => a.component.localeCompare(b.component),
      defaultSortOrder: 'ascend'
    },
  ];
  weeksInArray.map((weeks) => {
    const weekNumber = weeks.week.trim().replace(/\D/g, "");
    const formattedWeekNumber = weekNumber.padStart(2, "");
    const currentDate = moment().format("YYYY-MM-DD");
    const isCurrentWeek = weeks.dates.includes(currentDate);
    columns.push({
      title: `W${formattedWeekNumber}`,
      dataIndex: formattedWeekNumber,
      key: formattedWeekNumber,
      dates: weeks.dates,
      width: "16.67%",
      align: "center",
      className: isCurrentWeek ? "current-week weekHeader" : "weekHeader",
    });
    return null
  });
  return columns;
};

export const generateColumnsForDayView = (currentWeekDates, viewBy, currentTeam) => {
  const columns = [
    {
      title: getTitle(currentTeam, viewBy),
      dataIndex: "component",
      key: "component",
      width: "16.67%",
      sorter: (a, b) => a.component.localeCompare(b.component),
      defaultSortOrder: 'ascend'
    },
  ];
  if(currentWeekDates?.dates){
    currentWeekDates.dates?.map((date) => {
      const today = moment().format("YYYY-MM-DD");
      const isToday = date === today;
  
      columns.push({
        title: moment(date).format("DD MMM, ddd"),
        dataIndex: date,
        key: date,
        date: date,
        width: "16.67%",
        align: "center",
        className: isToday ? "currentDate dayHeader" : "",
      });
      return null
    });
  }
  return columns;
};

export const generateDataSourceForDayView = (
  columns,
  uniqueComponent,
  generatedComponentData
) => {
  const dataSource = uniqueComponent.map((component, index) => {
    const dataSourceItem = {
      key: index + 1,
      component: component,
    };
    columns.forEach((column) => {
      if (column.dataIndex !== "component") {
        const componentData = generatedComponentData[component];
        dataSourceItem[column.dataIndex] = (
          <DayDataComponent data={componentData} date={column.date} />
        );
      }
    });

    return dataSourceItem;
  });
  return dataSource;
};

export const generateDataSourceForWeeklyView = (
  uniqueData,
  columns,
  generatedComponentData
) => {
  const dataSource = uniqueData.map((component, index) => {
    const dataSourceItem = {
      key: index + 1,
      component: component,
    };
    columns.forEach((column) => {
      // Check if the column dataIndex is not "component" before adding it to the dataSourceItem
      if (column.dataIndex !== "component") {
        const componentData = generatedComponentData[component];
        const filteredDataByEta = filterDataByDates(
          componentData,
          column.dates,
          "dt_eta_due_latest"
        );
        dataSourceItem[column.dataIndex] = (
          <WeeklyDataComponent filteredDataByEta={filteredDataByEta} />
        );
      }
    });
    return dataSourceItem;
  });
  return dataSource;
};

export const filterDataByDates = (data, datesArray, dateField) => {
  return data.filter((item) => {
    const fieldValue = item[dateField];
    return datesArray.includes(fieldValue);
  });
};

export const findEarliestDate = (dataArray, startDateKey) => {
  const allSprintStartDate = dataArray.map((item) => item[startDateKey]);
  // commented for future checkup
  // const uniqueDate = [...new Set(allSprintStartDate)]
  const dates = allSprintStartDate.map((dateString) => new Date(dateString));
  const earliestDate = new Date(Math.min(...dates));
  const earliestDateFormatted = moment(earliestDate).format("YYYY-MM-DD");
  return earliestDateFormatted;
};

export const findLatestDate = (dataArray, endDateKey) => {
  const allSprintStartDate = dataArray.map((item) => item[endDateKey]);
  // commented for future checkup
  // const uniqueDate = [...new Set(allSprintStartDate)]
  // Convert date strings to Date objects
  const dates = allSprintStartDate.map((dateString) => new Date(dateString));
  const latestDate = new Date(Math.max(...dates));
  const latestDateFormatted = moment(latestDate).format("YYYY-MM-DD");
  return latestDateFormatted;
};

export const extractAndDispatchDates = (
  dataArray,
  startDateKey,
  endDateKey,
  startAction,
  endAction,
  dispatch
) => {
  // const startSprintDate = dataArray.find((item) => item[startDateKey]);
  // const endSprintDate = dataArray.find((item) => item[endDateKey]);
  const startSprintEarliestDate = findEarliestDate(dataArray, startDateKey);
  const endSprintLatestDate = findLatestDate(dataArray, endDateKey);
  if (startSprintEarliestDate && endSprintLatestDate) {
    dispatch(startAction(startSprintEarliestDate));
    dispatch(endAction(endSprintLatestDate));
  }
};

export const filterWeekdays = (datesArray) => {
  return datesArray.filter((date) => {
    const dayOfWeek = moment(date).format("dddd"); // Get the day of the week
    return dayOfWeek !== "Saturday" && dayOfWeek !== "Sunday"; // Filter out Saturdays and Sundays
  });
};

export const filterDataByCheckboxes = (
  filteredEta,
  isCompletedSelected,
  isOpenSelected
) => {
  if (!filteredEta) {
    return [];
  }
  return filteredEta.filter((eta) => {
    if (isCompletedSelected && isOpenSelected) {
      // Show all data when both checkboxes are selected
      return true;
    } else if (isCompletedSelected) {
      // Show data with eta_status === "Completed" when isCompletedSelected is true
      return eta.standard_jira_status === "7.Closed";
    } else if (isOpenSelected) {
      // Show data with ["ETA Missed", "Current", "Upcoming", "Assigned"] when isOpenSelected is true
      return eta.standard_jira_status !== "7.Closed";
    }
    return false;
  });
};

function calculateTotal(data, criteria) {
  let total = 0;
  data.forEach((item) => {
    total += item[criteria] === null ? 0 : item[criteria];
  });
  return total;
}

export const generateTrendsData = (items, trends) => {
  const menuItems = items.map((item, index) => {
    const filteredSprint = trends.filter((trend) => trend.sprint === item);
    return {
      key: `${index + 1}`,
      sprint: item,
      "Total Tickets": calculateTotal(filteredSprint, "total_tickets"),
      "Story Points": calculateTotal(filteredSprint, "total_story_points"),
      "New Tickets": calculateTotal(filteredSprint, "total_new_tickets"),
      "Carry Over": calculateTotal(filteredSprint, "total_carryover_tickets"),
      "Storypoint Missing": calculateTotal(filteredSprint, "total_sp_missing_tickets"),
      "Storypoint Assigned": calculateTotal(filteredSprint, "total_sp_assigned_tickets"),
      "ETA Not Assigned": calculateTotal(filteredSprint, "total_eta_notassigned_tickets"),
      "ETA Assigned": calculateTotal(filteredSprint, "total_eta_assigned_tickets"),
      "Closed Tickets": calculateTotal(filteredSprint, "total_closed_tickets"),
      "Inprogress Tickets": calculateTotal(filteredSprint, "total_inprogress_tickets"),
      "First Time Eta Met Percentage": Math.floor(calculateTotal(filteredSprint,"total_first_time_eta_met") / calculateTotal(filteredSprint, "total_closed_tickets") * 100) || 0,
    };
  });

  return menuItems.reverse();
};

export const generateCarryOverData = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  const sprints = [
    "2 Sprints",
    "3 Sprints",
    ">4 Sprints",
  ];
  const menuItems = sprints.map((item, index) => {
    let filteredData;
    if (item === ">4 Sprints") {
      filteredData = data.filter((carryover) => carryover.sprints_spanning === '4 Sprints' || carryover.sprints_spanning === '5 Sprints');
    } else {
      filteredData = data.filter((carryover) => carryover.sprints_spanning === item);
    }

    return {
      name: item,
      value: calculateTotal(filteredData, "jira_count"),
    };
  });

  return menuItems;
};

export const generateSelectMenu = (items, key) => {
  const menuItems = items.map((item, index) => ({
    label: item,
    value: `${item}`,
  }));
  if (key !== "sprint") {
    menuItems.unshift({
      label: "All",
      value: `All`,
    });
  }
  if (key !== "sprint") {
    // Sort the menu items alphabetically while keeping "All" on top
    return menuItems.sort((a, b) => {
      if (a.label === "All") return -1;
      if (b.label === "All") return 1;
      return a.label.localeCompare(b.label);
    });
  } else {
    // Return menu items without sorting
    return menuItems;
  }
};

export const headerItems = [
  {
    label: "Days",
    key: "1",
  },
  {
    label: "Weekly",
    key: "2",
  },
];

export const headerItemsViewBy = [
  {
    label: "Component",
    key: "1",
  },
  {
    label: "Bussiness Name",
    key: "2",
  },
  {
    label: "Assignee Name",
    key: "3",
  },
];

export const linePalette = {
  "Total Tickets": "blue",
  "Story Points": "vistaBlue",
  "New Tickets": "sanMarino",
  "Carry Over": "froly",
  "Storypoint Assigned": "allports",
  "Storypoint Missing": "mediumSlateBlue",
  "ETA Not Assigned": "buff",
  "All": "sandyBrown",
};

export const trendMenuItem = [
  {
    label: "All",
    value: "All",
    emoji: <Badge color={ColorManager.getColor(linePalette["All"])} />,
  },
  {
    label: "Total Tickets",
    value: "Total Tickets",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["Total Tickets"])} />
    ),
  },
  {
    label: "Story Points",
    value: "Story Points",
    emoji: <Badge color={ColorManager.getColor(linePalette["Story Points"])} />,
  },
  {
    label: "New Tickets",
    value: "New Tickets",
    emoji: <Badge color={ColorManager.getColor(linePalette["New Tickets"])} />,
  },
  {
    label: "Carry Over",
    value: "Carry Over",
    emoji: <Badge color={ColorManager.getColor(linePalette["Carry Over"])} />,
  },
  {
    label: "Storypoint Assigned",
    value: "Storypoint Assigned",
    emoji: (
      <Badge
        color={ColorManager.getColor(linePalette["Storypoint Assigned"])}
      />
    ),
  },
  {
    label: "Storypoint Missing",
    value: "Storypoint Missing",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["Storypoint Missing"])} />
    ),
  },
  {
    label: "ETA Not Assigned",
    value: "ETA Not Assigned",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["ETA Not Assigned"])} />
    ),
  },
];

export const getLine = (label) => {
  if (label === "Total Jira") {
    return "Total Tickets";
  } else if (label === "Missing Story Points") {
    return "Storypoint Missing";
  } else if (label === "Closed") {
    return "Closed Tickets";
  } else if (label === "New") {
    return "New Tickets";
  } else if (label === "Story Points Assigned") {
    return "Storypoint Assigned";
  } else if (label === "In Progress") {
    return "Inprogress Tickets";
  } else if (label === "ETA Accuracy") {
    return "First Time Eta Met Percentage";
  } else {
    return label;
  }
};

export const transformProductivity = (input) => {
  const output = [];
  for (const key in input) {
    const name = key;
    const value = Math.round(input[key]);
    output.push({ name, value });
  }
  return output;
}

// New performance api code here 

export const transformTeams = (teams) => {
  const uxTeam = {
    project_id: 100000,
    project_key: "UX",
    project_key_desc: "UX Team",
    sprint: [],
  };

  if (teams && teams.length > 0) {
    // Append UX team to the list of teams
    const allTeams = [...teams, uxTeam];
    return allTeams
      .map((team, index) => ({
        key: `${index + 1}`,
        label: team?.project_key_desc,
        ...team,
      }))
      .sort((a, b) => a?.project_key_desc.localeCompare(b?.project_key_desc));
  } else {
    return [
      {
        key: 1,
        label: "No Team Assigned",
        project_key_desc: "No Team Assigned",
        project_id: null,
      },
    ];
  }
};


export const filterDataByDropdown = (data, selectedSprint, selectedAssignee, selectedComponent, selectedIssueType, selectedLabels) => {
  return filterByDropdownSelection(
    data,
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels
  );
};



