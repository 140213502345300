// FiltersContext.js
import React, { createContext, useContext, useState } from 'react';

const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    projects: ['All'],
    ux_owners: ['All'],
    fixversions: ['All'],
    ux_pod: ['All'],
  });
  const updateFilters = (filterKey, selectedRows) => {
    if(filterKey === 'initial'){
        setSelectedFilters(selectedRows || {})
        return null
    }else{
        setSelectedFilters((prev) => ({
          ...prev,
          [filterKey]: selectedRows,
        }));
    }
  };

  return (
    <FiltersContext.Provider value={{ selectedFilters, updateFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);
