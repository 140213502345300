import { Select } from "@nutanix-ui/prism-reactjs";
import React from "react";

const SelectDropdown = ({ inputProps, selectProps, selectedRow, type = "borderless", className }) => {
  return (
    <Select
      inputProps={inputProps}
      type={type}
      {...selectProps}
      selectedRow={selectedRow}
      className={className}
    />
  );
};

export default SelectDropdown;
