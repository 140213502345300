import React from "react";

export default class NoReportsFoundCard extends React.Component {
    render() {
        return (
            <div
                style={{
                    width: "100%",
                    backgroundColor: "rgb(239 241 241)",
                    padding: "8px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
					No Reports Found
				</div>
            </div>
        );
    }
}
