import React from "react";
import JiraTooltipView from "../MonthlyView/JiraTooltipView";
import { Tooltip } from "antd";
import { statusClassMap } from "../Helper/Helper";

const WeeklyDataComponent = ({ filteredDataByEta }) => {
  return (
    <div className="weekly-card" data-testid="weekly-card">
      <div className="weekly-card-body">
        {filteredDataByEta.length === 0
          ? null
          : filteredDataByEta.map((item, index) => {
            const statusClass = item ? statusClassMap[item.eta_status] : "no-eta";
            return(
              <Tooltip
                placement="right"
                trigger="click"
                title={<JiraTooltipView item={item} />}
                color={"white"}
                overlayClassName="tooltip-container"
                key={index}
              >
                <span key={index} className={`jira-container ${statusClass}`}></span>
              </Tooltip>
            )})}
      </div>
      {filteredDataByEta.length === 0 && (
        <span className="no-tickets" data-testid="no-tickets"></span>
      )}
    </div>
  );
};

export default WeeklyDataComponent;
