import {
  Line,
  LineChart,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "@nutanix-ui/recharts";
import CustomTooltip from "./CustomTooltip";

const TrendLineChart = ({ line, trendata, label, carryOver, height, width, margin }) => {
  return (
    <ResponsiveContainer width={width} height={height}
    >
      <LineChart
        margin={margin}
        data={carryOver && label ==='Carry Over' ? carryOver : trendata}
      >
        <XAxis dataKey={carryOver && label ==='Carry Over' ? "name" :"sprint"} interval="preserveStartEnd" />
        <Line
          key={label ==='Carry Over' ? "value" : line}
          activeDot={true}
          // dot={true}
          type="linear"
          dataKey={carryOver && label ==='Carry Over' ? "value" : line}
          stroke={carryOver && label ==='Carry Over' ? 'red' : '#82ccfa'}
        />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TrendLineChart;
