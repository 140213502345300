import React from "react";
import RadioButton from "./RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { toggleDefaultViewVelocity } from "../../../../features/JiraAnalysis/enggDashboardSlice";

const VelocityRadioButton = () => {
  const { defaultViewVelocity } = useSelector((state) => state.enggDashboard);
  const dispatch = useDispatch();
  return (
    <>
      <RadioButton
        name="default_story"
        title="Default"
        checked={defaultViewVelocity.default_story}
        onChange={(e) => dispatch(toggleDefaultViewVelocity(e))}
      />
      <RadioButton
        name="view_story_points"
        title="Hide Story Points"
        checked={defaultViewVelocity.view_story_points}
        onChange={(e) => dispatch(toggleDefaultViewVelocity(e))}
      />
    </>
  );
};

export default VelocityRadioButton;
