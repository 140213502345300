import { Modal } from "antd";
import React, { Suspense, lazy } from "react";
const ViewDetailsTable = lazy(() => import("../AutoFix/AutoFixTable"));

const ViewAutoFixModal = ({modalTitle, open, onCancel, columns, dataSource}) => {
  return (
    <Modal
      title={<div style={{ fontSize: "14px" }}>{modalTitle}</div>}
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      width="70%"
    //   height={500}
      bodyStyle={{ minHeight:'200px',maxHeight:'500px', padding: "20px",overflow: 'scroll' }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ViewDetailsTable columns={columns} dataSource={dataSource} />
      </Suspense>
    </Modal>
  );
};

export default ViewAutoFixModal;
