import { Button, FlexLayout, Modal, Notification, NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import React, { useState } from "react";
import DagForm from "./DagForm";
import { createAirflowDag } from "../../../../Service/fileUploadUtility";

export default function CreateAirflowDagModal({ visible, closeModal }) {

    const [currentTab, setCurrentTab] = useState(0);

    const [dagFormData, setDagFormData] = useState([
        {
            dagName: '',
            functionName: '',
            functionList: [],
            tableauReports: []
        }
    ]);
    const [dagUserData, setDagUserData] = useState([]);
    const handleStepNextBtn = () => {
        setCurrentTab(prevState => 1);
    }

    const handleStepBackBtn = () => {
        setCurrentTab(prevState => 0);
    }

    const handleSubmitBtn = () => {
        try {
            setCurrentTab(prevState => 0);
            closeModal();
            createAirflowDag(dagFormData, dagUserData)
                .then(resp => {
                    setDagFormData(prevState => [
                        {
                            dagName: '',
                            functionName: '',
                            functionList: [],
                            tableauReports: []
                        }
                    ]);
                    setDagUserData(prevState => []);
                    if (resp.error) {
                        NotificationTrigger.add({
                            id: 'template-upload-failed-file-error',
                            message: `Creation of airflow dag has failed to upload due to ${resp.error}`,
                            type: 'error',
                            autoDismissDelaySecs: 30,
                        });
                    } else {
                        NotificationTrigger.add({
                            id: 'template-upload-success',
                            className: 'successAlert',
                            message: 'Creation of airflow dag is successfully',
                            type: 'success',
                            autoDismissDelaySecs: 30,
                        });
                    }
                })
        } catch (err) {
            NotificationTrigger.add({
                id: 'template-upload-failed-file-error',
                message: `Creation of airflow dag has failed to upload`,
                type: 'error',
                autoDismissDelaySecs: 30,
            });
            setCurrentTab(prevState => 0);
            closeModal();
        }
    }

    const handleCloseModal = () => {
        setCurrentTab(prevState => 0);
        closeModal();
    }

    const renderNavButtons = () => {
        if (currentTab === 0) {
            return <FlexLayout justifyContent="flex-end">
                <Button onClick={closeModal}>Cancel</Button>
                <Button onClick={handleStepNextBtn}>Next</Button>
            </FlexLayout>
        }
        return <FlexLayout justifyContent="flex-end">
            <Button onClick={() => {
                setCurrentTab(prevState => 0);
                closeModal();
            }}>Cancel</Button>
            <Button onClick={handleStepBackBtn}>Back</Button>
            <Button onClick={handleSubmitBtn}>Submit</Button>
        </FlexLayout>
    }

    return <>
        <Modal
            visible={visible}
            width={'60%'}
            title={<h3 style={{ textAlign: 'start' }}>Create Dag</h3>}
            onClose={handleCloseModal}
            primaryButtonOnClick={handleCloseModal}
            footer={renderNavButtons()}
        >
            <DagForm currentTab={currentTab} dagFormData={dagFormData} setDagFormData={setDagFormData} dagUserData={dagUserData} setDagUserData={setDagUserData} />
        </Modal>
        <Notification className="alert-modal" data-testid="notifications">
            <div
                style={{
                    position: 'fixed',
                    top: '80px',
                    right: '340px'
                }}

            />
        </Notification>
    </>
}