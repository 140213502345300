import React from "react";
import {
  Table,
  Popover,
} from "antd";
import axios from "axios";
export function DataSyncChartPopover ({ dataSyncData,children }) {
console.log(dataSyncData)

  const popoverDataSyncColumns = [
    {
      title: "Schema Name",
      dataIndex: "schema_name",
      key: "schema_name",
      render: (value)=>{
          return(
              <div style={{textTransform:"uppercase"}}>
                  {value}
              </div>
          )
      }
    },
    {
      title: "Table Count",
      dataIndex: "table_count",
      key: "table_count",
    },
    {
      title: "Data Quality",
      dataIndex: "data_quality",
      key: "data_quality",
    }
   ];


   return (
    <div>
      <Popover
        placement="rightTop"
        trigger={"click"}
        content={<PopoverContent gridData={dataSyncData} dataColumns={popoverDataSyncColumns}/>}
       
      >
        {children}
      </Popover>
    </div>
  );


  
}
function PopoverContent({gridData, dataColumns}) {
  console.log({gridData})
  return (
    <div >
     <Table
        columns={dataColumns}
        dataSource={gridData}
        pagination={false}
        style={{height:"200px",overflow:'auto'}}
     />
    </div>
  );
}

export default DataSyncChartPopover;
