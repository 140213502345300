import { useState, useEffect } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import FavouriteReportCard from "./FavouriteReportCard";
import NoReportsFoundCard from "./NoReportsFoundCard";

const TableauFavouriteReports = (props) => {
    const { allReports, handleFavChange, onReportClick } = { ...props };
    const sortDirections = ["CLEAR", "DESC", "ASC"];
    const [reports, setReports] = useState(allReports || []);
    const [sortDirection, setSortDirection] = useState({
        reportName: 0,
        projectName: 0,
    });

    const handleSortDirectionUpdate = (sortKey) => {
        const currentSort = sortDirection[sortKey];
        const sortDirectionLocal = {
            reportName: 0,
            projectName: 0,
            [sortKey]: 0,
        };
        if (currentSort === sortDirections.length - 1) {
            setSortDirection((current) => ({
                reportName: 0,
                projectName: 0,
                [sortKey]: 0,
            }));
        } else {
            sortDirectionLocal[sortKey] = sortDirection[sortKey] + 1;
            setSortDirection(sortDirectionLocal);
        }
        console.log(sortDirectionLocal);
        return sortDirectionLocal;
    };

    const handleSort = (e) => {
        const sortKey = e.currentTarget.name;
        const allActiveSortDirections = handleSortDirectionUpdate(sortKey);
        const activeSortDirection =
            sortDirections[allActiveSortDirections[sortKey]];
        if (!sortKey) return;
        console.log({ sortKey, activeSortDirection, sortDirection });
        if (activeSortDirection === "CLEAR") {
            setReports(allReports);
            return;
        }
        const sortedData = [...reports].sort((a, b) => {
            if (activeSortDirection === "DESC") {
                return a[sortKey].toLowerCase() < b[sortKey].toLowerCase()
                    ? -1
                    : 1;
            }
            if (activeSortDirection === "ASC") {
                return a[sortKey].toLowerCase() < b[sortKey].toLowerCase()
                    ? 1
                    : -1;
            }
            return 1;
        });
        setReports(sortedData);
    };

    useEffect(() => {
        if (reports.length !== allReports.length) setReports(allReports);
    }, [allReports]);

    return (
        <div
            className={"TableauTable-Wrapper"}
            style={{ width: "100%", padding: "8px" }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    paddingRight: "54px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 8,
                    }}
                >
                    <button
                        className={"table-header-sortable"}
                        name={"projectName"}
                        onClick={handleSort}
                        style={{ margin: 0, flexBasis: "25%", color: "grey" }}
                    >
                        Project Name
                        <span
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <CaretUpOutlined
                                style={{
                                    marginBottom: -3,
                                    color:
                                        sortDirection.projectName === 1
                                            ? "blue"
                                            : "grey",
                                    fontSize: "12px",
                                }}
                            />
                            <CaretDownOutlined
                                style={{
                                    marginBottom: -3,
                                    color:
                                        sortDirection.projectName === 2
                                            ? "blue"
                                            : "grey",
                                    fontSize: "12px",
                                }}
                            />
                        </span>
                    </button>
                    <button
                        className={"table-header-sortable"}
                        name={"reportName"}
                        onClick={handleSort}
                        style={{ margin: 0, flexBasis: "45%", color: "grey" }}
                    >
                        Report Name
                        <span
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <CaretUpOutlined
                                style={{
                                    marginBottom: -3,
                                    color:
                                        sortDirection.reportName === 1
                                            ? "blue"
                                            : "grey",
                                    fontSize: "12px",
                                }}
                            />
                            <CaretDownOutlined
                                style={{
                                    marginBottom: -3,
                                    color:
                                        sortDirection.reportName === 2
                                            ? "blue"
                                            : "grey",
                                    fontSize: "12px",
                                }}
                            />
                        </span>
                    </button>
                    <button
                        className={"table-header-sortable"}
                        style={{ margin: 0, flexBasis: "17%", color: "grey" }}
                    >
                        Last Extract
                    </button>
                    <button
                        className={"table-header-sortable"}
                        style={{ margin: 0, flexBasis: "13%", color: "grey" }}
                    >
                        Add to Favourites
                    </button>
                </div>
                {reports.length !== 0 ? (
                    reports.map((e, index) => (
                        <FavouriteReportCard
                            key={index}
                            {...e}
                            isFav={e.fav}
                            reportName={e.reportName}
                            onFavChange={handleFavChange}
                            dataQualityPercentage={e.data_quality}
                            onReportClick={onReportClick}
                        />
                    ))
                ) : (
                    <NoReportsFoundCard />
                )}
            </div>
        </div>
    );
};

export default TableauFavouriteReports;
