import {
  ContainerLayout,
  HeaderFooterLayout,
} from "@nutanix-ui/prism-reactjs";
import React from "react";
import Header from "./Header";

const Widget = ({ title, id, width, body, footer }) => {
  return (
      <ContainerLayout id={id} backgroundColor="white" style={{ width: width }} data-testid={id}>
        <HeaderFooterLayout
          header={<Header title={title} id={id} />}
          footer={footer}
          bodyContent={body}
        />
      </ContainerLayout>
  );
};

export default Widget;
