import React from "react";
import PageHeader from "../../Containers/PageHeader";
import SideBar from "../SideBar/SideBar";
import { Layout,  Row, Col,} from "antd";
import TilesDashboard from "./dashboardTiles";

const { Content } = Layout;
export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      // <div className="App data-audit-wrapper">
      //   <PageHeader />
      //   <Row>
      //     <Col span={3} >
      //       <SideBar />
      //     </Col>
      //     <Col span={24}>
      //           <React.Fragment>
      <Layout>
                <PageHeader />
                <Layout >
                    <SideBar />
                    <Content>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: 15,
                      marginLeft: "35px",
                      justifyContent: "space-between",
                      paddingBottom: 15,
                      alignItems: "center",
                      paddingRight: 50,
                    }}
                  >
                    <h2 style={{fontSize:"18px"}}>Dashboard</h2>
                  </div>
                  <div className="ant-layout-content-dashboard">
                      <TilesDashboard />
                  </div>
                  </Content>
                  </Layout>
                  </Layout>
                  
      //           </React.Fragment>
      //     </Col>
      //   </Row>
      // </div>
    );
  }
}
export default Dashboard;
