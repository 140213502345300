import { Accordion, Alert, FlexItem, FlexLayout, FormItemInput, StackingLayout, Steps, Select, Button, Input, RemoveIcon, Table, MinusCircleIcon, PlusIcon, PlusCircleIcon } from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useState } from "react";

import './styles.css';

const stepsData = [
    {
        title: 'Add Airflow Dags'
    },
    {
        title: 'User Details'
    },
]

const userAccessOption = [
    {
        key: 1,
        label: 'Success',
        value: 'success'
    },
    {
        key: 2,
        label: 'Failure',
        value: 'failure'
    }
];

const executionModeOptions = [
    {
        key: 1,
        label: 'Parallel',
        value: 'parallel'
    },
    {
        key: 2,
        label: 'Sequential',
        value: 'sequential'
    }
];

export default function DagForm({ currentTab, dagFormData, setDagFormData, dagUserData, setDagUserData }) {

    const renderForm = () => {
        if (currentTab === 0) {
            return <div className="dagFunctionBody"><DagFunction dagFormData={dagFormData} setDagFormData={setDagFormData} /></div>
        }
        return <DagUser dagUserData={dagUserData} setDagUserData={setDagUserData} />
    }
    return <StackingLayout style={{ width: '100%' }}>
        <Steps
            data={stepsData}
            current={currentTab}
        />
        <FlexLayout
            id={`createDagFormStep-${currentTab + 1}`}
            flexDirection="column"
            alignItems="center"
            color='red'
            role="tabpanel-2"
            aria-labelledby={currentTab}
            tabIndex={0}
        >
            <FlexItem style={{ width: '72%' }}>
                {renderForm()}
            </FlexItem>
        </FlexLayout>
    </StackingLayout>
}

function DagFunction({ dagFormData, setDagFormData }) {

    const handleAddMoreDagBtn = () => {
        setDagFormData(prevState => [...prevState, {
            dagName: '',
            functionName: '',
            functionList: [],
            tableauReports: []
        }]);
    }
    return <div className="dagFunctionContainer">
        {dagFormData.map((ob, i) => <DagAccordian idx={i} dagFormData={dagFormData} setDagFormData={setDagFormData} />)}
        <div className="addMoreDagBtn">
            <Button onClick={handleAddMoreDagBtn}>Add more Dag</Button>
        </div>
    </div>
}

function DagFunctionSchedular({ data, setData }) {

    useEffect(() => {

    }, [data]);

    const handleRemove = (key) => {
        const newArray = [];
        data.map(ob => {
            if (ob.key !== key) {
                newArray.push(ob);
            }
        });
        setData(newArray);
    }

    const handleFunctionNameChange = (e, key) => {
        const newArray = [];
        data?.map(ob => {
            if (ob.key !== key) {
                newArray.push(ob);
            } else {
                ob.functionName = e.target.value;
                newArray.push(ob);
            }
        });
        setData(newArray);
    }

    const handleExecutionModeChange = (selectedRow, key) => {
        const newArray = [];
        data?.map(ob => {
            if (ob.key !== key) {
                newArray.push(ob);
            } else {
                ob.executionMode = selectedRow
                newArray.push(ob);
            }
        });
        setData(newArray);
    }
    return data?.map(el => <div className="dagFunctionForm">
        <div className="dagFunctionNameTextBox">
            <FormItemInput id="dagNameTextBox"
                label="Function Name"
                width='100%'
                onChange={(e) => handleFunctionNameChange(e, el.key)}
                value={el.functionName}
                placeholder="e.g. VM Apache"
                defaultValue="VM Apache"
            />
        </div>
        <div className="dagFunctionScheduleBox">
            <label style={{ fontSize: '12px', color: '#627386' }}>Execution Mode</label>
            <Select
                rowsData={executionModeOptions}
                autoSize={true}
                onSelectedChange={(selectedRow) => handleExecutionModeChange(selectedRow, el.key)}
                selectedRow={el.executionMode}
            />
        </div>
        <div className="removeDagFunctionIcon">
            <MinusCircleIcon color='#9AA5B5' size="large" onClick={() => handleRemove(el.key)} />
        </div>
    </div>)
}

function RenderTableauReportsList({ data, setData }) {

    const handleRemove = (key) => {
        const newArray = [];
        data.map(ob => {
            if (ob.key !== key) {
                newArray.push(ob);
            }
        });
        setData(newArray);
    }

    return data.map(e => <div className="dagFunctionForm">
        <div className="dagFunctionNameTextBox">
            <FormItemInput id="dagNameTextBox"
                width='100%'
                disabled
                value={e.reportName}
            />
        </div>
        <div className=" height: 0rem;">
            <MinusCircleIcon color='#9AA5B5' size="large" onClick={() => handleRemove(e.key)} />
        </div>
    </div>)
}

function DagAccordian({ idx, dagFormData, setDagFormData }) {
    const title = `DAG ${idx + 1}`
    const [data, setData] = useState([]);
    const [airFlowDagName, setAirFlowDagName] = useState('');
    const [mainFunctionName, setMainFunctionNameChange] = useState('');
    const [defaultView, setDefaultView] = useState(true);
    const [tableauDefaultView, setTableauDefaultView] = useState(true);
    const [functionName, setFunctionName] = useState('');
    const [executionMode, setExecutionMode] = useState({
        key: 1,
        label: 'Parallel',
        value: 'parallel'
    });
    const [tableauReportName, setTableauReportName] = useState('')

    const handleFunctionNameChange = (e) => {
        setFunctionName(prevState => e.target.value)
    }

    const handleAirFlowDagNameChange = (e) => {
        setAirFlowDagName(prevState => e.target.value);
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.dagName = e.target.value;
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    const handleTableauReportName = (e) => {
        setTableauReportName(prevState => e.target.value)
    }

    const handleMainFunctionNameChange = (e) => {
        setMainFunctionNameChange(prevState => e.target.value)
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.functionName = e.target.value;
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    const handleExecutionModeChange = (selectedRow) => {
        setExecutionMode(prevState => selectedRow);
    }

    const handleAddTableauReportsBtn = () => {
        setTableauDefaultView(prevState => false);
    }

    const handleAddFunctionBtn = () => {
        setDefaultView(prevState => false);
    }

    const handleRemoveFunctionBtn = (newArray) => {
        setData(prevState => newArray);
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.functionList = newArray;
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    const handleTableauRemoveFunctionBtn = (newArray) => {
        setData(prevState => newArray);
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.tableauReports = newArray;
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    const handleAddMoreFunctionBtn = () => {
        const key = Math.random().toString(36).slice(2, 7);
        setData(prevState => [...prevState, {
            key: key,
            functionName: functionName,
            executionMode: executionMode,
        }]);
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.functionList.push({
            key: key,
            functionName: functionName,
            executionMode: executionMode,
        });
        setExecutionMode(prevState => {
            return {
                key: 1,
                label: 'Parallel',
                value: 'parallel'
            }
        })
        setFunctionName(prevState => '');
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    const handleAddMoreTableauReportsBtn = () => {
        const key = Math.random().toString(36).slice(2, 7);
        const tempDagFormData = dagFormData;
        const selectedDag = tempDagFormData[idx];
        selectedDag.tableauReports.push({
            key: key,
            reportName: tableauReportName
        });
        setTableauReportName(prevState => '');
        tempDagFormData[idx] = selectedDag;
        setDagFormData(prevState => tempDagFormData);
    }

    return <div className="dagFunctionContent">
        <Accordion
            className="dagAccordian"
            headerProps={{
                padding: '4rem',
                justifyContent: 'space-between',
                width: '100%'
            }} title={title} expandedByDefault={true}
        >
            <div className="dagAccordianContent">
                <FormItemInput id="dagNameTextBox"
                    className="dagNameTextBox"
                    label="Airflow DAG Name"
                    onChange={handleAirFlowDagNameChange} value={airFlowDagName}
                    width='100%'
                    placeholder="Please Add Airflow Name"
                />

                <FormItemInput id="dagNameTextBox"
                    className="dagNameTextBox"
                    onChange={handleMainFunctionNameChange} value={mainFunctionName}
                    label="Function Name"
                    width='100%'
                    placeholder="Please Add Function Name"
                />

                {defaultView && <div className="addMoreFunctionBtn">
                    <a onClick={handleAddFunctionBtn} ><PlusIcon /> Add</a>
                </div>}
                <DagFunctionSchedular data={dagFormData[idx]?.functionList} setData={handleRemoveFunctionBtn} />
                {!defaultView && <div className="dagFunctionForm">
                    <div className="dagFunctionNameTextBox">
                        <FormItemInput id="dagNameTextBox"
                            label="Function Name"
                            onChange={handleFunctionNameChange} value={functionName}
                            width='100%'
                            placeholder="Please Add Function Name"
                        />
                    </div>
                    <div className="dagFunctionScheduleBox">
                        <label style={{ fontSize: '12px', color: '#627386' }}>Execution Mode</label>
                        <Select
                            rowsData={executionModeOptions}
                            autoSize={true}
                            onSelectedChange={handleExecutionModeChange}
                            selectedRow={executionMode}
                        />
                    </div>
                </div>}
                {!defaultView && <div className="addMoreFunctionBtn">
                    <a onClick={handleAddMoreFunctionBtn} ><PlusIcon /> Add</a>
                </div>}
                {<div className="dagTableauReportsBtnContainer">
                    <h3>Tableau Reports</h3>
                    {tableauDefaultView && <div>
                        <Button style={{ padding: '1rem', background: 'white' }} onClick={handleAddTableauReportsBtn}>
                            <a><PlusIcon /> <label>Add</label></a>
                        </Button>
                    </div>}
                </div>}
                <RenderTableauReportsList data={dagFormData[idx]?.tableauReports} setData={handleTableauRemoveFunctionBtn} />
                {!tableauDefaultView && <div className="dagTableauReportsContainer">
                    <FormItemInput id="dagNameTextBox"
                        onChange={handleTableauReportName} value={tableauReportName}
                        placeholder="Please Add Tableau Report"
                    />
                </div>}
                {!tableauDefaultView && <div className="addMoreFunctionBtn">
                    <a onClick={handleAddMoreTableauReportsBtn} ><PlusIcon /> Add</a>
                </div>}
            </div>
        </Accordion>
    </div>
}

function DagUser({ dagUserData, setDagUserData }) {

    const userDetailTableColumn = [{
        title: 'Notification On',
        key: 'accessTypeLabel'
    }, {
        title: 'Email Address',
        key: 'userEmailAddress',
    },
    {
        title: 'Remove',
        key: 'remove',
        render: (value, object, index) => {
            return (
                <div className="userTableAction" data-testid="removeUserButton" onClick={() => handleRemove(index)}><RemoveIcon /></div>
            );
        }
    }];

    const handleRemove = (idx) => {
        const tempUserData = [];
        dagUserData.forEach((e, i) => {
            if (i !== idx) {
                tempUserData.push(e);
            }
        })
        setDagUserData(prevState => tempUserData);
    }

    const [userAccess, setUserAccess] = useState({
        key: 1,
        label: 'Success',
        value: 'success'
    });

    const [isError, setIsError] = useState(false);

    const [emailAddress, setEmailAddress] = useState('');

    const [userData, setUserData] = useState(dagUserData);

    const handleEmailAddressChange = (e) => {
        setIsError(prevState => false);
        setEmailAddress(prevState => e.target.value);
    }

    const handleAddUser = () => {
        const regex = /@nutanix\.com$/;
        if (regex.test(emailAddress)) {
            setIsError(prevState => false);
            setDagUserData(prevState =>
                [...prevState, {
                    key: Math.random(),
                    accessTypeLabel: userAccess.label,
                    accessTypeValue: userAccess.value,
                    userEmailAddress: emailAddress,
                }]
            );
        } else {
            setIsError(prevState => true);
        }
        setEmailAddress(prevState => '');
    }

    const handleUserAccessChange = (selectedRow) => {
        setUserAccess(prevState => selectedRow);
    }

    return <div className="dagUserFormContainer">
        <Alert
            type={Alert.AlertTypes.INFO}
            data-test-id="type-info"
            aria-live="off"
            message="All information will automatically be sent to the template owner as well as the selected users. No additional steps are necessary." />
        <div className="dagUserContainerHeading">
            <h2>Add User Details</h2>
            <p>Select the users you want to notify about the operations taking place. You can add users who should receive failure alerts and who should receive success alerts.</p>
        </div>
        {isError && <Alert
            type={Alert.AlertTypes.ERROR}
            data-test-id="type-info"
            aria-live="off"
            message="Please add correct email address" />}
        <div className="addUserContainer">
            <div className="dagAddUserSelect">
                <Input
                    id="dagAddUserSelect"
                    defaultValue={emailAddress}
                    value={emailAddress}
                    onChange={handleEmailAddressChange}
                    placeholder={'Please enter user email address'}
                    addonBefore={(
                        <Select
                            rowsData={userAccessOption}
                            autoSize={true}
                            type="mini"
                            onSelectedChange={handleUserAccessChange}
                            selectedRow={userAccess}
                        />
                    )}
                />
            </div>
            <Button onClick={handleAddUser}
            >Add</Button>
        </div>
        {dagUserData?.length > 0 && <Table
            showCustomScrollbar={true}
            dataSource={dagUserData}
            className="dagUserData"
            columns={userDetailTableColumn}
            wrapperProps={{
                'data-testid': 'dagUserData'
            }}
        />}
    </div>
}