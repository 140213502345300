import React from "react";
import Widget from "../WidgetContainer/Widget";
import StagesGraph from "../Graphs/StagesGraph";
import OverTime from "../Graphs/OverTime";
import JiraComparison from "../Graphs/JiraComparison";
import CycleFilter from "../Filters/CycleFilter";
// import CycleFooter from "../WidgetContainer/CycleFooter";
import { Loader } from "@nutanix-ui/prism-reactjs";
import { useSelector } from "react-redux";

const CycleTime = ({ margin }) => {
  const { loading } = useSelector((state) => state.enggDashboard);
  return (
    <div className="tabContainer">
      <div className="filterContainer">
        <CycleFilter />
      </div>
      <div className="widgetContainer">
        <div className="widgetItems">
          <Loader
            loading={loading.stages}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Cycle Time For Stages"
              id="stages"
              body={<StagesGraph margin={margin} />}
            />
          </Loader>
        </div>
        <div className="widgetItems">
          <Loader
            loading={loading.overtime}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Cycle Time (Over time comparison)"
              id="overtime"
              body={<OverTime margin={margin} />}
              // footer={<CycleFooter id="overtime" />}
            />
          </Loader>
        </div>
      </div>
      <div className="widgetContainer">
        <div className="widgetItems full">
          <Loader
            loading={loading.jiraComparison}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Jira Ticket Comparison"
              id="jira_comparison"
              width="100%"
              body={<JiraComparison margin={margin} />}
              // footer={<CycleFooter id="jira_comparison" />}
            />
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default CycleTime;
