import React from "react";
import PageHeader from "../../Containers/PageHeader";
import SideBar from "../SideBar/SideBar";
import { Layout, Row, Col, Card, Button ,Alert} from "antd";
import aboutus from "../../images/aboutus.png";
import { CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
import {apiResponseError} from "../../config/helper";
import { SERVER_URL } from "../../config/config";

const { Content } = Layout;
export class About extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (

            <Layout >
                {/* <PageHeader /> */}
                <Layout >
                    {/* <SideBar /> */}
                    <Content>
                        <Row>
                            <Col span={24} className="about-layout">
                                {/* <div className={"page-title-wrapper"}> */}
                                <div
                                    style={{
                                        paddingTop: 15,
                                        justifyContent: "space-between",
                                        paddingBottom: 15,
                                        alignItems: "center",
                                        paddingRight: 50,
                                        background: "#ececec",
                                        paddingLeft: 32
                                    }}
                                >
                                    <div>
                                        <h1 style={{ fontSize: "1.7em" }}>Feedback</h1>
                                    </div>
                                </div>
                                
                                <FeedBack />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

// const FeedBack=()=>{
class FeedBack extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            subject:"",
            message:"",
            apiProgress: { loading: false, ready: false, error: false }
        }
    }
    sendMail = async(e) => {
        try{
            this.setState({apiProgress:{ loading: true, ready: false, error: false }})
            let mailInput ={
                subject:this.state.subject,
                message:this.state.message
            }
            const data = await axios.post(`${SERVER_URL}/sendMail`,mailInput)
            this.setState({apiProgress:{ loading: false, ready: true, error: false },subject:"",message:""})
        }catch(e){
            
            this.setState({apiProgress:{ loading: false, ready: false, error: apiResponseError(e)}})
        }
    }
    handleValueChange = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        return (
            <div className="form-container">
                {/* <h2>Feedback</h2> */}
                <form className="about-feedback">
                {this.state.apiProgress.ready &&  <Alert message="Thanks for the Feedback" type="success" closable/>}
                {this.state.apiProgress.error &&  <Alert message={this.state.apiProgress.error} type="error" closable/>}
                    <div className="about-form-content">
                        <p>Subject:</p>
                        <input className="form-control" placeholder="Subject" name="subject" value={this.state.subject} onChange={this.handleValueChange}/>
                    </div>
                    <div className="about-form-content">
                        <p>Message:</p>
                        <textarea className="form-control" placeholder="Message" name="message" value={this.state.message} onChange={this.handleValueChange}/>
                    </div>
                    <div className="about-form-content">
                        <Button type="primary" onClick={this.sendMail}>SUBMIT</Button>
                    </div>
                </form>
            </div>
        )
    }
}

export default About;
