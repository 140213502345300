import {
    NavBarLayout,
    FlexLayout,
    UserIcon,
    PowerIcon,
    ShowIcon,
    Menu,
    MenuItem,
    Dropdown,
    ChevronDownIcon,
    Loader,
} from '@nutanix-ui/prism-reactjs';

import axios from "axios";

import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { SERVER_URL, LOGIN_URL } from "../config/config";

import './styles.css';
import AdoptionMetric from '../Components/AdoptionMetric';
import { getAdoptionMetric } from '../Service/adoptionMetric';
import UserContext from '../contexts/UserContext/UserContext';
import { DGO_USERS_LIST } from '../Authorization/dgoUsersList';

const style = {
    color: '#B8BFCA',
    margin: '1rem'
}

const findTitle = (path) => {
    if (path === '/') {
        return {
            health: false,
            report: true,
            utility: false,
            paas: false
        }
    } else if (path === '/platform_health') {
        return {
            health: true,
            report: false,
            utility: false,
            paas: false
        }
    } else if (path === '/datasync') {
        return {
            health: true,
            report: false,
            utility: false,
            paas: false
        }
    } else if (path === '/dataaudit') {
        return {
            health: true,
            report: false,
            utility: false,
            paas: false
        }
    } else if (path === '/tracker') {
        return {
            health: false,
            report: true,
            utility: false,
            paas: false
        }
    } else if (path === '/fulfillment_slide') {
        return {
            health: false,
            report: true,
            utility: false,
            paas: false
        }
    } else if (path === '/PnP 2.0') {
        return {
            health: false,
            report: true,
            utility: false,
            paas: false
        }
    } else if (path === '/file_upload') {
        return {
            health: false,
            report: false,
            utility: true,
            paas: false
        }
    } else if (path === '/jira-analysis') {
        return {
            health: false,
            report: false,
            utility: true,
            paas: false
        }
    } else if (path === '/kpi_engineering') {
        return {
            health: false,
            report: false,
            utility: true,
            paas: false
        }
    } else if (path === '/factory_data') {
        return {
            health: false,
            report: false,
            utility: true,
            paas: false
        }
    } else if (path === '/NextGenETL') {
        return {
            health: false,
            report: false,
            utility: false,
            paas: true
        }
    } else if (path === '/ETL_listing') {
        return {
            health: false,
            report: false,
            utility: false,
            paas: true
        }
    } else if (path === '/leo') {
        return {
            health: false,
            report: false,
            utility: true,
            paas: false
        }
    } else {
        return {
            health: false,
            report: false,
            utility: false,
            paas: false
        }
    }
}

export default function PageHeader() {

    const [loading, setLoading] = useState(true)
    const [uniqueVisitor, setUniqueVisitor] = useState(0);
    const [title, setTitle] = useState(findTitle(window.location.pathname));
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [totalUsersData, setTotalUsers] = useState([]);
    const [isDGO, setDGO] = useState(false);

    const contextValue = useContext(UserContext);

    const { user } = contextValue;

    useEffect(() => {
        getAdoptionMetric()
            .then(resp => {
                const { totalUsersResp, timeSeriesDataResp } = resp;
                setTotalUsers(prevState => totalUsersResp);
                totalUsersResp.forEach(ob => {
                    if (ob.name === "Total") {
                        setUniqueVisitor(prevState => ob.count);
                        setLoading(prevState => false);
                    }
                })
                setTimeSeriesData(prevState => timeSeriesDataResp);
            })
            .catch(err => {

            })
    }, [])

    const navigateTo = (url) => (url ? (window.location.href = url) : null);

    const redirectToLoginPage = () => {
        const loginUrl = LOGIN_URL;
        navigateTo(loginUrl);
    };

    useEffect(() => {
        if(DGO_USERS_LIST.includes(user)) {
            setDGO(prevState => true);
        } else {
            setDGO(prevState => false);
        }
    }, [user]);

    const logoutUser = () => {
        axios
            .post(`${SERVER_URL}/logout`)
            .then((response) => {
                redirectToLoginPage();
            })
            .catch((err) => {

            });
    };

    const accountMenuOption = (
        <Menu appearance={Menu.MenuAppearance.DEFAULT}
        >
            <MenuItem key="1">
                <FlexLayout itemSpacing="15px" alignItems="center" style={{ width: '5rem' }}>
                    <label onClick={logoutUser}>Log out</label>
                    <div onClick={logoutUser}>
                        <PowerIcon />
                    </div>
                </FlexLayout>
            </MenuItem>
        </Menu>
    )

    const accountMenu = () => {
        return <Dropdown
            popup={accountMenuOption}
        >
            <FlexLayout itemSpacing="15px" alignItems="center">
                <ChevronDownIcon />
            </FlexLayout>
        </Dropdown>
    }

    const accountInfo = (
        <FlexLayout itemSpacing="20px" alignItems="center">
            <p>{localStorage.getItem("email")}</p>
            {accountMenu()}
        </FlexLayout>
    )

    const accountActions = (
        <div>
            <FlexLayout alignItems="center" itemSpacing="20px">
                <FlexLayout itemSpacing="30px" alignItems="center">
                    <FlexLayout itemSpacing="10px" alignItems="center" justifyContent='center'>
                        <UserIcon aria-label="MagGlass Icon" color="#B8BFCA" />
                        {loading && <Loader loading={loading} />}
                        {!loading && <p style={{ marginTop: '2px' }}>{uniqueVisitor}</p>}
                        <AdoptionMetric timeSeriesData={timeSeriesData} totalUsersData={totalUsersData} />
                    </FlexLayout>
                </FlexLayout>
            </FlexLayout>
        </div>
    );

    const menuItems = [
        {
            key: 'healthMenu',
            label: <HealthMenu title={title} setTitle={setTitle} />
        },
        {
            key: 'reportsMenu',
            label: <ReportsMenu setTitle={setTitle} title={title} />
        },
        {
            key: 'utilityMenu',
            label: <UtilityMenu setTitle={setTitle} title={title} />
        },
        {
            key: 'paasMenu',
            label: <PaasMenu setTitle={setTitle} title={title} />
        }
    ];

    if(isDGO) {
        return <NavBarLayout
        accountInfo={accountInfo}
        layout={NavBarLayout.NavBarLayoutTypes.LEFT}
        title="Enterprise Data Portal"
    />
    }
    
    return (
        <NavBarLayout
            accountActions={accountActions}
            accountInfo={accountInfo}
            layout={NavBarLayout.NavBarLayoutTypes.LEFT}
            menuItems={menuItems}
            title="Enterprise Data Portal"
        />)
}

function HealthMenu({ title, setTitle }) {
    const [color, setColor] = useState('#B8BFCA')

    useEffect(() => {
        if (title.health) {
            setColor('white')
        } else {
            setColor('#B8BFCA')
        }
    }, [title])

    const healthMenu = (
        <Menu
            padding="10px-0px"
            type={Menu.MenuType.SECONDARY}
            role={Menu.MenuRole.MENUBAR}
            appearance={Menu.MenuAppearance.COMPACT}
        >
            <MenuItem key="1">
                <Link style={style} to='/platform_health' onClick={e => {
                    setTitle(findTitle('/platform_health'));
                    setColor('white');
                }}>Platform Health</Link>
            </MenuItem>
            <MenuItem key="2">
                <Link style={style} to='/datasync' onClick={e => {
                    setTitle(findTitle('/datasync'));
                    setColor('white')
                }}>Data Health</Link>
            </MenuItem>
            <MenuItem key="3">
                <Link style={style} to='/dataaudit' onClick={e => {
                    setTitle(findTitle('/dataaudit'));
                    setColor('white');
                }}>Data Audit</Link>
            </MenuItem>
        </Menu>
    );

    return <Dropdown
        popup={healthMenu}
    >
        <FlexLayout itemSpacing="15px" alignItems="center">
            <h3 className="headerButton" style={{ color: color }}>Health</h3>
            <ChevronDownIcon />
        </FlexLayout>
    </Dropdown>
}

function ReportsMenu({ title, setTitle }) {
    const [color, setColor] = useState('#B8BFCA')

    useEffect(() => {
        if (title.report) {
            setColor('white')
        } else {
            setColor('#B8BFCA')
        }
    }, [title])
    const reportMenu = (
        <Menu
            padding="10px-0px"
            type={Menu.MenuType.SECONDARY}
            role={Menu.MenuRole.MENUBAR}
            appearance={Menu.MenuAppearance.COMPACT}
        >
            <MenuItem key="1">
                <Link style={style} to='/' onClick={e => setTitle(findTitle('/'))}>Tableau Reports</Link>
            </MenuItem>
            <MenuItem key="2">
                <Link style={style} to='/tracker' onClick={e => setTitle(findTitle('/tracker'))}>Pro Tracker</Link>
            </MenuItem>
            <MenuItem key="3">
                <Link style={style} to='/fulfillment_slide' onClick={e => setTitle(findTitle('/fulfillment_slide'))}>Fulfillment Slide</Link>
            </MenuItem>
            <MenuItem key="4">
                <Link style={style} to='/PnP 2.0' onClick={e => setTitle(findTitle('/PnP 2.0'))}>PnP 2.0</Link>
            </MenuItem>
        </Menu>
    );

    return <Dropdown
        popup={reportMenu}
    >
        <FlexLayout itemSpacing="15px" alignItems="center">
            <h3 className="headerButton" style={{ color: color }}>Reports</h3>
            <ChevronDownIcon />
        </FlexLayout>
    </Dropdown>
}

function UtilityMenu({ title, setTitle, }) {
    const [color, setColor] = useState('#B8BFCA')

    useEffect(() => {
        if (title.utility) {
            setColor('white')
        } else {
            setColor('#B8BFCA')
        }
    }, [title])
    const utilityMenu = (
        <Menu
            padding="10px-0px"
            type={Menu.MenuType.SECONDARY}
            role={Menu.MenuRole.MENUBAR}
            appearance={Menu.MenuAppearance.COMPACT}
        >
            <MenuItem key="1">
                <Link style={style} to='/jira-analysis' onClick={e => {
                    setTitle(findTitle('/jira-analysis'));
                    setColor('white')
                }}>Jira Analysis</Link>
            </MenuItem>
            <MenuItem key="2">
                <Link style={style} to='/kpi_engineering' onClick={e => {
                    setTitle(findTitle('/kpi_engineering'));
                    setColor('white')
                }}>Engineering KPI</Link>
            </MenuItem>
            <MenuItem key="3">
                <Link style={style} to='/file_upload' onClick={e => {
                    setTitle(findTitle('/file_upload'));
                    setColor('white')
                }}>File Upload</Link>
            </MenuItem>
            <MenuItem key="4">
                <Link style={style} to='/leo' onClick={e => {
                    setTitle(findTitle('/leo'));
                    setColor('white')
                }}>Leo 2.0</Link>
            </MenuItem>
            <MenuItem key="5">
                <Link style={style} to='/factory_data' onClick={e => {
                    setTitle(findTitle('/factory_data'));
                    setColor('white')
                }}>Factory Data</Link>
            </MenuItem>
        </Menu>
    );

    return <Dropdown
        popup={utilityMenu}
    >
        <FlexLayout itemSpacing="15px" alignItems="center">
            <h3 className="headerButton" style={{ color: color }}>Utility</h3>
            <ChevronDownIcon />
        </FlexLayout>
    </Dropdown>
}

function PaasMenu({ title, setTitle }) {
    const [color, setColor] = useState('#B8BFCA');

    useEffect(() => {
        if (title.paas) {
            setColor('white')
        } else {
            setColor('#B8BFCA')
        }
    }, [title])

    const utilityMenu = (
        <Menu
            padding="10px-0px"
            type={Menu.MenuType.SECONDARY}
            role={Menu.MenuRole.MENUBAR}
            appearance={Menu.MenuAppearance.COMPACT}
        >
            <MenuItem key="1">
                <Link style={style} to='/NextGenETL' onClick={e => {
                    setTitle(findTitle('/NextGenETL'))
                    setColor('white')
                }}>PaaS Dashboard</Link>
            </MenuItem>
        </Menu>
    );

    return <Dropdown
        popup={utilityMenu}
    >
        <FlexLayout itemSpacing="15px" alignItems="center">
            <h3 className="headerButton" style={{ color: color }}>PaaS</h3>
            <ChevronDownIcon />
        </FlexLayout>
    </Dropdown>
}