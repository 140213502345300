import axios from "axios";

const formatDate = (value, key) => {
  // Extract the year, month, and day from the numeric string
  const year = Number(value.slice(0, 4));  // First 4 characters are the year
  const month = Number(value.slice(4, 6)); // Next 2 characters are the month
  const day = Number(value.slice(6, 8));   // Last 2 characters are the day

  // Create a date object
  const date = new Date(year, month - 1, day);

  // Get month name in short format (e.g., "Aug")
  const monthShort = date.toLocaleString("en-US", { month: "short" });
  if(key === 'short'){
    return `${day} ${monthShort}`
  }else{
    return `${day} ${monthShort}, ${year}`;
  }
  // Construct the custom date format: "24 Aug, 2024"
};

// Render HTML safely
const renderHTML = (html) => ({ __html: html });

const highlightText = (text, search) => {
  if (!search) return text;
  if (typeof text !== "string") text = String(text); // Convert non-string to string

  console.log("search", search);

  const regex = new RegExp(`(${search})`, "gi");
  return text.replace(
    regex,
    '<span style="background-color: yellow; font-weight: bold;">$1</span>'
  );
};

const createDataSourceAssetModal = (data=[]) => {
  return data?.map((item, index) => {
    const newItem = { ...item };
    return {
      ...newItem,
      key: index + 1,
    };
  });
};

const createDataSourceAndHighlightOnSearch = (data, search) => {
  return data.map((item, index) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      newItem[key] = highlightText(
        newItem[key] === null
          ? "NA"
          : newItem[key],
        search
      );
    });
    return {
      ...newItem,
      key: index + 1,
    };
  });
};

const fetchTableData = async ({ queryKey }) => {
  // const { page, pageSize, search, column, order, fetchUrl, start_date, end_date } = queryKey[1];
  const { page, pageSize, column, order, fetchUrl, start_date, end_date } = queryKey[1];

  // Construct query parameters safely
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: pageSize.toString(),
    column,
    order,
    start_date,
    end_date
  });

  // if (search) {
  //   queryParams.append("search", search);
  // }
  // if (start_date) {
  //   queryParams.append("start_date", start_date);
  // }
  // if (end_date) {
  //   queryParams.append("end_date", end_date);
  // }

  const response = await axios.get(`${fetchUrl}?${queryParams.toString()}`);
  return response.data;
};

const fetchInfoData = async ({ queryKey }) => {
  // const { page, pageSize, search, column, order, fetchUrl, start_date, end_date } = queryKey[1];
  const { fetchInfoUrl, start_date, end_date } = queryKey[1];

  // Construct query parameters safely
  const queryParams = new URLSearchParams({
    start_date,
    end_date
  });
  const response = await axios.get(`${fetchInfoUrl}?${queryParams.toString()}`);
  return response.data;
};

const getCompletionPercentage = (sfdc_count, factory_eod_count) => {
  // Case 1: No Shipment (Both counts are 0)
  if (sfdc_count === 0 && factory_eod_count === 0) {
    return {
      no_shipment: 100, // Indicating 100% no shipment
      matched: 0,
      unmatched: 0,
    };
  }

  // Case 2: Factory count is zero but SFDC count is not
  if (factory_eod_count === 0) {
    return {
      no_shipment: 0,
      matched: 0,
      unmatched: 100,
    };
  }

  // Case 3: All counts matched exactly
  if (sfdc_count === factory_eod_count) {
    return {
      no_shipment: 0,
      matched: 100,
      unmatched: 0,
    };
  }

  // Case 4: Calculate percentages (Ensure matched % doesn't exceed 100)
  const matchedPercentage = Math.min((factory_eod_count / sfdc_count) * 100, 100);
  const unmatchedPercentage = 100 - matchedPercentage;

  return {
    no_shipment: 0, // No shipment scenario is already handled in Case 1
    matched: Math.round(matchedPercentage), // Rounded percentage
    unmatched: Math.round(unmatchedPercentage),
  };
};


export {
  renderHTML,
  createDataSourceAndHighlightOnSearch,
  formatDate,
  createDataSourceAssetModal,
  fetchTableData,
  getCompletionPercentage,
  fetchInfoData,
};
