import React, { Component } from "react";
import {APP_VERSION} from "../config/config";
export default class PageFooter extends Component {
    constructor(props) {
        super(props);
    }
    
    render(){
        return(
            <div style={{padding:16,backgroundColor:"rgb(64 64 64)",textAlign:"center",color:"white",fontSize:"14px",position:"relative"}}>
            {/* Version: {APP_VERSION} */}
            Version: 1.0
            </div>
        )
    }
}