import React, {  useState } from "react";
import {
  LocalTable,
  PlayIcon,
  HistoryIcon,
  ReportIcon,
  RemoveIcon,
  Link,
  NotificationTrigger,
  ShareArrowIcon,
  PlusIcon,
  Button,
  ConfirmModal,
  Input,
  Modal,
  StackingLayout,
  FlexLayout,
  TextLabel,
  TextArea,
  FlexItem,
} from "@nutanix-ui/prism-reactjs";
import { useHistory } from "react-router-dom";
import {
  RadioGroup,
  Radio,
  VerticalSeparator,
  Select,
  Checkbox,
  Badge,
  CloseIcon,
} from "@nutanix-ui/prism-reactjs";
import { EditIcon } from '@nutanix-ui/prism-reactjs';
import "./style.css";

import axios from "axios";

import { SERVER_URL,PAAS_AIRFLOW_URL } from "../../config/config";

function ETLListTable({
  columnData,
  loading,
  selectedProject,
  visible,
  setVisible,
  PipelineName,
  setPipeLineName,
  listType,
  pipeID,
  fetchETLList,
  getProcessListModel,
  scheduleType,
  userRole
}) {
  const projectID = selectedProject?.value;
  const history = useHistory();

  const [selectedSchedule, setSelectedSchedule] = useState("sync_manually");
  const [periodDisabled, setPeriodDisable] = useState(true);
  const [alertsEnableDisabled, setAlertsEnableDisabled] = useState(true);
  const [pipeAlertModalOpen, setPipeAlertModalOpen] = useState(false);
  const [deletePipeModalOpen, setDeletePipeModalOpen] = useState(false);
  const [PipeLineAlertsDisableModal, setPipeLineAlertsDisableModal] =
    useState(false);
  const [PipeLineID, setPipeLineID] = useState("");
  const [interval, setInterval] = useState({
    key: 0,
    label: "Minute",
    value: "mins",
    rowProps: {
      'data-testid': 'mins'
    }
  });

  const [intervalValue, setIntervalValue] = useState("");
  const [sparkConfig, setSparkConfig] = useState({num_executors:'',executor_cores: '', executor_memory:'', driver_memory:''});
  const [extraSparkConfig, setExtraSparkConfig] = useState('');
  const durationValue = [
    {
      key: 0,
      label: "Minutes",
      value: "mins",
      rowProps: {
        'data-testid': 'mins'
      }
    },
    {
      key: 1,
      label: "Hours",
      value: "hrs",
      rowProps: {
        'data-testid': 'hrs'
      }
    },
    {
      key: 2,
      label: "Days",
      value: "days",
      rowProps: {
        'data-testid': 'days'
      }
    },
    {
      key: 3,
      label: "Weeks",
      value: "weeks",
      rowProps: {
        'data-testid': 'weeks'
      }
    },
    {
      key: 4,
      label: "Months",
      value: "mths",
      rowProps: {
        'data-testid': 'mths'
      }
    },
    {
      key: 5,
      label: "Years",
      value: "yrs",
      rowProps: {
        'data-testid': 'yrs'
      }
    },
  ];
  const handleIntervalChange = (selectedValue) => {
    setInterval((prevState) => selectedValue);
  };

  const handleIntervalValueChange = (interval) => {
    setIntervalValue(parseInt(interval));
  };
  const handleScheduleChange = (selectedValue) => {
    setSelectedSchedule((prevState) => selectedValue);
    if (selectedValue === "periodic") {
      setPeriodDisable((prevState) => false);
    } else {
      setPeriodDisable((prevState) => true);
    }
  };
  const handleDisablePipeLineAlertsModal = (obj) => {
    setPipeLineID(obj.pipeID);
    setPipeLineAlertsDisableModal(true);
  };
  const handlePipeLineAlerts = (payload) => {
    NotificationTrigger.add({
      id: "Pipeline Alert Info",
      className: "successAlert",
      message:
        payload.action === "activate"
          ? "pipepline enable request has been submitted"
          : "pipepline disable request has been submitted",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/createAlertModel`, payload)
      .then((resp) => {
        if (resp?.data?.data?.status === 200) {
          fetchETLList(projectID);
          NotificationTrigger.add({
            id: "Pipeline Alert Success",
            className: "successAlert",
            message:
              payload.action === "activate"
                ? "alerts have been enabled for the pipeline"
                : "alerts have been disabled for the pipeline",
            type: "success",
            autoDismissDelaySecs: 5,
          });
         
        } else {
          NotificationTrigger.add({
            id: "Pipeline Alert Failed",
            className: "successAlert",
            message: "Something went wrong. Please Refresh the page",
            type: "error",
            autoDismissDelaySecs: 5,
          });
        }
        setPipeAlertModalOpen(false);
        setPipeLineAlertsDisableModal(false);
        setPipeLineAlertObj({ pipeline_id: "'",
        action: "activate",
        alert_config: { max_runtime: "" ,slack:'no',opsgenie:'no' }})
      })
      .catch((err) => {
        setPipeAlertModalOpen(false);
        setPipeLineAlertsDisableModal(false);
        setPipeLineAlertObj({ pipeline_id: "'",
        action: "activate",
        alert_config: { max_runtime: "" ,slack:'no',opsgenie:'no' }})
        NotificationTrigger.add({
          id: "Pipeline Alert Failed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };
  const handleDisablePipeLineAlerts = () => {
    let payloadObj = {
      pipeline_id: PipeLineID,
      action: "deactivate",
      alert_config: "",
    };
    handlePipeLineAlerts(payloadObj);
  };
  const handlePipelineDelete = (obj) => {
    setPipeLineID(obj?.pipeID);
    setDeletePipeModalOpen(true);
  };
  const handleDeleteProcess = (obj) => {
    NotificationTrigger.add({
      id: "Delete success",
      className: "successAlert",
      message:
        " ETL Pipeline process delete request job has been submitted. Please wait for a while for execution",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/deleteProcessModel`, {
        pipeline_id: pipeID,
        process_name: obj?.proc_name,
      })
      .then((resp) => {
        if (resp?.status === 200) {
          NotificationTrigger.add({
            id: "ETL Pipeline Process Deleted Successfully",
            className: "successAlert",
            message: "ETL Pipeline Process has been deleted",
            type: "warning",
            autoDismissDelaySecs: 5,
          });
          getProcessListModel(pipeID);
        }
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "Pipeline Process Delete Failed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 90,
        });
      });
  };
  const handleEditProcess =(obj) =>{
    history.push({
      pathname: `/paas`,
      state: {
        pipeID: pipeID,
        scheduleType: scheduleType,
        project: selectedProject,
        process_name : obj.proc_name || '',
        edit : true
      },
      search: `?pipelineName=${PipelineName}&pipelineID=${pipeID}&projectID=${selectedProject?.value}`,
    });
  }
  const handleRunNow = (obj) => {
    if(obj && obj?.pipeID){
      window.open(
        `${PAAS_AIRFLOW_URL}/dynamic_paas_platform_${projectID}_${obj?.pipeID}/graph`
      );
      runPipeline(obj?.pipeID);
    }
  };

  const handlePausePipeline = (obj) => {
    pausePipeline(obj?.pipeID);
  };

  const handleDeployPipeline = (obj) => {
    deployPipeline(obj?.pipeID);
  };

  const runPipeline = (pipeline) => {
    NotificationTrigger.add({
      id: "upload success",
      className: "successAlert",
      message: "Pipeline run request job has been submitted",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/runPipeline`, {
        project_id: projectID,
        pipeline_id: pipeline,
      })
      .then((resp) => {
        NotificationTrigger.add({
          id: "Pipeline running successfully",
          className: "successAlert",
          message: "Pipeline running successfully",
          type: "info",
          autoDismissDelaySecs: 5,
        });
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "Pipeline  To Make Run Failed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };

  const deletePipeline = (pipeline) => {
    NotificationTrigger.add({
      id: "upload success",
      className: "successAlert",
      message:
        "Pipeline delete request job has been submitted. Please wait for a while for execution",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/deletePipeline`, {
        project_id: projectID,
        pipeline_id: pipeline,
      })
      .then((resp) => {
        if (resp?.status === 200) {
          NotificationTrigger.add({
            id: "Pipeline Deleted SuccessFul",
            className: "successAlert",
            message: "ETL Pipeline has been deleted",
            type: "warning",
            autoDismissDelaySecs: 5,
          });
          fetchETLList(projectID);
        }
        setDeletePipeModalOpen(false);
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "Pipeline Delete Failed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };

  const pausePipeline = (pipeline) => {
    NotificationTrigger.add({
      id: "upload success",
      className: "successAlert",
      message:
        "Pipeline pause request job has been submitted. Please wait for a while for execution",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/ETLDeploy`, {
        project_id: projectID,
        pipeline_id: pipeline,
        action: "deactivate",
      })
      .then((resp) => {
        if (resp?.status === 200) {
          NotificationTrigger.add({
            id: "Pipeline Paused Successfully",
            className: "successAlert",
            message: "Dag  has been paused",
            type: "warning",
            autoDismissDelaySecs: 5,
          });
          fetchETLList(projectID);
        }
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "Pipeline Not Deployed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };

  const deployPipeline = (pipeline) => {
    NotificationTrigger.add({
      id: "upload success",
      className: "successAlert",
      message:
        "Pipeline deploy request job has been submitted. Please wait for a while for execution",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    axios
      .post(`${SERVER_URL}/ETLDeploy`, {
        project_id: projectID,
        pipeline_id: pipeline,
        action: "activate",
      })
      .then((resp) => {
        if (resp?.status === 200) {
          NotificationTrigger.add({
            id: "Pipeline Deployed SuccessFully",
            className: "successAlert",
            message: "Dag  has been deployed",
            type: "info",
            autoDismissDelaySecs: 5,
          });
          fetchETLList(projectID);
        }
      })
      .catch((err) => {
        NotificationTrigger.add({
          id: "Pipeline Not Deployed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };
  const umail = localStorage.getItem("email");
  const [pipeLineAlertObj, setPipeLineAlertObj] = useState({
    pipeline_id: "'",
    action: "activate",
    alert_config: { max_runtime: "" ,slack:'no',opsgenie:'no'},
  });
  const handleEnablePipeLineAlerts = () => {
    pipeLineAlertObj.alert_config = JSON.stringify(
      pipeLineAlertObj?.alert_config
    );
    setPipeLineAlertObj(pipeLineAlertObj);
    handlePipeLineAlerts(pipeLineAlertObj);
  };
  const handleOnChangeAlertsOptions = (key, value) => {
    if (key === "slack" || key === "opsgenie") {
      pipeLineAlertObj.alert_config[key] = value ? "yes" : "no";
    } else {
      pipeLineAlertObj.alert_config[key] = value;
    }
    setPipeLineAlertObj((pipeLineAlertObj) => ({ ...pipeLineAlertObj }));
    if (
      pipeLineAlertObj?.alert_config?.max_runtime.length > 0 &&
      (pipeLineAlertObj?.alert_config?.opsgenie === "yes" ||
        pipeLineAlertObj?.alert_config?.slack === "yes")
    ) {
      setAlertsEnableDisabled(false);
    } else {
      setAlertsEnableDisabled(true);
    }
  };
  const CreateETLPipeLine = () => {
    if ((sparkConfig?.num_executors?.length)===0 ||
        (sparkConfig?.executor_cores?.length)===0 || 
        (sparkConfig?.executor_memory?.length)===0 || 
        (sparkConfig?.driver_memory?.length)===0) {
      NotificationTrigger.add({
        id: "Pipeline Create Alert",
        className: "successAlert",
        message: "please provide spark config data",
        type: "warning",
        autoDismissDelaySecs: 5,
      });
    } else {
      NotificationTrigger.add({
        id: "Pipeline Created SuccessFul",
        className: "successAlert",
        message: "pipeline creation request has been submitted",
        type: "info",
        autoDismissDelaySecs: 15,
      });
      const payload = {
        project_id: projectID,
        name: PipelineName,
        scheduling_config:
          selectedSchedule === "periodic"
            ? JSON.stringify({
                schedule_type: selectedSchedule,
                frequency: { interval: interval.value, value: intervalValue },
              })
            : JSON.stringify({ schedule_type: selectedSchedule }),
        user: umail,
        spark_config: JSON.stringify(sparkConfig) || "",
        extra_spark_config: extraSparkConfig || "",
      };
      axios
        .post(`${SERVER_URL}/createETLModel`, payload)
        .then((resp) => {
          if (resp?.data?.data?.status === 200) {
            NotificationTrigger.add({
              id: "Pipeline Created Success",
              className: "successAlert",
              message: "pipeline created successfully",
              type: "success",
              autoDismissDelaySecs: 5,
            });
          } else {
            NotificationTrigger.add({
              id: "Pipeline Created Failed",
              className: "successAlert",
              message: "Something went wrong. Please Refresh the page",
              type: "error",
              autoDismissDelaySecs: 5,
            });
          }
          fetchETLList(projectID);
          setVisible(!visible);
          setIntervalValue("");
          setInterval({ key: 0, label: "Minute", value: "mins" });
          setPipeLineName("");
          setSelectedSchedule("sync_manually");
          setExtraSparkConfig("");
          setSparkConfig({});
        })
        .catch((err) => {
          NotificationTrigger.add({
            id: "Pipeline Created Failed",
            className: "successAlert",
            message: "Something went wrong. Please Refresh the page",
            type: "error",
            autoDismissDelaySecs: 5,
          });
        });
    }
  };
  const dateFormatter = (d) => {
    let date = new Date(d);
    let n = date.toLocaleString('en', {
      timeZoneName: "short",
      hour12: true,
      timeZone: "PST",
      month: "long",
      year: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return n;
  };
  let columns =
    listType !== "processes"
      ? [
          {
            title: "Created At",
            key: "created",
            className: "etlList-col1-width",
            render: (value, object, index) => {
              return <span>{dateFormatter(object?.created)}</span>;
            },
          },
          {
            title: "Pipeline Name",
            key: "pipeline",
            className: "action-col etlList-col2-width",
            render: (value, object, index) => {
              return (
                <FlexLayout
                  data-testid='paas-etl-pipelineName-link'
                  style={{ height: "100%" }}
                  justifyContent="start"
                  alignItems="center"
                  gap="10px"
                >
                  <Link
                    onClick={() =>
                      history.push({
                        pathname: `/PipeLine_Processes_List/${projectID}/${object.pipeline}`,
                        state: {
                          Project: selectedProject,
                          pipeID: object?.pipeID,
                          scheduleType: object?.scheduleType,
                        },
                      })
                    }
                  >
                    {" "}
                    {object?.pipeline}
                    {"  "}
                    <ShareArrowIcon />
                  </Link>
                </FlexLayout>
              );
            },
          },
          {
            title: "Processes Count",
            key: "pipeline",
            textAlign: "center",
            render: (value, object, index) => {
              return <span>{object?.ProcessCount}</span>;
            },
          },
          {
            title: "Excution Status",
            key: "pipeline",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  <Link data-testid='paas-etl-pipeline-excution-link' onClick={() =>{
                      if( object && object?.etlStatusLink ){
                        window.open(object?.etlStatusLink) 
                      }}}
                    >
                    <Badge
                      color={
                          ['NEW', "NOT STARTED", "NA", "SUBMITTED", "ACCEPTED", "NEW_SAVING"]?.includes(object?.status)
                          ? "grey"
                          : object?.status === "RUNNING"
                          ? "yellow"
                          : ['SUCCEEDED', "FINISHED", "COMPLETED", "ENDED" ]?.includes(object?.status)
                          ? "green"
                          : "red"
                      }
                      count={object?.status}
                    />
                  </Link>
                </span>
              );
            },
          },

          {
            title: "Run",
            key: "run",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link data-testid='paas-etl-pipeline-run-action'
                  disabled={
                    (object?.ProcessCount === 0 || !object?.status || userRole==='Viewer') ? true : false
                  }
                  onClick={(e) => handleRunNow(object)}
                >
                  <PlayIcon
                    className="etlListingIcon"
                    size="Large"
                    color="Grey"
                  />
                </Link>
              );
            },
          },
          {
            title: "History",
            key: "history",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link data-testid='paas-etl-pipeline-history-link'
                  onClick={() =>{
                    if(object && object?.pipeID) {
                        window.open(
                        `${PAAS_AIRFLOW_URL}/dynamic_paas_platform_${projectID}_${object?.pipeID}/grid`
                      )}}}
                  disabled={object?.ProcessCount === 0 ? true : false}
                >
                  <HistoryIcon
                    className="etlListingIcon"
                    color="Grey"
                    size="Large"
                  />
                </Link>
              );
            },
          },
          {
            title: "Logs",
            key: "logs",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link data-testid='paas-etl-pipeline-logs-link'
                  onClick={() =>{
                    if(object && object?.pipeID){
                        window.open(
                        `${PAAS_AIRFLOW_URL}/dynamic_paas_platform_${projectID}_${object?.pipeID}/audit_log`
                    )}}}
                  disabled={object?.ProcessCount === 0 ? true : false}
                >
                  <ReportIcon
                    className="etlListingIcon"
                    color="Grey"
                    size="Large"
                  />
                </Link>
              );
            },
          },

          {
            title: "Delete",
            key: "delete",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link  data-testid='paas-etl-pipeline-delete-action'onClick={(e) => handlePipelineDelete(object)}
                disabled= {userRole==='Viewer'}
                >
                  <RemoveIcon
                    className="etlListingIcon"
                    color="Grey"
                    size="Large"
                  />
                </Link>
              );
            },
          },
          {
            title: "Alerts",
            key: "alerts",
            textAlign: "center",
            render: (value, object, index) => {
              if (object.alertsEnabled) {
                return (
                  <Checkbox
                    data-testid='paas-etl-pipeline-alertsEnable-action'
                    id="toggle_alerts_cheked"
                    type={Checkbox.CheckboxTypes.TOGGLE}
                    checked={true}
                    onChange={(e) => {
                      handleDisablePipeLineAlertsModal(object);
                    }}
                    disabled= {userRole==='Viewer'}
                  />
                );
              } else {
                return (
                  <Checkbox
                    data-testid='paas-etl-pipeline-alertsDisable-action'
                    id="toggle_alerts_unchecked"
                    type={Checkbox.CheckboxTypes.TOGGLE}
                    checked={false}
                    onChange={(e) => {
                      setPipeAlertModalOpen(e.target.checked);
                      setPipeLineAlertObj((pipeLineAlertObj) => ({
                        ...pipeLineAlertObj,
                        pipeline_id: object?.pipeID,
                      }));
                    }}
                    disabled= {userRole==='Viewer'}
                  />
                );
              }
            },
          },
          {
            title: "Actions",
            key: "actions",
            id: "actions ",
            className: "action-col etlList-col1-width",
            textAlign: "center",
            render: (value, object, index) => {
              if (object?.deployed) {
                return (
                  <Checkbox
                    disabled={(object?.ProcessCount === 0 ||  userRole==='Viewer' )? true : false}
                    id={`toggle_deploy_checked-${index}`}
                    data-testid='paas-etl-pipeline-pause-action'
                    type={Checkbox.CheckboxTypes.TOGGLE}
                    label="Deployed"
                    checked={true}
                    onChange={(e) => {
                      handlePausePipeline(object);
                    }}
                    wrapperProps={{
                      "data-test-id": "toggle_unchecked",
                    }}
                  />
                );
              }
              return (
                <Checkbox
                  color="Grey"
                  disabled={(object?.ProcessCount === 0 || userRole==='Viewer') ? true : false}
                  data-testid='paas-etl-pipeline-deploy-action'
                  id={`toggle_deploy_unchecked-${index}`}
                  type={Checkbox.CheckboxTypes.TOGGLE}
                  label="Not Deployed"
                  checked={false}
                  onChange={(e) => {
                    handleDeployPipeline(object);
                  }}
                />
              );
            },
          },
        ]
      : [
          {
            title: "ProcessName",
            key: "proc_name",
            className: "etlList-col1-width",
          },
          {
            title: "Schedule Type",
            key: "schedule_type",
            textAlign: "center",
            className: "etlList-col1-width",
          },
          {
            title: "Inputs Count",
            key: "ip_cnt",
            textAlign: "center",
          },
          {
            title: "Outputs Count",
            key: "op_cnt",
            textAlign: "center",
          },
          {
            title: "Edit",
            key: "edit",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link onClick={()=>handleEditProcess(object)} data-testid='paas-etl-process-edit-action'>
                  <EditIcon 
                  className="etlListingIcon"
                    color="Grey"
                    size="medium"
                    />
                </Link>
              );
            },
          },
          {
            title: "Delete",
            key: "delete",
            textAlign: "center",
            render: (value, object, index) => {
              return (
                <Link onClick={(e) => handleDeleteProcess(object)} data-testid='paas-etl-process-delete-action'>
                  <RemoveIcon
                    className="etlListingIcon"
                    color="Grey"
                    size="Large"
                  />
                </Link>
              );
            },
          },
        ];
  let buttonName =
    listType !== "processes"
      ? " Create ETL pipeline"
      : "Create ETL Pipeline Process";

  return (
    <div   style={{paddingTop:'0px'}}>
        <>
          <div style={{ height: "100%" }}>
            <FlexLayout flexDirection="column" itemSpacing="-30px">
              <FlexLayout justifyContent="flex-end" style={{marginRight:'0.7%'}}>
            <Button
            data-testid={listType !== "processes" ? 'paas-etl-list-createPipeline-btn' : 'paas-etl-list-createProcess-btn'}
              style={{
                margin: "10px",
                whiteSpace: "nowrap",
              }}
              type={Button.ButtonTypes.NAVIGATION_PRIMARY}
              aria-label="Save this config"
              onClick={() => {
                listType !== "processes"
                  ? setVisible(!visible)
                  : history.push({
                      pathname: `/paas`,
                      state: {
                        pipeID: pipeID,
                        scheduleType: scheduleType,
                        project: selectedProject,
                        edit : false
                      },
                      search: `?pipelineName=${PipelineName}&pipelineID=${pipeID}&projectID=${selectedProject?.value}`,
                    });
              }}
            >
              {buttonName} <PlusIcon aria-hidden="true" size="small" />
            </Button>
            </FlexLayout>
            <FlexLayout>
            <LocalTable 
              columns={columns}
              dataSource={columnData}
              loading={visible ? false : loading}
              className='local-table-max-height'
            />
            </FlexLayout>
            </FlexLayout>
          </div>
          <ConfirmModal
            height={500}
            className="etl-popup-scroll"
            visible={visible}
            header="Enter PipeLine Name To Proceed"
            onCancel={() => setVisible(!visible)}
            footer={[
              <Button
                key="cancel"
                type="secondary"
                data-testid = 'paas-etl-list-pipeline-create-popup-cancel-btn'
                onClick={() => {
                  setVisible(!visible);
                  setIntervalValue("");
                  setInterval({ key: 0, label: "Minute", value: "mins" });
                  setPipeLineName('');
                  setSelectedSchedule("sync_manually");
                  setExtraSparkConfig('');
                  setSparkConfig({})
                }}
              >
                Cancel
              </Button>,

              <Button
                onClick={() => CreateETLPipeLine()}
                disabled={PipelineName?.trim()?.length > 0 ? false : true}
                key="confirm"
                type="primary"
                data-testid = 'paas-etl-list-pipeline-create-popup-proceed-btn'
              >
                Proceed
              </Button>,
            ]}
          >
            <StackingLayout>
              <Input
                placeholder="Please Enter PipeLine Name"
                onChange={(e) => {
                  setPipeLineName(e.target.value);
                }} value={PipelineName}
                data-testid = 'paas-etl-list-pipeline-create-popup-pipelineName-box'
              />
              <div className="pipelineSchedularContainer">
                <p
                  style={{
                    marginTop: "2px",
                    fontWeight: 600,
                    fontSize: "13px",
                  }}
                >
                  Sync Schedule
                </p>
                <div
                  className="schedulingOptions"
                  data-testid="schedulingOptions"
                  style={{ padding: "30px" }}
                >
                  <div className="pipelineRadioOption">
                    <RadioGroup
                      selectedValue={selectedSchedule}
                      onChange={handleScheduleChange}
                    >
                      <Radio value="sync_manually" data-testid = 'paas-etl-list-pipeline-create-popup-syncManually-btn'>
                        Sync Manually (On demand)
                      </Radio>
                      <Radio value="streaming" data-testid = 'paas-etl-list-pipeline-create-popup-streaming-btn'>Sync Continuously </Radio>
                      <Radio value="periodic" data-testid = 'paas-etl-list-pipeline-create-popup-scheduledSync-btn'>Scheduled Sync</Radio>
                    </RadioGroup>
                    <div className="periodContainer">
                      <VerticalSeparator
                        size="large"
                        appearance={
                          VerticalSeparator.VerticalSeparatorAppearance
                            .SECONDARY
                        }
                      />
                      <div className="timerContainer">
                        <p className="timerTitle">Every</p>
                        <div className="timeOptionContainer">
                          <Input
                            data-testid = 'paas-etl-list-pipeline-create-popup-timeOption-ip'
                            placeholder="enter value"
                            onChange={(e) => {
                              handleIntervalValueChange(e.target.value);
                            }}
                            disabled={periodDisabled}
                            style={{ marginRight: "-77px" }}
                          />
                          <Select
                            id = 'paas-etl-list-pipeline-create-popup-timeOptions-selection'
                            data-testid = 'paas-etl-list-pipeline-create-popup-timeOptions-selection'
                            rowKeyAttribute='key'
                            style={{ width: "30%" }}
                            rowsData={durationValue}
                            selectedRow={interval}
                            disabled={periodDisabled}
                            onSelectedChange={handleIntervalChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary">Spark Configuration</TextLabel>

              <FlexLayout>
                <FlexItem>
                  <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary" error={ true }>Num Excutor</TextLabel>
                  <Input
                    data-testid = 'paas-etl-list-pipeline-create-popup-spartConfig-numExe-ip'
                    placeholder="Num Excutor"
                    onChange={(e) => {
                      setSparkConfig({...sparkConfig, num_executors : e.target.value});
                    }} 
                    value={sparkConfig?.num_executors || ''}
                  />
                </FlexItem>
                <FlexItem>
                  <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary">Excutor Core</TextLabel>
                  <Input
                    data-testid = 'paas-etl-list-pipeline-create-popup-spartConfig-exeCore-ip'
                    placeholder="Excutor Core"
                    onChange={(e) => {
                      setSparkConfig({...sparkConfig, executor_cores : e.target.value});
                    }} 
                    value={sparkConfig?.executor_cores || ''}
                  />
                </FlexItem>
              </FlexLayout>
              <FlexLayout>
                <FlexItem>
                  <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary">Excutor Memory</TextLabel>
                  <Input
                    data-testid = 'paas-etl-list-pipeline-create-popup-spartConfig-execMemory-ip'
                    placeholder="Excutor Memory"
                    onChange={(e) => {
                      setSparkConfig({...sparkConfig, executor_memory : e.target.value});
                    }} 
                    value={sparkConfig?.executor_memory || ''}
                  />
                </FlexItem>
                <FlexItem>
                  <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary">Driver Memory</TextLabel>
                  <Input
                    data-testid = 'paas-etl-list-pipeline-create-popup-spartConfig-driverMemory-ip'
                    placeholder="Driver Memory"
                    onChange={(e) => {
                      setSparkConfig({...sparkConfig, driver_memory : e.target.value});
                    }} 
                    value={sparkConfig?.driver_memory || ''}
                  />
              </FlexItem>
              </FlexLayout>
              <br/>
              <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } data-test-id="type-primary">Extra Spark Config</TextLabel>
              <TextArea 
                data-testid = 'paas-etl-list-pipeline-create-popup-extraSpartConfig-ip'
                name="text-area" 
                placeholder="Extra Spark Config"
                onChange={(e) => {
                  setExtraSparkConfig(e.target.value);
                }} 
                value={extraSparkConfig || ''}
              />
            </StackingLayout>
          </ConfirmModal>
          <Modal
            visible={pipeAlertModalOpen}
            title="Creating Job Alert"
            onCancel={() => setPipeAlertModalOpen(false)}
            headerActions={
              <FlexLayout
                htmlTag="button"
                key="cancel-btn"
                onClick={() => setPipeAlertModalOpen(false)}
                type={Button.ButtonTypes.ICON_DEFAULT}
              >
                <CloseIcon data-testid = 'paas-etl-list-pipeline-alerts-enable-popup-cancel-btn'/>
              </FlexLayout>
            }
            footer={[
              <Button
                data-testid = 'paas-etl-list-pipeline-alerts-enable-submit-btn'
                onClick={() => handleEnablePipeLineAlerts()}
                disabled={alertsEnableDisabled}
                key="confirm"
                type="primary"
              >
                Enable
              </Button>,
            ]}
          >
            <StackingLayout padding="20px">
              <FlexLayout flexDirection="column">
                <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
                  Max Run Time{" "}
                </TextLabel>
                <Input
                  data-testid = 'paas-etl-list-pipeline-alerts-enable-maxRunTime-ip'
                  placeholder="Please Enter Config"
                  value={pipeLineAlertObj?.alert_config?.max_runtime}
                  onChange={(e) =>
                    handleOnChangeAlertsOptions("max_runtime", e.target.value)
                  }
                />
                <Checkbox
                  data-testid = 'paas-etl-list-pipeline-alerts-opsginie-enable-checkbox'
                  id="1"
                  onChange={(e) =>
                    handleOnChangeAlertsOptions("opsgenie", e.target.checked)
                  }
                  // readOnly={ true }
                  checked={pipeLineAlertObj?.alert_config?.opsgenie === "yes"}
                  label="Opsginie"
                />
                <Checkbox
                  data-testid = 'paas-etl-list-pipeline-alerts-slack-enable-checkbox'
                  id="2"
                  onChange={(e) =>
                    handleOnChangeAlertsOptions("slack", e.target.checked)
                  }
                  checked={pipeLineAlertObj?.alert_config?.slack === "yes"}
                  label="Slack"
                />
                <Checkbox id="3"  data-testid = 'paas-etl-list-pipeline-alerts-jira-enable-checkbox' disabled checked={false} label="Jira" />
                <Checkbox id="4"  data-testid = 'paas-etl-list-pipeline-alerts-email-enable-checkbox' disabled checked={false} label="Email" />
              </FlexLayout>
            </StackingLayout>
          </Modal>
          <ConfirmModal
            visible={deletePipeModalOpen}
            onCancel={() => setDeletePipeModalOpen(false)}
            onConfirm={() => deletePipeline(PipeLineID)}
          >
            Are you sure you want delete PipeLine?
          </ConfirmModal>
          <ConfirmModal
            visible={PipeLineAlertsDisableModal}
            onCancel={() => setPipeLineAlertsDisableModal(false)}
            onConfirm={() => handleDisablePipeLineAlerts(PipeLineID)}
          >
            Are you sure you want disable PipeLine Alerts?
          </ConfirmModal>
        </>
    </div>
  );
}
export default ETLListTable;
