import React from "react";
import {

  Layout,
  Table,
  Button, Spin, Alert,
} from "antd";
import axios from "axios";
import { teamContactDataSource } from './config'
import JiraTableDetails from "./JiraTableDetails";
import StagePlatformChartPopover from "./StagePlatformChartPopover";
import ProductionPlatformChartPopover from "./ProductionPlatformChartPopover";
import DataSyncChartPopover from "./DataSyncChartPopover";
import DemoGauge from './ProgressChart';
import logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";
const { Content } = Layout;

export class TilesDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stageupTimeAverage: 0,
      productionUpTimeAverage: 0,
      dataSyncAverage: 0,
      platformProdData: [],
      plafformStgData: [],
      dataSyncData: [],
      apiProgress: { loading: false, ready: false, error: false },

    };
  }



  teamContactColumns = () => [
    {
      title: "SNo",
      dataIndex: "sno",
      key: "sno",
      className: "columndata t-a-r",
      render: (value) => {
        return <div className="snostyle">{value}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "columndata",
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      className: "columndata",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      className: "columndata",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "columndata",
    },
  ];


  async componentDidMount() {
    this.setState({
      apiProgress: { loading: true, ready: false, error: false },
    });
    try {
      logger.info(`Fetching Platform Production Data Uptime Values`, "dashboard_tiles_client", window.location.href)
      const getPlatformProdData = await axios.post(
        `${SERVER_URL}/getallmetrics?no_of_days=1`
      );
      const platformProdUptimeData = getPlatformProdData.data
        .filter((e) => e.name === "Up Time")
        .map((e) => e.metrics_details);
      logger.info(`Fetched Platform Data Uptime values successfully : ${platformProdUptimeData}`, "dashboard_tiles_client", window.location.href)
      logger.info(`Fetching Stage Data Uptime Values`, "dashboard_tiles_client", window.location.href)
      const getPlatformStageData = await axios.post(
        `${SERVER_URL}/getallmetricsstg?no_of_days=1`
      );
      const stageData = getPlatformStageData.data.filter((e) => e.platform !== "DREMIO");
      const platformStageUptimeData = getPlatformStageData.data
        .filter((e) => e.name === "Up Time" && e.platform !== "DREMIO")
        .map((e) => e.metrics_details);
      logger.info(`Fetched Platform Stage Uptime values successfully : ${platformStageUptimeData}`, "dashboard_tiles_client", window.location.href)
      logger.info(`Fetching Datasync Summary`, "dashboard_tiles_client", window.location.href)
      const { data: dataSyncRes } = await axios.post(
        `${SERVER_URL}/datasyncsummary`
      );
      logger.info(`Fetched Datasync Summary Successfully`, "dashboard_tiles_client", window.location.href)
      const dataSyncData = dataSyncRes.map(e => e.data_quality);
      const dataSyncAverage = dataSyncData.reduce((acc, curr) => {
        acc += Number(curr);
        return acc;
      }, 0) / dataSyncData.length;
      const stageAverage =
        platformStageUptimeData.reduce((acc, curr) => {
          acc += Number(curr);
          return acc;
        }, 0) / platformStageUptimeData.length;
      const prodAverage =
        platformProdUptimeData.reduce((acc, curr) => {
          acc += Number(curr);
          return acc;
        }, 0) / platformProdUptimeData.length;

      this.setState({
        platformProdData: getPlatformProdData.data,
        platformStgData: stageData,
        dataSyncData: dataSyncRes,
        stageupTimeAverage: stageAverage.toLocaleString(3) || 0,
        productionUpTimeAverage: prodAverage.toLocaleString(3) || 0,
        dataSyncAverage: dataSyncAverage.toLocaleString(3) || 0
      });
      this.setState({
        apiProgress: { loading: false, ready: true, error: false },
      });
    } catch (e) {
      logger.error(e, "dashboard_tiles_client", window.location.href)
      const error = e.message;
      this.setState({
        apiProgress: { loading: false, ready: false, error: error },
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.apiProgress.error && (
          <Alert
            message="Error"
            description={this.state.apiProgress.error}
            type="error"
            showIcon
          />
        )}
        <Spin
          spinning={this.state.apiProgress.loading}
          tip={"Loading.please wait..."}
        >
          <div
            style={{ display: "flex", gap: 32, justifyContent: "space-between" }}
          >

            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
                background: "#fff",
                alignItems: "strech",
                flexBasis: "66.66",
              }}
            >
              <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                <div
                  style={{
                    borderRight: "1px solid #b9b9b9",
                    paddingRight: 32,
                    flexBasis: "66.66",
                  }}
                >
                  <p style={{ fontSize: 20, paddingTop: 16, paddingLeft: 16 }}>
                    Platforms Health
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: 16,
                      paddingTop: 16,
                      paddingLeft: 16,
                    }}
                  >
                    {/* if percentage is 99 and above green.. greater than 96 and less than 99 then orange.... less than 96 red */}


                    <div style={{ position: 'relative' }}>
                      <DemoGauge percentage={this.state?.productionUpTimeAverage / 100} />
                      <div style={{ position: 'absolute', top: 16, right: 16, display: 'flex', gap: 24, alignItems: 'center' }}>
                        <p style={{ fontSize: 15, marginBottom: 0 }}>Production </p>
                        <ProductionPlatformChartPopover prodData={this.state.platformProdData}>
                          <div style={{ float: 'right', height: 21, width: 21, marginTop: 5 }}>
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-10cscxr"
                              fill="rgb(135 141 137)"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="MenuIcon"
                            >
                              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                            </svg>
                          </div>
                        </ProductionPlatformChartPopover>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <DemoGauge percentage={this.state?.stageupTimeAverage / 100} />
                      <div style={{ position: 'absolute', top: 16, right: 16, display: 'flex', gap: 24, alignItems: 'center' }}>
                        <p style={{ fontSize: 15, marginBottom: 0 }}>Stage </p>
                        <StagePlatformChartPopover stgData={this.state.platformStgData}>
                          <div style={{ float: 'right', height: 21, width: 21, marginTop: 5 }}>
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-10cscxr"
                              fill="rgb(135 141 137)"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="MenuIcon"
                            >
                              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                            </svg>
                          </div>
                        </StagePlatformChartPopover>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    flexBasis: "33.33%",
                    paddingRight: 20
                  }}
                >
                  <p style={{ fontSize: 20, paddingTop: 16 }}>Data Sync</p>
                  <div style={{ position: 'relative' }}>
                    <DemoGauge percentage={this.state?.dataSyncAverage / 100} labels={"PROD & STAGE"} />
                    <div style={{ position: 'absolute', top: 16, right: 16, display: 'flex', gap: 24, alignItems: 'center' }}>
                      <p style={{ fontSize: 15, marginBottom: 0 }}>Prod & Stage </p>
                      <DataSyncChartPopover dataSyncData={this.state.dataSyncData}>
                        <div style={{ float: 'right', height: 21, width: 21, marginTop: 5 }}>
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-10cscxr"
                            fill="rgb(135 141 137)"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="MenuIcon"
                          >
                            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                          </svg>
                        </div>
                      </DataSyncChartPopover>
                    </div>
                    <div style={{ position: 'absolute', top: 16, right: 16, color: 'black', display: 'flex' }}>
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-10cscxr"
                        fill="rgb(135 141 137)"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="MenuIcon"
                      >
                        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                      </svg>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <Button type="primary" style={{ marginRight: 20 }}>Create Jira</Button>
          </div>
        </Spin>
        <div style={{ display: "flex", gap: 32, marginTop: 32, overflow: "hidden", padding: 20, boxSizing: "border-box" }}>
          <div style={{ background: "#fff", flex: 1, flexBasis: "50%", overflow: "auto" }}>
            <div
              style={{
                padding: 12,
                fontWeight: 600,
                fontSize: 16,
                background: "#26bbf0",
                color: "#fff",
              }}
            >
              Team Contact Details
            </div>
            <Table
              columns={this.teamContactColumns()}
              dataSource={teamContactDataSource}
              pagination={true}
              style={{ overflow: "auto", padding: "16px" }}
            />
          </div>
          <div style={{ background: "#fff", marginLeft: 16, flex: 1, flexBasis: "50%", overflow: "auto" }}>
            <div className="card-container">
              <div
                style={{
                  background: "#26bbf0",
                  padding: 14,
                  paddingLeft: 24,
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Jira Tickets List
              </div>
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <JiraTableDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TilesDashboard;
