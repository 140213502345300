import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "@nutanix-ui/recharts";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, TextLabel } from "@nutanix-ui/prism-reactjs";
import { CartesianGridColor, formatQuarter, getStageColumn, getStageDataSource, graphHeight, transformAndCalculatePercentages, useStageDetail } from "../../EngineeringUtils";
import { setColumnData, setDataSourceData, setOpenModal } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import NoData from "./NoData";
const labelStyle = { fontSize: "11px", fill: "#9AA5B5" };

const OverTime = ({ margin }) => {
  const dispatch = useDispatch()
  const { overTimeData, filters, defaultView } = useSelector(
    (state) => state.enggDashboard
  );
  const [data, setData] = useState([]);
  const [param, setParam] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const handleBarClick = (data) => {
    setParam(data.name);
    setEnabled(true);
  };

  const { data:stageData, isLoading } = useStageDetail(filters, enabled, 'cycle_time_stages_overtime', param, null)
  

  useEffect(() => {
    if(enabled && !isLoading && stageData.length > 0){
      dispatch(setColumnData(getStageColumn()))
      dispatch(setDataSourceData(getStageDataSource(stageData)))
      dispatch(setOpenModal(true));
      setEnabled(false)
    }
  }, [stageData, enabled, isLoading])

  const formatLabelText = (value) => {
    return `${value}${
      defaultView.view_days ? "" : "%"
    }`;
  };

  useEffect(() => {
    if (overTimeData && filters) {
      // Filter out items with null values based on the viewBy filter
      const overTimeFiltered = overTimeData.filter(
        (item) =>
          item[filters.viewBy === "Monthly" ? "monthly" : "quarterly"] !== null
      );

      // Transform and calculate percentages
      const transformedData = transformAndCalculatePercentages(overTimeFiltered, filters);

      // Update state with the transformed data
      setData(transformedData);
    }
  }, [overTimeData, filters, transformAndCalculatePercentages]);


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <TextLabel
            display="block"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            {filters.viewBy === "Monthly" ? label :  formatQuarter(label)}
          </TextLabel>
            <div className="grid-container">
              <div className="grid-item bold">Stages</div>
              <div className="grid-item bold center-aligned">Days</div>
              <div className="grid-item bold center-aligned">% of Total</div>
              <div className="grid-item">
                <Badge color="#F55656" text="Blocked" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Blocked"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["BlockedPERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#F0CE75" text="Development" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Development"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["DevelopmentPERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#3DAEF5" text="Code Review" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Code Review"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["Code ReviewPERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#596CA6" text="QA Testing" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["QA Testing"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["QA TestingPERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#8974F0" text="Business UAT" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Business UAT"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["Business UATPERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#93D1B2" text="QA/UAT Done" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["QA/UAT Done"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["QA/UAT DonePERCENTAGE"]+ '%'}
              </div>
              <div className="grid-item">
                <Badge color="#3D8F8F" text="Deployment" />
              </div>
              <div className="grid-item center-aligned">
                {payload?.length > 0 ? payload[0].payload["Deployment"] : 0}
              </div>
              <div className="grid-item center-aligned">
                {payload[0].payload["DeploymentPERCENTAGE"]+ '%'}
              </div>
            </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={graphHeight}>
      {overTimeData?.length > 0 ? (
        <BarChart
          data={data}
          barSize={25} // Adjust the gap between bars as needed
          barCategoryGap={10}
          alignment="none"
          margin={margin}
        >
          <CartesianGrid stroke={CartesianGridColor} />
          <XAxis
            interval={0}
            axisLine={true}
            dataKey="name"
            tickFormatter={(value) => {
              return filters.viewBy === "Monthly"
                ? value
                : formatQuarter(value);
            }}
          />
          <YAxis
            axisLine={true}
            tickFormatter={(value) => {
              if (value === 0) {
                return 0;
              }
              return defaultView.view_days ? value : `${value}%`;
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            barGap={30}
            dataKey={defaultView.view_days ? "Blocked" : "BlockedPERCENTAGE"}
            stackId="a"
            fill="#F55656"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days ? "Blocked" : "BlockedPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "Development" : "DevelopmentPERCENTAGE"
            }
            stackId="a"
            fill="#F0CE75"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days
                    ? "Development"
                    : "DevelopmentPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "Code Review" : "Code ReviewPERCENTAGE"
            }
            stackId="a"
            fill="#3DAEF5"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days
                    ? "Code Review"
                    : "Code ReviewPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "QA Testing" : "QA TestingPERCENTAGE"
            }
            stackId="a"
            fill="#596CA6"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days ? "QA Testing" : "QA TestingPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "Business UAT" : "Business UATPERCENTAGE"
            }
            stackId="a"
            fill="#8974F0"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days
                    ? "Business UAT"
                    : "Business UATPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "QA/UAT Done" : "QA/UAT DonePERCENTAGE"
            }
            stackId="a"
            fill="#93D1B2"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days
                    ? "QA/UAT Done"
                    : "QA/UAT DonePERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
          <Bar
            barGap={30}
            dataKey={
              defaultView.view_days ? "Deployment" : "DeploymentPERCENTAGE"
            }
            stackId="a"
            fill="#3D8F8F"
            onClick={(data) => handleBarClick(data)}
          >
            {!defaultView.default && (
              <LabelList
                dataKey={
                  defaultView.view_days ? "Deployment" : "DeploymentPERCENTAGE"
                }
                position="right"
                style={labelStyle}
                formatter={formatLabelText}
              />
            )}
          </Bar>
        </BarChart>
      ) : (
        <NoData />
      )}
    </ResponsiveContainer>
  );
};

export default OverTime;
