import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, StackingLayout } from '@nutanix-ui/prism-reactjs';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveHeaderTab } from '../../../features/JiraAnalysis/enggDashboardSlice';
import moment from 'moment';
import { useLastRefreshed } from '../EngineeringUtils';
// import AdoptionHeaderContainer from '../../AdoptionHeader';
const EnggDashboardTab = () => {
  const dispatch = useDispatch();
  const [kpiLastUpdate, setKpiLastUpadte] = useState(null)
  const { data: lastRefreshed, isLoading, error, isError } = useLastRefreshed()
  if (isError) {
    throw error; // This will be caught by the ErrorBoundary
  }
  const { activeHeaderTab, codeCoverageLastUpdate } = useSelector((state) => state.enggDashboard);
  const coverage_last_update = codeCoverageLastUpdate ? moment(codeCoverageLastUpdate).format("DD MMM YYYY") : 'Fetching...'
  const panelIdPrefix = useMemo(() => uniqueId('tab-panel-'), []);

  const handleTabClick = (tabId) => {
    dispatch(setActiveHeaderTab(tabId))
  };

  useEffect(() => {
    if (!isLoading && lastRefreshed) {
      const lastUpdate = moment(lastRefreshed?.last_refreshed).format("DD MMM YYYY, hh:mm A")
      setKpiLastUpadte(lastUpdate)
    }
  }, [lastRefreshed, isLoading])


  return (
    <StackingLayout data-test-id="default-with-lr-zero" className='tabheader' >
      <Tabs
        adaptive={true}
        data={[
          { title: 'Velocity', key: 1 },
          { title: 'Cycle Time', key: 2 },
          { title: 'CFDs (Customer Defects)', key: 3 },
          { title: 'Automation Coverage & Regression Test', key: 4 }
        ]}
        onTabClick={handleTabClick}
        activeKey={activeHeaderTab}
        defaultActiveKey={1}
        fullWidth={true}
        data-test-id="full-width"
        panelIdPrefix={panelIdPrefix}
        style={{ width: '700px' }}
      />
      <div className='kpiEnggHeaderAdoption'>
        {/* <AdoptionHeaderContainer width={"20%"} businessUnit={'jira_analysis'} /> */}
        <span className='recentUpdate'>
          {`Last Update: ${activeHeaderTab !== 4 ? isLoading ? 'Fetching...' : kpiLastUpdate : coverage_last_update}`}
        </span>
      </div>
    </StackingLayout>
  );
};

export default EnggDashboardTab;
