import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  summary: [],
  eta: [],
  story: [],
  storyOverView: [],
  filteredData: {
    filteredSummary: [],
    filteredEta: [],
    filteredStory: [],
    filteredStoryOverView: [],
  },
  lastRefreshed: "",
  dataFetched: false,
  loaders: {
    teamLoading: false,
    dashboardLoading: false
  }
};

// Create a dataSlice using Redux Toolkit
const dataSlice = createSlice({
  name: "data", // Slice name
  initialState, // Initial state

  // Reducer functions
  reducers: {
    setTeamLoaders: (state, action) => {
      state.loaders.teamLoading = action.payload
    },
    setDashboardLoaders: (state, action) => {
      state.loaders.dashboardLoading = action.payload
    },
    // Set the summary data
    setSummary: (state, action) => {
      state.summary = action.payload;
    },

    // Set the eta data
    setEta: (state, action) => {
      state.eta = action.payload;
    },

    // Set the story data
    setStory: (state, action) => {
      state.story = action.payload;
    },
    setStoryOverView: (state, action) => {
      state.storyOverView = action.payload;
    },
    setFilteredSummary:(state, action) => {
      state.filteredData.filteredSummary = action.payload
    },
    setFilteredEta:(state, action) => {
      state.filteredData.filteredEta = action.payload
    },
    setFilteredStory:(state, action) => {
      state.filteredData.filteredStory = action.payload
    },
    setFilteredStoryOverview:(state, action) => {
      state.filteredData.filteredStoryOverView = action.payload
    },
    setLastRefresh: (state, action) => {
      state.lastRefreshed = action.payload; // Update the lastRefresh state with the new value
    },
    setDataFetched: (state, action) => {
      state.dataFetched = action.payload;
    },
  },
});
export const {
  setSummary,
  setEta,
  setStory,
  setStoryOverView,
  setFilteredSummary,
  setFilteredEta,
  setFilteredStory,
  setFilteredStoryOverview,
  setLastRefresh,
  setDataFetched,
  setTeamLoaders,
  setDashboardLoaders
} = dataSlice.actions;
export default dataSlice.reducer;
