import React, {  useEffect, useState } from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  Tooltip
} from '@nutanix-ui/recharts';
import "./RMEStatus.css";
import { useRMEFilters } from "../../../../../../contexts/rmeTeamContext/RMEFiltersContext";
import { Divider} from '@nutanix-ui/prism-reactjs'
import { downloadRMETemplate } from '../../../../../../utils/rmecsvDownloadUtlity';
import ViewDetailModal from "../../../../../ViewDetailModal/ViewDetailModal";
const CardContainer = ({ applyClicked }) => {
  const {teamData,tableColumns ,loading,setLoading} = useRMEFilters(); // Ensure correct destructuring

  const [error, setError] = useState(null);

    const [sum, setSum] = useState([]);
    const [data, setData] = useState([]);
    const [lineChartData, setLineChartData] = useState({});

      const [openModal, setOpenModal] = useState(false);
      const [modalTitle, setModalTitle] = useState('');
// Function to aggregate data by month
const chartFilterData=(item, status)=>{
  if(status === 'Closed'){
    return item.jira_status === 'Closed'
  }
  if(status === 'open'){
    return item.jira_status !== 'Closed'
  }
}
const aggregateByMonth = (data,status) => {
  const aggregatedData = {};
  const filteredData = !['All','Age'].includes(status) ? data.filter(item => chartFilterData(item,status)) : data
  // Loop through the data and aggregate by month
  filteredData?.forEach((item) => {
    const month = new Date(item.dt_created).toLocaleString('default', { month: 'short' }); // Get month in short format
   
    if(status=== 'Age'){
      if (!aggregatedData[month]) {
        aggregatedData[month] = 0;
      }
      aggregatedData[month] += item.ttl_rme_age;  
    } else
      {
      if (!aggregatedData[month]) {
        aggregatedData[month] = 0;
      }
      aggregatedData[month] += 1;  
    }
      
  });

  // Convert aggregated data into the desired format
  const result = Object.keys(aggregatedData).map((month) => ({
    name: month,
    value: status=== 'Age' ? Math.ceil(data.length>0 ?aggregatedData[month]/data.length :0) : aggregatedData[month]
  }));

  return result;
};


useEffect(()=>{
  setLoading(true);
if(teamData,teamData?.data?.length > 0,!loading){
    const initialSum = {
        closed: 0,
        open: 0,
        totalAge: 0,

        monitoringAc:0,
        taskTracker :0,

        redACStatus:0,
        yellowACStatus:0,
        greenACStatus:0,

        hotAc:0,
        criticalAc:0,
        reactiveAc:0,

        ttrCount:0,
        openToClose:0,
        openToMon:0,

        triggered:0,
        moved:0,
        reEscalated:0,

        oneAssigneeOpen:0,
        oneAssigneeClose:0,

        onePlusAssigneeOpen:0,
        onePlusAssigneeClose:0
      };
      // Aggregate the data monthly
    const aggregatedOpenTickets = aggregateByMonth(teamData?.data,'open');
    const aggregatedCloseedTickets = aggregateByMonth(teamData?.data,'Closed');
    const aggregatedAllTickets = aggregateByMonth(teamData?.data,'All');
    const aggregatedAgeTickets = aggregateByMonth(teamData?.data,'Age');
    setLineChartData({
      open: aggregatedOpenTickets,
      closed:aggregatedCloseedTickets,
      all:aggregatedAllTickets,
      age:aggregatedAgeTickets,
    })
      const issuesStatistics =  teamData?.data?.reduce((acc, ticket) => {
          
        if (ticket.jira_status === "Closed") {
              acc.closed += 1;
              acc.openToClose += Math.ceil((ticket['ttr_open_close']));
              acc.openToMon += Math.ceil((ticket['ttr_open_sol']));
          }
           if (ticket.jira_status !== "Closed") {
              acc.open += 1;
          } 
           if(ticket.issue_type ===  "Monitoring Account" ){
              acc.monitoringAc += 1;
          } 
           if(ticket.issue_type.trim() === "Task Tracker" ){
              acc.taskTracker += 1;
          } 
           if(ticket.engage_type.trim() === "Critical Case"){
            acc.criticalAc += 1 
          } 
           if(ticket.engage_type.trim() === "CAP"){
            acc.reactiveAc += 1;
          } 
           if(ticket.engage_type.trim() === "Hot Account"){
            acc.hotAc += 1;
          }
          if(ticket.ttl_rme === "Y"){
            acc.triggered += 1;
          }
          if(ticket.act_ttl_rme === "Y"){
            acc.moved += 1;
          }
          if(ticket.rme_r_g_y_to_red === "Y"){
            acc.reEscalated += 1;
          }
          acc.totalAge += ticket['ttl_rme_age'];
          acc.redACStatus += Math.ceil((ticket['days_in_red']));
          acc.yellowACStatus += Math.ceil((ticket['days_in_yellow']));
          acc.greenACStatus += Math.ceil((ticket['days_in_green']));        

          return acc;
      }, initialSum);
      let obj={
        oneAssigneeOpen: 0,
        oneAssigneeClose: 0,
        onePlusAssigneeOpen: 0,
        onePlusAssigneeClose: 0,
      }
      const assigneeCount =teamData?.one_assignee_count?.reduce((acc,item)=>{
        if(item.assignee_group== '1 assignee' && item.jira_status !== 'Closed'){
          acc.oneAssigneeOpen +=1
        }
        if(item.assignee_group== '1 assignee' && item.jira_status === 'Closed'){
          acc.oneAssigneeClose +=1
        }
        if(item.assignee_group== '1+ assignees' && item.jira_status !== 'Closed'){
          acc.onePlusAssigneeOpen +=1
        }
        if(item.assignee_group== '1+ assignees' && item.jira_status === 'Closed'){
          acc.onePlusAssigneeClose +=1
        }
        return acc
      },obj)
      
      setSum([
        {id: 'totalJira', label: 'Total Jira', count: teamData?.data?.length.toLocaleString(),totalAge:Math.ceil(issuesStatistics.totalAge/teamData?.data?.length)},
        { id: 'open', label: 'Open', count: issuesStatistics.open.toLocaleString(),color:'red'},
        {id: 'closed', label: 'Closed', count: issuesStatistics.closed.toLocaleString()},
        { id: 'smToRm', label: 'SM to RM', count: 0, stroke: '#20794E',data:{triggered:issuesStatistics.triggered,moved:issuesStatistics.moved}, trends: [] },
        {id: 'ttr', label: 'TTR', count: Math.ceil(teamData?.ttr_count),data :{openToClose:issuesStatistics.closed === 0 ? 0 : Math.ceil(issuesStatistics.openToClose/(issuesStatistics.closed)) ,openToMon: issuesStatistics.closed === 0 ? 0 : Math.ceil(issuesStatistics.openToMon/(issuesStatistics.closed)) || 0}},
        {id: 'accountState', label: 'Account State', data: {red:issuesStatistics.redACStatus/teamData?.data?.length,green:issuesStatistics.greenACStatus/teamData?.data?.length,yellow:issuesStatistics.yellowACStatus/teamData?.data?.length}},
        {id: 'ageBy', label: 'RME Count', data: {hotAC:issuesStatistics.hotAc,reactiveAC:issuesStatistics.reactiveAc,criticalAC:issuesStatistics.criticalAc}},
        {id: 'tracker', label: 'Accounts', data: {taskTracker:issuesStatistics.taskTracker,monitoringAc:issuesStatistics.monitoringAc}},
        {id: 'rmeReEscalation', label: `Re-escalated RME's`, count:issuesStatistics.reEscalated },
        {id: 'assignee', label: 'Assingee', data:assigneeCount },
      ]);
   
      setLoading(false);
}
},[teamData,teamData?.data?.length>0,teamData?.ttr_count])

  
    const logics =(cardName,item,cardData)=>{
      setModalTitle(cardData.label)
      if(cardName === 'open'){
        return item.jira_status !=='Closed'
      }
      if(cardName === 'closed'){
        return item.jira_status ==='Closed'
      }
      if(cardName === 'hotAc'){
        return item.engage_type.trim() === "Hot Account" 
      }
      if(cardName === 'criticalAc'){
        return item.engage_type.trim() === "Critical Case" 
      }
      if(cardName === 'reactiveAc'){
        return item.engage_type.trim() === "CAP"
      }
      if(cardName === 'MontoringAc'){
        return item.issue_type === "Monitoring Account"
      }
      if(cardName === 'taskTrackerAc'){
        return item.issue_type === "Task Tracker"
      }
      if(cardName === 'triggerd'){
        return item.ttl_rme === "Y"
      }
      if(cardName === 'moved'){
        return item.act_ttl_rme === "Y"
      }
      if(cardName === 'rmeReEscalation'){
        return item.rme_r_g_y_to_red === "Y"
      }
     
      if(cardName === 'oneAssigneeOpen'){
        return item.assignee_group== '1 assignee' && item.jira_status !== 'Closed'
      }

      if(cardName === 'oneAssigneeClose'){
        return item.assignee_group== '1 assignee' && item.jira_status === 'Closed'
      }

      if(cardName === 'onePlusAssigneeOpen'){
        return item.assignee_group== '1+ assignees' && item.jira_status !== 'Closed'
      }
      if(cardName === 'onePlusAssigneeClose'){
        return item.assignee_group== '1+ assignees' && item.jira_status === 'Closed'
      }
    }
    const handleClickCards=(id,cardData)=>{
      if(teamData &&teamData?.data?.length>0){
              const filteredData =teamData?.data?.filter((item)=>{
                let condtion =logics(id,item,cardData);
                    if(condtion){
                        return true
                    }
                 })
                 .map((item, index) => ({
                    key: index + 1,
                    ...item
                }));
                setOpenModal(true)
                setData(filteredData)
        }
    }
    const handleAssigneTableData =(id,cardData)=>{
      if(teamData &&teamData?.data?.length>0){
              const filteredData =teamData?.one_assignee_count?.filter((item)=>{
                let condtion =logics(id,item,cardData);
                    if(condtion){
                        return true
                    }
                 })
                 .map((item, index) => ({
                    key: index + 1,
                    ...item
                }));
                setOpenModal(true)
                setData(filteredData)
        }
    }
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
   
  const customLineChart =(data)=>{
    return (
      <ResponsiveContainer height={100} >
          <LineChart data={ data } >
          <XAxis dataKey="name" interval="preserveStartEnd"/>
          <Line dataKey="value" />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
      )
  }
const handleDownloadClick = (row,label) => {
    if(row.length>0){
      downloadRMETemplate(row, label);
    }
}
  return (
    <div className="rmeDashboard grid-container "  style={{ gridTemplateColumns: "repeat(6, 1fr)" }}>
      {sum.map((item) => (
        <div className="grid-item" key={item.id} onClick={()=>{['open','closed']?.includes(item.id) &&handleClickCards(item.id,item) }} >
          <div className="left-section" style={{cursor:['open','closed']?.includes(item.id)? 'pointer' :''}}>
          {item.id === 'ageBy' &&
          <div className='rmeCardItems'>
            <div className='rmeCardsLabel font-weight'>{item.label}</div>
            <div className='rmeCarditem-Age-tracker'
             onClick={(event)=>{
              event.stopPropagation();
              handleClickCards('reactiveAc',{label:'Cap A/C'})}}>
                <div  className="rmeCardLabelsFont15 font-weight" style={{color:'red'}}> CAP  </div> 
                <div>{item.data.reactiveAC}</div>
            </div>
            <Divider  />

            <div className='rmeCarditem-Age-tracker '
            onClick={(event)=>{
              event.stopPropagation();
              handleClickCards('hotAc',{label:'Hot A/C'})}}>
              <div  className="rmeCardLabelsFont15 font-weight" style={{color:'yellowgreen'}}> Hot Account </div> 
              <div>{item.data.hotAC}</div>
              </div> 
           
           
            <Divider  />
            <div className='rmeCarditem-Age-tracker'
              onClick={(event)=>{
                event.stopPropagation();
                handleClickCards('criticalAc',{label:'Critical Case'})}}>
                <div className="rmeCardLabelsFont15 font-weight"  style={{color:'green'}}> Critical Case </div> 
                <div  >{item.data.criticalAC}</div>
            </div>
          </div>}
          {item.id === 'smToRm'&&
            <div className='rmeCardItems'>
              <div className='rmeCardsLabel font-weight'>{item.label}</div>
              <Divider  />
            <div  className='rmeCardItem-smTorm rmeCardLabelsFont15' > 
                <div > Triggered </div> 
                <div > Moved</div> 
            </div>
            <div  className='rmeCardItem-smTorm rmeCardLabelsFont30 rmeCardPointer'> 
            <div
                 onClick={(event)=>{
                  event.stopPropagation();
                  handleClickCards('triggerd',{label:'Triggered Issues'})}}
                > {item.data.triggered} </div> 
                <div 
                 onClick={(event)=>{
                  event.stopPropagation();
                  handleClickCards('moved',{label:'Moved Issues'})}}
                > {item.data.moved} </div> 
                
            </div>
            </div>
            }
            {item.id === 'rmeReEscalation'&&
            <div className='rmeCardItems'>
              <div className='rmeCardsLabel font-weight'>{item.label}</div>
              <Divider  />
            <div className="rme-reEscalatedCard"
                 onClick={(event)=>{
                  event.stopPropagation();
                  handleClickCards('rmeReEscalation',{label:'Re-esclated Tickets'})}}
                > {item.count} </div> 
            </div>
            }
            {item.id === 'ttr' &&
               <div className='rmeCardItems'>
                <div className="rmeCardItem-smTorm">
                  <div className='rmeCardsLabel font-weight rmeCardLabelsFont20'>{item.label}</div>
                  {/* <div className='rmeCardsLabel font-weight rmeCardLabelsFont20'>{item.count}</div> */}
                </div>
                <Divider  />
                <div  className='rmeCardItem-smTorm rmeCardLabelsFont30' > 
                    <div  style={{whiteSpace:'nowrap',fontSize:'12px'}}> Open-Close </div> 
                    <div style={{fontSize:'12px',marginLeft:'45px'}}> open - solution provided</div> 
                </div>
             <div  className='rmeCardItem-smTorm rmeCardLabelsFont30'> 
                <div 
                  >{item.data.openToClose}</div>
                 <div 
                 > {item.data.moved} </div> 
                <div 
                >{item.data.openToMon}</div>
                 
             </div>
             </div>
          }
          {item.id === 'totalJira'&&
          <div className="rmeCardItems gap10">
            <div className="rme-totalJira-card"> 
                <div className="rme-totalJira-card-items"> 
                    <span className="rmeCardLabelsFont30 font-weight"> {item.count} </span> 
                    <div style={{whiteSpace:'nowrap'}} className="rmeCardLabelsFont15">Total Jira</div>
                </div>
                <div style={{position:'relative',width:'100%'}}>

                {  customLineChart(lineChartData?.all )}
                </div> 
              </div> 
              <Divider  />
              <div className="rme-totalJira-card"> 
                <div className="rme-totalJira-card-items"> 
                    <span className="rmeCardLabelsFont30 font-weight"> {item.totalAge || 0} </span> 
                    <div style={{whiteSpace:'nowrap'}} className="rmeCardLabelsFont15">Total Age</div>
                </div>
                <div style={{position:'relative',width:'100%'}}>

                {  customLineChart(lineChartData?.age )}
                </div> 
              </div> 
          </div>
            
          }
           { item.id === 'accountState'&&
           <div className="font-weight" style={{ color: item.textColor || '' }}>
            <div className="rmeCardsLabel">{item.label}</div>
             <div className="rmeCardItems gap10"> 
                <div style={{display:'flex',justifyContent:'space-around',marginTop:'20px'}}>
                    <span style={{fontSize:'20px',marginTop:'8px',color:'red'}}> Red </span> 
                    <div style={{fontSize:'30px'}}>{Math.ceil(item.data.red.toLocaleString()) || 0}</div>
                </div>
                <Divider  />
                <div style={{display:'flex',justifyContent:'space-around'}}>
                    <span style={{fontSize:'20px',marginTop:'8px',color:'yellowgreen'}}> Yellow </span> 
                    <div className="font-weight rmeCardLabelsFont30">{Math.ceil(item.data.yellow.toLocaleString()) || 0}</div>
                </div>
                <Divider  />
                <div style={{display:'flex',justifyContent:'space-around'}}>
                    <span style={{fontSize:'20px',marginTop:'8px',color:'green'}}> Green </span> 
                    <div className="font-weight rmeCardLabelsFont30">{Math.ceil(item.data.green.toLocaleString()) || 0}</div>
                </div>
          </div>
            </div>}
            
          {item.id === 'tracker'&&
          <div className="rmeCardItems gap10"> 
          <div className='rmeCardsLabel font-weight'>{'Activities'}</div>
          <div className="bottom-part rmeCarditem-Age-tracker"
              onClick={(event)=>{
                event.stopPropagation();
                handleClickCards('MontoringAc',{label:'Monitoring AC'})}}
              >
              <div className="rmeCardLabelsFont15"  style={{color:'green'}}> Monitoring Account </div> 
              <div className="font-weight">{item.data.monitoringAc}</div>
          </div>
          <Divider  />
          <div className="bottom-part rmeCarditem-Age-tracker"
            onClick={(event)=>{
              event.stopPropagation();
              handleClickCards('taskTrackerAc',{label:'Tracker Account'})}}>
              <div  className="rmeCardLabelsFont15" style={{color:'green'}}> Task Tracker</div> 
              <div className="font-weight">{item.data.taskTracker}</div>
          </div>
          </div>
          }
          {item.id === 'assignee'&&
          <div className='rmeCardItems' >
          <div className="rmeCardsLabel font-weight rmeCardLabelsFont15 rme-whiteSpace">  RME with multiple Assignees</div>
          <Divider  />
          <div  className='rme-totalJira-card rmeCardLabelsFont15' > 
                <div className="font-weight rmeCardLabelsFont15"> Assignee </div> 
                <div  className="font-weight rmeCardLabelsFont15"  style={{marginRight:"-40px"}}> Open</div> 
                <div className="font-weight rmeCardLabelsFont15"style={{marginRight:"-15px"}} > Close</div> 
            </div>
            <div  className='rme-totalJira-card rmeCardLabelsFont15' > 
                <div  > Single Owner </div> 
                <div style={{marginLeft:'20px',cursor:'pointer'}} onClick={()=>handleAssigneTableData('oneAssigneeOpen',{label:'Single Owner Open Issues'})}> {sum[9]?.data?.oneAssigneeOpen || 0}</div> 
                <div style={{cursor:'pointer'}} onClick={()=>handleAssigneTableData('oneAssigneeClose',{label:'Single Owner Closed Issues'})}> {sum[9]?.data?.oneAssigneeClose || 0}</div> 
            </div>
            <div  className='rme-totalJira-card rmeCardLabelsFont15' > 
                <div  > Multiple Owner </div> 
                <div style={{cursor:'pointer'}} onClick={()=>handleAssigneTableData('onePlusAssigneeOpen',{label:'Multiple Owner Open Issues'})}> {sum[9]?.data?.onePlusAssigneeOpen || 0} </div> 
                <div style={{cursor:'pointer'}} onClick={()=> handleAssigneTableData('onePlusAssigneeClose',{label:'Multiple Owner Closed Issues'})}> {sum[9]?.data?.onePlusAssigneeClose || 0} </div> 
            </div>
        </div>
          }
            {![ 'accountState','totalJira','smToRm','tracker','ttr','assignee','rmeReEscalation'].includes(item.id) && <div className="rmeCardLabelsFont30">{item.count} </div>}
           { ![ 'accountState','totalJira','smToRm','ageBy','tracker','assignee','ttr','rmeReEscalation'].includes(item.id) &&<div className="font-weight">{item.label}</div>}
          </div>

          {['closed']?.includes(item.id)&&
             <div className="right-section" >
            {  customLineChart(lineChartData?.closed )}
             </div>
          }
        
           {['open']?.includes(item.id)&&
             <div className="right-section" >
                {  customLineChart(lineChartData?.open )}
             </div>
          }
          
        </div>
      ))}
      {
        <ViewDetailModal
          modalTitle={modalTitle + '  ' +'table'}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          columns={tableColumns}
          dataSource={data}
          handleDownloadClick={handleDownloadClick} 
          downloadEnable={true}
          label={modalTitle + '  ' +'table'}
        />
      }
    </div>
  );
};

export default CardContainer;
