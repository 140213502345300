import React, { useEffect, useMemo, useState } from "react";
import { Title } from "@nutanix-ui/prism-reactjs";
import axios from "axios";
import { SERVER_URL } from "../../../../../config/config";
import { useFilters } from "../../../../../contexts/uxContexts/FiltersContext";
import FilterDropdown from "../../../../EngineeringDashboard/Components/FilterDropdown/FilterDropdown";
import { generateMenuForDropdown } from "../../../../EngineeringDashboard/EngineeringUtils";

const UXFilter = ({ setApplyClicked }) => {
  const { selectedFilters, updateFilters } = useFilters(); // Ensure correct destructuring
  const [openProject, setOpenProject] = useState(false);
  const [openUXOwner, setOpenUXOwner] = useState(false);
  const [openUXPod, setOpenUXPod] = useState(false);
  const [openFixVersion, setOpenFixVersion] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialFilterData, setInitialFilterData] = useState(null); // Holds the first fetched data
  // Memoize selected filter values and use only this to trigger data fetching
  const filterQueryKey = useMemo(() => {
    const { ux_owners, projects, fixversions, ux_pod } = selectedFilters;

    return {
      ux_owners: ux_owners[0] === 'All' ? null : ux_owners,
      projects: projects[0] === 'All' ? null : projects,
      fixversions: fixversions[0] === 'All' ? null : fixversions,
      ux_pod: ux_pod[0] === 'All' ? null : ux_pod,
    };
  }, [selectedFilters]);

  const fetchUxFilterData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${SERVER_URL}/ux_filter`, 
        { 
          ux_owners: filterQueryKey.ux_owners, 
          projects: filterQueryKey.projects, 
          fixversions: filterQueryKey.fixversions,
          ux_pod: filterQueryKey.ux_pod,
        }
      );

      if (!initialFilterData) {
        // Store data only the first time
        setInitialFilterData(data.data);
      }
      setFilterData(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUxFilterData(); // Fetch data on component mount
  }, []);

  const handleApplyClick = async () => {
    setApplyClicked(true);
    // Here you can trigger fetching again or apply changes based on the user's selection
    await fetchUxFilterData(); // Re-fetch the data if necessary
    setApplyClicked(false);
  };

  if (error) {
    throw error;
  }

  const createDropdownConfig = ({
    id,
    label,
    openState,
    selectedFilters,
    dataKey,
    isLoading,
    data,
    setOpenState,
    updateFilters,
  }) => {
    return {
      id,
      label,
      mode: "multiple",
      open: openState,
      loading: isLoading,
      handleClose: () => setOpenState(false),
      handleApplyClick,
      component: "ux_team",
      selectedItem: selectedFilters[id],
      items: !isLoading
        ? [
            { label: "All", value: "All" },
            ...generateMenuForDropdown(
              Object.keys(initialFilterData || {}).length > 0 ? initialFilterData[dataKey] : []
            ),
          ]
        : [],
      handleClick: (e) => {
        if (e.target.textContent === "All") {
          updateFilters(id, ["All"]);
        }
        setOpenState(true);
      },
      onChangeItem: (item) => {
        if (item.includes("All") && item.length > 1) {
          const filteredItem = item.filter((state) => state !== "All");
          updateFilters(id, filteredItem);
        } else if (item.length === 0) {
          updateFilters(id, ["All"]);
        } else {
          updateFilters(id, item);
        }
      },
    };
  };

  const projectDropdownConfig = createDropdownConfig({
    id: "projects",
    label: "Projects",
    openState: openProject,
    selectedFilters,
    dataKey: "projects",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenProject,
    updateFilters,
  });

  const uxOwnerDropdownConfig = createDropdownConfig({
    id: "ux_owners",
    label: "UX Owners",
    openState: openUXOwner,
    selectedFilters,
    dataKey: "ux_owners",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenUXOwner,
    updateFilters,
  });

  const fixVersionDropdownConfig = createDropdownConfig({
    id: "fixversions",
    label: "Fix Versions",
    openState: openFixVersion,
    selectedFilters,
    dataKey: "fixversions",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenFixVersion,
    updateFilters,
  });

  const uxPodDropdownConfig = createDropdownConfig({
    id: "ux_pod",
    label: "UX Pods",
    openState: openUXPod,
    selectedFilters,
    dataKey: "ux_pod",
    isLoading: loading,
    data: filterData,
    setOpenState: setOpenUXPod,
    updateFilters,
  });

  const dropdownConfigs = [
    projectDropdownConfig,
    uxOwnerDropdownConfig,
    fixVersionDropdownConfig,
    uxPodDropdownConfig
  ];
  return (
    <>
      <Title className="titleText">Filters</Title>
      <div className="uxDropdownContainer" data-testid="ux-filter-container">
        {dropdownConfigs.map((dropdown) => (
          <FilterDropdown key={dropdown.id} {...dropdown} />
        ))}
      </div>
    </>
  );
};

export default UXFilter;
