import React, { useContext, useState } from "react";
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "@nutanix-ui/recharts";
import NoData from "./NoData";
import { renderTooltip } from "../../utils";
import LeoContext from "../../../../contexts/leoContexts/leoContext";

const ExpensifyHorizontalComparison = ({ data, margin }) => {
  const { activeSpenders } = useContext(LeoContext);
  const [tooltip, setTooltip] = useState(null);
  const defaultDataMap = {
    Expenses: {
      label: "Expenses",
      color: "#59BCF9",
    },
    Flights: {
      label: "Flights",
      color: "#1B6DC6",
    },
  };

  if (activeSpenders === "2") {
    delete defaultDataMap.Flights;
  } else if (activeSpenders === "3") {
    delete defaultDataMap.Expenses;
  }

  const getFreeSpace = (keys, data, capacity) => {
    let used = 0;
    keys.forEach((key) => {
      if (data[key]) {
        used += data[key];
      }
    });

    return capacity - used;
  };

  function mapFreeData(keys, data, capacity) {
    return data.map((item) => {
      return {
        ...item,
        Total: getFreeSpace(keys, item, capacity),
      };
    });
  }

  function renderBars(data, dataMap, barSize) {
    return Object.keys(dataMap).map((key, index) => {
      const { color } = dataMap[key];
      return (
        <Bar
          barSize={barSize}
          dataKey={key}
          key={key}
          stackId="a"
          fill={color}
        />
      );
    });
  }
  // It is recommended to always use a static value of 30 when rendering bars horizontally.
  const barSize = 20;
  const formattedData = mapFreeData(["Expenses", "Flights"], data);
  // const data = [...expenseAndFlightData];
  if (data.length <= 0) {
    return <NoData />;
  }

  const CustomAxisTick = ({ x, y, payload }) => {
    const maxTextWidth = 150; // Maximum width for text before wrapping
    return (
      <g transform={`translate(${x},${y})`} style={{ width: "100%" }}>
        <foreignObject x={-150} y={-10} width={maxTextWidth} height={20}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "14px",
              textAlign: "right",
              color: "rgb(154, 165, 181)",
            }}
          >
            {payload.value}
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <React.Fragment >
      <ResponsiveContainer height={300} width="100%">
        <BarChart
          alignment="none"
          layout="vertical"
          data={data}
          margin={margin}
          onMouseLeave={() => setTooltip(null)}
        >
          <CartesianGrid vertical={true} horizontal={false} stroke="#eee" />
          <XAxis
            tickline={true}
            axisLine={false}
            stroke="#eee"
            type="number"
            tickFormatter={(value) => {
              if (value === 0) {
                return value;
              }
              const formattedValue = (value / 1000).toFixed(2);
              return formattedValue.endsWith(".00")
                ? `$${parseInt(formattedValue)}k`
                : `$${formattedValue}k`;
            }}
          />
          <YAxis
            interval={0}
            dataKey="name"
            type="category"
            width={150}
            onMouseEnter={(event) => {
              setTooltip(event);
            }}
            onMouseLeave={() => setTooltip(null)}
            tickFormatter={(value) =>
              value.length > 20 ? `${value.substring(0, 20)}...` : value
            }
            tick={<CustomAxisTick />}
          />
          <Tooltip
            formatter={(value, name) => {
              const formattedValue = Number(value).toFixed(2);
              const [integerPart, decimalPart] = formattedValue.split(".");
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              const formattedTooltipValue = `$${formattedIntegerPart}.${decimalPart}`;
              return decimalPart === "00"
                ? [formattedIntegerPart, name]
                : [formattedTooltipValue, name];
            }}
          />
          {renderBars(formattedData, defaultDataMap, barSize)}
        </BarChart>
      </ResponsiveContainer>
      {tooltip && renderTooltip(tooltip)}
    </React.Fragment>
  );
};

export default ExpensifyHorizontalComparison;
