// FiltersContext.js
import React, { createContext, useContext, useState } from 'react';

const RMEFiltersContext = createContext();

export const RMEFiltersProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    ticket_type: ['All'],
    issuenum: ['All'],
    aging: ['All'],
    engage_type: ['All'],
    assignee: ['All'],
    current_acc_sts: ['All'],
    rme_region: ['All'],
    rme_status: ['Closed'],
  });
    const [teamData, setTeamData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [loading, setLoading] = useState(true);
  const updateTeamData = (data) =>{
    setTeamData(data)
    return null
  }
  const updateFilters = (filterKey, selectedRows) => {
    if(filterKey === 'initial'){
        setSelectedFilters(selectedRows || {})
        return null
    }else{
        setSelectedFilters((prev) => ({
          ...prev,
          [filterKey]: selectedRows,
        }));
    }
  };

  return (
    <RMEFiltersContext.Provider value={{ selectedFilters, updateFilters,teamData, setTeamData,updateTeamData ,loading,setLoading,tableData, setTableData,tableColumns, setTableColumns}}>
      {children}
    </RMEFiltersContext.Provider>
  );
};

export const useRMEFilters = () => useContext(RMEFiltersContext);
