const RELEASE_API_KEY = process.env.RELEASE_API_KEY;

const RELEASE_API_URL = "https://release-api.nutanix.com/";
const RELEASE_API_ENDPOINT = `${RELEASE_API_URL}api/v1/releases?api_key=${RELEASE_API_KEY}`;
const UBER_TOWER_URL = "http://ubertower.corp.nutanix.com";

export const appDataArray = [
    {
        app_name: "JIRA",
        polling_frequency: 10,
        display: true,
        type: "prod",
        availability: "95.833",
        environment: [
            {
                name: "Tables Monitored:- 0",
                enable: true,
                endPoint: UBER_TOWER_URL,
                link: UBER_TOWER_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],

        createdAt: "2018-04-26T05:49:58.626Z",
        updatedAt: "2022-03-28T06:50:33.279Z",
        upTime: "100.00",
        id: "5ae168869279a2061ac65c1d",
    },

    {
        app_name: "PULSE",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Tables Monitored:- 0",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7j4016b015b345e88f81c",
    },
   
    {
        app_name: "EDW",
        polling_frequency: 20,
        display: true,
        lastUpdatedTime: "",
        type: "prod",
        availability: "100",

        environment: [
            {
                name: "Tables Monitored:- 0",
                enable: true,
                status: "active",

                type: "prod",
                endPoint: "https://portal.nutanix.com/#/page/docs",
                link: "http://portal.nutanix.com",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],

        updatedAt: "2022-03-28T06:50:03.429Z",
        upTime: "99",
        id: "597ef61faecf424d39349ca8",
    },
    {
        app_name: "GSO",
        polling_frequency: 20,
        display: true,
        type: "prod",
        lastUpdatedTime: "",
        availability: "100",
        environment: [
            {
                name: "Tables Monitored:- 0",

                status: "active",
                enable: true,
                endPoint: "http://alerts.corp.nutanix.com/status",
                link: "http://alerts.corp.nutanix.com",
                type: "prod",

                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],

        updatedAt: "2022-03-28T06:50:03.797Z",
        upTime: "100.00",
        id: "597f0e7daecf424d39349ca9",
    },

    {
        app_name: "MANUFACTURING",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Tables Monitored:- 0",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7c4016b015b345e88f81c",
    },
   
    {
        app_name: "google_analytics",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "TBD",
        environment: [
            {
                name: "Tables Monitored:- TBD",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "TBD",
        id: "59b7c4016b015b345e88f81c",
    },
    
  
    {
        app_name: "XACTLY",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "TBD",
        environment: [
            {
                name: "Tables Monitored:- TBD",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "TBD",
        id: "59b7c4016b015b345e88f81c",
    },
   
    {
        app_name: "ZUORA",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "TBD",
        environment: [
            {
                name: "Tables Monitored:- TBD",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "TBD",
        id: "59b7c4016b015b345e88f81c",
    },
    {
        app_name: "NETSUITE",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "TBD",
        environment: [
            {
                name: "Tables Monitored:- TBD",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "TBD",
        id: "59b7c4016b015b345e88f81c",
    },
    {
        app_name: "SFDC",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Tables Monitored:- 0",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7k4016b015b345e88f81c",
    },
    {
        app_name: "CSM",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Tables Monitored:- 0",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7k4016b015b345e88f81c",
    },
    {
        app_name: "SALESOPS",
        polling_frequency: 20,
        display: true,
        type: "prod",
        availability: "0.000",
        environment: [
            {
                name: "Tables Monitored:- 0",

                enable: true,
                endPoint: RELEASE_API_ENDPOINT,
                link: RELEASE_API_URL,
                type: "prod",
                status: "active",
                lastUpdatedTime: "2022-03-28T06:50:03+00:00",
            },
        ],
        updatedAt: "2022-03-28T06:50:03.473Z",
        upTime: "100.00",
        id: "59b7k4016b015b345e88f81c",
    }
];
