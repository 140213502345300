import {
  Link,
  Pagination,
  Sorter,
  Table,
  Title,DownloadIcon
  
} from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useState,useMemo } from "react";
import moment from "moment";
import { downloadRMETemplate } from '../../../../../utils/rmecsvDownloadUtlity';
import "./Status/RMEStatus.css";
import { useRMEFilters } from "../../../../../contexts/rmeTeamContext/RMEFiltersContext";

const validateParam = (value, defaultValue, minValue = 1) => {
  const parsedValue = parseInt(value, 10);
  if (!Number.isNaN(parsedValue) && parsedValue >= minValue) {
    return parsedValue;
  }
  return defaultValue;
};
const ViewTable = ({ applyClicked }) => {
  const {  teamData,loading, setTableColumns,setLoading } = useRMEFilters();
  const defaultPageSize = [5, 10, 15, 20];
  const [currentPage, setCurrentPage] = useState(validateParam(1));
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [teamDetaislData, setTeamDetailsData] = useState([]);

  const [total, setTotal] = useState(1);

  const [sort, setSort] = useState({
    order: Sorter.SORT_ORDER_CONST.ASCEND,
    column: "issuenum",
    sortable: [
      "issuenum",
      "issue_type",
      "engage_type",
      "jira_status",
      "ttl_rme_age"
    ],
  });
  const columns = [
    {
      title: <span>Issue Number</span>,
      key: "issuenum",
      dataIndex: "issuenum",
      render: (name) => {
        return (
          <Link
            target="blank"
            href={`https://jira.nutanix.com/browse/${name}`}
            type="table-nav-primary"
          >
            {name || "NA"}
          </Link>
        );
      },
    },

    {
      title: <span>Issue Type </span>,
      key: "issue_type",
      dataIndex: "issue_type",
      render: (name) => name || "NA",
    },

    {
      title: <span>Engagement type</span>,
      key: "engage_type",
      dataIndex: "engage_type",
      render: (name) => name || "NA",
    },
    {
      title: <span>Jira Status</span>,
      key: "jira_status",
      dataIndex: "jira_status",
      render: (name) => name || "NA",
    },
    {
      title: <span>JRME age</span>,
      key: "ttl_rme_age",
      dataIndex: "ttl_rme_age",
      render: (name) => name || "NA",
    },
    {
      title: <span>Summary</span>,
      key: "summary",
      dataIndex: "summary",
      render: (name) => name || "NA",
    },
    {
      title: <span>Linked</span>,
      key: "linked",
      dataIndex: "linked",
      render: (name) => name || "NA",
    },
  ];
  useEffect(() => {
    setLoading(true);
    const dataSource = teamData?.data?.map((item, index) => ({
      key: index + 1,
      ...item,
    }));
    setTeamDetailsData(dataSource);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentItems = dataSource?.slice(startIndex, endIndex);
    
    setDataSource(currentItems);
    setTotal(teamData?.data?.length || 1);
    setTableColumns(columns)
    setLoading(false);
  }, [teamData, teamData?.data?.length > 0]);

  const onChangePagination = (newPage, newPageSize) => {
    const startIndex = (newPage - 1) * pageSize;

    const endIndex = startIndex + pageSize;
    // Slice the data to show only the items for the current page
    const currentItems = teamDetaislData?.slice(startIndex, endIndex);
    setDataSource(currentItems);
    setCurrentPage(newPage);
    setPageSize(newPageSize);
  };

  const structure = {
    columnWidths: {
      issuenum: "200px",
      engage_type: "200px",
      issue_type:"150px",
      jira_status: "200px",
      ttl_rme_age: "100px",
      summary: "400px",
      linked: "200px",
      dt_updated: "200px",
    },
  };
 const handleDownloadClick = (row,label) => {
    if(row.length>0){
      downloadRMETemplate(row, label);
    }
  }
  const handleChangeSort = (newSort) => {
    setSort((prevState)=> ({
      ...prevState,
      ...newSort
    }));
  };
 const sortedData = useMemo(() => {
    const newData = [...dataSource];
    if (!sort.column) return newData;
    newData.sort((a, b) => {
      const aValue = a[sort.column];
      const bValue = b[sort.column];
      let result =
        typeof aValue === "number" && typeof bValue === "number"
          ? aValue - bValue
          : String(aValue).localeCompare(String(bValue));
      return sort.order === Sorter.SORT_ORDER_CONST.DESCEND ? -result : result;
    });
    return newData;
  }, [teamData?.data && dataSource, sort.column, sort.order]);
  return (
    <>
      <Title className="titleText" style={{ marginBottom: "5px" }}>
        All Jira Details
      </Title>
      <div className="widgetHeader">
        <h3 style={{ color: "#627386" }}>{`Viewing all ${
          teamData?.data?.length || 0
        } issues`}</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          <Pagination
            currentPage={currentPage}
            onChange={onChangePagination}
            pageSize={pageSize}
            pageSizeOptions={defaultPageSize}
            pageSizeOptionLabel="items"
            total={total}
          />
          <DownloadIcon
            size="large"
            onClick={() =>
              handleDownloadClick(
                teamData?.data,
                " All Jira Details -" +
                  moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
              )
            }
            data-testid="downloadIcon"
            style={{ marginRight: "10px" }}
          />
        </div>
      </div>
      <Table
        className="rme-dashboard-table"
        showCustomScrollbar={true}
        columns={columns}
        dataSource={sortedData || []}
        loading={loading}
        structure={structure}
        sort={sort}
        onChangeSort={handleChangeSort}
        wrapperProps={{
          "data-test-id": "rme-dashboard",
        }}
      />
    </>
  );
};

export default ViewTable;
