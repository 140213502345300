import React, { useRef } from "react";
import { Modal, Progress, Select } from "antd";
import axios from "axios";
import { SERVER_URL } from "../../config/config";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
const { Option, OptGroup } = Select;

function DropDown(props) {
    const {
        menuItems = [],
        // onChange,
        defaultValue,
        name,
        label,
        mode,
        loading,
        disabled = false,
        showSearch,
        placeholder,
        grouped = false,
        ...otherProps
    } = { ...props };
    const selectRef = useRef(null);

    return (
        <div className="flex">
            <div
                style={{
                    fontSize: "18",
                    marginRight: "20px",
                    flexBasis: "125px",
                }}
            >
                {label}
            </div>
            <div className={"dropdown-wrapper"} ref={selectRef}>
                <Select
                    placeholder={placeholder || "Please select"}
                    mode={mode}
                    showSearch={showSearch}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    loading={loading ? true : false}
                    notFoundContent={"No Data"}
                    // onChange={(value, label) => onChange(name, value, label)}
                    {...otherProps}
                    style={{ fontSize: "12px" }}
                >
                    {grouped
                        ? menuItems.map((e, index) => (
                              <OptGroup label={e.schemaName} key={index}>
                                  {e.tables.map((tablename, index1) => (
                                      <Option
                                          key={index1 + tablename + index}
                                          value={tablename}
                                          style={{
                                              width: selectRef?.current
                                                  ?.offsetWidth,
                                              overflow: "hidden",
                                              fontSize: "12px",
                                          }}
                                      >
                                          {tablename}
                                      </Option>
                                  ))}
                              </OptGroup>
                          ))
                        : menuItems.map((e) => (
                              <Option
                                  key={e}
                                  value={e}
                                  style={{
                                      width: selectRef?.current?.offsetWidth,
                                      overflow: "hidden",
                                      fontSize: "12px",
                                  }}
                              >
                                  {e}
                              </Option>
                          ))}
                </Select>
            </div>
        </div>
    );
}

class TableSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiProgress: { loading: false, ready: false, error: false },
            tableItems: [],
            isModalVisible: false,
            selectedTable: {},
        };
    }
    handleCancel = () => this.setState({ isModalVisible: false });

    showModal = () => this.setState({ isModalVisible: true });

    handleChange = (name, value, label) => {
        this.showModal();
        if (name) {
            const selectedTableProperties = this.props.envData.find(
                (e) => e.table_name === name
            );
            this.setState({ selectedTable: selectedTableProperties });
        }
    };

    handleGroupedTables() {
        return this.props.envData.reduce((acc, tableNames) => {
            if (acc[tableNames.schema_name]) {
                acc[tableNames.schema_name].tables.push(tableNames.table_name);
                return acc;
            }
            acc[tableNames.schema_name] = {
                schemaName: tableNames.schema_name,
                tables: [tableNames.table_name],
            };
            return acc;
        }, {});
    }

    render() {
        return (
            <div>
                <div style={{ width: 350 }}>
                    <DropDown
                        // onChange={this.handleChange}
                        menuItems={Object.values(this.handleGroupedTables())}
                        name={"tableName"}
                        placeholder={"Search any Table"}
                        suffixIcon={<SearchOutlined />}
                        showSearch
                        grouped={true}
                        onSelect={this.handleChange}
                    />
                </div>
                <Modal
                    width={350}
                    title={<b>{this.state.selectedTable?.table_name}</b>}
                    open={this.state.isModalVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <ModalContent {...this.state.selectedTable} />
                </Modal>
            </div>
        );
    }
}

class ModalContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { schema_name = "", status, act_time } = this.props;
        return (
            <article style={{ width: "100%" }}>
                <LocalPropertyRenderer
                    propertyName={"Schema Name"}
                    propertyValue={schema_name?.toUpperCase()}
                />
                <LocalPropertyRenderer
                    propertyName={"ETL Completed Time"}
                    propertyValue={
                        act_time
                            ? moment(act_time, "MMM DD, hh:mm A").format(
                                  "MMM DD, hh:mm A"
                              )
                            : "Nil"
                    }
                />
                <LocalPropertyRenderer
                    propertyName={"Status"}
                    propertyValue={
                        status ? (
                            <Progress type="circle" percent={100} width={24} />
                        ) : (
                            <Progress
                                type="circle"
                                percent={100}
                                width={24}
                                status="exception"
                            />
                        )
                    }
                />
            </article>
        );
    }
}
class LocalPropertyRenderer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { propertyName, propertyValue, className } = this.props;
        let color = "inherit";
        let fontColor = "inherit";

        if (propertyName === "Delta") {
            if (propertyValue !== "0") {
                color = "#fcdede";
                fontColor = "red";
            }
        }
        return (
            <div
                style={{
                    display: "flex",
                    // justifyContent: 'space-between',
                    padding: 4,
                    paddingBottom: 2,
                    paddingTop: 2,
                    gap: 8,
                    background: color,
                    color: fontColor,
                    fontSize: 12,
                    lineHeight: "14px",
                }}
            >
                <p style={{ flexBasis: 120 }}>{propertyName}</p>-
                <div style={{ marginTop: propertyName == "Status" ? -5 : 0 }}>
                    <b>{propertyValue}</b>
                </div>
            </div>
        );
    }
}

export default TableSearch;
