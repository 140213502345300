import React from "react";
import "./index.css";
import Logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";

class ErrorBoundary extends React.Component {
  state = { 
    hasError: false,
    status: null, 
    statusText: null, 
    message: null, 
    code: null || 'Something Went Wrong'
  };

  componentDidCatch(error) {
    const { status } = error.response
    if(status === 401){
        window.location.href = `${SERVER_URL}/login`;
        return null
    }
    this.setState({
        hasError: true,
        status, 
    })
    Logger.error(error);
  }
  
  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
             onPageRefresh={() => this.setState({ hasError: false })}
        />
      );
    }
    return this.props.children;
  }
}

function ErrorComponent({ onPageRefresh }) {
  return (
    <div className={"error-container"}>
      <div className={"error-wrapper-local"}>
        {/* <img src={lostImg} alt={'something off'}/> */}
        <p className={"error-heading"}>Something went wrong!!!</p>
        <p variant={"h5"}> Hi, the page is not functional.</p>
        <p variant={"subtitle1"}>
          Looks like something is off. This usually means you are trying to use
          a feature that is under development or something that is released for
          testing. In any case we're already fixing it and everything should be
          back to normal in few hours
        </p>
        <div className={"error-quick-actions"}>
          <div className={"error-page-action-button"} onClick={onPageRefresh}>
            Reload the page
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorBoundary;