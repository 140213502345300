import React, { useContext } from "react";
import { Table } from "@nutanix-ui/prism-reactjs";
import LeoContext from "../../../contexts/leoContexts/leoContext";
import { formatCurrencyWithCommas, generateDataSourceEmployee } from "../utils";
const EmployeeTable = ({transformedEmployeeData, empGrandTotal}) => {
  const { loading, sort, setSort } = useContext(LeoContext);
  const columns = [
    {
      title: "Employee",
      key: "employee_name",
    },
    {
      title: "Division",
      key: "division",
    },
    {
      title: "Cost Center",
      key: "cost_center",
    },
    {
      title: "Expense",
      key: "expense_amount_usd",
      className: "bold-column",
      textAlign: "right"
    },
    {
      title: "Flight",
      key: "flight_amount_usd",
      className: "bold-column",
      textAlign: "right"
    },
    {
      title: "PO Bill Amount",
      key: "po_invoice_amount_usd",
      className: "bold-column",
      textAlign: "right"
    },
    {
      title: "Amount USD",
      key: "total_amount_usd",
      className: "bold-column",
      textAlign: "right"
    },
  ];
  const updatedData = [];
  if (transformedEmployeeData.length > 0) {
    let divisionDataSource = generateDataSourceEmployee(transformedEmployeeData);
    const empGrand = empGrandTotal[0]
    const expense_amount_usd = empGrand.expense_amount_usd === null ? "-" : `${formatCurrencyWithCommas(empGrand.expense_amount_usd.toFixed(2))}`
    const flight_amount_usd = empGrand.flight_amount_usd === null ? "-" : `${formatCurrencyWithCommas(empGrand.flight_amount_usd.toFixed(2))}`
    const po_invoice_amount_usd = empGrand.po_invoice_amount_usd === null ? "-" : `${formatCurrencyWithCommas(empGrand.po_invoice_amount_usd.toFixed(2))}`
    const total_amount_usd = empGrand.total_amount_usd === null ? "-" : `${formatCurrencyWithCommas(empGrand.total_amount_usd.toFixed(2))}`
    updatedData.push({
      key: "total",
      employee_name: <p className="bold-column">Grand Total</p>,
      division: "-",
      cost_center: "-",
      expense_amount_usd,
      flight_amount_usd,
      po_invoice_amount_usd,
      total_amount_usd, // Format total amount as currency
    });
    updatedData.push(...divisionDataSource);
  }

  // Add custom className to the Grand Total row
  const components = {
    body: {
      row: (props) => {
        const { children, className } = props;
        return (
          <tr
            {...props}
            className={`${className} ${
              props.data.key === "total" ? "grand-total-row" : "grand-total-row"
            }`}
          >
            {children}
          </tr>
        );
      },
    },
  };

  const handleSortChange = (newSort) => {
    setSort((prevState)=> ({
      ...prevState,
      employeeTable: {
        ...prevState.employeeTable,
        order: newSort.order,
        column: newSort.column
      }
    }))
  };
  return (
    <Table
      loading={loading.employeeLoading}
      components={components}
      showCustomScrollbar={true}
      dataSource={updatedData}
      columns={columns}
      sort={sort.employeeTable}
      onChangeSort={handleSortChange}
    />
  );
};

export default EmployeeTable;
