import React from "react";

import internalError from '../images/internalError.svg';

import { Button } from '@nutanix-ui/prism-reactjs'

import { Link } from 'react-router-dom'

import '../App.css';

class UnAuthorisedPage extends React.Component {

    render() {
        return <div className="errorPageContainer">
            <div className="erroPageContent">
                <div className="errorImage">
                    <img src={internalError} alt="" />
                </div>
                <div className="errorHeading">
                    <h2>Unauthorised Access</h2>
                </div>
                <div className="errorParagraph">
                    <p>You are not authorised to this page. Please contact admin.</p>
                </div>
                <div className="reloadButton">
                    <Button>
                        <Link to="/" style={{ color: 'white', margin: '2rem' }}>
                            Go to Home
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    }
}

export default UnAuthorisedPage;
