import React from "react";
import CustomerFilter from "../Filters/CustomerFilter";
import { Loader } from "@nutanix-ui/prism-reactjs";
import Widget from "../WidgetContainer/Widget";
import Customer from "../Graphs/Customer";
import CustomerFooter from "../WidgetContainer/CustomerFooter";
import { useSelector } from "react-redux";

const CustomerDefects = () => {
  const { loading } = useSelector((state) => state.enggDashboard);
  return (
    <div className="tabContainer">
      <div className="filterContainer">
        <CustomerFilter />
      </div>
      <div className="widgetContainer">
        <div className="widgetItems full">
          <Loader
            loading={loading.cfd}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Total CFDs (Customer Defects)"
              id="customerDefects"
              body={<Customer />}
              footer={<CustomerFooter />}
            />
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default CustomerDefects;
