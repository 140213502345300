
module.exports = {
  config: {
    url: '/api/applicationstatus',
    downtimeUrl: '/api/applicationdowntimestatus',
    table2DaysTitle: 'Last 2 days Downtime List',
    table30DaysTitle: 'Last 30 days Downtime List',
    scrollHelperText: 'Scroll down to checkout downtime list',
    nDaysForChart: 30,
    nHours: 48,
    sliderSettings : {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    chartData: {
      columns: [
        ['active'],
        ['inactive'],
      ],
      type: 'bar',
      bar : {
        width: {
          ratio: 0.8
        }
      },
      colors: {
        'active': '#b2d8b2',
        'inactive': '#f27f7f',
        'noDataPolling': '#f2f2f2'
      },
      names: {
        active: 'Active',
        inactive: 'Down',
        noDataPolling: 'No Data Polling'
      },
      tooltip: {
        show: true,
        format: {
          title: function (d) { return (48-d) + ' hours ago' }
        }
      },
      groups: [
        ['active', 'inactive', 'noDataPolling']
      ],
      size: {
        height: 240,
      },
      axis: {
        x: {
          show: true,
          type: 'category',
          categories: [],
          tick: {
            fit: true,
            multiline: false,
            culling: {
              max: ''
            },
          },
          label: {
            text: 'Last 48 hours (x-axis)',
            position: 'outer-center'
          }
        },
        y: {
          show: true,
          tick: {
            values: [0, 1, 2, 3, 4, 5, 6]
          },
          label: {
            text: 'Polling per hour',
            position: 'outer-middle'
          }
        }
      },
    },
  }
};
